import {useEffect} from "react";
import axios from "axios/index";

export const PayForCards = ({company}) => {

    useEffect(async () => {
        console.log("Got here")
        if (company && company.paymentDetails && (company.paymentDetails.year1 || company.paymentDetails.year2 || company.paymentDetails.monthly)) {
            let label = "1 year"
            if (company.paymentDetails.year2)
                label = "2 years"
            const nakedUrl = "https://bizzerd.com";
            const returnUrl = window.location.origin + "/dashboard/collegas/payment";
            const webhook = nakedUrl + '/api/payment/webhook';
            let description = "Single Purchase of " + label + " bizzerd";
            let total_price = 0.00
            let paymentPlan = ""
            if (company.paymentDetails.year1) {
                total_price = company.paymentDetails.openUsers * 30
                paymentPlan = "year1"
            } else if (company.paymentDetails.year2) {
                total_price = company.paymentDetails.openUsers * 48
                paymentPlan = "year2"
            } else if (company.paymentDetails.monthly) {
                total_price = 0.21
                paymentPlan = "monthly"
            }
            const response = await axios.post("/api/payment/payment/inactive", {
                description: description,
                redirectUrl: returnUrl,
                webhookUrl: webhook,
                total_price: (total_price * 1.21).toFixed(2),
                paymentPlan: paymentPlan
            })
            if (response?.data?.paymentdata?._links?.checkout) {
                window.location.replace(response.data.paymentdata._links.checkout.href)
            }
        }
    }, [company]);
    return <></>

}
import React from 'react'
import GrayContainer from "../common2/containers/gray.container";
import NavigationHeader from "../common2/headers/navigation.header";
import GradientContainer from "../common2/containers/gradient.container";
import PageTitle from "../common2/fields/text/pagetitle.text";
import Footer from "../common2/footers/footer";
import {translate} from "react-i18nify";
import PasswordForget from "../common2/blocks/forgot.block";

interface pageProps {
    width: number
    match: any,
}

class ForgotPage extends React.Component<pageProps, {}> {
    render() {
        return (<><GrayContainer style={{paddingBottom: "40px"}}>
            <NavigationHeader width={this.props.width}/>
        </GrayContainer>
            <GradientContainer>
                <PageTitle text={translate("hd.loginBlock.pageTitleForgot")}/>
                <PasswordForget width={this.props.width} match={this.props.match}/>
            </GradientContainer>
            <Footer/></>);
    }
}

export default ForgotPage
import {GET_NFC_REDIRECT, GET_NFCS} from "../actions/types";
import isEmpty from '../validation/is-empty'

const initialState = {
    success: false,
    nfcs: [],
    nfc: {},
    redirect: "",
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_NFCS:
            return {
                ...state,
                success: !isEmpty(action.payload),
                nfcs: action.payload,
            };
        case GET_NFC_REDIRECT:
            return {
                ...state,
                redirect: action.payload
            }
        default:
            return state;
    }
}
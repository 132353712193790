import React from "react";
import './container.styles.css'
// @ts-ignore
import {default as Background} from "../../../assets/containers/orange.container.png"

export interface containerProps {
    children?: Array<any>,
    style?: {},
    className?: string,
}

class WhiteContainer extends React.Component<containerProps, {}> {
    render() {

        return <div style={this.props.style} className={"container-white " + this.props.className}>
            <div className={"content-wrapper"}>
                <div className={"container-white " + this.props.className} style={this.props.style}>
                {this.props.children}
                </div>
                <div></div>
            </div>
        </div>;
    }

}

export default WhiteContainer;
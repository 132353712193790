import axios from 'axios';
import {setBizzerdLoading} from "./bizzerdActions";
import {GET_TEMPLATE, GET_TEMPLATES} from "./types";

export const getAllTemplates = () => dispatch => {
    dispatch(setBizzerdLoading());
    axios.get(`/api/template/all/`)
        .then(res => {
            dispatch({
                type: GET_TEMPLATES,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_TEMPLATES,
            payload: null
        }))
};

export const getAllTemplatesFromVariations = () => dispatch => {
    dispatch(setBizzerdLoading());
    axios.get(`/api/template/all/variations/`)
        .then(res => {
            dispatch({
                type: GET_TEMPLATES,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_TEMPLATES,
            payload: null
        }))
};

export const getPopularTemplates = () => dispatch => {
    axios.get(`/api/template/popular/`)
        .then(res => {
            dispatch({
                type: GET_TEMPLATES,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_TEMPLATES,
            payload: null
        }))
};


export const getTemplateById = (id) => dispatch => {
    dispatch(setBizzerdLoading());
    axios.get(`/api/template/` + id)
        .then(res => {
            dispatch({
                type: GET_TEMPLATE,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_TEMPLATE,
            payload: null
        }))
};

export const updateTemplateById = (id, template) => dispatch => {
    dispatch(setBizzerdLoading());
    axios.post(`/api/template/` + id, template)
        .then(res => {
            dispatch({
                type: GET_TEMPLATE,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_TEMPLATE,
            payload: null
        }))
};
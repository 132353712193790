import React from "react";
import ButtonColors from "../buttons/button.colors";
import ShoppingCartItem from "../blocks/shoppingcartitem.block";
import MainButton from "../buttons/action_main/main.button";
import SubSubTitle from "../fields/text/subsubtitle.text";
import {translate} from "react-i18nify";


interface detailProps {
    width: number
}

interface shoppingCartState {
    person: any,
    duration: number,
    bizzerd: any,
    consent: boolean,
    nfc: boolean,
    code?: string,
}

class ShoppingCartFlow extends React.Component<detailProps, shoppingCartState> {

    constructor(props) {
        super(props);
        let personString = localStorage.getItem("person");
        let bizzerdString = localStorage.getItem("bizzerd");
        let duration: any = localStorage.getItem("duration");
        let person: any = {};
        let bizzerd: any = {};
        if (personString) {
            person = JSON.parse(personString);
        }
        if (bizzerdString) {
            bizzerd = JSON.parse(bizzerdString);
        }
        if (duration)
            duration = parseInt(duration)
        this.state = {
            person: person,
            duration: duration,
            bizzerd: bizzerd,
            consent: false,
            nfc: true,
        }
        this.changeConsent = this.changeConsent.bind(this)
        this.changeNfc = this.changeNfc.bind(this)
        this.startPayment = this.startPayment.bind(this)
        this.handleCodeInput = this.handleCodeInput.bind(this)


    }

    private startPayment() {
        if (this.state.consent) {
            const codeObject = {
                "52e5d784-633e-4caa-b61f-f01a3f75232e": {price: 75.00, title: "Bizzerd design klein 1"},
                "06b4a578-bd24-4b57-bb74-20ace189d575": {price: 125.00, title: "Bizzerd design klein 1+"},
                "553f829e-ddff-441f-9b19-0926c11e138e": {price: 250.00, title: "Bizzerd design middel"},
                "c915939f-23ac-49da-8a3f-0694e89f9507": {price: 495.00, title: "Bizzerd design groot"},
                "dr3zz1651t4rgn33l1wk1": {price: 0.00, title: "Gratis Bizzerd"},
            }
            let price = [-1, 9.48, 16.56, 21.24][this.state.duration] + (this.state.code && Object.keys(codeObject).includes(this.state.code) ? codeObject[this.state.code].price : 0)
            let items = [{
                item_name: "Bizzerd design " + this.state.duration.toString() + " year(s)",
                item_id: this.state.bizzerd._id,
                quantity: 1,
                value: [-1, 9.48, 16.56, 21.24][this.state.duration]
            }]
            if (this.state.code && Object.keys(codeObject).includes(this.state.code)) {
                items.push({
                    item_name: codeObject[this.state.code].title,
                    item_id: this.state.code,
                    quantity: 1,
                    value: codeObject[this.state.code].price
                })
            }
            // @ts-ignore
            window.gtag("event", "begin_checkout", {
                currency: "EUR",
                value: price,
                items: items
            })
            localStorage.setItem("nfc", this.state.nfc.toString())

            window.location.href = "/finish"
        }
    }

    private changeConsent(e) {
        this.setState({consent: e.target.checked})
    }

    private changeNfc(e) {
        this.setState({nfc: e.target.checked})
    }

    private handleCodeInput(code) {
        let codeObject = {
            "52e5d784-633e-4caa-b61f-f01a3f75232e": {price: 75.00, title: "Bizzerd design klein 1"},
            "06b4a578-bd24-4b57-bb74-20ace189d575": {price: 125.00, title: "Bizzerd design klein 1+"},
            "553f829e-ddff-441f-9b19-0926c11e138e": {price: 250.00, title: "Bizzerd design middel"},
            "c915939f-23ac-49da-8a3f-0694e89f9507": {price: 495.00, title: "Bizzerd design groot"},
            "dr3zz1651t4rgn33l1wk1": {price: 0.00, title: "Gratis Bizzerd"},
        }
        if (Object.keys(codeObject).includes(code)) {
            // @ts-ignore
            window.gtag("event", "add_to_cart", {
                currency: "EUR",
                value: codeObject[code].price,
                items: [{
                    item_name: codeObject[code].title,
                    price: codeObject[code].price,
                    quantity: 1
                }]
            })
        }
        switch (code) {
            //Bizzerd design 75
            case "52e5d784-633e-4caa-b61f-f01a3f75232e":
                this.setState({code: code})
                localStorage.setItem("code", code)
                break
            //Bizzerd design 125
            case "06b4a578-bd24-4b57-bb74-20ace189d575":
                this.setState({code: code})
                localStorage.setItem("code", code)
                break
            //Bizzerd design 250
            case "553f829e-ddff-441f-9b19-0926c11e138e":
                this.setState({code: code})
                localStorage.setItem("code", code)
                break
            //Bizzerd design 495
            case "c915939f-23ac-49da-8a3f-0694e89f9507":
                this.setState({code: code})
                localStorage.setItem("code", code)
                break
            //Gratis bizzerd
            case "dr3zz1651t4rgn33l1wk1":
                this.setState({code: code})
                localStorage.setItem("code", code)
                break
            default:
                break
        }
    }

    render() {

        let valid = true;
        if (!this.state.person || !this.state.bizzerd || !this.state.duration) {
            valid = false;
        }
        let codePrice = 0;
        switch (this.state.code) {
            case "52e5d784-633e-4caa-b61f-f01a3f75232e":
                codePrice = 75
                break
            //Bizzerd design 125
            case "06b4a578-bd24-4b57-bb74-20ace189d575":
                codePrice = 125
                break
            //Bizzerd design 250
            case "553f829e-ddff-441f-9b19-0926c11e138e":
                codePrice = 250
                break
            //Bizzerd design 495
            case "c915939f-23ac-49da-8a3f-0694e89f9507":
                codePrice = 495
                break
        }
        let price: number = [0, 0.79, 0.69, 0.59][this.state.duration] * (12 * this.state.duration) + codePrice;
        let btw: number = price * 0.21;
        let total: number = price + btw;
        if (!valid) {
            price = 0.00
            btw = 0.00
            total = 0.00
        }
        if (this.state.code === "dr3zz1651t4rgn33l1wk1") {
            price = 0.00
            btw = 0.00
            total = 0.00
        }


        return (
            <div className={"single-block-container"} style={valid ? {} : {paddingTop: "40px"}}>
                {valid ? <><ShoppingCartItem duration={this.state.duration} width={this.props.width}
                                             personContent={this.state.person}
                                             bizzerdLayout={this.state.bizzerd}/></> : <>
                    <SubSubTitle
                        text={translate("hd.shoppingCart.nothingHere")}
                        style={{paddingBottom: "60px", fontSize: "24px"}}/>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <MainButton color={ButtonColors.orange}
                                    onClick={() => window.location.href = translate("hd.links.businessCards")}
                                    text={translate("hd.shoppingCart.toTheCards")}/>
                        <span style={{marginLeft: 20, marginRight: 20}}>{translate("hd.shoppingCart.or")}</span>
                        <MainButton color={ButtonColors.white}
                                    onClick={() => window.location.href = translate("hd.links.enterprise")}
                                    text={translate("hd.shoppingCart.toEnterprise")}/>
                    </div>
                </>}
                {this.state.code ? <ShoppingCartItem width={this.props.width} personContent={this.state.person}
                                                     code={this.state.code}/> :
                    <></>}
                {valid ? <div className={"shoppingcart-pricing-container"}>
                    <div className={"editor-right"}>
                        <a className={"text-link"} style={{fontWeight: "bold"}}
                           onClick={() => {
                               let code = window.prompt("Voer uw promotie code in")
                               if (code == null || code == "") {
                               } else {
                                   this.handleCodeInput(code)
                               }
                           }}>

                            {translate("hd.shoppingCart.enterCode")}
                        </a>
                        <div className={"shoppingcart-pricing-table"}>
                            <div className={"shoppingcart-pricing-table-row"}>
                                <span>{translate("hd.shoppingCart.total")}</span>
                                <span>€ {price.toFixed(2)}</span>
                            </div>
                            <div className={"shoppingcart-pricing-table-row"}
                                 style={{borderBottom: "1px solid #c9c9c9"}}>
                                <span>{translate("hd.shoppingCart.btw")}</span>
                                <span>€ {btw.toFixed(2)}</span>
                            </div>
                            <div className={"shoppingcart-pricing-table-row"}
                                 style={{borderBottom: "1px solid #c9c9c9", color: "#334c6e"}}>
                                <span>{translate("hd.shoppingCart.totalIncBtw")}</span>
                                <span>€ {total.toFixed(2)}</span>
                            </div>
                            <div className={"shoppingcart-pricing-table-row"} style={{alignItems: "center"}}>
                                <input type={"checkbox"} id={"voorwaarden"} style={{width: "30px", height: "30px"}}
                                       onChange={this.changeConsent} checked={this.state.consent}/>
                                <label style={{width: "90%"}}
                                       htmlFor={"voorwaarden"}>{translate("hd.shoppingCart.agreeTo")} <a
                                    href={"/termsconditions.pdf"} target={"_blank"}
                                    className={"text-link"}>{translate("hd.shoppingCart.toc")}</a> & <a
                                    href={"/privacystatement.pdf"} target={"_blank"}
                                    className={"text-link"}>{translate("hd.shoppingCart.privacyPolicy")}</a></label>
                            </div>
                            <div className={"shoppingcart-pricing-table-row"} style={{alignItems: "center"}}>
                                <input type={"checkbox"} id={"nfc"} style={{width: "30px", height: "30px"}}
                                       onChange={this.changeNfc} checked={this.state.nfc}/>
                                <label style={{width: "90%"}}
                                       htmlFor={"nfc"}>{translate("hd.shoppingCart.nfc")}</label>
                            </div>
                            <div className={"shoppingcart-pricing-table-row"} style={{justifyContent: 'flex-end'}}>

                                <MainButton color={ButtonColors.orange} onClick={this.startPayment}
                                            text={translate("hd.shoppingCart.pay")}/>
                            </div>
                        </div>

                    </div>
                </div> : <></>}

            </div>)
    }


}

export default ShoppingCartFlow
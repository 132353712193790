import {
    ADD_TRANSLATION, GET_ABBREVIATIONS,
    GET_TRANSLATION,
    GET_TRANSLATIONS, GET_TRANSLATIONSBIZZERD,
    UPDATE_TRANSLATION
} from "../actions/types";
import isEmpty from '../validation/is-empty'

const initialState = {
    success: false,
    translations: {},
    person: {},
    updatedTranslation: {},
    refreshPerson: false,
    abbreviations: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case ADD_TRANSLATION:
            return {
                ...state,
                person: action.payload,
                refreshPerson: true,
                success: !isEmpty(action.payload)
            };
        case GET_TRANSLATION:
            return {
                ...state,
                translations: action.payload,
                refreshPerson: false,
                success: !isEmpty(action.payload)
            };
        case GET_TRANSLATIONSBIZZERD:
            return {
                ...state,
                translations: action.payload
            }
        case GET_TRANSLATIONS:
            return {
                ...state,
                translations: action.payload,
            };
        case GET_ABBREVIATIONS:
            return {
                ...state,
                abbreviations: action.payload
            }
        case UPDATE_TRANSLATION:
            return {
                ...state,
                updatedTranslation: action.payload,
                refreshPerson: false,
                success: !isEmpty(action.payload)
            };
        default:
            return state;
    }
}
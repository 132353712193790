export const IconTypes = Object.freeze({
    LinkedIn: {
        name: 'Linkedin',
        type: 'linkedin',
        placeholder: 'https://www.instagram.com/'
    },
    Facebook: {
        name: 'Facebook',
        type: 'facebook',
        placeholder: 'https://www.facebook.com/'
    },
    Instagram: {
        name: 'Instagram',
        type: 'instagram',
        placeholder: 'https://www.instagram.com/'
    },
    Twitter: {
        name: 'Twitter',
        type: 'twitter',
        placeholder: 'https://www.twitter.com/'
    },
    Pinterest: {
        name: "Pinterest",
        type: 'pinterest',
        placeholder: 'https://www.pinterest.com/'
    },
    Email: {
        name: "E-mailadres icoon",
        type: 'email',
        placeholder: 'mailto:example@example.com'
    },
    Tel: {
        name: 'Telefoonnummer icoon',
        type: 'tel',
        placeholder: 'tel:0123456789'
    },
    Website: {
        name: 'Website icoon',
        type: 'website',
        placeholder: 'https://www.bizzerd.com/'
    },
    Vimeo: {
        name: 'Vimeo icoon',
        type: 'vimeo',
        placeholder: 'https://www.vimeo.com/'
    },
    Opslaan: {
        name: 'Bizzerd Opslaan',
        type: 'opslaan',
        placeholder: ''
    },
    Delen: {
        name: 'Bizzerd Delen',
        type: 'delen',
        placeholder: ''
    }
});

export const ButtonTypes = Object.freeze({
    ExtraButton: {
        name: 'Extraknop',
        type: 'extrabutton',
        placeholder: 'https://www.google.com/'
    }
});

export const TextTypes = Object.freeze(
    {
        firstname: {
            name: 'Voornaam',
            type: 'firstname',
            placeholder: 'Voornaam'
        },
        lastname: {
            name: 'Achternaam',
            type: 'lastname',
            placeholder: 'Achternaam'
        },
        fullname: {
            name: 'Volledige naam',
            type: 'fullname',
            placeholder: 'Voornaam + Achternaam'
        },
        prefix_fullname: {
            name: 'Volledige naam 1',
            type: 'prefix_fullname',
            placeholder: 'VV + Voornaam + Achternaam'
        },
        prefix_fullname_appendix: {
            name: 'Volledige naam 2',
            type: 'prefix_fullname_appendix',
            placeholder: 'VV + Voornaam + Achternaam + BV'
        },
        fullname_appendix: {
            name: 'Volledige naam 3',
            type: 'fullname_appendix',
            placeholder: 'Voornaam + Achternaam + BV'
        },
        prefix_lastname_appendix: {
            name: 'Volledige naam 4',
            type: 'prefix_lastname_appendix',
            placeholder: 'VV + Achternaam + BV'
        },
        appendix: {
            name: 'Bijvoegsel',
            type: 'appendix',
            placeholder: 'BV'
        },
        prefix: {
            name: 'Voorvoegsel',
            type: 'prefix',
            placeholder: 'VV'
        },
        email: {
            name: 'E-mail',
            type: 'email',
            placeholder: 'emailadres@example.com'
        },
        telnumber: {
            name: 'Telefoonnummer',
            type: 'tel',
            placeholder: '+31 (0)80 234 56 79'
        },
        mobile: {
            name: 'Mobielnummer',
            type: 'mobile',
            placeholder: '+31 (0)6 12 34 56 79'
        },
        functiontitle: {
            name: 'Functietitel',
            type: 'jobtitle',
            placeholder: 'Functietitel'
        },
        quote: {
            name: 'Quote',
            type: 'quote',
            placeholder: 'Quote'
        },
        website: {
            name: 'Website',
            type: 'website',
            placeholder: 'https://www.example.com/'
        },
        address: {
            name: 'Adresveld',
            type: 'adresveld',
            placeholder: 'Straatnaam 1\n1234 AA\nStad'
        },
        companyname: {
            name: 'Bedrijfsnaam',
            type: 'companyname',
            placeholder: 'Bedrijf B.V.'
        },
        extratext1: {
            name: 'Extratext1',
            type: 'extratext1',
            placeholder: 'extratext1'
        },
        extratext2: {
            name: 'Extratext2',
            type: 'extratext2',
            placeholder: 'extratext2'
        },
        extratext3: {
            name: 'Extratext3',
            type: 'extratext3',
            placeholder: 'extratext3'
        },
        extratext4: {
            name: 'Extratext4',
            type: 'extratext4',
            placeholder: 'extratext4'
        },
        // translation: {
        //     name: 'Vertaling',
        //     type: 'translation',
        //     placeholder: 'NL'
        // }
    });

import React, {ReactElement} from 'react';
import '../Icon.scss';
import IconProps from "../IconProps.interface";

const LetUsDesignIconBackOffice = (props: IconProps) : ReactElement => {

    return (
        <div className={`icon ${props.additionalClass}`}>
            <svg fill={props.color} version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">

                <path id="Layer" className="s0"
                      d="m23.1 48.3c-0.1 0.4 0 0.8 0.2 1.1q0.1 0.4 0.3 0.8l-3.9-0.1c-0.4 0-0.7-0.4-0.7-0.9l-1.3-4.1q0-0.1 0-0.1-0.7-0.3-1.5-0.7l-3.1 1.9c-0.3 0.4-0.8 0.4-1.1 0.1l-3.7-4.1c-0.3-0.4-0.3-1 0-1.3l1.4-4q0.1-0.1 0.1-0.2-0.3-0.8-0.6-1.7 0 0 0 0l-3.4-1.4c-0.5 0-0.8-0.4-0.8-0.9v-6.1c0-0.5 0.4-0.9 0.8-0.9l3.2-1.4q0.3-1.1 0.8-2.2l-1.5-3.6c-0.3-0.3-0.3-0.9 0-1.3l3.5-4.2c0.3-0.4 0.8-0.4 1.1 0l3.1 1.6q0.9-0.6 1.9-1l1.1-3.7c0-0.5 0.3-0.9 0.7-0.9h5.1c0.4 0 0.7 0.4 0.7 0.9l1.2 3.7q1 0.4 1.9 1l2.8-1.9c0.3-0.3 0.8-0.4 1.1 0l3.8 4c0.3 0.4 0.3 0.9 0 1.3l-1.3 4q-0.1 0.1-0.1 0.1 0.3 0.9 0.6 1.7 0 0 0 0l1 0.4-6.9 8.3q0.4-1.5 0.4-3.1c0-5.1-3.4-9.2-7.7-9.2-4.2 0-7.7 4.1-7.7 9.2 0 5 3.5 9.1 7.7 9.1 1.2 0 2.3-0.3 3.3-0.9l-2.3 9.8c0 0-0.1 0.1-0.1 0.1l-0.1 0.8z"/>
                <path id="Layer" className="s0"
                      d="m28.8 37.1c-0.3-0.4-0.3-0.9 0-1.3l18-21.4c0.3-0.4 0.8-0.4 1.1 0l6.5 7.7c0.3 0.4 0.3 1 0 1.3l-18 21.4c-0.3 0.4-0.8 0.4-1.1 0z"/>
                <path id="Layer" className="s0" d="m24.8 48.3l2.4-9.6 7.2 8.5-7.9 2.7c-0.9 0.2-1.9-0.6-1.7-1.6z"/>
            </svg>
        </div>
    )
};

export default LetUsDesignIconBackOffice;

export const FontSize = [2, 4, 8, 16, 18, 20, 22, 24, 26, 28, 30, 32, 40, 48, 56, 72];

export const FontFamily = ['OpenSans', "OpenSans-Light", 'Arial', 'BebasNeue', 'Calibri', 'NeoTechStd', 'Helvetica', 'Roboto', "Montserrat", 'Fira sans', 'Museo', 'Campton', 'Asap', "EBGaramond", "PlayfairDisplay", "Lexend", "BeyondInfinity", "Aeonik", "Dassans", "ProximaNova","Poppins","Barlow"];

export const TooltipItems = Object.freeze({
    FONTSIZE: "fontSize",
    FONTFAMILY: "fontFamily",
    FONTCOLOR: "fontColor",
    BACKGROUNDCOLOR: "backgroundColor",
    LINK: "link",
    TYPE: "type",
    POSITION: "position",
    ROUND: "ronding",
    DOTS: "dots"
});

export const LinkOptions = Object.freeze({
    LinkedIn: "linkedin",
    Facebook: "facebook",
    Twitter: "twitter",
    Instagram: "instagram",
    Pinterest: "pinterest",
    Googlemaps: "googlemaps",
    Website: "website",
    Email: "email",
    Tel: "tel",
    Mobile: "mobile",
    Deel: "delen",
    Opslaan: 'opslaan',
    extralink1: 'extralink1',
    extralink2: 'extralink2',
    extralink3: 'extralink3',
    extralink4: 'extralink4',

});


import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getBizzerdById} from "../../actions/bizzerdActions";
import {getPersonByName} from "../../actions/personActions";
import DisplayBizzerd from '../bizzerddisplay/DisplayBizzerd';
import PropTypes from 'prop-types';
import '../oldbackoffice/scss/styles.scss'
import ShareBlock from "../common2/blocks/share.block";
import {getTranslationsByPerson} from "../../actions/translationActions";

class ShowBizzerd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: '',
            bizzerd: {},
            person: {},
            translations: [],
            currentTranslation: "",
            width: 0,
            height: 0
        }
    }

    componentDidMount() {
        if (this.props.match.params.company !== undefined && this.props.match.params.name !== undefined) {
            this.props.getPersonByName(this.props.match.params.company, this.props.match.params.name)
            this.props.getTranslationsByPerson(this.props.match.params.company, this.props.match.params.name)
        } else {
            this.setState({
                errors: 'Persoon of bedrijf is niet gevonden'
            });
        }
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    calculateDisplayWidth = () => {
        if (this.state.bizzerd) {
            if (Object.keys(this.state.bizzerd).length === 0) {
                return 0
            } else {
                if (this.state.width < this.state.height - 300) {
                    return this.state.width - 30;
                }

                return (this.state.bizzerd.background[0].width) * this.state.height /
                    this.state.bizzerd.background[0].height - 80;
            }
        } else {
            return 0
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.person.person !== this.props.person.person) {
            this.setState({
                person: nextProps.person.person
            });
            document.title = (nextProps.person.person.fullname ? nextProps.person.person.fullname : "Bekijken") + " | Bizzerd"
            window.gtag("event", "view_card", {fullname: nextProps.person.person.fullname ? nextProps.person.person.fullname : "Bekijken"})

            this.props.getBizzerdById(nextProps.person.person.bizzerd);
        } else if (nextProps.bizzerd.bizzerd !== this.props.bizzerd.bizzerd) {
            this.setState({
                bizzerd: nextProps.bizzerd.bizzerd
            })
        }
        if (nextProps.translations && nextProps.translations.translations && this.state.translations !== nextProps.translations.translations) {
            this.setState({
                translations: nextProps.translations.translations,
            })
            if (nextProps.translations.translations[0] && nextProps.translations.translations[0].hasOwnProperty("abbreviation") && this.state.currentTranslation !== nextProps.translations.translations[0].abbreviation) {
                this.changeTranslation(nextProps.translations.translations[0].abbreviation, nextProps.translations.translations)
            }
        }
        if (nextProps.errors.errors) {
            this.setState({errors: nextProps.errors.errors})
        }
    }

    updateWindowDimensions = () => {
        this.setState({width: window.visualViewport.width, height: window.visualViewport.height});
    };

    changeTranslation = (lang, translations = undefined) => {
        if (!translations) {
            translations = this.state.translations
        }
        this.setState({currentTranslation: lang})
        let person = translations.filter(trans => trans.abbreviation === lang) ? translations.filter(trans => trans.abbreviation === lang)[0] : this.state.person
        this.props.getBizzerdById(person.bizzerd)
    }

    render() {
        let width = this.calculateDisplayWidth();
        let page;
        let person = this.state.person
        if (this.state.currentTranslation) {
            person = this.state.translations.filter(trans => trans.abbreviation === this.state.currentTranslation) ? this.state.translations.filter(trans => trans.abbreviation === this.state.currentTranslation)[0] : person
        }
        if (this.state.errors) {
            width = this.state.width / 2;
            page = <h2>{this.state.errors}</h2>;
        } else if (this.props.match.params.page === undefined) {
            page = <DisplayBizzerd shouldBeClickable={true} realWidth={width} bizzerdLayout={this.state.bizzerd}
                                   personContent={person} changeTranslation={this.changeTranslation}/>;
        } else if (this.props.match.params.page === 'deel') {
            page = <ShareBlock realWidth={width} bizzerdLayout={this.state.bizzerd}
                               personContent={this.state.person}/>
        }
        return (
            <div id={"backoffice"}
                 style={{
                     display: 'block',
                     width: width,
                     minHeight: "95vh",
                     margin: this.state.width > 768 ? 'auto' : "auto",
                     paddingTop: 20,
                     backgroundColor: "#f7f7f7"
                 }}>
                {page}
            </div>
        )
    }
}

ShowBizzerd.propTypes = {
    person: PropTypes.object.isRequired,
    bizzerd: PropTypes.object.isRequired,
    getBizzerdById: PropTypes.func.isRequired,
    getPersonByName: PropTypes.func.isRequired,
    getTranslationsByPerson: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    person: state.persons,
    bizzerd: state.bizzerd,
    translations: state.translations,
    errors: state.errors
});

export default connect(mapStateToProps, {getBizzerdById, getPersonByName, getTranslationsByPerson})(ShowBizzerd);
import React from "react";
import './header.styles.css'
import ButtonColors from "../buttons/button.colors";
// @ts-ignore
// @ts-ignore
// @ts-ignore
// @ts-ignore

// @ts-ignore
import PageTitle from "../fields/text/pagetitle.text";
import MainButton from "../buttons/action_main/main.button";
// @ts-ignore
import {translate} from "react-i18nify";
import PageSubTitle from "../fields/text/pagesubtitle.text";

export const ProductHeader = () => {
    return <div className={"product-header"}>
        <div className={"product-header-left"}>
            <PageSubTitle text={translate('hd.product.pageSubTitle')}/>
            <PageTitle style={{marginTop: 10}} text={translate("hd.product.pageTitle")}/>
            <span>{translate("hd.product.pageContent1")}</span>
            <br/>
            <span>{translate("hd.product.pageContent2")}</span>
            <MainButton style={{marginBottom: 10, marginTop: 10}} color={ButtonColors.orange}
                        text={translate("hd.actions.contactSales")}/>
            <MainButton color={ButtonColors.white} text={translate("hd.actions.tryForFree")}/>
        </div>
        <div className={"product-header-right"}>
            <img className={"product-header-img"} src={"/product/header.svg"}/>
        </div>
    </div>
}


// class ProductHeader extends React.Component<> {
//     render() {
//         // return (<div className={"enterprise-header-container"}>
//         //     <div className={"product-header-left"}>
//         //         <PageTitle text={translate("hd.product.pageTitle")}
//         //                    style={{
//         //                        fontSize: "36px",
//         //                        marginBottom: "15px"
//         //                    }}/>
//         //         <span className={"enterprise-header-usp-item"}>{translate("hd.product.pageContent1")}</span>
//         //         <br/>
//         //
//         //         <span className={"enterprise-header-usp-item"}>{translate("hd.product.pageContent2")}</span>
//         //         <br/>
//         //         <div className={"enterprise-usp-container"}>
//         //             {/*<div className={"enterprise-header-usp"}>*/}
//         //
//         //
//         //             {/*    */}
//         //             {/*</div>*/}
//         //             <div className={"enterprise-header-usp"}>
//         //
//         //                 <div style={{
//         //                     display: "flex",
//         //                     flexDirection: "row",
//         //                     alignItems: "center",
//         //                     paddingBottom: 7
//         //                 }}><span
//         //                     className={"check-mark"}>✔︎</span>
//         //                     <a className={"enterprise-header-usp-item-link"}
//         //                        href="#nfcBlock">NFC</a>
//         //                 </div>
//         //                 <div style={{
//         //                     display: "flex",
//         //                     flexDirection: "row",
//         //                     alignItems: "center",
//         //                     paddingBottom: 7
//         //                 }}><span
//         //                     className={"check-mark"}>✔︎</span>
//         //                     <a className={"enterprise-header-usp-item-link"}
//         //                        href="#insightBlock">{translate("hd.product.insight")}</a>
//         //                 </div>
//         //
//         //                 <div style={{
//         //                     display: "flex",
//         //                     flexDirection: "row",
//         //                     alignItems: "center",
//         //                     paddingBottom: 7
//         //                 }}><span
//         //                     className={"check-mark"}>✔︎</span>
//         //                     <a className={"enterprise-header-usp-item-link"}
//         //                        href="#dashboardBlock">{translate("hd.product.dashboard")}</a>
//         //                 </div>
//         //
//         //
//         //             </div>
//         //             <div className={"enterprise-header-usp"} style={{marginLeft: 20}}>
//         //                 <div style={{
//         //                     display: "flex",
//         //                     flexDirection: "row",
//         //                     alignItems: "center",
//         //                     paddingBottom: 7
//         //                 }}><span
//         //                     className={"check-mark"}>✔︎</span>
//         //                     <a className={"enterprise-header-usp-item-link"}
//         //                        href="#interactiveBlock">{translate("hd.product.interactive")}</a>
//         //                 </div>
//         //
//         //
//         //                 <div style={{
//         //                     display: "flex",
//         //                     flexDirection: "row",
//         //                     alignItems: "center",
//         //                     paddingBottom: 7
//         //                 }}><span
//         //                     className={"check-mark"}>✔︎</span>
//         //                     <a className={"enterprise-header-usp-item-link"}
//         //                        href="#appBlock">{translate("hd.product.app")}</a>
//         //                 </div>
//         //             </div>
//         //         </div>
//         //         <div className={"enterprise-header-buttons"}
//         //              style={this.props.width > 768 ? {width: "100%"} : {width: "100%", alignItems: "center"}}>
//         //             <MainButton color={ButtonColors.orange} href={translate("hd.links.contact")}
//         //                         style={this.props.width > 768 ? {marginRight: "10px", marginTop: "20px"} : {width:"100%",}}
//         //                         text={translate("hd.actions.contactSales")}/>
//         //
//         //         </div>
//         //     </div>
//         //     <div className={"product-header-right"}>
//         //         <img style={{width: "80%", minWidth: "300px"}} src={landing}/>
//         //     </div>
//         //
//         // </div>)
//
//     }
//
// }

export default ProductHeader;
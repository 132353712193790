import {CookieConsent} from "react-cookie-consent";
import React from "react";
import ReactGA from "react-ga";

export const cookieConsent = () => {
    return <CookieConsent
        location="bottom"
        buttonText="Alle cookies accepteren"
        declineButtonText={"Optionele cookies afwijzen"}
        cookieName="CookieConsent"
        enableDeclineButton={true}
        onAccept={() => {
            if (!window.location.href.includes("localhost")) {
                ReactGA.initialize('UA-73972616-6');
                ReactGA.plugin.require("ecommerce");
                document.cookie = "CookieConsent=true"
                sessionStorage.setItem("UAinitialised", true)
            }
        }
        }
        style={{
            background: "#F2F2F2",
            color: "#000",
            textAlign: "left",
            display: "flex",
            alignItems: "center"
        }}
        buttonStyle={{
            color: "#FFF",
            fontSize: "26px",
            borderRadius: "10px",
            fontWeight: "bold",
            backgroundColor: "#FAA634"
        }}
        declineButtonStyle={{
            color: "#FAA634",
            fontSize: "13px",
            borderRadius: "10px",
            backgroundColor: "#FFF"
        }}
        overlay
        acceptOnOverlayClick={true}
    >
        Door op “Alle cookies accepteren” te klikken gaat u akkoord met het opslaan van cookies op uw
        apparaat voor het analyseren van websitegebruik.{" "}
    </CookieConsent>
}
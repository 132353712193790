import React from "react";
import PageTitle from "../fields/text/pagetitle.text";
import {translate} from "react-i18nify";
// @ts-ignore
import {default as nfcGraphic} from "../../../assets/nfc/graphic.nfc.png"
import SubTitle from "../fields/text/subtitle.text";

interface homeHeaderProps {
    width: number,
}

class NFCBlock extends React.Component<homeHeaderProps, {}> {
    render() {
        return (<div className={"enterprise-header-container"} style={{paddingTop: 20, color: "#324A6D"}}>
            <div className={"product-header-left"}>
                <PageTitle text={translate("hd.nfc.blockTitle")}
                           style={{
                               fontSize: "36px",
                               marginBottom: "15px"

                           }}/>
                <span className={"enterprise-header-usp-item"}>{translate("hd.nfc.blockContent1")}</span>
                <br/>
                <span className={"enterprise-header-usp-item"}>{translate("hd.nfc.blockContent2")}</span>
                <br/>

                {/*<div style={{*/}
                {/*    margin: 10,*/}
                {/*    marginLeft: 0,*/}
                {/*    padding: 15,*/}
                {/*    paddingRight: 30,*/}
                {/*    borderRadius: 10,*/}
                {/*    border: "1px solid #F7A823"*/}
                {/*}}>*/}
                {/*    <SubTitle text={translate("hd.nfc.saleTitle")}/>*/}

                {/*    <br/>*/}
                {/*    <br/>*/}
                {/*    /!*<span>{translate("hd.nfc.saleContent")}</span>*!/*/}
                {/*</div>*/}


                <PageTitle text={translate("hd.nfc.blockTitle2")}/>
                <span className={"enterprise-header-usp-item"}>{translate("hd.nfc.blockContent3")}</span>


            </div>
            <div className={"enterprise-header-right"} style={{width: this.props.width > 768 ? "60%" : "100%"}}>
                <div className={"product-header-right"}>
                    <img style={{maxHeight: 600}} src={nfcGraphic}/>
                </div>
            </div>

        </div>)

    }

}

export default NFCBlock;
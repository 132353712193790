import React from 'react'
import GrayContainer from "../common2/containers/gray.container";
import NavigationHeader from "../common2/headers/navigation.header";
import GradientContainer from "../common2/containers/gradient.container";
import PageTitle from "../common2/fields/text/pagetitle.text";
import RegisterBlock from "../common2/blocks/register.block";
import Footer from "../common2/footers/footer";
import {translate} from "react-i18nify";

interface pageProps {
    width: number
}

class RegisterPage extends React.Component<pageProps, {}> {
    render() {
        return (<><GrayContainer style={{paddingBottom: "40px"}}>
            <NavigationHeader width={this.props.width}/>
        </GrayContainer>
        <GradientContainer>
            <PageTitle text={translate("hd.register.pageTitle")}/>
            <RegisterBlock width={this.props.width}/>
        </GradientContainer>
        <Footer/></>);
    }
}

export default RegisterPage
import GradientContainer from "../common2/containers/gradient.container";
import GrayContainer from "../common2/containers/gray.container";
import NavigationHeader from "../common2/headers/navigation.header";
import ContactBlock from "../common2/blocks/contact.block";
import Footer from "../common2/footers/footer";
import React from "react";
import PageTitle from "../common2/fields/text/pagetitle.text";

export default function AndroidPrivacyPolicy({width}) {

    return (<>
            <GrayContainer style={{
                paddingBottom: width < 768 ? "0px" : "40px",
                background: width < 768 ? "linear-gradient(12deg, #ffffff10 57%, #42809620 76%)" : "linear-gradient(6deg, #ffffff10 57%, #42809620 76%)"
            }}>
                <NavigationHeader width={width}/>
                <div style={{width: "70%", maxWidth: 1000, textAlign: "left"}}>
                    <PageTitle text={"Privacy Statement"}/>
                    <h2>bizzerd bv</h2>
                    <p>2023</p>

                    <h3>Waarom dit statement?</h3>
                    <p>Door de aard van onze dienstverlening beschikken we bij bizzerd over bepaalde gegevens van jou,
                        bijvoorbeeld de gegevens die je vermeldt op je digitale visitekaartje. We gaan daar zeer
                        zorgvuldig mee
                        om en we zorgen ervoor dat elke verwerking van jouw gegevens voldoet aan de wet- en regelgeving.
                        Deze privacy informatie is van toepassing op alle gegevens die bizzerd van jou verwerkt in het
                        kader van
                        de diensten die bizzerd in Nederland op de markt brengt.</p>

                    <h3>Wie verwerkt jouw gegevens?</h3>
                    <p>De verantwoordelijke voor de verwerking van jouw persoonsgegevens is bizzerd bv, Rembrandtlaan
                        24,
                        3723 BJ in Bilthoven.</p>

                    <h3>Om welke gegevens gaat het?</h3>
                    <p>Persoonsgegevens: Als je een overeenkomst afsluit met bizzerd, en daarmee klant/afnemer wordt,
                        verwerkt bizzerd jouw persoonsgegevens. Dit zijn gegevens die zijn te herleiden tot individuele
                        natuurlijke personen. Dat zijn bijvoorbeeld de adresgegevens die je vermeldt op je digitale
                        visitekaartje.
                        Als je een account aanmaakt bij bizzerd dan word je daarnaast nog gevraagd om een gebruikersnaam
                        en password op te geven. Al deze gegevens worden door bizzerd verwerkt.</p>

                    <h3>Verkeersgegevens:</h3>
                    <p>Als je gebruik maakt van de diensten van bizzerd, verwerkt bizzerd ook verkeersgegevens
                        die verband houden met dit gebruik. Dit betreft bijvoorbeeld de contacten met wie jij jouw
                        digitale visitekaartje deelt.</p>

                    <h3>Geen verstrekking aan derden</h3>
                    <p>bizzerd verwerkt persoons- en verkeersgegevens enkel en alleen om diensten aan consumenten en
                        afnemers te kunnen leveren. We verstrekken geen gegevens aan derden.</p>

                    <h3>Bewaartermijn</h3>
                    <p>bizzerd bewaart je gegevens niet langer dan wettelijk is toegestaan en noodzakelijk is voor de
                        verwerkelijking
                        van de doeleinden waarvoor je gegevens worden verwerkt. Als je een overeenkomst hebt met
                        bizzerd, dan worden je persoonsgegevens gedurende de looptijd van de overeenkomst bewaard.</p>

                    <p>Bilthoven, 2023<br/>
                        bizzerd bv</p>
                </div>
            </GrayContainer>
            <Footer/>
        </>

    );
}
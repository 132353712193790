import React from 'react'
import GrayContainer from "../common2/containers/gray.container";
import NavigationHeader from "../common2/headers/navigation.header";
import GradientContainer from "../common2/containers/gradient.container";
import PageTitle from "../common2/fields/text/pagetitle.text";
import Footer from "../common2/footers/footer";
import {translate} from "react-i18nify";
import UploadBlock from "../common2/blocks/upload.block";

interface pageProps {
    width: number
}

class UploadPage extends React.Component<pageProps, {}> {
    render() {
        return (<><GrayContainer style={{paddingBottom: "40px"}}>
            <NavigationHeader width={this.props.width}/>
        </GrayContainer>
            <GradientContainer>
                <PageTitle text={translate("hd.uploadBlock.pageTitle")}/>
                <UploadBlock width={this.props.width}/>
            </GradientContainer>
            <Footer/></>);
    }
}

export default UploadPage
export const determineTopBackgroundColor = (template) => {
    for (const shapeElement of template.shape) {
        if (shapeElement.topBackgroundColor) {
            return shapeElement.backgroundColor
        }
    }
}

export const determineFont = (template) => {
    for (const textElement of template.text) {
        if (textElement.font) {
            return textElement.font
        }
    }
    return "OpenSans"
}

export const determineTitleColor = (template) => {
    for (const textElement of template.text) {
        if (textElement.type === "fullname") {
            return textElement.color
        }
    }
    return "#000000FF"
}

export const determineJobTitleColor = (template) => {
    for (const textElement of template.text) {
        if (textElement.type === "jobtitle") {
            return textElement.color
        }
    }
    return "#000000FF"
}

export const determineUserInfoTextColor = (template) => {
    for (const textElement of template.text) {
        if (textElement.type !== "jobtitle" || textElement.type !== "fullname") {
            return textElement.color
        }
    }
    return "#000000FF"
}

export const determineBackgroundColor = (template) => {
    for (const shapeElement of template.shape) {
        if (shapeElement.left === 40) {
            return shapeElement.backgroundColor
        }

    }
    return "#f5f5f5FF"
}

export const determineIconColor = (template) => {
    for (const iconElement of template.icon) {
        if (iconElement.type !== "opslaan" || iconElement.type !== "delen") {
            return iconElement.backgroundColor
        }
    }
    return "#000000FF"
}

export const determineSocialMediaButtonColor = (template) => {
    for (const iconElement of template.icon) {
        if (iconElement.type !== "opslaan" || iconElement.type !== "delen") {
            return iconElement.backgroundColor
        }
    }
    return "#000000FF"
}

export const determineShareSaveButtonColor = (template) => {
    for (const iconElement of template.icon) {
        if (iconElement.type === "opslaan" || iconElement.type === "delen") {
            return iconElement.backgroundColor
        }
    }
    return "#000000FF"
}

export const determineLogoPosition = (template) => {
    let logo = template.image.filter(image => image.logo)[0]
    switch (logo.left) {
        case 10:
            return "left"
        case 640 - logo.width - 10:
            return "right"
        default:
            return "center"
    }
}

export const determineLogoSize = (template, width, height) => {
    let logo = template.image.filter(image => image.logo)[0]
    return Math.round(logo.width * 100 / width)

}

import axios from "axios";
import React from "react";

export const uploadImageFlow = async (formData, type, template, setTemplate, logoPosition, setDefaultLogoWidth, setDefaultLogoHeight) => {
    setTemplate({
        ...template, image: template.image.map(image => {
            let newImage = {...image}
            if (type === "logo") {
                if (newImage.logo) {
                    newImage.image = "/ezgif-4-09613decb1.gif"
                }
            } else {
                if (newImage.backgroundTop) {
                    newImage.image = "/ezgif-4-09613decb1.gif"
                }
            }
            return newImage
        })
    })
    const response = await axios.post(`/api/person/photo`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
    let newWidth = response.data.width
    let newHeight = response.data.height
    const logoPositions = {
        left: (image) => 40,
        center: (image) => (640 / 2) - (image.width / 2),
        right: (image) => 640 - image.width - 40
    }
    setTemplate({
        ...template, image: template.image.map(image => {
            let newImage = {...image}
            if (type === "logo") {
                if (newImage.logo) {
                    newImage.image = response.data.url;
                    if (newWidth > 400 || newHeight > 200) {
                        let aspectRatio = newWidth / newHeight;
                        if (newWidth > 400) {
                            newWidth = 400;
                            newHeight = newWidth / aspectRatio;
                        }
                        if (newHeight > 200) {
                            newHeight = 200;
                            newWidth = newHeight * aspectRatio;
                        }
                    }
                    newImage.width = Math.round(newWidth)
                    newImage.height = Math.round(newHeight)
                    setDefaultLogoHeight(Math.round(newHeight))
                    setDefaultLogoWidth(Math.round(newWidth))
                    newImage.left = logoPositions[logoPosition](newImage)
                    newImage.top = (340/2) - (newImage.height / 2)
                }
            } else {
                if (newImage.backgroundTop) {
                    newImage.image = response.data.url
                }
            }
            return newImage
        })
    })


}

export const PlanFeature = ({feature}) =>{
    return <div className={"feature-row"}>
        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.27544 10.0084L11.4579 0.430317C11.7525 -0.0261272 12.3325 -0.137057 12.7532 0.182549C13.174 0.502155 13.2763 1.13127 12.9816 1.58771L6.04654 12.3318C5.75191 12.7882 5.17197 12.8992 4.75119 12.5795C4.67177 12.5192 4.60369 12.4479 4.54748 12.3687C4.50706 12.3257 4.46969 12.2781 4.43604 12.226L0.168282 5.61424C-0.126345 5.15779 -0.0240852 4.52868 0.396686 4.20907C0.817457 3.88947 1.3974 4.0004 1.69203 4.45684L5.27544 10.0084Z" fill="#428096"/>
        </svg>
        <span className={"feature-title"}>{feature}</span>

    </div>

}
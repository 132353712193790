import React from 'react'
import GradientContainer from "../common2/containers/gradient.container";
import PageTitle from "../common2/fields/text/pagetitle.text";
import ContactBlock from "../common2/blocks/contact.block";
import Footer from "../common2/footers/footer";
import NavigationHeader from "../common2/headers/navigation.header";
import GrayContainer from "../common2/containers/gray.container";
import {translate} from "react-i18nify";
import NFCHeader from "../common2/headers/nfc.header";
import IconsBlock from "../common2/blocks/icons.block";
import NFCBlock from "../common2/blocks/nfc.block";

interface pageProps {
    width: number
}

class NFCPage extends React.Component<pageProps, {}> {
    render() {
        return (<>
            <GrayContainer style={{
                paddingBottom: this.props.width < 768 ? "0px" : "40px",
                borderRadius: 0,
                color: "#324A6D",
                background: this.props.width < 768 ? "linear-gradient(12deg, #ffffff10 57%, #42809620 76%)" : "linear-gradient(to bottom, #eaf2f6, #eff4f8, #f3f6fa, #f7f9fc, #fbfbfd, #fbfbfd, #fbfbfd, #fbfbfd, #f7f9fc, #f3f6fa, #eff4f8, #eaf2f6)"
            }}>
                <NavigationHeader width={this.props.width}/>
                <NFCHeader width={this.props.width}/>
            </GrayContainer>

            <NFCBlock width={this.props.width}/>
            <div style={{backgroundColor: "#42809620"}}>
                <GradientContainer reverse={true}
                                   style={{
                                       background: "linear-gradient(180deg, #EAF2F6 0%, #FFF 100%)",
                                       borderRadius: this.props.width < 768 ? "0px 0px 50px 50px" : "0px 0px 130px 130px"
                                   }}>
                    <IconsBlock width={this.props.width}/>
                </GradientContainer>
            </div>

            <Footer/></>);
    }
}

export default NFCPage
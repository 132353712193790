import React from "react";
import ButtonColors from "../button.colors";
import '../button.styles.css'

// @ts-ignore
import {default as arrow} from "../../../../assets/blocks/step.arrow.png"
import {Link} from "react-router-dom";

export interface buttonProps {
    color: ButtonColors,
    onClick?: () => void,
    text: string,
    arrow?: boolean,
    style?: {},
    href?: string,
    newPage?: boolean,
}

class SecondaryButton extends React.Component<buttonProps, {}> {
    render() {
        if (this.props.href && this.props.newPage) {
            return <a style={this.props.style} className={"secondary-button " + ButtonColors[this.props.color]}
                      href={this.props.href}
                      target={this.props.newPage ? "_blank" : ""}>{this.props.text}{this.props.arrow ?
                <img src={arrow} className={"step-arrow"}/> : <></>}</a>
        }
        if (this.props.href) {
            return <Link style={this.props.style} className={"secondary-button " + ButtonColors[this.props.color]}
                         to={this.props.href}>{this.props.text}{this.props.arrow ?
                <img src={arrow} className={"step-arrow"}/> : <></>}</Link>
        }
        return <button style={this.props.style} className={"secondary-button " + ButtonColors[this.props.color]}
                       onClick={this.props.onClick}>{this.props.text}{this.props.arrow ?
            <img src={arrow} className={"step-arrow"}/> : <></>}</button>
    }

}

export default SecondaryButton;
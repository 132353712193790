import React from "react";
import './container.styles.css'


export interface containerProps {
    children?: Array<any>,
}

class FaqContainer extends React.Component<containerProps, {}> {
    render() {

        return <div className={"container-faq"}>
            {this.props.children}
        </div>;
    }

}

export default FaqContainer;
import React from 'react'
import {connect} from "react-redux";
import {initiatePayment, updateSlackOnPayment} from "../../actions/paymentActions";
import {sendUpdate} from "../../actions/userActions";
import {finishRegistration} from "../../actions/companyActions";

interface pageProps {
    width: number
    initiatePayment: (user, company, description, returnUrl, webhook, total_price, duration, code?) => void,
    updateSlackOnPayment: (companyName, totalPrice, domain, bizzerdName, nfc) => void,
    sendUpdate: (nfc) => void,
    finishRegistration: (user, bizzerd, person) => void,
    company: any,
    auth: any,
    payment: any,

}

interface paymentState {
    startedPayment: boolean,
    bizzerdName: string,
}


class PaymentPage extends React.Component<pageProps, paymentState> {
    constructor(props) {
        super(props);
        let personString = localStorage.getItem("person")
        let bizzerdString = localStorage.getItem("bizzerd")
        let userString = localStorage.getItem("user")
        let person: any = {}
        let bizzerd: any = {}
        let user: any = {}
        if (personString)
            person = JSON.parse(personString)
        if (bizzerdString)
            bizzerd = JSON.parse(bizzerdString)
        if (userString)
            user = JSON.parse(userString)
        let duration: any = localStorage.getItem("duration")
        if (duration)
            duration = parseInt(duration)
        person.paid = true;
        person.selectedSubscription = duration
        person.bizzerdName = person.fullname.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replaceAll(/[^\w!?]/g, '');
        if (duration > -1)
            this.props.finishRegistration(user, bizzerd, person)
        this.state = {
            startedPayment: false,
            bizzerdName: person.bizzerdName
        }
    }

    componentWillReceiveProps(nextProps: Readonly<pageProps>, nextContext: any) {
        if (nextProps.payment.session._links !== undefined) {
            window.location.replace(nextProps.payment.session._links.checkout.href);
        }
        if (nextProps.company && nextProps.company.returnObject && !this.state.startedPayment) {

            let user = nextProps.company.returnObject.user;
            let company = nextProps.company.returnObject.company;
            let duration: any = localStorage.getItem("duration")
            if (duration)
                duration = parseInt(duration)
            let code = localStorage.getItem("code")
            if (code === "dr3zz1651t4rgn33l1wk1") {
                this.props.updateSlackOnPayment(company.name, 0)
                this.props.sendUpdate()
                window.location.href = "/dashboard/simple"
            } else {
                this.startPayment(user, company, duration, code)
                this.setState({startedPayment: true})
            }
        }
    }

    render() {
        return (<div
            style={{display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: "100vh"}}>
            <img src={"/ezgif-4-09613decb1.gif"}/>
        </div>);
    }

    startPayment = (user, company, duration, code) => {
        const nakedUrl = "https://bizzerd.com";
        const returnUrl = window.location.origin + "/dashboard/collegas/payment";
        const webhook = nakedUrl + '/api/payment/webhook';
        let description = "Single Purchase of " + duration + " year(s) bizzerd";
        let codePrice = 0;
        let packageCode;
        switch (code) {
            case "52e5d784-633e-4caa-b61f-f01a3f75232e":
                codePrice = 75
                packageCode = "Design individueel"
                break
            //Bizzerd design 125
            case "06b4a578-bd24-4b57-bb74-20ace189d575":
                packageCode = "Design klein"
                codePrice = 125
                break
            //Bizzerd design 250
            case "553f829e-ddff-441f-9b19-0926c11e138e":
                packageCode = "Design middel";
                codePrice = 250
                break
            //Bizzerd design 495
            case "c915939f-23ac-49da-8a3f-0694e89f9507":
                packageCode = "Design groot";
                codePrice = 495
                break
        }

        let price: number = [0, 0.79, 0.69, 0.59][duration] * (12 * duration) + codePrice;
        let btw: number = price * 0.21;
        let total_price = (price + btw).toFixed(2);
        if (total_price) {
            if (code)
                this.props.initiatePayment(user, company, description, returnUrl, webhook, total_price, duration, packageCode)
            else
                this.props.initiatePayment(user, company, description, returnUrl, webhook, total_price, duration)

            this.props.updateSlackOnPayment(company.name, total_price, company.domain, this.state.bizzerdName, localStorage.getItem("nfc"))
            this.props.sendUpdate(localStorage.getItem("nfc"))
        }
    }
}


const
    mapStateToProps = (state) => ({
        company: state.company,
        auth: state.auth,
        payment: state.payment,
    });

export default connect(mapStateToProps, {
    initiatePayment,
    sendUpdate,
    updateSlackOnPayment,
    finishRegistration

})(PaymentPage);
import React from "react";
import RoundBizzerdContainer from "../containers/round.bizzerd.container";
import {translate} from "react-i18nify";

interface shoppingCartItemProps {
    width: number,
    personContent?: any,
    bizzerdLayout?: any,
    duration?: number,
    code?: string,
}

class ShoppingCartItem extends React.Component<shoppingCartItemProps, {}> {
    render() {
        if (this.props.code) {
            let codeTitle;
            let codePrice;
            switch (this.props.code) {
                //Bizzerd design 75
                case "52e5d784-633e-4caa-b61f-f01a3f75232e":
                    codeTitle = "Bizzerd design klein"
                    codePrice = "€75,-"
                    break
                //Bizzerd design 125
                case "06b4a578-bd24-4b57-bb74-20ace189d575":
                    codeTitle = "Bizzerd design klein"
                    codePrice = "€125,-"
                    break
                //Bizzerd design 250
                case "553f829e-ddff-441f-9b19-0926c11e138e":
                    codeTitle = "Bizzerd design middel"
                    codePrice = "€250,-"
                    break
                //Bizzerd design 495
                case "c915939f-23ac-49da-8a3f-0694e89f9507":
                    codeTitle = "Bizzerd design groot"
                    codePrice = "€495,-"
                    break
                //Gratis bizzerd
                case "dr3zz1651t4rgn33l1wk1":
                    codeTitle = "Gratis Bizzerd"
                    codePrice = "€0,-"
                    break
                default:
                    break
            }

            return <div className={"shoppingcart-item"}>
                <div className={"shoppingcart-item-left"}>
                <span
                    className={"shoppingcart-item-text-bold"}>{codeTitle}</span>
                    <span>{translate("hd.shoppingCart.card")} {this.props.personContent.fullname}</span>
                </div>
                <div className={"shoppingcart-item-right"}>
                    <span className={"shoppingcart-item-text-bold"}
                          style={{whiteSpace: "nowrap"}}>{codePrice}</span>
                </div>
            </div>
        }
        return (<div className={"shoppingcart-item"}>
            <div className={"shoppingcart-item-left"}>
                <span
                    className={"shoppingcart-item-text-bold"}>{translate("hd.shoppingCart.digitalCardNormal")} {this.props.duration} {translate("hd.shoppingCart.year")}</span>
                <span>1 x {translate("hd.shoppingCart.card")} {this.props.personContent.fullname}</span>
            </div>
            <div className={"shoppingcart-item-middle"}>
                <RoundBizzerdContainer drawCircle={false}
                                       steps={[100, 160, 160, 160]}
                                       width={this.props.width}
                                       style={{marginBottom: this.props.width < 768 ? "20px" : "80px"}}
                                       personContent={this.props.personContent}
                                       bizzerdLayout={this.props.bizzerdLayout}/>
            </div>
            <div className={"shoppingcart-item-right"}>
                <span className={"shoppingcart-item-text-bold"}
                      style={{whiteSpace: "nowrap"}}>€ {[0, 0.79, 0.69, 0.59][this.props.duration]} p.m.</span>
                <span className={"shoppingcart-item-text-bold"}
                      style={{whiteSpace: "nowrap"}}>€ {[0, 0.79, 0.69, 0.59][this.props.duration] * 12} {translate("hd.shoppingCart.py")}</span>
            </div>
        </div>)
    }
}

export default ShoppingCartItem
import React from "react";
import './blocks.styles.css'
import ButtonColors from "../buttons/button.colors";
// @ts-ignore
import {default as EyeIcon} from "../../../assets/icons/eye.icon.svg"
// @ts-ignore
import {default as CloudIcon} from "../../../assets/icons/cloud.icon.svg"
// @ts-ignore
import {default as DesignIcon} from "../../../assets/icons/design.icon.svg"
// @ts-ignore
import {default as CardsBackground} from "../../../assets/containers/small.container.png"
import InputField from "../fields/editable_input/text.input";
import MainButton from "../buttons/action_main/main.button";
import {translate} from "react-i18nify";

export interface loginBlockProps {
    width: number
}

interface registerState {
    input: {
        email: string,
        password: string,
    }

}


class RegisterBlock extends React.Component<loginBlockProps, registerState> {
    constructor(props) {
        super(props);
        let personString = localStorage.getItem("person");
        let person: any = {};
        if (personString)
            person = JSON.parse(personString);
        this.state = {
            input: {
                email: person.email ? person.email : "",
                password: "",
            }
        }
        this.validateInput = this.validateInput.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    validateInput() {
        let valid = true;
        if (!this.state.input.email) {
            valid = false;
        }
        if (!this.state.input.password || this.state.input.password.length < 8) {
            valid = false;
        }

        if (valid) {
            localStorage.setItem("user", JSON.stringify(this.state.input))
            window.gtag("event", "add_user_info", {template_id2: this.uuidv4()})
            window.location.href = "/subscription"
        }
    }

    onChange(e) {
        this.setState({input: {...this.state.input, [e.target.id]: e.target.value}})
    }


    render() {
        return (
            <div className={"single-block-container"}>
                <div className={"login-block"}>
                    <span className={"register-header"}>{translate("hd.register.accountDetails")}</span>
                    <InputField placeholder={translate("hd.register.email")}
                                validation={"^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$"}
                                id={"email"}
                                label={translate("hd.register.email")}
                                errorMessage={translate("hd.errorMessages.invalidEmail")}
                                value={this.state.input.email}/>
                    <InputField placeholder={translate("hd.register.password")} validation={""} id={"password"}
                                password={true}
                                label={translate("hd.register.password")} errorMessage={""}
                                value={this.state.input.password}
                                onChange={this.onChange}/>
                    <div className={"login-buttons"} style={{justifyContent: "flex-end"}}>
                        <MainButton color={ButtonColors.orange} style={{width: "auto"}}
                                    onClick={this.validateInput}
                                    text={translate("hd.register.register")}/>
                    </div>
                </div>

            </div>
        );
    }

}

export default RegisterBlock;
import React, {Component, Suspense} from 'react';
import {Link, Redirect, Route, Switch} from 'react-router-dom';
import "../scss/styles.scss"
import "../../common2/fields/text.styles.css"
import {logoutUser} from "../../../actions/userActions";
import {getCurrentCompany} from "../../../actions/companyActions";
import {connect} from 'react-redux';
import Spinner from "../../../assets/img/Spinner.svg";
import Sidebar from "../layout/Sidebar";
import AddColleaguePicker from "../colleagues/AddColleaguePicker";
import axios from "axios";
import ReactGA from "react-ga";
import rg4js from "raygun4js";
import {ChoosePlan} from "../../noeditorflow/choose-plan";
import {InactivePayment} from "../../noeditorflow/inactive-payment";
import {PayForCards} from "../../noeditorflow/pay-for-cards";

const MultiEdit = React.lazy(() => import("../settings/MultiEdit"));
const Colleagues = React.lazy(() => import("../colleagues/Colleagues"));
const ColleaguesSimple = React.lazy(() => import("../colleagues/ColleaguesSimple"));
const ColleagueOverview = React.lazy(() => import("../colleagues/ColleagueOverview"));
const AddColleague = React.lazy(() => import("../colleagues/AddColleague"));
const DisplayBizzerd = React.lazy(() => import("../../bizzerddisplay/DisplayBizzerd"));
const ChooseDesign = React.lazy(() => import("../designtool/selectdesign/ChooseDesign"));
const SelectDesign = React.lazy(() => import("../colleagues/SelectDesign"));
const InviteColleagues = React.lazy(() => import("../colleagues/InviteColleagues"));
const GenerateLink = React.lazy(() => import("../colleagues/GenerateLink"));
const ChooseTemplate = React.lazy(() => import("../designtool/selectdesign/ChooseTemplate"));
const CompanySettings = React.lazy(() => import("../settings/CompanySettings"));
const WelcomeEmailSettings = React.lazy(() => import("../settings/WelcomeEmailSettings"));
const NfcSettings = React.lazy(() => import("../settings/NfcSettings"));
const PaymentSettings = React.lazy(() => import("../settings/PaymentSettings"));
const PermissionSettings = React.lazy(() => import("../settings/PermissionSettings"));
const RightsError = React.lazy(() => import('./../layout/RightsError'));
const Koppeling = React.lazy(() => import("../crmkoppeling/Koppeling"));
const Excellijst = React.lazy(() => import("../colleagues/Excellijst"));
const PaymentLandingpage = React.lazy(() => import("../colleagues/PaymentLandingpage"));
const CardLabeling = React.lazy(() => import("../colleagues/CardLabeling"));
const SubscriptionSettings = React.lazy(() => import("../settings/SubscriptionSettings"));
const RightsErrorWithoutDataEdit = React.lazy(() => import("../layout/RightsErrorWithoutDataEdit"));
const NavigationHeader = React.lazy(() => import("../../common2/headers/navigation.header"));
const GradientContainer = React.lazy(() => import("../../common2/containers/gradient.container"));
const Insights = React.lazy(() => import("../insights/Insights"));
const GenerateCards = React.lazy(() => import("../Internal/generatecards.page"));
const AnalysticsVisualisation = React.lazy(() => import("../Internal/visualisation.page"))
const Designtool2 = React.lazy(() => import("../designtool/tool/DesignTool2"));
const Invoice = React.lazy(() => import("../settings/Invoice"));

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: this.props.auth.user,
            company: {},
        }

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.user !== this.state.user) {
            this.setState({
                user: nextProps.auth.user
            });
        }
        if (nextProps.company && nextProps.company.company) {
            this.setState({company: nextProps.company.company})
        }
    }

    onLogoutClick = (e) => {
        e.preventDefault();
        this.props.logoutUser();
    };

    componentDidMount() {
        this.props.getCurrentCompany();
    }


    updateLocalTracking() {
        function uuidv4() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
                var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
                return v.toString(16);
            });
        }

        let userId = localStorage.getItem("uid")
        if (!userId) {
            userId = uuidv4()
            localStorage.setItem("uid", userId)
        }
        let paths = sessionStorage.getItem("paths")
        if (!paths) {
            paths = []
        } else {
            paths = JSON.parse(paths)
        }
        if (paths.length > 0 && paths[paths.length - 1].link !== window.location.href || paths.length === 0)
            paths.push({link: window.location.href, data: Date.now()})

        sessionStorage.setItem("paths", JSON.stringify(paths))
    }

    updateGA = () => {
        if (sessionStorage.getItem("UAinitialised")) {
            ReactGA.pageview(window.location.pathname + window.location.search);
            rg4js('trackEvent', {type: 'pageView', path: window.location.pathname});
        }

    }

    render() {
        this.updateLocalTracking()
        this.updateGA();
        let modal;
        let routes;
        let onSimpleDashboard = !window.location.href.includes('/ontwerpen/tool') && !window.location.href.includes('/view/analytics')
        let onPayment = window.location.href.includes("/dashboard/payment")
        if (this.state.company?.paymentDetails?.monthly || this.state.company?.paymentDetails?.year1 || this.state.company?.paymentDetails?.year2) {
            if (this.state.company.paymentDetails.paymentCompleted === false && !window.location.href.includes("/dashboard/payment")) {
                window.location.href = "/dashboard/payment"
            }
        }
        console.log(onSimpleDashboard, onPayment)
        if (this.state.user.superuser !== undefined) {
            if (this.state.user.superuser) {
                window.document.title = "Dashboard | Bizzerd"
                routes = (
                    <div className={'dashboard--wrapper'} id={'dashboard'}>

                        {modal}
                        {onSimpleDashboard && !onPayment ?
                            <Sidebar page={window.location.href} superuser={this.state.user.superuser}/>
                            :
                            <></>}
                        {this.state.company && this.state.company.inactive && !window.location.href.includes("/inactief")
                            && !window.location.href.includes("/dashboard/payment") &&
                            <div style={{
                                height: 40,
                                position: "fixed",
                                width: "100%",
                                zIndex: 99999,
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#F00",
                                color: "#FFF",
                                textAlign: "center"
                            }}><span>Je account is inactief klik <Link style={{color: "#FFF", fontWeight: "bold"}}
                                                                       to={"/dashboard/inactief"}>hier</Link>!</span>
                            </div>}
                        <Switch>

                            <Route path={`${this.props.match.path}/selectdesign/:id?`} component={SelectDesign}/>
                            <Route path={`${this.props.match.path}/collegas/`} exact>
                                <Redirect to={"/dashboard/simple"}/>
                            </Route>
                            <Route path={`${this.props.match.path}/payment/`}>
                                <PayForCards company={this.state.company}/>
                            </Route>
                            <Route path={`${this.props.match.path}/simple/`} exact component={ColleaguesSimple}/>
                            <Route path={`${this.props.match.path}/generate/cards/`} exact
                                   component={GenerateCards}/>
                            <Route path={`${this.props.match.path}/view/analytics/`} exact
                                   component={AnalysticsVisualisation}/>
                            <Route path={`${this.props.match.path}/colleague/overview/`} exact
                                   component={ColleagueOverview}/>
                            <Route path={`${this.props.match.path}/cardlabeling/`} exact component={CardLabeling}/>
                            <Route path={`${this.props.match.path}/collegas/importeren`} exact
                                   component={Excellijst}/>
                            <Route path={`${this.props.match.path}/collegas/overzicht`}
                                   component={ColleagueOverview}/>
                            <Route path={`${this.props.match.path}/collegas/multiedit`}
                                   component={MultiEdit}/>

                            <Route path={`${this.props.match.path}/collegas/payment`}
                                   component={PaymentLandingpage}/>
                            <Route path={`${this.props.match.path}/inactief`}>
                                <InactivePayment/>
                            </Route>
                            <Route path={`${this.props.match.path}/collegas/toevoegen/:type?/:value1?/:value2?`}
                                   component={AddColleague}/>
                            <Route path={`${this.props.match.path}/collegas/kiezen`}
                                   component={AddColleaguePicker}/>
                            <Route path={`${this.props.match.path}/collegas/wijzigen/:type?/:value1?`}
                                   component={AddColleague}/>
                            <Route path={`${this.props.match.path}/collegas/uitnodigen`}
                                   component={InviteColleagues}/>
                            <Route path={`${this.props.match.path}/collegas/registratielink`}
                                   component={GenerateLink}/>
                            <Route path={`${this.props.match.path}/ontwerpen/kiesdesign`} component={ChooseDesign}/>
                            <Route path={`${this.props.match.path}/ontwerpen/kiestemplate`}
                                   component={ChooseTemplate}/>
                            <Route path={`${this.props.match.path}/ontwerpen/tool/:id?`} component={Designtool2}/>
                            <Route path={`${this.props.match.path}/instellingen/bedrijf`}
                                   component={CompanySettings}/>
                            <Route path={`${this.props.match.path}/instellingen/email/edit`}>
                                <WelcomeEmailSettings edit={true}/>
                            </Route>
                            <Route path={`${this.props.match.path}/instellingen/nfc/edit`}>
                                <NfcSettings edit={true}/>
                            </Route>
                            <Route path={`${this.props.match.path}/instellingen/email/view`}>
                                <WelcomeEmailSettings edit={false}/>
                            </Route>
                            <Route path={`${this.props.match.path}/instellingen/betaal`}
                                   component={PaymentSettings}/>
                            <Route path={`${this.props.match.path}/instellingen/abonnement`}
                                   component={SubscriptionSettings}/>
                            <Route path={`${this.props.match.path}/insights`} component={Insights}/>
                            <Route path={`${this.props.match.path}/instellingen/gebruiker`}
                                   component={PermissionSettings}/>
                            <Route path={`${this.props.match.path}/instellingen/invoice/:id`} component={Invoice}/>
                            <Route path={`${this.props.match.path}/koppeling/`} component={Koppeling}/>
                            <Route path={`${this.props.match.path}/display/:id?`} component={DisplayBizzerd}/>
                        </Switch>

                    </div>
                )
            } else {
                routes = (

                    <div className={'dashboard--wrapper'} id={'dashboard'}>
                        {onSimpleDashboard && !onPayment ?
                            <Sidebar page={window.location.href} superuser={this.state.user.superuser}/>
                            :
                            <></>}
                        <Switch>
                            {this.state.company && this.state.company.editableByEmployees && !this.state.user.superuser
                                ?
                                <Route path={`${this.props.match.path}/collegas/toevoegen/`}
                                       component={AddColleague}/>
                                :
                                <Route component={RightsErrorWithoutDataEdit}/>}
                            <Route component={RightsError}/>
                        </Switch>
                    </div>
                )
            }
        } else {
            routes = (
                <div className={'dashboard--wrapper'} id={'dashboard'}>
                    Loading
                </div>);
        }
        if (this.state.company && this.state.company.editableByEmployees === false && !this.state.user.superuser) {
            return (<>
                <Suspense fallback={<div className="dashboard--main-content">
                    <div className="placeholder--container"
                         style={{height: "100vh", justifyContent: "center", alignItems: "center"}}>
                        <div className="card--graphic">
                            <img alt={"spinnner"} src={Spinner}/>
                        </div>
                    </div>
                </div>}>
                    <div id={"backoffice"}>{routes}</div>
                </Suspense>
            </>)
        }
        return (<>
            <Suspense fallback={<div className="dashboard--main-content">
                <div className="placeholder--container"
                     style={{height: "100vh", justifyContent: "center", alignItems: "center"}}>
                    <div className="card--graphic">
                        <img alt={"spinnner"} src={Spinner}/>
                    </div>
                </div>
            </div>}>

                <GradientContainer style={{marginTop: "0px", flexDirection: onSimpleDashboard ? "row" : "column"}}>
                    {onSimpleDashboard ? <></> :
                        <NavigationHeader dashboard={true}/>}
                    <div id={"backoffice"}>{routes}</div>
                </GradientContainer>
            </Suspense>
        </>)
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    company: state.company
});

export default connect(mapStateToProps, {logoutUser, getCurrentCompany})(Dashboard);

import PageTitle from "../common2/fields/text/pagetitle.text";
import MainButton from "../common2/buttons/action_main/main.button";
import ButtonColors from "../common2/buttons/button.colors";
import React, {useEffect, useState} from "react";
import DisplayBizzerd from "../bizzerddisplay/DisplayBizzerd";
import InputField from "../common2/fields/editable_input/text.input";
import SecondaryButton from "../common2/buttons/action_secondary/secondary.button";
import {translate} from "react-i18nify";

export const StepPayment = ({step, setStep, template, person, paymentPlan, users, setUsers, startPayment}) => {
    const [price, setPrice] = useState(0.00)
    const [accepted, setAccepted] = useState(false)
    const label = [translate("hd.nef.s4.monthly"), translate("hd.nef.s4.y1"), translate("hd.nef.s4.y2")][paymentPlan]
    useEffect(() => {
        let cost = 3.00
        switch (paymentPlan) {
            case 0:
                cost = 0.21
                break
            case 1:
                cost = 30.00
                break
            case 2:
                cost = 48.00
                break

        }
        let newPrice = users * cost
        if (paymentPlan === 2) {
            newPrice *= 2
        }
        if (paymentPlan === 0) {
            newPrice = 0.21
        }
        setPrice(newPrice)
    }, [users, paymentPlan]);
    return <div className={"step-flow-item-container"}>
        <PageTitle text={"Checkout"}/>
        <div className={"step-flow-horizontal"} style={{flexDirection: "column"}}>
            <div className={"step-flow-row"}>
                <div className={"step-flow-row-big"}></div>
                <div className={"step-flow-row-small"}>
                    <span className={"step-flow-row-label"}>{translate("hd.nef.s5.users")}</span>
                </div>
                <div className={"step-flow-row-small"}>
                    <span className={"step-flow-row-label"}>{translate("hd.nef.s5.amount")}</span>
                </div>
            </div>
            <div className={"step-flow-divider"}></div>
            <div className={"step-flow-row"}>
                <div className={"step-flow-row-big step-flow-row-big-main"}>
                    {/*<DisplayBizzerd bizzerdLayout={template} personContent={person} realWidth={60}*/}
                    {/*                shouldBeClickable={false}/>*/}
                    <div className={"row-column"}>
                        <span className={"pp-header"}>{translate("hd.pricingPlan.business.title")}</span>
                        <span className={"pp-sub"}>{users} {translate("hd.nef.s5.usersSmall")}, {label}</span>
                    </div>
                </div>
                <div className={"step-flow-row-small"}>
                    <InputField placeholder={""} id={"user"} label={translate("hd.nef.s5.users")} value={users}
                                style={{width: 62}}
                                password={false} min={1}
                                onChange={(e) => setUsers(parseInt(e.target.value))} number={true}
                                inputStyle={{padding: 10}}/>
                </div>
                <div className={"step-flow-row-small"}>
                    <span className={"step-flow-row-label"}>€ {price.toFixed(2)}</span>
                </div>
            </div>
            <div className={"step-flow-divider"}></div>
            <div className={"step-flow-row"} style={{marginTop: 10}}>
                <div className={"step-flow-row-big"}>
                    {paymentPlan===0 && <span style={{opacity:0.6,color:"#324A6D"}}>{translate("hd.nef.s5.authorize")}</span>}
                </div>
                <div className={"step-flow-row-small"}>
                    <span className={"step-flow-row-label"}>{translate("hd.nef.s5.subtotal")}</span>
                </div>
                <div className={"step-flow-row-small"}>
                    <span className={"step-flow-row-label"}>€ {price.toFixed(2)}</span>
                </div>
            </div>
            <div className={"step-flow-row"} style={{marginBottom: 10}}>
                <div className={"step-flow-row-big"}/>
                <div className={"step-flow-row-small"}>
                    <span className={"step-flow-row-label-small"}>{translate("hd.nef.s5.vat")} 21%</span>
                </div>
                <div className={"step-flow-row-small"}>
                    <span className={"step-flow-row-label-small"}>€ {(price * 0.21).toFixed(2)}</span>
                </div>
            </div>
            <div className={"step-flow-row"}>
                <div className={"step-flow-row-big"}/>
                <div className={"step-flow-divider-small"}></div>
            </div>
            <div className={"step-flow-row"}>
                <div className={"step-flow-row-big"}/>
                <div className={"step-flow-row-small"}>
                    <span className={"step-flow-row-label-small"}>{translate("hd.nef.s5.total")}</span>
                </div>
                <div className={"step-flow-row-small"}>
                    <span className={"step-flow-row-label-small"}>€ {(price * 1.21).toFixed(2)}</span>
                </div>
            </div>
            <div className={"step-flow-row"}>
                <div className={"step-flow-row-big"}/>
                <div className={"step-flow-row-small"}/>
                <div className={"step-flow-row-small"}>
                    <div className={"checkbox-container"}>
                        <input style={{margin: 0}} value={accepted} onChange={(e) => setAccepted(e.target.checked)}
                               type={"checkbox"}/><span className={"checkbox-label"}>{translate("hd.nef.s5.agree")}<a
                        className={"step-link"}
                        href={"/bizzerd_Algemene_voorwaarden.pdf"}
                        target={"_blank"}>{translate("hd.nef.s5.terms")}</a></span>
                    </div>
                </div>
            </div>
            <div className={"step-flow-row"}>
                <div className={"step-flow-row-big"}/>
                <div className={"step-flow-row-small"}/>
                <div className={"step-flow-row-small"}>
                    <SecondaryButton color={ButtonColors.orange} onClick={() => {
                        if (accepted) {
                            startPayment()
                        }
                    }} text={translate("hd.nef.s5.button")}/>
                </div>
            </div>

        </div>
    </div>
}
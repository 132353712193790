import axios from "axios/index";
import {
    ADD_TRANSLATION,
    CLEAR_ERRORS, GET_ABBREVIATIONS,
    GET_ERRORS,
    GET_TRANSLATION,
    GET_TRANSLATIONS, GET_TRANSLATIONSBIZZERD,
    UPDATE_TRANSLATION,
} from "./types";

export const addTranslation = (abbreviation, person) => dispatch => {
    axios.post(`/api/translation/addtranslation/${abbreviation}`, {person: person})
        .then(res => {
            dispatch({
                type: GET_TRANSLATIONS,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

export const getTranslation = (id) => dispatch => {
    axios.get(`/api/translation/gettranslation/${id}`)
        .then(res => {
            dispatch({
                type: GET_TRANSLATION,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

export const getTranslations = () => dispatch => {
    axios.get(`/api/translation/translation/all`)
        .then(res => {
            dispatch({
                type: GET_TRANSLATIONS,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

export const getAbbreviations = () => dispatch => {
    axios.get(`/api/translation/getTranslationAbbreviations`)
        .then(res => {
            dispatch({
                type: GET_ABBREVIATIONS,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

export const getTranslationsByPerson = (domain, bizzerdName) => dispatch => {
    axios.get(`/api/translation/getTranslationsByPerson/${domain}/${bizzerdName}`)
        .then(res => {
            dispatch({
                type: GET_TRANSLATIONSBIZZERD,
                payload: res.data
            })
        }).catch(err => dispatch({
        type: GET_ERRORS,
        payload: err.response.data
    }))
}

export const updateTranslations = (translations) => dispatch => {
    axios.post(`/api/translation/updatetranslations/all`, {translations: translations})
        .then(res => {
            dispatch({
                type: GET_TRANSLATIONS,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

export const updateTranslation = (translation) => dispatch => {
    axios.post(`/api/translation/changetranslation`, translation)
        .then(res => {
            dispatch({
                type: UPDATE_TRANSLATION,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};


//Person loading
export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS
    }
};

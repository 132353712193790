import React from 'react'
import GradientContainer from "../common2/containers/gradient.container";
import PageTitle from "../common2/fields/text/pagetitle.text";
import ContactBlock from "../common2/blocks/contact.block";
import Footer from "../common2/footers/footer";
import NavigationHeader from "../common2/headers/navigation.header";
import GrayContainer from "../common2/containers/gray.container";
import {translate} from "react-i18nify";
import AboutUsBlock from "../common2/blocks/aboutus.block";

interface pageProps {
    width: number
}

export default function AboutUsPage({width}) {

    return (<>
        <GrayContainer contentStyle={{overflowX: "hidden"}} style={{
            paddingBottom: width < 768 ? "0px" : "40px",
            background: width < 768 ? "linear-gradient(12deg, #ffffff10 57%, #42809620 76%)" : "linear-gradient(6deg, #ffffff10 57%, #42809620 76%)"
        }}>
            <NavigationHeader/>
            <AboutUsBlock/>
        </GrayContainer>
        <Footer/></>);

}


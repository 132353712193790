import "../blocks.styles.css"
import PageTitle from "../../fields/text/pagetitle.text";
import {translate} from "react-i18nify";

export const ProductBarOrangeBlock = ({}) => {
    return (<div className={"product-orange-bar"}>
        <PageTitle style={{color: "#FFF"}} text={translate("hd.product.orangebar.title")}/>
        <span style={{color: "#FFF"}}>{translate("hd.product.orangebar.content")}</span>
        <div className={"product-orange-bar-list"}>
            <div className={"product-orange-bar-item"}>
                <img className={"product-orange-bar-img"} src={"/product/contact.svg"}/>
                <span className={"product-orange-bar-title"}>{translate("hd.product.orangebar.item1.title")}</span>
                <span>{translate("hd.product.orangebar.item1.content")}</span>
            </div>
            <div className={"product-orange-bar-item"}>
                <img className={"product-orange-bar-img"} src={"/product/socialmedia.svg"}/>
                <span className={"product-orange-bar-title"}>{translate("hd.product.orangebar.item2.title")}</span>
                <span>{translate("hd.product.orangebar.item2.content")}</span>
            </div>
            <div className={"product-orange-bar-item"}>
                <img className={"product-orange-bar-img"} src={"/product/videos.svg"}/>
                <span className={"product-orange-bar-title"}>{translate("hd.product.orangebar.item3.title")}</span>
                <span>{translate("hd.product.orangebar.item3.content")}</span>
            </div>
            <div className={"product-orange-bar-item"}>
                <img className={"product-orange-bar-img"} src={"/product/documents.svg"}/>
                <span className={"product-orange-bar-title"}>{translate("hd.product.orangebar.item4.title")}</span>
                <span>{translate("hd.product.orangebar.item4.content")}</span>
            </div>
        </div>

    </div>)
}
import PageTitle from "../common2/fields/text/pagetitle.text";
import MainButton from "../common2/buttons/action_main/main.button";
import ButtonColors from "../common2/buttons/button.colors";
import React, {useEffect, useState} from "react";
import SubTitle from "../common2/fields/text/subtitle.text";
import SubSubSubTitle from "../common2/fields/text/subsubsubtitle.text";
import SecondaryButton from "../common2/buttons/action_secondary/secondary.button";
import {PlanFeature} from "./utils/utils";
import InputField from "../common2/fields/editable_input/text.input";
import DisplayBizzerd from "../bizzerddisplay/DisplayBizzerd";
import {FlowButton} from "./flow-button";
import {translate} from "react-i18nify";

export const ChoosePlan = ({
                               step,
                               setStep,
                               person,
                               template,
                               paymentPlan,
                               setPaymentPlan,
                               users,
                               setUsers,
                               submitTryForFree
                           }) => {
    const [price, setPrice] = useState(3.00)
    const [label, setLabel] = useState(translate("hd.nef.s4.monthly"))
    const [label2, setLabel2] = useState(translate("hd.nef.s4.pupm"))
    const [label3, setLabel3] = useState(translate("hd.nef.s4.pm"))
    const [priceDisplay, setPriceDisplay] = useState(price * users)
    useEffect(() => {
        let newPrice = users * price
        if (paymentPlan === 2) {
            newPrice *= 2
        }
        setPriceDisplay(newPrice)
    }, [price, users, paymentPlan]);
    useEffect(() => {
        switch (paymentPlan) {
            case 0:
                setPrice(3.00)
                setLabel(translate("hd.nef.s4.monthly"))
                setLabel2(translate("hd.nef.s4.pupm"))
                setLabel3(translate("hd.nef.s4.pm"))
                break;
            case 1:
                setPrice(30.00)
                setLabel(translate("hd.nef.s4.y1"))
                setLabel2(translate("hd.nef.s4.pupy"))
                setLabel3(translate("hd.nef.s4.it"))
                break;
            case 2:
                setPrice(24.00)
                setLabel(translate("hd.nef.s4.y2"))
                setLabel3(translate("hd.nef.s4.it"))
                setLabel2(translate("hd.nef.s4.pupy"))
                break;
        }
    }, [paymentPlan]);
    return <div className={"step-flow-item-container"}>
        <PageTitle text={"Choose a plan"}/>
        <div className={"step-flow-horizontal"}>

            <div className={"step-flow-left"}>
                <div className={"step-flow-button-container"}>
                    <PaymentPlanButton title={translate("hd.nef.s4.monthly")} label={translate("hd.nef.s4.pupm")} price={"€ 3,00"}
                                       extraLabel={translate("hd.nef.s4.ppm")} selected={paymentPlan === 0}
                                       onClick={() => setPaymentPlan(0)}/>
                    <PaymentPlanButton title={translate("hd.nef.s4.y1")} label={translate("hd.nef.s4.pupm")} price={"€ 2,50"}
                                       extraLabel={translate("hd.nef.s4.ppy")} selected={paymentPlan === 1}
                                       onClick={() => setPaymentPlan(1)}/>
                    <PaymentPlanButton title={translate("hd.nef.s4.y2")} label={translate("hd.nef.s4.pupm")} price={"€ 2,00"}
                                       extraLabel={translate("hd.nef.s4.ppy")} selected={paymentPlan === 2}
                                       onClick={() => setPaymentPlan(2)}/>
                </div>
                <div className={"payment-plan-container"} >
                    <div className={"step-payment-plan"} style={{border: 0, textAlign: "left"}}>
                        <SubTitle text={translate("hd.pricingPlan.free.title")} style={{margin: 0}}/>
                        <div className={"feature-row-container"}>
                            <SubTitle text={translate("hd.pricingPlan.free.subTitle")} style={{margin: 0}}/>
                            <SubSubSubTitle style={{color: "#324A6D", opacity: 0.6, fontWeight: "bold"}}
                                            text={translate("hd.pricingPlan.free.days30")}/>
                        </div>
                        <SubSubSubTitle style={{color: "#324A6D", opacity: 0.6}}
                                        text={translate("hd.pricingPlan.free.tryFor")}/>

                        <SecondaryButton color={ButtonColors.orange} text={translate("hd.pricingPlan.free.button")}
                                         onClick={submitTryForFree}/>
                        <div className={"feature-row-container"}>
                            <PlanFeature feature={translate("hd.pricingPlan.free.f1")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.free.f2")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.free.f3")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.free.f4")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.free.f5")}/>
                        </div>

                    </div>
                    <div className={"step-payment-plan step-payment-plan-border"} style={{width: 320, height: 700}}>
                        <div className={"feature-row-container"}>
                            <SubTitle text={translate("hd.pricingPlan.business.title")} style={{margin: 0}}/>
                            <SubSubSubTitle style={{color: "#324A6D", opacity: 0.6, fontWeight: "bold"}}
                                            text={label}/>
                        </div>
                        <div className={"feature-row-container"}>
                            <SubTitle text={"€" + price.toFixed(2)} style={{margin: 0}}/>
                            <SubSubSubTitle style={{color: "#324A6D", fontWeight: "bold"}}
                                            text={label2}/>
                            {paymentPlan === 0 && <SubSubSubTitle style={{color: "#324A6D", opacity: 0.6, fontSize: 12}}
                                                                  text={translate("hd.pricingPlan.business.pay")}/>}
                        </div>
                        <div className={"payment-user-input"}>
                            <InputField placeholder={""} id={"user"} label={translate("hd.pricingPlan.business.users")} value={users} style={{width: 62}}
                                        password={false} min={1}
                                        onChange={(e) => setUsers(parseInt(e.target.value))} number={true}
                                        inputStyle={{padding: 10}}/>
                            <div className={"payment-user-input-labels"}>
                                <SubSubSubTitle style={{color: "#324A6D", opacity: 0.6, fontWeight: "bold"}}
                                                text={"€ " + priceDisplay.toFixed(2) + " " + label3}/>
                                <SubSubSubTitle style={{color: "#324A6D", opacity: 0.6}}
                                                text={translate("hd.pricingPlan.business.vat")}/>
                            </div>
                        </div>
                        <SecondaryButton color={ButtonColors.orange} text={translate("hd.pricingPlan.business.button")}
                                         style={{zIndex: 9999}} onClick={() => setStep(step + 1)}/>

                        <div className={"feature-row-container"}>
                            <PlanFeature feature={translate("hd.pricingPlan.business.f1")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.business.f2")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.business.f3")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.business.f4")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.business.f5")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.business.f6")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.business.f7")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.business.f8")}/>

                        </div>

                    </div>
                </div>
            </div>
            <div className={"step-flow-right"}>
                <DisplayBizzerd bizzerdLayout={template} personContent={person} realWidth={300}
                                shouldBeClickable={false}/>
                <div className={"step-button-container"}>

                    <FlowButton color={ButtonColors.white} onClick={() => setStep(step - 1)}
                                title={translate("hd.nef.nav.previousStep")}
                                subTitle={translate("hd.nef.s3.title")}
                                icon={<svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M2.73633 17.8L1.88633 17.45C1.36966 17.2333 1.02383 16.8583 0.848828 16.325C0.673828 15.7917 0.702995 15.2667 0.936328 14.75L2.73633 10.85V17.8ZM6.73633 20C6.18633 20 5.7155 19.8042 5.32383 19.4125C4.93216 19.0208 4.73633 18.55 4.73633 18V12L7.38633 19.35C7.43633 19.4667 7.48633 19.5792 7.53633 19.6875C7.58633 19.7958 7.653 19.9 7.73633 20H6.73633ZM11.8863 19.9C11.353 20.1 10.8363 20.075 10.3363 19.825C9.83633 19.575 9.48633 19.1833 9.28633 18.65L4.83633 6.45001C4.63633 5.91667 4.65299 5.39584 4.88633 4.88751C5.11966 4.37917 5.503 4.03334 6.03633 3.85001L13.5863 1.10001C14.1197 0.900008 14.6363 0.925008 15.1363 1.17501C15.6363 1.42501 15.9863 1.81667 16.1863 2.35001L20.6363 14.55C20.8363 15.0833 20.8197 15.6042 20.5863 16.1125C20.353 16.6208 19.9697 16.9667 19.4363 17.15L11.8863 19.9ZM9.73633 8.00001C10.0197 8.00001 10.2572 7.90418 10.4488 7.71251C10.6405 7.52084 10.7363 7.28334 10.7363 7.00001C10.7363 6.71667 10.6405 6.47917 10.4488 6.28751C10.2572 6.09584 10.0197 6.00001 9.73633 6.00001C9.453 6.00001 9.2155 6.09584 9.02383 6.28751C8.83216 6.47917 8.73633 6.71667 8.73633 7.00001C8.73633 7.28334 8.83216 7.52084 9.02383 7.71251C9.2155 7.90418 9.453 8.00001 9.73633 8.00001ZM11.1863 18L18.7363 15.25L14.2863 3.00001L6.73633 5.75001L11.1863 18Z"
                                        fill="#324A6D"/>
                                </svg>

                                }/>
                </div>
            </div>
        </div>
    </div>
}

export const PaymentPlanButton = ({title, price, extraLabel, selected, onClick}) => {
    return <div className={"payment-plan-button" + (selected ? " ppb-selected" : "")} onClick={onClick}>
        <span className={"payment-plan-button-title"}>{title}</span>
        {extraLabel && selected && <span className={"payment-plan-button-extra-label"}>{extraLabel}</span>}
        <div className={"payment-plan-button-label-container"}>
            <span className={"payment-plan-button-price"}>{price}</span>
            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                <span className={"payment-plan-button-label"}>{translate("hd.nef.s4.pu")}</span>
                <span className={"payment-plan-button-label"}>{translate("hd.nef.s4.pMonth")}</span>
            </div>
        </div>
    </div>

}
import React, {Component} from 'react';
import {IconSets} from "../../common/IconSets";
import {api} from "../../../utils/apiCall";

class Icon extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        let image;
        const style = {
            position: 'absolute',
            left: this.props.resize(this.props.layout.left),
            top: this.props.resize(this.props.layout.top),
            zIndex: this.props.layout.zindex,
            backgroundSize: 'contain',
            width: this.props.resize(this.props.layout.width),
            height: this.props.resize(this.props.layout.height)
        };
        const iconStyle = {
            width: this.props.resize(this.props.layout.width),
            height: this.props.resize(this.props.layout.height),
            color: this.props.layout.backgroundColor
        };
        if (this.props.layout.backgroundColor !== undefined) {
            iconStyle.fill = this.props.layout.backgroundColor;
        }
        if (this.props.layout.color !== undefined) {
            iconStyle.fill = this.props.layout.color;
        }
        if (this.props.delete){
            style.display="none"
        }

        // console.log(style)
        // console.log(iconStyle)
        if (this.props.layout.image === "" || !this.props.layout.image) {
            if (this.props.iconset !== undefined) {
                image = IconSets(iconStyle, this.props.id)[this.props.iconset][this.props.layout.type]
            }
        } else {
            image = <img src={this.props.layout.image}/>
        }

        if (this.props.layout.rotation !== undefined) {
            style.transform = "rotate(" + this.props.layout.rotation + "deg)"
        }
        if (this.props.link && this.props.link.startsWith("change_language_")) {
            return (<div style={{...style, cursor: "pointer"}}
                         onClick={() => this.props.changeTranslation(this.props.link.replace("change_language_", ""))}>{image}</div>)
        }
        if (this.props.shouldBeClickable === false) {
            if (this.props.onSelectField) {
                return (
                    <div className={"selected-bizzerd-editor-field"} style={style}
                         onClick={() => this.props.onSelectField(this.props.layout._id)}>
                        {image}
                    </div>
                )
            } else {
                return (
                    <div style={style}>
                        {image}
                    </div>
                )
            }
        }
        if (this.props.link && this.props.link.includes("vcard")) {
            let w;
            let h;
            for (let img in this.props.bizzerd.shape) {
                let shape = this.props.bizzerd.shape[img];
                if (shape.type === "photo") {
                    w = shape.width
                    h = shape.height
                }
            }

            function getBase64Image(img, w, h) {
                let canvas = document.createElement("canvas");
                canvas.width = w;
                canvas.height = h;
                let ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0, w, h);
                let dataURL = canvas.toDataURL("image/png");
                return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
            }

            return (
                <div style={{cursor: "pointer"}}>
                    <img crossOrigin="anonymous" id="imageid" src={this.props.person.pasfoto}
                         style={{visibility: "hidden"}}/>
                    <a onClick={async () => {
                        let base64 = getBase64Image(document.getElementById("imageid"), w, h);
                        let person = this.props.person;
                        let responseString
                        if (person.person) {
                            responseString = await api('/api/person/vcardtranslation/' + (person._id))
                        } else {
                            responseString = await api('/api/person/vcard/' + (person._id))
                        }
                        const url = window.URL.createObjectURL(new Blob([responseString.data], {
                            type: 'text/x-vcard',
                            name: person.fullname + ".vcf"
                        }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', person.fullname + `.vcf`);
                        // 3. Append to html page
                        document.body.appendChild(link);
                        // 4. Force download
                        link.click();
                        // 5. Clean up and remove the link
                        link.parentNode.removeChild(link);
                    }} style={style}>{image}
                    </a>
                </div>
            );
        }
        if (!this.props.link || this.props.layout.type === 'delen') {
            return (
                <a href={this.props.link} style={style}>
                    {image}
                </a>
            )
        }

        return (
            <a target={"_blank"}
               href={this.props.link} style={style}>
                {image}
            </a>
        )
    }
}

export default Icon;

export const PlaceholderPerson = Object.freeze({
    firstname: "Voornaam",
    lastname: "Achternaam",
    jobtitle: "Functietitel",
    email: "E-mailadres",
    street: "Straatnaam",
    number: "10",
    stad: "Plaatsnaam",
    linkedin: "https://www.linkedin.com",
    facebook: "https://www.facebook.com",
    twitter: "https://www.twitter.com",
    instagram: "https://www.instagram.com",
    pinterest: "https://www.pinterest.com",
    website: "https://www.bizzerd.com"
});
import RoundBizzerdContainer from "../containers/round.bizzerd.container";
import React from "react";
import CardRow from "../containers/card.row";
import CardPagination from "../filters/pagination.filter";
import CardContainer from "../containers/card.container";


interface businessCardsState {
    currentPage: number,
    cardsPerPage: number,

}

interface businessCardsProps {
    width: number
    templates: [any],
    variations: [any],
    personContent: any,
    logo?: string,

}

class BusinessCardsFlow extends React.Component<businessCardsProps, businessCardsState> {

    constructor(props) {
        super(props);
        let selectedString = new URLSearchParams(window.location.search).get("page")
        let selected = 1
        if (selectedString)
            selected = parseInt(selectedString)

        this.state = {
            currentPage: selected - 1,
            cardsPerPage: this.props.width < 768 ? 9 : 12,

        }
        this.changePage = this.changePage.bind(this)
    }

    changePage(pageNr) {
        if (pageNr >= 0 && pageNr < Math.ceil(this.props.variations.length / this.state.cardsPerPage)) {
            this.setState({currentPage: pageNr})
        }
    }

    render() {
        let cards: Array<JSX.Element> = []
        let cardRow: Array<JSX.Element> = []
        let cardsPerRow = 4
        if (this.props.width < 1400) {
            cardsPerRow = 3
        }
        if (this.props.width < 1050) {
            cardsPerRow = 2
        }
        for (let i = this.state.currentPage * this.state.cardsPerPage; i < (this.state.currentPage + 1) * this.state.cardsPerPage; i++) {
            if (cardRow.length === cardsPerRow) {
                cards.push(<CardRow>{cardRow}</CardRow>)
                cardRow = []
            }
            if (this.props.variations.length > i)
                cardRow.push(<div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <RoundBizzerdContainer drawCircle={false} steps={[200, 180, 150, 100]}
                                           variation={this.props.variations[i]}
                                           personContent={this.props.personContent}
                                           width={this.props.width} logo={this.props.logo}
                                           onClick={"detail"}/>
                </div>)
        }
        if (cardRow.length > 0)
            cards.push(<CardRow>{cardRow}</CardRow>)


        return (<>
            <CardContainer class={"container-cards-picker"}>
                {cards}
                <CardPagination pages={Math.ceil(this.props.variations.length / this.state.cardsPerPage)}
                                selected={this.state.currentPage + 1 <= Math.ceil(this.props.variations.length / this.state.cardsPerPage) ? this.state.currentPage + 1 : 1}
                                onChangePagination={this.changePage}/>
            </CardContainer>
        </>)
    }
}

export default BusinessCardsFlow;

import {ILanguage} from "./Istrings.interface";

export const english: ILanguage = {

    dashboard: {
        sidebar: {
            overview: "User overview",
            customize: "Customising design",
            add: "Add colleagues",
            settings: "Settings"
        },
        daysAgo:"days ago",
        addAColleague: "You can add colleagues manually and automatically. You can enter your colleague's details yourself or invite your colleague to do it.",
        addAColleague2: "If you want to connect with AFAS or Azure AD, please contact us at",
        viewColleagues: "Update colleagues' information via the user overview.",
        viewColleaguesButton: "User overview",
        insights: "Insights",
        inviteEmail: "Invite colleagues via email",
        addManually: "Add manually",
        open: "Open",
        edit: "Edit",
        users:"Users",

        invite: "Invite",
        inviteColleagues: "Invite colleagues",
        inviteColleagues1: "You can also invite colleagues via e-mail or via a registration link.",
        inviteColleagues2: "By using the link in the e-mail, users can enter their own data after creating a password.",
        addManually1: "Create a business card for a new colleague.",
        addManually2: "The user will receive an email at the email address entered with instructions on how to set up a user account.",
        bizzerdNfc: "Here you can link a Bizzerd tag to a colleague. This colleague&#39;s digital business card will then appear when someone scans the Bizzerd tag.",
        bizzerdNfc1: "Apply for NFC",
        bizzerdNfc2: "View the instructions (PDF)",
        nfcLink: "nfceng.pdf",
        addNfc:"Add NFC",
        selectUser:"Select a user"

    },
    hd: {
        actions: {
            confirm: "Confirm",
            request: "Request reset",
            logout: "Log out",
            login: "Login",
            requestQuotation: "Request Quotation",
            save: "Save",
            successChanged: "Password changed successfully. Click on the button to return to login",
            successRequest: "Successfully request. Check your email and don't forget to check your spam!",
            toLogin: "Back to login",
            uploadLogo: "Upload logo",
            uploadProfile: "Upload profile photo",
            send: "Send",
            cancel: "Cancel",
            clickHere: "Click here",
            contactSales: "Talk to sales",
            tryForFree: "Create your card",

            requestDraft: "Request for quotation"
        },
        designPicker: {
            chooseAndPersonalize: "Choose a card and customize it...",
            next: "Next step",
            free: "Create your free digital businesscard",
            back: "Back",
            loadAll: "Load all",
            start: "Select one of the designs and start creating",
            select: "Select",
        },
        links: {
            home: "/en/",
            product: "/en/product",
            about: "/en/about",
            nfc: "/en/nfc",
            businessCards: "/start",
            detail: "/en/detail",
            contact: "/en/contact",
            blogs: "/en/blogs",
            designEntry: "/start",
            shoppingcart: "/en/shoppingcart",
            enterprise: "/en/enterprise",
            pricing: "/en/pricing",
            upload: "/en/upload",
            login: "/en/login",

        },
        nfc: {
            pageTitle: "Digital Business Card with a NFC tag!",
            pageContent1: "With Bizzerd, you can take advantage of all the benefits of NFC. You can choose an NFC tag, which is a sticker with an NFC chip that you can stick on your phone, or opt for a physical business card with NFC, available in sustainable bamboo or wood.",
            pageContent2: "With the NFC tag or NFC card, you can share your Bizzerd digital business card in 1 second! As much and as often as you want.",
            buttonText: "Request now",
            blockTitle: "What is NFC?",
            blockContent1: "NFC stands for Near Field Communication. It is a technology for automatic wireless data transfer without an internet connection. It works for contactless payments or using your public transport card (like an OV-chipkaart). This transfer only happens close by, for example, when you hold an NFC chip against a phone. It's the quickest and easiest way to share your digital business card with someone, which is handy during networking events or business gatherings.",
            blockContent2: "Bizzerd is the most widely used digital business card, offering the most functionalities. Bizzerd is the best way to represent yourself and your organization. Now, it's even easier and faster with the NFC tag or NFC card.",
            blockContent3: "By the way, you can reuse your Bizzerd tag or physical business card as many times as you want. For instance, you can transfer it to a colleague. You just unlink your digital business card and link your colleague's digital business card to the tag or card.",
            blockTitle2: "Reusable and therefore sustainable",
            saleTitle: "NFC tag!",
            saleContent: "Choose Bizzerd with NFC tag now and take advantage of this temporary offer. If you switch to Bizzerd digital business cards before 1st September 2023, you'll get the NFC tag completely free!"
        },
        companySettings: {
            welcomeMail: "Welcome mail",
            description: "This mail is sent to your colleagues when you invite them. Press the button below to view the default mail or edit the mail",
            view: "View",
            save: "Save",
            cancel: "Cancel",
            edit: "Edit",
            title: "Here you can customize the email sent to your colleagues when inviting them. You can use the following special values to personalize the email per colleague.",
            list1: "~voornaam~ to use your colleague's first name",
            list2: "~achternaam~ to use your colleague's last name",
            list3: "~volledigenaam~ to use your colleague's full name",
            list4: "~kaartje~ to use the link to your colleague's card",
            list5: "~account~ to use your colleague's invitation link",
            required: "*required",
            nfcTitle: "Bizzerd-tags setup",
            nfcDescription: "Here you can link the Bizzerd tags to a colleague which makes the digital business card of this colleague appear after scanning the Bizzerd tag",
        },
        multiEdit: {
            title: "Edit Colleagues",
            subTitle: "Select a field and enter the new value in the field to update the field for all selected colleagues simultaneously",
            selectedNr: "Selected People",
            submit: "Apply",
            selectDesign: "Select Design",
            newValue: "New Value",
            value: "Value...",
            selected: "Selected",
            header: "Colleagues",
        },
        blogBlock: {
            pageTitle: "Our blogs",
            subTitle: "Discover the power of fully online digital business cards: Overview of our blog articles",
            language: "en",
            readMore: "Read more",
            backToOverview: "Back to overview",
            blog1: {
                "title": "What is an NFC business card and what can you do (or not do) with it?",
                "description": "Hardly anyone uses a paper business card these days. digital business cards are the standard. There are lots of different forms of digital business cards available. What you hear most about are NFC business cards. NFC means Near Field Communication. An NFC business card is a physical card with a tiny microchip embedded in it. This chip can be scanned by most smartphones and linked to your digital business card. This is how you share your contact information. This data can also be stored directly in the phone's address book.",
                "link": "nfc-business-card-blog"
            },
            blog2: {
                "title": "The pros and cons of digital business cards: Fact or fiction?",
                "description": "Who still uses paper business cards? Isn't LinkedIn enough? If you want to share your contact details with someone, you can just connect on LinkedIn, right? Or can you? In this blog article, we will outline the advantages and disadvantages of paper cards, LinkedIn, and digital business cards.",
                "link": "pros-cons-digital-business-card-blog"
            },

            blog3: {
                title: "Here’s how to ensure privacy and data security of digital business cards\n",
                description: "More and more organisations are opting for interactive, digital business cards. This requires extra attention to privacy and data security of the stored data. After all, personal data is involved. You want to decide with whom you share your data. Your business cards should not end up on the street. ",
                link: "privacy-blog"
            },
            blog4: {
                title: "Here's how te get extra engagement from (potential) customers with your digital business card",
                description: "With a digital business card, you ensure that recipients do more than just passively receive information. You can actively engage them in further interaction and ensure activation, such as contacting them, continuing a conversation, requesting more information or a quotation, participating in promotions or giving feedback. A few tips.",
                link: "engagement-blog"
            },
            blog5: {
                title: "Here’s how your business cards show that you take sustainability seriously",
                description: "Your organisation takes sustainability seriously and takes as many measures as possible to improve the environment and limit climate change. What can digital business cards contribute to this?",
                link: "climate-blog"
            },
            blog6: {
                title: "Here’s how to enhance your brand with a digital business card",
                description: "Leading, meaningful brands stand or fall with a consistent translation of the brand identity. But not only that, a brand should also inspire and capture the imagination. So brands invest a lot in their brand identity and in brand communication. This ranges from appealing commercials to daily branding such as signing, corporate clothing and stationary.",
                link: "corporate-identity-blog"
            },
            blog7: {
                title: "Here's how to automatically link business cards to your CRM and HRM system",
                description: "New employee hired? Then you also need to arrange business cards. Fortunately, these days this is a matter of a few clicks. Thanks to linking digital business cards to CRM and HRM systems. You can read how that works here.",
                link: "crm-hrm-blog"
            },
            blog8: {
                title: "Here's how to make your own NFC business card",
                description: "You have probably heard of it or dealt with it: an NFC business card. An NFC business card is a digital business card that uses Near Field Communication (NFC) technology. NFC is a wireless communication technology that allows data to be exchanged between two devices located within a short distance of each other, usually within a few centimetres.",
                link: "create-nfc-blog"
            }
        },

        dashboard: {
            title: "My business cards",
            subTitle: "Welcome to the Bizzerd Dashboard. Here you can view and edit your business card(s) and those of colleagues. Here you can invite colleagues and others to also use Bizzerd and control everything else.",

        },
        notFound: {
            title: "Sorry, we can't find this page anymore",
            content: "We tried our best, but it seems that this page does not exist (anymore) or may have moved. Of course, you can always go to the home page or contact us.",
            buttonHome: "To the homepage",
            buttonContact: "Contact us"
        },
        paymentLanding: {
            pageTitle: "Payment successful!",
            pageContent: "Thank you! Quickly go to the Bizzerd Dashboard. This is where you can edit your data, edit the design and add or invite your colleagues!",
            buttonText: "To dashboard"
        },
        about: {
            title: "The story behind the most used<br/> digital business card",
            subTitle: "Businesses discard millions of paper business cards every year, often unused. This is a waste of money and causes unnecessary environmental and climate impact. We believe it can be different, it can be better at Bizzerd.",
            mainButton: "See how Bizzerd works",
            secondaryButton: "Enterprise",
            locationTitle: "We are located in the Netherlands, Bizzerd is used worldwide",
            locationText: "We started in 2015 as the first digital business card in the Netherlands. By now, Bizzerd is used by hundreds of thousands worldwide and is the most chosen digital business card for organizations and companies.",
            bizzerdWorkTitle: "We are socially engaged, creating more opportunities for young people",
            bizzerdWorkText: "At Bizzerd, we are committed to society. That's why we support the Bizzerd.work foundation to create more opportunities for all pre-vocational, special education, secondary vocational, and tertiary vocational students. For this purpose, we have developed an app: Bizzerd.work, along with an associated educational program. The app serves as a digital resume, which is a handy tool for young people when applying for (part-time) jobs or internships. For primary education, we have developed a digital portfolio in which students can keep track of their learning experiences.",
            bizzerdWorkLink: "Explore Bizzerd.work",
            partnersTitle: "Partners",
            blogTitle: "Blog Posts",
            blogLink: "Read More",
            referralText: "Are you excited about our digital business card and would you recommend Bizzerd to other organizations as well? Definitely do it! If you provide us with a new customer with your tip, we will give you a nice reward. Interested?",
            referralEmailText: "Email us",
            referralText2: "and we'll send you our referral program.",
            referralTitle: "Bizzerd referral program",
        },
        titles: {

            businessCards: "Business Cards | Bizzerd",
            home: "Bizzerd, the most used digital business card",
            nfc: "Create your NFC business card",
            about: "Bizzerd, Our story",

            pricing: "This is what your digital business card costs",
            product: "How Bizzerd, the digital business card, works",
            contact: "Contact | Bizzerd",
            shoppingCart: "Shopping Cart | Bizzerd",
            enterprise: "Let Bizzerd create your custom digital business card",
            upload: "Upload | Bizzerd",
            blogs: "Bizzerd, Blogs about digital (NFC) business cards",
        },
        errorMessages: {
            invalidEmail: "Enter a valid email address",
            companyNameExists: "Recovery name already exists",
            noName: "Fill in your name",
            noCompanyName: "Enter your company name",
            invalidNumber: "Enter a valid mobile number"
        },
        uploadBlock: {
            pageTitle: "Create your own design",
            blockTitle2: "Create your own design",
            block2Content: "Click the button below to begin the design process. You will then first be sent to the Bizzerd design tool where you can create a design. After this you fill in your details and pay the standard rates for a subscription.",
            block2ButtonText: "Start now",
            blockTitle: "Upload design",
            designSpecification: "design specifications",
            block1Content: "Want to design your own digital business card? You can! Just follow these 5 steps:",
            step1: "View the",
            step2: "Choose the design tool you want to use:",
            step2a: "Click here for ",
            step2b: "Click here for ",
            step3: "Create your design",
            step4: "Export the file",
            step5: "Email the file with your (company) name to: ",
            block1Footer: "Our experts will then put your digital business card in your account within 3 business days. You'll be notified as soon as it's ready. And then share it!",
        },
        iconsBlock: {
            nfc: "Share your digital business card quickly and easily with the handy Bizzerd tag (NFC chip). Available as a physical card (bamboo or wood) or as a tag that you stick on your phone, for example.",
            pageTitle: "Why choose the most used digital, paperless business card?",
            iconContainerContent: "Bizzerd is the digital, paperless business card. Bizzerd is simple: easy to use. Bizzerd is practical: you always have your business cards with you. Bizzerd is sustainable: no unnecessary paper waste. With a digital business card from Bizzerd you are always up-to-date. And you have an unlimited stock of business cards! So you will never be without business cards at times when it matters!",
            interactive: "With Bizzerd, your business card is a mini-website. As a result, you can always give your digital business card to someone, even without the app. You only need to share the link, for example, via e-mail, WhatsApp, text message or the qr code.",
            impact: "Give someone your Bizzerd and make the best first impression.",
            uptodate: "Check how many views your business card gets, how often it is shared and what is clicked on. This will help you get to know your network better and take advantage of more opportunities.",
            climate: "Choose sustainable. With Bizzerd, you know you're contributing to a cleaner planet. The 100% digital business card requires virtually no paper, printing or transportation",
            fingerprint: "At Bizzerd, your business card has the design you want, matching your identity. Choose one of the designs from our collection or use your organization's corporate identity.",
            interactiveLabel: "Interactive",
            nfcLabel: "Bizzerd-tag",
            impactLabel: "Impact",
            uptodateLabel: "Insights",
            climateLabel: "Sustainable",
            fingerprintLabel: "Your design",
            howDoesItWork: "How it works"
        },
        navigationbar: {
            home: "Home",
            businessCards: "Business cards",
            nfc: "NFC-businesscard",
            product: "Features",
            about: "About",
            pricing: "Prices",
            enterprise: "Enterprise",
            contact: "Sales",
            myOverview: "My overview",
            myInsights: "My insights",
            mySubscriptions: "My subscriptions",
            myCompany: "My company"
        },
        businessCardPage: {
            pageTitle: "Digital business cards",
            subTitle: "Browse the designs, choose your favorite and start creating your own business card!",
            enterpriseSelling: "Do you prefer to have your digital business cards made by Bizzerd, in your own corporate identity?"

        },
        headerFilter: {
            sortOn: "Sort by",
            mostChosen: "Most chosen",
            newest: "Newest",
            images: "Image",
            largeProfile: "Big profile photos",
            smallProfile: "Small profile photos",
            allProfile: "All profile photos",
            noProfile: "No profile photo",
            adjustCard: "Adjust cards",
            warning: "It may be that the default size of your logo is not the best.... no worries you can adjust it in the following steps and put it all right!"
        },
        contact: {
            pageTitle: "Contact our sales team!",
            subTitle: "Our support team is ready to answer your question or message. Fill out the form and you'll get a quick response from us. You can also use it for quote requests.",
            name: "Name",
            thirdTitle: "Get Expert Advice",
            subThirdTitle: "Need advice? Contact Martin. He can tell you everything about digital business cards and will be happy to assist you.",
            call: "Call",
            bizzerdOffer: "Find out what Bizzerd can offer your company. We will gladly discuss the possibilities for your organization's digital business card.",
            companyName: "Company Name",
            weAreReachable: "We are reachable on ",
            email: "E-mail",
            questions: "Questions?",
            telephoneNumber: "Phone number",
            message: "Message",
            backToHome: "Back to Home",
            thanksForTheMessage: "Thanks for your message!",
            noIndication: "No indication",
            employeeIndication: "Number of employees",
            nfcRequest: "Interested in NFC-cards (from 10 employees)",
            requestType: "Contact type",
            contactSupport: "Contact & support",
            quotationRequest: "Quotation request",
        },
        footer: {
            bizzerdTitle: "Bizzerd",
            pricing: "Prices",
            businessCards: "Business cards",
            resourcesTitle: "Resources",
            toc: "Terms and Conditions",
            product: "Product",
            privacyStatement: "Privacy Statement",
            appManual: "Manual app",
            webManual: "Web manual",
            userTips: "User tips",
            contactTitle: "Contact",
            netherlands: "The Netherlands",
            extraTitle: "Other",
            kvk: "Chamber of Commerce",
            tocPrivacy: "Our conditions / privacy",
            blogs: "Blogs"

        },
        detail: {
            pageTitle: "Design",
            pppm: "p.p. p/mth",
            nfc: "A request for the Bizzerd-tag or NFC card can be added in the shopping cart",
            customize: "Personalize",
            priceSimDesign: "Price info with the same design",
            duration: "Duration",
            productName: "Digital business card ",
            price: "Price",
            pricePm: "Price p/mth",
            discount: "Discount",
            yr1: "1 year",
            yr2: "2 years",
            yr3: "3 years",
            discountSmall: "discount",
            details: "Create your digital business card in 3 steps",
            designSpecifications: "Design specifications",
            detailContent: "Go for speed and convenience. Use this specially created design and create your digital business card in 3 steps. First you fill in your details. Then choose the buttons you want to add to your social media and your website(s). Then choose your colors and the logo you want to use. Are you done with that? Then choose your subscription, create your account and checkout. And then the fun can begin: share your interactive business card as often as you want and with whomever you want.",
            designContent: {
                textTitle: "The text fields in this design are fully customizable to your or your company's needs. For example, links can be placed behind all texts, this is automatic for fields such as the website, phone numbers and email. This design contains the following text fields:",
                text: {
                    "firstname": "firstname",// "A field that allows space for the first name of you and/or your colleagues",
                    "lastname": "lastname",// "A field that allows space for the last name of you and/or your colleagues",
                    "fullname": "full name",// "A field that allows space for the full name of you and/or your colleagues",
                    "email": "email",// "A field that is filled with your email address and/or those of your colleagues. This field automatically links to your email",
                    "tel": "telephone number",// "A field that is filled with your phone number and/or those of your colleagues. This field automatically links to your phone number",
                    "mobile": "mobile number",// "A field that is filled with your mobile number and/or those of your colleagues. This field automatically links to your mobile number",
                    "jobtitle": "job title",// "A field that allows space for the job title of you and/or your colleagues",
                    "website": "website",// "A field that is filled with your website or that of your company. This field automatically links to your website",
                    "adresveld": "address",// "A field that allows space for the address of you and/or your colleagues. This field automatically links to Google Maps",
                    "companyname": "company name",// "A field that allows space for the name of your company"
                },
                iconTitle: "The icons in this design are completely customizable to your or your company's needs. For example, all icons link to the corresponding website. This design contains the following icon fields:",
                icon: {
                    "linkedin": "LinkedIn",
                    "facebook": "Facebook",
                    "instagram": "Instagram",
                    "twitter": "Twitter",
                    "pinterest": "Pinterest",
                    "email": "E-mail",
                    "tel": "Telefoon",
                    "website": "Website",
                    "vimeo": "Vimeo",
                    "opslaan": "Save (save to contacts)",
                    "delen": "Share (links to the personal share page)",
                },
                generalText: "All text fields are customizable such as the location, fontsize, font, color, font weight. The type of the fields can also be customized. Additionally, more fields can be added or removed",
                generalIcon: "All icon fields are customizable such as the location, the size, the color and the appearance. The type of the icon can also be customized. Additionally, more icons can be added or removed",
            }
        },
        editor: {
            pageTitle: "Create your business card in 3 steps",
            differentDesign: "Do you want something else",
            designEnterprise: "Tailor-made design",
            pickDesign: "choose a different design",
            or: "Or",
            contactDetails: "Your contact details",
            fullname: "Name",
            mobile: "Mobile number",
            email: "E-mail",
            companyName: "Company Name",
            jobtitle: "Function",
            tel: "phone number",
            adresveld: "Address",
            toStep: "To step",
            colorLogo: "Colors & Logo",
            delete: "To delete",
            correctLogoMessage: "Did you know that you can always put your logo in the extensive editor later?",
            adjustFields: "Adjust",
            clickToSelect: "Click on a field in your business card to select it",
            next: "Next one"
        },
        enterprise: {
            cardTitle: "Enterprise cards",
            fromPriceMonth: "From € 0.00 p/mnd",
            viewAllCards: "View all design",
            viewMoreCards: "View more designs ...",
            enterpriseTitle: "Have your digital business card tailor-made",
            enterpriseContent: "For countless large organizations, worldwide, Bizzerd creates digital business cards in their own corporate identity. Step over with the entire organization as well and choose customized interactive business cards. We take all the steps out of your hands. From the design - in your corporate identity - to the automatic loading of all data. Request a no-obligation quote now!",
            viewPros: "Advantages of Bizzerd enterprise for large organizations",
            scalable: "Scalable",
            privacy: "Privacy",
            security: "Data security",
            engagement: "Engagement",
            branding: "Branding",
            crm: "HRM integration",
            climate: "Sustainable",
            scalableBlock: {
                pageTitle: "Scalable: up to (one hundred) thousands of users",
                pageContent: "With Bizzerd, your organization can move forward (worldwide). Bizzerd is used by organizations of varying sizes. From a few dozen users to hundreds of thousands. Also with various country offices and different language areas.",
                usp1: "Easy to add or delete users",
                usp2: "Usable worldwide",
                usp3: "Supports all languages ​​and characters"
            },
            privacyBlock: {
                pageTitle: "Privacy: maximum protected",
                pageContent: "Bizzerd is trusted by numerous large (international) organizations and meets strict standards for privacy and protection of personal data (GDPR).",
                usp1: "Users decide for themselves what information they share",
                usp2: "Processing in accordance with strict privacy guidelines",
                usp3: "Hosted on Dutch and German servers"
            },
            securityBlock: {
                pageTitle: "Data security: robust and proven",
                pageContent: "Bizzerd pays a lot of attention to the security of systems and data. Our data security is regularly tested and updated",
                usp1: "Proven procedures for backups and incident management",
                usp2: "Regular pen testing demonstrates security",
                usp3: "Hosted on strictly secure servers"
            },
            engagementBlock: {
                pageTitle: "Engagement: really make an impression with business cards",
                pageContent: "With Bizzerd, employees make the best first impression. Create extra engagement by adding moving visuals, presentations, videos or animations, for example. Or place a call-to-action. With the insights provided by Bizzerd analytics, you monitor the impact. This way you can make your organization's digital business cards even more effective!",
                usp1: "Maximum options for interaction",
                usp2: "Understanding impact and engagement",
                usp3: "Up-to-date: the recipient now has your current data"
            },
            brandingBlock: {
                pageTitle: "Branding: strengthen your brand",
                pageContent: "Bizzerd enhances your organization's visual identity and branding. Each brand comes to life, thanks to its personal and distinctive character. Each digital business card is a vibrant expression of the corporate identity and turns employees into true organizational ambassadors.",
                usp1: "Entirely in the organization's own corporate identity",
                usp2: "Makes the corporate identity personal and interactive",
                usp3: "Plenty of opportunities to stand out"
            },
            hrmBlock: {
                pageTitle: "HRM integration: optimal linking",
                pageContent: "More and more organizations are integrating Bizzerd with their CRM system (Afas or Microsoft). This offers many advantages. The management of business cards becomes even easier. New colleagues automatically receive a digital business card. Ticking a box in the system is enough.",
                usp1: "Automatic adjustment of any business card with a new corporate identity or with design adjustments",
                usp2: "All business cards are updated at once as company information changes.",
                usp3: "Monitoring: Statistics for internal reports."
            },
            climateBlock: {
                pageTitle: "Sustainable: From (Foot)Print to Digital",
                pageContent: "Organizations are constantly looking for ways to become more sustainable. Bizzerd is one of the easiest ways to contribute to climate and environment. Did you know that over 10 billion business cards are printed worldwide every year? That's about 27 million per day. Choose sustainable; paperless.",
                usp1: "Paperless, so no cutting of trees, no transportation and no printing",
                usp2: "Show that your organization cares about climate and environment",
                usp3: "Practical and concrete implementation of CSR policy"
            }
        },
        nef: {
            nav: {
                step1: "Choose a template",
                step2: "Add your details",
                step3: "Customize template",
                step4: "Choose plan",
                step5: "Checkout",
                step: "Step",
                ofThe: "of",
                nextStep: "Next step",
                previousStep: "Previous step",
            },
            s1: {
                title: "Choose your template",
                subTitle: "Add your company styles",
                back: "Back",
            },
            s2: {
                title: "Add your details",
                subTitle: "Create your first user",
                userDetails: "User details",
                accountDetails: "Account details",
                email: "Email address",
                password: "Password",
                passwordAgain: "Password again",
                personDetails: "Person details",
                firstName: "First name",
                lastName: "Last name",
                jobTitle: "Job title",
                mobile: "Mobile number",
                phone: "Phone number",
                companyDetails: "Company details",
                companyName: "Company name",
                website: "Website",
                address: "Address",
                errors: {
                    passwordLength: 'Password needs at least a length of 8',
                    passwordMatch: 'Passwords do not match'
                }
            },
            s3: {
                title: "Customize template",
                subTitle: "Add your company styles.",
                subSubTitle: "You can always adjust these settings later in your account.",
                logoPosition: "Logo position",
                left: "Left",
                center: "Center",
                right: "Right",
                size: "Size",
                backgroundColors: "Background colors",
                topBackgroundImage: "Top background image",
                topBackgroundColor: "Top background color",
                uploadImage: "Upload Image",
                userInfo: "User info",
                textColors: "Text colors",
                titleColor: "Title color",
                jobTitleColor: "Job title color",
                userInfoColor: "User info text color",
                buttonColors: "Button colors",
                backgroundColor: "Background color",
                iconColor: "Icon color",
                i9: "No background",
                i10: "Sharp",
                i11: "Rounded",
                i12: "Circle",
                smbc: "Social media button color",
                ssbc: "Share & save button color"

            },
            s4: {
                title: "Choose a plan",
                monthly: "Monthly",
                pupm: 'Per user, per month',
                pupy: 'Per user, per year',
                pm: "per month",
                y1: "1 Year",
                y2: "2 Years",
                ppm: "Pay per month",
                ppy: "Pay per year",
                pu: "Per user,",
                it: "in total",
                pMonth: "Per month"
            },
            s5: {
                title: "Checkout",
                users: "Users",
                amount: "Amount",
                usersSmall: "users",
                subtotal: "Subtotal",
                vat: "VAT",
                authorize: "Pay € 0.25 once the authorize the recurring payment",
                total: "Total",
                agree: "I agree to the ",
                terms: "terms",
                button: "Checkout"
            }
        },
        pricingPlan: {
            free: {
                title: "Give it a try",
                subTitle: "Free",
                days30: "For 30 days",
                tryFor: "Try Bizzerd free for 30 days with limited functionalities",
                f1: "Max. 2 users",
                f2: "Access for 30 days",
                f3: "Share your card everywhere",
                f4: "Manage users",
                f5: "Add your social media channels",
                button: "Try for free"
            },
            business: {
                title: "Bizzerd Business",
                from: "From",
                perUser: "Per user, per month",
                pay: "You will pay for each user in the system by the end of the month",
                f1: "Unlimited users",
                f2: "Unlimited amount of shares",
                f3: "Share your card everywhere",
                f4: "Customise your card",
                f5: "Manage users",
                f6: "Share everywhere",
                f7: "Track statistics",
                users: "Users",
                vat: "Excl. VAT",
                button: "Choose Bizzerd Bussiness",
                f8: "Add your social media channels",
            },
            enterprise: {
                title: "Bizzerd Enterprise",
                subTitle: "Business card solution for big corporations",
                subsubTitle: "Starting from 10 users",
                fTitle: "Enterprise solutions",
                quotation: "Download quotation pdf",
                quotationLink: "/Offerte_2024_V18_04-ENG.pdf",
                f1: "Fully custom design",
                f2: "Custom domain name",
                f3: "Bizzerd NFC cards",
                f4: "CRM connections",
                contact: "Contact sales"
            }
        },
        home: {
            pageTitle: "The most widely used digital business card",
            pageSubtitle: "From €0,00 a month",
            pricingButton: "Pricing",
            tryForFree: "Create your digital<br/> business-card",
            pageContent: "Use the features of the only truly digital business card",
            usp1: "Gain <b>insights</b>. Track what recipients do with your business card",
            usp2: "Your business card is a <b>mini-website</b>. Works anytime, even without the app",
            usp3: "<b>Sustainable</b>. Save on paper, print, and transport",
            scanOrClick: "Scan or click and \nsee how it works",
            viewDesigns: "View the designs",
            toTheCards: "To the cards",
            nfcHow: "Bizzerd NFC<br/> How does that work?",
            costs: "Digital businesscards for enterprise",
            letUsDesign: "Let us design your card",
            uploadOwnDesign: "Upload or create your own design",
            upload: "Enterprise",
            startNow: "Request quotation",
            requestDesign: "Let us design your business card",
            enterprise: "Enterprise",
            popularDesigns: "These organisations already chose Bizzerd:",
            fromPrice: "From € 0.00 p/mnd",
            viewAllDesigns: "View all designs",
            viewMoreDesigns: "View more designs ...",
            mkbCompany: "Small- and medium-sized enterprises",
            mkbContent: "Are you an entrepreneur? Self-employed or SME, choose Bizzerd for your business. For yourself and for your employees",
            moreInfo: "View demo",
            corporateCompany: "Corporate solutions",
            corporateContent: "For countless large organizations, Bizzerd creates digital business cards in their own corporate identity. Thus, Bizzerd is used hundreds of thousands of times each day worldwide.",
            faq: {
                q1: "Does Bizzerd only work with NFC?",
                a1: "No, Bizzerd is the only digital business card that you can share even without a physical NFC card. This is due to Bizzerd's unique setup. Each digital business card at Bizzerd is actually a mini-website with a unique domain name. As a result, you can use the digital business card anytime, anywhere.",
                q2: "Do you need to download Bizzerd's app to use the digital business card?",
                a2: "No, it is not necessary to download Bizzerd's app. Bizzerd also works without an app. Bizzerd's app is extra, to add more functionality to the digital business card. Like a library, where you can store the digital business cards of all your colleagues. Moreover, Bizzerd's app has a practical scanner, which allows you to scan and save received paper business cards.",
                q3: "What makes Bizzerd cheaper than other digital business card providers, even though Bizzerd has the most features?",
                a3: "Bizzerd is cheaper (and even FREE for 1 digital business card), because Bizzerd knows economies of scale as the most widely used digital business card. This allows us to share the cost with a large number of users. At Bizzerd, we have chosen to pass this cost advantage directly to the users.",
                q4: "Does Bizzerd only have standard designs or can I use my own corporate identity?",
                a4: "Bizzerd can be made completely in the corporate identity of your organization. For organizations with more than 10 users, Bizzerd can create your design in your corporate identity. You can also choose to have your design agency create the design. If you opt for 1 digital business card (FREE), you can create your design yourself, using the templates on the site. This works very easily and looks professional.",
                q5: "What is the advantage of integration with HRM and CRM packages such as AFAS and Azure?",
                a5: "Organizations with more than 10 users can integrate Bizzerd with their CRM or HRM package (Azure, AFAS). This offers several advantages. Thanks to the integration with HRM packages, it is possible to automatically give new colleagues a digital business card when they join the company. When they leave employment, the digital business card is automatically cancelled. Thanks to integration with CRM packages, it is possible, for example, to record and monitor which customers and prospects have been given a digital business card.",
            },
            faqTitle: "FAQ",
            faq1Title: "How do I create a digital business card?",
            faq1Answer: "You can create your own business card or have Bizzerd do it for you (Enterprise).",
            faq1AnswerHeader1: "Create your own",
            faq1AnswerContent1: "If you want to create your own business card, click on 'Business cards' in the main menu. Choose one of the designs from the collection and create your business card in 3 steps. If you want to use your own design, you can do that too. You can then upload your own design.",
            faq1AnswerHeader2: "Enterprise cards",
            faq1AnswerContent2: "You can also choose to have enterprise business card(s) made by Bizzerd. Bizzerd will then take care of everything involved. Ask for more information or a quotation.",
            faq2Title: "What do I spend per month on a digital business card?",
            faq2Answer: "On the Pricing page you will find our rates. You can already use Bizzerd for 59 cents per month (3 year term). If you have your business cards customized by Bizzerd, you will receive a quote in advance.",
            faq3Title: "Can I easily adjust my data?",
            faq3Answer: "Yes. If you want to change your information, you can do so very easily. Log in with your e-mail address and password. You will be taken to the dashboard. There you can modify all the data on your business card and change the design. If you have forgotten your password, you can request a new one there.",
            faq4Title: "How do I share my digital business card with someone?",
            faq4Answer: "You can share your digital business card with someone in several ways, with or without the Bizzerd app. The most common ways are:",
            faq4AnswerList1: "Send your business card via email, WhatsApp or text message. Click the share icon and share.",
            faq4AnswerList2: "Having the QR code of your online business card scanned by the other person.",
            faq4AnswerList3: "Via AirDrop (iPhone). Depending on how you set up AirDrop, people around you can see your Bizzerd.",
            faq4AnswerList4: "Via the special Bizzerd app (Apple or Android)",
            faq4AnswerList5: "Via the easy to use NFC cards & tags",
            faq5Title: "What is the difference between digital business cards from Bizzerd and other providers?",
            faq5Answer: "There are many differences. Here we list the 2 main differences between Bizzerd and other digital business cards.",
            faq5AnswerList1: "SHARE FUNCTION - Sharing your digital business card always works with Bizzerd: with NFC and without NFC, with the special app and without app, with QR code and without QR code. This is not the case with other digital business cards. With most providers, you can only share a digital business card via an NFC tag or card. Or you can only share your digital business cards with a QR code. With Bizzerd, your digital business card is actually a mini-website. As a result, it always works and you can always share your digital business card.",
            faq5AnswerList2: "PRICE - Another important difference is the price. Bizzerd is the most inexpensive provider. If you choose 1 digital business card, Bizzerd is even free. No other digital business card offers that. For the other options, Bizzerd is the cheapest provider.",
        },
        reviewBlock: {
            pageTitle: "Why pick the most widely used digital business card?",
            pageContent: "Bizzerd is used daily worldwide by hundreds of thousands of professionals. From freelancers to multinationals. Bizzerd is much more than just a business card. Thanks to all the possibilities to present yourself and all the ways you can share your business card. Choose Bizzerd",
            reviewOwner: "Raymond de Boer - Satisfied customer since 2016",
            viewCards: "View business cards"
        },
        loginBlock: {
            pageTitle: "Login",
            blockTitle: "Welcome to Bizzerd",
            pageTitleForgot: "Forgot my password",
            blockTitleForgotRequest: "Request a link for a password reset",
            ForgotblockTitle: "Create a new password",
            email: "E-mail",
            password: "Password",
            password2: "Repeat password",
            enterprise: "Enterprise",
            forgotPassword: "Forgot your password",
            noAccount: "Don't have an account yet?",
            pickCard: "Then select a card here",
            makeDesign: "And create your account to save it."
        },
        pricing: {
            pageTitle: "Prices",
            subTitle: "At Bizzerd, we specialise in crafting digital business cards tailored to the unique corporate identities of countless large organisations globally.",
            freeCard: "1 digital business card for FREE!",
            pickCard: "Create your own digital business card with one of the many designs available",
            "block1": {
                "title": "10-30 digital business cards",
                "subTitle": "€9.48 per person per year excl. VAT",
                "button": "€0.79 per month"
            },
            "block2": {
                "title": "31-100 digital business cards",
                "subTitle": "€8.28 per person per year excl. VAT",
                "button": "€0.69 per month"
            },
            "block3": {
                "title": "101-200 digital business cards",
                "subTitle": "€7.08 per person per year excl. VAT",
                "button": "€0.59 per month"
            },
            "block4": {
                "title": "201-500 digital business cards",
                "subTitle": "€5.88 per person per year excl. VAT",
                "button": "€0.49 per month"
            },
            "block5": {
                "title": "500+ digital business cards",
                "subTitle": "Contact us for more information",
                "button": "Request"
            },

            "inclusive": "This is what you'll always get",
            "usp1": "Extendable term during the contract period",
            "usp2": "You can always update the information on your card",
            "usp3": "Free app to share your card and save other cards!",
            "nfcTitle": "Digital business card with NFC",
            "nfcContent1": "Bizzerd works always, even without NFC. If you choose NFC, sharing your digital business card becomes even easier. You can opt for an NFC tag (phone sticker) or an NFC card (made of sustainable bamboo).",
            "nfcContent2": "NFC card* bamboo, with your own logo, €16 each, excl. VAT",
            "nfcContent3": "NFC tag* (phone sticker), with your own logo, €8 each, excl. VAT",
            "star": "* Includes linking to the URL of your digital business card"
        },
        product: {
            pageTitle: "This is how Bizzerd, the digital business card, works",
            pageContent1: "With Bizzerd, your business card is actually a mini-website. This is convenient because it allows you to give your interactive business card to someone anytime and anywhere, for example via WhatsApp, SMS, e-mail and with the qr code. Or use the handy Bizzerd tag with NFC chip.",
            pageContent2: "The recipient of your business card doesn't have to install an app first. Bizzerd is just easy. But Bizzerd is much more. With Bizzerd, you have a lot of opportunities to showcase yourself, gain insight into your network and build abusiness relationships.",
            buttonText: "View business cards",
            pageSubTitle: "Features",
            insight: "Insights",
            dashboard: "The Dashboard",
            interactive: "Interactive card",
            app: "The Bizzerd app",
            bar: {
                title: "What is a digital business card?",
                content1: "Transform the way you connect with Bizzerd - where your business card becomes a dynamic mini-website. Effortlessly share your interactive business card via platforms like WhatsApp, SMS, and email, or even in person using a QR code.",
                item1: {
                    title: "Create",
                    content: "Create a card for your business and save paper.\n\nLet us design your card or use our extensive editor."
                },
                item2: {
                    title: "Use and Share",
                    content: "Use our app to easily share your card and those of your colleagues.\n\nOpt for NFC cards to share your card without using your phone."
                },
                item3: {
                    title: "Save",
                    content: "The recipient can call you, email you, or get directions through interactive links on the card.\n\nThey can also easily save your information in their phone contacts for future use."
                },
            },
            orangebar: {
                title: "Facilitate Interaction",
                content: "Transform the way you connect with Bizzerd - where your business card becomes a dynamic mini-website. Fully customizable to reflect the identity and needs of your business.",
                item1: {title: "Contact Information", content: "Link your phone numbers, email address, and more."},
                item2: {title: "Social Media", content: "Add your social media and other media channels."},
                item3: {
                    title: "Video",
                    content: "Incorporate moving images into your business card, such as gifs or videos."
                },
                item4: {
                    title: "Documents",
                    content: "Attach presentations, price lists, and quotes, or any other documents you desire."
                },
            },
            brandingBlock: {
                blockTitle: "In your own corporate style",
                blockContent: "Bizzerd supports the corporate style, visual identity, and branding of your organization. Each digital business card is a vibrant expression of the corporate identity, turning employees into true ambassadors.",
            },
            shareBlock: {
                blockTitle: "Share your digital business card as you like",
                blockContent: "Sharing your digital business card is easy. The recipient of your digital business card can instantly save your contact details on their phone. This allows you to quickly and easily share everything you want, such as your job title, company address, and website.\n\nDid you know that you can also link to your digital business card from your email signature? Super handy!\n",
            },
            statisticsBlock: {
                blockTitle: "Usage Statistics and Insights",
                blockContent: "Track what recipients do with your digital business card. Who views your business card? How often? For how long? What gets clicked on? How often is your business card shared again? Bizzerd provides all the answers to these questions. This gives you valuable insights to strengthen relationships, discover and seize opportunities.",
            },
            nfcBlock: {
                blockTitle: "Convenient NFC card",
                blockContent: "Bizzerd is also available as a physical card with NFC chip (Near Field Communication) or as a handy tag. This tag is a sticker with the NFC chip that you can stick on your phone, for example. The tags are also available in sustainable bamboo or wood. Hold the NFC card or the NFC tag against the smartphone of the person with whom you want to share your digital business card. The recipient immediately has your information. Quick and easy, for example during a networking event or business meeting.",
            },
            insightBlock: {
                blockTitle: "Optimum insight",
                blockContent: "Track what recipients do with your digital business card. Who views your business card? How often? How long? What is being clicked on? How often is your business card shared again? Bizzerd gives you all the answers to these questions. This gives you valuable insight to strengthen relationships, identify and capitalize on opportunities."
            },
            dashboardBlock: {
                blockTitle: "Easy-to-understand dashboard",
                blockContent: "You manage your digital business card through the clear and user-friendly Bizzerd dashboard. Very simple. The design of the dashboard ensures that everyone can work with it. With just a few clicks you can adjust the design of your business card and update contact details. This way you make sure that all contact information is always up to date. Do you use multiple business cards, for yourself or for colleagues? Then manage them via the dashboard as well. You add colleagues and invite others to also use a digital business card."
            },
            interactiveBlock: {
                blockTitle: "Interactive business card",
                blockContent: "Bizzerd is the best way to represent yourself and your organization. With a digital business card, every brand comes to life and you express the identity of your organization in a personal way. With Bizzerd you don't just give your business card, but you ensure real interaction. For example, link your social media, website, presentations, photos, quotations, price lists or your CV and make use of your crucial contact moments."
            },
            appBlock: {
                blockTitle: "Practical app",
                blockContent: "Bizzerd always works, even without an app. But with the free app, you get access to practical extra features you don't want to miss. Like a scanner. This allows you to scan other people's paper business cards, save their details in your phone and send them your Bizzerd in return. The app also has a handy library where you save received business cards and gives you access to your colleagues' digital business cards. Useful if you want to put them in touch with your business contacts. The app gives you overview and insight. Indispensable for those who want to streamline business contacts and network professionally."
            }
        },
        register: {
            pageTitle: "create an account",
            accountDetails: "Account information",
            email: "E-mail",
            password: "password",
            register: "Register"
        },
        shoppingCart: {
            pageTitle: "Shopping basket",
            subTitle: "Did you know that you can always add / adjust more colleagues and designs later?",
            nothingHere: "There is nothing here! First add a card",
            nfc: "Add a request for NFC (optional)",
            toTheCards: "To the cards",
            digitalCardNormal: "Digital business card - regular version",
            year: "year",
            card: "card",
            py: "P.J.",
            or: "or",
            toEnterprise: "To enterprise",
            enterCode: "Enter promotion code",
            total: "Total (ex. VAT)",
            btw: "21% VAT",
            totalIncBtw: "Total (incl. VAT)",
            agreeTo: "I agree with the",
            toc: "Terms and Conditions",
            privacyPolicy: "Privacy Policy",
            pay: "To settle"
        },
        subscription: {
            pageTitle: "Choose your subscription",
            block1: {
                title: "1 year - Try it!",
                subTitle: "€ 9.48 per person per year ex. VAT",
                button: "€ 0.79 p/mnth"
            },
            block2: {
                title: "2 years - most chosen",
                subTitle: "€ 8.28 per person per year ex. VAT",
                button: "€ 0.69 p/mnth"
            },
            block3: {
                title: "3 years - Smart purchasing",
                subTitle: "€ 7.08 per person per year ex. VAT",
                button: "€ 0.59 p/mnth"
            },
            inclusive: "Always inclusive",
            usp1: "Renewable term during the term",
            usp2: "You can always adjust the data on your card",
            usp3: "Free app to share your card and save other cards!"
        }
    },
    hi: 'hoi',
    cardPlaceholders: {
        name: 'First name Surname',
        jobTitle: 'Job title',
        phoneNumber: 'Phone number',
        email: 'Email Address',
        companyName: 'Company Name',
        zipCode: 'ZIP code',
        address: 'Address',
        websiteName: 'Website',
        twitter: 'Twitter',
        facebook: 'Facebook',
        linkedin: 'Linkedin',
        instagram: 'Instagram',
    },
    generic: {
        nextStep: 'Next step',
        previousStep: 'Previous step',
        logIn: 'Sign in',
        logOut: "Sign out",
        shoppingCart: 'Shopping cart',
        changeDesign: "Change design",
        insights: "Insights",

        settings: "Settings",
        contact: "Contact us",
        manageBusinesscards: "Manage Business cards",
        manageSubscriptions: "Manage Subscriptions",
    },
    shoppingCart: {
        addColleaguesAfter: "Did you know? That you can always add colleagues and change or add more designs later",
        title: "Shopping cart",
        discountCode: "Enter a code",
        total: "Total",
        totalIncBtw: "Total (incl. VAT)",
        toc: "Terms and conditions",
        accept: "I agree and accept the",
        and: "and",
        pp: "privacy policy",
        addColleague: "Add colleague",
        pay: "Pay",
        or: "or",
        item: {
            title: "Digital business card normal",
            year: "year(s)",
            py: "p.y.",
            card: "card",

        }
    },
    colleagues: {
        active: "Active",
        activate: "Activate",
        view: "View",
        edit: "Edit",
        fullname: "Full name",
        viewColleagues2: "View colleagues",
        inviteSendTo: "Invite send to:",
        screenTooSmall: "Your current screen is too small to use the editor, use a computer.",
        noFirefox: "Currently, the editor is not supported in firefox. Use a different browser",
        viewDesigns: "View designs",
        viewColleagues: "View colleagues",
        viewInvites: "View invites",
        addColleague: "Add a colleague",
        addNewColleague: "Add a new colleague",
        inviteColleagues: "Invite colleagues",
        orClickColleagues: "All colleagues",
        orClickDesigns: "All designs",
        orClickEmailInvite: "More options",
        continueWithData: "Continue with data",
        generateLink: "Generate registration link",
        orClickInvites: "All invites",
        registrationLinkContent: "Everyone with a registration link can join your Bizzerd environment. You can determine the duration that the link is valid yourself.",
        firstname: "First name",
        lastname: "Last name",
        content: "Content",
        status: "Status",
        expiration1: "Expires in 1 day",
        expiration7: "Expires in 7 days",
        expiration30: "Expires in 30 days",
        expirationNever: "Never expires",
        copy: "Copy",
        generate: "Generate",
        deactivate: "Deactivate link",
        linkExpires: ". Delete this link or generate a new one?",
        deleteLink: "This link expires ",
        email: "Email",
        jobtitle: "Job title",
        mobile: "Mobile",
        add: "Add a colleague",
        invite: "Invite",
        inviteThroughEmail: "Invite colleagues via emails",
        inviteOverview: {
            title: "View and edit your colleagues",
            fullname: "Full name",
            view: "View",
            edit: "Edit",
            delete: "Delete",
            account: "Account",
            active: "Active",
            inactive: "Inactive",
            viewInvites: "View your invites",
            expiresOn: "Expires on",
            content: "Content",
            status: "Status",
        },
        colleagueOverview: {
            confirmDelete: "Are you sure you want to delete this person?",
            confirmInvite: "Are you sure you want to invite this person?",
            title: "View and edit your colleagues",
            invite: "Invite",
            inviteAgain: "Invite again",
            fullname: "Full name",
            view: "View",
            edit: "Edit",
            delete: "Delete",
            account: "Account",
            active: "Active",
            inactive: "Inactive",
            viewInvites: "View your invites",
            expiresOn: "Expires on",
            content: "Content",
            status: "Status",
        },
        addColleagueModal: {
            title: "New colleague? Enter the info here.",
            pageTitle1: "Personal data",
            pageTitle2: "Contact data",
            pageTitle3: "Social media",
            back: "Back",
            edit: "Edit",
            cancel: "Cancel",
            cardExample: "This is what the business card will look like.",
            chooseDesign: "Choose design",
            chooseDesignFirst: "Choose a design first.",
            save: "Save",
            page1: {
                firstname: "First name",
                lastname: "Last name",
                fullname: "Full name",
                appendix: "Appendix",
                prefix: "Prefix",
                email: "Email",
                jobtitle: "Job title",
                bizzerdName: "Bizzerd-name",
                personalQuote: "Personal quote",
                profilePicture: "Profile picture",
                noPictureFound: "No profile picture found",
                choosePicture: "Choose a picture",
                removePicture: "Remove picture"
            },
            page2: {
                phoneNumber: "Phone number",
                mobileNumber: "Mobile number",
                address: "Address"
            },
            page3: {
                website: "Website",
                linkedin: "LinkedIn",
                facebook: "Facebook",
                twitter: "Twitter",
                instagram: "Instagram",
                pinterest: "Pinterest",
            }

        }

    },
    contact: {
        title: 'Contact',
        subTitle: 'For questions, quotation-requests or support, fill in the form below',
        fields: {
            name: "Name",
            companyName: "Company name",
            email: "Email",
            phoneNumber: "Phone number",
            message: "Message",
        },
        send: "Send",
        faq: {
            faqItem1: {
                question: "What is bizzerd?",
                answer: "Bizzerd is the digital, paperless, contactless business card. Digital, always accessible. Do you have your phone with you? Then your business cards will always be within reach. Always up-to-date. Because, when your contact details change, you can easily update them. Paperless, so socially responsible. Yearly, millions of business cards are thrown away unused. If it were up to bizzerd that would be a thing of the past. Contactless, you can share your digital business card from a distance, save and hygienic."
            }, faqItem2: {
                question: "How do I use Bizzerd?",
                answer: "Bizzerd is always available: online and in the app. Thanks to Bizzerd your business card is always available at the unique URL. This URL ensures that anyone can reach your businnesscard from anywhere in the world at any time."
            }, faqItem3: {
                question: "For whom is bizzerd intended?",
                answer: "Bizzerd is for everyone. Bizzerd is the digital business card for professional use. Bizzerd is being used from small companies to multi-million organisations."
            }, faqItem4: {
                question: "Which organizations use Bizzerd?",
                answer: "A large number of organizations within SMEs, government and (international) business use bizzerd. These include construction companies, real estate organizations, pharmaceutical companies, housing associations, banks, insurance companies, yacht and shipbuilding companies, installation companies, municipalities, schools, etc."
            }, faqItem5: {
                question: "What is the difference between paper business cards and Bizzerd?",
                answer: "Paper business cards are less easy to use. They wrinkle quickly, the information is quickly out of date (for example, a new phone number or address) and they get lost quickly. In addition, paper business cards are easy to forget. Bizzerd you always have with you (unless you forget your phone). Your digital business card is always up to date and is contemporary."
            }, faqItem6: {
                question: "Why Bizzerd?",
                answer: "We were amazed that in this digital age there was still no useful alternative to paper business cards. Because paper business cards are quite clumsy to use. And they cost paper, thus trees and energy. That can and should be different, we thought. Smarter, better, more convenient. That's why we came up with Bizzerd. With Bizzerd you always have your business cards with you. And you contribute to a sustainable world. The more people use Bizzerd, the better it gets."
            }, faqItem7: {
                question: "What does Bizzerd cost?",
                answer: "Bizzerd has prices for zzp'ers, for SMEs and for large organization. As a self-employed person, you pay 79 cents per month. For SMEs, rates range from 79 cents to 49 cents per user per month, plus setup fees. For large organizations, we provide a custom quote. More information can be found on our rates page"
            }, faqItem8: {
                question: "How do i share my digital business card?",
                answer: "You can share your online business card in several ways. The most common ways are:",
                answerLine1: "Send the url of your online business card via email, WhatsApp or SMS.",
                answerLine2: "Have the other person scan the QR code of your online business card.",
                answerLine3: "Keep the phones close together (if your phones both support near field communication (NFC))",
                answerLine4: "Via AirDrop (iPhone)",
                answerLine5: "Via the bizzerd app"
            }, faqItem9: {
                question: "Where do I find the bizzerd app?",
                answer: "The Bizzerd app for Android can be found at Google Play. For iPhone you can find it in the App Store. You can get there quickly via the Bizzerd website."
            }, faqItem10: {
                question: "How does AirDrop work?",
                answer: "If you have an iPhone 5 or later, iPad (4th generation or later), iPad mini (1st generation or later), or iPod Touch (5th generation or later), your device has AirDrop. With this function you can share data quickly and easily via Bluetooth and WiFi. You switch the function on or off via the Control Center (from one of the home screens of iOS make a swipe from bottom to top).\nTap the AirDrop icon and choose whether you want to receive and share files with contacts or anyone nearby. At the same time, you will see the icon for bluetooth light up.\nThen select your bizzerd. Tap the share button and choose the appropriate recipient under AirDrop. Note: this must also have AirDrop enabled. If the other person has received your Bizzerd, he or she can refuse or accept it. If he chooses Accept, he can immediately save your Bizzerd to Contacts."
            }, faqItem11: {
                question: "How can I share my digital business card?",
                answer: "\n" +
                    "You can share bizzerd in many ways. As a 'signature' under your emails. And via SMS, WhatsApp or other social media, via the QR code and contactless (via Near Field Communication, NFC or AirDrop). The more possibilities you combine, the easier people get to know you."
            }, faqItem12: {
                question: "I have received a digital business card. What can I do with that?",
                answer: "You can automatically save the sender's contact information in 'Contacts'. And you can save the received digital business card as an icon on your phone (as a 'shortcut'). So that you always have the sender's details at hand."
            }, faqItem13: {
                question: "How can I save a digital business card as a shortcut on my phone?",
                answerLine1: "For iOS (iPhone):",
                answerLine2: "Open the business card.",
                answerLine3: "Click on the share icon in the bottom menu.",
                answerLine4: "Pick 'put in the homescreen'.",
                answerLine5: "For Android and other systems:",
                answerLine6: "Click on the menu button of your phone or",
                answerLine7: "Click on the share icon.",
                answerLine8: "Add a shortcut to the home screen (menu options might differ from names)",
            }, faqItem14: {
                question: "What about my privacy?",
                answer: "Your privacy is paramount at bizzerd. You decide which data will appear on your digital business card. And bizzerd guarantees that nothing will be done with your data. You can read more about this in the privacy statement. bizzerd works for various large (international) organizations. Our information security and privacy assurance (AVG, GDPR) meets the requirements of these organizations and is regularly tested. Bizzerd complies with all guidelines of the Dutch Data Protection Authority."
            }, faqItem15: {
                question: "What should I do if I no longer want a digital business card?",
                answer: "If you no longer wish to use a digital business card, you can simply delete your account after logging in. You can also always send us an email. We will then delete your digital business card. Note: use the e-mail address known to us."
            }, faqItem16: {
                question: "What is the difference between Bizzerd and LinkedIn?",
                answer: "Bizzerd is your digital business card. You can share your digital business card with everyone you meet (online and offline). Just like a paper business card, but easier, cheaper and with more functions. LinkedIn is a business online network. You usually only put limited contact details on your LinkedIn page. With bizzerd you share all (contact) data you want to share."
            }, faqItem17: {
                question: "What are the possibilities for an organization that uses Bizzerd?",
                answer: "Bizzerd offers many possibilities for organizations. With bizzerd you can provide all employees with a digital business card at the same time in no time at all. Perfectly in line with your corporate identity. Users can manage the contact details themselves. It is possible to manage the contact details centrally. \\n\\nIt is even possible to link price lists, brochures, quotations, presentations, etc. to bizzerd. This makes bizzerd the most convenient (re)presentation tool at trade fairs and exhibitions."
            }, faqItem18: {
                question: "I want to use bizzerd for my organization for all employees. What should I do?",
                answer: "Contact us. We then introduce Bizzerd in 2 steps: 1) Creating the design, fitting it to your company's style. 2) Enter the details of all your colleagues. Bizzerd is then ready for use, each employee has their own digital business card in the corporate identity of the organization"
            }, faqItem19: {
                question: "How do I make sure the correct style and details are shown on the cards of my colleagues?",
                answer: "If you use bizzerd for all employees, then all digital business cards are automatically formatted in the style of your organization. Your design or advertising agency can make the design. We can also take care of that for you. The contact details are centrally managed by an administrator within your organization. As a result, all employees have a correct digital business card. You can also choose to let all users within your organization manage their own bizzerd."
            }, faqItem20: {
                question: "How are bizzerd's rates structured?",
                answer: "Bizzerd uses prices for freelancers, for SMEs and for large organizations. As a freelancer you pay 79 cents per month. For SMEs, the rates range from 79 cents to 49 cents per user per month, plus setup costs. We provide a tailor-made offer for large organizations. More information can be found on our rates page."
            }, faqItem21: {
                question: "How does the link between Bizzerd and AFAS work?",
                answer: "Users of AFAS Software for HR can link bizzerd. With this link they can automatically create business cards for new employees that are entered in AFAS. Another advantage is that the information on every business card is automatically updated if data changes, such as function, surname, location data, telephone number, etc. Also with a new corporate identity or graphic design adjustments, all digital business cards are automatically adjusted in 1 go."
            },

        }
    },
    product: {
        title: "Share your business card",
        subtitle: "In your style. In your way",
        android: "Android",
        ios: "iPhone",
        video: "https://bizzerd2.bizzerd.com/api/videos/bizzerdEN.mp4",
        web: "Mac or Windows PC",
        block1: {
            title: "Share your bizzerd with others",
            content: "Sharing can be done in many ways: via email, WhatsApp, SMS, QR code, NFC (Android) and AirDrop (iPhone)."
        }, block2: {
            title: "Scan paper business cards",
            content: "Download the app, scan your received paper business cards and immediately send your Bizzerd back."
        }, block3: {
            title: "Add to your contacts",
            content: "Did you receive a bizzerd? Save it to your phone with the touch of a button and add it to your contacts."
        }, block4: {
            title: "Add files",
            content: "Easily link files to your bizzerd. For example photos, presentations, quotations, price lists or your curriculum vitae."
        }
    },
    headerHome: {
        standard: "Standard",
        maximum: "maximum",
        fromPrice: "price from: ",
        ppm: " p.p.p.m.",
        // title: "The digital business card",
        // subtitle: "Hundreds of thousands of users choose Bizzerd, from self employed to multinationals",
        title: "Digital, paperless business card",
        subtitle: "Contacless. Practical. Sustainable.",
        button: "Start here",
    },
    navBar: {
        home: "Home",
        businessCards: "Business cards",
        pricing: "Price",
        contact: "Contact",
        product: "How to use",
        enterprise: "Enterprise"
    },
    feedback: {
        button: "Feedback",
        title: "Your feedback helps!",
        subtitle: "This form is for website feedback only.",
        description: "Do you have questions about the design tool, adding colleagues, subscriptions or other services? Then ask our colleagues from customer service via email, chat or telephone.",
        question: "What are you planning on doing on our website today? *",
        choice: "Make a choice",
        feedbackDescription: "Leave your feedback here. The more specific you are, the better we will be able to improve bizzerd.com!",
        send: "Send",
    },
    bulletPoints: {
        bullet1: "Always unique and personal",
        bullet2: "From €0,00 per month",
        bullet3: "Convenient, Practical, Durable",
    },
    footer: {
        seoTitle: "Why choose Bizzerd?",
        seoContent: "Bizzerd is the digital, paperless business card. Bizzerd is practical: it's easy to use. Bizzerd is convenient: you always have your business cards on hand. Bizzerd is durable: No paper is being wasted. With a digital business card, your details will always be up-to-date. You can share your Bizzerd limitless therefore, you will never run out of business cards the moment it matters most.",
        bizzerd: {
            title: "Bizzerd",
            home: "Home",
            businessCards: "Business cards",
            product: "Product",
            contact: "Contact",
            pricing: "Pricing"
        },
        service: {
            title: "Service",
            toc: "Terms and Conditions",
            privacyStatement: "Privacy statement",
            appManual: "App manual",
            appManualLink: "appUserGuide.pdf",
            webManual: "Web manual",
            userTips: "User tips",
            userTipsLink: "/bizzerd_user_tips.pdf",
        }, socialMedia: {
            title: "Social media",
            linkedin: "LinkedIn",
            whatsapp: "Whatsapp",
            facebook: "Facebook",
        }, contactInfo: {
            phoneTitle: "Phone number",
            phoneNumber: "(0031) (0) 88 227 22 22",
            officeTitle: "Bizzerd office",
            officeLocation1: "Rembrandtlaan 24",
            officeLocation2: "3723 BJ Bilthoven",
            officeLocation3: "Netherlands",
        }, kvkBtw: {
            title: "Chamber of Commerce / VAT (Netherlands)",
            content1: "Chamber of Commerce",
            content2: "KvK 63663449",
            content3: "VAT NL 855341555 B01",
        }
    },
    bizzerdCommunity: {
        bizzerdClimate: {
            title: "This is how you contribute to the environment and climate with Bizzerd.",
            content: "Did you know that there are 10 billion business cards being printed each year? That is a around 27 million per day. Almost 90% of these cards end up in the trash after one week. The rest will follow soon after. The transport, printing and making of the business cards has great impact on the environment. Choose sustainable, choose Bizzerd.",
        },
        bizzerdEaseOfUse: {
            title: "The convenience of a digital business card",
            content: "Easy to use: you will never have to reprint your business card when your contact info changes. Easy for the recipient: the recipient of your digital business card can save your info with 1 press of a button",
        }
    },
    home: {
        headerTitle: 'Digital, paperless business card',
        headerSubTitle: 'Hundreds of thousands are already using Bizzerd. From freelancers to multinationals.',
        headerButton: 'Start here',
        actionButtonLook: 'Look at the designs',
        actionButtonUpload: 'Upload or make your own design',
        actionButtonDesign: 'Let us design your card',
        popularDesignsTitle: 'Popular Designs',
        popularDesignsButton: 'Choose a design and begin!',
        youtubeTitle: 'This is how easy it is!',
        counterMade: 'Made',
        counterUsers: 'Users',
        counterAverageGrade: 'Average grade',
    },
    popularDesigns: {
        title: "Popular Designs",
        button: "Choose a design and begin!",
        from: "from:",
        fields: {
            firstname: 'First name',
            lastname: 'Last name',
            fullname: "First name Last name",
            jobtitle: 'Job title',
            tel: '+31 (0)88 123 45 67',
            mobile: "+316 123 45678",
            email: 'firstname@bizzerd.com',
            adresveld: 'City: Street house number',
            website: 'https://www.bizzerd.com/'
        },
        card1: {
            title: "General practitioner",
            subTitle: "Digital business card"
        }, card2: {
            title: "Art gallery",
            subTitle: "Digital business card"
        }, card3: {
            title: "Veterinary",
            subTitle: "Digital business card"
        }
    },
    actionButtons: {
        title1: "View the designs",
        title2: "Upload your own design",
        title3: "Let us design your card"
    },
    enterprise: {
        title: "Have your business card customized",
        listItem1: "In your corporate identity ",
        listItem2: "Technical support",
        listItem3: "Service and customization",
        listItem4: "Assistance with internal introduction and use",
        buttonText: "Talk to sales",
        cardsEnterpriseTitle: "Customized digital business card",
        cardsEnterpriseText: "Switch to the digital business card with the entire organization. Quickly and easily. We take all the steps off your hands. From the design - in your corporate identity - to the automatic loading of all data. We also advise on the use and support the internal communication within your organization. Completely tailored to your needs.",
        prosEnterprise: "BENEFITS OF CUSTOMIZATION",
        pros1Title: "Always up to date",
        pros1Text: "New data, such as a different e-mail address? Update them immediately!",
        pros2Title: "Various language options",
        pros2Text: "Easy for international organizations.",
        pros3Title: "Sustainable",
        pros3Text: "Paperless, so you save trees and transportation. Virtually no CO2 emissions. Socially responsible.",
        pros4Title: "Bizzerd app",
        pros4Text: "Truly easy sharing and storage of business cards.",
        pros5Title: "Professional",
        pros5Text: "New data, such as a different email address? Update them immediately!",
        pros6Title: "Presentation platform",
        pros6Text: "Link presentations, videos or price lists to your digital business cards. Useful for trade shows and conferences.",
        alreadyUsingBizzerd: "THESE ORGANIZATIONS ALREADY USE BIZZERD",
        evenEasierTitle: "EVEN EASIER: INTEGRATE THE DIGITAL BUSINESS CARD WITH YOUR HRM SYSTEM",
        evenEasierSubtitle: "Benefit from all the advantages of digital business cards and more. Link bizzerd to your HRM system (AFAS, Microsoft):",
        evenEasierlist1: "New colleagues automatically receive a digital business card. Ticking is enough.",
        evenEasierlist2: "Automatically adjust each business card to a new corporate identity or to design changes.",
        evenEasierlist3: "All business cards are updated at once when company information changes.",
        evenEasierlist4: "Monitoring. Statistics for marketing and sales information.",
        privacyEnterpriseTitle: "PRIVACY",
        privacyEnterpriseText: "Bizzerd is trusted by many large (international) organizations and meets the requirements for information security and protection of personal data (AVG, GDPR). Our data security is regularly reviewed. See our privacy statement for details.",
    },
    ourCustomers: {
        card: "Your own digital business card always within reach",
        qr: "Easily sharable via QR, airdrop and web",
        library: "Your colleagues and scanned cards stored in your library",
        scanner: "Scan paper cards and send your digital card back",
    },
    pricing: {
        title: "Price",
        subtitle: "What option suits you bests? All prices are ex. VAT",
        pppm: "Per user, per year",
        mostChosen: "Most popular",
        year: "Year",
        discountOf: "Discount of",
        yearly: "a year",
        yearlyCapital: "Annually",
        yearly2: "Annual",
        pay: "billing",
        multiple: "Multiple",
        designs: "Designs",
        numberOf: "Unlimited",
        users: "users",
        central: "Controlled",
        control: "centrally",
        payYearly: "",
        support: "Support",
        and: "and",
        support2: "assistance",
        total: "year total",
        start: "Start now",
        contactSales: "Contact sales",
        allOptions: "Full payment plans",
        sellingPoint1: "Choose from 100+ designs",
        sellingPoint2: "Support and assistance",
        sub1: "Give it a try",
        sub2: "Simply use it",
        sub3: "Use it longer",
        enterprise: "Enterprise",
        year1: "1 year",
        year2: "2 years",
        year3: "3 years",
        yearenterprise: "From 1 year",
        invoice1: "1 invoice for 1 year",
        invoice2: "1 invoice for 2 years",
        invoice3: "1 invoice for 3 years",
        invoiceenterprise: "1 invoice from 1 year",
    },
    businessCards: {
        title: "Business cards",
        results: "Results",
        card: "Card",
        branches: {
            branch1: "Hospitality",
            branch2: "Real estate",
            branch3: "Health and social work",
            branch4: "Retail and sales",
            branch5: "Travel and accommodation",
            branch6: "Government",
            branch7: "Pet and pet care",
            branch8: "Financial and insurance",
            branch9: "Information technology",
            branch10: "Religious and spiritual",
            branch11: "Business",
            branch12: "Construction, renovation and reparation",
            branch13: "Farming",
            branch14: "Education and child care",
            branch15: "Beauty",
            branch16: "Production",
            branch17: "Marketing and communication",
            branch18: "Sport and fitness",
            branch19: "Car and transport",
            branch20: "Art and entertainment",
        },
        showMore: "Show more",
        showLess: "Show less",
        image: "Image",
        mostChosen: "Most popular",
        newlyAdded: "Newly added",
        bigPicture: "Big profile-picture",
        smallPicture: "Small profile-picture",
        noPicture: "No profile-picture"

    },
    detail: {
        title: "Design",
        year: "Years",
        start: "Start here",
        priceInfo: "Price information with the same design",
        duration: "Duration",
        price: "Price",
        discount: "Discount",
        descriptionTitle: "Description",
        description: "Digital business card | Bizzerd | Design",
    },
    editor: {
        title: "Create your card in 3 easy steps",
        subtitle: "Fill in the information that will appear on your business card.",
        step: "Step",
        step1Title: " Your contact details",
        step2Title: " Buttons",
        step3Title: " Colors and logos",
        toStep: "To step",
        profilePicture: "profile picture",
        logoPosition: "Logo position",
        iconColor: "Icon color",
        textColor: "Text color",
        logoSize: "Logo size",
        left: "Left",
        middle: "Middle",
        right: "Right",
        next: "Next",
        upsale1: "Do you want something else? Click",
        upsale2: "here",
        upsale3: "and contact us!",
        fields: {
            firstname: "First name",
            lastname: "Last name",
            fullname: "Full name",
            pinterest: "Pinterest",
            email: "Email",
            mobile: "Mobile",
            company: "Company name",
            tel: "Telephone",
            adres: "Address",
            title: "Job title",
            facebook: "Facebook",
            twitter: "Twitter",
            instagram: "Instagram",
            linkedin: "LinkedIn",
            youtube: "Youtube",
            website: "Website",
            profilePic: "Profile picture",
            bizzerdName: "Bizzerd name",
            bizzerd: "Design",

        }
    },
    subscription: {
        title: "Choose your subscription",
        subtitle: "You can always extend the duration",
        next: "Next",
        package1: {
            title: "Taking a look",
            subtitle: "One year",
        },
        package2: {
            title: "Just using",
            subtitle: "Two years",
        },
        package3: {
            title: "Use longer",
            subtitle: "Three years",
        },
        mostChosen: "Most popular",
        pppm: "Per person, per month ex VAT",
        pjeb: "per year ex VAT",
    },
    register: {
        title: "Register",
        companyInfo: "Company information",
        companyName: "Company name",
        address: "Invoice address",
        accountInfo: "Account information",
        email: "Email",
        password: "Password",
        showPassword: "Show password",
        register: "Register",
        dutch: "Dutch",
        english: "English",
        mainLanguage: "Main language"
    },
    login: {
        sso: "Sign in SSO",
        title: "Sign in",
        opMaat: "Enterprise",
        email: "Email",
        password: "Password",
        logIn: "Sign in",
        forgotPassword: "Forgot password",
        showPassword: "Show password",
        noAccount: "No account yet? Pick a card here!",
    },
    companySettings: {
        cancel: "Cancel",
        view: "Download",
        editCompanyName: "Edit your company name or domain",
        companyName: "Company name",
        domain: "Domain name",
        deleteCompanyTitle: "Delete your company",
        deleteCompanySubtitle: "If you delete your company it will be permanent. We cannot undo this.",
        deleteCompany: "Delete company",
        viewInvoices: "View your invoices",
        ownDomain: "Custom domain name",
        requestSend: "A request has been send",
        requestAlreadySend: "A request has already been send",
        request: "Request",
        edit: "Edit",
        save: "Save",

    },
    invoice: {
        invoice: "invoice",
        year1: "1 Year Bizzerd",
        year2: "2 Years Bizzerd",
        year3: "3 Years Bizzerd",
        invoiceCapitals: "INVOICE",
        invoiceNumber: "Invoice number",
        invoiceDate: "Invoice date",
        destinedFor: "Bizzerd | The digital business card",
        description: "Description",
        price: "Price",
        amount: "Amount",
        date: "Date",
        total: "Total",
        subTotal: "Subtotal",
        priceWasPaid: "The above amount has been debited.",
        getInvoices: "Getting invoices",

    },
    subscriptionSettings: {
        active: "Active",
        inactive: "Inactive",
        year1: "1 year",
        year2: "2 years",
        year3: "3 years",
        subscriptionOverview: "Subscription overview",
        year: "Years",
        amount: "Amount",
        available: "Available",
        used: "Used",
        firstname: "First name",
        lastname: "Last name",
        purchaseSubscriptions: "Purchase subscriptions",
        subscriptionShortage: {
            part1: "You are ",
            part2: " subscriptions short. Purchase more subscriptions!",
            part3: "You have ",
            part4: " subscriptions left",
        },
        singleYear: "1 Year Subscription",
        doubleYear: "2 Years Subscription",
        tripleYear: "3 Years Subscription",
        subscriptionAfterPurchase: {
            part1: "After purchasing you will be ",
            part2: " subscription(s) short",
            part3: "After purchasing you will have ",
            part4: " subscriptions left",
        },
        perYear: "Years",
        monthPrice: "Month price",
        totalPrice: "Total price",
        total: "Total",
        pay: "Pay"

    },
    chooseDesigns: {
        title: "Click to edit a design.",
        retrievingCards: "Retrieving business cards.",
        orClickHere: "Or click here to create a new design",
        noDesignsFound: "It looks like you haven't made any designs yet!",
        createNewDesign: "Create a new design",
        fields: {
            firstname: "First name",
            lastname: "Last name",
            fullname: "First name Last name",
            email: "Email",
            mobile: "Mobile",
            company: "Company name",
            tel: "Telephone",

            adres: "Address",
            title: "Job title",
            facebook: "Facebook",
            twitter: "Twitter",
            instagram: "Instagram",
            linkedin: "LinkedIn",
            youtube: "Youtube",
            website: "Website",
            bizzerdName: "Bizzerd name"

        },

    },
    chooseTemplate: {
        title: "Click to edit a design.",
        next: "Next",
        previous: "Previous",
        fields: {
            firstname: "First name",
            lastname: "Last name",
            fullname: "First name Last name",
            email: "Email",
            mobile: "Mobile",
            company: "Company name",
            tel: "Telephone",
            adres: "Address",
            title: "Job title",
            facebook: "Facebook",
            twitter: "Twitter",
            instagram: "Instagram",
            linkedin: "LinkedIn",
            youtube: "Youtube",
            website: "Website",
            bizzerdName: "Bizzerd name"

        },

    },
    designTool: {
        fields: {
            firstname: "First name",
            lastname: "Last name",
            fullname: "First name Last name",
            email: "Email",
            prefix: "Prefix",
            appendix: "Appendix",
            mobile: "Mobile",
            Mobile: "Mobile",
            company: "Company name",
            tel: "Telephone",
            adres: "Address",
            title: "Jobtitle",
            jobtitle: "Jobtitle",
            facebook: "Facebook",
            twitter: "Twitter",
            instagram: "Instagram",
            linkedin: "LinkedIn",
            youtube: "Youtube",
            website: "Website",
            bizzerdName: "Bizzerd name",
            quote: "Personal quote",
            companyname: "Company name",
            Delen: "Share icon",
            Opslaan: "Save icon",
            Tel: "Phone icon",
            Deel: "Share icon",
            adresveld: "Address",
        },

        objectFit: {
            fill: "Fill",
            cover: "Cover",
            contain: "Contain",
        },
        submit: "Search",
        save: "Save",
        saved: "Saved",
        notSaved: "Not saved",
        type: "Type",
        position: "Position",
        duplicate: "Duplicate",
        share: "Share",
        profilePicture: "Profile Picture",
        toBack: "To Back",
        toFront: "To Front"
    },
    addColleague: {
        title: "Add manually",
        subtitle: "Did you know? You can always add more colleagues later, manually or by inviting them",
        colleague: "Colleague",
        complete: "Complete",
        added: "Added",
        update: "Update",
        add: "Add",
        extraColleague: "Add extra colleague",
        toShoppingCart: "To shopping cart",
    },
    cookieConsentButton: {
        title: "Cookies",
        subtitle: "Settings",
        description: "Welcome to Bizzerd! We're happy you are here and we want you to know that we respect your privacy. You have the right to determine how we collect and use your data. Listed below are the different categories on which we collect data. If you have any questions feel free to send us an email at cookies@bizzerd.com. Or check out our",
        allowAll: "Accept all",
        necessaryCookie: "Strictly essential cookies",
        performanceCookie: "Session cookies",
        functionalCookie: "Functional cookies",
        targetGroupCookie: "Target audience cookies",
        saveChoices: "Save choices",
    }
}

import React, {Component} from 'react'
import {registerWithKey, submitNewUser} from "../../../actions/registerActions";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {ErrorMessage} from "../../common/AuthErrorComponent";
import GradientContainer from "../../common2/containers/gradient.container";
import PageTitle from "../../common2/fields/text/pagetitle.text";
import SubSubSubTitle from "../../common2/fields/text/subsubsubtitle.text";
import MainButton from "../../common2/buttons/action_main/main.button";
import ButtonColors from "../../common2/buttons/button.colors";
import InputField from "../../common2/fields/editable_input/text.input";

class Register extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ''
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({errors: nextProps.errors.errors});
        }
    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    onSubmit = () => {
        //If the user was not invited submit just the email
        if (this.props.match.params.key === undefined) {
            this.props.submitNewUser({email: this.state.email}, this.props.history);
        }
        //If the user was invited: Submit email + invite code.
        else {
            this.props.registerWithKey({email: this.state.email, key: this.props.match.params.key}, this.props.history)
        }
    };

    render() {
        const {errors} = this.state;
        let error;
        if (errors !== undefined) {
            error = <ErrorMessage errors={errors}/>
        }
        return (
            <div>
                <GradientContainer style={{marginTop: 0}}>
                    <div className="wrapper" style={{marginLeft: "0px"}}>
                        <div className="card" style={{borderRadius: "10px"}}>
                            <PageTitle text={"Aan de slag met bizzerd"}/>
                            <br/>
                            <SubSubSubTitle
                                text={"Vul hier het e-mailadres in waarmee je jouw bizzerd-account wilt maken."}/>
                            <br/>

                            <InputField placeholder={"E-mailadres"} id={"email"} label={"E-mailadres"}
                                        onChange={this.onChange} value={this.state.email}
                                        validation={"^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$"}
                            />
                            <div className="form-group__footer" style={{justifyContent: "flex-end"}}>
                                <MainButton text={"Bevestigen"} color={ButtonColors.orange} onClick={this.onSubmit}/>
                            </div>
                            {error}
                        </div>
                    </div>
                </GradientContainer>
            </div>

        );
    }
}

Register.propTypes = {
    errors: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    errors: state.errors
});

export default connect(mapStateToProps, {submitNewUser, registerWithKey})(Register);
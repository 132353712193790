import React from "react";
import '../text.styles.css'

export interface subSubTitleProps {
    text: string,
    style?: {}
}

class SubSubTitle extends React.Component<subSubTitleProps, {}> {
    render() {

        return <h3 style={this.props.style} className={"page-sub-sub-title"}>{this.props.text}</h3>;
    }

}

export default SubSubTitle;
import {GET_VARIATIONS} from "../actions/types";
import isEmpty from '../validation/is-empty'

const initialState = {
    success: false,
    variations: [],
    variation: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_VARIATIONS:
            return {
                ...state,
                success: !isEmpty(action.payload),
                variations: action.payload
            };
        default:
            return state;
    }
}
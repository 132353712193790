import ButtonColors from "../common2/buttons/button.colors";
import "./noeditorflow.css"
import React from "react";

export const FlowButton = ({onClick, title, subTitle, color, icon,type}) => {
    return <button type={type}
        className={"flow-button " + ButtonColors[color]}
        onClick={onClick}>
        {icon}

        <div className={"flow-button-text"}>
            <span className={"flow-button-title flow-text-" + ButtonColors[color]}>{title}</span>
            <span className={"flow-button-sub-title flow-text-" + ButtonColors[color]}>{subTitle}</span>
        </div>
    </button>
}
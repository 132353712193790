import React from 'react'
import GrayContainer from "../common2/containers/gray.container";
import NavigationHeader from "../common2/headers/navigation.header";
import PageTitle from "../common2/fields/text/pagetitle.text";
import SubSubTitle from "../common2/fields/text/subsubtitle.text";
import GradientContainer from "../common2/containers/gradient.container";
import PricingFlow from "../common2/flow/pricing.flow";
import Footer from "../common2/footers/footer";
// @ts-ignore
import {default as Blue} from "../../assets/containers/blue.container.svg"
import IconsBlock from "../common2/blocks/icons.block";
import {translate} from "react-i18nify";
import CalculatorBlock from "../common2/blocks/calculator.block";
import CalculatorBlockSmall from "../common2/blocks/calculatorsmall.block";
import CalculatorBlockMedium from "../common2/blocks/calculatormedium.block";

interface pageProps {
    width: number
}

class ContainerPage extends React.Component<pageProps, { width: number }> {

    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth
        }
        window.addEventListener('resize', this.reportWindowSize);


    }

    reportWindowSize = () => {
        this.setState({width: window.visualViewport.width})
    }

    render() {
        let calculator = <CalculatorBlock width={this.props.width}/>
        if (this.state.width < 1300) {
            calculator = <CalculatorBlockMedium width={this.props.width}/>
        }
        if (this.state.width < 768) {
            calculator = <CalculatorBlockSmall width={this.props.width}/>

        }
        return (<><GrayContainer style={{paddingBottom: "40px"}}>
                <NavigationHeader width={this.props.width}/>
                <br/>
                <br/>
                <br/>

                <PageTitle text={"Stel jouw Bizzerd samen"}/>
                <br/>
            </GrayContainer>
                {calculator}
                <Footer/></>
        )
            ;
    }
}

export default ContainerPage
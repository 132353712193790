import {GET_SHARES_BY_COMPANY, GET_STATS_BY_COMPANY, GET_STATS_BY_COMPANY_BY_BIZZERDNAME} from "../actions/types";

const initialState = {
    data: {}
};

export default function(state=initialState, action){
    switch(action.type){
        case GET_STATS_BY_COMPANY:
            return{
                ...state,
                data: action.payload
            };
        case GET_STATS_BY_COMPANY_BY_BIZZERDNAME:
            return{
                ...state,
                databizzerdname: action.payload
            };
        case GET_SHARES_BY_COMPANY:
            return{
                ...state,
                shares: action.payload
            };
        default:
            return state;
    }
}
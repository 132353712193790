import {combineReducers} from 'redux';
import bizzerdReducer from './bizzerdReducer';
import errorReducer from './errorReducer';
import personReducer from './personReducer';
import userReducer from "./userReducer";
import dataReducer from "./dataReducer";
import authReducer from "./authReducer";
import companyReducer from "./companyReducer";
import templateReducer from "./templateReducer";
import inviteReducer from "./inviteReducer";
import uploadReducer from "./uploadReducer";
import paymentReducer from "./paymentReducer";
import {SET_CURRENT_USER} from '../actions/types'
import statsReducer from "./statsReducer";
import nfcReducer from "./nfcReducer";
import variationsReducer from "./variationsReducer";
import translationReducer from "./translationReducer";


const appReducer = combineReducers({
    errors: errorReducer,
    bizzerd: bizzerdReducer,
    persons: personReducer,
    translations: translationReducer,
    users: userReducer,
    auth: authReducer,
    company: companyReducer,
    templates: templateReducer,
    invite: inviteReducer,
    stats: statsReducer,
    nfc: nfcReducer,
    uploads: uploadReducer,
    payment: paymentReducer,
    variations: variationsReducer,
    data: dataReducer
});

export const rootReducer = (state, action) => {
    if (action.type === SET_CURRENT_USER && Object.keys(action.payload).length === 0) {
        state = undefined
    }

    return appReducer(state, action)
}
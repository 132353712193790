import React from "react";
import './container.styles.css'


export interface containerProps {
    children?: Array<any>,
}

class BlueContainer extends React.Component<containerProps, {}> {
    render() {

        return <div className={"container-blue"}>
            <div className={"content-wrapper"}>
                {this.props.children}
            </div>
        </div>;
    }

}

export default BlueContainer;
const ShapeTypes = {
    RECTANGLE: "rectangle",
    CIRCLE: "circle",
    TRIANGLE: "triangle",
    ELLIPSE: "ellipse",
    PHOTO: "photo"
};

export const ShapeTypesEditor = {
    RECTANGLE: {type: "rectangle", name: "Vierkant"},
    CIRCLE: {type: "circle", name: "Cirkel"},
    TRIANGLE: {type: "triangle", name: "Driehoek"},
};

Object.freeze(ShapeTypes);
export default ShapeTypes;

import axios from "axios/index";
import {
    EXCEL_SUCCESS,
    FINISH_REGISTRATION,
    GET_BIZZERDS,
    GET_COMPANY,
    GET_ERRORS, GET_MAIL,
    GET_USER,
    POST_COMPANY,
    POST_COMPANYPERSONBIZZERD
} from "./types";
import {logoutUser, setCurrentUser} from "./userActions";
import {setBizzerdLoading} from "./bizzerdActions";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";


//Submit the name of the new company to the server.
export const submitCompany = (company, history) => dispatch => {
    axios.post(`/api/user/createcompany`, company)
        .then(res => {
            dispatch({
                type: GET_COMPANY,
                payload: res.data.company
            });
            dispatch({
                type: GET_USER,
                payload: res.data.user
            });
            history.push('/companyurl')
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

//Submit the domain for this user, as entered in the registration process
export const submitCompanyURL = (url, id, history) => dispatch => {
    axios.post(`/api/company/${id}`, url)
        .then(res => {
            history.push('/addperson/register');
        })
        .catch(err => dispatch(
            {
                type: GET_ERRORS,
                payload: err.response.data
            }
        ))

};

export const finishRegistration = (user, bizzerd, person) => dispatch => {
    axios.post(`/api/company/finishRegistration`, {user, bizzerd, person})
        .then(res => {
            const token = res.data.token
            localStorage.setItem('jwtToken', token);
            //Set token to auth header
            setAuthToken(token);
            //Decode token to get user data
            const decoded = jwt_decode(token);
            //Set current user
            dispatch(setCurrentUser(decoded));

            dispatch(
                {
                    type: FINISH_REGISTRATION,
                    payload: res.data,
                }
            )
        })
        .catch(err => dispatch(
            {
                type: GET_ERRORS,
                payload: err.response.data
            }
        ))

};

export const finishRegistrationDesignTool = (user, bizzerd, person,company) => dispatch => {
    axios.post(`/api/company/finishRegistrationDesignTool`, {user, bizzerd, person,company})
        .then(res => {
            const token = res.data.token
            localStorage.setItem('jwtToken', token);
            //Set token to auth header
            setAuthToken(token);
            //Decode token to get user data
            const decoded = jwt_decode(token);
            //Set current user
            dispatch(setCurrentUser(decoded));

            dispatch(
                {
                    type: FINISH_REGISTRATION,
                    payload: res.data,
                }
            )
        })
        .catch(err => dispatch(
            {
                type: GET_ERRORS,
                payload: err.response.data
            }
        ))

};

export const uploadExcellijst = (file, id) => dispatch => {
    axios.post(`/api/company/excellijst/${id}`, file, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then(res => {
            dispatch({
                type: EXCEL_SUCCESS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        })
};

//Get the data from the company of the current user.
export const getCurrentCompany = () => dispatch => {
    axios.get('/api/company/current')
        .then(res => {
            dispatch({
                type: GET_COMPANY,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
};

export const saveWelcomeMail = (data) => dispatch => {
    axios.post('/api/company/welcomeMail/set', data)
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
};

export const getWelcomeMail = () => dispatch => {
    axios.get('/api/company/welcomeMail/get')
        .then(res => {
            dispatch({
                type: GET_MAIL,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
};

//Get the data from the company by tid.
export const getCompanyById = (id) => dispatch => {
    axios.get('/api/company/' + id)
        .then(res => {
            dispatch({
                type: GET_COMPANY,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
};

export const getBizzerds = () => dispatch => {
    dispatch(setBizzerdLoading());
    axios.get(`/api/bizzerd/company`)
        .then(res => {
            dispatch({
                type: GET_BIZZERDS,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_BIZZERDS,
            payload: []
        }))
};

export const updateCompany = (company) => dispatch => {
    axios.post(`/api/company/${company._id}`, company)
        .then(res => {
            dispatch({
                type: GET_COMPANY,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        })
};

export const createCompanyPersonBizzerdUser = (company, bizzerds, persons, userId, uniqueDesigns) => dispatch => {
    axios.post(`/api/company/createCompanyPersonBizzerdUser/create`, {
        company: company,
        bizzerds: bizzerds,
        persons: persons,
        userId: userId,
        uniqueDesigns: uniqueDesigns
    })
        .then(res => {
            dispatch({
                type: POST_COMPANYPERSONBIZZERD,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err
            })
        })
};


export const updateCompany2 = (company) => dispatch => {
    axios.post(`/api/company/update/${company._id}`, company)
        .then(res => {
            dispatch({
                type: GET_COMPANY,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err
            })
        })
};


export const deleteCompany = () => dispatch => {
    axios.delete(`/api/company`)
        .then(() => {
            dispatch(
                logoutUser()
            );
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            })
        })
};

export const createCompanyByName = (name, adres) => dispatch => {
    axios.post(`/api/company/create/` + name, adres)
        .then(res => {
            dispatch(
                dispatch({
                    type: POST_COMPANY,
                    payload: res.data
                })
            );
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response
            })
        })
};

import React from "react";
import '../input.styles.css'

export interface inputProps {
    placeholder: string,
    validation?: string,
    value?: string,
    style: any,
    id: string,
    label: string,
    errorMessage?: string,
    onChange?: (e) => void,
    readOnly?: boolean,
}

class InputArea extends React.Component<inputProps, {}> {
    render() {
        let invalid = "";
        let filled = ""
        let error;
        if (this.props.value) {
            filled = "filled"
        }
        if (this.props.validation) {
            const regex = new RegExp(this.props.validation)
            if (this.props.value) {
                if (!regex.test(this.props.value)) {
                    invalid = "invalid"
                    error = <label className={"input-field-label-error"}
                                   htmlFor={this.props.id}>{this.props.errorMessage}</label>
                }
            }
        }

        return <div className={"input-field-container"} style={this.props.style}>
            <div className={"input-field-label-container"}>
                <label className={"input-field-label"} htmlFor={this.props.id}>{this.props.label}</label>
                {error}

            </div>
            <textarea readOnly={this.props.readOnly} rows={4} id={this.props.id}
                      name={this.props.id}
                      className={"editable-input-field " + invalid + " " + filled}
                      placeholder={this.props.placeholder} value={this.props.value}
                      onChange={this.props.onChange}/>
        </div>
    }

}

export default InputArea;
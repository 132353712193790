import {Component} from "react";
import {connect} from "react-redux";


class RedirectPage extends Component {

    componentDidMount() {
        let destination = new URLSearchParams(window.location.search).get("destination")
        // @ts-ignore
        window.location.href = destination;
    }

    render() {
        return <></>;
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(RedirectPage);
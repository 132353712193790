import React from "react";
import '../text.styles.css'

export interface subSubSubTitleProps {
    text:string,
    style?:{}
}

class SubSubSubTitle extends React.Component<subSubSubTitleProps, {}> {
    render() {

        return <h4 className={"page-sub-sub-sub-title"} style={this.props.style}>{this.props.text}</h4>;
    }

}

export default SubSubSubTitle;
import React, {ReactElement} from 'react';
import '../Icon.scss';
import IconProps from "../IconProps.interface";

const UserIcon = (props: IconProps) : ReactElement => {
   
    return (
        <div className="icon">
            <svg version="1.2" fill={props.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" >{/*width="60" height="60"*/}
                <path id="Layer" className="s0"
                      d="m21.7 37.4c-3.1 0-5.7-3.9-5.7-8.7 0-4.8 2.6-8.7 5.7-8.7 3.2 0 5.8 3.9 5.8 8.7 0 4.8-2.6 8.7-5.8 8.7z"/>
                <path id="Layer" className="s0"
                      d="m35.8 44.9q0.1 0.2 0.2 0.5 0.1 0.2 0.1 0.4 0.1 0.2 0.1 0.5 0 0.2 0 0.4v1.4q0 0.8-0.2 1.4-0.2 0.8-0.6 1.4-0.4 0.5-0.9 0.8-0.6 0.3-1.1 0.3h-24.3q-0.6 0-1-0.3-0.6-0.3-1-0.8-0.3-0.6-0.6-1.4-0.2-0.6-0.2-1.4v-1.4q0 0 0-0.1 0-0.1 0-0.1c0-4.6 4.6-8.4 7.8-8.4h1.4c2.9 0 5.4 1 5.8 4.7 0.4-3.7 2.8-4.7 5.8-4.7h1.4c2.7 0 6.3 3.1 7.3 6.8z"/>
                <path id="Layer" className="s0"
                      d="m32.9 12.9c-0.3-0.4-0.5-1-0.5-1.6 0-0.7 0.2-1.3 0.5-1.7 0.4-0.4 0.9-0.7 1.3-0.7h18.8c0.5 0 1 0.3 1.4 0.7 0.3 0.4 0.4 1 0.4 1.7 0 0.6-0.1 1.2-0.4 1.6-0.4 0.5-0.9 0.7-1.4 0.7h-18.8c-0.4 0-0.9-0.2-1.3-0.7z"/>
                <path id="Layer" className="s0"
                      d="m54.4 19c0.4 0.4 0.6 1 0.6 1.6 0 0.6-0.2 1.2-0.6 1.7-0.4 0.4-0.9 0.7-1.4 0.6h-18.8c-0.5 0.1-1-0.2-1.4-0.6-0.3-0.5-0.6-1.1-0.6-1.7 0-0.6 0.3-1.2 0.6-1.6 0.4-0.5 0.9-0.7 1.4-0.7h18.8c0.5 0 1 0.2 1.4 0.7z"/>
                <path id="Layer" className="s0"
                      d="m54.4 28.3c0.3 0.5 0.4 1.1 0.4 1.7 0 0.6-0.1 1.2-0.4 1.6-0.4 0.5-0.9 0.7-1.4 0.7h-18.8c-0.4 0-0.9-0.2-1.3-0.7-0.3-0.4-0.5-1-0.5-1.6 0-0.6 0.2-1.2 0.5-1.7 0.4-0.4 0.9-0.7 1.3-0.7h18.8c0.5 0 1 0.3 1.4 0.7z"/>
            </svg>
        </div>
    )
};

export default UserIcon;
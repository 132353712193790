import {connect} from "react-redux";
import React, {Component} from "react";
import PageTitle from "../../common2/fields/text/pagetitle.text";
import SubTitle from "../../common2/fields/text/subtitle.text";
import SecondaryButton from "../../common2/buttons/action_secondary/secondary.button";
import ButtonColors from "../../common2/buttons/button.colors";
import {translate} from "react-i18nify";


class AddColleaguePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
        }

        window.addEventListener('resize', this.reportWindowSize);
    }

    reportWindowSize = () => {
        this.setState({width: window.innerWidth})
    }

    render() {
        let mainStyle = {
            width: "calc(100% - 210px)",
            left: 210,
            position: "relative",
            minHeight: "100vh",
            padding: 0,
            paddingTop: 70,
            marginBottom: 0,

        }
        let blockStyle = {
            height: "fit-content",
            minWidth: 350,
            maxWidth: 450,
            minHeight: 300,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: 30
        }
        if (this.state.width < 768) {
            mainStyle = {width: "100%", padding: "0 0%"}
        }
        if (this.state.width < 1277) {
            mainStyle["flexDirection"] = "column"
            mainStyle["alignItems"] = "center"
            mainStyle["marginTop"] = 0
            mainStyle["marginBottom"] = 0
            blockStyle["marginTop"] = 10
            blockStyle["marginBottom"] = 0
        }
        if (this.state.width < 400) {
            mainStyle["padding"] = 0

        }
        return (
            <div className={"double-block-container"} style={mainStyle}>
                <div className={"login-block"} style={blockStyle}>
                    <SubTitle text={translate("dashboard.inviteColleagues")} style={{marginBlockStart: 0, marginBlockEnd: 0}}/>
                    <span>{translate("dashboard.inviteColleagues1")}</span>
                    <br/>
                    <span>{translate("dashboard.inviteColleagues2")}</span>
                    <SecondaryButton color={ButtonColors.orange} href={"/dashboard/collegas/uitnodigen"}
                                     text={"Collega's uitnodigen"} style={{marginTop: 10}}/>

                </div>
                <div className={"login-block"} style={blockStyle}>
                    <SubTitle text={translate("dashboard.addManually")} style={{marginBlockStart: 0, marginBlockEnd: 0}}/>
                    <span>{translate("dashboard.addManually1")}</span>
                    <br/>
                    <span>{translate("dashboard.addManually2")}</span>
                    <SecondaryButton color={ButtonColors.orange} href={"/dashboard/collegas/toevoegen"}
                                     text={translate("dashboard.addManually")} style={{marginTop: 10}}/>
                </div>

                {/*<div className={"login-block"} style={blockStyle}>*/}
                {/*    <SubTitle text={"Inladen via excel of CRM"} style={{marginBlockStart: 0, marginBlockEnd: 0}}/>*/}

                {/*    <span>*/}
                {/*        Collega's inladen via excel kan veel tijd schelen als je meerdere collega's wil inladen. Daarnaast*/}
                {/*        kan een koppeling met het CRM systeem van je bedrijf er voor zorgen dat nieuwe collega's automatisch*/}
                {/*        worden toegevoegd en de gegevens altijd up-to-date zijn.*/}
                {/*    </span>*/}

                {/*    <SecondaryButton color={ButtonColors.orange} text={"Naar automatisch"} style={{marginTop: 10}}/>*/}


                {/*</div>*/}
            </div>
        );
    }
}

AddColleaguePicker.propTypes = {};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(AddColleaguePicker);

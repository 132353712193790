import {
    EXCEL_SUCCESS,
    FINISH_REGISTRATION,
    GET_COMPANY, GET_MAIL,
    POST_COMPANY,
    POST_COMPANYPERSONBIZZERD
} from "../actions/types";

const initialState = {
    company: {},
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_COMPANY:
            return {
                ...state,
                company: action.payload
            };

        case GET_MAIL:
            return {
                ...state,
                mail: action.payload
            };
        case POST_COMPANY:
            return {
                ...state,
                company: action.payload
            };
        case FINISH_REGISTRATION:
            return {
                ...state,
                returnObject: action.payload
            }

        case POST_COMPANYPERSONBIZZERD:
            return {
                ...state,
                returnObject: action.payload
            };
        case EXCEL_SUCCESS:
            return {
                ...state,
                data: action.payload,
                excelSuccess: true
            };
        default:
            return state;
    }
}

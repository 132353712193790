import React from "react";
import '../text.styles.css'

export interface subTitleProps {
    text: string,
    thin?: boolean,
    style?: {}
}

class SubTitle extends React.Component<subTitleProps, {}> {
    render() {
        const {text, thin} = this.props
        let thinClass = "";
        if (thin) {
            thinClass = "-thin"
        }
        return <h2 style={this.props.style} className={"page-sub-title" + thinClass}>{text}</h2>;
    }

}

export default SubTitle;
import React from 'react'
import GrayContainer from "../common2/containers/gray.container";
import NavigationHeader from "../common2/headers/navigation.header";
import GradientContainer from "../common2/containers/gradient.container";
import PageTitle from "../common2/fields/text/pagetitle.text";
import Footer from "../common2/footers/footer";
import {translate} from "react-i18nify";
import BlogBlock from "../common2/blocks/blog.block";
import IconsBlock from "../common2/blocks/icons.block";
import SubSubTitle from "../common2/fields/text/subsubtitle.text";
import {
    nfcBlog,
    riseBlog,
    uselessBlog,
    climateBlog,
    engagementBlog,
    crmHrmBlog,
    createNFCBlog,
    privacyBlog,
    corporateIdentityBlog
} from "../blogs/blog"

interface pageProps {
    width: number,
    match: any,
}

class BlogPage extends React.Component<pageProps, {}> {
    render() {
        if (this.props.match && this.props.match.params && this.props.match.params.blog) {
            return this.renderBlog(this.props.match.params.blog)
        }

        return (<><GrayContainer>
            <NavigationHeader width={this.props.width}/>
            <div style={{
                width: "100%",
                alignSelf: "flex-start",
                paddingLeft: "15%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column"
            }}>
                <PageTitle style={{paddingTop: "40px", paddingBottom: 20, marginBlockEnd: 0, fontSize: 36}}
                           text={translate("hd.blogBlock.pageTitle")}/>
                <SubSubTitle style={{
                    fontFamily: "Outfit-Regular, serif",
                    display: "block",
                    paddingRight: "15%",
                }}
                             text={translate("hd.blogBlock.subTitle")}/>
            </div>
        </GrayContainer>
            <GradientContainer style={{backgroundColor: "#42809620"}}>
                <div className={"blog-block-container"}>
                    <BlogBlock title={translate("hd.blogBlock.blog1.title")}
                               width={this.props.width}
                               date={"27-05-2023"}
                               link={translate("hd.links.blogs") + "/" + translate("hd.blogBlock.blog1.link")}
                               description={translate("hd.blogBlock.blog1.description")}/>
                    <BlogBlock title={translate("hd.blogBlock.blog2.title")}
                               width={this.props.width}
                               date={"27-05-2023"}
                               link={translate("hd.links.blogs") + "/" + translate("hd.blogBlock.blog2.link")}
                               description={translate("hd.blogBlock.blog2.description")}/>
                    <BlogBlock title={translate("hd.blogBlock.blog3.title")}
                               width={this.props.width}
                               date={"26-06-2023"}
                               link={translate("hd.links.blogs") + "/" + translate("hd.blogBlock.blog3.link")}
                               description={translate("hd.blogBlock.blog3.description")}/>
                    <BlogBlock title={translate("hd.blogBlock.blog4.title")}
                               width={this.props.width}
                               date={"26-06-2023"}
                               link={translate("hd.links.blogs") + "/" + translate("hd.blogBlock.blog4.link")}
                               description={translate("hd.blogBlock.blog4.description")}/>
                    <BlogBlock title={translate("hd.blogBlock.blog5.title")}
                               width={this.props.width}
                               date={"26-06-2023"}
                               link={translate("hd.links.blogs") + "/" + translate("hd.blogBlock.blog5.link")}
                               description={translate("hd.blogBlock.blog5.description")}/>
                    <BlogBlock title={translate("hd.blogBlock.blog6.title")}
                               width={this.props.width}
                               date={"26-06-2023"}
                               link={translate("hd.links.blogs") + "/" + translate("hd.blogBlock.blog6.link")}
                               description={translate("hd.blogBlock.blog6.description")}/>
                    <BlogBlock title={translate("hd.blogBlock.blog7.title")}
                               width={this.props.width}
                               date={"26-06-2023"}
                               link={translate("hd.links.blogs") + "/" + translate("hd.blogBlock.blog7.link")}
                               description={translate("hd.blogBlock.blog7.description")}/>
                    <BlogBlock title={translate("hd.blogBlock.blog8.title")}
                               width={this.props.width}
                               date={"26-06-2023"}
                               link={translate("hd.links.blogs") + "/" + translate("hd.blogBlock.blog8.link")}
                               description={translate("hd.blogBlock.blog8.description")}/>

                </div>
            </GradientContainer>
            <IconsBlock width={this.props.width}/>
            <Footer/></>);
    }

    private renderBlog(blog: any) {
        if (blog === translate("hd.blogBlock.blog1.link")) {
            return nfcBlog(this.props.width, translate("hd.blogBlock.language"))
        } else if (blog === translate("hd.blogBlock.blog2.link")) {
            return uselessBlog(this.props.width, translate("hd.blogBlock.language"))
        } else if (blog === translate("hd.blogBlock.blog3.link")) {
            return privacyBlog(this.props.width, translate("hd.blogBlock.language"))
        } else if (blog === translate("hd.blogBlock.blog4.link")) {
            return engagementBlog(this.props.width, translate("hd.blogBlock.language"))
        } else if (blog === translate("hd.blogBlock.blog5.link")) {
            return climateBlog(this.props.width, translate("hd.blogBlock.language"))
        } else if (blog === translate("hd.blogBlock.blog6.link")) {
            return corporateIdentityBlog(this.props.width, translate("hd.blogBlock.language"))
        } else if (blog === translate("hd.blogBlock.blog7.link")) {
            return crmHrmBlog(this.props.width, translate("hd.blogBlock.language"))
        } else if (blog === translate("hd.blogBlock.blog8.link")) {
            return createNFCBlog(this.props.width, translate("hd.blogBlock.language"))
        }
    }
}

export default BlogPage
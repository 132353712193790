import React from "react";
import './blocks.styles.css'

import {ContactService} from "../../../services/Contact/Contact.service"
import ButtonColors from "../buttons/button.colors";
// @ts-ignore
import {default as EyeIcon} from "../../../assets/icons/eye.icon.svg"
// @ts-ignore
import {default as CloudIcon} from "../../../assets/icons/cloud.icon.svg"
// @ts-ignore
import {default as martinProfile} from "../../../assets/team/martin.jpg"
// @ts-ignore
import {default as CardsBackground} from "../../../assets/containers/small.container.png"
import InputField from "../fields/editable_input/text.input";
import MainButton from "../buttons/action_main/main.button";
import InputArea from "../fields/editable_input/textarea.input";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import SubSubTitle from "../fields/text/subsubtitle.text";
import {translate} from "react-i18nify";
import PageTitle from "../fields/text/pagetitle.text";
import LogoBlock from "./logo.block";
import SubSubSubTitle from "../fields/text/subsubsubtitle.text";
import GradientContainer from "../containers/gradient.container";
import SubTitle from "../fields/text/subtitle.text";
import FaqContainer from "../containers/faq.container";
import FaqBlock from "./faq.block";
import ReviewBlock from "./review.block";

export interface loginBlockProps {
    width: number
}

interface contactState {
    contact: {
        name: string,
        companyName: string,
        email: string,
        phoneNumber: string,
        employees: string,
        message: string,
        type: string,
        nfc: boolean,
    },
    send: boolean,
    contactService: ContactService

}


class ContactBlock extends React.Component<loginBlockProps, contactState> {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.state = {
            contact: {
                name: "",
                companyName: "",
                email: "",
                phoneNumber: "",
                type: "Contact & ondersteuning",
                employees: "",
                message: "",
                nfc: true,
            },
            send: false,
            contactService: new ContactService()
        }
    }

    onChange(e) {

        let name = e.target.id;
        let value = e.target.value;
        if (name === "nfc") {
            value = e.target.checked
        }
        this.setState({
            contact: {
                ...this.state.contact,
                [name]: value
            }
        })
    }

    onSubmit() {
        const contact = this.state.contact
        let rccode = document.getElementById("rccode") as HTMLInputElement;
        let captchaToken = ""
        if (rccode) {
            captchaToken = rccode.value
        }
        const {name, companyName, type, employees, email, phoneNumber, message, nfc} = contact
        if (captchaToken != '') {
            this.state.contactService.submitContactForm({
                name,
                companyName,
                email,
                phoneNumber,
                type,
                employees,
                message,
                nfc,
                captchaToken
            })
            this.setState({send: true})

        }
    }


    render() {


        let loginBlockContent = (<><InputField placeholder={translate("hd.contact.name")} validation={""} id={"name"}
                                               onChange={this.onChange}
                                               label={translate("hd.contact.name")} errorMessage={""}
                                               value={this.state.contact.name}/>

            <InputField placeholder={translate("hd.contact.email")} validation={"^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$"}
                        id={"email"}
                        label={translate("hd.contact.email")} errorMessage={translate("hd.errorMessages.invalidEmail")}
                        onChange={this.onChange}
                        value={this.state.contact.email}/>
            <InputField placeholder={translate("hd.contact.telephoneNumber")} validation={""} id={"phoneNumber"}
                        onChange={this.onChange}
                        label={translate("hd.contact.telephoneNumber")} errorMessage={""}
                        value={this.state.contact.phoneNumber}/>
            <div style={{
                display: "flex",
                flexDirection: this.props.width < 768 ? "column" : "row",
                alignItems: "center"
            }}>
                <InputField placeholder={translate("hd.contact.companyName")} validation={""} id={"companyName"}
                            onChange={this.onChange} style={{
                    width: this.props.width < 768 ? "100%" : "50%",
                    marginRight: this.props.width < 768 ? 0 : "5%"
                }}
                            label={translate("hd.contact.companyName")} errorMessage={""}
                            value={this.state.contact.companyName}/>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "left",
                        marginTop: 5,
                        marginBottom: 5,
                        width: this.props.width < 768 ? "100%" : ""
                    }}>
                    <span style={{fontSize: "14px"}}>{translate("hd.contact.employeeIndication")}</span>
                    <select id={"employees"} name={"employees"}
                            style={{
                                float: "left",
                                border: "1px solid #dfdee4",
                                backgroundColor: "#fff",
                                padding: "10px 20px 10px 10px",
                                borderRadius: 3,
                                fontSize: 16,
                                fontFamily: "Outfit-Regular, serif",
                                color: "#28395b"
                            }}
                            onChange={this.onChange}>
                        <option style={{fontFamily: "Outfit-Regular, serif"}}
                                value={""}>{translate("hd.contact.noIndication")}</option>
                        <option style={{fontFamily: "Outfit-Regular, serif"}} value={"1-10"}>1-10</option>
                        <option style={{fontFamily: "Outfit-Regular, serif"}} value={"10-100"}>10-100</option>
                        <option style={{fontFamily: "Outfit-Regular, serif"}} value={"100-1000"}>100-1000</option>
                        <option style={{fontFamily: "Outfit-Regular, serif"}} value={"1000+"}>1000+</option>
                    </select>
                </div>
            </div>

            <InputArea placeholder={translate("hd.contact.message")} validation={""} id={"message"} style={{}}
                       label={translate("hd.contact.message")} errorMessage={""} value={this.state.contact.message}
                       onChange={this.onChange}/>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    textAlign: "left",
                    marginTop: 5,
                    marginBottom: 5
                }}>
                <label htmlFor={"nfc"}
                       style={{fontSize: "16px"}}>{translate("hd.contact.nfcRequest")}</label>
                <input checked={this.state.contact.nfc} id={"nfc"} name={"nfc"} type={"checkbox"}
                       style={{marginLeft: 10}}
                       onChange={this.onChange}/>
            </div>
            <div className={"login-buttons"} style={{justifyContent: "flex-end"}}>
                <MainButton color={ButtonColors.orange} style={{width: "100%"}}
                            onClick={this.onSubmit}
                            text={translate("hd.actions.requestQuotation")}/>
            </div>
        </>)
        if (this.state.send) {
            loginBlockContent = <><br/><SubSubTitle text={translate("hd.contact.thanksForTheMessage")}/><br/><br/>
                <a href={translate("hd.links.home")} className={"text-link"}>{translate("hd.contact.backToHome")}</a></>
        }

        let {usp1, usp2, usp3} = this.determineUsps()
        return (<>
            <div className={"contact-page-title-bar"} style={{paddingTop: this.props.width < 768 ? 10 : 60}}>
                <PageTitle text={translate("hd.contact.pageTitle")}
                           style={{
                               marginBottom: 10,
                               fontSize: this.props.width < 768 ? 30 : 36,
                               width: this.props.width < 768 ? "100%" : "40%",
                               marginTop: 0
                           }}/>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: this.props.width < 768 ? "flex-start" : "flex-end",
                    textAlign: this.props.width < 768 ? "left" : "right"
                }}>
                    <SubSubTitle text={translate("hd.contact.questions")}/>
                    <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <SubSubSubTitle text={translate("hd.contact.weAreReachable")}
                                        style={{marginRight: 10, fontWeight: "bold"}}/>
                        <a href={"tel:+31882272222"} className={"text-link"}>+31 88 - 227 22 22</a>
                    </div>
                </div>
            </div>
            <div className={"contact-page-header"}>
                <div className={"contact-page-header-left"}>
                    <span>{translate("hd.contact.bizzerdOffer")}</span>
                    <br/>
                    <SubSubTitle text={translate("hd.contact.thirdTitle")}/>
                    <span>{translate("hd.contact.subThirdTitle")}</span>
                    <br/>
                    <span>{translate("hd.contact.call")}: <a className={"text-link"} href={"tel:+31882272222"}>+31 88 - 227 22 22</a></span>
                    <span>Email: <a className={"text-link"}
                                    href={"mailto:martin@bizzerd.com"}>martin@bizzerd.com</a></span>
                    <br/>
                    <img style={{width: 200, height: 200}} src={martinProfile}/>
                    <br/>
                    <div className={"home-header-usp"}>
                    <span
                        className={"home-header-usp-item"}>{translate("hd.home.pageContent")}</span>
                        {usp1}
                        {usp2}
                        {usp3}

                    </div>
                    <LogoBlock width={this.props.width} logoCount={this.props.width < 1200 ? 2 : 3}
                               style={{
                                   width: "90%",
                                   minWidth: 350,
                                   boxShadow: "0px 0px 0px 0px #000",
                                   backgroundColor: "#FFFFFF00",
                                   top: this.props.width < 768 ? 0 : 40
                               }}/>
                    {/*<div style={{*/}
                    {/*    margin: 10,*/}
                    {/*    marginLeft: 0,*/}
                    {/*    marginTop: this.props.width < 768 ? 20 : 80,*/}
                    {/*    marginBottom: this.props.width < 768 ? 20 : 0,*/}
                    {/*    marginRight: this.props.width < 768 ? 0 : 10,*/}
                    {/*    padding: 15,*/}
                    {/*    paddingRight: 30,*/}
                    {/*    borderRadius: 10,*/}
                    {/*    border: "1px solid #F7A823"*/}
                    {/*}}>*/}
                    {/*    <SubTitle text={translate("hd.nfc.saleTitle")}/>*/}

                    {/*    <br/>*/}
                    {/*    <br/>*/}
                    {/*    <span>{translate("hd.nfc.saleContent")}</span>*/}
                    {/*</div>*/}

                </div>

                <div className={"login-block"}
                     style={{marginTop: 0, boxShadow: "0px -21px 25px 0px rgba(0, 0, 0, 0.05)"}}>
                    <input type={"hidden"} id={'rccode'}/>
                    {loginBlockContent}
                    <GoogleReCaptchaProvider useRecaptchaNet={false}
                                             reCaptchaKey={"6LckfcciAAAAAP3VmUUCEei_LrqHKt_CNG5rErrr"}>
                        <GoogleReCaptcha onVerify={(captchaToken) => {
                            let rccode = document.getElementById("rccode") as HTMLInputElement;
                            if (rccode) {
                                rccode.value = captchaToken
                            }
                        }}/>
                    </GoogleReCaptchaProvider>
                </div>
            </div>

            <GradientContainer reverse={true}>
                <SubTitle text={translate("hd.home.faqTitle")}/>
                <FaqContainer>
                    <FaqBlock
                        text={translate("hd.home.faq1Answer")}
                        title={translate("hd.home.faq1Title")} open={true} first={true}/>
                    <FaqBlock
                        text={translate("hd.home.faq2Answer")}
                        title={translate("hd.home.faq2Title")}/>
                    <FaqBlock
                        text={translate("hd.home.faq3Answer")}
                        title={translate("hd.home.faq3Title")}/>
                    <FaqBlock
                        text={translate("hd.home.faq4Answer")}
                        title={translate("hd.home.faq4Title")} last={true}/>
                </FaqContainer>
            </GradientContainer>

            <ReviewBlock style={{backgroundImage: "linear-gradient(to top, #42809600, #42809600)"}}/>

        </>)
            ;
    }

    determineUsps = () => {
        let uspString = translate("hd.home.usp1").split("<b>")
        let usp1 = <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}><span
            className={"check-mark"}>✔︎</span>
            <span
                className={"home-header-usp-item"}>{uspString[0]}<b
                style={{color: "#28395b"}}>{uspString[1].split("</b>")[0]}</b>{uspString[1].split("</b>")[1]}</span>
        </div>

        uspString = translate("hd.home.usp2").split("<b>")


        let usp2 = <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}><span
            className={"check-mark"}>✔︎</span>
            <span
                className={"home-header-usp-item"}>{uspString[0]}<b
                style={{color: "#28395b"}}>{uspString[1].split("</b>")[0]}</b>{uspString[1].split("</b>")[1]}</span>
        </div>

        uspString = translate("hd.home.usp3").split("<b>")

        let usp3 = <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}><span
            className={"check-mark"}>✔︎</span>
            <span
                className={"home-header-usp-item"}>{uspString[0]}<b
                style={{color: "#28395b"}}>{uspString[1].split("</b>")[0]}</b>{uspString[1].split("</b>")[1]}</span>
        </div>

        return {usp1, usp2, usp3}

    }

}

export default ContactBlock;

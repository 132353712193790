import React, {Component} from 'react'
import {api} from "../../../utils/apiCall";

class Image extends Component {
    render() {
        const divStyle = {
            position: 'absolute',
            left: this.props.resize(this.props.layout.left) + 'px',
            top: this.props.resize(this.props.layout.top) + 'px',
            zIndex: this.props.layout.zindex,
            width: this.props.resize(this.props.layout.width),
            height: this.props.resize(this.props.layout.height),
            objectFit: this.props.layout.objectFit,
            objectPosition: this.props.layout.clipPosition ? this.props.layout.clipPosition.replace("-", " ").replace("middle", "center") : this.props.layout.clipPosition,
            maxWidth: 'none'
        };

        if (this.props.layout.rotation !== undefined) {
            divStyle.transform = "rotate(" + this.props.layout.rotation + "deg)"
        }
        if (this.props.layout.image.includes("http://")) {
            this.props.layout.image = this.props.layout.image.replace("http://", "https://");
        }
        let image;
        if (this.props.layout && this.props.layout.image) {
            image = this.props.layout.image
            if (image.includes("res.cloudinary.com")) {
                image = image.replace("/upload/", "/upload/c_limit,h_" + this.props.layout.height + ",w_" + this.props.layout.width + "/")
            }
        }
        let link_object
        if (this.props.link) {

            if (this.props.link && this.props.link.includes("vcard", 0)) {
                // eslint-disable-next-line
                divStyle.cursor = 'pointer'
                link_object = (
                    <a onClick={async () => {
                        let person = this.props.person;

                        const responseString = await api('/api/person/vcard/' + person._id)
                        const url = window.URL.createObjectURL(new Blob([responseString.data], {
                            type: 'text/x-vcard',
                            name: person.fullname + ".vcf"
                        }));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', person.fullname + `.vcf`);
                        // 3. Append to html page
                        document.body.appendChild(link);
                        // 4. Force download
                        link.click();
                        // 5. Clean up and remove the link
                        link.parentNode.removeChild(link);
                    }}><img alt={"loading"} src={image} style={divStyle}/>
                    </a>
                );
            }
            else if (this.props.layout.link === "delen") {
                link_object = (
                    <a href={this.props.link}>
                        <img alt={"loading"} src={image} id={this.props.id} style={divStyle}/>
                    </a>
                )
            }
            else if (this.props.link && this.props.link.startsWith("change_language_")) {
                return (<div style={{cursor: "pointer"}}
                             onClick={() => this.props.changeTranslation(this.props.link.replace("change_language_", ""))}>
                    <img src={image} id={this.props.id} style={divStyle} alt={"loading"}/>
                </div>)
            } else {
                link_object = (
                    <a target="_blank" rel="noopener noreferrer" href={this.props.link}>
                        <img src={image} id={this.props.id} style={divStyle} alt={"loading"}/>
                    </a>
                )
            }
        } else {
            link_object = (
                <img alt={"loading"} src={image} id={this.props.id} style={divStyle}/>
            )
        }
        if (!this.props.shouldBeClickable) {
            link_object = (
                <img alt={"loading"} src={image} id={this.props.id} style={divStyle}/>
            )

        }
        return link_object
    }
}

export default Image;

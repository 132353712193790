import React, {useEffect, useState} from "react";
import "./chat.css"
import InputField from "../common2/fields/editable_input/text.input";
import axios from 'axios';
import MainButton from "../common2/buttons/action_main/main.button";
import ButtonColors from "../common2/buttons/button.colors";
import SecondaryButton from "../common2/buttons/action_secondary/secondary.button";
import SubSubSubTitle from "../common2/fields/text/subsubsubtitle.text";

function sendMessage(message, setMessage, threadTS, setThreadTS, setMessages, messages, setTimer) {

    axios.post(
        '/api/stats/slack/send-message', // Replace with your server's URL
        {
            text: message,
            threadTS: threadTS
        }
    ).then((resp) => {
        if (!threadTS) {
            sessionStorage.setItem("threadTS", resp.data.message.ts)
            setThreadTS(resp.data.message.ts)
            const timerDuration = 10000; // 60 seconds = 60,000 milliseconds
            const timerId = setTimeout(() => handleTimerExpiration(resp.data.message.ts), timerDuration);
            setTimer(timerId)
        }
        setMessage('')

    });
};

const handleTimerExpiration = (threadTS) => {
    // This function is called when the timer expires (e.g., after one minute)
    // You can use it to send a follow-up message or take other actions
    axios.post("/api/stats/slack/inactive/" + threadTS)
};


function parseMessages(messages) {
    let messageDisplay: any[] = []
    for (const message of messages) {
        if (message.userId === "Anonymous") {
            messageDisplay.push(<div className={"popup-chat-message"}>
                <div>
                    <br/>
                    <span>{new Date(message.date).getHours() + ":" + new Date(message.date).getMinutes()}</span>
                </div>
                <div className={"message-anonymous"}>
                    <span style={{fontWeight: "bold"}}>You</span><br/>
                    <span>{message.message}</span>
                </div>
            </div>)
        } else {
            messageDisplay.push(<div className={"popup-chat-message"}>
                <div>
                    <br/>
                    <span>{new Date(message.date).getHours() + ":" + new Date(message.date).getMinutes()}</span>
                </div>
                <div className={"message-bizzerd"}>
                    <span style={{fontWeight: "bold"}}>Bizzerd <span
                        style={{fontWeight: "normal", fontSize: 14}}>({message.userId.split(" ")[0]})</span></span><br/>
                    <span>{message.message}</span>
                </div>
            </div>)
        }
    }
    return messageDisplay
}

export default function ChatComponent({show, width}) {
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [messageLength, setMessageLength] = useState(0)
    const [threadTS, setThreadTS] = useState(undefined)
    const [timer, setTimer] = useState(undefined)
    useEffect(() => {
        if (sessionStorage.getItem("threadTS")) {
            setThreadTS(sessionStorage.getItem("threadTS"))
        }
    }, [threadTS]);
    useEffect(() => {
        if (threadTS && show)
            setInterval(() => {
                axios.get("/api/stats/slack/poll/" + threadTS).then((response) => {
                    if (response.data.filter(message => message.userId !== "Anonymous").length > 0) {
                        clearTimeout(timer)
                    }
                    setMessages(response.data)
                })
            }, 1000)
    }, [threadTS, show, timer]);
    useEffect(() => {
        if (messages.length > messageLength)
            document.getElementById("scrollable").scrollTo(0, 2000)
        setMessageLength(messages.length)
    }, [messages, messageLength]);

    let messagesDisplay = parseMessages(messages)
    return <>
        <span
            style={{
                position: "relative",
                display: show ? "none" : "block",
                color: "#FFF",
                backgroundColor: "#E41C1C",
                borderRadius: "50%",
                lineHeight: "30px",
                right: -20,
                top: 25,
                width: 30,
                height: 30,
                zIndex: 1500
            }}>{messages.length > 0 ? messages.length : "!"}</span>
        <div className={"popup-chat"} style={show ? {} : {height: 0, top: 0, padding: 0, border: 0}}>
            <div className={"popup-chat-content"}>
                <SubSubSubTitle text={"Bizzerd Live Chat"}
                                style={{color: "#334c6e", fontWeight: "bold", fontSize: 20}}/><br/><br/>
                <span>Wat wil je weten over het digitale visitekaartje van Bizzerd? Stel hier je vraag:</span>
                <div className={"popup-chat-messages"} id={"scrollable"}>
                    {messagesDisplay}
                </div>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        bottom: 10,
                        position: "absolute",
                        width: "90%",
                    }}>
                    <InputField style={{width: "100%"}} placeholder={"Begin met typen..."} id={"chat"} label={""}
                                value={message}
                                onKeyDown={(e) => e.key === 'Enter' ? sendMessage(message, setMessage, threadTS, setThreadTS, setMessages, messages, setTimer) : ""}
                                onChange={(e) => setMessage(e.target.value)}/>
                    {width < 768 ? <></> : <SecondaryButton color={ButtonColors.orange} text={"Stuur"}
                                                            onClick={() => sendMessage(message, setMessage, threadTS, setThreadTS, setMessages, messages, setTimer)}></SecondaryButton>}
                </div>
            </div>
        </div>
    </>

}
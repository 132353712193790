import React from "react";
import './blocks.styles.css'
import ButtonColors from "../buttons/button.colors";
// @ts-ignore
import {default as EyeIcon} from "../../../assets/icons/eye.icon.svg"
// @ts-ignore
import {default as CloudIcon} from "../../../assets/icons/cloud.icon.svg"
// @ts-ignore
import {default as DesignIcon} from "../../../assets/icons/design.icon.svg"
// @ts-ignore
import {default as CardsBackground} from "../../../assets/containers/small.container.png"
import InputField from "../fields/editable_input/text.input";
import MainButton from "../buttons/action_main/main.button";
import {resetPassword, submitNewPassword} from "../../../actions/userActions"
import {connect} from "react-redux";
import {translate} from "react-i18nify";
import SubSubTitle from "../fields/text/subsubtitle.text";

export interface loginBlockProps {
    width: number,
    auth: any,
    match: any,
    resetPassword: (email) => void,
    submitNewPassword: (passwordData: any) => void,
}

export interface loginBlockState {
    auth: any,
    errors?: any,
    email: string,
    password: string,
    password2: string,
}


class PasswordForget extends React.Component<loginBlockProps, loginBlockState> {
    constructor(props) {
        super(props);
        this.state = {
            auth: {
                isAuthenticated: false,
            },
            email: "",
            password: "",
            password2: "",
        }
        this.onChange = this.onChange.bind(this)
        window.addEventListener(
            "keydown",
            (event) => {
                if (event.key === "Enter") {
                    this.handleForgot();
                }
            });
    }

    componentWillReceiveProps(nextProps, nextContext: any) {
        if (nextProps.auth && nextProps.auth.isAuthenticated) {
            this.setState({auth: nextProps.auth})
        }
        if (nextProps.auth && nextProps.auth.requestSuccess || nextProps.auth.resetSuccess) {
            this.setState({auth: nextProps.auth})
        }
        if (nextProps.errors && nextProps.errors.errors) {
            this.setState({errors: nextProps.errors.errors})
        }
    }

    onChange(e) {
        const value = e.target.value;
        this.setState({
            ...this.state,
            errors: undefined,
            [e.target.id]: value
        })

    }

    handleForgot = () => {
        this.props.resetPassword({email: this.state.email})
    }

    handleChangePassword = () => {
        this.props.submitNewPassword({
            password: this.state.password,
            password2: this.state.password2,
            key: this.props.match.params.key
        })
    }

    render() {
        if (this.state.auth.resetSuccess) {
            return <div className={"single-block-container"}>
                <div className={"login-block"}>
                    <SubSubTitle
                        text={translate("hd.actions.successChanged")}/>

                    <div className={"login-buttons"}>
                        <MainButton color={ButtonColors.orange} style={{width: "auto"}}
                                    onClick={() => window.location.href = "/login"}
                                    text={translate("hd.actions.toLogin")}/>
                    </div>

                </div>
            </div>
        }
        if (this.props.match.params.key) {
            return <div className={"single-block-container"}>
                <div className={"login-block"}>
                    <span>{translate("hd.loginBlock.ForgotblockTitle")}</span>

                    <InputField password={true} placeholder={translate("hd.loginBlock.password")} id={"password"}
                                label={translate("hd.loginBlock.password")} value={this.state.password}
                                onChange={(e) => this.onChange(e)}/>

                    <InputField password={true} placeholder={translate("hd.loginBlock.password2")} id={"password2"}
                                label={translate("hd.loginBlock.password2")} value={this.state.password2}
                                onChange={(e) => this.onChange(e)}/>

                    <div className={"login-buttons"}>
                        <MainButton color={ButtonColors.orange} style={{width: "auto"}}
                                    onClick={() => this.handleChangePassword()}
                                    text={translate("hd.actions.confirm")}/>
                    </div>

                </div>
            </div>
        } else {
            return (
                <div className={"single-block-container"}>
                    <div className={"login-block"}>
                        {this.state.auth.requestSuccess ? <SubSubTitle
                            text={translate("hd.actions.successRequest")}/> : <>
                            <span>{translate("hd.loginBlock.blockTitleForgotRequest")}</span>
                            <InputField placeholder={translate("hd.loginBlock.email")}
                                        validation={"^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$"} id={"email"}
                                        label={translate("hd.loginBlock.email")} showError={!!this.state.errors}
                                        errorMessage={this.state.errors ? this.state.errors : translate("hd.errorMessages.invalidEmail")}
                                        value={this.state.email} onChange={(e) => this.onChange(e)}/>

                            <div className={"login-buttons"}>
                                <MainButton color={ButtonColors.orange} style={{width: "auto"}}
                                            onClick={() => this.handleForgot()}
                                            text={translate("hd.actions.request")}/>
                            </div>
                        </>}


                    </div>
                </div>
            );
        }
    }

}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
    users: state.users
});

export default connect(mapStateToProps, {
    resetPassword,
    submitNewPassword
})(PasswordForget);
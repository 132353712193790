import React from "react";
import './filter.styles.css'
// @ts-ignore
import {default as arrow} from "../../../assets/blocks/pagination.arrow.png"
import {translate} from "react-i18nify";

interface paginationProps {
    pages: number,
    selected: number,
    onChangePagination: (pageNr) => void
}

class CardPagination extends React.Component<paginationProps, {}> {
    render() {

        const searchParams = new URLSearchParams();
        let profileString = new URLSearchParams(window.location.search).get("profile")
        let orderString = new URLSearchParams(window.location.search).get("order")
        searchParams.append("page", "1")
        if (orderString)
            searchParams.append("order", orderString);
        if (profileString)
            searchParams.append("profile", profileString);


        let paginationBalls: Array<JSX.Element> = [];
        if (this.props.selected > 1) {
            searchParams.set("page", (this.props.selected - 2).toString());
            paginationBalls.push(<a
                href={translate("hd.links.businessCards") + "?" + searchParams.toString()}><img
                key={"leftArrow"} className={"pagination-arrow"} style={{transform: "scaleX(-1)"}}
                src={arrow} alt={"arrow"}/></a>);
        }
        for (let i = 1; i <= this.props.pages; i++) {
            let threshold = 2;
            if (this.props.selected <= 2) {
                threshold = 2 + (3 - this.props.selected)
            }
            if (this.props.selected <= 2) {
                threshold = 2 + (3 - this.props.selected)
            }
            if (this.props.pages - this.props.selected <= 2) {
                threshold = 2 + (3 - (this.props.pages - this.props.selected))

            }
            if ((paginationBalls.length > 5 || Math.abs(this.props.selected - i) > threshold) && i != this.props.pages) {
                continue
            }
            if (i === this.props.pages && this.props.pages - this.props.selected > 3) {
                paginationBalls.push(<span key={i + "..."}>...</span>)
            }
            if (i === this.props.selected) {
                paginationBalls.push(<span
                    className={"pagination-ball-selected"} key={i + "selected"}>{i}</span>)
            } else {
                searchParams.set("page", (i).toString());
                paginationBalls.push(<a
                    className={"pagination-ball"} key={i}
                    href={translate("hd.links.businessCards") + "?" + searchParams.toString()}>{i}</a>)
            }
            if (i === this.props.pages && this.props.selected != this.props.pages) {
                searchParams.set("page", this.props.selected.toString());

                paginationBalls.push(<a
                    href={translate("hd.links.businessCards") + "?" + searchParams.toString()}><img
                    className={"pagination-arrow"} key={i + "arrow"}
                    src={arrow} alt={"arrow"}/></a>)
            }
        }

        return <div className={"pagination-container"}>
            {paginationBalls}
        </div>;
    }

}

export default CardPagination;
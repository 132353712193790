import {
    GET_ERRORS,
    GET_SHARES_BY_COMPANY,
    GET_STATS_BY_COMPANY,
    GET_STATS_BY_COMPANY_BY_BIZZERDNAME,
    GET_UPLOADS,
    GET_USER,
    GET_USERS,
    REMOVE_UPLOAD,
    RESET_REQUEST_SUCCESSFUL,
    RESET_SUCCESSFUL,
    SET_CURRENT_USER,
    UPDATE_TUTORIAL
} from "./types";
import axios from "axios/index";
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';

//Send the login credentials to the server for checking.
export const login = (user) => dispatch => {
    console.log("Started login");
    axios.post(`/api/user/login`, user)
        //If the login was correct, store the user Token
        .then(res => {
            console.log("Login Succesfull")
            //Save to local storage
            const {token} = res.data;
            //Set token to local storage
            localStorage.setItem('jwtToken', token);
            //Set token to auth header
            setAuthToken(token);
            //Decode token to get user data
            const decoded = jwt_decode(token);
            //Set current user
            dispatch(setCurrentUser(decoded));
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: (err.response !== undefined ? err.response.data : '')
            })
        });
};
export const getOrCreateUserByEmail = (email, company_id, oid, sub) => dispatch => {
    axios.post(`/api/user/getuserbyemail/${email}/${company_id}/${oid}/${sub}`)
        .then(res => {
            dispatch({
                type: GET_USERS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_USERS,
                payload: err.response.data
            })
        })
};

export const loginSSO = (user) => dispatch => {
    axios.post(`/api/user/loginSSO`, user)
        //If the login was correct, store the user Token
        .then(res => {
            // Save to local storage
            const {token} = res.data;
            //Set token to local storage
            localStorage.setItem('jwtToken', token);
            //Set token to auth header
            setAuthToken(token);
            //Decode token to get user data
            const decoded = jwt_decode(token);
            //Set current user
            dispatch(setCurrentUser(decoded));
        })
        .catch(err => {

            dispatch({
                type: GET_ERRORS,
                payload: (err.response !== undefined ? err.response.data : '')
            })
        });
};

//Set logged in User
export const setCurrentUser = (decoded) => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    }
};

//Get current profile
export const getCurrentUser = () => dispatch => {
    axios.get('/api/user/current')
        .then(res => {
            dispatch({
                type: GET_USER,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_USER,
            payload: {}
        }));
};

export const getViewsByCompany = (bizzerdnames) => dispatch => {
    axios.post('/api/user/statistieken', bizzerdnames)
        .then(res => {
            dispatch({
                type: GET_STATS_BY_COMPANY,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_STATS_BY_COMPANY,
            payload: {}
        }));
};

export const getViewsByBizzerdName = (bizzerdnames) => dispatch => {
    axios.post('/api/user/statistieken/bybizzerdname', bizzerdnames)
        .then(res => {
            dispatch({
                type: GET_STATS_BY_COMPANY_BY_BIZZERDNAME,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_STATS_BY_COMPANY_BY_BIZZERDNAME,
            payload: {}
        }));
};

export const getSharesByCompany = (bizzerdnames) => dispatch => {
    axios.post('/api/user/statistieken/byshare', bizzerdnames)
        .then(res => {
            dispatch({
                type: GET_SHARES_BY_COMPANY,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_SHARES_BY_COMPANY,
            payload: {}
        }));
};

export const updateTutorial = (tutorial) => dispatch => {
    axios.post('/api/user/updatetutorial', tutorial)
        .then(res => {
            dispatch({
                type: UPDATE_TUTORIAL,
                payload: res.data
            })

        })
        .catch(err => dispatch({
            type: UPDATE_TUTORIAL,
            payload: {}
        }));
};

export const getUploads = (tempId = undefined) => dispatch => {
    axios.get(`/api/user/uploads` + (tempId ? `/${tempId}` : ""))
        .then(res => {
            dispatch({
                type: GET_UPLOADS,
                payload: res.data
            })
        })
        .catch(err => {
        })
};

export const removeUpload = (upload_id) => dispatch => {
    axios.delete(`/api/user/upload/${upload_id}`)
        .then(res => {
            dispatch({
                type: REMOVE_UPLOAD,
                payload: res.data
            })
        })
        .catch(err => {
        })
};

export const resetPassword = (email) => dispatch => {
    axios.post('/api/user/request', email)
        .then(res => {
            dispatch({
                type: RESET_REQUEST_SUCCESSFUL,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
};

export const submitNewPassword = (passwordData) => dispatch => {
    axios.post('/api/user/newpassword', passwordData)
        .then(res => {
            dispatch({
                type: RESET_SUCCESSFUL,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        })
}

export const uploadImage = (formData) => dispatch => {
    axios
        .post(`/api/bizzerd/image`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => {
            dispatch({
                type: GET_UPLOADS,
                payload: res.data
            })
        })
        .catch(err => {
        });
}


export const uploadImage2 = (formData, tempId) => dispatch => {
    axios
        .post(`/api/bizzerd/imageNewDesign/${tempId}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(res => {
            dispatch({
                type: GET_UPLOADS,
                payload: res.data
            })
        })
        .catch(err => {
        });
}

export const createUserPersonCompany = (user, person, company) => dispatch => {
    const payload = {
        user: user,
        person: person,
        company: company
    };
    axios.post('/api/user/createUserPersonCompany', payload)
        .then(res => {
            //Save to local storage
            const token = res.data;
            //Set token to local storage
            localStorage.setItem('jwtToken', token);
            //Set token to auth header
            setAuthToken(token);
            //Decode token to get user data
            const decoded = jwt_decode(token);
            //Set current user
            dispatch(setCurrentUser(decoded));
            dispatch({
                type: GET_USER,
                payload: decoded
            })

        })
        .catch(err => {
            // console.log(err)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        })
}

export const createUser = (user) => dispatch => {
    axios.post('/api/user/create', user)
        .then(res => {
            //Save to local storage
            const token = res.data;
            //Set token to local storage
            localStorage.setItem('jwtToken', token);
            //Set token to auth header
            setAuthToken(token);
            //Decode token to get user data
            const decoded = jwt_decode(token);
            //Set current user
            dispatch(setCurrentUser(decoded));
            dispatch({
                type: GET_USER,
                payload: decoded
            })

        })
        .catch(err => {
            // console.log(err)
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        })
}

//Log user out
export const logoutUser = () => dispatch => {
    //Remove token from localStorage
    localStorage.removeItem('jwtToken');
    //Remove auth header for future requests
    setAuthToken(false);
    //Set current user to {} which will set isAuthenticated to false
    dispatch(setCurrentUser({}));
};

export const sendUpdate = (nfc) => dispatch => {
    axios.get(`/api/user/sendupdate/${nfc}`)
        .then(res => {
            // dispatch(setCurrentUser(res.data));
        })
        .catch(err => {
            // dispatch({
            //     type: GET_ERRORS,
            //     payload: err.response.data
            // });
        })
};
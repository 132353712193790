import axios from "axios/index";
import {CLEAR_ERRORS, GET_ERRORS, GET_NFC_REDIRECT, GET_NFCS, POST_STAT,} from "./types";

export const getNfcs = () => dispatch => {
    axios.get(`/api/nfc/all`)
        .then(res => {
            dispatch({
                type: GET_NFCS,
                payload: res.data
            })
        })
        .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response && err.response.data ? err.response.data : ""
                })
            }
        )
};
export const updateNfcs = (nfcs) => dispatch => {
    axios.post(`/api/nfc/updateAll`,{nfcs:nfcs})
        .then(res => {
            dispatch({
                type: GET_NFCS,
                payload: res.data
            })
        })
        .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response && err.response.data ? err.response.data : ""
                })
            }
        )
};

export const addNFC = () => dispatch => {
    axios.get(`/api/nfc/add`)
        .then(res => {
            dispatch({
                type: GET_NFCS,
                payload: res.data
            })
        })
        .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response && err.response.data ? err.response.data : ""
                })
            }
        )
};

export const removeNfc = (id) => dispatch => {
    axios.get(`/api/nfc/remove/${id}`)
        .then(res => {
            dispatch({
                type: GET_NFCS,
                payload: res.data
            })
        })
        .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response && err.response.data ? err.response.data : ""
                })
            }
        )
};


export const getNfcRedirect = (code) => dispatch =>{
    axios.get(`/api/nfc/getNfcRedirect/${code}`).then(res => {
        dispatch({
            type: GET_NFC_REDIRECT,
            payload: res.data
        })
    })
        .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response && err.response.data ? err.response.data : ""
                })
            }
        )
}


//Person loading
export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS
    }
};

import React, {ReactElement} from 'react';
import '../Icon.scss';
import IconProps from "../IconProps.interface";

const ContactBackOfficeIcon = (props: IconProps) : ReactElement => {

    return (
        <div className="icon">
            <svg version="1.2" fill={props.color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">  {/*width="60" height="60"*/}
                <path id="Layer" className="s0"
                      d="m36 21.5q0.6 0.2 1 0.7 0.5 0.5 0.7 1.1 0.3 0.6 0.3 1.3v14.9q0 0.7-0.3 1.3-0.2 0.6-0.7 1.1-0.4 0.5-1 0.7-0.6 0.3-1.2 0.3h-1.4v11.1l-10.7-11.1h-14.5q-0.6 0-1.2-0.3-0.6-0.2-1.1-0.7-0.4-0.5-0.7-1.1-0.2-0.6-0.2-1.3v-14.9q0-0.7 0.2-1.3 0.3-0.6 0.7-1.1 0.5-0.5 1.1-0.7 0.6-0.3 1.2-0.3h26.6q0.6 0 1.2 0.3z"/>
                <path id="Layer" className="s0"
                      d="m52.3 29.1l0.2 11-8-3.9q-0.5 0.3-1 0.5-0.6 0.1-1.2 0.3-0.5 0.2-1.1 0.3-0.5 0.1-1.1 0.1v-14.5q0-0.7-0.2-1.4-0.2-0.6-0.7-1.2-0.4-0.5-1-0.9-0.6-0.3-1.3-0.3h-14.7c1.3-7.5 7.9-13.1 15.9-13.1 8.9 0 16.1 7 16.1 15.8q0 0.9-0.1 1.9-0.1 0.9-0.4 1.9-0.2 0.9-0.6 1.8-0.3 0.9-0.8 1.7z"/>
            </svg>
        </div>
    )
};

export default ContactBackOfficeIcon;

import axios from 'axios';
import {GET_VARIATIONS} from "./types";

export const getAllVariations = () => dispatch => {
    axios.get(`/api/variations/all/`)
        .then(res => {
            dispatch({
                type: GET_VARIATIONS,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_VARIATIONS,
            payload: null
        }))
};

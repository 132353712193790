import React from "react";
import ButtonColors from "../button.colors";
import '../button.styles.css'
import {Link} from "react-router-dom";

export interface buttonProps {
    color: ButtonColors,
    onClick?: () => void,
    text: string,
    style?: any,
    newPage?: boolean,
    href?: string,
}

class MainButton extends React.Component<buttonProps, {}> {
    render() {
        if (this.props.newPage){
            return <a style={this.props.style} className={"main-button " + ButtonColors[this.props.color]}
                         href={this.props.href} target={this.props.newPage ? "_blank" : ""}>{this.props.text}</a>
        }
        if (this.props.href) {
            return <Link style={this.props.style} className={"main-button " + ButtonColors[this.props.color]}
                      to={this.props.href} target={this.props.newPage ? "_blank" : ""}>{this.props.text}</Link>
        }
        return <button style={this.props.style} className={"main-button " + ButtonColors[this.props.color]}
                       onClick={this.props.onClick}>{this.props.text}</button>
    }

}

export default MainButton;
import React from "react";
import './blocks.styles.css'
// @ts-ignore
import {default as EyeIcon} from "../../../assets/icons/eye.icon.svg"
// @ts-ignore
import {default as CloudIcon} from "../../../assets/icons/cloud.icon.svg"
// @ts-ignore
import {default as DesignIcon} from "../../../assets/icons/design.icon.svg"
// @ts-ignore
import {default as CardsBackground} from "../../../assets/containers/small.container.png"
import SubSubSubTitle from "../fields/text/subsubsubtitle.text";
import {translate} from "react-i18nify";

export interface faqBlockProps {
    text: string,
    title: string,
    open?: boolean,
    last?: boolean,
    first?: boolean,
    alternatives?: boolean,
}

export interface faqBlockState {
    open: boolean,
}

class FaqBlock extends React.Component<faqBlockProps, faqBlockState> {
    constructor(props) {
        super(props);
        this.state = {open: this.props.open ? this.props.open : false}
    }


    render() {
        let content;
        // if (this.props.last) {
        //     content = (<><span>{this.props.text}</span>
        //         <ul>
        //             <li>{translate("hd.home.faq4AnswerList1")}</li>
        //             <li>{translate("hd.home.faq4AnswerList2")}</li>
        //             <li>{translate("hd.home.faq4AnswerList3")}</li>
        //             <li>{translate("hd.home.faq4AnswerList4")}</li>
        //             <li>{translate("hd.home.faq4AnswerList5")}</li>
        //         </ul>
        //     </>)
        // } else if (this.props.alternatives) {
        //     content = <>
        //         <span>{this.props.text}</span>
        //         <ol>
        //             <li>{translate("hd.home.faq5AnswerList1")}</li>
        //             <br/>
        //             <li>{translate("hd.home.faq5AnswerList2")}</li>
        //         </ol>
        //     </>
        // } else {
        //     content = <></>
        // }
        return (<div className={"block-faq"} style={this.props.last ? {borderBottom: "1px solid #334c6e4D"} : {}}
        >
            <div className={"block-faq-header"} onClick={() => this.setState({open: !this.state.open})}>
                <SubSubSubTitle style={{fontSize: "18px", textAlign: "left"}} text={this.props.title}/>
                <span>{this.state.open ? "-" : "+"}</span>
            </div>
            <div className={"block-faq-content"} hidden={!this.state.open}>
                {this.props.text}
            </div>
        </div>);
    }

}

export default FaqBlock;

import React from "react";
// @ts-ignore
import {default as phone} from "../../../assets/calculator/phone.calculator.svg"
import CalculatorOptionBlock from "./calculatorOptions.block";
import {CRMAnimation, DomainAnimation, NFCAnimation, SSOAnimation} from "./utils";
// @ts-ignore
import {default as loadingcard} from "../../../assets/calculator/loadingcard.calculator.svg"
// @ts-ignore
import {default as filledcard} from "../../../assets/calculator/filledcard.calculator.svg"
// @ts-ignore
import {default as backface} from "../../../assets/calculator/backface.calculator.svg"
// @ts-ignore
import {default as loadingnfc} from "../../../assets/calculator/loadingnfc.calculator.svg"
// @ts-ignore
import {default as nfcTag} from "../../../assets/calculator/nfctag.calculator.svg"


interface calculatorBlockProps {
    width: number,
}

interface calculatorBlockState {
    animationActive: {
        nfc: boolean,
        domain: boolean,
        sso: boolean,
        crm: boolean
    },
    phoneImage: any,
    opacities: any,
    prevOpacities: any,
    flipCard: string,
    nfcOpacity: number,
    nfcCanceled: boolean,
    nfcLeft: number
    ripple: number
    crmActive: boolean,
    domain?: string,
    customDomain: boolean,
    extension: string,
}

class CalculatorBlockMedium extends React.Component<calculatorBlockProps, calculatorBlockState> {

    constructor(props) {
        super(props);
        this.state = {
            animationActive: {
                nfc: false,
                domain: false,
                sso: false,
                crm: false
            },
            customDomain: false,
            phoneImage: phone,
            flipCard: "",
            crmActive: false,
            nfcCanceled: false,
            nfcLeft: 0,
            ripple: 0,
            opacities: [1, 0, 0, 0],
            prevOpacities: [1, 0, 0, 0],
            nfcOpacity: 0.0,
            extension: "card.com"
        }
    }

    render() {
        return (<div className={"calculator-outer-container"}>
            <div className={"calculator-inner-container"} style={{alignItems: "center"}}>
                <img className={"calculator-phone-normal"} style={{
                    opacity: this.state.opacities[0],
                    transform: this.state.flipCard,
                }} src={phone}/>
                <img className={"calculator-phone-backside"} style={{
                    transform: this.state.flipCard === "" ? "rotateY(180deg)" : "rotateY(360deg)"
                }} src={backface}/>
                <img className={"calculator-phone-special"} style={{
                    opacity: this.state.opacities[1],
                    transform: this.state.flipCard,

                }} src={loadingcard}/>
                <img className={"calculator-phone-special"} style={{
                    opacity: this.state.opacities[2],
                    transform: this.state.flipCard,

                }} src={filledcard}/>
                <img className={"calculator-phone-special"} style={{
                    opacity: this.state.opacities[3],
                    transform: this.state.flipCard,
                }} src={loadingnfc}/>
                <div style={{position: "absolute", textAlign: "left"}}>
                    <div
                        style={{position: "relative", top: 230, left: 10, textAlign: "left", width: 235, transition: "opacity " + (this.state.flipCard === "" ? "4" : "1") + "s ease, transform 0.8s linear", transform: this.state.flipCard, transformStyle: "preserve-3d", backfaceVisibility: "hidden", opacity: this.state.flipCard === "" ? 1 : 0, MozBackfaceVisibility: "hidden"}}>
                        <span
                            style={{whiteSpace: "nowrap", textOverflow: "ellipsis", maxWidth: 235, overflow: "hidden", display: "block"}}>{"www." + (this.state.customDomain && this.state.domain ? this.state.domain + this.state.extension + "/" : "bizzerd.com/cards/bedrijf/")}</span>
                    </div>
                </div>
            </div>
            <div className={"calculator-inner-container"}>
                <SSOAnimation/>

                <CRMAnimation changePhoneImage={this.changePhoneImage} CRMAnimationActive={this.CRMAnimationActive}/>

                <NFCAnimation
                    flipPhone={(animationActive) => {
                        console.log(animationActive, this.state.flipCard)
                        if (this.state.flipCard !== "" || animationActive) {
                            return this.setState({nfcLeft: 0, ripple: 0, flipCard: "", nfcCanceled: true, nfcOpacity: 0})
                        } else {
                            this.setState({prevOpacities: this.state.opacities, nfcCanceled: false})
                            setTimeout(() => this.setState({nfcLeft: -480, opacities: [0, 0, 0, 1]}), 800)
                            setTimeout(() => {
                                if (!this.state.nfcCanceled)
                                    this.setState({ripple: 1})
                                else {
                                    this.setState({nfcLeft: 0, opacities: [1, 0, 0, 0]})
                                }
                            }, 2400)
                            setTimeout(() => {

                                if (!this.state.nfcCanceled)
                                    this.setState({
                                        nfcLeft: 0, ripple: 0,
                                        flipCard: this.state.flipCard === "" ? "rotateY(180deg)" : "",
                                        nfcOpacity: this.state.nfcOpacity > 0.5 ? 0.0 : 1.0
                                    })
                            }, 5000)
                            setTimeout(() => {
                                if (this.state.nfcCanceled)
                                    return
                                if (this.state.crmActive) {
                                    this.setState({opacities: [0, 0, 1, 0]})
                                }
                                if (this.state.opacities[1] === 1) {
                                    this.setState({opacities: [0, 0, 1, 0]})
                                }
                                if (this.state.opacities[3] === 1) {

                                    this.setState({opacities: [1, 0, 0, 0]})
                                }
                            }, 6000)
                        }
                        this.setState({
                            flipCard: this.state.flipCard === "" ? "rotateY(180deg)" : "",
                            nfcOpacity: this.state.nfcOpacity > 0.5 ? 0.0 : 1.0
                        })

                    }} cancelNFCAnimation={this.cancelNFCAnimation}/>
                <div style={{position: "absolute", pointerEvents: "none"}}>
                    <img src={nfcTag} className={"calculator-nfc-tag"}
                         style={{
                             left: this.state.nfcLeft,
                             opacity: this.state.nfcOpacity,
                         }}/>
                    <div className={"calculator-circle-container"} style={{
                        opacity: this.state.ripple
                    }}>
                        <div className={"circle circle1"}></div>
                        <div className={"circle circle2"}></div>
                        <div className={"circle circle3"}></div>
                    </div>
                </div>

                <DomainAnimation changeDomain={this.changeDomain} domain={this.state.domain}
                                 changeExtension={this.changeExtension} customDomainActive={this.customDomainActive}/>
            </div>

        </div>)

    }

    customDomainActive = (active) => {
        this.setState({customDomain: active})
    }

    CRMAnimationActive = (active) => {
        this.setState({crmActive: active})
    }

    cancelNFCAnimation = () => {
        this.setState({nfcCanceled: true})
    }

    changeDomain = (domain) => {
        this.setState({domain: domain})
    }

    changeExtension = (extension) => {
        this.setState({extension: extension})
    }
    changePhoneImage = (phoneState) => {
        if (!this.state.crmActive && phoneState === "filled")
            return this.setState({crmActive: true})

        switch (phoneState) {
            case "loading":
                this.setState({opacities: [0, 1, 0, 0]})
                break
            case "filled":
                this.setState({opacities: [0, 0, 1, 0]})
                break
            default:
                this.setState({opacities: [1, 0, 0, 0]})
                break
        }
    }

}

export default CalculatorBlockMedium;
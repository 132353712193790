import {
    CLEAR_PERSON, CLEAR_PERSONS,
    EXIST_COMPANYNAME,
    GET_ANALYTICS,
    GET_PERSON,
    GET_PERSONS, GET_PERSONSLIBRARY,
    PERSON_LOADING,
    PHOTO_SUCCESS
} from "../actions/types";

const initialState = {
    person: {},
    persons: [],
    personsSmall: [],
    loading: false,
    photoSuccess: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case PERSON_LOADING:
            return {
                ...state,
                loading: true,
                photoSuccess: false
            };
        case GET_PERSONS:
            return {
                ...state,
                persons: action.payload,
                loading: false
            };
        case GET_PERSONSLIBRARY:
            return {
                ...state,
                personsSmall: action.payload,
                loading: false
            };
        case GET_ANALYTICS:
            return {
                ...state,
                views: action.payload
            };
        case GET_PERSON:
            return {
                ...state,
                person: action.payload,
                loading: false,
                photoSuccess: false
            };
        case PHOTO_SUCCESS:
            return {
                ...state,
                photoUrl: action.payload,
                photoSuccess: true
            };
        case CLEAR_PERSONS:
            return {
                ...state,
                person: {},
            }
        case EXIST_COMPANYNAME:
            return {
                ...state,
                exists: action.payload,
            }
        default:
            return state;
    }
}

import React from "react";
import './container.styles.css'
import '../blocks/blocks.styles.css'
import DisplayBizzerd from "../../bizzerddisplay/DisplayBizzerd"
// @ts-ignore
import {default as leftCircle} from "../../../assets/blocks/left.circle.png"
// @ts-ignore
import {default as rightCircle} from "../../../assets/blocks/right.circle.png"
import {translate} from "react-i18nify";

export interface containerProps {
    children?: [],
    bizzerdLayout?: [],
    personContent?: {},
    width: number,
    variation?: any,
    steps?: Array<number>,
    circleSize?: number,
    drawCircle?: boolean,
    style?: {},
    onSelectField?: (fieldId) => void,
    onClick?: any,
    logo?: string,
    img?: string,
}


class RoundBizzerdContainer extends React.Component<containerProps, { selectedVariationIndex: number }> {

    constructor(props) {
        super(props);
        this.state = {
            selectedVariationIndex: 0
        }
        this.changeVariation = this.changeVariation.bind(this)
    }

    changeVariation(index) {
        this.setState({selectedVariationIndex: index})
    }

    render() {

        let realWidth = this.props.steps ? this.props.steps[0] : 140
        if (this.props.width < 468) {
            realWidth = this.props.steps ? this.props.steps[3] : 80
        } else if (this.props.width < 568) {
            realWidth = this.props.steps ? this.props.steps[2] : 100
        } else if (this.props.width < 768) {
            realWidth = this.props.steps ? this.props.steps[1] : 120
        }
        if (this.props.img) {
            return <>
                <div className={"bizzerd-circle-container"}
                     style={this.props.steps ? {paddingTop: "40px", cursor: "default"} : {cursor: "default"}}>
                    <img src={leftCircle}
                         style={this.props.drawCircle === false ? {display: "none"} : {
                             height: this.props.circleSize ? this.props.circleSize + "px" : "",
                             width: this.props.circleSize === 170 ? 30 : ""
                         }}
                         className={"circle-part" + (this.props.steps ? "-big" : "")}/>
                    <div className={"container-round-bizzerd"} style={this.props.style}>
                        {<img style={{maxWidth: realWidth}} src={this.props.img}/>}
                    </div>
                    <img src={rightCircle}
                         style={this.props.drawCircle === false ? {display: "none"} : {
                             height: this.props.circleSize ? this.props.circleSize + "px" : "",
                             width: this.props.circleSize === 170 ? 30 : ""
                         }}
                         className={"circle-part" + (this.props.steps ? "-big" : "")}/>

                </div>
            </>;
        }

        let bizzerd = <></>;
        if (this.props.bizzerdLayout) {
            bizzerd = <DisplayBizzerd shouldShowShadow={false} realWidth={realWidth}
                                      bizzerdLayout={this.props.bizzerdLayout} onSelectField={this.props.onSelectField}
                                      personContent={this.props.personContent} shouldBeClickable={false}/>
        }
        if (this.props.variation) {
            let variation = this.props.variation.templateIds[this.state.selectedVariationIndex]
            bizzerd = <DisplayBizzerd shouldShowShadow={false} realWidth={realWidth}
                                      templateId={variation} shouldBeClickable={false} logo={this.props.logo}
                                      personContent={this.props.personContent}/>
        }

        let colorRow = []
        if (this.props.variation && this.props.variation.colors) {
            colorRow = this.props.variation.colors.map((color, index) => {
                if (color.length > 1) {
                    return <div
                        className={"variation-ball" + (index === this.state.selectedVariationIndex ? " selected" : "")}
                        style={{
                            background: "linear-gradient(90deg," + color[0] + " 50%," + color[1] + " 50%)"
                        }}
                        onClick={() => this.changeVariation(index)}
                    />
                } else
                    return <div
                        className={"variation-ball" + (index === this.state.selectedVariationIndex ? " selected" : "")}
                        style={{
                            backgroundColor: color
                        }}
                        onClick={() => this.changeVariation(index)}
                    />
            })
        }


        if (this.props.onClick && this.props.onClick === "detail" && this.props.variation.templateIds[this.state.selectedVariationIndex]) {
            return <>
                <a className={"bizzerd-circle-container"}
                   href={translate("hd.links.detail") + "/" + this.props.variation.templateIds[this.state.selectedVariationIndex]}
                   style={this.props.steps ? {paddingTop: "40px"} : {}}
                   onClick={() => {
                       let temp_ids: any = sessionStorage.getItem('template_ids')
                       if (temp_ids) {
                           temp_ids = JSON.parse(temp_ids)
                       } else {
                           temp_ids = [this.props.variation.templateIds[this.state.selectedVariationIndex]]
                       }
                       window.gtag('event', 'view_design', {template_ids: temp_ids});
                   }}>
                    <img src={leftCircle}
                         style={this.props.drawCircle === false ? {display: "none"} : {
                             height: this.props.circleSize ? this.props.circleSize + "px" : "",
                             width: this.props.circleSize === 170 ? 30 : ""
                         }}
                         className={"circle-part" + (this.props.steps ? "-big" : "")}/>
                    <div className={"container-round-bizzerd"} style={this.props.style}>
                        {bizzerd}
                    </div>
                    <img src={rightCircle}
                         style={this.props.drawCircle === false ? {display: "none"} : {
                             height: this.props.circleSize ? this.props.circleSize + "px" : "",
                             width: this.props.circleSize === 170 ? 30 : ""
                         }}
                         className={"circle-part" + (this.props.steps ? "-big" : "")}/>

                </a>
                <div className={"variation-selector"}>
                    {colorRow}
                </div>
            </>;
        }


        return <>
            <div className={"bizzerd-circle-container"} id={"bizzerdCircle"} style={this.props.steps ? {
                paddingTop: "40px",
                cursor: this.props.onClick ? "pointer" : "default"
            } : {cursor: this.props.onClick ? "pointer" : "default"}}
                 onClick={() => {
                     if (this.props.onClick && this.props.onClick === "detail" && this.props.variation.templateIds[this.state.selectedVariationIndex]) {
                         window.gtag('event', 'view_item', {
                             value: 9.48, currency: "EUR", items: [
                                 {
                                     item_id: this.props.variation.templateIds[this.state.selectedVariationIndex],
                                     item_name: "Bizzerd design",
                                     price: 9.48,
                                     quantity: 1
                                 }
                             ]
                         });
                         window.location.href = "/detail/" + this.props.variation.templateIds[this.state.selectedVariationIndex]

                     } else if (this.props.onClick) {
                         this.props.onClick()
                     }
                 }}>
                <img src={leftCircle}
                     style={this.props.drawCircle === false ? {display: "none"} : {
                         height: this.props.circleSize ? this.props.circleSize + "px" : "",
                         width: this.props.circleSize === 170 ? 30 : ""
                     }}
                     className={"circle-part" + (this.props.steps ? "-big" : "")}/>
                <div className={"container-round-bizzerd"} style={this.props.style}>
                    {bizzerd}
                </div>
                <img src={rightCircle}
                     style={this.props.drawCircle === false ? {display: "none"} : {
                         height: this.props.circleSize ? this.props.circleSize + "px" : "",
                         width: this.props.circleSize === 170 ? 30 : ""
                     }}
                     className={"circle-part" + (this.props.steps ? "-big" : "")}/>

            </div>
            <div className={"variation-selector"}>
                {colorRow}
            </div>
        </>;
    }

}

export default RoundBizzerdContainer;
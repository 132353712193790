import React, {useEffect} from 'react';
import './App.css';
import MainRouter from "./components/Main.router";
import {Provider} from "react-redux";
import store from "./store";
import HttpsRedirect from 'react-https-redirect'
import jwt_decode from "jwt-decode";
import {logoutUser, setCurrentUser} from "./actions/userActions";
import setAuthToken from "./utils/setAuthToken";
import {useHistory, useLocation} from "react-router-dom";


if (localStorage.jwtToken) {
    //Set auth token header Auth
    setAuthToken(localStorage.jwtToken);
    //Decode
    const decoded = jwt_decode(localStorage.jwtToken);
    // Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded));
    //Check for expired token
    const currentTime = Date.now() / 1000;
    if (decoded.exp < currentTime) {


        //Logout user
        store.dispatch(logoutUser());
        // Redirect to login
        if (window.location.href.includes('addcolleague')) {
            window.location.href = '/login'
        }
        if (!window.location.href.includes("/show/") || !window.location.href.includes("/cards/")) {
            // window.location.href = '/login';
        }
    }
}


function App() {


    return (
        <HttpsRedirect>
            <Provider store={store}>
                <div className="App">

                    <MainRouter />
                </div>
            </Provider>
        </HttpsRedirect>
    );
}

export default App;

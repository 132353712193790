import React from 'react'
import GradientContainer from "../common2/containers/gradient.container";
import PageTitle from "../common2/fields/text/pagetitle.text";
import SubSubSubTitle from "../common2/fields/text/subsubsubtitle.text";
import ShoppingCartFlow from "../common2/flow/shoppingcart.flow";
import Footer from "../common2/footers/footer";
import GrayContainer from "../common2/containers/gray.container";
import NavigationHeader from "../common2/headers/navigation.header";
import {translate} from "react-i18nify";

interface pageProps {
    width: number
}

class ShoppingcartPage extends React.Component<pageProps, {}> {
    render() {
        return (<>
            <GrayContainer style={{paddingBottom: "40px"}}>
                <NavigationHeader width={this.props.width}/>
            </GrayContainer>
            <GradientContainer>
                <PageTitle text={translate("hd.shoppingCart.pageTitle")} style={{paddingBottom: "20px"}}/>
                <SubSubSubTitle
                    text={translate("hd.shoppingCart.subTitle")}/>
                <ShoppingCartFlow width={this.props.width}/>
            </GradientContainer>
            <Footer/></>);
    }
}

export default ShoppingcartPage
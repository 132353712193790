import React, {Component} from 'react'
import './toolbar.scss'
// @ts-ignore
import bizzerdLogo from '../../../../assets/img/bizzerd_logo.png';
// @ts-ignore
import bizzerdLogoSmall from '../../../../assets/img/bizzerd-b.png';
import {desktopVersionMinimumSize, ICON_COLOR} from '../../../../constants';
import UserIcon from '../../../common/Icons/User/User.icon';
import ShoppingCart from '../../../common/Icons/ShoppingCart/ShoppingCart.icon';
import {getLocale, translate} from "react-i18nify";
import {connect} from "react-redux";
import {logoutUser} from '../../../../actions/userActions';
import SettingsBackOffice from "../../../common/Icons/Settings/SettingsBackOffice.icon";
import ContactBackOfficeIcon from "../../../common/Icons/Contact/ContactBackOffice.icon";
import ManageCardsBackOfficeIcon from '../../../common/Icons/ManageCards/ManageCardsBackOffice.icon';
// @ts-ignore
import dutchFlag from "../../../../assets/img/EN vlag.png";
import englishFlag from "../../../../assets/img/NL vlag.png";
import LetUsDesignIconBackOffice from "../../../common/Icons/LetUsDesign/LetUsDesignBackOffice.icon";

interface toolbarProps {
    backoffice?: boolean,
    logoutUser: () => void,
    useLanguage?: () => void,
    shouldFollow?: boolean,
}

interface toolbarState {
    isDesktop: boolean,
    width: number,
}

class ToolBar extends Component<toolbarProps, toolbarState> {

    constructor(props) {
        super(props);
        this.state = {
            isDesktop: false,
            width: window.screen.width,
        };
        window.addEventListener('resize', this.reportWindowSize);
        window.addEventListener('scroll', this.reportWindowSize);

        this.updatePredicate = this.updatePredicate.bind(this);
        this.openHomePage = this.openHomePage.bind(this);
        this.openShoppingCartPage = this.openShoppingCartPage.bind(this);
        this.openAuthPage = this.openAuthPage.bind(this);
    }

    openHomePage() {
        window.location.href = window.location.origin
    }

    openShoppingCartPage() {
        window.location.href = window.location.origin + "/shoppingcart"
    }

    openAuthPage() {
        if (this.props.backoffice) {
            // e.preventDefault();
            this.props.logoutUser();
            window.location.href = window.location.origin
        } else {
            window.location.href = window.location.origin + "/auth"
        }
    }

    reportWindowSize = () => {
        this.setState({width: window.innerWidth})
    }

    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
    }

    updatePredicate() {
        this.setState({isDesktop: window.innerWidth > desktopVersionMinimumSize});
    }

    changeLanguage = () => {
        if (getLocale() === "en") {
            localStorage.setItem("language", "nl")
        } else {
            localStorage.setItem("language", "en")
        }
        window.location.reload()
    }

    myFunction = () => {
        // console.log('ye')
        var x = document.getElementById("myLinks");
        var y = document.getElementById("topnav");
        if (x && y)
            if (x.style.display === "block") {
                x.style.display = "none";
                y.style.boxShadow = '';
            } else {
                x.style.display = "block";
                y.style.boxShadow = "5px 5px 10px rgb(0 0 0 / 0.2)";
            }
    }

    render(): JSX.Element {
        const isDesktop = this.state.isDesktop;
        const shouldShowIcons = this.shouldIconsBeShown();
        let shouldShowLabels = true;
        const showLanguage = true
        let pathname = new URL(window.location.href).pathname;
        if (pathname.indexOf('/', 1) !== -1) {
            pathname = pathname.substring(0, pathname.indexOf('/', 1))
        }
        let active_dict = {
            // "/": "",
            "/business-cards": "",
            "/reviews": "",
            "/payment-plans": "",
            "/contact": "",
            "/method": "",
            "/enterprise": "",
            "/product": ""
        }
        active_dict[pathname] = "selectedNavbarItem"
        let toolbarClassname = "toolbarContainer"
        let logoClassname = "logoSection"
        if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 0 && this.props.shouldFollow) {
            toolbarClassname = "toolbarContainer is--scrolled"
            logoClassname = "logoSection is--scrolled-logo"
        }
        if (this.state.width < 1315) {
            shouldShowLabels = false;
        }
        let toolbar = (<div className={toolbarClassname}>
            <div className={logoClassname}>
                <img src={bizzerdLogo} onClick={this.openHomePage} className="logo" alt="Bizzerd logo"/>
            </div>
            {this.props.shouldFollow ?
                <div className={"middleSection"}>
                    <a className="navbarItem" href={'/business-cards'}>
                        <span className={active_dict['/business-cards']}>{translate("navBar.businessCards")}</span>
                    </a>
                    <a className="navbarItem" href={'/product'}>
                        <span className={active_dict['/product']}>{translate("navBar.product")}</span>
                    </a>
                    <a className="navbarItem" href={'/payment-plans'}>
                        <span className={active_dict['/payment-plans']}>{translate("navBar.pricing")}</span>
                    </a>
                    <a className="navbarItem" href={'/enterprise'}>
                        <span className={active_dict['/enterprise']}>{translate("navBar.enterprise")}</span>
                    </a>
                    <a className="navbarItem" href={'/contact'}>
                        <span className={active_dict['/contact']}>{translate("navBar.contact")}</span>
                    </a>

                </div>
                : <></>}
            <div className="rightSection">

                {shouldShowIcons ?
                    (<>
                        <div className="toolbarItem"
                             style={{justifyContent: "flex-end", display: showLanguage ? "flex" : "none"}}
                             onClick={() => this.changeLanguage()}>
                            <img className={"flagImage"} alt={"english flag"}
                                 src={getLocale() === "nl" ? englishFlag : dutchFlag}/>
                        </div>

                        <div onClick={this.openAuthPage} className="toolbarItem">
                            <div className="toolbarIcon"><UserIcon color={ICON_COLOR}/></div>
                            <span
                                hidden={!isDesktop || !shouldShowLabels}>{this.props.backoffice ? translate('generic.logOut') : translate('generic.logIn')}</span>

                        </div>
                        <div onClick={this.openShoppingCartPage} className="toolbarItem">
                            <div className="toolbarIcon"><ShoppingCart color={ICON_COLOR}/></div>
                            <span className="toolbarIconText"
                                  hidden={!isDesktop || !shouldShowLabels}>{translate('generic.shoppingCart')}</span>
                        </div>
                    </>) : <></>}
            </div>
        </div>)

        if (this.state.width < 768) {
            toolbar = (
                <div className="toolbarContainer">
                    <a href="#" className="hamburg" onClick={() => this.myFunction()}>
                        <i className="fa fa-bars"/>
                    </a>
                    <div className="logoSection">
                        <img src={bizzerdLogo} onClick={this.openHomePage} className="logo" alt="Bizzerd logo"/>
                    </div>
                    <div className="topnav" id="topnav">
                        <div id="myLinks">
                            <div className="navbarItemMenu" onClick={() => this.myFunction()}>
                                <span className={'menu'}>Menu</span>
                                <span className={'menuIcon'}>&#10006;</span>
                            </div>
                            {/*<a className="navbarItem" href={'/'}>*/}
                            {/*    <span className={active_dict['/']}>{translate("navBar.home")}</span>*/}
                            {/*    <span className={"navbarItemIcon"}><i className="fas fa-chevron-right"/></span>*/}
                            {/*</a>*/}
                            <a className="navbarItem" href={'/business-cards'}>
                                <span
                                    className={active_dict['/business-cards']}>{translate("navBar.businessCards")}</span>
                                <span className={"navbarItemIcon"}><i className="fas fa-chevron-right"/></span>

                            </a>
                            <a className="navbarItem" href={'/product'}>
                                <span className={active_dict['/product']}>{translate("navBar.product")}</span>
                                <span className={"navbarItemIcon"}><i className="fas fa-chevron-right"/></span>
                            </a>
                            <a className="navbarItem" href={'/payment-plans'}>
                                <span className={active_dict['/payment-plans']}>{translate("navBar.pricing")}</span>
                                <span className={"navbarItemIcon"}><i className="fas fa-chevron-right"/></span>
                            </a>
                            <a className="navbarItem" href={'/enterprise'}>
                                <span className={active_dict['/enterprise']}>{translate("navBar.enterprise")}</span>
                                <span className={"navbarItemIcon"}><i className="fas fa-chevron-right"/></span>
                            </a>
                            <a className="navbarItem" href={'/contact'}>
                                <span className={active_dict['/contact']}>{translate("navBar.contact")}</span>
                                <span className={"navbarItemIcon"}><i className="fas fa-chevron-right"/></span>
                            </a>

                        </div>
                    </div>
                    <div className="rightSection">

                        {/*<div className="toolbarItem">*/}
                        {/*    <SearchBar iconOnly={true}/>*/}
                        {/*</div>*/}

                        {shouldShowIcons ?
                            (<>
                                <div className="toolbarItem"
                                     style={{justifyContent: "flex-end", display: showLanguage ? "" : "none"}}
                                     onClick={() => this.changeLanguage()}>
                                    <img className={"flagImage"} alt={"english flag"}
                                         src={getLocale() === "nl" ? englishFlag : dutchFlag}/>
                                </div>

                                <div className="toolbarItem">
                                    <div className="toolbarIconLogin" onClick={this.openAuthPage}><UserIcon
                                        color={ICON_COLOR}/></div>
                                    <span
                                        hidden={!isDesktop}>{(this.props.backoffice ? translate('generic.logOut') : translate('generic.logIn'))}</span>
                                </div>
                                <div className="toolbarItem">
                                    <div className="toolbarIcon" onClick={this.openShoppingCartPage}><ShoppingCart
                                        color={ICON_COLOR}/></div>
                                    <span hidden={!isDesktop}>{translate('generic.shoppingCart')}</span>
                                </div>
                            </>)
                            : <></>}

                    </div>
                </div>)
        }
        if (this.props.backoffice) {
            toolbar = this.generateBackOfficeToolbar(false)
        }
        return (toolbar)
    }

    generateBackOfficeToolbar = (showLanguage) => {
        const isDesktop = this.state.isDesktop;
        let logoStyle
        let settingsStyle
        if (this.state.width < 768) {
            logoStyle = {left: "10px", width: "30%"}
            // settingsStyle = {top: "-8px"}
        }
        return (<div className="toolbarContainer">
            {this.state.width > 768 ?
                <div className="logoSection">
                    <img src={bizzerdLogo} onClick={this.openHomePage} className="logo" alt="Bizzerd logo"/>
                </div>
                : <div className="logoSection" onClick={this.openHomePage}>
                    <img style={logoStyle} src={bizzerdLogoSmall} className="logo" alt="Bizzerd logo"/>
                </div>}
            {/*<div className="searchbarSection">*/}
            {/*    <SearchBar iconOnly={false}/>*/}
            {/*</div>*/}
            <div className="rightSection">
                {/*<div className="toolbarItemBack">*/}
                {/*    <LocaleSelector/>*/}
                {/*</div>*/}
                <div className="toolbarItem" style={{justifyContent: "flex-end", display: showLanguage ? "" : "none"}}
                     onClick={() => this.changeLanguage()}>
                    <img className={"flagImage"} alt={"english flag"}
                         src={getLocale() === "nl" ? englishFlag : dutchFlag}/>
                </div>
                {/*<div onClick={this.openInsights} className="toolbarItem">*/}
                {/*    <div className="toolbarIcon"><ManageCardsBackOfficeIcon color={ICON_COLOR}/></div>*/}
                {/*    <span hidden={!isDesktop}>{translate('generic.insights')}</span>*/}
                {/*</div>*/}
                <div onClick={this.openManageBusinesscards} className="toolbarItem">
                    <div className="toolbarIcon"><ManageCardsBackOfficeIcon color={ICON_COLOR}/></div>
                    <span hidden={!isDesktop}>{translate('generic.manageBusinesscards')}</span>
                </div>
                <div onClick={this.openManageSubscriptions} className="toolbarItem">
                    <div className="toolbarIcon" style={settingsStyle}><SettingsBackOffice color={ICON_COLOR}/></div>
                    <span hidden={!isDesktop}>{translate('generic.manageSubscriptions')}</span>
                </div>
                {/*<div onClick={this.openChangeDesign} className="toolbarItem">*/}
                {/*    <div className="toolbarIcon"><EyeIcon color={ICON_COLOR}/></div>*/}
                {/*    <span hidden={!isDesktop}>{translate('generic.changeDesign')}</span>*/}
                {/*</div>*/}

                <div onClick={() => {
                    window.location.href = window.location.origin + "/contact"
                }} className="toolbarItem">
                    <div className="toolbarIcon"><ContactBackOfficeIcon color={ICON_COLOR}/></div>
                    <span hidden={!isDesktop}>{translate('generic.contact')}</span>
                </div>
                <div onClick={this.openSettings} className="toolbarItem">
                    <div className="toolbarIcon"><LetUsDesignIconBackOffice color={ICON_COLOR}/></div>
                    <span hidden={!isDesktop}>{translate('generic.settings')}</span>
                </div>
                {/*<div onClick={this.openShoppingCartPage} className="toolbarItem">*/}
                {/*    <div className="toolbarIcon"><ShoppingCart  color={ICON_COLOR}/> </div>*/}
                {/*    <span hidden={!isDesktop}>{translate('generic.shoppingCart')}</span>*/}
                {/*</div>*/}
                <div onClick={this.openAuthPage} className="toolbarItem">
                    <div className="toolbarIcon"><UserIcon color={ICON_COLOR}/></div>
                    <span hidden={!isDesktop}>{this.props.backoffice ?
                        translate('generic.logOut') : translate('generic.logIn')}</span>
                </div>

            </div>
        </div>);
    }

    private openChangeDesign() {
        window.location.href = window.location.origin + '/dashboard/ontwerpen/kiesdesign'
    }

    private openManageBusinesscards() {
        window.location.href = window.location.origin + '/dashboard/collegas'
    }

    private openManageSubscriptions() {
        window.location.href = window.location.origin + '/dashboard/instellingen/abonnement'
    }

    private openInsights() {
        window.location.href = window.location.origin + '/dashboard/instellingen/inzichten'
    }

    private openSettings() {
        window.location.href = window.location.origin + '/dashboard/instellingen/bedrijf'
    }

    private shouldIconsBeShown() {
        return !(window.location.href.includes("detail") || window.location.href.includes("auth") || window.location.href.includes("subscription") || window.location.href.includes("editor"))
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
    logoutUser
})(ToolBar);

import {GET_UPLOADS, UPLOAD_LOADING} from "../actions/types";

const initialState = {
    uploads: [],
    loading: false
};

export default function(state=initialState, action){
    switch(action.type){
        case GET_UPLOADS:
            return{
                ...state,
                uploads: action.payload,
                loading: false
            };
        case UPLOAD_LOADING:
            return{
                ...state,
                loading: true
            };
        default:
            return state;
    }
}
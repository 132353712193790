import React from "react";
import './container.styles.css'

export interface cardRowProps {
    children?: Array<any>,
}

class CardRow extends React.Component<cardRowProps, {}> {
    render() {

        return <div className={"container-cards-row"}>
            {this.props.children}
        </div>;
    }

}

export default CardRow;
export const templates = [
    {
        "button":
            [],
        "text":
            [
                {
                    "width": 555,
                    "height": 31,
                    "type": "fullname",
                    "zindex": "26",
                    "fontSize": 32,
                    "left": 40,
                    "top": 375,
                    "text": "Matthijs de haan",
                    "bold": true,
                    "color": "#525252ff"
                },
                {
                    "width": 555,
                    "height": 45,
                    "type": "jobtitle",
                    "zindex": "27",
                    "fontSize": 20,
                    "color": "#000000FF",
                    "left": 40,
                    "top": 415,
                    "text": "Ontwerper"
                },
                {
                    "width": 475,
                    "height": 20,
                    "type": "mobile",
                    "color": "#000000FF",
                    "zindex": "29",
                    "fontSize": 20,
                    "left": 85,
                    "top": 492,
                    "text": "+00 0 12 34 56 78",
                    "bold": true
                },
                {
                    "width": 475,
                    "height": 20,
                    "type": "tel",
                    "color": "#000000FF",
                    "zindex": "31",
                    "fontSize": 20,
                    "left": 85,
                    "top": 549,
                    "text": "+31 (0)88 123 45 67",
                    "bold": true
                },
                {
                    "width": 475,
                    "height": 20,
                    "type": "email",
                    "zindex": "33",
                    "color": "#000000FF",
                    "fontSize": 20,
                    "left": 85,
                    "top": 609,
                    "text": "matthijs+1@bizzerd.com",
                    "bold": true
                },
                {
                    "width": 560,
                    "height": 31,
                    "type": "companyname",
                    "zindex": "34",
                    "fontSize": 20,
                    "left": 40,
                    "color": "#000000FF",
                    "top": 664,
                    "text": "Matthijs Designs",
                    "bold": true
                },
                {
                    "width": 475,
                    "height": 60,
                    "type": "adresveld",
                    "zindex": "36",
                    "fontSize": 20,
                    "left": 85,
                    "color": "#000000FF",
                    "top": 717,
                    "text": "Rembrandtlaan 24, \n3723 BJ Bilthoven\nThe Netherlands",
                    "bold": true
                },
                {
                    "width": 475,
                    "height": 20,
                    "type": "website",
                    "zindex": "38",
                    "fontSize": 20,
                    "color": "#000000FF",
                    "left": 85,
                    "top": 815,
                    "text": "www.bizzerd.com",
                    "bold": true
                }
            ],
        "background":
            [
                {

                    "width": 640,
                    "height": 1000
                }
            ],
        "icon":
            [
                {
                    "width": 64,
                    "height": 64,
                    "type": "linkedin",
                    "backgroundColor": "#525252ff",
                    "zindex": "25",
                    "left": 40,
                    "top": 896
                },
                {
                    "width": 64,
                    "height": 64,
                    "type": "facebook",
                    "backgroundColor": "#525252ff",
                    "zindex": "25",
                    "left": 120,
                    "top": 896
                },
                {
                    "width": 64,
                    "height": 64,
                    "type": "instagram",
                    "backgroundColor": "#525252ff",
                    "zindex": "25",
                    "left": 200,
                    "top": 896
                },
                {
                    "width": 64,
                    "height": 64,
                    "type": "website",
                    "backgroundColor": "#525252ff",
                    "zindex": "25",
                    "left": 280,
                    "top": 896
                },
                {
                    "width": 64,
                    "height": 64,
                    "type": "delen",
                    "backgroundColor": "#7b7b7bff",
                    "zindex": "25",
                    "left": 360,
                    "top": 896
                },
                {
                    "width": 64,
                    "height": 64,
                    "type": "opslaan",
                    "backgroundColor": "#7b7b7bff",
                    "zindex": "25",
                    "left": 440,
                    "top": 896
                }
            ],
        "shape":
            [
                {
                    "ronding": 0,
                    "objectFit": "contain",
                    "width": 0,
                    "left": 0,
                    "height": 0,
                    "top": 0,
                    "zindex": "1",
                    "type": "photo",
                    "backgroundColor": "#000000"
                },
                {
                    "ronding": 0,
                    "objectFit": "contain",
                    "width": 640,
                    "height": 340,
                    "type": "rectangle",
                    "zindex": "2",
                    "topBackgroundColor": true,
                    "backgroundColor": "#cecececc",
                    "left": 0,
                    "top": 0
                },
                {
                    "ronding": 0,
                    "objectFit": "contain",
                    "width": 560,
                    "height": 51,
                    "type": "rectangle",
                    "zindex": "28",
                    "backgroundColor": "#f5f5f5",
                    "left": 40,
                    "top": 475
                },
                {
                    "ronding": 0,
                    "objectFit": "contain",
                    "width": 560,
                    "height": 51,
                    "type": "rectangle",
                    "zindex": "30",
                    "backgroundColor": "#f5f5f5",
                    "left": 40,
                    "top": 535
                },
                {
                    "ronding": 0,
                    "objectFit": "contain",
                    "width": 560,
                    "height": 51,
                    "type": "rectangle",
                    "zindex": "32",
                    "backgroundColor": "#f5f5f5",
                    "left": 40,
                    "top": 595
                },
                {
                    "ronding": 0,
                    "objectFit": "contain",
                    "width": 560,
                    "height": 91,
                    "type": "rectangle",
                    "zindex": "35",
                    "backgroundColor": "#f5f5f5",
                    "left": 40,
                    "top": 703
                },
                {
                    "ronding": 0,
                    "objectFit": "contain",
                    "width": 560,
                    "height": 51,
                    "type": "rectangle",
                    "zindex": "37",
                    "backgroundColor": "#f5f5f5",
                    "left": 40,
                    "top": 803
                }
            ],
        "iconset":
            "iconSet7",
        "__v":
            0,
        "image":
            [
                {
                    "objectFit": "cover",
                    "image": "http://res.cloudinary.com/bizzerd-b-v/image/upload/v1712260102/ug5u55k2jx6xmnmoyxke.png",
                    "zindex": "1",
                    "backgroundTop": true,
                    "left": 0,
                    "top": 0,
                    "width": 640,
                    "height": 351
                },
                {
                    "width": 20,
                    "height": 20,
                    "link": "mobile",
                    "zindex": "80",
                    "left": 55,
                    "top": 492,
                    "image": "http://bizzerd.com/templateIcons/pre_mobile.svg",
                    "objectFit": "cover",

                },
                {
                    "width": 20,
                    "height": 20,
                    "link": "tel",
                    "zindex": "80",
                    "left": 55,
                    "top": 549,
                    "image": "http://bizzerd.com/templateIcons/pre_telephone.svg",
                    "objectFit": "cover",

                },
                {
                    "width": 20,
                    "height": 20,
                    "link": "email",
                    "zindex": "80",
                    "left": 55,
                    "top": 609,
                    "image": "http://bizzerd.com/templateIcons/pre_email.svg",
                    "objectFit": "cover",

                },
                {
                    "width": 20,
                    "height": 20,
                    "link": "googlemaps",
                    "zindex": "80",
                    "left": 55,
                    "top": 717,
                    "image": "http://bizzerd.com/templateIcons/pre_googlemaps.svg",
                    "objectFit": "cover",

                },
                {
                    "width": 20,
                    "height": 20,
                    "link": "website",
                    "zindex": "80",
                    "left": 55,
                    "top": 815,
                    "image": "http://bizzerd.com/templateIcons/pre_website.svg",
                    "objectFit": "cover",

                },

                {
                    "objectFit": "fill",
                    "image": "http://res.cloudinary.com/bizzerd-b-v/image/upload/v1712263818/slkownalhm5asmssmw8h.png",
                    "zindex": "3",
                    "left": 0,
                    "top": 320,
                    "width": 640,
                    "height": 680
                },
                {
                    "objectFit": "fill",
                    "image": "http://res.cloudinary.com/bizzerd-b-v/image/upload/v1712264713/qwhefq7zmxfzckfa5h89.png",
                    "logo": true,
                    "zindex": "39",
                    "left": 256,
                    "top": 119,
                    "width": 127,
                    "height": 95
                }
            ]
    },
]
import React from "react";
import './header.styles.css'
import ButtonColors from "../buttons/button.colors";
// @ts-ignore
import {default as shoppingCart} from "../../../assets/icons/shoppingcart.icon.svg";
// @ts-ignore
import {default as bizzerdLogo} from '../../../assets/img/bizzerd_logo.png';
// @ts-ignore
import {default as landing} from '../../../assets/product/landing.product.png';
// @ts-ignore
import {default as graphicSmall} from '../../../assets/img/holderGraphicSmall.png';

import {default as nfc} from '../../../assets/product/nfc.product.png';


// @ts-ignore
import {default as qrcode} from '../../../assets/icons/qrcode.icon.svg';
import PageTitle from "../fields/text/pagetitle.text";
import MainButton from "../buttons/action_main/main.button";
// @ts-ignore
import {default as blueb} from '../../../assets/img/bluebchip.png';
import {translate} from "react-i18nify";

interface homeHeaderProps {
    width: number
}

class NFCHeader extends React.Component<homeHeaderProps, {}> {
    render() {
        return (<div className={"enterprise-header-container"} style={{paddingBottom: 0}}>
            <div className={"product-header-left"} style={{justifyContent: "flex-start"}}>
                <PageTitle text={translate("hd.nfc.pageTitle")}
                           style={{
                               fontSize: "36px",
                               marginBottom: "15px"
                           }}/>
                <span className={"enterprise-header-usp-item"}>{translate("hd.nfc.pageContent1")}</span>
                <br/>
                <span className={"enterprise-header-usp-item"}>{translate("hd.nfc.pageContent2")}</span>

                <div className={"enterprise-header-buttons"}
                     style={this.props.width > 768 ? {} : {width: "100%", alignItems: "center"}}>
                    <MainButton color={ButtonColors.orange} href={translate("hd.links.contact")}
                                style={this.props.width > 768 ? {marginRight: "10px", marginTop: "20px"} : {}}
                                text={translate("hd.actions.contactSales")}/>

                </div>
            </div>
            <div className={"product-header-right"}>
                <img style={{width: "80%", minWidth: "300px"}} src={nfc}/>
            </div>

        </div>)

    }

}

export default NFCHeader;
import React from "react";
import './blocks.styles.css'
// @ts-ignore
import {default as Orange} from "../../../assets/containers/orange.container.png"
// @ts-ignore
import {default as Blue} from "../../../assets/containers/blue.container.svg"

// @ts-ignore
import {default as Android} from "../../../assets/home/playstore.svg"
// @ts-ignore
import {default as Apple} from "../../../assets/home/applestore.svg"
// @ts-ignore
import {default as CardsBackground} from "../../../assets/containers/small.container.png"
import GradientContainer from "../containers/gradient.container";
import {translate} from "react-i18nify";
import SubTitle from "../fields/text/subtitle.text";

export interface mediumRectangleProps {

}

class ReviewBlock extends React.Component<{ style?: any }, {}> {

    render() {

        return (<GradientContainer style={{marginTop: 0,...this.props.style}}>
            <div className={"container-rectangle-white"} style={{textAlign: "center"}}>

                <SubTitle text={translate("hd.reviewBlock.pageTitle")} style={{paddingBottom: "15px"}}/>
                <span className={"review-block-content"}>{translate("hd.reviewBlock.pageContent")}</span>
                <div style={{marginTop:30}}>
                    <a href={"https://itunes.apple.com/us/app/bizzerd/id1396772300"} target={"_blank"}>
                        <img src={Apple} className={"apple-button"}/>
                    </a>
                    <a href={"https://play.google.com/store/apps/details?id=com.bizzerd"} target={"_blank"}>
                        <img src={Android} className={"android-button"}/>
                    </a>
                </div>

            </div>
        </GradientContainer>);
    }

}

export default ReviewBlock;
import React from 'react'
import GrayContainer from "../common2/containers/gray.container";
import NavigationHeader from "../common2/headers/navigation.header";
import PageTitle from "../common2/fields/text/pagetitle.text";
import GradientContainer from "../common2/containers/gradient.container";
import EditorFlow from "../common2/flow/editor.flow";
import Footer from "../common2/footers/footer";
import {translate} from "react-i18nify";

interface pageProps {
    width: number,
    match: any,
}

class EditorPage extends React.Component<pageProps, {}> {
    render() {
        return (<>
            <GrayContainer style={{paddingBottom: "40px"}}>
                <NavigationHeader width={this.props.width}/>
                <PageTitle text={translate("hd.editor.pageTitle")} style={{paddingTop: "100px"}}/>
            </GrayContainer>
            <GradientContainer>
                <EditorFlow width={this.props.width} templateId={this.props.match.params.templateId}/>
            </GradientContainer>
            <Footer/></>);
    }
}

export default EditorPage
import React from "react";
import './container.styles.css'
// @ts-ignore
import {default as Background} from "../../../assets/containers/orange.container.png"

export interface containerProps {
    children?: Array<any>,
}

class OrangeContainer extends React.Component<containerProps, {}> {
    render() {

        return <div className={"container-orange"}
                    /*style={{backgroundImage: `url(${Background})`}}*/>
            <div className={"content-wrapper"}>
                <div className={"container-orange"}>
                {this.props.children}
                </div>
            </div>
        </div>;
    }

}

export default OrangeContainer;
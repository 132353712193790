import {GET_ERRORS, GET_PAYMENT, GET_PAYMENT_RESULT, GET_PAYMENT_STATUS, GET_PAYMENTS} from "./types";
import axios from "axios/index";

export const initiatePaymentSession = (email, description, returnUrl, webhook) => dispatch => {
    const post = {
        email: email,
        description: description,
        redirectUrl: returnUrl,
        webhookUrl: webhook
    };
    axios.post(`/api/payment/initiate`, post)
        .then(res => {
            dispatch({
                type: GET_PAYMENT_STATUS,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        })
}

export const initiateSinglePayment = (email, description, returnUrl, webhook, price, subscriptionPurchases, code = null) => dispatch => {
    const post = {
        email: email,
        description: description,
        redirectUrl: returnUrl,
        webhookUrl: webhook,
        total_price: price,
        packageCode: code,
        subscriptionPurchases: subscriptionPurchases
    };
    // console.log("Started initiateSinglePayment")
    axios.post(`/api/payment/initiateSinglePayment`, post)
        .then(res => {
            dispatch({
                type: GET_PAYMENT_STATUS,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        })
}

export const initiatePayment = (user, company, description, returnUrl, webhook, total_price, duration, code = null) => dispatch => {
    const post = {
        user: user,
        company: company,
        description: description,
        redirectUrl: returnUrl,
        webhookUrl: webhook,
        total_price: total_price,
        duration: duration,
        packageCode: code,
    };
    // console.log("Started finishPayment")
    axios.post(`/api/payment/finishPayment`, post)
        .then(res => {
            dispatch({
                type: GET_PAYMENT_STATUS,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        })
}

export const updateSlackOnPayment = (companyName, price, domain, bizzerdName, nfc) => dispatch => {
    let nfcRequest = "NOT put in a request for NFC"
    if (nfc === "true") {
        nfcRequest = "put in a request for NFC @Martin @fmijsters contact them!"
    }
    const payload = {
        text: "The company: \"" + companyName + "\" has started a payment for: €" + price + " link: https://bizzerd.com/cards/" + domain + "/" + bizzerdName + " The company has " + nfcRequest,
        channel: "#webshop-v3",
        username: "New webshop helper",
        icon_url: "https://www.bizzerd.com/wp-content/uploads/2018/01/bizzerd-b.png"
    };
    axios.post("/api/payment/updateSlackOnPayment", payload)
        .then(res => {
            // console.log(res)
        })
        .catch(err => {
            // console.log(err)
        })
}


export const checkPaymentSession = (paymentId) => dispatch => {
    const post = {
        id: paymentId
    };
    axios.post(`/api/payment/result`, post)
        .then(res => {
            dispatch({
                type: GET_PAYMENT_RESULT,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        })
}

export const getPaymentData = (id) => dispatch => {
    axios.get(`/api/payment/invoice/${id}`)
        .then(res => {
            dispatch({
                type: GET_PAYMENT,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data ? err.response.data : "Er is iets misgegaan"
            })
        })
};

export const getPayments = () => dispatch => {
    axios.get('/api/payment/payments')
        .then(res => {
            dispatch({
                type: GET_PAYMENTS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        })
}
import React, {ReactElement} from 'react';
import '../Icon.scss';
import IconProps from "../IconProps.interface";

const ShoppingCart = (props: IconProps) : ReactElement => {

    return (
        <div className="icon">
            <svg version="1.2" fill={props.color} className={props.additionalClass} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" >{/*width="60" height="60"*/}
                <path id="Layer" className="s0"
                      d="m51 9.9q-0.1 0.5-0.3 1.1-0.3 0.4-0.8 0.7-0.4 0.2-0.9 0.3l-3.3 0.4q-0.2 0-0.4 0.1-0.2 0.1-0.3 0.2-0.2 0.3-0.3 0.5-0.1 0.1-0.1 0.3c-1.3 4.7-1.5 25.1-3.2 25.1h-26.4q-0.7 0-1.4-0.2-0.6-0.3-1-0.7-0.5-0.6-0.8-1.2-0.3-0.6-0.3-1.3l-2.5-15.2q0-0.6 0.2-1.2 0.3-0.7 0.7-1.2 0.6-0.4 1.2-0.7 0.6-0.2 1.3-0.2h25.9q0.1 0 0.2 0 0.2 0 0.4 0 0.1 0 0.2 0.1 0.1 0 0.2 0l0.7-5.2q0-0.6 0.2-1.3 0.4-0.6 0.8-1.1 0.5-0.5 1.1-0.7 0.7-0.3 1.3-0.3l5.8-0.2q0.3 0 0.5 0 0.2 0.1 0.5 0.2 0.2 0.1 0.4 0.3 0.1 0.1 0.2 0.4 0.2 0.5 0.2 1z"/>
                <path id="Layer" className="s0"
                      d="m19.8 43.9q0.7 0.3 1.2 0.8 0.6 0.6 0.9 1.3 0.3 0.7 0.3 1.6c0 1.6-1 3-2.4 3.7-1.5 0.6-3.3 0.3-4.5-0.9-1.1-1.1-1.4-2.8-0.8-4.4 0.6-1.5 2.1-2.4 3.7-2.4q0.8 0 1.6 0.3z"/>
                <path id="Layer" className="s0"
                      d="m37.9 43.9q0.8 0.3 1.4 0.8 0.5 0.6 0.8 1.3 0.3 0.7 0.3 1.6c0 1.6-0.9 3-2.5 3.7-1.5 0.6-3.2 0.3-4.3-0.9-1.2-1.1-1.5-2.8-0.9-4.4 0.7-1.5 2-2.4 3.7-2.4q0.8 0 1.5 0.3z"/>
            </svg>
        </div>
    )
};

export default ShoppingCart;

import {ILanguage} from "./Istrings.interface";

export const dutch: ILanguage = {

    dashboard: {
        sidebar: {
            overview: "Gebruikers overzicht",
            customize: "Ontwerp wijzigen",
            add: "Collega's toevoegen",
            settings: "Instellingen"
        },
        daysAgo:"dagen terug",
        addAColleague: "Je kan collega's handmatig en automatisch toevoegen. Zo kun je zelf de gegevens van je collega invoeren of je collega uitnodigen om het zelf te doen",
        addAColleague2: "Wil je een koppeling maken tussen AFAS of Azure AD neem dan contact op via ",
        viewColleagues: "Pas de informatie van collega's aan via ons handige gebruikers overzicht",
        viewColleaguesButton: "Gebruikers overzicht",
        insights: "Inzichten",
        inviteEmail: "Collega's uitnodigen via email",
        addManually: "Handmatig toevoegen",
        open: "Openen",
        edit: "Aanpassen",
        invite: "Uitnodigen",
        users:"Gebruikers",
        inviteColleagues: "Collega's uitnodigen",
        inviteColleagues1: "Je kan ook collega's uitnodigen via e-mail of via een registratie link.",
        inviteColleagues2: "Via de link in de e-mail kunnen de gebruikers na het aanmaken van een wachtwoord, zelf hun gegevens invullen.",
        addManually1: "Maak handmatig een visitekaartje aan voor een nieuwe collega.",
        addManually2: "De gebruiker ontvangt een email op het ingevoerde email adres met instructies voor het instellen van een gebruikersaccount.",
        bizzerdNfc: "Hier kun je de Bizzerd-tags linken aan een collega waardoor het digitale visitekaartje van deze collega tevoorschijn komt na het scannen van de Bizzerd-tag.",
        bizzerdNfc1: "NFC aanvragen",
        bizzerdNfc2: "Bekijk de instructies (PDF)",
        nfcLink: "nfc.pdf",
        addNfc:"NFC toevoegen",
        selectUser:"Selecteer een gebruiker"

    },
    hd: {
        actions: {
            confirm: "Aanpassen",
            logout: "Uitloggen",
            request: "Aanvragen",
            login: "login",
            requestQuotation: "Offerte aanvragen",
            clickHere: "Klik dan hier",
            save: "Opslaan",
            cancel: "Annuleren",
            uploadLogo: "Logo uploaden",
            contactSales: "Contact met sales",
            tryForFree: "Maak een kaartje",
            toLogin: "Terug naar inloggen",
            successChanged: "Succesvol veranderd. Klik op onderstaande knop om terug te gaan",
            successRequest: "Succesvol aangevraagd. Controlleer je email en vergeet niet ook je spam te checken!",
            uploadProfile: "Profielfoto uploaden",
            send: "Verzenden",
            requestDraft: "Offerte aanvragen"
        },
        designPicker: {
            chooseAndPersonalize: "Kies een kaart en personaliseer...",
            next: "Volgende stap",
            free: "Maak jouw gratis digitale visitekaartje",
            back: "Terug",
            loadAll: "Alles laden",
            start: "Selecteer één van de designs en start met creëren",
            select: "Selecteer",
        },
        links: {
            home: "/",
            product: "/product",
            contact: "/contact",
            detail: "/detail",
            about: "/about",
            nfc: "/nfc",
            blogs: "/blogs",
            designEntry: "/start",
            login: "/login",
            businessCards: "/start",
            shoppingcart: "/shoppingcart",
            enterprise: "/enterprise",
            pricing: "/pricing",
            upload: "/upload",
        },
        nfc: {
            pageTitle: "Digitaal visitekaartje met NFC-tag!",
            pageContent1: "Bij Bizzerd benut je alle voordelen van NFC. Je kunt kiezen voor een NFC-tag. Dat is een sticker met de NFC-chip die je bijvoorbeeld op je telefoon kunt plakken. Of kies voor een fysiek visitekaartje met NFC, verkrijgbaar in duurzaam bamboe of hout.",
            pageContent2: "Met de NFC-tag of de NFC-card deel je jouw digitale visitekaartje van Bizzerd in 1 seconde! Zo veel en zo vaak als je wil.",
            buttonText: "Nu aanvragen",
            blockTitle: "Wat is NFC?",
            blockContent1: "NFC staat voor Near Field Communication. Het is een technologie voor automatische draadloze gegevensoverdracht zonder internetverbinding. Contactloos betalen werkt hiermee. Of je OV-chipkaart. Deze overdracht gebeurt alleen dichtbij, bijvoorbeeld als je een NFC-chip tegen een telefoon houdt. Het is de snelste en makkelijkste manier om jouw digitale visitekaartje met iemand te delen. Handig, bijvoorbeeld tijdens een netwerkevenement of zakelijke bijeenkomst.",
            blockContent2: "Bizzerd is het meest gebruikte digitale visitekaartje, met de meeste functionaliteiten. Bizzerd is de beste manier om jezelf en je organisatie te representeren. Dat gaat nu nog makkelijker en sneller met de NFC-tag of NFC-card.",
            blockContent3: "Trouwens, je kunt jouw Bizzerd-tag of fysieke visitekaartje zo vaak hergebruiken als je wil. Bijvoorbeeld voor een collega. Je ontkoppelt dan jouw digitale visitekaartje en koppelt het digitale visitekaartje van jouw collega aan de tag of het kaartje.",
            blockTitle2: "Herbruikbaar dus duurzaam",
            saleTitle: "NFC-tag!",
            saleContent: "Kies nu ook voor Bizzerd met NFC-tag en profiteer van de tijdelijke actie. Stap je voor 1 september 2023 over op digitale visitekaartjes van Bizzerd, dan betaal je helemaal niks voor je NFC-tag!"
        },
        calculator: {
            domain: {
                title: "Eigen domein",
                subTitle: "Kies voor een persoonlijke url, zoals bedrijfsnaamcard.com",
                buttonText: "Eigen domein toevoegen",
                buttonTextActive: "Eigen domein verwijderen"
            }, sso: {
                title: "Single Sign-On",
                subTitle: "Makkelijk inloggen met je bedrijfaccount",
                buttonText: "SSO Toevoegen",
                buttonTextActive: "SSO Verwijderen"
            }, crm: {
                title: "CRM",
                subTitle: "Koppel AFAS of Azure en houdt alle kaartje automatisch up-to-date",
                buttonText: "CRM koppelen",
                buttonTextActive: "CRM ontkoppelen"
            }, nfc: {
                title: "NFC visitekaartje",
                subTitle: "Kies voor NFC en maak het delen nog gemakkelijker",
                buttonText: "NFC Toevoegen",
                buttonTextActive: "NFC Verwijderen"
            }
        },
        about: {
            title: "Het verhaal achter het meest gebruikte<br/> digitale visitekaartje",
            subTitle: "Het bedrijfsleven gooit jaarlijks miljoenen papieren visitekaartjes weg. Meestal ook nog ongebruikt. Dit is weggegooid geld en het zorgt voor onnodige belasting van milieu en klimaat. Dat kan anders, dat kan beter, vinden we bij Bizzerd.",
            mainButton: "Bekijk hoe Bizzerd werkt",
            secondaryButton: "Maatwerk",
            locationTitle: "We zijn gevestigd in Nederland, Bizzerd wordt wereldwijd gebruikt",
            locationText: "We zijn in 2015 gestart als eerste digitale visitekaartje in Nederland. Inmiddels wordt Bizzerd wereldwijd door honderdduizenden gebruikt en is het voor organisaties en ondernemingen het meest gekozen digitale visitekaartje.",
            bizzerdWorkTitle: "We zijn maatschappelijk betrokken, meer kansen voor jongeren",
            bizzerdWorkText: "Bij Bizzerd voelen we ons betrokken bij de maatschappij. Daarom zetten we ons met de stichting Bizzerd.work in voor het vergroten van kansen voor alle pro-, vso-, vmbo- en mbo-jongeren. Hiervoor hebben we een app ontwikkeld: Bizzerd.work en een bijbehorend lesprogramma. De app werkt als digitaal cv. Voor jongeren is dat een handig hulpmiddel bij het solliciteren naar een (bij)baan of stage. Voor het basisonderwijs hebben we een digitaal portfolio ontwikkeld, waarin leerlingen hun (leer)ervaringen bijhouden.",
            bizzerdWorkLink: "Bekijk Bizzerd.work",
            partnersTitle: "Partners",
            blogTitle: "Blogposts",
            blogLink: "Meer lezen",
            referralText: "Ben je enthousiast over ons digitale visitekaartje en zou je andere organisaties ook Bizzerd aanraden? Zeker doen! Zorg jij met jouw tip voor een nieuwe klant, dan krijg je van ons een leuke beloning. Interesse?",
            referralEmailText: "Email ons",
            referralText2: "en we sturen je ons referral-programma.",
            referralTitle: "Bizzerd Referral-programma",
        },

        blogBlock: {
            pageTitle: "Onze blogs",
            subTitle: "Ontdek de kracht van volledig online digitale visitekaartjes: Overzicht van onze blogartikelen",
            language: "nl",
            readMore: "Verder lezen",
            backToOverview: "Terug naar overzicht",
            blog1: {
                title: "Wat is een NFC visitekaartje en wat kun je er (niet) mee?",
                description: "Bijna niemand gebruikt tegenwoordig nog een papieren visitekaartje. Interactieve visitekaartjes zijn de standaard. Er zijn heel veel verschillende vormen van digitale visitekaartjes beschikbaar. Waar je het meest over hoort, zijn NFC-visitekaartjes. NFC betekent Near Field Communication. Een NFC-visitekaartje is een fysiek kaartje met een kleine microchip erin verwerkt. Deze chip kunnen de meeste smartphones scannen en linken naar je digitale visitekaartje. Zo deel je jouw contactgegevens. Deze gegevens kunnen ook direct worden opgeslagen in het adresboek van de telefoon.",
                link: "nfc-business-card-blog"
            },
            blog2: {
                title: "De zin en onzin van digitale visitekaartjes?",
                description: "Wie gebruikt er nog een papieren visitekaartje? Er is toch LinkedIn? Als je iemand jouw gegevens wilt geven, maak je gewoon contact via LinkedIn, toch? Of niet? Hier zetten we de voordelen en nadelen van papieren kaartjes, LinkedIn én digitale visitekaartjes op een rij",
                link: "pros-cons-digital-business-card-blog",
            },
            blog3: {
                title: "Zo borg je de privacy en databeveiliging van digitale visitekaartjes",
                description: "Steeds meer organisaties kiezen voor interactieve, digitale visitekaartjes. Dat vraagt om extra aandacht voor privacy en databeveiliging van de opgeslagen gegevens. Het gaat immers om persoonsgegevens. Je wil zelf bepalen met wie je jouw gegevens deelt. Je visitekaartjes mogen dan niet zomaar op straat komen te liggen.",
                link: "privacy-blog"
            },
            blog4: {
                title: "Zo krijg je extra betrokkenheid van (potentiële) klanten met jouw digitale  visitekaartje",
                description: "Met een digitaal visitekaartje zorg je ervoor dat ontvangers meer doen dan alleen passief informatie ontvangen. Je kunt ze actief betrekken bij verdere interactie en zorgen voor activatie, zoals contact opnemen, een gesprek voortzetten, meer informatie of een offerte aanvragen, deelnemen aan acties of feedback geven. Hoe? Een paar tips.\n",
                link: "engagement-blog"
            },
            blog5: {
                title: "Zo laat je met je visitekaartjes zien dat je duurzaamheid serieus neemt",
                description: "Jouw organisatie neemt duurzaamheid serieus en zorgt voor zoveel mogelijk maatregelen om het milieu te verbeteren en klimaatverandering te beperken. Wat kunnen digitale visitekaartjes daaraan bijdragen?",
                link: "climate-blog"
            },
            blog6: {
                title: "Zo versterk je jouw merk met een digitaal visitekaartje",
                description: "Leidende, betekenisvolle merken staan of vallen met een consistente doorvertaling van de merkidentiteit. Maar dat niet alleen, een merk moet ook inspireren en tot de verbeelding spreken. Zodoende investeren merken veel in hun brand identity en in merkcommunicatie. Dat loopt uiteen van aansprekende commercials tot dagelijkse huisstijluitingen als signing, bedrijfskleding en stationary.",
                link: "corporate-identity-blog"
            },
            blog7: {
                title: "Zo koppel je automatisch visitekaartjes aan je CRM- en HRM-systeem",
                description: "Nieuwe medewerker in dienst? Dan moet je ook visitekaartjes regelen. Gelukkig is dat tegenwoordig een kwestie van een paar keer klikken. Dankzij het koppelen van digitale visitekaartjes aan CRM- en HRM-systemen. Hoe dat werkt, lees je hier.",
                link: "crm-hrm-blog"
            },
            blog8: {
                title: "Zo maak je zelf een NFC-visitekaartje",
                description: "Je hebt er vast wel eens van gehoord of mee te maken gehad: een NFC-visitekaartje. Een NFC-visitekaartje is een digitaal visitekaartje dat gebruikmaakt van NFC-technologie (Near Field Communication). NFC is een draadloze communicatietechnologie die het mogelijk maakt om gegevens uit te wisselen tussen twee apparaten die zich binnen een korte afstand van elkaar bevinden, meestal binnen enkele centimeters.",
                link: "create-nfc-blog"
            }
        },
        multiEdit: {
            title: "Collega's aanpassen",
            subTitle: "Selecteer een veld en vul de nieuwe waarde in, in het veld om van alle geslecteerde collega's tegelijkertijd het veld te updaten",
            selectedNr: "Personen geselecteerd",
            submit: "Doorvoeren",
            selectDesign: "Ontwerp selecteren",
            newValue: "Nieuwe waarde",
            value: "Waarde...",
            selected: "Geselecteerd",
            header: "Collega's",
        },
        companySettings: {
            welcomeMail: "Welkomstmail",
            description: "Deze mail wordt naar je collega's gestuurd wanneer je ze uitnodigd. Druk op de onderstaande knop om de standaard mail te bekijken of de mail aan te passen",
            view: "Bekijken",
            save: "Opslaan",
            cancel: "Annuleren",
            edit: "Wijzigen",
            title: "Hier kun je de mail aanpassen die gestuurd wordt naar je collega's wanneer op uitnodigen drukt. Je kan de volgende speciale waardes gebruiken om de email te personaliseren per collega.",
            list1: "~voornaam~ om de voornaam van je collega te gebruiken",
            list2: "~achternaam~ om de achternaam van je collega te gebruiken",
            list3: "~volledigenaam~ om de volledige naam van je collega te gebruiken",
            list4: "~kaartje~ om de link naar het kaartje van je collega te gebruiken",
            list5: "~account~ om de uitnodigings link van je collega te gebruiken",
            required: "*verplicht",
            nfcTitle: "Bizzerd-tag's instellen",
            nfcDescription: "Hier kun je de Bizzerd-tags linken aan een collega waardoor het digitale visitekaartje van deze collega tevoorschijn komt na het scannen van de Bizzerd-tag"
        },
        notFound: {
            title: "Sorry, we kunnen deze pagina niet meer vinden",
            content: "We hebben ons best gedaan, maar het lijkt erop dat deze pagina niet (meer) bestaat of misschien verhuisd is. Je kunt natuurlijk altijd naar de homepage of contact opnemen.",
            buttonHome: "Naar de homepage",
            buttonContact: "Neem contact op" 
        },
        titles: {
            businessCards: "Visitekaartjes | Bizzerd",
            nfc: "Maak jouw NFC visitekaartje",
            home: "Bizzerd, het meest gebruikte digitale visitekaartje",
            about: "Bizzerd, ons verhaal",
            pricing: "Dit kost jouw digitale visitekaartje",
            product: "Zo werkt Bizzerd, het digitale visitekaartje",
            contact: "Contact | Bizzerd",
            shoppingCart: "Winkelmandje | Bizzerd",
            enterprise: "Laat Bizzerd jullie digitale visitekaartje op maat maken",
            upload: "Upload | Bizzerd",
            blogs: "Bizzerd, Blogs over digitale (NFC-)visitekaartjes",
        },
        errorMessages: {
            invalidEmail: "Vul een geldig emailadress in",
            companyNameExists: "Bedrijfsnaam bestaat al",
            noName: "Vul je naam in",
            noCompanyName: "Vul je bedrijfsnaam in",
            invalidNumber: "Vul een geldig mobiel nummer in"
        },
        dashboard: {
            title: "Mijn visitekaartjes",
            subTitle: "Welkom in het Bizzerd Dashboard. Hier kun je jouw visitekaartje(s) en die van collega’s bekijken en aanpassen. Je kunt hier collega’s en anderen uitnodigen om ook Bizzerd te gebruiken en verder alles regelen.",
        },
        iconsBlock: {
            pageTitle: "Wat is Bizzerd?",
            iconContainerContent: "Bizzerd is het enige écht interactieve visitekaartje. Bij Bizzerd is jouw digitale visitekaartje eigenlijk een mini-website. Daardoor kun je altijd en overal aan iemand jouw visitekaartje geven. Simpel: je deelt alleen de link, bijvoorbeeld via e-mail, WhatsApp, sms of de qr-code.",
            interactive: "Bij Bizzerd is jouw visitekaartje een mini-website. Daardoor kun je altijd jouw digitale visitekaartje aan iemand geven, ook zonder de app. ",
            impact: "Geef iemand jouw Bizzerd en maak de beste eerste indruk.",
            uptodate: "Check hoeveel views jouw visitekaartje krijgt, hoe vaak het wordt gedeeld en waarop wordt geklikt. Zo leer je je netwerk beter kennen en benut je meer kansen.",
            climate: "Kies voor duurzaam. Met Bizzerd weet je dat je bijdraagt aan een schonere planeet. Voor het 100% digitale visitekaartje is vrijwel geen papier, drukwerk of transport nodig",
            fingerprint: "Bij Bizzerd heeft jouw visitekaartje het ontwerp dat jij wilt, passend bij jouw identiteit.",
            interactiveLabel: "Interactief",
            impactLabel: "Impact",
            nfcLabel: "Bizzerd-tag",
            uptodateLabel: "Insights",
            climateLabel: "Duurzaam",
            fingerprintLabel: "Jouw ontwerp",
            howDoesItWork: "Hoe het werkt"
        },
        navigationbar: {
            home: "Home",
            businessCards: "Visitekaartjes",
            product: "Product",
            pricing: "Prijzen",
            about: "Over ons",
            nfc: "NFC-visitekaartje",
            enterprise: "Maatwerk",
            contact: "Contact",
            myOverview: "Mijn overzicht",
            myInsights: "Mijn inzichten",
            mySubscriptions: "Mijn abonnementen",
            myCompany: "Mijn bedrijf",
        },
        businessCardPage: {
            pageTitle: "Digitale visitekaartjes",
            subTitle: "Blader door de designs, kies jouw favoriet en start met het creëren van je eigen visitekaartje!",
            enterpriseSelling: "Laat je liever jullie digitale visitekaartjes maken door Bizzerd, in jullie eigen huisstijl?"

        },
        headerFilter: {
            sortOn: "Sorteren op",
            mostChosen: "Meest gekozen",
            newest: "Nieuwste",
            images: "Afbeelding",
            allProfile: "Alle profielfotos",
            largeProfile: "Grote profielfoto",
            smallProfile: "Kleine profielfoto",
            noProfile: "Geen profielfoto",
            adjustCard: "Kaartjes aanpassen",
            warning: "Het kan zijn dat de standaard grote van je logo niet de beste is... geen zorgen deze kan je in de volgende stappen aanpassen en helemaal goed neerzetten!"
        },
        contact: {
            pageTitle: "Ontvang jouw gratis voorstel!",
            subTitle: "Ons supportteam zit klaar om jouw vraag of bericht te beantwoorden. Vul het formulier in en je krijgt snel een reactie van ons. Je kunt het ook gebruiken voor offerteaanvragen.",
            name: "Naam",
            thirdTitle: "Laat je adviseren",
            subThirdTitle: "Wil je advies? Neem contact op met Martin. Hij kan je alles vertellen over digitale visitekaartjes en adviseert je graag.",
            call: "Bel",
            bizzerdOffer: "Ontdek alle mogelijkheden van Bizzerd voor jouw organisatie. Vraag nu jouw voorstel aan. Gratis en vrijblijvend. Vul het formulier in en je weet snel meer.",
            companyName: "Bedrijfsnaam",
            weAreReachable: "Wij zijn bereikbaar op ",
            email: "E-mail",
            telephoneNumber: "Telefoonnummer",
            message: "Bericht",
            questions: "Vragen?",
            backToHome: "Terug naar Home",
            thanksForTheMessage: "Bedankt voor je bericht!",
            noIndication: "Nader te bepalen",
            employeeIndication: "Medewerkers aantal",
            nfcRequest: "Interesse in NFC (vanaf 10 personen)",
            requestType: "Aanvraag type",
            contactSupport: "Contact & ondersteuning",
            quotationRequest: "Offerte aanvraag",


        },
        footer: {
            bizzerdTitle: "Bizzerd",
            pricing: "Prijzen",
            product: "Hoe werkt het",
            businessCards: "Visitekaartjes",
            resourcesTitle: "Resources",
            toc: "Algemene voorwaarden",
            privacyStatement: "Privacy statement",
            appManual: "Handleiding app",
            webManual: "Handleiding web",
            userTips: "Gebruikertips",
            contactTitle: "Contact",
            netherlands: "Nederland",
            extraTitle: "Overig",
            kvk: "Kamer van Koophandel",
            tocPrivacy: "Onze voorwaarden / Privacy",
            blogs: "Blogs"
        },
        detail: {
            pageTitle: "Ontwerp",
            productName: "Digitaal visitekaartje ",
            nfc: "Een aanvraag voor de Bizzerd-tag of NFC kaartje kan je toevoegen bij het winkelmandje",
            pppm: "p.p. p/mnd",
            customize: "Personaliseren",
            priceSimDesign: "Prijsinfo bij hetzelfde ontwerp",
            duration: "Looptijd",
            price: "Prijs",
            pricePm: "Prijs p/mnd",
            discount: "Korting",
            yr1: "1 jaar",
            yr2: "2 jaar",
            yr3: "3 jaar",
            discountSmall: "korting",
            details: "Creëer in 3 stappen jouw digitale visitekaartje",
            designSpecifications: "Design specificaties",
            detailContent: "Ga voor snelheid en gemak. Gebruik dit speciaal gemaakte ontwerp en creëer in 3 stappen jouw digitale visitekaartje. Je vult eerst je gegevens in. Dan kies de buttons die je wil toevoegen naar jouw social media en jouw website(s). Vervolgens kies jouw kleuren en het logo dat je wil gebruiken. Ben je daarmee klaar? Kies dan je abonnement, maak je account en reken af. En dan kan het leukste beginnen: deel jouw interactieve visitekaartje zo vaak je maar wil en met wie je maar wil.",
            designContent: {
                textTitle: "De tekst velden in dit ontwerp zijn volledig aan te passen naar jouw wensen of die van je bedrijf. Zo kunnen er achter alle teksten linkjes worden geplaatst, dit gaat automatisch voor velden zoals de website, telefoonnummers en email. Dit design bevat de volgende tekst velden:",
                text: {
                    "firstname": "voornaam",//"Een veld waar ruimte is voor de voornaam van jou en/of je collega's",
                    "lastname": "achternaam",//"Een veld waar ruimte is voor de achternaam van jou en/of je collega's",
                    "fullname": "volledige naam",//"Een veld waar ruimte is voor de volledige naam van jou en/of je collega's",
                    "email": "e-mail",//"Een veld die gevuld wordt met jouw e-mailadres en/of die van je collega's. Dit veld linkt automatisch door naar je e-mail",
                    "tel": "telefoonnummer",//"Een veld die gevuld wordt met jouw telefoonnummer en/of die van je collega's. Dit veld linkt automatisch door naar je telefoonnummer",
                    "mobile": "mobielnummer",//"Een veld die gevuld wordt met jouw mobielnummer en/of die van je collega's. Dit veld linkt automatisch door naar je mobielnummer",
                    "jobtitle": "functie titel",//"Een veld waar ruimte is voor de functie titel van jou en/of je collega's",
                    "website": "website",//"Een veld die gevuld wordt met jouw website of die van je bedrijf. Dit veld linkt automatisch door naar je website",
                    "adresveld": "adres",//"Een veld waar ruimte is voor het adres van jou en/of je collega's. Dit veld linkt automatisch door naar Google Maps",
                    "companyname": "bedrijfsnaam",//"Een veld waar ruimte is voor de naam van jouw bedrijf"
                },
                iconTitle: "De iconen in dit ontwerp zijn volledig aan te passen naar jouw wensen of die van je bedrijf. Zo linken alle iconen naar de desbetreffende website. Dit design bevat de volgende iconen velden:",
                icon: {
                    "linkedin": "LinkedIn",// "Dit is een icoon die linkt naar jouw LinkedIn pagina of die van je bedrijf",
                    "facebook": "Facebook",//"Dit is een icoon die linkt naar jouw Facebbok pagina of die van je bedrijf",
                    "instagram": "Instagram",// "Dit is een icoon die linkt naar jouw Instagram pagina of die van je bedrijf",
                    "twitter": "Twitter",// "Dit is een icoon die linkt naar jouw Twitter pagina of die van je bedrijf",
                    "pinterest": "Pinterest",//"Dit is een icoon die linkt naar jouw Pinterest pagina of die van je bedrijf",
                    "email": "E-mail",// "Dit is een icoon die linkt naar jouw e-mail of die van je bedrijf",
                    "tel": "Telefoon",// "Dit is een icoon die linkt naar jouw telefoonnummer of die van je bedrijf",
                    "website": "Website",// "Dit is een icoon die linkt naar jouw website of die van je bedrijf",
                    "vimeo": "Vimeo",// "Dit is een icoon die linkt naar jouw Vimeo of die van je bedrijf",
                    "opslaan": "Opslaan (toevoegen aan contacten)",// "Dit is een icoon die na een klik jouw contactgegevens opslaat in de contacten van de persoon die je visitekaartje bekijkt",
                    "delen": "Delen (link naar de persoonlijke deel pagina)",// "Dit is een icoon die linkt naar jouw persoonlijke deel pagina achter je visitekaartje. Zodat je vanaf daar makkelijk en snel kan delen"
                }
            }
        },
        paymentLanding: {
            pageTitle: "Betaling gelukt!",
            pageContent: "Dank je wel! Ga snel naar het Bizzerd Dashboard waar je jouw gegevens kunt beheren, het ontwerp kan aanpassen, en collega’s kunt uitnodigen en toevoegen.",
            buttonText: "Naar dashboard"
        },
        uploadBlock: {
            pageTitle: "Maak jouw eigen ontwerp",
            blockTitle2: "Maak je eigen ontwerp",
            block2Content: "Klik op onderstaande knop om aan het design proces te beginnen. Je wordt dan eerst naar de Bizzerd designtool gestuurd waar je een ontwerp kan maken. Hierna vul je je gegevens in en betaal je de standaard tarieven voor een abonnement.",
            block2ButtonText: "Start nu",
            blockTitle: "Upload ontwerp",
            designSpecification: "ontwerpspecificaties",
            block1Content: "Wil je zelf jouw digitale visitekaartje ontwerpen? Dat kan! Volg deze 5 stappen:",
            step1: "Bekijk de",
            step2: "Kies de ontwerptool die je wil gebruiken:",
            step2a: "Kies hier voor ",
            step2b: "Kies hier voor ",
            step3: "Creëer je ontwerp",
            step4: "Exporteer het bestand",
            step5: "E-mail het bestand met je (bedrijfs)naam naar: ",
            block1Footer: "Onze experts zetten jouw digitale visitekaartje vervolgens binnen 3 werkdagen in jouw account. Je krijgt bericht zodra het klaar is. En dan delen maar!",
            designSpecifications: "Ontwerp specificaties",
            viewDesignSpecifications: "Ontwerp specificaties bekijken",
            clickOnLink: "Klik op deze ",
            figmaDownload: "om het Figma template te downloaden",
            psDownload: " om het Photoshop template te downloaden"
        },
        editor: {
            pageTitle: "Maak je visitekaartje in 3 stappen",
            differentDesign: "Wil je toch iets anders",
            designEnterprise: "Ontwerp op maat",
            pickDesign: "ander ontwerp uitkiezen",
            or: "Of",
            contactDetails: "Jouw contactgegevens",
            fullname: "Naam",
            mobile: "Mobiel nummer",
            email: "E-mail",
            companyName: "Bedrijfsnaam",
            jobtitle: "Functie",
            tel: "Telefoonnummer",
            adresveld: "Adres",
            toStep: "Naar stap",
            colorLogo: "Kleuren & logo",
            delete: "Verwijderen",
            correctLogoMessage: "Wist je dat je altijd later je logo goed kan zetten in de uitgebreide editor?",
            adjustFields: "Velden aanpassen",
            clickToSelect: "Klik op een veld in je visitekaartje om het te selecteren",
            next: "Volgende"
        },
        enterprise: {
            cardTitle: "Enterprise kaartjes",
            fromPriceMonth: "Vanaf €0.00 p/mnd",
            viewAllCards: "Alle design bekijken",
            viewMoreCards: "Meer designs bekijken...",
            enterpriseTitle: "Laat jullie digitale visitekaartje op maat maken",
            enterpriseContent: "Voor talloze grote organisaties, wereldwijd, creëert Bizzerd digitale visitekaartjes in de eigen huisstijl. Wij nemen alle stappen uit handen. Van het ontwerp – in jullie aangeleverde huisstijl – tot het automatisch inladen van alle gegevens. Vraag nu een vrijblijvende offerte aan!",
            viewPros: "Voordelen van Bizzerd op maat voor grote organisaties:",
            scalable: "Schaalbaar",
            privacy: "Privacy",
            security: "Databeveiliging",
            engagement: "Engagement",
            branding: "Branding",
            crm: "CRM-integratie",
            climate: "Duurzaam",
            scalableBlock: {
                pageTitle: "Schaalbaar: tot (honderd)duizenden gebruikers",
                pageContent: "Met Bizzerd kan jullie organisatie (wereldwijd) vooruit. Bizzerd wordt gebruikt bij organisaties van uiteenlopende omvang. Van enkele tientallen gebruikers tot honderdduizenden. Ook met diverse landenvestigingen en verschillende taalgebieden.",
                usp1: "Eenvoudig gebruikers toevoegen of verwijderen",
                usp2: "Wereldwijd bruikbaar",
                usp3: "Ondersteunt alle talen en lettertekens"
            },
            privacyBlock: {
                pageTitle: "Privacy: maximaal beschermd",
                pageContent: "Bizzerd wordt vertrouwd door tal van grote (internationale) organisaties en voldoet aan strenge normen voor privacy en bescherming van persoonsgegevens (AVG, GDPR).",
                usp1: "Gebruikers bepalen zelf welke informatie ze delen",
                usp2: "Verwerking conform strenge privacyrichtlijnen",
                usp3: "Gehost op Nederlandse en Duitse servers"
            },
            securityBlock: {
                pageTitle: "Databeveiliging: robuust en beproefd",
                pageContent: "Bizzerd besteedt veel aandacht aan de beveiliging van systemen en data. Onze databeveiliging wordt regelmatig getoetst en geüpdatet",
                usp1: "Beproefde procedures voor back-ups en incidentmanagement",
                usp2: "Regelmatige pentests tonen veiligheid aan",
                usp3: "Gehost op streng beveiligde servers"
            },
            engagementBlock: {
                pageTitle: "Engagement: maak echt indruk met visitekaartjes",
                pageContent: "Met Bizzerd maken medewerkers de beste eerste indruk. Creëer extra betrokkenheid door bijvoorbeeld bewegende visuals, presentaties, video's of animaties toe te voegen. Of plaats een call-to-action. Met de inzichten die Bizzerd analytics geeft, monitor je de impact. Zo maak je de digitale visitekaartjes van je organisatie nóg effectiever!",
                usp1: "Maximale mogelijkheden voor interactie",
                usp2: "Inzicht in impact en betrokkenheid",
                usp3: "Up-to-date: de ontvanger heeft voortaan jouw actuele gegevens"
            },
            brandingBlock: {
                pageTitle: "Branding: versterk je merk",
                pageContent: "Bizzerd versterkt de visuele identiteit en branding van je organisatie. Elk merk komt tot leven, dankzij het persoonlijke en onderscheidende karakter. Elk interactief visitekaartje is een levendige uiting van de huisstijl en maakt van medewerkers echte organisatieambassadeurs.",
                usp1: "Geheel in de eigen huisstijl van de organisatie",
                usp2: "Maakt de corporate identity persoonlijk en interactief",
                usp3: "Volop mogelijkheden om te onderscheiden"
            },
            hrmBlock: {
                pageTitle: "CRM-integratie: optimale koppeling",
                pageContent: "Steeds meer organisaties integreren Bizzerd met hun CRM-systeem (Afas of Microsoft). Dit biedt veel voordelen. Het beheer van visitekaartjes wordt zo nóg makkelijker. Nieuwe collega’s krijgen automatisch een digitaal visitekaartje. Aanvinken in het systeem is voldoende.",
                usp1: "Automatisch aanpassen van elk visitekaartje bij een nieuwe huisstijl of bij designaanpassingen",
                usp2: "Alle visitekaartjes worden in 1 keer geüpdatet als bedrijfsgegevens veranderen.",
                usp3: "Monitoren: statistieken voor interne rapportages."
            },
            climateBlock: {
                pageTitle: "Duurzaam: van (foot)print naar digitaal",
                pageContent: "Organisaties zijn voortdurend op zoek naar mogelijkheden om verder te verduurzamen. Bizzerd is een van de eenvoudigste manieren om bij te dragen aan klimaat en milieu. Wist je dat er wereldwijd jaarlijks ruim 10 miljard visitekaartjes worden gedrukt? Dat is zo’n 27 miljoen per dag. Kies voor duurzaam; papierloos.",
                usp1: "Papierloos, dus geen kap van bomen, geen transport en geen drukwerk",
                usp2: "Laat zien dat je organisatie geeft om klimaat en milieu",
                usp3: "Praktische en concrete invulling van MVO-beleid"
            }
        },
        nef: {
            nav: {
                step1: "Kies een template",
                step2: "Voeg je gegevens toe",
                step3: "Template aanpassen",
                step4: "Kies een plan",
                step5: "Afrekenen",
                step: "stap",
                ofThe: "van de",
                nextStep: "Volgende stap",
                previousStep: "Vorige stap",
            },
            s1: {
                title: "Kies een template",
                subTitle: " ",
                back: "Terug",
            },
            s2: {
                title: "Voeg je gegevens toe",
                subTitle: "Maak je eerste gebruiker aan",
                userDetails: "Gebruikersgegevens",
                accountDetails: "Accountgegevens",
                email: "Email adres",
                password: "Wachtwoord",
                passwordAgain: "Wachtwoord opnieuw",
                personDetails: "Persoonsgegevens",
                firstName: "Voornaam",
                lastName: "Achternaam",
                jobTitle: "Functietitel",
                mobile: "Mobiel nummer",
                phone: "Telefoon nummer",
                companyDetails: "Bedrijfsgegevens",
                companyName: "Bedrijfsnaam",
                website: "Website",
                address: "adres",
                errors: {
                    passwordLength: "Het wachtwoord moet een minimale lengte hebben van 8.",
                    passwordMatch: "Wachtwoorden komen niet overeen"
                }
            },
            s3: {
                title: "Template aanpassen",
                subTitle: "Voeg je bedrijfshuisstijl toe.",
                subSubTitle: "Je kan dit altijd later nog aanpassen in je account.",
                logoPosition: "Logo positie",
                left: "Links",
                center: "Midden",
                right: "Rechts",
                size: "Grootte",
                backgroundColors: "Achtergrondkleur",
                topBackgroundImage: "Achtergrondafbeelding",
                topBackgroundColor: "Achtergrondkleur boven",
                uploadImage: "uploaden",
                userInfo: "Gebruikersgegevens",
                textColors: "Tekst kleur",
                titleColor: "Titel kleur",
                jobTitleColor: "Functietitel kleur",
                userInfoColor: "Gebruikersgegevens kleur",
                buttonColors: "Button kleur",
                backgroundColor: "Achtergrondkleur",
                iconColor: "icoon kleur",
                i9: "Geen achtergrond",
                i10: "Scherp",
                i11: "Afgerond",
                i12: "Circel",
                smbc: "Social media button kleur",
                ssbc: "opslaan & delen button kleur"
            },
            s4: {
                title: "Kies een plan",
                monthly: "Maandelijks",
                pupm: "Per gebruiker, per maand",
                pupy: "Per gebruiker, per jaar",
                pm: "per maand",
                y1: "1 jaar",
                y2: "2 jaar",
                ppm: "Betaal per maand",
                ppy: "Betaal per jaar",
                pu: "Per gebruiker,",
                it: "in totaal",
                pMonth: "Per maand"
            },
            s5: {
                title: "Winkelwagen",
                users: "Gebruikers",
                amount: "Aantal",
                usersSmall: "gebruikers",
                authorize: "Betaal eenmalig € 0.25 om de automatische afschrijving te authorizeren",
                subtotal: "subtotaal",
                vat: "BTW",
                total: "Totaal",
                agree: "Ik ga akkoord met de ",
                terms: "algemene voorwaarden",
                button: "Betalen"
            }
        },
        pricingPlan: {
            free: {
                title: "Probeer gratis",
                subTitle: "Gratis",
                days30: "Voor 30 dagen",
                tryFor: "Probeer Bizzerd voor 30 dagen gratis met beperkte functionaliteit",
                f1: "Max. 2 gebruikers",
                f2: "toegang voor30 dagen",
                f3: "Deel je kaart overal",
                f4: "Card manager / Gebruikersbeheer",
                f5: "Voeg je social media kanalen toe",
                button: "Probeer gratis"
            },
            business: {
                title: "Bizzerd Business",
                from: "From",
                perUser: "Per gebruiker, per maand",
                pay: "Aan het einde van de maand betaalt u voor elke gebruiker in het systeem.",
                f1: "Onbeperkt aantal gebruikers",
                f2: "Onbeperkt aantal shares",
                f3: "Deel je kaartje overal",
                f4: "Bewerk je visitekaartje",
                f5: "Card manager / Gebruikersbeheer",
                f6: "Add your social media channels",
                f7: "Houdt statistieken bij",
                users: "Gebruikers",
                vat: "Excl. BTW",
                button: "Kies Bizzerd business",
                f8: "",
            },
            enterprise: {
                title: "Bizzerd Op Maat",
                subTitle: "Digitale visitekaartjes voor grote bedrijven",
                subsubTitle: "",
                quotationLink: "/Offerte_2024_V18_04-NL.pdf",
                fTitle: "Oplossingen voor grote bedrijven",
                quotation: "Download offerte pdf",
                f1: "Volledig eigen ontwerp",
                f2: "Eigen domeinnaam",
                f3: "Bizzerd NFC",
                f4: "CRM connecties",
                contact: "Offerte aanvragen"
            }
        },


        home: {
            pageTitle: "Het meest gebruikte digitale visitekaartje",
            pageSubtitle: "Vanaf €0,00 per maand",
            pricingButton: "Prijzen",
            pageContent: "Benut de features van het enige écht digitale visitekaartje",
            usp1: "Krijg <b>inzicht</b>. Volg wat ontvangers doen met jouw visitekaartje",
            usp2: "Je visitekaartje is een <b>mini-website</b>. Werkt altijd, ook zonder de app",
            usp3: "<b>Duurzaam</b>. Bespaar papier, drukwerk en transport",
            scanOrClick: "Scan of klik en \nontdek hoe het werkt",
            viewDesigns: "Bekijk de ontwerpen",
            nfcHow: "Bizzerd NFC<br/> Hoe werkt dat?",
            tryForFree: "Maak jouw digitale<br/> visitekaartje",
            costs: "Digitale visitekaartjes voor grote bedrijven",
            letUsDesign: "De prijs van  digitale visitekaartjes",
            toTheCards: "Zo werkt het",
            uploadOwnDesign: "Upload of maak je eigen ontwerp",
            upload: "Enterprise",
            requestDesign: "Laat ons jouw kaartje ontwerpen",
            enterprise: "Maatwerk",
            startNow: "Offerte aanvragen",
            popularDesigns: "Deze organisaties kozen al voor Bizzerd:",
            fromPrice: "Vanaf €0.00 p/mnd",
            viewAllDesigns: "Alle designs bekijken",
            viewMoreDesigns: "Meer designs bekijken...",
            mkbCompany: "Midden-en-kleinbedrijf",
            mkbContent: "Ben je ondernemer? ZZP’er of MKB’er, kies Bizzerd voor jouw bedrijf. Voor jezelf en voor je medewerkers",
            moreInfo: "Demo bekijken",
            corporateCompany: "Corporate oplossingen",
            corporateContent: "Voor talloze grote organisaties creëert Bizzerd digitale visitekaartjes in de eigen huisstijl. Zo helpt Bizzerd grote organisaties met verduurzamen, inzicht krijgen in hun netwerk en huisstijl getrouw het bedrijf presenteren.",
            faq: {
                q1: "Werkt Bizzerd alleen met NFC?",
                a1: "Nee, Bizzerd is het enige digitale visitekaartje dat je ook zonder fysiek NFC-kaartje kunt delen. Dit komt dankzij de unieke opzet van Bizzerd. Elk digitaal visitekaartje bij Bizzerd is eigenlijk een mini-website met een unieke domeinnaam. Daardoor kun je het digitale visitekaartje altijd en overal gebruiken, zolang je maar internetverbinding hebt.",
                q2: "Moet je de app van Bizzerd downloaden om het digitale visitekaartje te gebruiken?",
                a2: "Nee, het is niet nodig om de app van Bizzerd te downloaden. Bizzerd werkt ook zonder app. De app van Bizzerd is extra, om meer functionaliteit toe te voegen aan het digitale visitekaatje. Zoals een bibliotheek, waarin je de digitale visitekaartjes van al je collega’s kunt bewaren. Bovendien heeft de app van Bizzerd een praktische scanner, waarmee je ontvangen papieren visitekaartjes kunt scannen en opslaan.",
                q3: "Waardoor is Bizzerd goedkoper dan andere aanbieders van digitale visitekaartjes, terwijl Bizzerd de meeste functionaliteiten heeft?",
                a3: "Bizzerd is goedkoper (en voor 1 digitaal visitekaartje zelfs GRATIS), doordat Bizzerd schaalvoordeel weet te behalen als meest gebruikte digitale visitekaartje. Hierdoor kunnen we de kosten delen met een groot aantal gebruikers. Bij Bizzerd hebben we ervoor gekozen om dit kostenvoordeel direct door te berekenen aan de gebruikers.",
                q4: "Heeft Bizzerd alleen standard-designs of kan ik mijn eigen huisstijl toepassen?",
                a4: "Bizzerd kun je volledig in de huisstijl van jouw organisatie laten maken. Voor organisaties met meer dan 10 gebruikers maakt Bizzerd jullie design desgewenst in jullie huisstijl. Je kunt er ook voor kiezen om je designbureau het ontwerp te laten maken. Kies je voor 1 digitaal visitekaartje (GRATIS), dan kun je zelf je design maken, met de templates op de site. Dit werkt heel eenvoudig en ziet er professioneel uit.",
                q5: "Wat is het voordeel van integratie met HRM- en CRM-pakketten zoals AFAS en Azure?",
                a5: "Organisaties met meer dan 10 gebruikers kunnen Bizzerd integreren met hun CRM- of HRM-pakket (Azure, AFAS). Dit biedt meerdere voordelen. Dankzij de integratie met HRM-pakketten, is het mogelijk om nieuwe collega’s geautomatiseerd een digitaal visitekaartje te geven, als ze in dienst treden. Gaan ze uit dienst, dan wordt het digitale visitekaartje automatisch opgeheven.\nDankzij de integratie met CRM-pakketten is het bijvoorbeeld mogelijk te registreren en te monitoren aan welke klanten en prospects een digitaal visitekaartje is gegeven. \n",
            },

            faqTitle: "FAQ",
            faq1Title: "Hoe werkt het aanmaken van een digitaal visitekaartje?",
            faq1Answer: "Je kunt zelf je visitekaartje aanmaken of dat door Bizzerd laten doen (Op maat).",
            faq1AnswerHeader1: "Zelf aanmaken",
            faq1AnswerContent1: "Wil je zelf je visitekaartje aanmaken, klik dan in het hoofdmenu op ‘Visitekaartjes’. Kies een van de ontwerpen uit de collectie en maak in 3 stappen jouw visitekaartje. Wil je een eigen ontwerp gebruiken, dan kan dat ook. Je kunt dan je eigen ontwerp uploaden.",
            faq1AnswerHeader2: "Op maat laten maken",
            faq1AnswerContent2: "Je kunt er ook voor kiezen om visitekaartje(s) op maat te laten maken door Bizzerd. Bizzerd neemt dan alles uit handen wat erbij komt kijken. Vraag meer informatie of een offerte aan.",
            faq2Title: "Wat ben ik per maand kwijt aan een digitaal visitekaartje?",
            faq2Answer: "Op de pagina Prijzen vind je onze tarieven. Je kunt Bizzerd al gebruiken voor 59 cent per maand (3 jaar looptijd). Laat je jullie visitekaartjes door Bizzerd op maat maken, dan ontvang je een offerte vooraf.",
            faq3Title: "Kan ik mijn gegevens makkelijk aanpassen?",
            faq3Answer: "Jazeker. Als je jouw gegevens wilt aanpassen, dan kan dat heel makkelijk. Log in met je e-mailadres en wachtwoord. Je komt dan in het dashboard. Daar kun je alle gegevens op je visitekaartje aanpassen en het ontwerp aanpassen. Ben je jouw wachtwoord vergeten, dan kun je daar een nieuw wachtwoord aanvragen.",
            faq4Title: "Hoe deel ik mijn digitale visitekaartje met iemand?",
            faq4Answer: "Je kunt je digitale visitekaartje op meerdere manieren aan iemand geven, met of zonder de Bizzerd-app. De meest gebruikte manieren zijn:",
            faq4AnswerList1: "Jouw visitekaartje versturen via e-mail, WhatsApp of sms. Klik op het delen-icoon en deel.",
            faq4AnswerList2: "De QR-code van jouw online visitekaartje door de ander laten scannen.",
            faq4AnswerList3: "Via AirDrop (iPhone). Afhankelijk van hoe je AirDrop hebt ingesteld, kunnen mensen in jouw omgeving jouw Bizzerd zien. ",
            faq4AnswerList4: "Via de speciale Bizzerd app (Apple of Android)",
            faq4AnswerList5: "Via de eenvoudig te gebruiken NFC kaartjes & tags",
            faq5Title: "Wat is het verschil tussen digitale visitekaartjes van Bizzerd en andere aanbieders?",
            faq5Answer: "Er zijn veel verschillen. We noemen hier de 2 belangrijkste verschillen tussen Bizzerd en andere digitale visitekaartjes.",
            faq5AnswerList1: "DEELFUNCTIE – Het delen van je digitale visitekaartje werkt bij Bizzerd altijd: met NFC en zonder NFC, met de speciale app en zonder app, met QR-code en zonder QR-code. Bij andere digitale visitekaartjes is dit niet zo. Bij de meeste aanbieders kun je een digitaal visitekaartje alleen maar delen via een NFC-tag of -kaart. Of je kunt je digitale visitekaartjes alleen maar delen met een QR-code. Bij Bizzerd is je digitale visitekaartje eigenlijk een mini-website. Daardoor werkt het altijd en kun je je digitale visitekaartje altijd delen.",
            faq5AnswerList2: "PRIJS - Een ander belangrijk verschil is de prijs. Bizzerd is de voordeligste aanbieder. Als je kiest voor 1 digitaal visitekaartje, dan is Bizzerd zelfs gratis. Dat biedt geen enkel ander digitaal visitekaartje. Bij de andere opties is Bizzerd de voordeligste aanbieder.",

        },
        reviewBlock: {
            pageTitle: "Waarom kiezen voor het meest gebruikte interactieve visitekaartje?",
            pageContent: "Bizzerd wordt dagelijks wereldwijd gebruikt door honderdduizenden professionals. Van zzp'ers tot multinationals. Bizzerd is veel meer dan alleen een visitekaartje. Dankzij alle mogelijkheden om jezelf te presenteren en alle manieren waarop je jouw visitekaartje kunt delen. Kies ook voor Bizzerd",
            reviewOwner: "Raymond de Boer - Tevreden klant sinds 2016",
            viewCards: "Visitekaartjes bekijken"
        },
        loginBlock: {
            pageTitle: "Inloggen",
            pageTitleForgot: "Wachtwoord vergeten",
            blockTitle: "Welkom bij Bizzerd",
            ForgotblockTitle: "Nieuw wachtwoord aanmaken",
            blockTitleForgotRequest: "Wachtwoord reset link aanvragen",

            email: "E-mail",
            password: "Wachtwoord",
            password2: "Wachtwoord herhalen",
            enterprise: "Op maat",
            forgotPassword: "Wachtwoord vergeten",
            noAccount: "Heb je nog geen account?",
            pickCard: "Kies dan hier een kaartje",
            makeDesign: "En maak je account aan om deze op te slaan."
        },
        pricing: {
            pageTitle: "Prijzen",
            subTitle: "Bij Bizzerd zijn we gespecialiseerd in het maken van digitale visitekaartjes op maat voor de unieke huisstijlen van talloze grote organisaties wereldwijd.",
            freeCard: "1 digitaal visitekaartje GRATIS!",
            pickCard: "Maak zelf je digitale visitekaartje met een van de vele beschikbare designs",
            block1: {
                title: "10-30 digitale visitekaartjes",
                subTitle: "€9,48 per persoon per jaar ex. BTW",
                button: "€0,79 p/mnd"
            }, block2: {
                title: "31 - 100 digitale visitekaartjes",
                subTitle: "€8,28 per persoon per jaar ex. BTW",
                button: "€0,69 p/mnd"
            }, block3: {
                title: "101 - 200 digitale visitekaartjes",
                subTitle: "€7,08 per persoon per jaar ex. BTW",
                button: "€0,59 p/mnd"

            }, block4: {
                title: "201 - 500 digitale visitekaartjes",
                subTitle: "€5,88 per persoon per jaar ex. BTW",
                button: "€0,49 p/mnd"
            }, block5: {
                title: "500+ digitale visitekaartjes",
                subTitle: "Neem contact met ons op voor meer info",
                button: "Aanvragen"
            },
            inclusive: "Hier kies je altijd voor",
            usp1: "Verlengbare termijn gedurende de looptijd",
            usp2: "De gegevens op je kaartje kun je altijd aanpassen",
            usp3: "Gratis app om je kaartje te delen en andere kaartjes mee op te slaan!",
            nfcTitle: "Digitaal visitekaartje met NFC",
            nfcContent1: "Bizzerd werkt altijd, ook zonder NFC. Kies je voor NFC, dan kun je jouw digitale visitekaartje nog makkelijker delen. Je kunt kiezen voor een NFC-tag (telefoonsticker) of een NFC-pasje (van duurzaam bamboe).",
            nfcContent2: "NFC-pasje* bamboe, met eigen logo, €16,- per stuk, ex. btw",
            nfcContent3: "NFC-tag* (telefoonsticker), met eigen logo, €8,- per stuk, ex. btw",
            star: "* Inclusief koppelen aan de url van je digitale visitekaartje"

        },
        product: {
            pageTitle: "Zo werkt Bizzerd, het digitale visitekaartje",
            pageSubTitle: "Features",
            pageContent1: "Bizzerd is het meest gebruikte digitale visitekaartje, met de meest uitgebreide functionaliteit.",
            pageContent2: "Met Bizzerd gaat netwerken verder dan alleen het uitwisselen van contactgegevens. Je creëert een blijvende indruk. Waar en wanneer je maar wilt.",
            buttonText: "Visitekaartjes bekijken",
            bar: {
                title: "Wat is een digitaal visitekaartje?",
                content1: "Verander de manier waarop je contact maakt met Bizzerd - waar je visitekaartje een dynamische mini-website wordt. Deel je interactieve visitekaartje moeiteloos via platforms als WhatsApp, sms en e-mail, of zelfs persoonlijk met behulp van een QR-code.",
                item1: {
                    title: "Create",
                    content: "Creëer eenvoudig jouw digitale visitekaartje met de handige editor.\n\nGrotere organisatie?\nLaat ons jouw kaart ontwerpen."
                },
                item2: {
                    title: "Use and share",
                    content: "Benut de vele mogelijkheden om je digitale\nvisitekaartje te delen: via de Bizzerd-app,\nmet een NFC-kaartje of tag, via e-mail,\nWhatsApp, sms of QR-code.\n\nEn voeg je digitale visitekaartje toe aan je\nApple Wallet. Superhandig!"
                },
                item3: {
                    title: "Receive",
                    content: "De ontvanger bewaart jouw digitale\nvisitekaartje met 1 klik in diens telefoon.\n\nZo heeft de ontvanger alle gegevens bij de\nhand die jij wilt delen."
                },
            },
            orangebar: {
                title: "Zorg voor interactie",
                content: "Verander de manier waarop je contact maakt met Bizzerd - waar je visitekaartje een dynamische mini-website wordt. Volledig aanpasbaar om de identiteit en behoeften van je bedrijf te weerspiegelen.",
                item1: {title: "Contactgegevens", content: "Koppel je telefoonnummers, e-mailadres en meer"},
                item2: {title: "Sociale media", content: "Voeg je social media en andere mediakanalen toe"},
                item3: {
                    title: "Video",
                    content: "Voeg bewegende beelden toe aan je visitekaartje, bijvoorbeeld gifjes of video’s"
                },
                item4: {
                    title: "Documenten",
                    content: "Voeg presentaties, prijslijsten en offertes toe, of elk ander document dat je wilt"
                },

            },
            shareBlock: {
                blockTitle: "Deel je digitale visitekaartje zoals je wilt",
                blockContent: "Je digitale visitekaartje kan eenvoudig op verschillende manieren\n" +
                    "worden gedeeld met iemand anders. Er zijn veel opties, zodat je\n" +
                    "kunt kiezen wat het beste past bij de situatie.\n" +
                    "Voeg je kaartje toe aan je Apple Wallet en deel hem in 1 click.\n" +
                    "Óf deel via Airdrop, Whatsapp, QR code en via onze Bizzerd NFC\n" +
                    "kaarten*.\n" +
                    "\n" +
                    "Het beste is dat de ontvanger van je digitale visitekaartje direct\n" +
                    "jouw contactgegevens in hun telefoon kan opslaan. Dit omvat\n" +
                    "bijna alles op het kaartje, zoals je contactgegevens, bedrijfsadres\n" +
                    "en website.\n" +
                    "\n" +
                    "Wist je dat je ook een link naar je digitale visitekaartje kunt\n" +
                    "toevoegen aan je e-mailhandtekening?",
            },
            statisticsBlock: {
                blockTitle: "Gebruiksstatistieken en inzichten",
                blockContent: "Volg wat ontvangers met je digitale visitekaartje doen. Wie bekijkt je visitekaartje? Hoe vaak? Hoe lang? Waar wordt op geklikt? Hoe vaak wordt je visitekaartje opnieuw gedeeld? Bizzerd geeft je alle antwoorden op deze vragen. Dit geeft je waardevolle inzichten om relaties te versterken, kansen te ontdekken en te benutten.",
            },
            appBlock: {
                blockTitle: "Bizzerd app",
                blockContent: "Bizzerd werkt altijd, ook zonder app. Maar met de gratis app krijg je toegang tot praktische extra functies die je niet wilt missen. Zoals een scanner. Hiermee kun je papieren visitekaartjes van anderen scannen, hun gegevens opslaan in je telefoon en ze jouw Bizzerd terugsturen. \n" +
                    "\n" +
                    "De app heeft ook een handige bibliotheek waar je ontvangen visitekaartjes kunt opslaan. En de app geeft je toegang tot de digitale visitekaartjes van je collega's. Handig als je hen in contact wilt brengen met jouw zakelijke contacten. De app geeft je overzicht en inzicht. Onmisbaar voor wie zakelijke contacten wil stroomlijnen en professioneel wil netwerken.\n",
            },
            brandingBlock: {
                blockTitle: "In de eigen huisstijl",
                blockContent: "Bizzerd ondersteunt de huisstijl, visuele identiteit en branding van je organisatie. Elk digitaal visitekaartje is een levendige uitdrukking van de bedrijfsidentiteit en maakt van medewerkers echte ambassadeurs.",
            },
            nfcBlock: {
                blockTitle: "Bizzerd met NFC",
                blockContent: "Bij Bizzerd benut je alle voordelen van NFC (Near Field Communication). Je kunt kiezen voor een NFC-tag. Dat is een sticker met de NFC-chip die je bijvoorbeeld op je telefoon kunt plakken. Of kies voor een fysiek visitekaartje met NFC, verkrijgbaar in duurzaam bamboe.\n" +
                    "\n" +
                    "Met de NFC-tag of de NFC-card deel je jouw digitale visitekaartje in 1 seconde! Zo veel en zo vaak als je wil.\n" +
                    "\n" +
                    "Kies uit ons gevarieerde aanbod de NFC-kaart die volledig past bij het karakter en de stijl van jouw organisatie.\n",
            },
            insight: "Inzichten",
            dashboard: "Het dashboard",
            interactive: "Interactief kaartje",
            app: "De Bizzerd app",
        },
        register: {
            pageTitle: "Account aanmaken",
            accountDetails: "Accountgegevens",
            email: "E-mail",
            password: "Wachtwoord",
            register: "Registreren"


        },
        shoppingCart: {
            pageTitle: "Winkelmandje",
            subTitle: "Wist je dat je later altijd meer collega’s en designs kan toevoegen / aanpassen?",
            nothingHere: "Er is hier niks! Voeg eerst een kaartje toe",
            toTheCards: "Naar de kaartjes",
            digitalCardNormal: "Digitaal visitekaartje - ",
            nfc: "Voeg een aanvraag voor NFC toe (optioneel)",
            year: "jaar",
            card: "kaartje",
            py: "p.j.",
            or: "of",
            toEnterprise: "Naar maatwerk",
            enterCode: "Promotiecode invoeren",
            total: "Totaal (ex. BTW)",
            btw: "21% BTW",
            totalIncBtw: "Totaal (incl. BTW)",
            agreeTo: "Ik ga akkoord met de",
            toc: "Algemene voorwaarden",
            privacyPolicy: "Privacy policy",
            pay: "Afrekenen",
        },
        subscription: {
            pageTitle: "Kies je abonnement",
            block1: {
                title: "1 jaar - Probeer ‘t uit!",
                subTitle: "€9,48 per persoon per jaar ex. BTW",
                button: "€0,79 p/mnd"
            }, block2: {
                title: "2 jaar - Meest gekozen",
                subTitle: "€8,28 per persoon per jaar ex. BTW",
                button: "€0,69 p/mnd"
            }, block3: {
                title: "3 jaar - Slim ingekocht",
                subTitle: "€7,08 per persoon per jaar ex. BTW",
                button: "€0,59 p/mnd"
            },
            inclusive: "Altijd inclusief",
            usp1: "Verlengbare termijn gedurende de looptijd",
            usp2: "De gegevens op je kaartje kun je altijd aanpassen",
            usp3: "Gratis app om je kaartje te delen en andere kaartjes mee op te slaan!",
        }


    },
    hi: 'hoi',
    cardPlaceholders: {
        name: 'Voornaam Achternaam',
        jobTitle: 'Functie',
        phoneNumber: 'Telefoonnummer',
        email: 'email',
        companyName: 'Bedrijfsnaam',
        zipCode: 'Postcode',
        address: 'Adres',
        websiteName: 'Website',
        twitter: 'Twitter',
        facebook: 'Facebook',
        linkedin: 'Linkedin',
        instagram: 'Instagram',
    },
    enterprise: {
        title: "Laat jullie visitekaartje op maat maken",
        listItem1: "In jullie huisstijl",
        listItem2: "Technische ondersteuning",
        listItem3: "Service en maatwerk",
        listItem4: "Begeleiding bij interne introductie en gebruik",
        buttonText: "Offerte aanvragen",
        cardsEnterpriseTitle: "Digitaal visitekaartje op maat",
        cardsEnterpriseText: "Stap met de hele organisatie over op het digitale visitekaartje. Snel en eenvoudig. Wij nemen alle stappen uit handen. Van het ontwerp – in jullie aangeleverde huisstijl – tot het automatisch inladen van alle gegevens. Ook adviseren we over het gebruik en ondersteunen we bij de interne communicatie binnen jullie organisatie. Geheel afgestemd op jullie behoefte.",
        prosEnterprise: "VOORDELEN OP MAAT",
        pros1Title: "Altijd up to date",
        pros1Text: "Nieuwe gegevens, zoals een ander e-mailadres? Pas ze meteen aan!",
        pros2Title: "Diverse taalopties",
        pros2Text: "Gemakkelijk voor internationale organisaties.",
        pros3Title: "Duurzaam",
        pros3Text: "Papierloos, dus je bespaart bomen en transport. Vrijwel geen CO2-uitstoot. Maatschappelijk verantwoord.",
        pros4Title: "Bizzerd app",
        pros4Text: "Heel eenvoudig delen en opslaan van visitekaartjes.",
        pros5Title: "Professioneel",
        pros5Text: "Nieuwe gegevens, zoals een ander e-mailadres? Pas ze meteen aan!",
        pros6Title: "Presentatieplatform",
        pros6Text: "Koppel presentaties, video’s of prijslijsten aan jullie digitale visitekaartjes. Handig voor op beurzen en conferenties.",
        alreadyUsingBizzerd: "DEZE ORGANISATIES GEBRUIKEN AL BIZZERD OP MAAT",
        evenEasierTitle: "NOG MAKKELIJKER: INTEGREER HET DIGITALE VISITEKAARTJE MET JULLIE HRM-SYSTEEM",
        evenEasierSubtitle: "Profiteer van alle voordelen van digitale visitekaartjes én meer. Koppel bizzerd aan jullie HRM-systeem (AFAS, Microsoft):",
        evenEasierlist1: "Nieuwe collega’s krijgen automatisch een digitaal visitekaartje. Aanvinken is genoeg.",
        evenEasierlist2: "Automatisch aanpassen van elk visitekaartje bij een nieuwe huisstijl of bij designaanpassingen.",
        evenEasierlist3: "Alle visitekaartjes worden in 1 keer geüpdatet als bedrijfsgegevens veranderen.",
        evenEasierlist4: "Monitoren. Gebruiksstatistieken voor marketing- en salesinformatie.",
        privacyEnterpriseTitle: "PRIVACY OP MAAT",
        privacyEnterpriseText: "Bizzerd wordt vertrouwd door tal van grote (internationale) organisaties en voldoet aan de eisen voor informatiebeveiliging en beveiliging van persoonsgegevens (AVG, GDPR). Onze databeveiliging wordt regelmatig getoetst. Zie ons privacy statement voor details.",
    },
    generic: {
        nextStep: 'Volgende stap',
        previousStep: 'Vorige stap',
        logIn: 'Inloggen',
        logOut: "Uitloggen",
        shoppingCart: 'Winkelmandje',
        changeDesign: "Ontwerp wijzigen",
        settings: "Instellingen",
        contact: "Contact",
        manageBusinesscards: "Visitekaartjes",
        insights: "Inzichten",
        manageSubscriptions: "Abonnementen",

    },
    product: {
        title: "Geef je visitekaartje af",
        subtitle: "In jouw stijl. Op jouw manier",
        android: "Android",
        video: "https://bizzerd2.bizzerd.com/api/videos/bizzerdNL.mp4",
        ios: "iPhone",
        web: "Mac of Windows PC",
        block1: {
            title: "Deel je bizzerd met anderen",
            content: "Delen kan op veel manieren: via e-mail, WhatsApp, sms, QR-code, NFC (Android) en AirDrop (iPhone)."
        }, block2: {
            title: "Scan papieren visitekaartjes",
            content: "Download de app, scan je ontvangen papieren visitekaartjes en stuur meteen jouw bizzerd terug."
        }, block3: {
            title: "Voeg toe aan je contacten",
            content: "Heb je een bizzerd ontvangen? Sla hem op in je telefoon met één druk op de knop en voeg hem toe aan je contacten."
        }, block4: {
            title: "Voeg bestanden toe",
            content: "Koppel eenvoudig bestanden aan je bizzerd. Bijvoorbeeld foto's, presentaties, offertes, prijslijsten of je curriculum vitae."
        }
    },
    shoppingCart: {
        addColleaguesAfter: "Wist je dat? Je altijd later meer collega's en designs kan aanpassen en toevoegen",
        title: "Winkelmandje",
        discountCode: "Voer een code in",
        total: "Totaal",
        totalIncBtw: "Totaal (incl. BTW)",
        toc: "algemene voorwaarden",
        accept: "Ik ga akkoord met de",
        and: "en",
        pp: "privacy policy",
        addColleague: "Collega toevoegen",
        pay: "Afrekenen",
        or: "of",
        item: {
            title: "Digitaal visitekaartje normaal",
            year: "jaar",
            py: "p.j.",
            card: "kaart",

        }
    },
    colleagues: {
        active: "Actief",
        activate: "Activeren",
        view: "Bekijken",
        edit: "Wijzigen",
        inviteSendTo: "Uitnodiging verzonden naar:",
        screenTooSmall: "Je huidige scherm is te klein voor de editor. Gebruik een computer",
        noFirefox: "Momenteel wordt de editor in firefox niet ondersteund. Gebruik een andere browser",
        viewDesigns: "Ontwerpen bekijken en aanpassen",
        viewColleagues: "Kaartjes collega’s bekijken",
        viewInvites: "Verzonden uitnodigingen",
        orClickEmailInvite: "Of klik hier voor meer opties",
        orClickDesigns: "Alle ontwerpen",
        orClickInvites: "Alle uitnodigingen",
        orClickColleagues: "Alle collega's",
        inviteColleagues: "Collega's uitnodigen",
        generateLink: "Nodig iemand uit",
        addColleague: "Collega's toevoegen",
        viewColleagues2: "Bekijk collega's",
        addNewColleague: "Voeg een nieuwe collega toe",
        continueWithData: "Verder met overige gegevens",
        firstname: "Voornaam",
        fullname: "Volledige naam",
        lastname: "Achternaam",
        content: "Waarde",
        status: "Status",
        registrationLinkContent: "Wil je een collega uitnodigen om ook Bizzerd te gebruiken? Genereer een unieke link, kopieer en plak die in een bericht. Degene die jij uitnodigt kan met de link zelf een digitaal visitekaartje maken die onder jouw bedrijf valt. Zo hoef je niet zelf alle gegevens in te vullen! Je kunt zelf instellen hoe lang de link geldig is.",
        expiration1: "Verloopt in 1 dag",
        expiration7: "Verloopt in 7 dagen",
        expiration30: "Verloopt in 30 dagen",
        expirationNever: "Altijd geldig",
        copy: "Kopiëren",
        generate: "Genereren",
        deactivate: "Link deactiveren",
        linkExpires: ". Deze link verwijderen of een andere link aanmaken?",
        deleteLink: "Deze link verloopt ",
        email: "E-mail",
        jobtitle: "Functietitel",
        mobile: "Mobiel nummer",
        add: "Naar collega toevoegen",
        invite: "Uitnodigen",
        inviteThroughEmail: "Nodig collega's uit via e-mail",
        inviteOverview: {
            title: "Bekijk en wijzig je collega's",
            fullname: "Volledige naam",
            view: "Bekijken",
            edit: "Wijzigen",
            delete: "Verwijderen",
            account: "Account",
            active: "Actief",
            inactive: "Inactief",
            viewInvites: "Bekijk je uitnodigingen",
            expiresOn: "Verloopt op",
            content: "Waarde",
            status: "Status",

        },
        colleagueOverview: {
            confirmDelete: "Weet je zeker dat je deze persoon wil verwijderen?",
            confirmInvite: "Weet je zeker dat je deze persoon wil uitnodigen?",
            title: "Bekijk en wijzig je collega's",
            fullname: "Volledige naam",
            view: "Bekijken",
            invite: "Uitnodigen",
            inviteAgain: "Opnieuw uitnodigen",
            edit: "Wijzigen",
            delete: "Verwijderen",
            account: "Account",
            active: "Actief",
            inactive: "Inactief",
            viewInvites: "Bekijk je uitnodigingen",
            expiresOn: "Verloopt op",
            content: "Waarde",
            status: "Status",

        },
        addColleagueModal: {
            title: "Nieuwe collega? Vul hier de gegevens in.",
            pageTitle1: "Persoonsgegevens",
            pageTitle2: "Contactgegevens",
            pageTitle3: "Social media",
            back: "Terug",
            edit: "Wijzigen",
            cancel: "Annuleren",
            cardExample: "Dit is hoe het visitekaartje eruit komt te zien.",
            chooseDesign: "Kies ontwerp",
            chooseDesignFirst: "Kies eerst een ontwerp.",
            save: "Opslaan",
            page1: {
                firstname: "Voornaam",
                lastname: "Achternaam",
                fullname: "Volledige naam",
                appendix: "Bijvoegsel",
                prefix: "Voorvoegsel",
                email: "E-mailadres",
                jobtitle: "Functietitel",
                bizzerdName: "Bizzerd-naam",
                personalQuote: "Persoonlijke quote",
                profilePicture: "Pasfoto",
                noPictureFound: "Geen pasfoto gevonden",
                choosePicture: "Kies een foto",
                removePicture: "Pasfoto weghalen"
            },
            page2: {
                phoneNumber: "Telefoonnummer",
                mobileNumber: "Mobielnummer",
                address: "Adresveld"
            },
            page3: {
                website: "Website",
                linkedin: "LinkedIn",
                facebook: "Facebook",
                twitter: "Twitter",
                instagram: "Instagram",
                pinterest: "Pinterest",
            }

        }


    },
    bulletPoints: {
        bullet1: "Altijd uniek en persoonlijk",
        bullet2: "Al vanaf €0,00 per maand",
        bullet3: "Eenvoudig, Praktisch, Duurzaam",
    },
    navBar: {
        home: "Home",
        businessCards: "Visitekaartjes",
        pricing: "Prijs",
        contact: "Contact",
        product: "Zo werkt het",
        enterprise: "Op maat"
    },
    feedback: {
        button: "Feedback",
        title: "Jouw feedback helpt!",
        subtitle: "Dit formulier is alleen bedoeld voor feedback op de website.",
        description: "Heb je vragen over de ontwerptool, collega's toevoegen, abonnementen of andere diensten? Stel die dan via mail, chat of telefoon aan onze collega's van de klantenservice.",
        question: "Wat kom je vandaag doen op onze website? *",
        choice: "Maak een keuze",
        feedbackDescription: "Laat hier je feedback achter. Hoe specifieker je bent, hoe beter wij in staat zijn om je feedback te behandelen en bizzerd.com te verbeteren!",
        send: "Verzenden",
    },
    contact: {
        title: 'Contact',
        subTitle: 'Voor vragen, offerte-aanvragen of support, vul onderstaand formulier in',
        fields: {
            name: "Naam",
            companyName: "Bedrijfsnaam",
            email: "E-mailadres",
            phoneNumber: "Telefoonnummer",
            message: "Bericht",

        },
        send: "Verzenden",
        faq: {
            faqItem1: {
                question: "Wat is Bizzerd?",
                answer: "Bizzerd is hét digitale, papierloze, contactloze visitekaartje. Digitaal, dus altijd toegankelijk. Heb je je telefoon bij je, dan heb je je visitekaartjes bij je. Altijd up-to-date. Want veranderen je contactgegevens, dan pas je die heel makkelijk aan. Papierloos, dus maatschappelijk verantwoord. Jaarlijks worden wereldwijd miljarden papieren visitekaartjes (ongebruikt) weggegooid. Als het aan bizzerd ligt, is dat voorbij. Contactloos, je kunt bizzerd op afstand delen, zonder fysiek contact. Veilig en hygiënisch."
            }, faqItem2: {
                question: "Hoe gebruik ik bizzerd?",
                answer: "Bizzerd is altijd beschikbaar: online én als handige app. Dankzij bizzerd is jouw digitale visitekaartje een mini-website, met een unieke url. Als url kan iedereen bizzerd overal gebruiken."
            }, faqItem3: {
                question: "Voor wie is bizzerd bedoeld?",
                answer: "Bizzerd is voor iedereen. Bizzerd is hét digitale visitekaartje voor zakelijk gebruik. Bizzerd wordt gebruikt door zzp’ers, MKB en door grote organisaties."
            }, faqItem4: {
                question: "Welke organisaties gebruiken bizzerd?",
                answer: "Een groot aantal organisaties binnen het MKB, de overheid en het (internationale) bedrijfsleven gebruiken bizzerd. Dit zijn bijvoorbeeld bouwbedrijven, vastgoedorganisaties, farmaceutische bedrijven, woningcorporaties, banken, verzekeringsmaatschappijen, jacht- en scheepsbouwbedrijven, installatiebedrijven, gemeentes, scholen, etc."
            }, faqItem5: {
                question: "Wat is het verschil tussen papieren visitekaartjes en bizzerd?",
                answer: "Papieren visitekaartjes zijn minder makkelijk in gebruik. Ze kreukelen snel, de gegevens zijn snel verouderd (bijvoorbeeld een nieuw telefoonnummer of nieuw adres) en ze raken snel zoek. Bovendien vergeet je papieren visitekaartjes makkelijk. Bizzerd heb je altijd bij je (tenzij je je telefoon vergeet). Je digitale visitekaartje is altijd up-to-date en is eigentijds."
            }, faqItem6: {
                question: "Waarom bizzerd?",
                answer: "We verbaasden ons erover dat er in dit digitale tijdperk nog steeds geen bruikbaar alternatief was voor papieren visitekaartjes. Want papieren visitekaartjes zijn best onhandig in gebruik. En ze kosten papier, dus bomen en energie. Dat kan en moet anders, vonden we. Slimmer, beter, handiger. Daarom bedachten we bizzerd. Met bizzerd heb je altijd je visitekaartjes bij je. En je draagt bij aan een duurzame wereld. Hoe meer mensen bizzerd gebruiken, hoe beter het wordt."
            }, faqItem7: {
                question: "Wat kost bizzerd?",
                answer: "Bizzerd hanteert prijzen voor zzp’ers, voor MKB en voor grote organisatie. Als zzp’er betaal je 79 cent per maand. Voor MKB lopen de tarieven uiteen van 79 cent tot 49 cent per gebruiker per maand, plus inrichtingskosten. Voor grote organisaties brengen we een offerte op maat uit. Meer informatie vind je op onze tarievenpagina"
            }, faqItem8: {
                question: "Hoe deel ik mijn bizzerd?",
                answer: "Je kunt je online visitekaartje op meerdere manieren geven. De meest gebruikte manieren zijn:",
                answerLine1: "De url van jouw online visitekaartje versturen via e-mail, WhatsApp of sms.",
                answerLine2: "De QR-code van jouw online visitekaartje door de ander laten scannen.",
                answerLine3: "De telefoons dicht bij elkaar houden (als jullie telefoons allebei near field communication (NFC) ondersteunen)",
                answerLine4: "Via AirDrop (iPhone)",
                answerLine5: "Via de bizzerd app"
            }, faqItem9: {
                question: "Waar vind ik de bizzerd app?",
                answer: "De bizzerd app voor Android vind je in de bij Google Play. Voor iPhone vind je hem in de App Store. Via de website van bizzerd kom je er snel."
            }, faqItem10: {
                question: "Hoe werkt AirDrop?",
                answer: "Heb je een iPhone 5 of hoger, iPad (4e generatie of hoger), iPad mini (1e generatie of hoger) en de iPod Touch (5e generatie of hoger), dan heeft je apparaat AirDrop. Met deze functie deel je in een snel en makkelijk gegevens via bluetooth en wifi. Je schakelt de functie aan of uit via het Controlecentrum (maak vanuit een van de homescreens van iOS een veegbeweging van onder naar boven te maken).\n" +
                    "Tik op het AirDrop-pictogram en kies of je bestanden wilt ontvangen en delen met contacten of iedereen in je buurt. Je ziet tegelijkertijd het icoontje voor bluetooth oplichten.\n" +
                    "Selecteer vervolgens jouw bizzerd. Tik op de deel-knop en kies bij AirDrop de desbetreffende ontvanger. Let op: deze moet ook AirDrop ingeschakeld hebben. Heeft de ander jouw bizzerd ontvangen, dan kan hij of zij deze weigeren of accepteren. Kiest hij voor Accepteren, dan kan hij jouw bizzerd meteen opslaan bij Contacten."
            }, faqItem11: {
                question: "Hoe kan ik mijn digitale visitekaartje delen?",
                answer: "Je kunt bizzerd op heel veel manier delen. Als ‘handtekening’ onder je e-mails. En via sms, WhatsApp of andere sociale media, via de QR-code en contactloos (via Near Field Communication, NFC of AirDrop). Hoe meer mogelijkheden je combineert, hoe makkelijker mensen je leren kennen."
            }, faqItem12: {
                question: "Ik heb een digitaal visitekaartje ontvangen. Wat kan ik daarmee?",
                answer: "Je kunt de contactgegevens van de afzender automatisch opslaan bij ‘Contacten’. En je kunt het ontvangen digitale visitekaartje bewaren als icoon op je telefoon (als ‘sneltoets’). Zodat je altijd de gegevens van de afzender bij de hand hebt."
            }, faqItem13: {
                question: "Hoe kan ik een ontvangen digitaal visitekaartje opslaan als sneltoets?",
                answerLine1: "Voor iOS(iPhone):",
                answerLine2: "Open het visitekaartje.",
                answerLine3: "Klik op het deelicoon onderin het menu.",
                answerLine4: "Kies: 'zet in beginscherm'.",
                answerLine5: "Voor Android en overige systemen:",
                answerLine6: "Klik op de menutoets van je toestel of",
                answerLine7: "Klik op het deelicoon.",
                answerLine8: "Voeg een sneltoets toe aan het startscherm (menuopties verschillen per type toestel).",
            }, faqItem14: {
                question: "Hoe zit het met mijn privacy?",
                answer: "Jouw privacy staat bij bizzerd voorop. Jij bepaalt zelf welke gegevens op jouw digitale visitekaartje komen. En bizzerd garandeert dat er met jouw gegevens niets wordt gedaan. In privacy statement lees je daar meer over. bizzerd werkt voor diverse grote (internationale) organisaties. Onze informatiebeveiliging en privacyborging (AVG, GDPR) voldoet aan de eisen die deze organisaties stellen en wordt regelmatig getoetst. bizzerd voldoet aan alle richtlijnen van de Autoriteit Persoonsgegevens."
            }, faqItem15: {
                question: "Wat moet ik doen als ik geen bizzerd meer wil?",
                answer: "Wil je geen digitaal visitekaartje meer gebruiken, stuur ons dan een e-mail. We verwijderen dan je digitale visitekaartje. Let op: gebruik het e-mailadres dat bij ons bekend is."
            }, faqItem16: {
                question: "Wat is het verschil tussen Bizzerd en LinkedIn?",
                answer: "Bizzerd is jouw digitale visitekaartje. Je kunt jouw digitale visitekaartje delen met iedereen die je (online en offline) ontmoet. Net als bij een papieren visitekaartje, maar dan makkelijker, voordeliger en met meer functies. LinkedIn is een zakelijk online netwerk. Op je LinkedIn-pagina zet je meestal maar beperkte contactgegevens. Met bizzerd deel je alle (contact)gegevens die je wilt delen."
            }, faqItem17: {
                question: "Wat zijn de mogelijkheden om bizzerd als organisatie te gebruiken?",
                answer: "Bizzerd biedt zeer veel mogelijkheden voor organisaties. In een handomdraai kun je met bizzerd alle medewerkers tegelijk van een digitaal visitekaartje voorzien. In de huisstijl. En tegen zeer lage kosten. De contactgegevens kunnen gebruikers zelf beheren. En het is mogelijk om de contactgegevens centraal te beheren.\n" +
                    "\n" +
                    "Interessant is de mogelijkheid om bijvoorbeeld prijslijsten, brochures, offertes, presentaties, etc. te koppelen aan bizzerd. Daarmee is bizzerd de handigste (re)presentatietool op beurzen en tentoonstellingen."
            }, faqItem18: {
                question: "Ik wil bizzerd voor mijn organisatie gebruiken voor alle medewerkers. Wat moet ik doen?",
                answer: "Neem contact met ons op. In 2 stappen voeren we dan bizzerd in: 1) Ontwerpen van het design, passend in de huisstijl. 2) Invoeren gegevens van alle gebruikers. Bizzerd is dan klaar voor gebruik, iedere medewerker heeft dan een eigen digitaal visitekaartje in de huisstijl van de organisatie."
            }, faqItem19: {
                question: "Hoe bewaak ik de huisstijl en de contactgegevens?",
                answer: "Gebruik je bizzerd voor alle medewerkers, dan worden alle digitale visitekaartjes automatisch opgemaakt in de huisstijl van jouw organisatie. Jouw ontwerp- of reclamebureau maakt het design. Wij kunnen dat ook voor je verzorgen.\n" +
                    "\n" +
                    "De contactgegevens worden centraal bijgehouden door een beheerder binnen jouw organisatie. Daardoor hebben alle medewerkers een correct digitaal visitekaartje.\n" +
                    "\n" +
                    "Je kunt er ook voor kiezen om alle gebruikers binnen jouw organisatie zelf hun bizzerd te laten beheren."
            }, faqItem20: {
                question: "Hoe zijn de tarieven van bizzerd opgebouwd?",
                answer: "bizzerd hanteert prijzen voor zzp’ers, voor MKB en voor grote organisatie. Als zzp’er betaal je 79 cent per maand. Je kunt maandelijks opzeggen. Voor MKB lopen de tarieven uiteen van 79 cent tot 49 cent per gebruiker per maand, plus inrichtingskosten. Voor grote organisaties brengen we een offerte op maat uit. Meer informatie vind je op onze tarievenpagina."
            }, faqItem21: {
                question: "Hoe werkt de koppeling met AFAS Software?",
                answer: "Gebruikers van AFAS Software voor HR kunnen bizzerd koppelen. Met deze koppeling kunnen ze automatisch visitekaartjes aanmaken voor nieuwe medewerkers die in AFAS worden ingevoerd. Ander voordeel is dat de informatie op elk visitekaartje automatiscsh wordt geüpdatet als gegevens veranderen, zoals functie, achternaam, locatiegegevens, telefoonnummer, etc. Ook bij een nieuwe huisstijl of bij grafische designaanpassingen worden alle digitale visitekaartjes in 1 keer automatisch aangepast."
            },

        }


    },
    headerHome: {
        standard: "Standaard",
        maximum: "maximum",
        fromPrice: "prijs van: ",
        ppm: " p.p.p.m.",
        title: "Het digitale visitekaartje",
        // title: "Digital, paperless business card",
        // subtitle: "Contacless. Practical. Sustainable.",
        subtitle: "Honderdduizenden gebruiken Bizzerd al. Van zzp'ers tot multinationals.",
        button: "Start hier",
    },
    footer: {
        seoTitle: "Waarom kiezen voor het meest gebruikte digitale, papierloze visitekaartje?",
        seoContent: "Bizzerd is het digitale, papierloze visitekaartje. Bizzerd is eenvoudig: makkelijk te gebruiken. Bizzerd is praktisch: je hebt altijd je visitekaartjes bij je. Bizzerd is duurzaam: geen onnodige papierverspilling. Met digitale visitekaartjes van bizzerd ben je altijd up-to-date. En je hebt een onbeperkte voorraad visitekaartjes! Je zit dus nooit meer zonder visitekaartjes op momenten dat het ertoe doet!",
        bizzerd: {
            title: "Bizzerd",
            home: "Home",
            businessCards: "Visitekaartjes",
            product: "Hoe werkt het",
            contact: "Contact",
            pricing: "Prijzen"
        },
        service: {
            title: "Service",
            toc: "Algemene voorwaarden",
            privacyStatement: "Privacy statement",
            appManual: "Handleiding app",
            appManualLink: "appHandleiding.pdf",
            webManual: "Handleiding web",
            userTips: "Gebruikertips",
            userTipsLink: "/bizzerdGebruikerstips.pdf"
        }, socialMedia: {
            title: "Social media",
            linkedin: "LinkedIn",
            whatsapp: "Whatsapp",
            facebook: "Facebook",
        }, contactInfo: {
            phoneTitle: "Telefoonnummer",
            phoneNumber: "(0031)(0)88 - 227 22 22",
            officeTitle: "Bizzerd kantoor",
            officeLocation1: "Rembrandtlaan 24",
            officeLocation2: "3723 BJ Bilthoven",
            officeLocation3: "Nederland",
        }, kvkBtw: {
            title: "Kamer van Koophandel / BTW (Nederland)",
            content1: "Kamer van Koophandel",
            content2: "KvK 63663449",
            content3: "VAT NL 855341555 B01",
        }
    },
    bizzerdCommunity: {
        bizzerdClimate: {
            title: "Zo draag je met bizzerd bij aan milieu en klimaat",
            content: "Wist je dat er wereldwijd jaarlijks ruim 10 miljard visitekaartjes worden gedrukt? Dat is zo’n 27 miljoen per dag. Bijna 90 procent van die kaartjes verdwijnt binnen een week in de prullenbak. De rest na iets langere tijd. Al die miljarden stukjes bedrukt papier bij elkaar drukken belasten de natuur, het milieu en het klimaat. Er wordt bos voor gekapt. Het drukwerk is belastend. En het transport zorgt voor uitstoot. Kies voor duurzaam; papierloos. Kies bizzerd.",
        },
        bizzerdEaseOfUse: {
            title: "Het gemak van een digitaal visitekaartje",
            content: "Gemak voor jou: je hoeft nooit meer een nieuw visitekaartje te laten drukken als bijvoorbeeld je adres wijzigt. Gemak voor de ontvanger: een ontvangen bizzerd voeg je in 1 klik toe aan je Contacten.",
        }
    },
    home: {
        headerTitle: 'Digital, paperless business card',
        headerSubTitle: 'Hundreds of thousands are already using Bizzerd. From freelancers to multinationals.',
        headerButton: 'Start hier',
        actionButtonLook: 'Bekijk de ontwerpen',
        actionButtonUpload: 'Upload of maak je eigen ontwerp',
        actionButtonDesign: 'Laat ons jouw card ontwerpen',
        popularDesignsTitle: 'Populaire Designs',
        popularDesignsButton: 'Kies een ontwerp en begin!',
        youtubeTitle: 'Zo eenvoudig is het!',
        counterMade: 'Aangemaakt',
        counterUsers: 'Gebruikers',
        counterAverageGrade: 'Gemiddeld cijfer',
    },
    popularDesigns: {
        title: "Populaire Designs",
        button: "Kies een ontwerp en begin!",
        from: "vanaf:",
        fields: {
            firstname: 'Voornaam',
            lastname: 'Achternaam',
            fullname: "Voornaam Achternaam",
            jobtitle: 'Functietitel',
            tel: '+31 (0)88 123 45 67',
            mobile: "+316 123 45678",
            email: 'voornaam@bizzerd.com',
            adresveld: 'Plaatsnaam: Straatnaam huisnummer',
            website: 'https://www.bizzerd.com/'
        },
        card1: {
            title: "Huisarts",
            subTitle: "Digitaal visitekaartjes"
        }, card2: {
            title: "Kunstgalerie",
            subTitle: "Digitaal visitekaartjes"
        }, card3: {
            title: "Dierenarts",
            subTitle: "Digitaal visitekaartjes"
        }
    },
    actionButtons: {
        title1: "Bekijk de ontwerpen",
        title2: "Upload je eigen ontwerp",
        title3: "Laat ons jouw card ontwerpen"
    },
    ourCustomers: {
        card: "Altijd je visitekaartje bij de hand",
        qr: "Gemakkelijk delen via QR, airdrop en web",
        library: "Je collega\'s en ingescande visitekaartjes handig in de bibliotheek",
        scanner: "Papieren vistekaartjes inscannen en je digitale visitekaartje terugsturen",
    },
    pricing: {
        title: "Prijs",
        subtitle: "Bepaal zelf wat je betaalt. Alle prijzen zijn excl. btw.",
        pppm: "Per jaar, per gebruiker",
        mostChosen: "Meest gekozen",
        year: "Jaar",
        discountOf: "Korting van ",
        yearly: "per jaar",
        yearlyCapital: "Per jaar",
        yearly2: "Jaarlijkse",
        pay: "facturatie",
        multiple: "Meerdere",
        designs: "ontwerpen",
        numberOf: "Aantal gebruikers",
        users: "onbeperkt",
        central: "Centraal",
        control: "beheer",
        payYearly: "Jaarlijkse facturatie",
        support: "Support",
        and: "en",
        contactSales: "Contact met sales",
        support2: "ondersteuning",
        total: "jaar totaal",
        start: "Start nu",
        allOptions: "Volledig prijzen schema",
        sellingPoint1: "Keuze uit 100+ ontwerpen",
        sellingPoint2: "Support en ondersteuning",
        sub1: "Even aankijken",
        sub2: "Gewoon gebruiken",
        sub3: "Langer gebruiken",
        enterprise: "Op maat",
        year1: "1 Jaar",
        year2: "2 Jaar",
        year3: "3 Jaar",
        yearenterprise: "Vanaf 1 jaar",
        invoice1: "1 factuur voor 1 jaar",
        invoice2: "1 factuur voor 2 jaar",
        invoice3: "1 factuur voor 3 jaar",
        invoiceenterprise: "1 factuur vanaf 1 jaar",

    },
    businessCards: {
        title: "Visitekaartjes",
        results: "Resultaten",
        card: "Kaart",
        branches: {
            branch1: "Horeca",
            branch2: "Vastgoed",
            branch3: "Gezondheid en maatschappelijke diensten",
            branch4: "Detailhandel en verkoop",
            branch5: "Reizen en accommodaties",
            branch6: "Overheid en politiek",
            branch7: "Dieren en dierenverzorging",
            branch8: "Financieel en verzekering",
            branch9: "Informatietechnologie",
            branch10: "Religieus en spiritueel",
            branch11: "Zakelijke diensten",
            branch12: "Bouw, renovatie en reparatie",
            branch13: "Landbouw",
            branch14: "Onderwijs en kinderopvang",
            branch15: "Beauty",
            branch16: "Productie",
            branch17: "Marketing en communicatie",
            branch18: "Sport en fitness",
            branch19: "Auto en transport",
            branch20: "Kunst en entertainment",
        },
        showMore: "Meer weergeven",
        showLess: "Minder weergeven",
        image: "Afbeelding",
        mostChosen: "Meest gekozen",
        newlyAdded: "Nieuw toegevoegd",
        bigPicture: "Grote profielfoto",
        smallPicture: "Kleine profielfoto",
        noPicture: "Geen profielfoto"
    },
    detail: {
        title: "Ontwerp",
        year: "Jaar",
        start: "Start hier",
        priceInfo: "Prijsinformatie bij hetzelfde ontwerp",
        duration: "Duratie",
        price: "Prijs",
        discount: "Korting",
        descriptionTitle: "Omschrijving",
        description: "Digitaal visitekaartje | bizzerd | ontwerp",

    },
    editor: {
        title: "Maak je kaartje in 3 stappen",
        subtitle: "Vul de gegevens in die op jouw visitekaartje komen te staan.",
        step: "Stap",
        step1Title: " Jouw contactgegevens",
        step2Title: " Buttons",
        step3Title: " Kleuren en logo",
        toStep: "Naar stap",
        profilePicture: "Profielfoto",
        logoPosition: "Logo positie",
        iconColor: "Icoon kleur",
        textColor: "Tekst kleur",
        logoSize: "Logo grootte",
        left: "Links",
        middle: "Midden",
        right: "Rechts",
        next: "Volgende",
        upsale1: "Wil je iets anders? Klik dan",
        upsale2: "hier",
        upsale3: "en neem contact met ons op",
        fields: {
            firstname: "Voornaam",
            lastname: "Achternaam",
            fullname: "Volledige naam",
            email: "E-mail",
            mobile: "Mobiel",
            tel: "Telefoon",
            adres: "Adres",
            company: "Bedrijfsnaam",
            title: "Functie",
            facebook: "Facebook",
            pinterest: "Pinterest",
            twitter: "Twitter",
            instagram: "Instagram",
            linkedin: "LinkedIn",
            youtube: "Youtube",
            website: "Website",
            profilePic: "Pasfoto",
            bizzerd: "Ontwerp",
            bizzerdName: "Bizzerdnaam"
        }
    },
    subscription: {
        title: "Kies je abonnement",
        subtitle: "Je kunt de termijn altijd verlengen",
        next: "Volgende",
        package1: {
            title: "Even aankijken",
            subtitle: "Één jaar",
        },
        package2: {
            title: "Gewoon gebruiken",
            subtitle: "Twee jaar",
        },
        package3: {
            title: "Langer gebruiken",
            subtitle: "Drie jaar",
        },
        mostChosen: "Meest gekozen",
        pppm: "Per persoon, per maand ex BTW",
        pjeb: "per jaar ex BTW",
    },
    register: {
        title: "Registreren",
        companyInfo: "Bedrijf gegevens",
        companyName: "Bedrijfsnaam",
        address: "Factuur adres",
        accountInfo: "Account gegevens",
        email: "E-mail",
        password: "Wachtwoord",
        showPassword: "Wachtwoord tonen",
        register: "Registreren",
        dutch: "Nederlands",
        english: "Engels",
        mainLanguage: "Voertaal"
    },
    login: {
        sso: "Login SSO",
        title: "Inloggen",
        opMaat: "Op Maat",
        email: "E-mail",
        password: "Wachtwoord",
        logIn: "Inloggen",
        forgotPassword: "Wachtwoord vergeten",
        showPassword: "Toon wachtwoord",
        noAccount: "Heb je nog geen account? Kies dan hier een kaartje!",
    },
    companySettings: {
        cancel: "Annuleren",
        view: "Downloaden",
        editCompanyName: "Wijzig je bedrijfsnaam of je domeinnaam",
        companyName: "Bedrijfsnaam",
        domain: "Domeinnaam",
        deleteCompanyTitle: "Verwijder je bedrijf",
        deleteCompanySubtitle: "Als je bedrijf verwijderd is, kunnen wij het niet meer terugdraaien. Wees er dus zeker van dat je dit wilt.",
        deleteCompany: "Bedrijf verwijderen",
        viewInvoices: "Bekijk je facturen",
        ownDomain: "Eigen domeinnaam",
        requestSend: "Er is een aanvraag verstuurd",
        requestAlreadySend: "Er is al een aanvraag verstuurd",
        request: "Aanvragen",
        edit: "Wijzigen",
        save: "Opslaan",

    },
    invoice: {
        invoice: "factuur",
        year1: "1 Jaar bizzerd",
        year2: "2 Jaar bizzerd",
        year3: "3 Jaar bizzerd",
        invoiceCapitals: "FACTUUR",
        invoiceNumber: "Factuurnummer",
        invoiceDate: "Factuurdatum",
        destinedFor: "Betreft: Bizzerd | Het digitale visitekaartje",
        description: "Omschrijving",
        price: "Prijs",
        amount: "Aantal",
        date: "Datum",
        total: "Totaal",
        subTotal: "Subtotaal",
        priceWasPaid: "Bovenstaand bedrag is afgeschreven.",
        getInvoices: "Facturen ophalen",

    },
    subscriptionSettings: {
        active: "Actief",
        inactive: "Inactief",
        year1: "1 jaar",
        year2: "2 jaar",
        year3: "3 jaar",
        subscriptionOverview: "Abonnementen overzicht",
        year: "Jaren",
        amount: "Aantal",
        available: "Beschikbaar",
        used: "Gebruikt",
        firstname: "Voornaam",
        lastname: "Achternaam",
        purchaseSubscriptions: "Abonnementen aanschaffen",
        subscriptionShortage: {
            part1: "Je komt ",
            part2: " abonnement(en) te kort. Voeg hieronder abonnementen toe!",
            part3: "Je hebt nog ",
            part4: " abonnement(en) over",
        },
        singleYear: "1 Jarig Abonnement",
        doubleYear: "2 Jarig Abonnement",
        tripleYear: "3 Jarig Abonnement",
        subscriptionAfterPurchase: {
            part1: "Je komt na de betaling nog ",
            part2: " abonnement(en) te kort.",
            part3: "Je houdt na de betaling ",
            part4: " abonnement(en) over",
        },
        perYear: "Aantal jaar",
        monthPrice: "Maand prijs",
        totalPrice: "Totaal prijs",
        total: "Totaal",
        pay: "Afrekenen"

    },
    chooseDesigns: {
        title: "Klik op een ontwerp om te wijzigen.",
        retrievingCards: "Visitekaartjes ophalen.",
        orClickHere: "Of klik hier om een nieuw ontwerp te maken",
        noDesignsFound: "Het lijkt erop dat je nog geen ontwerpen hebt gemaakt.",
        createNewDesign: "Nieuw ontwerp maken",
        fields: {
            firstname: "Voornaam",
            lastname: "Achternaam",
            fullname: "Voornaam Achternaam",
            email: "E-mail",
            mobile: "Mobiel",
            tel: "Telefoon",
            adres: "Adres",
            company: "Bedrijfsnaam",
            title: "Functie",
            facebook: "Facebook",
            twitter: "Twitter",
            instagram: "Instagram",
            linkedin: "LinkedIn",
            youtube: "Youtube",
            website: "Website",
            bizzerdName: "Bizzerdnaam"
        },

    },
    chooseTemplate: {
        title: "Klik op een ontwerp om te wijzigen.",
        next: "Volgende",
        previous: "Vorige",
        fields: {
            firstname: "Voornaam",
            lastname: "Achternaam",
            fullname: "Voornaam Achternaam",

            email: "E-mail",
            mobile: "Mobiel",
            tel: "Telefoon",
            adres: "Adres",
            company: "Bedrijfsnaam",
            title: "Functie",
            facebook: "Facebook",
            twitter: "Twitter",
            instagram: "Instagram",
            linkedin: "LinkedIn",
            youtube: "Youtube",
            website: "Website",
            bizzerdName: "Bizzerdnaam"
        },

    },
    designTool: {
        fields: {
            firstname: "Voornaam",
            lastname: "Achternaam",
            fullname: "Voornaam Achternaam",
            prefix: "Voorvoegsel",
            appendix: "Bijvoegsel",
            email: "E-mail",
            mobile: "Mobiel",
            Mobile: "Mobiel",
            tel: "Telefoon",
            adres: "Adres",
            company: "Bedrijfsnaam",
            title: "Functie",
            jobtitle: "Functie",
            facebook: "Facebook",
            twitter: "Twitter",
            instagram: "Instagram",
            linkedin: "LinkedIn",
            youtube: "Youtube",
            website: "Website",
            bizzerdName: "Bizzerdnaam",
            companyname: "Bedrijfsnaam",
            quote: "Persoonlijke quote",
            Delen: "Delen",
            Opslaan: "Opslaan",
            Tel: "Telefoon",
            Deel: "Delen",
            adresveld: "Adresveld",
        },
        objectFit: {
            fill: "Gevuld",
            cover: "Bedekt",
            contain: "Passend",
        },
        submit: "Zoeken",
        save: "Opslaan",
        saved: "Opgeslagen",
        notSaved: "Niet opgeslagen",
        type: "Type",
        position: "Positie",
        duplicate: "Dupliceren",
        share: "Delen",
        profilePicture: "Profiel foto",
        toBack: "Naar achteren",
        toFront: "Naar voren"
    },
    addColleague: {
        title: "Handmatig toevoegen",
        subtitle: "Wist je dat? Je altijd meer collega's kan uitnodigen of handmatig kan toevoegen",
        colleague: "Collega",
        complete: "Compleet",
        added: "Toegevoegd",
        update: "Updaten",
        add: "Toevoegen",
        extraColleague: "Extra collega toevoegen",
        toShoppingCart: "Naar winkelwagen",
    },
    cookieConsentButton: {
        title: "Cookies",
        subtitle: "Voorkeur instellingen",
        description: "Welkom bij Bizzerd! We zijn blij dat je er bent en willen dat je weet dat we je privacy respecteren en je het recht hebt om te bepalen hoe we je persoonlijke gegevens verzamelen, gebruiken en delen. Hieronder vindt u de doeleinden waarvoor we uw gegevens verwerken - geef aan of u instemt met dergelijke verwerking. Lees ons Privacybeleid voor meer informatie over onze",
        allowAll: "Alles toestaan",
        necessaryCookie: "Strikt noodzakelijk cookies",
        performanceCookie: "Prestatiecookies",
        functionalCookie: "Functionelecookies",
        targetGroupCookie: "Doelgroepgerichte cookies",
        saveChoices: "Bevestig keuzes",
    }
}

import React from 'react'
import GrayContainer from "../common2/containers/gray.container";
import NavigationHeader from "../common2/headers/navigation.header";
import PageTitle from "../common2/fields/text/pagetitle.text";
import SubSubTitle from "../common2/fields/text/subsubtitle.text";
import GradientContainer from "../common2/containers/gradient.container";
import PricingFlow from "../common2/flow/pricing.flow";
import Footer from "../common2/footers/footer";
// @ts-ignore
import {default as Blue} from "../../assets/containers/blue.container.svg"
import IconsBlock from "../common2/blocks/icons.block";
import {translate} from "react-i18nify";
import SubTitle from "../common2/fields/text/subtitle.text";
import {Link} from "react-router-dom";
import EnterpriseHeader from "../common2/headers/enterprise.header";
import PricingHeader from "../common2/headers/pricing.header";

interface pageProps {
    width: number
}

class PricingPage extends React.Component<pageProps, {}> {
    render() {
        return (<>
                <GrayContainer style={{backgroundImage: "linear-gradient(0deg, rgba(255, 255, 255, 0.063) 57%, rgba(66, 128, 150, 0.125) 76%)", borderRadius: 0}}>
                    <NavigationHeader width={this.props.width}/>
                    <PricingHeader width={this.props.width}/>
                </GrayContainer>

                <Footer/></>
        )
            ;
    }
}

export default PricingPage
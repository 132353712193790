import RoundBizzerdContainer from "../containers/round.bizzerd.container";
import PageTitle from "../fields/text/pagetitle.text";
import MainButton from "../buttons/action_main/main.button";
import ButtonColors from "../buttons/button.colors";
import SubSubTitle from "../fields/text/subsubtitle.text";
import React from "react";
import {translate} from "react-i18nify";
import {connect} from "react-redux";
import {getTemplateById} from "../../../actions/templateActions"
import {Product} from "schema-dts";
import {JsonLd} from "react-schemaorg";


interface detailProps {
    width: number,
    templateId: string,
    getTemplateById: (templateId) => void,
    templates: any,
}

interface detailState {
    template: any
}

class DetailFlow extends React.Component<detailProps, detailState> {

    constructor(props) {
        super(props);
        this.state = {
            template: {},
        }
        this.props.getTemplateById(this.props.templateId);
    }

    componentWillReceiveProps(nextProps: Readonly<detailProps>, nextContext: any) {
        if (nextProps.templates && nextProps.templates.template) {
            // if (nextProps.templates.template.shape) {
            //     nextProps.templates.template.shape = nextProps.templates.template.shape.map(shape => {
            //         if (shape.type === "photo") {
            //             shape.objectFit = "cover"
            //             if (nextProps.templates.template.text) {
            //                 nextProps.templates.template.text = nextProps.templates.template.text.map(text => {
            //                     if ((text.type === "fullname" || text.type === "jobtitle") && text.left > shape.left && text.left < shape.width && text.top > shape.top && text.top < shape.height) {
            //                         text.color = "#FFF"
            //                     }
            //
            //                     return text
            //                 })
            //             }
            //         }
            //         return shape
            //     })
            //
            // }
            let temp_ids: any = sessionStorage.getItem('template_ids')
            if (temp_ids) {
                temp_ids = JSON.parse(temp_ids)
                if (Array.isArray(temp_ids)) {
                    if (!temp_ids.includes(nextProps.templates.template._id))
                        temp_ids.push(nextProps.templates.template._id)
                }
            } else {
                temp_ids = [nextProps.templates.template._id]
            }
            sessionStorage.setItem("template_ids", JSON.stringify(temp_ids))
            this.setState({template: nextProps.templates.template})
        }
    }

    render() {
        let product;


        let personContent = {
            firstname: translate("chooseDesigns.fields.firstname"),
            pasfoto: "https://res.cloudinary.com/bizzerd-b-v/image/upload/v1680172291/dt3sixmvarf6ht35qpoi.jpg",
            lastname: translate("chooseDesigns.fields.lastname"),
            fullname: translate("chooseDesigns.fields.fullname"),
            jobtitle: translate("chooseDesigns.fields.title"),
            tel: translate("chooseDesigns.fields.tel"),
            mobile: translate("chooseDesigns.fields.mobile"),
            email: translate("chooseDesigns.fields.email"),
            adresveld: translate("chooseDesigns.fields.adres"),
            website: translate("chooseDesigns.fields.website")
        }
        let description = "Hét 100% digitaal visitekaartje\n\n"
        let designSpecifications: any = [];
        if (this.state.template && this.state.template.text) {
            designSpecifications.push(<span>{translate("hd.detail.designContent.textTitle")}</span>)
            description += translate("hd.detail.designContent.textTitle") + "\n"
            designSpecifications.push(<br/>)
            this.state.template.text.forEach((text, i) => {

                designSpecifications.push(translate("hd.detail.designContent.text." + text.type))
                description += translate("hd.detail.designContent.text." + text.type)
                if (i !== this.state.template.text.length - 1) {
                    designSpecifications.push(" - ")
                    description += " - "
                }
            })
        }
        designSpecifications.push(<br/>)
        designSpecifications.push(<br/>)
        description += "\n\n"
        if (this.state.template && this.state.template.icon) {
            designSpecifications.push(<span>{translate("hd.detail.designContent.iconTitle")}</span>)
            description += translate("hd.detail.designContent.iconTitle") + "\n"
            designSpecifications.push(<br/>)
            this.state.template.icon.forEach((icon, i) => {

                designSpecifications.push(translate("hd.detail.designContent.icon." + icon.type))
                description += translate("hd.detail.designContent.icon." + icon.type)
                if (i !== this.state.template.icon.length - 1) {
                    designSpecifications.push(" - ")
                    description += " - "
                }
            })
        }
        designSpecifications.push(<br/>)
        description += "\n"

        if (this.state.template && this.state.template.productNumber) {
            product = {
                "@type": "Product",
                "@context": "https://schema.org",
                "image": "https://bizzerdcard.com/templateProduct/resized_" + this.state.template._id + ".png",
                "url": "https://bizzerd.com/detail/" + this.state.template._id,
                "name": "Digitaal visitekaartje" + this.state.template.productNumber,
                "description": description,
                "price": 11.47,
                "priceCurrency": "EUR",
                "offers": {
                    "@type": "Offer",
                    "availability": "https://schema.org/InStock",
                    "price": 11.47,
                    "priceCurrency": "EUR"
                },
                "brand": "Bizzerd"

            }

        }


        let detailPage = (
            <div className={"detail-container"}>
                <div className={"detail-left"}>
                    <RoundBizzerdContainer steps={[300, 250, 200, 150]} width={this.props.width}
                                           personContent={personContent}
                                           bizzerdLayout={this.state.template}/>
                </div>
                <div className={"detail-right"}>
                    <PageTitle style={{paddingBottom: "20px"}} text={translate("hd.detail.pageTitle")}/>
                    <span className={"detail-price"}>€0.79 {translate("hd.detail.pppm")}</span>
                    <br/>

                    <MainButton color={ButtonColors.orange}
                                onClick={() => window.location.href = "/editor/" + this.props.templateId}
                                text={translate("hd.detail.customize")}/>
                    <br/>
                    <SubSubTitle text={"Bizzerd-tag & NFC"}/>
                    <br/>
                    <span>{translate("hd.detail.nfc")}</span>
                    <br/>

                    <SubSubTitle text={translate("hd.detail.priceSimDesign")}/>
                    <br/>

                    <table className={"price-table"}>
                        <tr key={"header"}>
                            <th key={"duration"}>{translate("hd.detail.duration")}</th>
                            <th key={"price"}>{translate("hd.detail.price")}</th>
                            <th key={"month-price"}>{translate("hd.detail.pricePm")}</th>
                            <th key={"discount"}>{translate("hd.detail.discount")}</th>
                        </tr>
                        <tr className={"uneven-row-table"} key={"1yr"}>
                            <td key={"year1"}>{translate("hd.detail.yr1")}</td>
                            <td key={"yprice1"}>€9,48</td>
                            <td key={"mprice1"}>€0.79</td>
                            <td key={"discount1"}>-</td>
                        </tr>
                        <tr key={"2yr"}>
                            <td key={"year2"}>{translate("hd.detail.yr2")}</td>
                            <td key={"yprice2"}>€16,56</td>
                            <td key={"mprice2"}>€0.69</td>
                            <td key={"discount2"}>25% {translate("hd.detail.discountSmall")}</td>
                        </tr>
                        <tr className={"uneven-row-table"} key={"3yr"}>
                            <td key={"year3"}>{translate("hd.detail.yr3")}</td>
                            <td key={"yprice3"}>€21,24</td>
                            <td key={"mprice3"}>€0.59</td>
                            <td key={"discount3"}>45% {translate("hd.detail.discountSmall")}</td>
                        </tr>
                    </table>

                    <br/>

                    <SubSubTitle text={translate("hd.detail.details")}/>
                    <br/>

                    <span>{translate("hd.detail.detailContent")}</span>
                    <br/>
                    <SubSubTitle text={translate("hd.detail.designSpecifications")}/>
                    <br/>

                    {designSpecifications}


                </div>
            </div>)

        if (this.props.width < 768) {
            detailPage = (
                <div className={"detail-container"}>
                    <div className={"detail-left"}>
                        <PageTitle style={{paddingBottom: "20px"}} text={translate("hd.detail.pageTitle")}/>
                        <span>Een aanvraag voor de Bizzerd-tag of NFC kaartje kan je toevoegen bij het winkelmandje</span>
                        <RoundBizzerdContainer style={{cursor: "none"}} steps={[300, 250, 250, 250]}
                                               width={this.props.width}
                                               personContent={personContent}
                                               bizzerdLayout={this.state.template}/>
                        <br/>

                        <span className={"detail-price"}>€0.79 {translate("hd.detail.pppm")}</span>

                        <MainButton color={ButtonColors.orange}
                                    onClick={() => window.location.href = "/editor/" + this.props.templateId}
                                    text={translate("hd.detail.customize")}/>
                        <br/>
                    </div>
                    <div className={"detail-right"}>
                        <SubSubTitle text={translate("hd.detail.priceSimDesign")}/>
                        <br/>

                        <table className={"price-table"}>
                            <tr>
                                <th key={"duration"}>{translate("hd.detail.duration")}</th>
                                <th key={"price"}>{translate("hd.detail.price")}</th>
                                <th key={"month-price"}>{translate("hd.detail.pricePm")}</th>
                                <th key={"discount"}>{translate("hd.detail.discount")}</th>
                            </tr>
                            <tr className={"uneven-row-table"}>
                                <td>{translate("hd.detail.yr1")}</td>
                                <td>€9,48</td>
                                <td>€0.79</td>
                                <td>-</td>
                            </tr>
                            <tr>
                                <td>{translate("hd.detail.yr2")}</td>
                                <td>€16,56</td>
                                <td>€0.69</td>
                                <td>25% {translate("hd.detail.discountSmall")}</td>
                            </tr>
                            <tr className={"uneven-row-table"}>
                                <td>{translate("hd.detail.yr3")}</td>
                                <td>€21,24</td>
                                <td>€0.59</td>
                                <td>45% {translate("hd.detail.discountSmall")}</td>
                            </tr>
                        </table>
                        <br/>

                        <SubSubTitle text={translate("hd.detail.details")}/>
                        <br/>

                        <span>{translate("hd.detail.detailContent")}</span>
                        <br/>
                        <SubSubTitle text={translate("hd.detail.designSpecifications")}/>

                        <br/>

                        {designSpecifications}
                    </div>
                </div>)
        }


        return <>
            {window.location.href.includes("/en/") ? <></> : <JsonLd<Product>
                item={product}/>}
            {detailPage}</>
    }
}

const mapStateToProps = (state) => ({
    templates: state.templates,
});

export default connect(mapStateToProps, {getTemplateById})(DetailFlow);
import React from "react";
import './blocks.styles.css'
import ButtonColors from "../buttons/button.colors";
// @ts-ignore
import {default as EyeIcon} from "../../../assets/icons/eye.icon.svg"
// @ts-ignore
import {default as CloudIcon} from "../../../assets/icons/cloud.icon.svg"
// @ts-ignore
import {default as DesignIcon} from "../../../assets/icons/design.icon.svg"
// @ts-ignore
import {default as CardsBackground} from "../../../assets/containers/small.container.png"
import MainButton from "../buttons/action_main/main.button";
import {translate} from "react-i18nify";
import SubSubTitle from "../fields/text/subsubtitle.text";

export interface loginBlockProps {
    width: number,
}


class UploadBlock extends React.Component<loginBlockProps, {}> {
    render() {

        return (
            <div className={"double-block-container"}>
                <div className={"upload-block"}>
                    <SubSubTitle text={translate("hd.uploadBlock.blockTitle")}/>
                    <span>{translate("hd.uploadBlock.block1Content")}</span>
                    <br/>
                    <ol>
                        <li>{translate("hd.uploadBlock.step1")} <a href={"/designSpecifications.pdf"} target={"_blank"}
                                                                   className={"text-link"}>{translate("hd.uploadBlock.designSpecification")}</a>
                        </li>
                        <li>{translate("hd.uploadBlock.step2")}</li>
                        <ul>
                            <li>{translate("hd.uploadBlock.step2a")} <b>Figma</b></li>
                            <li>{translate("hd.uploadBlock.step2b")} <b>Photoshop</b></li>
                        </ul>
                        <li>{translate("hd.uploadBlock.step3")}</li>
                        <li>{translate("hd.uploadBlock.step4")}</li>
                        <li>{translate("hd.uploadBlock.step5")} <a href={"mailto:designs@bizzerd.com"}
                                                                   className={"text-link"}>designs@bizzerd.com</a></li>

                    </ol>
                    <span>{translate("hd.uploadBlock.block1Footer")}</span>

                </div>
                <div className={"upload-block"} style={{justifyContent: "space-between"}}>
                    <div>
                        <SubSubTitle text={translate("hd.uploadBlock.blockTitle2")}/><br/>
                        <span>{translate("hd.uploadBlock.block2Content")}</span>
                    </div>
                    <MainButton style={{alignSelf: "center"}} color={ButtonColors.orange}
                                onClick={() => window.location.href = "/design/template/645e1a6983354f6f8acb1c4c"}
                                text={translate("hd.uploadBlock.block2ButtonText")}/>

                </div>
            </div>
        );
    }

}


export default UploadBlock;
import React, {Component} from "react";
import './selectaddcolleague.scss'
import {connect} from "react-redux";
import Collapsible from "react-collapsible";
import Validator from "validator";
import DisplayBizzerd from "../../../bizzerddisplay/DisplayBizzerd";
import ToolBar from "../../../Shop/common/ToolBar/ToolBar.component";
import {translate} from "react-i18nify";


interface addcolleagueState {
    selected: number
    // emails: string[],
    // names: string[],
    // oldInput: string[],
    // success: boolean,
    persons: {
        firstname: string,
        lastname: string,
        fullname: string,
        email: string,
        mobile: string,
        jobtitle: string,
        tel: string,
        paid: boolean,
        adresveld: string,
        bizzerdName: string,
        company?: string
    }[],
    page: number,
    screenWidth: number,
    company: { name: string },
    template: any

}

interface addcolleagueProps {
}

class AddColleague extends Component<addcolleagueProps, addcolleagueState> {
    constructor(props) {
        super(props)
        let bizzerd;
        let adres;
        let existingPersons = [];
        let company = JSON.parse(sessionStorage.getItem("company"))
        let shoppingCart = localStorage.getItem('shoppingCart');
        if (shoppingCart) {
            shoppingCart = JSON.parse(shoppingCart)
            if (Array.isArray(shoppingCart)) {
                bizzerd = shoppingCart[0].bizzerd;
                shoppingCart.forEach(item => existingPersons.push(item.person))
                adres = shoppingCart[0].person.adresveld;
                // existingPersons = shoppingCart[0].person
            }
        }
        this.state = {
            selected: 1,
            page: existingPersons.length,
            screenWidth: window.innerWidth,

            persons: [...existingPersons, {
                firstname: "",
                lastname: "",
                paid: false,
                fullname: "",
                email: "",
                mobile: "",
                jobtitle: "",
                tel: "",
                adresveld: adres,
                bizzerdName: ""
            }],
            company: company,
            template: bizzerd
        }
        window.addEventListener('resize', this.reportWindowSize);

    }

    reportWindowSize = () => {
        this.setState({screenWidth: window.innerWidth})
    }

    addAdditionalPerson = () => {
        // console.log("Adding additional person")
        let persons = this.state.persons

        persons.push({
            firstname: "",
            lastname: "",
            paid: false,
            fullname: "",
            email: "",
            mobile: "",
            jobtitle: "",
            tel: "",
            adresveld: persons[0].adresveld,
            bizzerdName: ""
        })
        this.setState({persons: persons, page: persons.length - 1})
    }

    createFullName = (e, index, fullname) => {
        if (e.target.name === 'firstname') {
            if (this.state.persons[index].lastname) {
                return e.target.value + " " + this.state.persons[index].lastname
            } else {
                return e.target.value
            }
        } else if (e.target.name === 'lastname') {
            return this.state.persons[index].firstname + " " + e.target.value
        } else if (e.target.name === 'fullname') {
            return e.target.value
        } else {
            return fullname
        }
    };

    createBizzerdName = (e, bizzerdname, person) => {
        if (e.target.name === 'firstname') {
            if (person.lastname) {
                return e.target.value.toLowerCase() + person.lastname.toLowerCase()
            } else {
                return e.target.value.toLowerCase()
            }
        } else if (e.target.name === 'lastname') {
            return person.firstname.toLowerCase() + e.target.value.toLowerCase()
        } else {
            return bizzerdname
        }
    };

    onChange = (index, e) => {
        const name = e.target.name
        const value = e.target.value
        let persons = this.state.persons;
        let person = persons[index];
        let fullname = this.createFullName(e, index, person.fullname);
        let bizzerdName = this.createBizzerdName(e, person.bizzerdName, person).normalize("NFD").replace(/[\u0300-\u036f]/g, "").replaceAll(" ", '');
        person[name] = value;
        person['fullname'] = fullname;
        person['bizzerdName'] = bizzerdName;
        persons[index] = person;
        this.setState({persons: persons});
    }

    render() {
        let showPerson = {
            firstname: translate("popularDesigns.fields.firstname"),
            lastname: translate("popularDesigns.fields.lastname"),
            fullname: translate("popularDesigns.fields.fullname"),
            jobtitle: translate("popularDesigns.fields.jobtitle"),
            tel: translate("popularDesigns.fields.tel"),
            mobile: translate("popularDesigns.fields.mobile"),
            email: translate("popularDesigns.fields.email"),
            adresveld: translate("popularDesigns.fields.adresveld"),
            website: translate("popularDesigns.fields.website")
        }
        for (const [key, value] of Object.entries(this.state.persons[this.state.page])) {
            showPerson[key] = value
        }
        let title = translate("addColleague.title");
        let subtitle = translate("addColleague.subtitle");
        let realWidth = 294;
        if (this.state.screenWidth > 768 && this.state.screenWidth < 1400) {
            realWidth = 244
        }
        let persons;
        if (this.state.persons) {
            persons = this.state.persons.map((person, index) => {
                let name = "Voornaam Achternaam"
                if (person.fullname && person.fullname !== "") {
                    name = person.fullname
                }
                let validated = this.validatePerson(person) ? "✓" : '✗'
                let added = '✗'
                let shoppingCartEmails
                if (localStorage.getItem("shoppingCart")) {
                    shoppingCartEmails = JSON.parse(localStorage.getItem("shoppingCart")).map(item => {
                        return item.person.email ? item.person.email : ""
                    })
                    added = shoppingCartEmails.includes(person.email) ? "✓" : '✗'
                }

                return (
                    <div><Collapsible key={index} open={index === this.state.page}
                                      className="editorCollapsible"
                                      openedClassName={'editorCollapsible'}
                                      trigger={
                                          <div className={"editorFormTrigger"}
                                               onClick={() => this.setState({page: index})} style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "space-between",
                                              paddingRight: this.state.screenWidth < 768 ? "0vw" : "2vw",
                                              fontSize: this.state.screenWidth < 768 ? "1.1em" : "1.2em"
                                          }}>
                                              <div>
                                                  <b>{translate("addColleague.colleague")} {index + 1}</b> {this.state.screenWidth < 768 ? "" : name}
                                              </div>
                                              <div>
                                                  <b>{translate("addColleague.complete")}:</b> {validated}
                                              </div>
                                              <div>
                                                  <b>{translate("addColleague.added")}:</b> {added}
                                              </div>

                                          </div>}>
                        <div className="editorCollapsibleContent">
                            <div className="editorRow">
                                <input className="editorInput" name={"firstname"}
                                       placeholder={translate("editor.fields.firstname")}
                                       onChange={e => this.onChange(index, e)}
                                       value={this.state.persons[index].firstname}/>
                                <input className="editorInput" name={"lastname"}
                                       placeholder={translate("editor.fields.lastname")}
                                       onChange={e => this.onChange(index, e)}
                                       value={this.state.persons[index].lastname}/>
                            </div>
                            <div className="editorRow">
                                <input className="editorInput" name={"email"}
                                       placeholder={translate("editor.fields.email")}
                                       onChange={e => this.onChange(index, e)}
                                       value={this.state.persons[index].email}/>
                                <input className="editorInput" placeholder={translate("editor.fields.mobile")}
                                       name={"mobile"} onChange={e => this.onChange(index, e)}
                                       value={this.state.persons[index].mobile}/>
                            </div>
                            <div className="editorRow">
                                <input className="editorInput" placeholder={translate("editor.fields.title")}
                                       name={"jobtitle"} onChange={e => this.onChange(index, e)}
                                       value={this.state.persons[index].jobtitle}/>
                                <input className="editorInput" placeholder={translate("editor.fields.tel")}
                                       name={"tel"} onChange={e => this.onChange(index, e)}
                                       value={this.state.persons[index].tel}/>
                            </div>

                            {/*<div className="editorRow">*/}
                            {/*<div className="editorColumn">*/}
                            {/*<input className="editorInput" placeholder={"Bedrijfsnaam"}*/}
                            {/*       style={{marginBottom: "1vh"}}*/}
                            {/*       name={"companyname"}*/}
                            {/*       onChange={e => this.onChange(index, e)}*/}
                            {/*       value={this.state.company.name}/>*/}
                            {/*<input className="editorInput" placeholder={"Bizzerd naam"}*/}
                            {/*       name={"bizzerdName"}*/}
                            {/*       onChange={e => this.onChange(index, e)}*/}
                            {/*       value={this.state.persons[index].bizzerdName}/>*/}
                            {/*</div>*/}
                            {/*<textarea className="editorInputArea" placeholder={"Adres"} rows={5}*/}
                            {/*          name={"adresveld"} onChange={e => this.onChange(index, e)}*/}
                            {/*          value={this.state.persons[index].adresveld}/>*/}
                            {/*</div>*/}

                            <div className={"editorButtonRowAddColleague"}>
                                {this.validatePerson(person) ? (<button
                                    className={"shoppingCartItemButtonFinishOrder"} onClick={() => {
                                    this.addToShoppingCart(index)
                                }
                                }>{shoppingCartEmails.includes(person.email) ? translate("addColleague.update") : translate("addColleague.add")}</button>) : undefined}

                            </div>

                        </div>
                    </Collapsible>

                    </div>
                )

            })

        }
        return <>
            <ToolBar/>

            <div className={"editor"}>

                <div className="subscriptionFlex">
                    <div>
                        <div className={"subscriptionHeader"}>
                            <p className={"subscriptionHeaderTitle"}>{title}</p>
                            <p className={"subscriptionHeaderSubTitle"}>{subtitle}</p>
                        </div>

                        <div className={"subscriptionItemRow"}>
                            <div className={"colleagueContainer"}>
                                {persons}
                                <div style={{width: this.state.screenWidth < 768 ? "100vw" : "60vw"}}>
                                    <a style={{
                                        width: this.state.screenWidth < 768 ? "300px" : "84vw",
                                        display: this.state.screenWidth < 768 ? "block" : "inline"
                                    }} className='addExtraColleagues' type="submit"
                                       onClick={() => {
                                           this.addAdditionalPerson()
                                       }}>+ {translate("addColleague.extraColleague")}</a>
                                </div>
                            </div>
                        </div>
                        <div className="buttonRow">

                            <input style={{width: "230px"}} className='shoppingCartItemButtonFinishOrder' type="submit"
                                   value="Naar Winkelwagen"
                                   onClick={() => window.location.href = window.location.origin + "/shoppingcart"}/>
                        </div>
                    </div>


                </div>
                <div className="editorResult">
                    {<DisplayBizzerd realWidth={realWidth} shouldBeClickable={false}
                                     bizzerdLayout={this.state.template}
                                     personContent={showPerson}/>}
                </div>
            </div>


        </>
    }

    validatePerson = (person) => {
        let valid = true;
        let personEmailCount = {}
        this.state.persons.forEach(person => {
            personEmailCount[person.email] = (personEmailCount[person.email] ? personEmailCount[person.email] : 0) + 1
        })
        if (!person.email || !Validator.isEmail(person.email) || personEmailCount[person.email] > 1) {
            valid = false;
        }
        if (!person.fullname) {
            valid = false;
        }
        if (!person.bizzerdName || (person.bizzerdName && person.bizzerdName.length < 2) || (person.bizzerdName && !/[a-z0-9]+/.test(person.bizzerdName)) || (person.bizzerdName && person.bizzerdName.indexOf(" ") !== -1)) {
            valid = false;
        }
        if (!person.bizzerdName) {
            valid = false;
        }
        if (person.website && !Validator.isURL(person.website)) {
            valid = false;
        }
        if (person.facebook && !Validator.isURL(person.facebook)) {
            valid = false;
        }
        if (person.linkedin && !Validator.isURL(person.linkedin)) {
            valid = false;
        }
        if (person.youtube && !Validator.isURL(person.youtube)) {
            valid = false;
        }
        if (person.twitter && !Validator.isURL(person.twitter)) {
            valid = false;
        }
        if (person.instagram && !Validator.isURL(person.instagram)) {
            valid = false;
        }
        if (person.pinterest && !Validator.isURL(person.pinterest)) {
            valid = false;
        }

        return valid;
    }

    private addToShoppingCart(index) {
        let person = this.state.persons[index]
        person.adresveld = this.state.persons[0].adresveld
        let shoppingCart = localStorage.getItem('shoppingCart');
        let adres;
        if (shoppingCart) {
            shoppingCart = JSON.parse(shoppingCart)
            if (Array.isArray(shoppingCart)) {
                let most_common_template = shoppingCart[0].bizzerd
                adres = shoppingCart[0].person.adresveld
                const shoppingCartItem = {
                    person: person,
                    bizzerd: most_common_template,
                    subscriptionDuration: shoppingCart[0].person.selectedSubscription,
                    uniqueDesign: false
                }
                if (shoppingCart && shoppingCart.map(item => item.person.email).includes(person.email)) {
                    shoppingCart[shoppingCart.map(item => item.person.email).indexOf(person.email)] = shoppingCartItem
                } else {
                    shoppingCart.push(shoppingCartItem)
                }
                localStorage.setItem("shoppingCart", JSON.stringify(shoppingCart))
            }
        }
        let persons = this.state.persons
        persons.push({
            firstname: "",
            paid: false,
            lastname: "",
            fullname: "",
            email: "",
            mobile: "",
            jobtitle: "",
            tel: "",
            adresveld: adres,
            bizzerdName: ""
        })
        this.setState({persons: persons, page: this.state.page + 1})
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(AddColleague);

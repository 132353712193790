import React from "react";
import '../../headers/header.styles.css'
import PageTitle from "../../fields/text/pagetitle.text";
import {translate} from "react-i18nify";
import {default as Android} from "../../../../assets/home/playstore.svg"
import {default as Apple} from "../../../../assets/home/applestore.svg"


export const ProductBlock = ({reverse = false, blockType, img, width}) => {
    let innerWidth = 70
    if (blockType === "interactiveBlock")
        innerWidth = 50
    return (<div className={"enterprise-header-container"} id={blockType}
                 style={reverse ? {flexDirection: "row-reverse"} : {}}>
        <div className={"product-block-left"}>
            <PageTitle text={translate("hd.product." + blockType + ".blockTitle")}
                       style={{
                           fontSize: width < 768 ? "30px" : "36px",
                           marginBottom: "15px",
                           lineHeight: "40px"
                       }}/>
            <span style={{whiteSpace: "pre-wrap"}}
                  className={"enterprise-header-usp-item"}>{translate("hd.product." + blockType + ".blockContent")}</span>
            {blockType === "appBlock" ? <>
                <div style={{marginTop:30}}>
                    <a href={"https://itunes.apple.com/us/app/bizzerd/id1396772300"} target={"_blank"}>
                        <img src={Apple} className={"apple-button"}/>
                    </a>
                    {width < 768 ? <br/> : <></>}
                    <a href={"https://play.google.com/store/apps/details?id=com.bizzerd"} target={"_blank"}>
                        <img src={Android} className={"android-button"}/>
                    </a>
                </div>
            </> : <></>}


        </div>
        <div className={"enterprise-header-right"}>
            <div className={"product-block-right"}>
                <img style={{width: innerWidth + "%"}} src={img}/>
            </div>
        </div>

    </div>)


}


import PageTitle from "../common2/fields/text/pagetitle.text";
import "./noeditorflow.css"
import MainButton from "../common2/buttons/action_main/main.button";
import ButtonColors from "../common2/buttons/button.colors";
import React, {useEffect, useRef, useState} from "react";
import SubSubSubTitle from "../common2/fields/text/subsubsubtitle.text";
import InputField from "../common2/fields/editable_input/text.input";
import SecondaryButton from "../common2/buttons/action_secondary/secondary.button";
import {SketchPicker} from "react-color";
import {FontFamily} from "../oldbackoffice/designtool/sidebartop/ToolbarOptions";
import {ColorInputField} from "./utils/color-input-field";
import {IconSets} from "../common/IconSets";
import DisplayBizzerd from "../bizzerddisplay/DisplayBizzerd";
import {FlowButton} from "./flow-button";
import {
    determineBackgroundColor,
    determineFont,
    determineIconColor,
    determineJobTitleColor,
    determineLogoPosition,
    determineLogoSize,
    determineShareSaveButtonColor,
    determineSocialMediaButtonColor,
    determineTitleColor,
    determineTopBackgroundColor,
    determineUserInfoTextColor
} from "./utils/determine";
import {uploadImageFlow} from "./utils/utils";
import {translate} from "react-i18nify";

export const CustomizeTemplate = ({
                                      step,
                                      setStep,
                                      person,
                                      template,
                                      setTemplate,
                                      defaultLogoWidth,
                                      defaultLogoHeight,
                                      setDefaultLogoWidth, setDefaultLogoHeight
                                  }) => {
    const [logoFolded, setLogoFolded] = useState(false)
    const [backgroundFolded, setBackgroundFolded] = useState(false)
    const [userFolded, setUserFolded] = useState(false)
    const [socialMediaFolded, setSocialMediaFolded] = useState(false)
    const [logoPosition, setLogoPosition] = useState(determineLogoPosition(template))
    const [logoSize, setLogoSize] = useState(determineLogoSize(template, defaultLogoWidth, defaultLogoHeight))
    const [topBackgroundColor, setTopBackgroundColor] = useState(determineTopBackgroundColor(template))
    const [topBackgroundColorOpen, setTopBackgroundColorOpen] = useState(false)
    const [selectedFont, setSelectedFont] = useState(determineFont(template))
    const [titleColor, setTitleColor] = useState(determineTitleColor(template))
    const [titleColorOpen, setTitleColorOpen] = useState(false)
    const [jobTitleColor, setJobTitleColor] = useState(determineJobTitleColor(template))
    const [jobTitleColorOpen, setJobTitleColorOpen] = useState(false)
    const [userInfoTextColor, setUserInfoTextColor] = useState(determineUserInfoTextColor(template))
    const [userInfoTextColorOpen, setUserInfoTextColorOpen] = useState(false)
    const [backgroundColor, setBackgroundColor] = useState(determineBackgroundColor(template))
    const [backgroundColorOpen, setBackgroundColorOpen] = useState(false)
    const [iconColor, setIconColor] = useState(determineIconColor(template))
    const [iconColorOpen, setIconColorOpen] = useState(false)
    const [socialMediaButtonColor, setSocialMediaButtonColor] = useState(determineSocialMediaButtonColor(template))
    const [socialMediaButtonColorOpen, setSocialMediaButtonColorOpen] = useState(false)
    const [shareSaveButtonColor, setShareSaveButtonColor] = useState(determineShareSaveButtonColor(template))
    const [shareSaveButtonColorOpen, setShareSaveButtonColorOpen] = useState(false)
    const [selectedIconSet, setSelectedIconSet] = useState(template.iconset)
    const fileInputRef = useRef(null);
    const fileInputRef2 = useRef(null);

    const handleButtonClick = (type) => {
        // Trigger click event on file input
        if (type === "logo")
            fileInputRef.current.click();
        else {
            fileInputRef2.current.click();
        }
    };

    const handleFileChange = (event, type) => {
        const file = event.target.files[0];
        const formData = new FormData();
        if (!file)
            return
        formData.append("file", file);
        formData.append("type", type);
        if (type === "logo") {
            setLogoSize(100)
        }
        uploadImageFlow(formData, type, template, setTemplate, logoPosition, setDefaultLogoWidth, setDefaultLogoHeight)


    };

    const logoPositions = {
        left: (image) => 40,
        center: (image) => (640 / 2) - (image.width / 2),
        right: (image) => 640 - image.width - 40

    }
    useEffect(() => {
        setTemplate({
            ...template, iconset: selectedIconSet, icon: template.icon.map(icon => {
                if (icon.type === "opslaan" || icon.type === "delen") {
                    return {...icon, backgroundColor: shareSaveButtonColor}
                } else {
                    return {...icon, backgroundColor: socialMediaButtonColor}
                }
            }), text: template.text.map(text => {
                let newText = {...text}
                if (text.type === "fullname") {
                    newText = {...newText, color: titleColor}
                } else if (text.type === "jobtitle") {
                    newText = {...newText, color: jobTitleColor}
                } else {
                    newText = {...newText, color: userInfoTextColor}
                }
                return {...newText, font: selectedFont}
            }), shape: template.shape.map(shape => {
                if (shape.left === 40) {
                    return {...shape, backgroundColor: backgroundColor}
                }
                if (shape.topBackgroundColor) {
                    return {...shape, backgroundColor: topBackgroundColor}
                }
                return shape
            }), image: template.image.map(image => {
                let newImage = {...image}
                if (image.logo) {
                    let newWidth = parseInt(defaultLogoWidth * logoSize / 100)
                    let newHeight = parseInt(defaultLogoHeight * logoSize / 100)
                    newImage = {
                        ...newImage,
                        width: newWidth,
                        height: newHeight
                    }
                    newImage = {
                        ...newImage,
                        left: logoPositions[logoPosition](newImage),

                    }
                }

                return newImage
            })

        })
    }, [selectedIconSet, shareSaveButtonColor, iconColor, selectedFont, titleColor, jobTitleColor, userInfoTextColor, socialMediaButtonColor, backgroundColor, logoPosition, logoSize, topBackgroundColor]);
    let colorPickerOpen = [
        topBackgroundColorOpen,
        shareSaveButtonColorOpen,
        jobTitleColorOpen,
        titleColorOpen,
        userInfoTextColorOpen,
        iconColorOpen,
        backgroundColorOpen,
        socialMediaButtonColorOpen
    ]
    let setColorPickerOpens = [
        setTopBackgroundColorOpen,
        setShareSaveButtonColorOpen,
        setJobTitleColorOpen,
        setTitleColorOpen,
        setUserInfoTextColorOpen,
        setIconColorOpen,
        setBackgroundColorOpen,
        setSocialMediaButtonColorOpen
    ]

    return <div className={"step-flow-item-container"}>
        <div className={"sketch-overlay"} onClick={() => {
            setColorPickerOpens.forEach(fn => fn(false))
        }} style={{display: colorPickerOpen.includes(true) ? "block" : "none"}}></div>
        <PageTitle style={{marginBottom: 0}} text={translate("hd.nef.s3.title")}/>
        <SubSubSubTitle style={{color: "#324A6D", opacity: 0.5, fontWeight: "bold"}}
                        text={translate("hd.nef.s3.subTitle")}/>
        <SubSubSubTitle style={{color: "#324A6D", opacity: 0.5, fontWeight: "normal"}}
                        text={translate("hd.nef.s3.subSubTitle")}/>
        <div className={"step-flow-horizontal"} style={{paddingBottom: 80}}>
            <div className={"step-flow-left"}>
                <div className={"foldable-input"} style={{maxHeight: logoFolded ? 50 : 400}}>
                    <div className={"foldable-title-container"} onClick={() => setLogoFolded(!logoFolded)}>
                        <span className={"foldable-title-number"}>1</span>
                        <span className={"foldable-title"}>Logo</span>
                        <svg style={{
                            position: "absolute",
                            right: 10,
                            transform: logoFolded ? "rotate(180deg)" : "",
                            transition: "all ease-in-out 0.2s"
                        }}
                             xmlns="http://www.w3.org/2000/svg"
                             width="10" height="7"
                             viewBox="0 0 10 7" fill="none">
                            <path
                                d="M1.94319 -2.31723e-08C1.12982 -1.34729e-08 0.656693 0.91937 1.12946 1.58124L4 5.6L4.18627 5.86077C4.58509 6.41912 5.41491 6.41912 5.81373 5.86077L6 5.6L8.87055 1.58124C9.34331 0.919368 8.87018 -1.05776e-07 8.05681 -9.60765e-08L1.94319 -2.31723e-08Z"
                                fill="#324A6D"/>
                        </svg>
                    </div>
                    <div className={"logo-input"}>
                        <div className={"logo-input-column"}>
                            <span className={"flow-input-header"}>Upload logo</span>
                            <div className={"upload-logo-container"}>
                                <div className={"logo-image-container"}>
                                    <img src={template.image.filter(img => img.logo)[0].image}
                                         style={{objectFit: "contain", width: 75, height: 75}}/>
                                </div>
                                <SecondaryButton color={ButtonColors.orange} text={"Upload logo"}
                                                 onClick={() => handleButtonClick("logo")}/>
                                <input
                                    type="file"
                                    accept="image/*"
                                    ref={fileInputRef}
                                    style={{display: 'none'}}
                                    onChange={(e) => handleFileChange(e, "logo")}
                                />
                            </div>
                        </div>
                        <div className={"logo-input-column"}>
                            <span className={"flow-input-header"}>{translate("hd.nef.s3.logoPosition")}</span>
                            <div className={"logo-position-container"}>
                                <SecondaryButton
                                    color={logoPosition === "left" ? ButtonColors.orange : ButtonColors.white}
                                    text={translate("hd.nef.s3.left")} onClick={() => setLogoPosition("left")}/>
                                <SecondaryButton
                                    color={logoPosition === "center" ? ButtonColors.orange : ButtonColors.white}
                                    text={translate("hd.nef.s3.center")} onClick={() => setLogoPosition("center")}/>
                                <SecondaryButton
                                    color={logoPosition === "right" ? ButtonColors.orange : ButtonColors.white}
                                    text={translate("hd.nef.s3.right")} onClick={() => setLogoPosition("right")}/>
                            </div>
                            <span className={"flow-input-header"}>{translate("hd.nef.s3.size")}</span>
                            <div className={"logo-size-container"}>
                                <input value={logoSize} onChange={(e) => setLogoSize(e.target.value)}
                                       className={"logo-size-slider"} type={"range"} min={50} max={150}/>
                                <span className={"flow-input-header"}>{logoSize}%</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"foldable-input"} style={{maxHeight: backgroundFolded ? 50 : 800}}>
                    <div className={"foldable-title-container"} onClick={() => setBackgroundFolded(!backgroundFolded)}>
                        <span className={"foldable-title-number"}>2</span>
                        <span className={"foldable-title"}>{translate("hd.nef.s3.backgroundColors")}</span>
                        <svg style={{
                            position: "absolute",
                            right: 10,
                            transform: backgroundFolded ? "rotate(180deg)" : "",
                            transition: "all ease-in-out 0.2s"
                        }}
                             xmlns="http://www.w3.org/2000/svg"
                             width="10" height="7"
                             viewBox="0 0 10 7" fill="none">
                            <path
                                d="M1.94319 -2.31723e-08C1.12982 -1.34729e-08 0.656693 0.91937 1.12946 1.58124L4 5.6L4.18627 5.86077C4.58509 6.41912 5.41491 6.41912 5.81373 5.86077L6 5.6L8.87055 1.58124C9.34331 0.919368 8.87018 -1.05776e-07 8.05681 -9.60765e-08L1.94319 -2.31723e-08Z"
                                fill="#324A6D"/>
                        </svg>
                    </div>
                    <div className={"logo-input"}>
                        <div className={"logo-input-column"}>
                            <span className={"flow-input-header"}>{translate("hd.nef.s3.topBackgroundImage")}</span>
                            <div className={"upload-logo-container"}>
                                <div className={"logo-image-container"}>
                                    <img src={template.image.filter(img => img.backgroundTop)[0].image}
                                         style={{objectFit: "contain", width: 75, height: 75}}/>
                                </div>
                                <SecondaryButton color={ButtonColors.orange} text={translate("hd.nef.s3.uploadImage")}
                                                 onClick={() => handleButtonClick("image")}/>
                                <input
                                    accept="image/*"
                                    type="file"
                                    ref={fileInputRef2}
                                    style={{display: 'none'}}
                                    onChange={(e) => handleFileChange(e, "image")}

                                />
                            </div>
                        </div>
                        <div className={"logo-input-column"}>
                            <ColorInputField open={topBackgroundColorOpen} setOpen={setTopBackgroundColorOpen}
                                             color={topBackgroundColor} setColor={setTopBackgroundColor}
                                             title={translate("hd.nef.s3.topBackgroundColor")}
                                             style={{width: "calc(100% - 20px)"}}/>
                        </div>
                    </div>

                </div>
                <div className={"foldable-input"} style={{maxHeight: userFolded ? 50 : 800, alignItems: "flex-start"}}>
                    <div className={"foldable-title-container"} onClick={() => setUserFolded(!userFolded)}>
                        <span className={"foldable-title-number"}>3</span>
                        <span className={"foldable-title"}>{translate("hd.nef.s3.userInfo")}</span>
                        <svg style={{
                            position: "absolute",
                            right: 10,
                            transform: userFolded ? "rotate(180deg)" : "",
                            transition: "all ease-in-out 0.2s"
                        }}
                             xmlns="http://www.w3.org/2000/svg"
                             width="10" height="7"
                             viewBox="0 0 10 7" fill="none">
                            <path
                                d="M1.94319 -2.31723e-08C1.12982 -1.34729e-08 0.656693 0.91937 1.12946 1.58124L4 5.6L4.18627 5.86077C4.58509 6.41912 5.41491 6.41912 5.81373 5.86077L6 5.6L8.87055 1.58124C9.34331 0.919368 8.87018 -1.05776e-07 8.05681 -9.60765e-08L1.94319 -2.31723e-08Z"
                                fill="#324A6D"/>
                        </svg>
                    </div>
                    <span className={"flow-input-header"}>{translate("hd.nef.s3.textColors")}</span>
                    <div className={"step-input-row"}>
                        <div className={"select-input-row"}>
                            <span className={"flow-input-header"}>Font</span>
                            <select className={"step-select"} onChange={(e) => setSelectedFont(e.target.value)}>
                                {FontFamily.map(font => <option key={font} value={font}
                                                                selected={font === selectedFont}>{font}</option>)}
                            </select>
                        </div>
                        <ColorInputField open={titleColorOpen} setOpen={setTitleColorOpen} color={titleColor}
                                         setColor={setTitleColor} title={translate("hd.nef.s3.titleColor")}/>
                    </div>
                    <div className={"step-input-row"}>
                        <ColorInputField open={jobTitleColorOpen} setOpen={setJobTitleColorOpen} color={jobTitleColor}
                                         setColor={setJobTitleColor} title={translate("hd.nef.s3.jobTitleColor")}/>
                        <ColorInputField open={userInfoTextColorOpen} setOpen={setUserInfoTextColorOpen}
                                         color={userInfoTextColor}
                                         setColor={setUserInfoTextColor} title={translate("hd.nef.s3.userInfoColor")}/>
                    </div>
                    <span className={"flow-input-header"}>{translate("hd.nef.s3.buttonColors")}</span>
                    <div className={"step-input-row"}>
                        <ColorInputField open={backgroundColorOpen} setOpen={setBackgroundColorOpen}
                                         color={backgroundColor}
                                         setColor={setBackgroundColor} title={translate("hd.nef.s3.backgroundColor")}/>
                        <ColorInputField open={iconColorOpen} setOpen={setIconColorOpen}
                                         color={iconColor}
                                         setColor={setIconColor} title={translate("hd.nef.s3.iconColor")}/>
                    </div>


                </div>
                <div className={"foldable-input"} style={{maxHeight: socialMediaFolded ? 50 : 800}}>
                    <div className={"foldable-title-container"}
                         onClick={() => setSocialMediaFolded(!socialMediaFolded)}>
                        <span className={"foldable-title-number"}>4</span>
                        <span className={"foldable-title"}>Social media buttons</span>
                        <svg style={{
                            position: "absolute",
                            right: 10,
                            transform: socialMediaFolded ? "rotate(180deg)" : "",
                            transition: "all ease-in-out 0.2s"
                        }}
                             xmlns="http://www.w3.org/2000/svg"
                             width="10" height="7"
                             viewBox="0 0 10 7" fill="none">
                            <path
                                d="M1.94319 -2.31723e-08C1.12982 -1.34729e-08 0.656693 0.91937 1.12946 1.58124L4 5.6L4.18627 5.86077C4.58509 6.41912 5.41491 6.41912 5.81373 5.86077L6 5.6L8.87055 1.58124C9.34331 0.919368 8.87018 -1.05776e-07 8.05681 -9.60765e-08L1.94319 -2.31723e-08Z"
                                fill="#324A6D"/>
                        </svg>
                    </div>
                    <div className={"step-input-row"}>
                        <div className={"select-input-row"}>
                            <div style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                width: "100%"
                            }}>
                                <span className={"flow-input-header"}>Icon set</span>
                                <select className={"step-select"} onChange={(e) => setSelectedIconSet(e.target.value)}
                                        value={selectedIconSet}>
                                    {Object.keys(IconSets({}, 1)).filter(iconSet => iconSet === "iconSet9" || iconSet === "iconSet10" || iconSet === "iconSet11" || iconSet === "iconSet12").map(
                                        iconSet => {
                                            let label = ""
                                            switch (iconSet) {
                                                case "iconSet9":
                                                    label = translate("hd.nef.s3.i9")
                                                    break
                                                case "iconSet10":
                                                    label = translate("hd.nef.s3.i10")
                                                    break
                                                case "iconSet11":
                                                    label = translate("hd.nef.s3.i11")
                                                    break
                                                case "iconSet12":
                                                    label = translate("hd.nef.s3.i12")
                                                    break
                                            }
                                            return <option key={iconSet} value={iconSet}
                                                           selected={iconSet === selectedFont}>{label}</option>
                                        })}
                                </select>
                            </div>
                        </div>
                        <ColorInputField open={socialMediaButtonColorOpen} setOpen={setSocialMediaButtonColorOpen}
                                         color={socialMediaButtonColor}
                                         setColor={setSocialMediaButtonColor} title={translate("hd.nef.s3.smbc")}/>
                    </div>
                    <div className={"step-input-row"}>
                        <ColorInputField open={shareSaveButtonColorOpen} setOpen={setShareSaveButtonColorOpen}
                                         color={shareSaveButtonColor}
                                         setColor={setShareSaveButtonColor} title={translate("hd.nef.s3.ssbc")}/>
                    </div>


                </div>

            </div>
            <div className={"step-flow-right"}>
                <DisplayBizzerd bizzerdLayout={template} personContent={person} realWidth={300}
                                shouldBeClickable={false}/>
                <FlowButton color={ButtonColors.orange} onClick={() => setStep(step + 1)}
                            title={translate("hd.nef.nav.nextStep")}
                            subTitle={translate("hd.nef.s4.title")}
                            icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19"
                                       fill="none">
                                <path
                                    d="M4.27441 18.494C3.52441 18.494 2.78275 18.3106 2.04941 17.944C1.31608 17.5773 0.724414 17.094 0.274414 16.494C0.707747 16.494 1.14941 16.3231 1.59941 15.9815C2.04941 15.6398 2.27441 15.144 2.27441 14.494C2.27441 13.6606 2.56608 12.9523 3.14941 12.369C3.73275 11.7856 4.44108 11.494 5.27441 11.494C6.10775 11.494 6.81608 11.7856 7.39941 12.369C7.98275 12.9523 8.27441 13.6606 8.27441 14.494C8.27441 15.594 7.88275 16.5356 7.09941 17.319C6.31608 18.1023 5.37441 18.494 4.27441 18.494ZM4.27441 16.494C4.82441 16.494 5.29525 16.2981 5.68691 15.9065C6.07858 15.5148 6.27441 15.044 6.27441 14.494C6.27441 14.2106 6.17858 13.9731 5.98691 13.7815C5.79525 13.5898 5.55775 13.494 5.27441 13.494C4.99108 13.494 4.75358 13.5898 4.56191 13.7815C4.37025 13.9731 4.27441 14.2106 4.27441 14.494C4.27441 14.8773 4.22858 15.2273 4.13691 15.544C4.04525 15.8606 3.92441 16.1606 3.77441 16.444C3.85775 16.4773 3.94108 16.494 4.02441 16.494H4.27441ZM10.0244 12.494L7.27441 9.74398L16.2244 0.793981C16.4077 0.610648 16.6369 0.514815 16.9119 0.506481C17.1869 0.498148 17.4244 0.593981 17.6244 0.793981L18.9744 2.14398C19.1744 2.34398 19.2744 2.57731 19.2744 2.84398C19.2744 3.11065 19.1744 3.34398 18.9744 3.54398L10.0244 12.494Z"
                                    fill="white"/>
                            </svg>}/>
                <FlowButton color={ButtonColors.white} onClick={() => setStep(step - 1)}
                            title={translate("hd.nef.nav.previousStep")}
                            subTitle={translate("hd.nef.s2.title")}
                            icon={<svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                                       xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M2.73633 17.8L1.88633 17.45C1.36966 17.2333 1.02383 16.8583 0.848828 16.325C0.673828 15.7917 0.702995 15.2667 0.936328 14.75L2.73633 10.85V17.8ZM6.73633 20C6.18633 20 5.7155 19.8042 5.32383 19.4125C4.93216 19.0208 4.73633 18.55 4.73633 18V12L7.38633 19.35C7.43633 19.4667 7.48633 19.5792 7.53633 19.6875C7.58633 19.7958 7.653 19.9 7.73633 20H6.73633ZM11.8863 19.9C11.353 20.1 10.8363 20.075 10.3363 19.825C9.83633 19.575 9.48633 19.1833 9.28633 18.65L4.83633 6.45001C4.63633 5.91667 4.65299 5.39584 4.88633 4.88751C5.11966 4.37917 5.503 4.03334 6.03633 3.85001L13.5863 1.10001C14.1197 0.900008 14.6363 0.925008 15.1363 1.17501C15.6363 1.42501 15.9863 1.81667 16.1863 2.35001L20.6363 14.55C20.8363 15.0833 20.8197 15.6042 20.5863 16.1125C20.353 16.6208 19.9697 16.9667 19.4363 17.15L11.8863 19.9ZM9.73633 8.00001C10.0197 8.00001 10.2572 7.90418 10.4488 7.71251C10.6405 7.52084 10.7363 7.28334 10.7363 7.00001C10.7363 6.71667 10.6405 6.47917 10.4488 6.28751C10.2572 6.09584 10.0197 6.00001 9.73633 6.00001C9.453 6.00001 9.2155 6.09584 9.02383 6.28751C8.83216 6.47917 8.73633 6.71667 8.73633 7.00001C8.73633 7.28334 8.83216 7.52084 9.02383 7.71251C9.2155 7.90418 9.453 8.00001 9.73633 8.00001ZM11.1863 18L18.7363 15.25L14.2863 3.00001L6.73633 5.75001L11.1863 18Z"
                                    fill="#324A6D"/>
                            </svg>

                            }/>

            </div>
        </div>
    </div>
}
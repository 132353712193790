import React from 'react'
import {ColorResult, SketchPicker} from 'react-color'
import './ColorPicker.scss'


interface colorPickerProps {
    color: string,
    idList?: string,
    group?: string,
    onChange: (color: string, name: string, startingColor?: string) => void,
    name: string,
    label: string
}

interface colorPickerState {
    displayColorPicker: boolean,
}

class ColorPicker extends React.Component<colorPickerProps, colorPickerState> {


    constructor(props: colorPickerProps) {
        super(props);
        this.state = {
            displayColorPicker: false,
        }
    }

    handleClick = (): void => {
        this.setState({displayColorPicker: !this.state.displayColorPicker})
    };

    handleClose = (): void => {
        this.setState({displayColorPicker: false})
    };

    handleChange = (color: ColorResult): void => {
        if (this.props.idList) {
            this.props.onChange(color.hex, this.props.name, this.props.idList, this.props.group);
        }
        this.props.onChange(color.hex, this.props.name);
    };

    render(): JSX.Element {
        return (
            <div className="colorPickerContainer">
                <div className="label" onClick={this.handleClick}><span>Kleur</span></div>
                <div className="swatch" style={{backgroundColor: this.props.color}} onClick={this.handleClick}>
                    <div className="color" style={{backgroundColor: this.props.color}}/>
                </div>
                {this.state.displayColorPicker ?
                    <div className="popover">
                        <div className="cover" onClick={this.handleClose}/>
                        <SketchPicker color={this.props.color} onChange={this.handleChange} disableAlpha={true}/>
                    </div> : ''}

            </div>
        )
    }
}

export default ColorPicker
import React, {useEffect, useState} from 'react';
import MainButton from "../../../common2/buttons/action_main/main.button";
import ButtonColors from "../../../common2/buttons/button.colors";
import axios from "axios";
import PageTitle from "../../../common2/fields/text/pagetitle.text";

export const NFCAnalysis = () => {
    const [logs, setLogs] = useState([]);
    const [bizzerdTagId, setBizzerdTagId] = useState("")
    const [message, setMessage] = useState("")
    const [scanStatus, setScanStatus] = useState("")
    const [firstname, setFirstname] = useState("")
    const [lastname, setLastname] = useState("")
    useEffect(() => {

        if (bizzerdTagId && bizzerdTagId.length > 0) {
            setLogs(["started getting name"])
            axios.get(`https://www.bizzerd.com/api/nfc/fromid/${bizzerdTagId}`).then((res) => {
                setLogs(["started getting name", "got name", res.data.toString()])

                setFirstname(res.data.firstname)
                setLastname(res.data.lastname)
            }).catch(err => {
            })
        }
    }, [bizzerdTagId]);
    return (
        <div style={{display: "flex", flexDirection: "column", alignItems: "center", gap: 10}}>
            <PageTitle text={"NFC Analysis"}/>
            <MainButton color={ButtonColors.orange} text={"Scan for 5 seconds!"} onClick={() => {
                const ndef = new NDEFReader();
                const abortController = new AbortController();

                ndef.scan({signal: abortController.signal}).then(() => {
                    setBizzerdTagId("")
                    setFirstname("")
                    setLastname("")
                    setMessage("")
                    setScanStatus("Scan started succesfully!")
                    setLogs([])
                    ndef.onreadingerror = () => {
                        setScanStatus("Could not read NFC try again!")
                    };
                    ndef.onreading = event => {
                        const message = event.message;
                        for (const record of message.records) {
                            let recordType = record.recordType
                            let urlData;
                            const textDecoder = new TextDecoder();
                            if (recordType === "url") {
                                urlData = textDecoder.decode(record.data)
                                if (urlData.includes("bizzerd.com/nfc/redirect")) {
                                    const parts = urlData.split("/");
                                    const lastPart = parts[parts.length - 1];
                                    setBizzerdTagId(lastPart)
                                    setMessage("The following Bizzerd NFC ID was found: " + lastPart)
                                } else {
                                    setMessage("The Bizzerd URL was not found")
                                }
                            } else {
                                setMessage("The Bizzerd URL was not found")
                            }

                        }
                    };
                    setTimeout(() => {
                        setScanStatus("Stopped scanning for NFC tags")
                        abortController.abort()
                    }, 3000)

                }).catch(error => {
                    setLogs((prev) => {
                        return [...prev, `Error! Scan failed to start: ${error}.`]
                    })
                });

            }}></MainButton>
            <MainButton color={ButtonColors.orange} text={"Write"} onClick={() => {
                const ndef = new NDEFReader();
                setMessage("Trying to write the following URL: " + "https://bizzerd.com/nfc/redirect/6538fa31a50e813c6464ffe8")

            }}/>

            <span>Scan status:{scanStatus}</span>
            {message}
            <br/>
            <br/>
            {(firstname || lastname) && <span>This NFC tag belongs to: {firstname + " " + lastname}</span>}
            <br/>
            <br/>
            {logs.map((log, index) => (
                <div key={index}>{log}</div>
            ))}
        </div>
    );
};

import React, {Component} from 'react';
import {connect} from 'react-redux';
import {getBizzerdById} from "../../actions/bizzerdActions";
import {getPersonByName} from "../../actions/personActions";
import DisplayBizzerd from '../bizzerddisplay/DisplayBizzerd';
import PropTypes from 'prop-types';
import '../oldbackoffice/scss/styles.scss'
import ShareBlock from "../common2/blocks/share.block";
import {getTranslationsByPerson} from "../../actions/translationActions";
import {getTemplateById} from "../../actions/templateActions";
import {translate} from "react-i18nify";

class ShowDemo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: '',
            bizzerd: {},
            person: {
                firstname: translate("chooseDesigns.fields.firstname"),
                pasfoto: "https://res.cloudinary.com/bizzerd-b-v/image/upload/v1680172291/dt3sixmvarf6ht35qpoi.jpg",
                lastname: translate("chooseDesigns.fields.lastname"),
                fullname: translate("chooseDesigns.fields.fullname"),
                jobtitle: translate("chooseDesigns.fields.title"),
                tel: translate("chooseDesigns.fields.tel"),
                mobile: translate("chooseDesigns.fields.mobile"),
                email: translate("chooseDesigns.fields.email"),
                adresveld: translate("chooseDesigns.fields.adres"),
                website: translate("chooseDesigns.fields.website"),
                facebook: "https://facebook.com",
                twitter: "https://twitter.com",
                linkedin: "https://linkedin.com",
                instagram: "https://instagram.com",
                vimeo: "https://vimeo.com",
            },
            translations: [],
            currentTranslation: "",
            width: 0,
            height: 0
        }
    }

    componentDidMount() {
        if (this.props.match.params.templateId !== undefined) {
            this.props.getTemplateById(this.props.match.params.templateId)
        } else {
            this.setState({
                errors: 'Persoon of bedrijf is niet gevonden'
            });
        }
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    calculateDisplayWidth = () => {
        if (this.state.bizzerd) {
            if (Object.keys(this.state.bizzerd).length === 0) {
                return 0
            } else {
                if (this.state.width < this.state.height - 300)
                    return this.state.width - 30;
                return (this.state.bizzerd.background[0].width) * this.state.height /
                    this.state.bizzerd.background[0].height - 40;
            }
        } else {
            return 0
        }
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.templates && nextProps.templates.template) {
            this.setState({bizzerd: nextProps.templates.template})
        }
    }

    updateWindowDimensions = () => {
        this.setState({width: window.visualViewport.width, height: window.visualViewport.height});
    };

    // changeTranslation = (lang, translations = undefined) => {
    //     if (!translations) {
    //         translations = this.state.translations
    //     }
    //     this.setState({currentTranslation: lang})
    //     let person = translations.filter(trans => trans.abbreviation === lang) ? translations.filter(trans => trans.abbreviation === lang)[0] : this.state.person
    //     this.props.getBizzerdById(person.bizzerd)
    // }

    render() {
        let width = this.calculateDisplayWidth();
        let page;
        let person = this.state.person
        if (this.props.match.params.page === undefined) {
            // page = <DisplayBizzerd shouldBeClickable={true} realWidth={width} bizzerdLayout={this.state.bizzerd}
            //                        personContent={person} changeTranslation={this.changeTranslation}/>
            page = <DisplayBizzerd shouldBeClickable={true} realWidth={width} bizzerdLayout={this.state.bizzerd}
                                   personContent={person} changeTranslation={this.changeTranslation}/>;
        } else if (this.props.match.params.page === 'deel') {
            page = <ShareBlock realWidth={width} bizzerdLayout={this.state.bizzerd}
                               personContent={this.state.person}/>
        }
        return (
            <div id={"backoffice"}
                 style={{
                     display: 'block',
                     width: width,
                     minHeight: "95vh",
                     // overflow: "hidden",
                     margin: this.state.width > 768 ? 'auto' : "auto",
                     paddingTop: 20,
                     backgroundColor: "#f7f7f7"
                 }}>
                {page}
            </div>
        )
    }
}

ShowDemo.propTypes = {
    getTemplateById: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    templates: state.templates,
});

export default connect(mapStateToProps, {getBizzerdById, getTemplateById})(ShowDemo);
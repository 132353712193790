import React, {Component} from 'react';
import Icon from "./bizzerdComponents/Icon";
import TextLine from "./bizzerdComponents/TextLine";
import Button from "./bizzerdComponents/Button";
import Image from "./bizzerdComponents/Image";
import isEmpty from "../../validation/is-empty";
import Shape from "./bizzerdComponents/Shape";
import BizzerdTypes from "./BizzerdTypes";

const skippableFields = ["mobile", "tel", "website", "email", "companyname", "fullname", "firstname", "lastname", "jobtitle", "adresveld", "extratext", 'quote', 'prefix', 'appendix', 'prefix_fullname', 'prefix_lastname_appendix', 'prefix_fullname_appendix', 'fullname_appendix'];

class Bizzerd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentTranslation: 0,
            translationActive: false,
        }
    }

    walkThroughTranslation = () => {
        this.setState({
            binaryTranslation: this.props.translations.length === 1
        })
        if (this.state.binaryTranslation) {
            this.setState({translationActive: !this.state.translationActive})
        } else {
            this.setState({currentTranslation: this.state.currentTranslation + 1, translationActive: true});
            if (this.props.translations.length - 1 === this.state.currentTranslation) {
                this.setState({currentTranslation: 0, translationActive: true})
            }
        }
    }

    getLink = (link, person, iconObject) => {
        let value = '';
        let dataSource = person
        if (this.state.translationActive && false) {
            dataSource = this.props.translations[this.state.currentTranslation]
        }


        if (iconObject.link && iconObject.link.includes("www.") && !iconObject.link.includes("https") && !iconObject.link.includes("http://")) {
            return window.location.origin + "/redirect?destination=https://" + iconObject.link + "&origin=" + window.location.href
        } else if (iconObject.link && iconObject.link.includes("http")) {
            return window.location.origin + "/redirect?destination=" + iconObject.link + "&origin=" + window.location.href
        } else if (iconObject.link && iconObject.link.startsWith("change_language_")) {
            return iconObject.link
        } else if (iconObject.link && iconObject.link.startsWith("mailto:") || iconObject.link && iconObject.link.startsWith("tel:")) {
            return iconObject.link
        }
        if (iconObject.link && iconObject.link.includes("extralink")) {
            return window.location.origin + "/redirect?destination=" + person[iconObject.link] + "&origin=" + window.location.href
        }
        if (link !== undefined) {
            switch (link) {

                case "email":
                    value = 'mailto:' + dataSource.email
                    return window.location.origin + "/redirect?destination=" + value + "&origin=" + window.location.href
                case "tel":
                    if (!dataSource.tel) {
                        return ""
                    }
                    value = (dataSource.tel ? 'tel:' + dataSource.tel.replace(/[^0-9.+]/g, '') : '');
                    return window.location.origin + "/redirect?destination=" + value + "&origin=" + window.location.href
                case "mobile":
                    if (!dataSource.mobile) {
                        return ""
                    }
                    value = (dataSource.mobile ? 'tel:' + dataSource.mobile.replace(/[^0-9.+]/g, '') : '');
                    return window.location.origin + "/redirect?destination=" + value + "&origin=" + window.location.href
                case "delen":
                    if (window.location.href.includes("?")){
                        return window.location.href.replace("?","/deel?")
                    }
                    return window.location.href + "/deel"
                case "opslaan":
                    return '/api/dataSource/vcard/' + person._id;
                case "facebook":
                    value = dataSource.facebook;
                    if (!value) return "";
                    return window.location.origin + "/redirect?destination=" + value + "&origin=" + window.location.href
                case "twitter":
                    value = dataSource.twitter;
                    if (!value) return "";
                    return window.location.origin + "/redirect?destination=" + value + "&origin=" + window.location.href
                case "linkedin":
                    value = dataSource.linkedin;
                    if (!value) return "";
                    return window.location.origin + "/redirect?destination=" + value + "&origin=" + window.location.href
                case "website":
                    value = dataSource.website;
                    if (value && !value.includes('http') && (!value.includes('https') && (!value.includes("www.")))) {
                        value = 'https://www.' + value;
                    }
                    if (value && value.startsWith("www."))
                        value = "https://" + value
                    if (!value) return "";
                    return window.location.origin + "/redirect?destination=" + value + "&origin=" + window.location.href
                case "instagram":
                    value = dataSource.instagram;
                    if (!value) return "";
                    return window.location.origin + "/redirect?destination=" + value + "&origin=" + window.location.href
                case "pinterest":
                    value = dataSource.pinterest;
                    if (!value) return "";
                    return window.location.origin + "/redirect?destination=" + value + "&origin=" + window.location.href
                case "vimeo":
                    value = iconObject.link;
                    if (!value) return "";
                    return window.location.origin + "/redirect?destination=" + value + "&origin=" + window.location.href
                case "googlemaps":
                    if (dataSource.adresveld)
                        return window.location.origin + "/redirect?destination=https://www.google.com/maps/search/" + dataSource.adresveld.replace("\n", "") + "&origin=" + window.location.href
                    else
                        return ""
                default:
                    if (link.startsWith("www.")) {
                        value = "https://" + link
                        return window.location.origin + "/redirect?destination=" + value + "&origin=" + window.location.href
                    }
                    skippableFields.forEach((type) => {
                        if (type === link) {
                            return '';
                        }
                    })
                    return ""

            }
        }
        return '';
    };

    getText = (text, person) => {
        let value = '';
        if (text.type === 'extratext') {
            value = text.text;
        } else if (text.type === "translation" && this.props.translations && this.props.translations.length > 0) {
            let currentTranslationId = this.props.translations[this.state.currentTranslation]._id
            for (let translationIndex in this.props.personContent.translations) {
                if (currentTranslationId === this.props.personContent.translations[translationIndex].translationId) {
                    value = this.props.personContent.translations[translationIndex].abbreviation
                }
                if (!this.state.translationActive) {
                    value = "EN"
                }
            }
        } else {
            if (this.state.translationActive) {
                if (text.type !== 'companyname') {
                    value = this.props.translations[this.state.currentTranslation][text.type]
                } else {
                    value = this.props.personContent.companyname
                }
            } else {
                value = person[text.type];
                if (text.type === "prefix_fullname") {
                    value = (person["prefix"] ? person["prefix"] : "");
                    value += " " + (person["fullname"] ? person["fullname"] : "");
                } else if (text.type === "prefix_fullname_appendix") {
                    value = (person["prefix"] ? person["prefix"] : "");
                    value += " " + (person["fullname"] ? person["fullname"] : "");
                    value += " " + (person["appendix"] ? person["appendix"] : "");
                } else if (text.type === "fullname_appendix") {
                    value = (person["fullname"] ? person["fullname"] : "");
                    value += " " + (person["appendix"] ? person["appendix"] : "");
                } else if (text.type === "prefix_lastname_appendix") {
                    value = (person["prefix"] ? person["prefix"] : "");
                    value += " " + (person["lastname"] ? person["lastname"] : "");
                    value += " " + (person["appendix"] ? person["appendix"] : "");
                }
            }
        }
        return value;
    }


    render() {
        const bizzerdStyle = {
            position: 'relative',
            backgroundSize: 'contain',
            overflow: 'hidden',
            boxShadow: this.props.shouldShowShadow ? 'rgba(0, 0, 0, 0.2) 0px 0px 20px 0px' : "",
            left: this.props.left
        };
        if (!isEmpty(this.props.bizzerdLayout) && this.props.bizzerdLayout.background.length > 0) {
            bizzerdStyle.height = this.props.resize(this.props.bizzerdLayout.background[0].height) + "px";
            bizzerdStyle.width = this.props.resize(this.props.bizzerdLayout.background[0].width) + "px";
            bizzerdStyle.backgroundColor = this.props.bizzerdLayout.background[0].backgroundColor ? this.props.bizzerdLayout.background[0].backgroundColor : "#FFFFFF";
        }

        const personIcons = this.props.bizzerdLayout[BizzerdTypes.ICON].filter(icon => {
            return this.getLink(icon.type, this.props.personContent, icon) !== '';
        });
        let icons = personIcons.map((value, index) => {

            return <Icon shouldBeClickable={this.props.shouldBeClickable} id={value.name} key={index}
                         resize={this.props.resize} layout={value}
                         link={this.getLink(value.type, this.props.personContent, value)}
                         bizzerd={this.props.bizzerdLayout} person={this.props.personContent}
                         changeTranslation={this.props.changeTranslation} delete={value.type === "delete"}
                         iconset={this.props.bizzerdLayout.iconset} onSelectField={this.props.onSelectField}/>
        });
        const personTexts = this.props.bizzerdLayout[BizzerdTypes.TEXT].filter(text => {
            // eslint-disable-next-line
            if (text.type === "prefix_fullname") {
                return this.props.personContent["fullname"] !== undefined && this.props.personContent["fullname"] !== ''
            }
            if (text.type === "prefix_fullname_appendix") {
                return this.props.personContent["fullname"] !== undefined && this.props.personContent["fullname"] !== ''
            }
            if (text.type === "fullname_appendix") {
                return this.props.personContent["fullname"] !== undefined && this.props.personContent["fullname"] !== ''
            }
            if (text.type === "prefix_lastname_appendix") {
                return this.props.personContent["fullname"] !== undefined && this.props.personContent["fullname"] !== ''
            }
            return this.props.personContent[text.type] !== undefined && this.props.personContent[text.type] !== '' || text.type === 'extratext' || text.type === "translation";
        });
        let texts = personTexts.map((value, index) => {
            if (!value.textAlign) {
                value.textAlign = "left"
            }
            return <TextLine shouldBeClickable={this.props.shouldBeClickable} id={value.name} key={index}
                             link={this.getLink(value.type, this.props.personContent, value)}
                             changeTranslation={this.props.changeTranslation}
                             resize={this.props.resize} text={this.getText(value, this.props.personContent)}
                             layout={value} onSelectField={this.props.onSelectField}
            />
        });
        let buttons = this.props.bizzerdLayout[BizzerdTypes.BUTTON].map((value, index) => {
            return <Button shouldBeClickable={this.props.shouldBeClickable} id={value.name} key={index} layout={value}
                           resize={this.props.resize}
                           link={this.getLink(value.link, this.props.personContent, value)}/>
        });
        let images = this.props.bizzerdLayout[BizzerdTypes.IMAGE].map((value, index) => {
            if (this.props.personContent) {
                if (value.link === "mobile" || value.link === "tel" || value.link === "linkedin" || value.link === "facebook" || value.link === "instagram" || value.link === "website" || value.link === "youtube" || value.link === "twitter") {
                    if (!this.getLink(value.link, this.props.personContent, value)) {
                        return <></>
                    }
                }
                if (value.link && value.link.includes("google.com/maps") && this.props.personContent.adresveld) {
                    value.link = "https://www.google.com/maps/search/" + this.props.personContent.adresveld.replace("\n", "+").replaceAll(" ", "+") + '/'
                }
            }
            return <Image recolorSvg={this.props.recolorSvg} shouldBeClickable={this.props.shouldBeClickable}
                          id={value.name} key={index} layout={value} resize={this.props.resize}
                          person={this.props.personContent} changeTranslation={this.props.changeTranslation}
                          link={this.getLink(value.link, this.props.personContent, value)}

            />
        });
        //rijks: '60f049adc644fc0017eb01b6'
        let companies_to_skip = ["64df6e372a714a0014241a37"]
        if (!companies_to_skip.includes(this.props.personContent.company)) {
            let i =
                {
                    height: 24,
                    image: "https://app.bizzerd.com/images/mobicard_i.png",
                    left: 610,
                    link: "website",
                    name: "9",
                    top: 975,
                    width: 24,
                    zindex: "99"
                }
            images.push(<Image id={'i'} key={this.props.bizzerdLayout[BizzerdTypes.IMAGE].length + 1}
                               layout={i} shouldBeClickable={this.props.shouldBeClickable}
                               resize={this.props.resize} person={this.props.personContent}
                               link={window.location.origin + "/redirect?destination=" + 'https://www.bizzerd.com' + "&origin=" + window.location.href}/>);
        } else {
            let i =
                {
                    height: 24,
                    image: "https://app.bizzerd.com/images/mobicard_i.png",
                    left: 610,
                    link: "website",
                    name: "9",
                    top: 975,
                    width: 24,
                    zindex: "99"
                }
            images.push(<Image id={'i'} key={this.props.bizzerdLayout[BizzerdTypes.IMAGE].length + 1}
                               layout={i} shouldBeClickable={this.props.shouldBeClickable}
                               resize={this.props.resize} person={this.props.personContent}
                               link={window.location.origin + "/redirect?destination=" + 'https://www.multicopy.nl/digitaal-visitekaartje' + "&origin=" + window.location.href}/>);
        }
        let shapes = this.props.bizzerdLayout[BizzerdTypes.SHAPE].map((value, index) => {
            let url = this.props.personContent[value.type]
            if (!url) {
                url = ""
            }
            if (url)
                url = window.location.origin + "/redirect?destination=" + url + "&origin=" + window.location.href;


            return <Shape id={value.name} key={index} layout={value} resize={this.props.resize}
                          link={url}
                          photo={this.props.personContent.base64pasfoto ? this.props.personContent.base64pasfoto : this.props.personContent.pasfoto}/>
        })
        return (
            <div id={'bizzerd'} key={this.props.bizzerdLayout._id} className={'card'} style={bizzerdStyle}
                 onMouseMove={this.props.onMouseMove}>
                {icons}
                {texts}
                {buttons}
                {images}
                {shapes}
            </div>
        )
    }
}

export default Bizzerd

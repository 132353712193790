import {Component} from "react";
import './checkbox.scss'

type checkboxProps = {
    checked: boolean,
    disabled: boolean ,
    name: string,
    label: string,
    className?: string,
    onChange: (checked: boolean) => void
}
type checkboxState = {
    checked: boolean
}
export class Checkbox extends Component<checkboxProps, checkboxState> {

    constructor(props) {
        super(props);
        this.state = {
            checked: props.checked,
        };
    };

    _handleChange = () => {
        this.props.onChange(!this.state.checked)
        this.setState({
            checked: !this.state.checked,
        });
    };

    render() {
        const { disabled, name, className } = this.props;
        const { checked } = this.state;
        return (
            <div className={`checkBoxFlex ${className}`}>
                <div className="React__checkbox">
                    <label className={"checkboxLabel"}>
                        <input
                            type="checkbox"
                            className="React__checkbox--input"
                            checked={checked}
                            disabled={disabled}
                            onChange={this._handleChange}
                            name={name}
                        />
                        <span
                            className="React__checkbox--span"
                        />
                    </label>
                </div>
                <label className={"checkBoxLabel"} htmlFor={name}>{this.props.label}</label>
            </div>

        );
    }
}

import React from "react";
import './container.styles.css'
// @ts-ignore
import {default as Blue} from "../../../assets/containers/blue.container.svg"

export interface containerProps {
    children?: Array<any>,
    background?: boolean,
    style?: {}
}

class IconsContainer extends React.Component<containerProps, {}> {
    render() {

        return <div className={"container-icons"}
                    style={this.props.background ? {
                        // backgroundImage: `url(${Blue})`,
                        // backgroundSize: "cover",
                        paddingTop: 0,
                        ...this.props.style
                    } : {...this.props.style}}>
            <div className={"content-wrapper"} style={{borderRadius: "0px 0px 130px 130px"}}>

                {this.props.children}
            </div>
        </div>;
    }

}

export default IconsContainer;
import React from "react";
import './blocks.styles.css'
import SecondaryButton from "../buttons/action_secondary/secondary.button";
import ButtonColors from "../buttons/button.colors";
// @ts-ignore
import {default as EyeIcon} from "../../../assets/icons/eye.icon.svg"
// @ts-ignore
import {default as CloudIcon} from "../../../assets/icons/cloud.icon.svg"
// @ts-ignore
import {default as DesignIcon} from "../../../assets/icons/design.icon.svg"
// @ts-ignore
import {default as CardsBackground} from "../../../assets/containers/small.container.png"
import SubTitle from "../fields/text/subtitle.text";
import {translate} from "react-i18nify";

export interface smallRectangleProps {
    text: string,
    buttonText: string,
    icon: string,
    background: string,
    href: string,
    width: number,
}

class SmallRectangle extends React.Component<smallRectangleProps, {}> {

    render() {
        let icon;
        let background;
        let color;
        switch (this.props.icon) {
            case "eye":
                icon = EyeIcon;
                background = CardsBackground;
                color = ButtonColors.orange;
                break
            case "cloud":
                color = ButtonColors.aqua;

                icon = DesignIcon;
                break
            case "design":

                icon = CloudIcon;
                color = ButtonColors.blue;

                break
        }
        let headerText = this.props.text;
        const finalHeaderText = <span>{headerText.split("<br/>")[0]}<br/>{headerText.split("<br/>")[1]}</span>
        if (this.props.width<768){
            return (<>
                <a className={"container-rectangle-small"} href={this.props.href}
                     style={{
                         border: "5px solid #FFF"
                     }}>
                    <div className={"container-rectangle-small-header"}>
                        <SubTitle text={finalHeaderText}
                                  style={{
                                      paddingTop: 0,
                                      marginTop: 0,
                                      marginBottom: 0,
                                      paddingBottom: 0
                                  }}/>
                        <svg style={{minWidth: 10, minHeight: 10}}
                             xmlns="http://www.w3.org/2000/svg" width="10" height="16"
                             viewBox="0 0 10 16" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd"
                                  d="M9.80757 7.90386C9.80759 7.90383 9.80759 7.90379 9.80757 7.90376L8.32568 6.42187C8.32566 6.42186 8.32564 6.42186 8.32563 6.42187C8.32561 6.42189 8.32559 6.42188 8.32557 6.42187L2.61086 0.707159C2.22034 0.316635 1.58717 0.316634 1.19665 0.707159L1.1289 0.774909C0.738375 1.16543 0.738376 1.7986 1.1289 2.18912L6.13653 7.19675C6.52705 7.58728 6.52705 8.22044 6.13653 8.61097L1.12895 13.6185C0.738428 14.0091 0.738428 14.6422 1.12895 15.0328L1.1967 15.1005C1.58723 15.491 2.22039 15.491 2.61092 15.1005L8.3256 9.38582L9.80757 7.90386Z"
                                  fill="#324A6D"/>
                        </svg>
                    </div>
                    <div className={"bottom-bar-rectangle-small " + ButtonColors[color]}/>
                </a>
            </>);
        }
        return (<>
            <div className={"container-rectangle-small"}
                 style={{
                     border: "5px solid #FFF"
                 }}>
                <div className={"container-rectangle-small-header"}>
                    <SubTitle text={finalHeaderText}
                              style={{
                                  width: "250px",
                                  paddingTop: 0,
                                  marginTop: 0,
                                  marginBottom: 0,
                                  paddingBottom: 0
                              }}/>
                </div>
                <div className={"container-rectangle-small-button"}>
                    <SecondaryButton color={color}
                                     href={this.props.href}
                                     text={this.props.buttonText}/>
                </div>
                <div className={"bottom-bar-rectangle-small " + ButtonColors[color]}/>
            </div>
        </>);
    }

}

export default SmallRectangle;

import InputField from "../../common2/fields/editable_input/text.input";
import {SketchPicker} from "react-color";
import React from "react";

export const ColorInputField = ({open, setOpen, color, setColor, title, style}) => {
    return (
        <div style={style} className={"color-input-row-container"}>
            <span className={"flow-input-header"}>{title}</span>

            <div style={{width: "100%"}}>
                <div style={{display: "flex", flexDirection: "row", gap: 10, alignItems: "center", width: "100%"}}>
                    <div className={"flow-color-block"} style={{backgroundColor: color}}
                         onClick={() => {
                             setOpen(!open)
                         }}/>
                    <InputField style={{width: "calc(100% - 50px)"}} id={"color"} label={""} placeholder={""}
                                value={color && color.length === 9 ? color.replace('#', "").substring(0,6) : color.replace('#', "")}
                                onChange={(e) => {
                                    if (e.target.value.length > 6) {
                                        return
                                    }
                                    if (!color.includes("#"))
                                        setColor("#" + e.target.value)
                                    else
                                        setColor("#" + e.target.value)
                                }
                                }/>
                </div>
                <div
                    style={{
                        display: open ? "block" : "none",
                        position: "absolute",
                        zIndex: 99999
                    }}>
                    <SketchPicker color={color}
                                  onChange={(color) => {
                                      setColor(color.hex + ((color.rgb.a * 255) | 1 << 8).toString(16).slice(1))
                                  }}
                    />
                </div>
            </div>
        </div>)
}
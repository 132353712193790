import React, {Component} from 'react';

class Button extends Component {
    render() {
        const style = {
            position: 'absolute',
            left: this.props.resize(this.props.layout.left),
            zIndex: this.props.layout.zindex,
            top: this.props.resize(this.props.layout.top),
            width: this.props.resize(this.props.layout.width),
            height: this.props.resize(this.props.layout.height)
        };
        if (this.props.layout.image !== undefined) {
            style.backgroundImage = `url(${this.props.layout.image})`;
            style.backgroundSize = 'contain';
            style.backgroundRepeat = 'no-repeat';
        } else if (this.props.layout.backgroundColor !== undefined) {
            style.backgroundColor = this.props.layout.backgroundColor;
        }
        if (this.props.layout.rotation !== undefined) {
            style.transform = "rotate(" + this.props.layout.rotation + "deg)"
        }
        let innerText;
        if (this.props !== undefined) {
            const textStyle = {
                color: this.props.layout.color,
                fontSize: this.props.resize(this.props.layout.fontSize)
            };
            innerText = (
                <p style={textStyle}>{this.props.text}</p>
            );
        }
        if (this.props.shouldBeClickable === false) {
            return (
                <div style={style}>
                    {innerText}
                </div>
            )
        }
        return (
            <a href={this.props.link} style={style}>
                {innerText}
            </a>
        )
    }
}

export default Button;
import "../blocks.styles.css"
import PageTitle from "../../fields/text/pagetitle.text";
import {translate} from "react-i18nify";

export const ProductBarBlock = () => {
    return (<div className={"product-bar"}>
        <PageTitle text={translate("hd.product.bar.title")}/>
        <span className={"product-item-bar-content"}>{translate("hd.product.bar.content1")}</span>
        <div className={"product-item-bar"}>
            <div className={"product-item"}>
                <div className={"product-item-header"}>
                    <span className={"product-item-number"}>1</span>
                    <span className={"product-item-title"}>{translate("hd.product.bar.item1.title")}</span>
                </div>
                <span>{translate("hd.product.bar.item1.content")}</span>
            </div>
            <div className={"product-item"}>
                <div className={"product-item-header"}>
                    <span className={"product-item-number"}>2</span>
                    <span className={"product-item-title"}>{translate("hd.product.bar.item2.title")}</span>
                </div>
                <span>{translate("hd.product.bar.item2.content")}</span>
            </div>
            <div className={"product-item"}>
                <div className={"product-item-header"}>
                    <span className={"product-item-number"}>3</span>
                    <span className={"product-item-title"}>{translate("hd.product.bar.item3.title")}</span>
                </div>
                <span>{translate("hd.product.bar.item3.content")}</span>
            </div>


        </div>
    </div>)
}
import React from "react";
import SubSubSubTitle from "../../fields/text/subsubsubtitle.text";
import InputField from "../../fields/editable_input/text.input";
import EditorFooter from "./footer.editor";
import {translate} from "react-i18nify";

interface editorProps {
    width: number,
    folded: boolean,
    changeSelected: (blockNr) => void,
    onChange: (e) => void,
    onChangeIcon: (e) => void,
    newDesign: boolean,
    icons: any,
    person: any

}

class ButtonEditor extends React.Component<editorProps, {}> {

    getNameFromType(type) {
        if (type[0] === "") {
            return "Website"
        }
        switch (type[1]) {
            case "linkedin":
                return "linkedIn Icon";
            case "facebook":
                return "Facebook Icon";
            case "instagram":
                return "Instagram Icon";
            case "twitter":
                return "Twitter Icon";
            case "pinterest":
                return "Pinterest Icon";
            case "tel":
                return "Telefoon Icon";
            case "website":
                return "Website Icon";
            case "vimeo":
                return "Vimeo Icon";
            default:
                return "";
        }
    }

    getNameFromType2(type) {
        switch (type) {
            case "linkedin":
                return "linkedIn Icon";
            case "facebook":
                return "Facebook Icon";
            case "instagram":
                return "Instagram Icon";
            case "twitter":
                return "Twitter Icon";
            case "pinterest":
                return "Pinterest Icon";
            case "tel":
                return "Telefoon Icon";
            case "website":
                return "Website Icon";
            case "vimeo":
                return "Vimeo Icon";
            default:
                return "";
        }
    }

    render() {
        let icons = this.props.icons;
        let iconOptions = ["linkedin", "facebook", "twitter", "instagram", "pinterest", "tel", "website", "vimeo"];
        icons = icons.filter(icon => {
            return icon[1] !== "opslaan" && icon[1] !== "delen" && icon[1] !== "email"
        })
        if (!icons.includes("website")) {
            icons.push(["", "website"])
        }

        return <div className={"editor-container"}>
            <div className={"personal-detail-outer-container"} onClick={() => {
                if (this.props.folded)
                    this.props.changeSelected(2)
            }} style={{
                maxHeight: (this.props.folded ? "70px" : "100vh"),
                cursor: (this.props.folded ? "pointer" : "")
            }}>
                <div className={"editor-header"}>
                    <span className={"pagination-ball"}>2</span>
                    <SubSubSubTitle style={{textAlign: "left"}} text={"Buttons"}/>
                </div>
                <div className={"personal-detail-inner-container"}>
                    {icons.map(icon => {
                            return <div className={"icon-picker-container"}>
                                <InputField id={icon[1]} placeholder={this.getNameFromType(icon)}
                                            label={this.getNameFromType(icon)}
                                            style={{width: this.props.width < 768 ? "100%" : "75%"}}
                                            errorMessage={""} value={this.props.person[icon[1]]}
                                            validation={""} onChange={this.props.onChange}/>
                                {!!icon[0] ? <select id={icon[0]} onChange={this.props.onChangeIcon}
                                                     style={{
                                                         border: "1px solid #428fae",
                                                         height: 30,
                                                         padding: 3,
                                                         borderRadius: 5,
                                                         width: this.props.width < 768 ? "100%" : "",
                                                         marginTop: this.props.width < 768 ? 0 : 17,
                                                         fontFamily: "Outfit-Regular, serif"
                                                     }}
                                                     defaultValue={icon[1]}>
                                    {iconOptions.map(io => {
                                        return <option value={io}>{this.getNameFromType2(io)}</option>
                                    })}
                                </select> : <></>}
                            </div>
                        }
                    )}
                    {this.props.newDesign ?
                        <EditorFooter width={this.props.width} changeSelected={this.props.changeSelected}
                                      text={translate("hd.editor.next")}
                                      toStep={4}/> :
                        <EditorFooter width={this.props.width} changeSelected={this.props.changeSelected}
                                      text={translate("hd.editor.toStep") + " 3"} toStep={3}/>}
                </div>
            </div>
        </div>
    }
}

export default ButtonEditor;
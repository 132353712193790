import React, {Component} from 'react'

import './pvh.scss'

interface methodState {
    width: number
}

interface methodProps {
}


export default class PVH extends Component<methodProps, methodState> {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
        }
        window.addEventListener('resize', this.reportWindowSize);

    }

    reportWindowSize = () => {
        this.setState({width: window.innerWidth})
    }


    render(): JSX.Element {
        return (
            <>
                <div className={"pvh-header"}>
                    <img src={"/PVH/PVH_Logo.jpg"} className={"pvh-logo"}/>
                </div>
                <div className={"pvh-subheader"}>
                    <img src={"/PVH/CalvinKlein.png"} className={"pvh-calvin-logo"}/>
                    <img src={"/PVH/TommyHilfiger.jpg"} className={"pvh-hillfiger-logo"}/>

                </div>
                <div className={"pvh-body"}>
                    <h1>PVH digital business card: durable and easy to use.</h1>
                    <h2>Very nice! You have received a digital business card from PVH and now want to know more. On this
                        page you can read the story behind the PVH digital business card, which is facilitated by
                        Multicopy
                        The Communication Company Amsterdam Centrum and Bizzerd.</h2>
                    <p>The digital business cards offer PVH all kinds of smart features. For example, employees can add
                        their own social media or a company video. Sharing information can be done fast and easy by
                        email,
                        WhatsApp, SMS, QR-code, NFC (Android) or Airdrop (iPhone).</p>
                    <p>The PVH digital business card is available as both a web app and a mobile app. In the app’s
                        library
                        employees have access to the digital cards of all their colleagues which they can share easily
                        with
                        others. And with just one single push of a button a PVH employee can add a received digital
                        business
                        card to the contact list of his or her phone. Even paper business cards, which can be scanned
                        and
                        saved. Like this, PVH saves paper and reduces waste while keeping their CO2 footprint to a
                        minimum.</p>
                    <p>Digital business cards help making the world a bit better and cleaner.</p>
                    <p>Just like PVH, more organizations are transitioning to paperless digital business cards because
                        of
                        their ease of use, and their innovative and durable nature.</p>
                    <p>Do you want to make the transition too? Just give me a call or send me an email.</p>
                    <div className={"pvh-image-part"}>
                        <img src={"/PVH/Pasfoto.jpg"} className={"pvh-image"}/>
                        <div className={"pvh-contact-info"}>
                            <h1>Multicopy Amsterdam Centrum</h1>
                            <p>Contact: <b>Jeroen Hermans</b></p>
                            <p>Phone: <a href={"tel:31653360228"} className={"pvh-contact-link"}><b>+31(0) 6 53 36 02
                                28</b></a></p>
                            <p>Mail: <a href={"mailto:j.hermans@multicopy.nl"}
                                        className={"pvh-contact-link"}><b>j.hermans@multicopy.nl</b></a></p>
                        </div>
                    </div>
                    <p>You can also click the link below to visit our page about digital business cards:</p>
                    <a target={"_blank"}
                       href={"https://www.multicopy.nl/amsterdam-centrum/digitaal-visitekaartje"}>https://www.multicopy.nl/amsterdam-centrum/digitaal-visitekaartje</a>
                </div>
                <div className={"pvh-footer"}>
                    <div className={"pvh-list"}>
                        <p> ✓ From 1 to over more than 100.000 users</p>
                        <p> ✓ Easy, parctical, durable</p>
                        <p> ✓ Always visible online and sharable from the app (iOS en Android)</p>
                    </div>
                    <div className={"pvh-benefits"}>
                        <b>PVH users</b>
                        <b>Shares per day</b>
                        <b>Trees Saved</b>
                    </div>
                    <div className={"pvh-benefits2"}>
                        <p>-</p>
                        <p>~1072</p>
                        <p>1301</p>
                    </div>
                    <img src={"/PVH/PVH_Logo.jpg"} className={"pvh-logo"}
                         style={{position: "relative", left: "-10px"}}/>
                    <br/>
                    <a target="_blank"
                       style={{position: "relative", left: "-12px", color: "#FFF", textDecoration: "underline"}}
                       href={"/pvhdemo"}><b>Demo Link</b></a>
                </div>
            </>
        )
    }

}

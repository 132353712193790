import PageTitle from "../fields/text/pagetitle.text";
import {translate} from "react-i18nify";
import MainButton from "../buttons/action_main/main.button";
import ButtonColors from "../buttons/button.colors";
// @ts-ignore
import {default as world} from "../../../assets/about/world.about.svg"
// @ts-ignore
import {default as bizzerdWork} from "../../../assets/about/work.about.svg"
// @ts-ignore
import {default as multicopy} from "../../../assets/about/img.png"
// @ts-ignore
import {default as blogImage} from "../../../assets/about/blog.about.svg"
// @ts-ignore
import {default as mecc} from "../../../assets/about/mecc.about.svg"
import React, {useEffect, useState} from "react";
import SubSubTitle from "../fields/text/subsubtitle.text";
import Lottie from "react-lottie";
import axios from "axios";


export default function AboutUsBlock() {
    const [animationData, setAnimationData] = useState(null);

    useEffect(() => {
        const fetchAnimationData = async () => {
            try {
                const response = await axios.get('/api/nfc/lottie/aboutus.lottie.json');
                const json = await response.data;
                setAnimationData(json);
            } catch (error) {
                console.error('Error fetching animation data:', error);
            }
        };

        fetchAnimationData();
    }, []);

    let headerText = translate("hd.about.title");
    const finalHeaderText = <span>{headerText.split("<br/>")[0]}<br/>{headerText.split("<br/>")[1]}</span>
    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData,
    };
    const header =
        <div className={"about-header"}>
            <PageTitle text={finalHeaderText} style={{fontSize: 36}}/>
            <span className={"enterprise-header-usp-item"}> {translate("hd.about.subTitle")}</span>
            <br/>
            <div className={"about-header-buttons"}>
                <MainButton color={ButtonColors.orange} href={translate("hd.links.product")}
                            text={translate("hd.about.mainButton")}/>
                <MainButton color={ButtonColors.white} href={translate("hd.links.enterprise")}
                            text={translate("hd.about.secondaryButton")}/>
            </div>
            <br/>
            <Lottie options={defaultOptions}/>


        </div>
    return <>
        {header}
        <div className={"about-container"}>

            <div className={"about-flex-row"}>
                <div className={"about-flex-row-content"}>
                    <PageTitle text={translate("hd.about.locationTitle")}/>
                    <span className={"enterprise-header-usp-item"}>
                    {translate("hd.about.locationText")}
                </span>
                </div>
                <div style={{display: "flex", flexDirection: "column", width: "100%", alignItems: "center"}}>
                    <img src={world} style={{maxWidth: 300}}/>
                </div>

            </div>
            <div>
                <PageTitle text={translate("hd.about.bizzerdWorkTitle")}/>
                <br/>
                <br/>
                <div className={"about-flex-row"}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        <img src={bizzerdWork}/>
                    </div>
                    <div style={{display: "flex", flexDirection: "column"}}>

                <span className={"enterprise-header-usp-item"}>
                    {translate("hd.about.bizzerdWorkText")}
                </span>
                        <br/>
                        <a href={"https://bizzerd.work"} target={"_blank"}
                           className={"text-link"}>{translate("hd.about.bizzerdWorkLink")} {">>"} </a>
                    </div>
                </div>
            </div>
            <div>
                <PageTitle text={translate("hd.about.referralTitle")}/>
                <br/>
                <br/>
                <div className={"about-flex-row"}>
                    <div style={{display: "flex", flexDirection: "column"}}>
                <span className={"enterprise-header-usp-item"}>
                    {translate("hd.about.referralText")} <a className={"text-link"}
                                                            href={"mailto:referral@bizzerd.com"}>{translate("hd.about.referralEmailText")}</a> {translate("hd.about.referralText2")}
                </span>
                    </div>
                </div>
            </div>
            <div>
                <PageTitle text={translate("hd.about.partnersTitle")}/>
                <br/>
                <br/>
                <div className={"about-flex-row"}>
                    <a href={"https://www.multicopy.nl/digitaal-visitekaartje"} target={"_blank"}>
                        <img style={{maxWidth: 200}} src={multicopy}/></a>
                    <img src={mecc}/>
                </div>
            </div>
            <div>
                <PageTitle text={translate("hd.about.blogTitle")}/>
                <br/>
                <br/>
                <div className={"about-flex-row"}>
                    <div className={"about-block-item"}>
                        <div>

                            <img src={blogImage} style={{maxWidth: 200}}/>
                            <SubSubTitle style={{textAlign: "left"}} text={translate("hd.blogBlock.blog1.title")}/>
                        </div>

                        <a href={translate("hd.links.blogs") + "/" + translate("hd.blogBlock.blog1.link")}
                           target={"_blank"}
                           className={"text-link"}>{translate("hd.about.blogLink")} {">>"} </a>

                    </div>
                    <div className={"about-block-item"}>
                        <div>

                            <img src={blogImage} style={{maxWidth: 200}}/>
                            <SubSubTitle style={{textAlign: "left"}} text={translate("hd.blogBlock.blog2.title")}/>
                        </div>

                        <a href={translate("hd.links.blogs") + "/" + translate("hd.blogBlock.blog2.link")}
                           target={"_blank"}
                           className={"text-link"}>{translate("hd.about.blogLink")} {">>"} </a>
                    </div>
                    <div className={"about-block-item"}>
                        <div>
                            <img src={blogImage} style={{maxWidth: 200}}/>
                            <SubSubTitle style={{textAlign: "left"}} text={translate("hd.blogBlock.blog3.title")}/>
                        </div>
                        <a href={translate("hd.links.blogs") + "/" + translate("hd.blogBlock.blog3.link")}
                           target={"_blank"}
                           className={"text-link"}>{translate("hd.about.blogLink")} {">>"} </a>
                    </div>
                    <div className={"about-block-item"}>
                        <div>

                            <img src={blogImage} style={{maxWidth: 200}}/>
                            <SubSubTitle style={{textAlign: "left"}} text={translate("hd.blogBlock.blog4.title")}/>
                        </div>

                        <a href={translate("hd.links.blogs") + "/" + translate("hd.blogBlock.blog4.link")}
                           target={"_blank"}
                           className={"text-link"}>{translate("hd.about.blogLink")} {">>"} </a>
                    </div>
                </div>
            </div>
        </div>
    </>
}
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {setLocale, setTranslations} from "react-i18nify";
import {dutch, english} from "./locales";
import {unregister as unregisterServiceWorker} from './registerServiceWorker'

setTranslations({
    en: english,
    nl: dutch,
})

let language = localStorage.getItem("language")
if (window.location.href.endsWith("/en") || window.location.href.includes("/en/")) {
    language = "en"
    localStorage.setItem("language", "en")
}
if (language) {
    setLocale(language)
} else {
    setLocale('nl')
}

// ReactDOM.render(
//     <React.StrictMode>
//         <App />
//     </React.StrictMode>,
//     document.getElementById('root')
// );
// ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(<App/>, document.getElementById('root'));
unregisterServiceWorker();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import PageTitle from "../common2/fields/text/pagetitle.text";
import MainButton from "../common2/buttons/action_main/main.button";
import ButtonColors from "../common2/buttons/button.colors";
import React, {useEffect, useState} from "react";
import SubTitle from "../common2/fields/text/subtitle.text";
import SubSubSubTitle from "../common2/fields/text/subsubsubtitle.text";
import SecondaryButton from "../common2/buttons/action_secondary/secondary.button";
import {PlanFeature} from "./utils/utils";
import InputField from "../common2/fields/editable_input/text.input";
import DisplayBizzerd from "../bizzerddisplay/DisplayBizzerd";
import {FlowButton} from "./flow-button";
import axios from "axios/index";

export const InactivePayment = ({}) => {
    const [paymentPlan, setPaymentPlan] = useState(0)
    const [users, setUsers] = useState(10)

    const [price, setPrice] = useState(3.00)
    const [label, setLabel] = useState("Monthly")
    const [label2, setLabel2] = useState("Per user, per month")
    const [label3, setLabel3] = useState("per month")
    const [priceDisplay, setPriceDisplay] = useState(price * users)
    useEffect(async () => {
        const response = await axios.get("/api/person/countPersons/inactive")
        setUsers(response.data.count)
    }, []);
    useEffect(() => {
        let newPrice = users * price
        if (paymentPlan === 2) {
            newPrice *= 2
        }
        setPriceDisplay(newPrice)
    }, [price, users, paymentPlan]);
    useEffect(() => {
        switch (paymentPlan) {
            case 0:
                setPrice(3.00)
                setLabel("Monthly")
                setLabel2("Per user, per month")
                setLabel3("per month")
                break;
            case 1:
                setPrice(30.00)
                setLabel("1 Year")
                setLabel2("Per user, per year")
                setLabel3("in total")
                break;
            case 2:
                setPrice(24.00)
                setLabel("2 Years")
                setLabel3("in total")
                setLabel2("Per user, per year")
                break;
        }
    }, [paymentPlan]);

    return <div className={"dashboard--main flexible-dashboard"}
                style={{paddingTop: 40, display: "flex", alignItems: "center", flexDirection: "column"}}>
        <PageTitle
            style={{
                fontSize: 36,
                lineHeight: "46px",
                fontWeight: 700,
                margin: 0
            }}
            text={"Choose a plan"}/>

        <div className={"step-flow-left"} style={{alignItems: 'center', display: "flex", flexDirection: "column"}}>
            <div className={"step-flow-button-container"}>
                <PaymentPlanButton title={"Monthly"} label={"Per user, Per month"} price={"€ 3,00"}
                                   extraLabel={"Pay per month"} selected={paymentPlan === 0}
                                   onClick={() => setPaymentPlan(0)}/>
                <PaymentPlanButton title={"1 Year"} label={"Per user, Per month"} price={"€ 2,50"}
                                   extraLabel={"Pay per year"} selected={paymentPlan === 1}
                                   onClick={() => setPaymentPlan(1)}/>
                <PaymentPlanButton title={"2 Year"} label={"Per user, Per month"} price={"€ 2,00"}
                                   extraLabel={"Pay per year"} selected={paymentPlan === 2}
                                   onClick={() => setPaymentPlan(2)}/>
            </div>
            <div style={{flexDirection: "row", display: "flex", alignItems: "flex-end"}}>
                <div className={"step-payment-plan step-payment-plan-border"}
                     style={{width: 320, height: "fit-content", borderRadius: 10}}>
                    <div className={"feature-row-container"}>
                        <SubTitle text={"Bizzerd Business"} style={{margin: 0}}/>
                        <SubSubSubTitle style={{color: "#324A6D", opacity: 0.6, fontWeight: "bold"}}
                                        text={label}/>
                    </div>
                    <div className={"feature-row-container"}>
                        <SubTitle text={"€" + price.toFixed(2)} style={{margin: 0}}/>
                        <SubSubSubTitle style={{color: "#324A6D", fontWeight: "bold"}}
                                        text={label2}/>
                    </div>
                    <div className={"payment-user-input"}>
                        <InputField placeholder={""} id={"user"} label={"Users"} value={users} style={{width: 62}}
                                    password={false} min={1}
                                    onChange={(e) => setUsers(parseInt(e.target.value))} number={true}
                                    inputStyle={{padding: 10}}/>
                        <div className={"payment-user-input-labels"}>
                            <SubSubSubTitle style={{color: "#324A6D", opacity: 0.6, fontWeight: "bold"}}
                                            text={"€ " + priceDisplay.toFixed(2) + " " + label3}/>
                            <SubSubSubTitle style={{color: "#324A6D", opacity: 0.6}}
                                            text={"Excl. VAT"}/>
                        </div>
                    </div>
                    <SecondaryButton color={ButtonColors.orange} text={"Choose Bizzerd Business"}
                                     style={{zIndex: 9999}} onClick={async () => {
                        await axios.post("/api/company/submit/inactive", {paymentPlan, users})
                        window.location.href = "/dashboard/simple"
                    }}/>

                    <div className={"feature-row-container"}>
                        <PlanFeature feature={"Unlimited users"}/>
                        <PlanFeature feature={"Unlimited amount of shares"}/>
                        <PlanFeature feature={"Share your card everywhere"}/>
                        <PlanFeature feature={"Add your social media channels"}/>
                        <PlanFeature feature={"Customise your card template"}/>
                        <PlanFeature feature={"Manage users"}/>
                        <PlanFeature feature={"Share everywhere"}/>
                        <PlanFeature feature={"Track statistics"}/>

                    </div>

                </div>
            </div>
        </div>

    </div>
}

const PaymentPlanButton = ({title, price, extraLabel, selected, onClick}) => {
    return <div className={"payment-plan-button" + (selected ? " ppb-selected" : "")} onClick={onClick}>
        <span className={"payment-plan-button-title"}>{title}</span>
        {extraLabel && selected && <span className={"payment-plan-button-extra-label"}>{extraLabel}</span>}
        <div className={"payment-plan-button-label-container"}>
            <span className={"payment-plan-button-price"}>{price}</span>
            <div style={{display: "flex", flexDirection: "column", alignItems: "flex-start"}}>
                <span className={"payment-plan-button-label"}>Per user,</span>
                <span className={"payment-plan-button-label"}>Per month</span>
            </div>
        </div>
    </div>

}
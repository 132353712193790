import {IIndustry} from "../../interfaces/IIndustry.interface";
import {api} from "../../utils/apiCall";


export interface IContactFormData {
    name: string,
    companyName: string,
    email: string,
    phoneNumber: string,
    type: string,
    employees?: string,

    message: string,
    nfc: boolean,
    captchaToken: string,
    code?: string,
}

export class ContactService {


    constructor() {
    }

    async submitContactForm(formData: IContactFormData): Promise<IIndustry[]> {


        console.log(formData)
        return api<IIndustry[]>('/api/contact/contactform', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        })

    }


}


import React from "react";
import '../text.styles.css'

export interface titleProps {
    text: string,
    thin?: boolean,
    style?: {},
}

class PageSubTitle extends React.Component<titleProps, {}> {

    render() {
        const {thin, text} = this.props
        let thinClass = "";
        if (thin) {
            thinClass = "-thin"
        }
        return <h1 style={this.props.style} className={"page-header-sub-title" + thinClass}>{text}</h1>;
    }

}

export default PageSubTitle;
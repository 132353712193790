import {
    BIZZERD_LOADING,
    BIZZERD_STORED,
    BIZZERD_STORING,
    GET_BIZZERD,
    GET_BIZZERDS,
    GET_UNSPLASH
} from "../actions/types";

const initialState = {
    bizzerd: {},
    bizzerds: [],
    loading: false,
    stored: true
};

export default function(state=initialState, action){
    switch(action.type){
        case BIZZERD_LOADING:
            return{
                ...state,
                loading: true
            };
        case GET_BIZZERDS:
            return{
                ...state,
                bizzerds: action.payload,
                loading: false
            };
        case GET_UNSPLASH:
            return{
                images: action.payload,
            };
        case GET_BIZZERD:
            return{
                ...state,
                bizzerd: action.payload,
                loading: false
            };
        case BIZZERD_STORING:
            return{
                ...state,
                stored:false
            };
        case BIZZERD_STORED:
            return{
                ...state,
                bizzerd:action.payload,
                stored: true
            };
        default:
            return state;
    }
}
import axios from 'axios';
import {
    BIZZERD_LOADING,
    BIZZERD_STORED,
    BIZZERD_STORING,
    GET_BIZZERD,
    GET_BIZZERDS,
    GET_ERRORS,
    GET_UNSPLASH
} from "./types";

export const getBizzerdByPersonId = (id) => dispatch => {
    dispatch(setBizzerdLoading());
    axios.get(`/api/bizzerd/person/${id}`)
        .then(res => {
            dispatch({
                type: GET_BIZZERD,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_BIZZERD,
            payload: null
        }))
};

export const getBizzerdByName = (company, name) => dispatch => {
    dispatch(setBizzerdLoading());
    axios.get(`/api/bizzerd/name/${company}/${name}`)
        .then(res => {
            dispatch({
                type: GET_BIZZERD,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_BIZZERD,
            payload: null
        }))
};

export const getBizzerdById = (id) => dispatch => {
    dispatch(setBizzerdLoading());
    axios.get(`/api/bizzerd/${id}`)
        .then(res => {
            dispatch({
                type: GET_BIZZERD,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_BIZZERD,
            payload: null
        }))
};

export const emailShare = (emailContent) => dispatch => {
    axios.post(`/api/bizzerd/share`, emailContent)
        .then(res => {
            dispatch({
                type: GET_ERRORS,
                payload: {}
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.errors
            });
        })
};

//Profile loading
export const setBizzerdLoading = () => {
    return {
        type: BIZZERD_LOADING
    }
};

//Profile loading
export const setBizzerdSaving = () => {
    return {
        type: BIZZERD_STORING
    }
};

//Update profile
export const newBizzerdFromTemplate = (id, bizzerd) => dispatch => {
    axios
        .post(`/api/bizzerd/${id}`, bizzerd)
        .then(res => {
                dispatch({
                    type: GET_BIZZERD,
                    payload: res.data
                })
            }
        )
        .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                })
            }
        )
};

export const newBizzerdForCompany = (company_id, bizzerd) => dispatch => {
    axios
        .post(`/api/bizzerd/addByCompany/${company_id}`, bizzerd)
        .then(res => {
                dispatch({
                    type: GET_BIZZERD,
                    payload: res.data
                })
            }
        )
        .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                })
            }
        )
};

//Update profile
export const updateBizzerd = (id, bizzerd) => dispatch => {
    // dispatch(setBizzerdSaving());
    axios.post(`/api/bizzerd/${id}`, bizzerd)
        .then(res => {
                dispatch({
                    type: BIZZERD_STORED,
                    payload: res.data
                })
            }
        )
        .catch(err => {
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response
                })
            }
        )
};

export const deleteBizzerd = (id) => dispatch => {
    axios.delete(`/api/bizzerd/${id}`)
        .then(res => {
            dispatch({
                type: GET_BIZZERDS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        })
};

export const duplicateBizzerd = (id) => dispatch => {
    axios.get(`/api/bizzerd/duplicateBizzerd/${id}`)
        .then(res => {
            dispatch({
                type: GET_BIZZERDS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        })
};

export const getImagesUnsplash = () => dispatch => {
    axios.get(`https://api.unsplash.com/photos/?client_id=Yhf8l17u7fZx44pCtfIBM3JHIMMSqAXjtNPXG869e34`)
        .then(res => {
            dispatch({
                type: GET_UNSPLASH,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        })
};

import {GET_PAYMENT, GET_PAYMENT_RESULT, GET_PAYMENT_STATUS, GET_PAYMENTS} from "../actions/types";

const initialState={
    status : 'initiate',
    session: {},
    payload: {},
    orderNr: 0,
    payment: {},
    payments: []
};

export default function(state=initialState, action) {
    switch(action.type){
        case GET_PAYMENT_STATUS:
            return {
                ...state,
                session: action.payload.paymentdata,
                orderNr: action.payload.orderNr
            };
        case GET_PAYMENT_RESULT:
            return{
                ...state,
                status: 'result',
                payload: action.payload
            };
        case GET_PAYMENT:
            return{
                ...state,
                payment: action.payload
            };
        case GET_PAYMENTS:
            return{
                ...state,
                payments: action.payload
            };
        default:
            return state;
    }
}
import React from 'react'
import GrayContainer from "../common2/containers/gray.container";
import NavigationHeader from "../common2/headers/navigation.header";
import HomeHeader from "../common2/headers/home.header";
import OrangeContainer from "../common2/containers/orange.container";
import SmallRectangle from "../common2/blocks/small.rectangle";
import CardContainer from "../common2/containers/card.container";
import RoundBizzerdContainer from "../common2/containers/round.bizzerd.container";
import SecondaryButton from "../common2/buttons/action_secondary/secondary.button";
import ButtonColors from "../common2/buttons/button.colors";
import PageTitle from "../common2/fields/text/pagetitle.text";
import GradientContainer from "../common2/containers/gradient.container";
import SubTitle from "../common2/fields/text/subtitle.text";
import FaqContainer from "../common2/containers/faq.container";
import FaqBlock from "../common2/blocks/faq.block";
import Footer from "../common2/footers/footer";
import {translate} from "react-i18nify";
import ReviewBlock from "../common2/blocks/review.block";
import {getPopularTemplates} from "../../actions/templateActions"
import {connect} from "react-redux";
import IconsBlock from "../common2/blocks/icons.block";
// @ts-ignore
import {default as brandingImg} from "../../assets/enterprice/branding.enterprise.svg"
// @ts-ignore
import {default as crmImg} from "../../assets/enterprice/crm.enterprise.svg"
// @ts-ignore
import {default as engagementImg} from "../../assets/enterprice/engagement.enterprise.svg"
// @ts-ignore
import {default as privacyImg} from "../../assets/enterprice/privacy.enterprise.svg"
// @ts-ignore
import {default as scalableImg} from "../../assets/enterprice/scalable.enterprise.svg"
// @ts-ignore
import {default as climateImg} from "../../assets/enterprice/climate.enterprise.svg"
// @ts-ignore
import {default as securityImg} from "../../assets/enterprice/security.enterprise.svg"
// @ts-ignore
import {default as headerImg} from "../../assets/enterprice/header.enterprise.svg"
// @ts-ignore
import {default as brabant} from "../../assets/enterprice/designs/brabant.png";
// @ts-ignore
import {default as enactus} from "../../assets/enterprice/designs/enactus.png";
// @ts-ignore
import {default as hurks} from "../../assets/enterprice/designs/hurks.png";
// @ts-ignore
import {default as makeawish} from "../../assets/enterprice/designs/makeawish.png";
// @ts-ignore
import {default as nibud} from "../../assets/enterprice/designs/nibud.png";
// @ts-ignore
import {default as rijksmuseum} from "../../assets/enterprice/designs/rijksmuseum.png";
// @ts-ignore
import {default as site} from "../../assets/enterprice/designs/site.png";
// @ts-ignore
import {default as sonneveld} from "../../assets/enterprice/designs/sonneveld.png";
// @ts-ignore
import {default as thecolourkitchen} from "../../assets/enterprice/designs/thecolourkitchen.png";
// @ts-ignore
import {default as wbl} from "../../assets/enterprice/designs/wbl.png";
import LogoBlock from "../common2/blocks/logo.block";
import SubSubSubTitle from "../common2/fields/text/subsubsubtitle.text";

interface pageProps {
    width: number,
    getPopularTemplates: () => void,
    templates: any,
}

interface homeState {
    templates: any,
    randomCards: number[]
}

class HomePage extends React.Component<pageProps, homeState> {
    constructor(props) {
        super(props);
        let arr: number[] = []
        while (arr.length < 6) {
            let r = Math.floor(Math.random() * 10 - 1) + 1;
            if (arr.indexOf(r) === -1) arr.push(r);
        }

        this.state = {
            templates: [{}, {}, {}, {}, {}],
            randomCards: arr
        }
        this.props.getPopularTemplates();
        setInterval(this.changeLogos, 5000)
    }

    changeLogos = () => {
        let arr: number[] = []
        while (arr.length < 6) {
            let r = Math.floor(Math.random() * 10 - 1) + 1;
            if (arr.indexOf(r) === -1) arr.push(r);
        }
        this.setState({randomCards: arr})
    }

    componentWillReceiveProps(nextProps: Readonly<pageProps>, nextContext: any) {
        if (nextProps.templates && nextProps.templates.templates)
            this.setState({templates: nextProps.templates.templates})
    }

    determineUsps = () => {
        let uspString = translate("hd.home.usp1").split("<b>")
        let usp1 = <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}><span
            className={"check-mark"}>✔︎</span>
            <span
                className={"home-header-usp-item"}>{uspString[0]}<b
                style={{color: "#428096"}}>{uspString[1].split("</b>")[0]}</b>{uspString[1].split("</b>")[1]}</span>
        </div>

        uspString = translate("hd.home.usp2").split("<b>")


        let usp2 = <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}><span
            className={"check-mark"}>✔︎</span>
            <span
                className={"home-header-usp-item"}>{uspString[0]}<b
                style={{color: "#428096"}}>{uspString[1].split("</b>")[0]}</b>{uspString[1].split("</b>")[1]}</span>
        </div>

        uspString = translate("hd.home.usp3").split("<b>")

        let usp3 = <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}><span
            className={"check-mark"}>✔︎</span>
            <span
                className={"home-header-usp-item"}>{uspString[0]}<b
                style={{color: "#428096"}}>{uspString[1].split("</b>")[0]}</b>{uspString[1].split("</b>")[1]}</span>
        </div>

        return {usp1, usp2, usp3}

    }


    render() {

        let templates = this.calculateNrTemplates()
        let {usp1, usp2, usp3} = this.determineUsps()
        let usps;
        if (this.props.width < 768) {
            usps = (
                <div className={"home-header-usp"} style={{paddingLeft: "5%", paddingRight: "5%", textAlign: "left"}}>
<span
    className={"home-header-usp-item"} style={{textAlign: "left"}}>{translate("hd.home.pageContent")}</span>
                    {usp1}
                    {usp2}
                    {usp3}

                </div>)
        }
        return (<>
            <div style={{backgroundColor: "rgb(234, 242, 246)"}}>
                <GrayContainer
                    style={{borderRadius: this.props.width < 768 ? "0px 0px 50px 50px" : "0px 0px 130px 130px"}}>
                    <NavigationHeader width={this.props.width}/>
                    <HomeHeader width={this.props.width}/>

                    <div className={"small-rectangle-container"}>
                        <SmallRectangle text={translate("hd.home.tryForFree")}
                                        buttonText={translate("hd.actions.tryForFree")}
                                        icon={"eye"} width={this.props.width}
                                        background={"#FFF"} href={translate("hd.links.businessCards")}/>

                        <SmallRectangle text={translate("hd.home.letUsDesign")}
                                        buttonText={translate("hd.home.pricingButton")}
                                        icon={"design"} width={this.props.width}
                                        background={"#FFF"} href={translate("hd.links.pricing")}/>
                        <SmallRectangle text={translate("hd.home.costs")}
                                        buttonText={translate("hd.home.upload")}
                                        icon={"cloud"} width={this.props.width}
                                        background={"#FFF"} href={translate("hd.links.enterprise")}/>
                    </div>
                    {usps}
                    <LogoBlock width={this.props.width} logoCount={7}
                               style={{
                                   top: 0,
                                   marginTop: 20,
                                   marginBottom: 40,
                                   boxShadow: "0 0 3px 0 rgba(0, 0, 0, 0.0)"
                               }}/>
                </GrayContainer>
            </div>

            <div
                style={{background: "linear-gradient(180deg, #EAF2F6 0%, rgba(234, 242, 246, 0.00) 100%)"}}>
                <CardContainer>
                    <div className={"title-button-row-container"}>
                        <div className={"title-thin-container"}>
                            <PageTitle text={translate("hd.home.popularDesigns")}
                                       style={this.props.width < 768 ? {marginTop: 0, marginBottom: 0} : {}}/>
                            <PageTitle
                                text={translate("hd.home.fromPrice")} thin={true} style={this.props.width < 768 ? {
                                marginTop: 0,
                                marginBottom: 0
                            } : {marginLeft: "10px"}}/>
                        </div>
                    </div>
                    <div className={"container-inner-cards"}>
                        {templates}
                    </div>

                    {/*<SecondaryButton color={ButtonColors.white} href={translate("hd.links.businessCards")}*/}
                    {/*                 text={translate("hd.home.viewAllDesigns")}*/}
                    {/*                 style={this.props.width < 768 ? {display: "none"} : {marginTop: 40}}/>*/}

                    {/*{this.props.width > 768 ?*/}
                    {/*    <></> : <SecondaryButton style={{marginTop: "40px"}} color={ButtonColors.white}*/}
                    {/*                             text={translate("hd.home.viewMoreDesigns")}*/}
                    {/*                             href={translate("hd.links.businessCards")}/>}*/}
                </CardContainer>
                <IconsBlock width={this.props.width}/>
            </div>
            <OrangeContainer>
                <></>
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    paddingLeft: "10%",
                    paddingRight: "10%"
                }}>
                    <SubSubSubTitle text={translate("hd.home.corporateCompany")}
                                    style={{color: "#FFF", fontWeight: "bold", fontSize: 18, marginBottom: 20}}/>
                    <span style={{
                        color: "#FFF",
                        marginTop: 10,
                        marginBottom: 30
                    }}>{translate("hd.home.corporateContent")}</span>
                    <SecondaryButton color={ButtonColors.white} href={translate("hd.links.enterprise")}
                                     text={translate("hd.navigationbar.enterprise")}/>
                </div>

            </OrangeContainer>
            <GradientContainer reverse={true}>
                <SubTitle text={translate("hd.home.faqTitle")}/>
                <FaqContainer>

                    {/*<FaqBlock*/}
                    {/*    text={translate("hd.home.faq2Answer")}*/}
                    {/*    title={translate("hd.home.faq2Title")} open={true} first={true}/>*/}
                    <FaqBlock
                        text={translate("hd.home.faq3Answer")}
                        title={translate("hd.home.faq3Title")} open={true} first={true}/>
                    <FaqBlock
                        text={translate("hd.home.faq5Answer")} alternatives={true}
                        title={translate("hd.home.faq5Title")} open={false}/>
                    <FaqBlock
                        text={translate("hd.home.faq4Answer")}
                        title={translate("hd.home.faq4Title")} last={true}/>
                </FaqContainer>
            </GradientContainer>
            <ReviewBlock/>
            <Footer/> </>);
    }

    // private calculateNrTemplates() {
    //     const personContent = {
    //         firstname: translate("chooseDesigns.fields.firstname"),
    //         pasfoto: "https://res.cloudinary.com/bizzerd-b-v/image/upload/v1680172291/dt3sixmvarf6ht35qpoi.jpg",
    //         lastname: translate("chooseDesigns.fields.lastname"),
    //         fullname: translate("chooseDesigns.fields.fullname"),
    //         jobtitle: translate("chooseDesigns.fields.title"),
    //         tel: translate("chooseDesigns.fields.tel"),
    //         mobile: translate("chooseDesigns.fields.mobile"),
    //         email: translate("chooseDesigns.fields.email"),
    //         adresveld: translate("chooseDesigns.fields.adres"),
    //         website: translate("chooseDesigns.fields.website")
    //     }
    //     let steps = [180, 160, 140, 100]
    //     let sizeOfContainer = steps[0] + 100
    //     let circleSize = 170
    //     if (this.props.width < 468) {
    //         sizeOfContainer = steps[3] + 20
    //         circleSize = 100
    //     } else if (this.props.width < 568) {
    //         sizeOfContainer = steps[2] + 100
    //         circleSize = 150
    //     } else if (this.props.width < 768) {
    //         sizeOfContainer = steps[1] + 100
    //         circleSize = 110
    //     }
    //     let templates: Array<JSX.Element> = []
    //     //margin
    //     sizeOfContainer += 40
    //     let nrOfTemplates = Math.floor(this.props.width / sizeOfContainer)
    //     if (nrOfTemplates === 0)
    //         nrOfTemplates = 1
    //     if (nrOfTemplates > 5) {
    //         nrOfTemplates = 5
    //     }
    //     for (let i = 0; i < nrOfTemplates; i++) {
    //         templates.push(<RoundBizzerdContainer width={this.props.width} steps={steps}
    //                                               personContent={personContent}
    //                                               bizzerdLayout={this.state.templates[i]} circleSize={circleSize}
    //                                               onClick={() => window.location.href = '/detail/' + this.state.templates[i]._id}/>)
    //     }
    //     return templates
    // }

    private calculateNrTemplates() {
        const designs = {
            brabant: brabant,
            enactus: enactus,
            hurks: hurks,
            makeawish: makeawish,
            nibud: nibud,
            rijksmuseum: rijksmuseum,
            site: site,
            sonneveld: sonneveld,
            thecolourkitchen: thecolourkitchen,
            wbl: wbl,
        }
        let steps = [180, 160, 140, 100]
        let sizeOfContainer = steps[0] + 100
        let circleSize = 170
        if (this.props.width < 468) {
            sizeOfContainer = steps[3] + 20
            circleSize = 100
        } else if (this.props.width < 568) {
            sizeOfContainer = steps[2] + 100
            circleSize = 150
        } else if (this.props.width < 768) {
            sizeOfContainer = steps[1] + 100
            circleSize = 110
        }
        let templates: Array<JSX.Element> = []
        //margin
        sizeOfContainer += 40
        let nrOfTemplates = Math.floor(this.props.width / sizeOfContainer)
        if (nrOfTemplates === 0)
            nrOfTemplates = 1
        if (nrOfTemplates > 5) {
            nrOfTemplates = 5
        }


        for (let i = 0; i < nrOfTemplates; i++) {
            templates.push(<RoundBizzerdContainer width={this.props.width} steps={steps}
                                                  img={designs[Object.keys(designs)[this.state.randomCards[i]]]}
                                                  circleSize={circleSize}
            />)
        }
        return templates
    }
}

const
    mapStateToProps = (state) => ({
        templates: state.templates
    });

export default connect(mapStateToProps, {
    getPopularTemplates
})(HomePage);

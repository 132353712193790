import React from 'react'
import GrayContainer from "../common2/containers/gray.container";
import NavigationHeader from "../common2/headers/navigation.header";
import GradientContainer from "../common2/containers/gradient.container";
import SubscriptionFlow from "../common2/flow/subscription.flow";
import Footer from "../common2/footers/footer";

interface pageProps {
    width: number
}

class SubscriptionPage extends React.Component<pageProps, {}> {
    render() {
        return (<>
            <GrayContainer style={{paddingBottom: "40px"}}>
                <NavigationHeader width={this.props.width}/>
            </GrayContainer>
            <GradientContainer>
                <SubscriptionFlow width={this.props.width}/>
            </GradientContainer>
            <Footer/>
        </>);
    }
}

export default SubscriptionPage
import {RESET_REQUEST_SUCCESSFUL, RESET_SUCCESSFUL, SET_CURRENT_USER} from "../actions/types";
import isEmpty from '../validation/is-empty'

const initialState={
    isAuthenticated: false,
    user: {},
    requestSuccess: false,
    resetSuccess: false
};

export default function(state=initialState, action) {
    switch(action.type){
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            };
        case RESET_REQUEST_SUCCESSFUL:
            return {
                ...state,
                requestSuccess: true
            };
        case RESET_SUCCESSFUL:
            return {
                ...state,
                resetSuccess: true
            };
        default:
            return state;
    }
}
import {Component} from "react";

interface methodState {
    width: number
}

interface methodProps {
}

export default class PfizerVideo extends Component<methodProps, methodState> {
    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
        }
        window.addEventListener('resize', this.reportWindowSize);
    }

    reportWindowSize = () => {
        this.setState({width: window.innerWidth})
    }

    render(): JSX.Element {
        return (<div
            style={{width: "100vw", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <video controls width={this.state.width > 768 ? "80%" : "80%"} style={{borderRadius:"10px",marginBottom:this.state.width>768?"0":"2vh", boxShadow:"0 2px 4px 0 rgb(0 0 0 / 50%)"}}>
                <source src={"https://bizzerd2.bizzerd.com/api/videos/Pfizer Demo video V1.mp4"} type="video/mp4"/>
            </video>
        </div>)
    }


}
import React from "react";
import './header.styles.css'
import ButtonColors from "../buttons/button.colors";
// @ts-ignore
import {default as shoppingCart} from "../../../assets/icons/shoppingcart.icon.svg";
// @ts-ignore
import {default as bizzerdLogo} from '../../../assets/img/bizzerd_logo.png';
// @ts-ignore
import {default as graphicBig} from '../../../assets/img/holderGraphic.png';
// @ts-ignore
import {default as graphicSmall} from '../../../assets/img/holderGraphicSmall.png';

// @ts-ignore
import {default as qrcode} from '../../../assets/icons/qrcode.icon.svg';
import PageTitle from "../fields/text/pagetitle.text";
// @ts-ignore
import {translate} from "react-i18nify";
import SubTitle from "../fields/text/subtitle.text";
import SubSubSubTitle from "../fields/text/subsubsubtitle.text";
import SecondaryButton from "../buttons/action_secondary/secondary.button";
import {PlanFeature} from "../../noeditorflow/utils/utils";


interface homeHeaderProps {
    width: number,
    img: any,
}

class PricingHeader extends React.Component<homeHeaderProps, {}> {

    constructor(props: homeHeaderProps) {
        super(props);
    }

    render() {
        return (<div className={"pricing-header"}>
                <div className={"enterprise-header-left"} style={{width: "100%", maxWidth: "100%"}}>
                    <PageTitle text={translate("hd.pricing.pageTitle")}
                               style={{
                                   fontSize: "36px",
                                   marginBottom: "15px"
                               }}/>
                    <span
                        className={"enterprise-header-usp-item"}
                        style={{lineHeight: "24px"}}>{translate("hd.pricing.subTitle")}</span>
                    <br/>
                </div>
                <div className={"pricing-plans-container"}>
                    <div className={"step-payment-plan full-width"}
                         style={{border: 0, textAlign: "left", borderRadius: 10}}>
                        <SubTitle text={translate("hd.pricingPlan.free.title")} style={{margin: 0}}/>
                        <div className={"feature-row-container"}>
                            <SubTitle text={translate("hd.pricingPlan.free.subTitle")} style={{margin: 0}}/>
                            <SubSubSubTitle style={{color: "#324A6D", opacity: 0.6, fontWeight: "bold"}}
                                            text={translate("hd.pricingPlan.free.days30")}/>
                        </div>
                        <SubSubSubTitle style={{color: "#324A6D", opacity: 0.6}}
                                        text={translate("hd.pricingPlan.free.tryFor")}/>
                        <div className={"feature-row-container"}>
                            <PlanFeature feature={translate("hd.pricingPlan.free.f1")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.free.f2")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.free.f3")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.free.f4")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.free.f5")}/>
                        </div>

                    </div>
                    <div className={"step-payment-plan step-payment-plan-border2 move-up full-width"}
                         style={{width: this.props.width < 768 ? 300 : 320, borderRadius: 10, height: 700}}>
                        <div className={"feature-row-container"}>
                            <SubTitle text={translate("hd.pricingPlan.business.title")} style={{margin: 0}}/>
                        </div>
                        <div className={"feature-row-container"}>
                            <SubSubSubTitle
                                style={{color: "#324A6D", opacity: 0.6, fontWeight: "bold"}}
                                text={translate("hd.pricingPlan.business.from")}/>
                            <SubTitle text={"€ 2,00"} style={{margin: 0}}/>
                            <SubSubSubTitle style={{color: "#324A6D", fontWeight: "bold"}}
                                            text={translate("hd.pricingPlan.business.perUser")}/>
                        </div>

                        <div className={"feature-row-container"}>
                            <PlanFeature feature={translate("hd.pricingPlan.business.f1")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.business.f2")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.business.f3")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.business.f4")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.business.f5")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.business.f6")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.business.f7")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.business.f8")}/>

                        </div>

                    </div>
                    <div className={"step-payment-plan move-up2 full-width"}
                         style={{border: 0, textAlign: "left", borderRadius: 10}}>
                        <SubTitle text={translate("hd.pricingPlan.enterprise.title")} style={{margin: 0}}/>
                        <SubSubSubTitle style={{color: "#324A6D", opacity: 0.6, fontWeight: "bold"}}
                                        text={translate("hd.pricingPlan.enterprise.subTitle")}/>


                        <div className={"feature-row-container"}>
                            <SubSubSubTitle style={{color: "#324A6D", fontWeight: "bold"}}
                                            text={translate("hd.pricingPlan.enterprise.fTitle")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.enterprise.f1")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.enterprise.f2")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.enterprise.f3")}/>
                            <PlanFeature feature={translate("hd.pricingPlan.enterprise.f4")}/>
                        </div>
                        <a href={translate("hd.pricingPlan.enterprise.quotationLink")} className={"text-link"}
                           target={"_blank"}>{translate("hd.pricingPlan.enterprise.quotation")}</a>
                        <SecondaryButton color={ButtonColors.white}
                                         text={translate("hd.pricingPlan.enterprise.contact")}
                                         style={{fontWeight: "bold"}}
                                         href={"/contact"}/>

                    </div>
                </div>

            </div>
        )

    }

}

export default PricingHeader;

import React from "react";
import './blocks.styles.css'
import ButtonColors from "../buttons/button.colors";
// @ts-ignore
import {default as EyeIcon} from "../../../assets/icons/eye.icon.svg"
// @ts-ignore
import {default as CloudIcon} from "../../../assets/icons/cloud.icon.svg"
// @ts-ignore
import {default as DesignIcon} from "../../../assets/icons/design.icon.svg"
// @ts-ignore
import {default as CardsBackground} from "../../../assets/containers/small.container.png"
import SubTitle from "../fields/text/subtitle.text";
import SecondaryButton from "../buttons/action_secondary/secondary.button";
import {translate} from "react-i18nify";

export interface loginBlockProps {
    width: number,
    title: string,
    description: string,
    date: string,
    link: string,
}


class BlogBlock extends React.Component<loginBlockProps, {}> {
    render() {

        return (
            <div className={"single-blog-block-container"}>
                <div className={"blog-block"}>
                    <span style={{color: "#334c6e88"}}>{this.props.date}</span>
                    <SubTitle style={{marginTop: 0}}
                              text={this.props.title}/>
                    <span>{this.props.description}</span>
                    <br/>
                    <SecondaryButton color={ButtonColors.white} href={this.props.link}
                                     onClick={() => window.location.href = translate("hd.links.blogs") + "/" +this.props.link}
                                     text={translate("hd.blogBlock.readMore")}/>
                </div>

            </div>
        );
    }

}


export default BlogBlock;
import PageTitle from "../common2/fields/text/pagetitle.text";
import "./noeditorflow.css"
import SubSubSubTitle from "../common2/fields/text/subsubsubtitle.text";
import InputField from "../common2/fields/editable_input/text.input";
import React, {useState} from "react";
import InputArea from "../common2/fields/editable_input/textarea.input";
import MainButton from "../common2/buttons/action_main/main.button";
import ButtonColors from "../common2/buttons/button.colors";
import {FlowButton} from "./flow-button";
import DisplayBizzerd from "../bizzerddisplay/DisplayBizzerd";
import {translate} from "react-i18nify";

export const PersonalDetails = ({
                                    step,
                                    setStep,
                                    template,
                                    person,
                                    setPerson,
                                    setTemplate,
                                    errors,
                                    setErrors,
                                    width,
                                    setPersonValidated
                                }) => {
    const [userFolded, setUserFolded] = useState(false)
    const [companyFolded, setCompanyFolded] = useState(false)
    const [socialMediaFolded, setSocialMediaFolded] = useState(false)
    const socialMediaButtons = 5;
    const demoPerson = {
        firstname: translate("chooseDesigns.fields.firstname"),
        pasfoto: "https://res.cloudinary.com/bizzerd-b-v/image/upload/v1680172291/dt3sixmvarf6ht35qpoi.jpg",
        lastname: translate("chooseDesigns.fields.lastname"),
        fullname: translate("chooseDesigns.fields.fullname"),
        jobtitle: translate("chooseDesigns.fields.title"),
        tel: translate("chooseDesigns.fields.tel"),
        mobile: translate("chooseDesigns.fields.mobile"),
        email: translate("chooseDesigns.fields.email"),
        companyname: translate("chooseDesigns.fields.company"),
        adresveld: translate("chooseDesigns.fields.adres"),
        website: translate("chooseDesigns.fields.website"),
        linkedin: "https://test.com",
        facebook: "https://test.com",
        twitter: "https://test.com",
        instagram: "https://test.com",
        youtube: "https://test.com",
    }

    const removeIcon = (index) => {
        let icons = template.icon
        for (let i = index + 1; i < template.icon.length; i++) {
            icons[i].left -= 80
        }
        setTemplate({...template, icons})
    }

    const addIcon = (index) => {
        let icons = template.icon
        for (let i = index + 1; i < template.icon.length; i++) {
            icons[i].left += 80
        }
        setTemplate({...template, icons})
    }

    let socialMediaInputs = []
    for (let i = 0; i < template.icon.length; i++) {
        let number = (i + 1).toString()
        socialMediaInputs.push(<div className={"step-input-row"}>
            <InputField style={width < 768 ? {width: "100%"} : {width: "calc(50% - 10px)"}} placeholder={"URL"}
                        id={"button" + number + "-url"}
                        label={"Link " + number}
                        onChange={(e) => setPerson({
                            ...person,
                            [template.icon[i].type]: (e.target.value === "" ? demoPerson[template.icon[i].type] : e.target.value),
                        })}
                        value={person[template.icon[i].type] === demoPerson[template.icon[i].type] ? "" : person[template.icon[i].type]}
                        readOnly={template.icon[i].type === "opslaan" || template.icon[i].type === "delen"}/>
            <div className={"step-select-container"}
                 style={width < 768 ? {width: "100%"} : {width: "calc(50% - 10px)"}}>
                <div className={"input-field-label-container"}>
                    <label className={"input-field-label"} htmlFor={"button" + number}>Button {number}</label>
                </div>
                <select id={"button" + number} name={"button" + number} className={"step-select"}
                        style={{width: "auto"}} value={template.icon[i].type}
                        onChange={(e) => {
                            if (e.target.value === "delete") {
                                removeIcon(i)
                            }
                            if (template.icon[i].type === "delete") {
                                addIcon(i)
                            }
                            setTemplate((oldTemplate) => {
                                let newTemplate = {...oldTemplate}
                                newTemplate.icon = newTemplate.icon.map((icon, j) => {
                                    if (i === j) {
                                        return {...icon, type: e.target.value}
                                    }
                                    return icon
                                })
                                return newTemplate
                            })

                        }}>
                    <option value={"linkedin"}>LinkedIn</option>
                    <option value={"facebook"}>Facebook</option>
                    <option value={"twitter"}>Twitter</option>
                    <option value={"instagram"}>Instagram</option>
                    <option value={"youtube"}>Youtube</option>
                    <option value={"website"}>Website</option>
                    <option value={"opslaan"}>Opslaan</option>
                    <option value={"delen"}>Delen</option>
                    <option value={"delete"}>Empty</option>
                </select>
            </div>
        </div>)
    }


    return <div className={"step-flow-item-container"}>
        <PageTitle style={{paddingBottom: 0, marginBottom: 0}} text={translate("hd.nef.s2.title")}/>
        <SubSubSubTitle style={{color: "#324A6D", opacity: 0.5}} text={translate("hd.nef.s2.subTitle")}/>
        <form style={{width: "100%"}} onSubmit={(e) => {
            e.preventDefault()
            setErrors({})
            setSocialMediaFolded(false)
            setUserFolded(false)
            setCompanyFolded(false)
            if (person.password.length <= 7) {
                setErrors({...errors, password: translate("hd.nef.s2.errors.passwordLength")})
                return
            }
            if (person.password !== person.password2) {
                setErrors({
                    ...errors,
                    password: translate("hd.nef.s2.errors.passwordMatch"),
                    password2: translate("hd.nef.s2.errors.passwordMatch")
                })
                return
            }
            setStep(step + 1)
            setPersonValidated(true)
        }}>
            <div className={"step-flow-horizontal"}>
                <div className={"step-flow-left"}>

                    <div className={"foldable-input"} style={{maxHeight: userFolded ? 50 : 800}}>
                        <div className={"foldable-title-container"} onClick={() => setUserFolded(!userFolded)}>
                            <span className={"foldable-title-number"}>1</span>
                            <span className={"foldable-title"}>{translate("hd.nef.s2.userDetails")}</span>
                            <svg style={{
                                position: "absolute",
                                right: 10,
                                transform: userFolded ? "rotate(180deg)" : "",
                                transition: "all ease-in-out 0.2s"
                            }}
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="10" height="7"
                                 viewBox="0 0 10 7" fill="none">
                                <path
                                    d="M1.94319 -2.31723e-08C1.12982 -1.34729e-08 0.656693 0.91937 1.12946 1.58124L4 5.6L4.18627 5.86077C4.58509 6.41912 5.41491 6.41912 5.81373 5.86077L6 5.6L8.87055 1.58124C9.34331 0.919368 8.87018 -1.05776e-07 8.05681 -9.60765e-08L1.94319 -2.31723e-08Z"
                                    fill="#324A6D"/>
                            </svg>
                        </div>
                        <span className={"flow-input-header"}>{translate("hd.nef.s2.accountDetails")}</span>
                        <div className={"step-input-row"}>
                            <InputField style={width < 768 ? {width: "100%"} : {width: "calc(50% - 10px)"}}
                                        required={true}
                                        placeholder={translate("hd.nef.s2.email")} id={"email"}
                                        label={translate("hd.nef.s2.email") + "*"} errorMessage={errors.email}
                                        showError={!!errors.email}
                                        value={person.email === demoPerson.email ? "" : person.email}
                                        onChange={(e) => setPerson({
                                            ...person,
                                            email: (e.target.value === "" ? demoPerson.email : e.target.value),
                                        })}/>
                            <InputField style={width < 768 ? {width: "100%"} : {width: "calc(50% - 10px)"}}
                                        placeholder={translate("hd.nef.s2.password")} id={"password"}
                                        label={translate("hd.nef.s2.password") + "*"} password={true} required={true}
                                        value={person.password === demoPerson.password ? "" : person.password}
                                        errorMessage={errors.password} showError={!!errors.password}
                                        onChange={(e) => setPerson({
                                            ...person,
                                            password: (e.target.value === "" ? demoPerson.password : e.target.value),
                                        })}/>
                        </div>
                        <div className={"step-input-row"} style={{justifyContent: "flex-end"}}>

                            <InputField style={width < 768 ? {width: "100%"} : {width: "calc(50% - 10px)"}}
                                        placeholder={translate("hd.nef.s2.password")} id={"password"}
                                        label={translate("hd.nef.s2.passwordAgain") + "*"} password={true}
                                        required={true}
                                        value={person.password2 === demoPerson.password2 ? "" : person.password2}
                                        errorMessage={errors.password2} showError={!!errors.password2}
                                        onChange={(e) => setPerson({
                                            ...person,
                                            password2: (e.target.value === "" ? demoPerson.password2 : e.target.value),
                                        })}/>
                        </div>
                        <span className={"flow-input-header"}>{translate("hd.nef.s2.personDetails")}</span>
                        <div className={"step-input-row"}>

                            <InputField style={width < 768 ? {width: "100%"} : {width: "calc(50% - 10px)"}}
                                        placeholder={translate("hd.nef.s2.firstName")} id={"firstname"}
                                        label={translate("hd.nef.s2.firstName") + "*"} required={true}
                                        value={person.firstname === demoPerson.firstname ? "" : person.firstname}
                                        onChange={(e) => setPerson({
                                            ...person,
                                            firstname: (e.target.value === "" ? demoPerson.firstname : e.target.value),
                                            fullname: (e.target.value === "" ? demoPerson.firstname : e.target.value) + " " + person.lastname
                                        })}/>
                            <InputField style={width < 768 ? {width: "100%"} : {width: "calc(50% - 10px)"}}
                                        placeholder={translate("hd.nef.s2.lastName")} id={"lastname"}
                                        label={translate("hd.nef.s2.lastName") + "*"} required={true}
                                        value={person.lastname === demoPerson.lastname ? "" : person.lastname}
                                        onChange={(e) => setPerson({
                                            ...person,
                                            lastname: (e.target.value === "" ? demoPerson.lastname : e.target.value),
                                            fullname: person.firstname + " " + (e.target.value === "" ? demoPerson.lastname : e.target.value)
                                        })}/>
                        </div>
                        <div className={"step-input-row"}>
                            <InputField style={width < 768 ? {width: "100%"} : {width: "calc(50% - 10px)"}}
                                        placeholder={translate("hd.nef.s2.jobTitle")} id={"jobtitle"}
                                        label={translate("hd.nef.s2.jobTitle")}
                                        value={person.jobtitle === demoPerson.jobtitle ? "" : person.jobtitle}
                                        onChange={(e) => setPerson({
                                            ...person,
                                            jobtitle: (e.target.value === "" ? demoPerson.jobtitle : e.target.value),
                                        })}/>
                            <InputField style={width < 768 ? {width: "100%"} : {width: "calc(50% - 10px)"}}
                                        placeholder={translate("hd.nef.s2.mobile")} id={"mobile"}
                                        label={translate("hd.nef.s2.mobile") + "*"} required={true}
                                        value={person.mobile === demoPerson.mobile ? "" : person.mobile}
                                        onChange={(e) => setPerson({
                                            ...person,
                                            mobile: (e.target.value === "" ? demoPerson.mobile : e.target.value),
                                        })}/>
                            {/*<InputField style={{width: "calc(50% - 10px)"}} placeholder={"Email address"} id={"email"}*/}
                            {/*            label={"Email address*"}*/}
                            {/*            value={person.email === demoPerson.email ? "" : person.email}*/}
                            {/*            onChange={(e) => setPerson({*/}
                            {/*                ...person,*/}
                            {/*                email: (e.target.value === "" ? demoPerson.email : e.target.value),*/}
                            {/*            })}/>*/}
                        </div>
                        <div className={"step-input-row"}>

                            <InputField style={width < 768 ? {width: "100%"} : {width: "calc(50% - 10px)"}}
                                        placeholder={translate("hd.nef.s2.phone")} id={"tel"}
                                        label={translate("hd.nef.s2.phone")}
                                        value={person.tel === demoPerson.tel ? "" : person.tel}
                                        onChange={(e) => setPerson({
                                            ...person,
                                            tel: (e.target.value === "" ? demoPerson.tel : e.target.value),
                                        })}/>
                        </div>
                    </div>
                    <div className={"foldable-input"} style={{maxHeight: companyFolded ? 50 : 400}}>
                        <div className={"foldable-title-container"} onClick={() => setCompanyFolded(!companyFolded)}>
                            <span className={"foldable-title-number"}>2</span>
                            <span className={"foldable-title"}>{translate("hd.nef.s2.companyDetails")}</span>
                            <svg style={{
                                position: "absolute",
                                right: 10,
                                transform: companyFolded ? "rotate(180deg)" : "",
                                transition: "all ease-in-out 0.2s"
                            }}
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="10" height="7"
                                 viewBox="0 0 10 7" fill="none">
                                <path
                                    d="M1.94319 -2.31723e-08C1.12982 -1.34729e-08 0.656693 0.91937 1.12946 1.58124L4 5.6L4.18627 5.86077C4.58509 6.41912 5.41491 6.41912 5.81373 5.86077L6 5.6L8.87055 1.58124C9.34331 0.919368 8.87018 -1.05776e-07 8.05681 -9.60765e-08L1.94319 -2.31723e-08Z"
                                    fill="#324A6D"/>
                            </svg>
                        </div>
                        <div className={"step-input-row"}>
                            <InputField style={width < 768 ? {width: "100%"} : {width: "calc(50% - 10px)"}}
                                        placeholder={translate("hd.nef.s2.companyName")}
                                        id={"companyname"} errorMessage={errors.companyname}
                                        showError={!!errors.companyname}
                                        label={translate("hd.nef.s2.companyName") + "*"} required={true}
                                        value={person.companyname === demoPerson.companyname ? "" : person.companyname}
                                        onChange={(e) => setPerson({
                                            ...person,
                                            companyname: (e.target.value === "" ? demoPerson.companyname : e.target.value),
                                        })}/>

                            <InputField style={width < 768 ? {width: "100%"} : {width: "calc(50% - 10px)"}}
                                        placeholder={"Website"} id={"website"}
                                        label={"Website"}
                                        value={person.website === demoPerson.website ? "" : person.website}
                                        onChange={(e) => setPerson({
                                            ...person,
                                            website: (e.target.value === "" ? demoPerson.website : e.target.value),
                                        })}/>
                        </div>
                        <div className={"step-input-row"}>
                            <InputArea style={width < 768 ? {width: "100%"} : {width: "calc(50% - 10px)"}}
                                       placeholder={translate("hd.nef.s2.address")} id={"adresveld"}
                                       label={translate("hd.nef.s2.address")}
                                       value={person.adresveld === demoPerson.adresveld ? "" : person.adresveld}
                                       onChange={(e) => setPerson({
                                           ...person,
                                           adresveld: (e.target.value === "" ? demoPerson.adresveld : e.target.value),
                                       })}/>
                        </div>
                    </div>
                    <div className={"foldable-input"} style={{maxHeight: socialMediaFolded ? 50 : 1000}}>
                        <div className={"foldable-title-container"}
                             onClick={() => setSocialMediaFolded(!socialMediaFolded)}>
                            <span className={"foldable-title-number"}>3</span>
                            <span className={"foldable-title"}>Social media</span>
                            <svg style={{
                                position: "absolute",
                                right: 10,
                                transform: socialMediaFolded ? "rotate(180deg)" : "",
                                transition: "all ease-in-out 0.2s"
                            }}
                                 xmlns="http://www.w3.org/2000/svg"
                                 width="10" height="7"
                                 viewBox="0 0 10 7" fill="none">
                                <path
                                    d="M1.94319 -2.31723e-08C1.12982 -1.34729e-08 0.656693 0.91937 1.12946 1.58124L4 5.6L4.18627 5.86077C4.58509 6.41912 5.41491 6.41912 5.81373 5.86077L6 5.6L8.87055 1.58124C9.34331 0.919368 8.87018 -1.05776e-07 8.05681 -9.60765e-08L1.94319 -2.31723e-08Z"
                                    fill="#324A6D"/>
                            </svg>
                        </div>
                        {socialMediaInputs}

                    </div>
                </div>
                <div className={"step-flow-right"}>
                    <DisplayBizzerd bizzerdLayout={template} personContent={person} realWidth={300}
                                    shouldBeClickable={false}/>
                    <FlowButton color={ButtonColors.orange} title={translate("hd.nef.nav.nextStep")} type={"submit"}
                                subTitle={translate("hd.nef.s3.title")}
                                icon={<svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19"
                                           fill="none">
                                    <path
                                        d="M4.27441 18.494C3.52441 18.494 2.78275 18.3106 2.04941 17.944C1.31608 17.5773 0.724414 17.094 0.274414 16.494C0.707747 16.494 1.14941 16.3231 1.59941 15.9815C2.04941 15.6398 2.27441 15.144 2.27441 14.494C2.27441 13.6606 2.56608 12.9523 3.14941 12.369C3.73275 11.7856 4.44108 11.494 5.27441 11.494C6.10775 11.494 6.81608 11.7856 7.39941 12.369C7.98275 12.9523 8.27441 13.6606 8.27441 14.494C8.27441 15.594 7.88275 16.5356 7.09941 17.319C6.31608 18.1023 5.37441 18.494 4.27441 18.494ZM4.27441 16.494C4.82441 16.494 5.29525 16.2981 5.68691 15.9065C6.07858 15.5148 6.27441 15.044 6.27441 14.494C6.27441 14.2106 6.17858 13.9731 5.98691 13.7815C5.79525 13.5898 5.55775 13.494 5.27441 13.494C4.99108 13.494 4.75358 13.5898 4.56191 13.7815C4.37025 13.9731 4.27441 14.2106 4.27441 14.494C4.27441 14.8773 4.22858 15.2273 4.13691 15.544C4.04525 15.8606 3.92441 16.1606 3.77441 16.444C3.85775 16.4773 3.94108 16.494 4.02441 16.494H4.27441ZM10.0244 12.494L7.27441 9.74398L16.2244 0.793981C16.4077 0.610648 16.6369 0.514815 16.9119 0.506481C17.1869 0.498148 17.4244 0.593981 17.6244 0.793981L18.9744 2.14398C19.1744 2.34398 19.2744 2.57731 19.2744 2.84398C19.2744 3.11065 19.1744 3.34398 18.9744 3.54398L10.0244 12.494Z"
                                        fill="white"/>
                                </svg>}/>
                    <FlowButton color={ButtonColors.white} title={translate("hd.nef.nav.previousStep")}
                                subTitle={translate("hd.nef.s1.title")} onClick={() => setStep(step - 1)}
                                icon={<svg width="21" height="21" viewBox="0 0 21 21" fill="none"
                                           xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M2.73633 17.8L1.88633 17.45C1.36966 17.2333 1.02383 16.8583 0.848828 16.325C0.673828 15.7917 0.702995 15.2667 0.936328 14.75L2.73633 10.85V17.8ZM6.73633 20C6.18633 20 5.7155 19.8042 5.32383 19.4125C4.93216 19.0208 4.73633 18.55 4.73633 18V12L7.38633 19.35C7.43633 19.4667 7.48633 19.5792 7.53633 19.6875C7.58633 19.7958 7.653 19.9 7.73633 20H6.73633ZM11.8863 19.9C11.353 20.1 10.8363 20.075 10.3363 19.825C9.83633 19.575 9.48633 19.1833 9.28633 18.65L4.83633 6.45001C4.63633 5.91667 4.65299 5.39584 4.88633 4.88751C5.11966 4.37917 5.503 4.03334 6.03633 3.85001L13.5863 1.10001C14.1197 0.900008 14.6363 0.925008 15.1363 1.17501C15.6363 1.42501 15.9863 1.81667 16.1863 2.35001L20.6363 14.55C20.8363 15.0833 20.8197 15.6042 20.5863 16.1125C20.353 16.6208 19.9697 16.9667 19.4363 17.15L11.8863 19.9ZM9.73633 8.00001C10.0197 8.00001 10.2572 7.90418 10.4488 7.71251C10.6405 7.52084 10.7363 7.28334 10.7363 7.00001C10.7363 6.71667 10.6405 6.47917 10.4488 6.28751C10.2572 6.09584 10.0197 6.00001 9.73633 6.00001C9.453 6.00001 9.2155 6.09584 9.02383 6.28751C8.83216 6.47917 8.73633 6.71667 8.73633 7.00001C8.73633 7.28334 8.83216 7.52084 9.02383 7.71251C9.2155 7.90418 9.453 8.00001 9.73633 8.00001ZM11.1863 18L18.7363 15.25L14.2863 3.00001L6.73633 5.75001L11.1863 18Z"
                                        fill="#324A6D"/>
                                </svg>

                                }/>

                </div>

            </div>
        </form>

    </div>
}
import React, {Component} from 'react';

class TextLine extends Component {

    render() {
        const backgroundStyle = {
            position: 'absolute',
            left: this.props.resize(this.props.layout.left),
            top: this.props.resize(this.props.layout.top),
            width: this.props.resize(this.props.layout.width),
            height: this.props.resize(this.props.layout.height),
            zIndex: this.props.layout.zindex
        };
        const textStyle = {
            color: this.props.layout.color ? this.props.layout.color:"#000",
            fontSize: this.props.resize(this.props.layout.fontSize),
            fontWeight: (this.props.layout.bold ? "bold" : "normal"),
            fontStyle: (this.props.layout.italic ? "italic" : "normal"),
            fontFamily: this.props.layout.font,
            wordWrap: 'break-word',
            lineHeight: '1.1em',
            marginTop: 0,
            display: 'block',
            textAlign: (this.props.layout.textAlign),
        };
        if (this.props.layout.rotation !== undefined) {
            backgroundStyle.transform = "rotate(" + this.props.layout.rotation + "deg)"
        }
        let textTag;
        if (this.props.layout.type !== "translation" && (this.props.link || this.props.layout.link || this.props.layout.type === "adresveld")) {
            let text = this.props.text
            let newText = text.split('\n').map((item, i) => {
                if (item === "") {
                    return <br/>
                }
                return <span key={i}>{item}<br/></span>;
            });
            if (this.props.link && this.props.link.startsWith("change_language_")) {
                textTag = <span style={{...textStyle, cursor: "pointer"}}
                                onClick={() => this.props.changeTranslation(this.props.link.replace("change_language_", ""))}>{newText}</span>
            }
            let link = this.props.link
            if (this.props.layout.type === "adresveld") {
                if (text.includes('\n')) {
                    link = "https://www.google.com/maps/search/" + text.replace("\n", ' ')
                } else {
                    link = "https://www.google.com/maps/search/" + text
                }
            }
            if (link && (this.props.link.includes("mailto:") || this.props.link.includes("tel:"))) {
                textTag = <a href={this.props.link}
                             style={textStyle} target={"_parent"}>{newText} </a>;
            } else if (link && !textTag) {
                textTag = <a href={link} target={'_blank'} rel="noopener noreferrer"
                             style={textStyle}>{newText}</a>;

            }
        } else {
            let text = this.props.text;
            if (this.props.layout.type !== 'translation' && text && text.includes('\n')) {
                let newText = text.split('\n').map((item, i) => {
                    if (item === "") {
                        return <br/>
                    }
                    return <p key={i}>{item}<br/></p>;
                });
                textTag = <text style={textStyle}>{newText}</text>

            } else if (this.props.layout.type === 'translation' && false) {
                textStyle['-webkit-user-select'] = "none"
                textStyle['-moz-user-select'] = "none"
                textStyle['-ms-user-select'] = "none"
                textStyle['user-select'] = "none"
                textTag = <a href={"#/"} style={textStyle} onClick={() => {
                    this.props.link()
                }}>
                    {
                        text
                    }
                </a>
            } else {
                textTag = <p style={textStyle}>{text}</p>
            }

        }

        if (!this.props.shouldBeClickable) {
            let newText = this.props.text;
            if (this.props.text) {
                newText = this.props.text.split('\n').map((item, i) => {
                    if (item === "") {
                        return <br/>
                    }
                    return <span key={i}>{item}<br/></span>;
                });
            }
            textTag = <p style={textStyle}>{newText}</p>
        }
        if (this.props.onSelectField) {
            return (
                <a className={"selected-bizzerd-editor-field"} onMouseDown={this.props.onChange}
                   style={backgroundStyle}
                   onClick={() => this.props.onSelectField(this.props.layout._id)}>
                    {textTag}
                </a>
            )
        }
        return (
            <div onMouseDown={this.props.onChange} style={backgroundStyle}>
                {textTag}
            </div>
        )
    }

}

export default TextLine;

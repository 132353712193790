import React from 'react'
import GrayContainer from "../common2/containers/gray.container";
import NavigationHeader from "../common2/headers/navigation.header";
import GradientContainer from "../common2/containers/gradient.container";
import DetailFlow from "../common2/flow/detail.flow";
import Footer from "../common2/footers/footer";

interface pageProps {

    width: number,
    match?: any,

}

class DetailPage extends React.Component<pageProps, {}> {

    render() {
        return (<>
            <GrayContainer style={{paddingBottom: "40px"}}>
                {[<NavigationHeader width={this.props.width}/>]}
            </GrayContainer>
            <GradientContainer>
                <DetailFlow width={this.props.width} templateId={this.props.match.params.templateId}/>
            </GradientContainer>
            <Footer/>
        </>);
    }
}

export default DetailPage
import React from "react";
import '../input.styles.css'
import {default as copyIcon} from "../../../../assets/icons/copy.icon.svg"


export interface inputProps {
    placeholder: string,
    validation?: string,
    value?: string,
    id: string,
    label: string,
    showError?: boolean,
    errorMessage?: string,
    password?: boolean,
    number?: boolean,
    onChange?: (e) => void;
    new?: boolean,
    required?: boolean,
    style?: any,
    onKeyDown?: (e) => void,
    readOnly?: boolean,
    icon?: string
    inputStyle?: {}
    min?: number,
    max?: number,
    boldLabel?: boolean,

}

class InputField extends React.Component<inputProps, {}> {

    render() {
        let invalid = "";
        let filled = ""
        let error;
        if (this.props.value) {
            filled = "filled"
        }
        if (this.props.validation) {
            const regex = new RegExp(this.props.validation)
            if (this.props.value) {
                if (!regex.test(this.props.value)) {
                    invalid = "invalid"
                    error = <label className={"input-field-label-error"}
                                   htmlFor={this.props.id}>{this.props.errorMessage}</label>
                }
            }
        }
        if (this.props.showError) {
            invalid = "invalid"
            error = <label className={"input-field-label-error"}
                           htmlFor={this.props.id}>{this.props.errorMessage}</label>
        }
        if (this.props.icon) {
            return <div className={"input-field-container"} style={this.props.style}>
                {this.props.label && <div className={"input-field-label-container"}>
                    <label className={"input-field-label"}
                           htmlFor={this.props.id}>{this.props.label}</label>
                    {error}

                </div>}

                <input required={this.props.required} readOnly={this.props.readOnly} name={this.props.id}
                       type={this.props.password ? "password" : this.props.number ? "number" : ""}
                       id={this.props.id} onKeyDown={this.props.onKeyDown}
                       autoComplete={this.props.password && this.props.new !== false ? "new-password" : ""}
                       className={"editable-input-field " + invalid + " " + filled}
                       style={{paddingRight: 40}}
                       placeholder={this.props.placeholder} value={this.props.value}
                       onChange={this.props.onChange}/>
                <img src={copyIcon} style={{width: 20, height: 20, position: "absolute", right: 5, cursor: "pointer"}}
                     onClick={() => {
                         document.getElementById(this.props.id).value = "Gekopieerd"
                         setTimeout(() => {
                             document.getElementById(this.props.id).value = this.props.value
                         }, 2000)
                         navigator.clipboard.writeText(this.props.value);
                     }}/>
            </div>
        }

        return <div className={"input-field-container"} style={this.props.style}>
            {this.props.label && <div className={"input-field-label-container"}>
                <label  style={{fontWeight: this.props.boldLabel ? "bold" : "",color:"#334c6e"}} className={"input-field-label"} htmlFor={this.props.id}>{this.props.label}</label>
                {error}

            </div>}
            <input required={this.props.required} readOnly={this.props.readOnly} name={this.props.id}
                   type={this.props.password ? "password" : this.props.number ? "number" : ""}
                   id={this.props.id} onKeyDown={this.props.onKeyDown} style={this.props.inputStyle}
                   min={this.props.min} max={this.props.max}
                   autoComplete={this.props.password && this.props.new !== false ? "new-password" : ""}
                   className={"editable-input-field " + invalid + " " + filled}
                   placeholder={this.props.placeholder} value={this.props.value}
                   onChange={this.props.onChange}/>
        </div>
    }

}

export default InputField;
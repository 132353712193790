import React from "react";
import './footer.styles.css'
import BlueContainer from "../containers/blue.container";

// @ts-ignore
import {default as phone} from "../../../assets/icons/phone.icon.svg"
// @ts-ignore
import {default as linkedin} from "../../../assets/icons/linkedin.icon.svg"
// @ts-ignore
import {default as bizzerdLogo} from '../../../assets/img/bizzerd_logo.png';
// @ts-ignore
import {default as facebook} from "../../../assets/icons/facebook.icon.svg"
import {translate} from "react-i18nify";

class Footer extends React.Component<{}, {}> {
    render() {
        return (<BlueContainer>
            <div className={"footer-main"}>
                <div className={"footer-item-column"}>
                    <a href={translate("hd.links.home")} className={"footer-item-title"}>{translate("hd.footer.bizzerdTitle")}</a>
                    <a href={translate("hd.links.home")} className={"footer-item-standard"}>Home</a>
                    {/*<a href={translate("hd.links.businessCards")}*/}
                    {/*   className={"footer-item-standard"}>{translate("hd.footer.businessCards")}</a>*/}
                    <a href={translate("hd.links.product")} className={"footer-item-standard"}>{translate("hd.footer.product")}</a>
                    <a href={translate("hd.links.contact")} className={"footer-item-standard"}>Contact</a>
                    {/*<a href={translate("hd.links.pricing")} className={"footer-item-standard"}>{translate("hd.footer.pricing")}</a>*/}
                </div>
                <div className={"footer-item-column"}>
                    <span className={"footer-item-title"}>Resources</span>
                    <a href={"/bizzerd_Algemene_voorwaarden.pdf"}
                       className={"footer-item-standard"}>{translate("hd.footer.toc")}</a>
                    <a href={'/privacystatement.pdf'}
                       className={"footer-item-standard"}>{translate("hd.footer.privacyStatement")}</a>
                    <a href={"/" + translate("footer.service.appManualLink")}
                       className={"footer-item-standard"}>{translate("hd.footer.appManual")}</a>
                    <a href={translate("footer.service.userTipsLink")}
                       className={"footer-item-standard"}>{translate("hd.footer.userTips")}</a>
                    <a href={translate("hd.links.blogs")}
                       className={"footer-item-standard"}>{translate("hd.footer.blogs")}</a>
                </div>
                <div className={"footer-item-column"}>
                    <span className={"footer-item-title"}>{translate("hd.footer.contactTitle")}</span>
                    <a href={"https://www.google.com/maps/place/Rembrandtlaan+24,+3723+BJ+Bilthoven"}
                       className={"footer-item-standard"}>Rembrandtlaan 24</a>
                    <a href={"https://www.google.com/maps/place/Rembrandtlaan+24,+3723+BJ+Bilthoven"}
                       className={"footer-item-standard"}>3723 BJ Bilthoven</a>
                    <a href={"https://www.google.com/maps/place/Rembrandtlaan+24,+3723+BJ+Bilthoven"}
                       className={"footer-item-standard"}>{translate("hd.footer.netherlands")}</a>
                    <span className={"footer-item-standard"}>&#8203;</span>
                    <a href={"tel:0882272222"} className={"footer-item-standard"}>088 - 227 22 22</a>
                </div>
                <div className={"footer-item-column"}>
                    <span className={"footer-item-title"}>{translate("hd.footer.extraTitle")}</span>
                    <span className={"footer-item-standard"}>{translate("hd.footer.kvk")}</span>
                    <span className={"footer-item-standard"}>KvK 63663449</span>
                    <span className={"footer-item-standard"}>VAT NL 855341555 B01</span>
                </div>
            </div>
            <div className={"footer-bottom"}>
                <div style={{display: "flex", flexDirection: "row", flexWrap: "nowrap"}}>
                    <img onClick={() => window.location.href = "https://www.linkedin.com/company/bizzerd/"}
                         className={"footer-icon"} src={linkedin} alt={"linkedin"}/>
                    <img onClick={() => window.location.href = "https://www.facebook.com/bizzerd/"}
                         className={"footer-icon"} src={facebook} alt={"facebook"}/>
                    <img onClick={() => window.open("https://wa.me/31882272222")} className={"footer-icon"} src={phone}
                         alt={"phone"}/>
                </div>
                <span>Copyright © 2023 - Alle rechten voorbehouden</span>
                <img src={bizzerdLogo} style={{height: "45px", maxWidth: "fit-content"}}/>
            </div>
        </BlueContainer>);
    }

}

export default Footer;
import React from "react";
import './filter.styles.css'

interface filterProps {
    title: string,
    categories: Array<string>,
    onChange: (e) => void,
    categoryCounts: Array<number>,
    filterTypes: any,
    type: string,
}

class DropdownFilter extends React.Component<filterProps, {}> {
    render() {
        let filterRows: Array<JSX.Element> = []
        for (let i = 0; i < this.props.categories.length; i++) {
            filterRows.push(
                <option className={"filter-category-option"} id={this.props.categories[i]} key={i + 'cat'}
                        value={this.props.filterTypes[i]}>
                    {this.props.categories[i]}
                </option>
            )
        }
        return <div className={"filter-header-item-container"}>
            <span className={"filter-header"}>{this.props.title}:</span>
            <select className={"filter-category-option"} onChange={this.props.onChange}
                    defaultValue={new URLSearchParams(window.location.search).get(this.props.type)}>{filterRows}</select>
        </div>;
    }

}

export default DropdownFilter;
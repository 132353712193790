import React from "react";
import SecondaryButton from "../../buttons/action_secondary/secondary.button";
import ButtonColors from "../../buttons/button.colors";

interface footerEditorProps {
    text: string,
    toStep: number,
    width: number,
    changeSelected: (blockNr) => void
}

class EditorFooter extends React.Component<footerEditorProps, {}> {
    render() {
        return (
            <div className={"editor-footer-container"}>
                {this.props.width < 768 ? <></> : <div className={"editor-footer-line"}/>}
                {this.props.toStep === 4 ?
                    <SecondaryButton text={this.props.text} onClick={() => this.props.changeSelected(this.props.toStep)}
                                     color={ButtonColors.orange}
                                     style={this.props.width < 768 ? {width: "100%"} : {width: "48%"}}/> :
                    <SecondaryButton style={this.props.width < 768 ? {width: "100%"} : {width: "48%"}} arrow={true}
                                     color={ButtonColors.aqua}
                                     onClick={() => this.props.changeSelected(this.props.toStep)}
                                     text={this.props.text}/>}

            </div>
        );
    }


}

export default EditorFooter;
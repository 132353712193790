import React, {Component} from 'react'
import {submitPasswordFromKey, submitPasswordNewUser, submitTOS} from "../../../actions/registerActions";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {ErrorMessage} from "../../common/AuthErrorComponent";
import GradientContainer from "../../common2/containers/gradient.container";
import MainButton from "../../common2/buttons/action_main/main.button";
import ButtonColors from "../../common2/buttons/button.colors";
import PageTitle from "../../common2/fields/text/pagetitle.text";
import SubSubSubTitle from "../../common2/fields/text/subsubsubtitle.text";
import InputField from "../../common2/fields/editable_input/text.input";
import {Checkbox} from "../../common/Checkbox/Checkbox.component";
import {translate} from "react-i18nify";

//TODO: MAYBE ADD TOS ACCEPT HERE

class Signup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id,
            password: '',
            password2: '',
            displayModal: false,
            error: "",
            acceptedTos: false,
            error2: ""
        }
    };


    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({errors: nextProps.errors.errors});
        }
        if (nextProps.auth.user.id) {
            if (nextProps.auth.user.company !== undefined) {
                console.log("I got here")
                window.location.href = "/dashboard/collegas/toevoegen"
            } else {
                this.props.history.push('/createcompany');
            }
        }

    }

    onChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    };

    onSubmit = (e) => {
        e.preventDefault();
        if (this.state.password !== this.state.password2) {
            this.setState({error: "De wachtwoorden zijn niet gelijk"})
            return
        }
        if (this.state.password.length <= 7) {
            this.setState({error: "Je wachtwoord is niet lang genoeg"})
            return
        }
        if (!this.state.acceptedTos) {
            this.setState({error2: "Accepteer de voorwaarden"})
            return
        }

        if (this.props.match.params.key === undefined) {
            //Submit the password for users that have already been through earlier steps.
            this.props.submitPasswordNewUser(this.state.password);
        } else {
            //Submit the password for users that only started at this point with an invite email.
            this.props.submitPasswordFromKey(this.state.password, this.props.match.params.key);
        }

    };

    onSubmitTOS = () => {
        this.props.submitTOS();
    };

    changeConsent = (e) => {
        this.setState({acceptedTos: e.target.checked})
    }

    render() {
        const {errors} = this.state;
        let error;
        if (this.state.error2 !== "") {
            error = <ErrorMessage errors={this.state.error2}/>
        }
        if (errors !== undefined) {
            error = <ErrorMessage errors={errors}/>
        }
        let modal;
        if (this.state.displayModal) {
            modal =
                <div className="modal--mask">
                    <div className="modal">
                        <PageTitle text={"Bekijk onze voorwaarden"}/>
                        <br/>
                        <p>Lees onze <a className={"underline"} target={"_blank"}
                                        href="/bizzerd_Algemene_voorwaarden.pdf">algemene
                            voorwaarden</a> en <a className={"underline"} target={"_blank"}
                                                  href='/privacystatement.pdf'>privacy
                            statement</a>.</p>
                        <MainButton color={ButtonColors.orange} onClick={this.onSubmitTOS} text={"Akkoord"}/>
                    </div>
                </div>;
        }
        return (
            <GradientContainer style={{marginTop: 0}}>
                <div className="wrapper" style={{marginLeft: 0}}>
                    <div className="card" style={{borderRadius: "10px"}}>
                        <PageTitle text={"Kies je wachtwoord"}/>
                        <SubSubSubTitle
                            text={"Stel een wachtwoord in waarmee je wilt inloggen. Deze moet minimaal 8 tekens zijn en 1 letter en 1 cijfer bevatten."}/>
                        <br/>
                        <InputField errorMessage={this.state.error} showError={!!this.state.error}
                                    placeholder={"Wachtwoord"} id={"password"} label={"Wachtwoord"} password={true}
                                    boldLabel={true}
                                    value={this.state.password}
                                    onChange={this.onChange}/>
                        <InputField errorMessage={this.state.error} showError={!!this.state.error}
                                    placeholder={"Wachtwoord opnieuw"} id={"password2"} label={"Wachtwoord opnieuw"}
                                    password={true}
                                    value={this.state.password2} boldLabel={true}
                                    onChange={this.onChange}/>
                        <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                            <input type={"checkbox"} id={"voorwaarden"} style={{width: "20px", height: "20px"}}
                                   onChange={this.changeConsent} checked={this.state.acceptedTos}/>
                            <label style={{width: "90%"}}
                                   htmlFor={"voorwaarden"}>{translate("hd.shoppingCart.agreeTo")} <a
                                style={{textDecoration: "underline"}}
                                href={"/termsconditions.pdf"} target={"_blank"}
                                className={"text-link"}>{translate("hd.shoppingCart.toc")}</a> & <a
                                style={{textDecoration: "underline"}}
                                href={"/privacystatement.pdf"} target={"_blank"}
                                className={"text-link"}>{translate("hd.shoppingCart.privacyPolicy")}</a>
                            </label>
                        </div>

                        <div className="form-group__footer" style={{justifyContent: "flex-end"}}>
                            <MainButton color={ButtonColors.orange} onClick={this.onSubmit} text={"Verder"}/>

                        </div>


                    </div>
                    {modal}
                    {error}
                </div>
            </GradientContainer>
        );
    }
}

Signup.propTypes =
    {
        submitPasswordNewUser: PropTypes.func.isRequired,
        submitPasswordFromKey: PropTypes.func.isRequired,
        submitTOS: PropTypes.func.isRequired,
        auth: PropTypes.object.isRequired,
        errors: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired
    };

const mapStateToProps = (state) => (
    {
        auth: state.auth,
        errors: state.errors,
        user: state.auth
    });

export default connect(mapStateToProps,
    {
        submitPasswordNewUser, submitPasswordFromKey, submitTOS
    }
)(Signup);
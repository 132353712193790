import {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {getNfcRedirect} from "../../../../actions/nfcActions"

interface NfcProps {
    match: any,
    nfc: any,
    getNfcRedirect: (code) => void,
}

class NFCRedirectPage extends Component<NfcProps, { redirect?: string }> {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        let code = this.props.match.params.code
        this.props.getNfcRedirect(code)
    }

    componentWillReceiveProps(nextProps: Readonly<NfcProps>, nextContext: any) {
        if (nextProps.nfc && nextProps.nfc.redirect) {
            this.setState({redirect: nextProps.nfc.redirect})
        }
    }

    render() {
        if (this.state.redirect) {
            // @ts-ignore
            window.gtag("event", "nfc", {link: this.state.redirect})
            window.location.href = this.state.redirect
        }
        return <></>;
    }
}

const mapStateToProps = (state) => ({
    nfc: state.nfc
});

export default connect(mapStateToProps, {getNfcRedirect})(NFCRedirectPage);
import React, {useEffect, useState} from 'react'
import './shop.scss'
import {Redirect, Route, RouteComponentProps, Switch, useLocation} from "react-router-dom";
import Register from '../oldbackoffice/registrationflow/Register'
import Signup from '../oldbackoffice/registrationflow/Signup'
import EnterRegistrationKey from '../oldbackoffice/registrationflow/EnterRegistrationKey'
import ShowBizzerd from "../bizzerddisplay/ShowBizzerd"
import ShowDemo from "../bizzerddisplay/ShowDemo"
import {cookieConsent} from '../common/CookieConsent/CookieConsentBar'
import PVH from "./pages/PVH/PVH.component";
import PVHVideo from "./pages/PVH/PVHVideo.component";
import MultiCopyVideo from "./pages/PVH/MultiCopyVideo.component";
import PfizerVideo from "./pages/PVH/PfizerVideo.component";
import RedirectPage from "./pages/RedirectPage/RedirectPage.component";
import HomePage from "../Pages/home.page";
import ContactPage from "../Pages/contact.page";
import DetailPage from "../Pages/detail.page";
import LoginPage from "../Pages/login.page";
import PricingPage from "../Pages/pricing.page";
import RegisterPage from "../Pages/register.page";
import EditorPage from "../Pages/editor.page";
import SubscriptionPage from "../Pages/subscription.page";
import BusinesscardsPage from "../Pages/businesscards.page";
import ShoppingcartPage from "../Pages/shoppingcart.page";
import AboutUsPage from "../Pages/aboutus.page";
import PaymentPage from "../Pages/payment.page";
import EnterprisePage from "../Pages/enterprise.page";
import ProductPage from "../Pages/product.page";
import UploadPage from "../Pages/upload.page";
import DesignPage from "../Pages/design.page";
import ForgotPage from "../Pages/forgot.page";
import NotFoundPage from "../Pages/notfound.page";
import {translate} from "react-i18nify";
import BlogPage from "../Pages/blog.page";
import NFCRedirectPage from "./pages/RedirectPage/NFCRedirectPage.component";
import NFCPage from "../Pages/nfc.page";
import CalculatorPage from "../Pages/calculator.page";
import {PVHRequestPage} from "../Pages/pvhrequest.page";
import AndroidPrivacyPolicy from "../Pages/privacyandroid.page";
import ReactGA from "react-ga";
import rg4js from 'raygun4js'

// @ts-ignore
import {default as nfcTag} from "../../assets/chat/nfctag.calculator.svg"
import ChatComponent from "../chat/Chat.component";
import DesignPicker from "../oldbackoffice/designtool/tool/components/DesignPicker"
import {StepFlow} from "../noeditorflow/step-flow"
import {NFCAnalysis} from "./pages/nfc/NFCAnalysis";


type shopProps = {}

interface MatchParams {
    objectId: string;
}

interface MatchProps extends RouteComponentProps<MatchParams> {
}

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

function updateGA() {
    if (sessionStorage.getItem("UAinitialised")) {
        ReactGA.pageview(window.location.pathname + window.location.search);
        rg4js('trackEvent', {type: 'pageView', path: window.location.pathname});
    }

}


function updateLocalTracking() {
    let userId = localStorage.getItem("uid")
    if (!userId) {
        userId = uuidv4()
        localStorage.setItem("uid", userId)
    }
    let paths: any[] = []
    let pathString = sessionStorage.getItem("paths")
    if (!pathString) {
        paths = []
    } else {
        paths = JSON.parse(pathString)
    }
    if (paths && paths.length > 0 && paths[paths.length - 1].link !== window.location.href || paths.length === 0)
        paths.push({link: window.location.href, date: Date.now()})
    sessionStorage.setItem("paths", JSON.stringify(paths))
    window.scrollTo(0, 0);
}

export default function Shop() {
    const [width, setWidth] = useState(window.innerWidth)
    const [showingTag, setShowingTag] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    setTimeout(() => {
        setShowingTag(true)
    }, 2000)
    window.addEventListener('resize', () => setWidth(window.innerWidth));
    let location = useLocation()
    useEffect(() => {
        updateLocalTracking()
        updateGA()
    }, [location]);
    let chat = (<div className={"sales-popup" + (showingTag ? "" : " invisible")}>
        <ChatComponent show={showMessage} width={width}/>
        <img onClick={() => {
            setShowMessage(!showMessage)
        }} src={nfcTag}/>
    </div>)


    return (

        <div>

            <div className="shopContainer">
                <Switch>

                    <Route exact path="/" render={() => {
                        document.title = translate("hd.titles.home")
                        return <><HomePage width={width}/>
                            {cookieConsent()}
                            {chat}</>
                    }}/>
                    <Route exact path={"/android/privacypolicy"}>
                        <AndroidPrivacyPolicy width={width}/>
                    </Route>
                    <Route exact path={"/auth/forgot"}>
                        <Redirect to={"/forgotpassword"}></Redirect>
                    </Route>

                    {/*{DUTCH PAGES}*/}
                    <Route exact path="/contact" render={() => {
                        document.title = translate("hd.titles.contact")
                        return <><ContactPage width={width}/>
                            {cookieConsent()}
                            {chat}</>
                    }}/>

                    <Route exact path="/about" render={() => {
                        document.title = translate("hd.titles.about")
                        return <><AboutUsPage width={width}/>
                            {cookieConsent()}
                            {chat}
                        </>
                    }}/>

                    <Route exact={true} path="/nfc" render={() => {
                        document.title = translate("hd.titles.nfc")
                        return <><NFCPage width={width}/>
                            {cookieConsent()}
                            {chat}
                        </>
                    }}/>
                    <Route exact path="/pricing" render={() => {
                        document.title = translate("hd.titles.pricing")
                        return <> <PricingPage width={width}/>
                            {cookieConsent()}
                            {chat}
                        </>
                    }}/>
                    <Route exact path="/calculator" render={() => {
                        document.title = translate("hd.titles.calculator")
                        return <>
                            <CalculatorPage width={width}/>
                            {cookieConsent()}
                            {chat}
                        </>
                    }}/>
                    <Route exact path="/business-cards/:page?" render={({match}: MatchProps) => {
                        document.title = translate("hd.titles.businessCards")
                        return <><BusinesscardsPage match={match} width={width}/>
                            {cookieConsent()}</>
                    }}/>
                    <Route exact path="/shoppingcart" render={() => {
                        document.title = translate("hd.titles.shoppingCart")
                        return <><ShoppingcartPage width={width}/>
                            {cookieConsent()}</>
                    }}/>
                    <Route exact={true} path="/enterprise" render={() => {
                        document.title = translate("hd.titles.enterprise")
                        return <><EnterprisePage width={width}/>
                            {cookieConsent()}
                            {chat}
                        </>
                    }}/>
                    <Route exact path="/product" render={() => {
                        document.title = translate("hd.titles.product")
                        return <><ProductPage width={width}/>
                            {cookieConsent()}
                            {chat}
                        </>
                    }}/>
                    <Route exact path="/upload" render={() => {
                        document.title = translate("hd.titles.upload")
                        return <><UploadPage width={width}/>
                            {cookieConsent()}</>
                    }}/>
                    <Route exact path="/blogs/:blog?" render={({match}: MatchProps) => {
                        document.title = translate("hd.titles.blogs")
                        return <><BlogPage width={width} match={match}/>
                            {cookieConsent()}
                            {chat}
                        </>
                    }}/>
                    <Route exact path='/detail/:templateId' render={({match}: MatchProps) => <>
                        <DetailPage match={match} width={width}/>
                        {cookieConsent()}
                    </>}/>
                    <Route exact path="/login" render={() => {
                        document.title = "Login | Bizzerd"
                        return <><LoginPage width={width}/>
                            {cookieConsent()}
                            {chat}
                        </>
                    }
                    }/>

                    {/*{ENGLISH PAGES}*/}
                    <Route exact exact path="/en/" render={() => {
                        document.title = translate("hd.titles.home")
                        return <><HomePage width={width}/>
                            {cookieConsent()}
                            {chat}
                        </>
                    }}/>
                    <Route exact path="/en/contact" render={() => {
                        document.title = translate("hd.titles.contact")
                        return <><ContactPage width={width}/>
                            {cookieConsent()}
                            {chat}
                        </>
                    }}/>

                    <Route exact path="/en/about" render={() => {
                        document.title = translate("hd.titles.about")
                        return <><AboutUsPage width={width}/>
                            {cookieConsent()}
                            {chat}
                        </>
                    }}/>

                    <Route exact path="/en/nfc" render={() => {
                        document.title = translate("hd.titles.nfc")
                        return <><NFCPage width={width}/>
                            {cookieConsent()}
                            {chat}
                        </>
                    }}/>
                    <Route exact path="/en/pricing" render={() => {
                        document.title = translate("hd.titles.pricing")
                        return <> <PricingPage width={width}/>
                            {cookieConsent()}
                            {chat}
                        </>
                    }}/>
                    <Route exact path="/en/business-cards" render={({match}: MatchProps) => {
                        document.title = translate("hd.titles.businessCards")
                        return <><BusinesscardsPage width={width}/>
                            {cookieConsent()}</>
                    }}/>
                    <Route exact path="/en/shoppingcart" render={() => {
                        document.title = translate("hd.titles.shoppingCart")
                        return <><ShoppingcartPage width={width}/>
                            {cookieConsent()}</>
                    }}/>
                    <Route exact path="/en/enterprise" render={() => {
                        document.title = translate("hd.titles.enterprise")
                        return <><EnterprisePage width={width}/>
                            {cookieConsent()}
                            {chat}
                        </>
                    }}/>
                    <Route exact path="/en/product" render={() => {
                        document.title = translate("hd.titles.product")
                        return <><ProductPage width={width}/>
                            {cookieConsent()}
                            {chat}
                        </>
                    }}/>
                    <Route exact path="/en/upload" render={() => {
                        document.title = translate("hd.titles.upload")
                        return <><UploadPage width={width}/>
                            {cookieConsent()}</>
                    }}/>
                    <Route path="/en/blogs/:blog?" render={({match}: MatchProps) => {
                        document.title = translate("hd.titles.blogs")
                        return <><BlogPage width={width} match={match}/>
                            {cookieConsent()}
                            {chat}
                        </>
                    }}/>
                    <Route path='/en/detail/:templateId' render={({match}: MatchProps) => <>
                        <DetailPage match={match} width={width}/>
                        {cookieConsent()}
                    </>}/>
                    <Route path="/en/login" render={() => {
                        document.title = "Login | Bizzerd"
                        return <><LoginPage width={width}/>
                            {cookieConsent()}
                            {chat}
                        </>
                    }
                    }/>


                    {/*{NON GOOGLE DUTCH/ENGLISH PAGES}*/}
                    <Route path={'/editor/:templateId'} render={({match}: MatchProps) => <>
                        <EditorPage match={match} width={width}/>
                        {cookieConsent()}
                    </>}/>
                    <Route path="/forgotpassword/:key?"
                           render={({match}: MatchProps) => <> <ForgotPage width={width} match={match}/>
                               {cookieConsent()}</>}/>
                    <Route path="/registration">
                        <RegisterPage width={width}/>
                        {cookieConsent()}
                    </Route>
                    <Route path="/finish">
                        <PaymentPage width={width}/>
                    </Route>
                    <Route path={'/subscription'}>
                        <SubscriptionPage width={width}/>
                        {cookieConsent()}
                    </Route>

                    <Route exact path="/design/:type/:templateId?" render={({match}: MatchProps) => <>
                        <DesignPage width={width} match={match}/>
                        {cookieConsent()}
                    </>}/>
                    <Route exact path="/gratisdigitaalvisitekaartje" render={({match}: MatchProps) => {
                        document.title = "Maak jouw gratis digitale visitekaartje"
                        return (<>
                            <DesignPicker/>
                            {cookieConsent()}
                        </>)
                    }}/>


                    <Route exact path="/start" render={() => {
                        document.title = translate("hd.titles.home")
                        return <><StepFlow/>
                            {cookieConsent()}
                        </>
                    }}/>

                    <Route exact path="/en/freedigitalbusinesscard" render={({match}: MatchProps) => {
                        document.title = "Create your free digital businesscard"
                        return (<>
                            <DesignPicker/>
                            {cookieConsent()}
                        </>)
                    }}/>
                    <Route exact path="/pvh">
                        <PVH/>
                        {cookieConsent()}
                    </Route>
                    <Route exact path="/pvhdemo">
                        <PVHVideo/>
                        {cookieConsent()}
                    </Route>
                    <Route exact path="/pfizerdemo">
                        <PfizerVideo/>
                        {cookieConsent()}
                    </Route>
                    <Route exact path="/multicopydemo">
                        <MultiCopyVideo/>
                        {cookieConsent()}
                    </Route>
                    <Route path={"/redirect"}>
                        <RedirectPage/>
                    </Route>
                    <Route path={"/nfc/redirect/:code"} render={({match}: MatchProps) => <>
                        <NFCRedirectPage match={match}/>
                    </>}/>

                    <Route path={"/pvhdigitalbusinesscards"}>
                        <PVHRequestPage/>
                    </Route>
                    <Route path={"/nfcanalysis"}>
                        <NFCAnalysis/>
                    </Route>


                    <div id={"backoffice"}
                         style={{backgroundColor: "#f7f7f7"}}> {/*,height:"97.5vh"*/}
                        <Switch>
                            <Route path={'/show/:company/:name/:page?'} component={ShowBizzerd}/>
                            <Route path={'/cards/:company/:name/:page?'} component={ShowBizzerd}/>
                            <Route path={'/demo/:templateId/:page?'} component={ShowDemo}/>
                            <Route path={'/register/:key?'}
                                   component={Register}>
                            </Route>
                            <Route path={'/registrationkey/:email'}
                                   component={EnterRegistrationKey}>
                            </Route>
                            <Route path={'/signup/:key?'} component={Signup}>
                            </Route>
                            {/*<Route path={'/auth/forgot'} component={NewPassword}/>*/}
                            <Route path="*">
                                <NotFoundPage width={width}/>
                            </Route>

                        </Switch>
                    </div>
                    <Route exact={true} path="*"> <NotFoundPage width={width}/>
                    </Route>

                </Switch>


            </div>

        </div>
    )

}

import React from "react";
import './container.styles.css'

export interface containerProps {
    children?: Array<any>,
    style?: {},
    class?: string
}

class CardContainer extends React.Component<containerProps, {}> {
    render() {

        return <div className={this.props.class ? this.props.class : "container-cards"} style={this.props.style}>
            <div className={"content-wrapper"}>
                {this.props.children}
            </div>
        </div>;
    }

}

export default CardContainer;
import axios from "axios/index";
import {
    CLEAR_ERRORS, CLEAR_PERSONS,
    EXIST_COMPANYNAME,
    GET_ANALYTICS,
    GET_ERRORS,
    GET_PERSON,
    GET_PERSONS, GET_PERSONSLIBRARY,
    GET_STATS_BY_COMPANY,
    PERSON_LOADING,
    PHOTO_SUCCESS
} from "./types";

export const getPersonsByCompany = () => dispatch => {
    dispatch(setPersonLoading());
    axios.get(`/api/person/company`)
        .then(res => {
            dispatch({
                type: GET_PERSONS,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

export const getPersonsByCompanyLibrary = () => dispatch => {
    dispatch(setPersonLoading());
    axios.get(`/api/person/company/library`)
        .then(res => {
            dispatch({
                type: GET_PERSONSLIBRARY,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};


export const getAnalyticsByCompany = (timespan) => dispatch => {
    axios.get(`/api/person/analytics/person/${timespan}`)
        .then(res => {
            dispatch({
                type: GET_ANALYTICS,
                payload: res.data
            })
        })
        .catch(err => {
                console.log(err)
                dispatch({
                    type: GET_ERRORS,
                    payload: err.response.data
                })
            }
        )
};

export const getPersonsByCompanyId = (companyId) => dispatch => {
    dispatch(setPersonLoading());
    axios.get(`/api/person/company/${companyId}`)
        .then(res => {
            dispatch({
                type: GET_PERSONS,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

export const getPersonById = (id) => dispatch => {
    dispatch(setPersonLoading());
    axios.get(`/api/person/${id}`)
        .then(res => {
            dispatch(clearErrors());
            dispatch({
                type: GET_PERSON,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

export const getPersonByName = (company, name) => dispatch => {
    dispatch(setPersonLoading());
    axios.get(`/api/person/name/${company}/${name}`)
        .then(res => {
            dispatch({
                type: GET_PERSON,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

export const getPersonByUser = () => dispatch => {
    axios.get(`/api/person`)
        .then(res => {
            dispatch({
                type: GET_PERSON,
                payload: res.data
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        })
};

export const getVcardByPerson = (person) => dispatch => {
    axios.get(`/api/person/vcard/${person}`)
        .then(res => {

            dispatch({
                type: GET_STATS_BY_COMPANY,
                payload: res
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        })
};


export const checkPersonAndContinue = (history) => dispatch => {
    history.push('/paymentinfo');
};

export const setBizzerdForPerson = (personId, bizzerdId, history) => dispatch => {
    axios.post(`/api/person/bizzerd/${personId}`, {id: bizzerdId})
        .then(res => {
            history.push('/dashboard/simple')
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};

export const uploadPassportPhoto = (photo) => dispatch => {
    axios.post(`/api/person/photo`, photo, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then(res => {
            dispatch({
                type: PHOTO_SUCCESS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err && err.response && err.response.data ? err.response.data : "Something went wrong"
            })
        })
};

export const uploadPhotoToColleague = (photo, id) => dispatch => {
    axios.post(`/api/person/photoToColleague/${id}`, photo, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    })
        .then(res => {
            dispatch({
                type: GET_PERSON,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        })
};
export const createPerson = (person) => dispatch => {
    axios.post(`/api/person/create`, person)
        .then(res => {
            dispatch({
                type: GET_PERSON,
                payload: res.data
            })
        })
        .catch(err => {
                if (err && err.response && err.response.data)
                    dispatch({
                        type: GET_ERRORS,
                        payload: err.response.data
                    })
            }
        )
}

export const updatePerson = (person) => dispatch => {
    axios.post(`/api/person/update`, person)
        .then(res => {
            dispatch({
                type: GET_PERSON,
                payload: res.data
            })
        })
        .catch(err => {
                if (err && err.response && err.response.data)
                    dispatch({
                        type: GET_ERRORS,
                        payload: err.response.data
                    })
            }
        )
};

export const addPerson = (person) => dispatch => {
    dispatch(setPersonLoading());
    dispatch(clearErrors());
    axios.post(`/api/person/`, person)
        .then(res => {
            dispatch({
                type: GET_PERSON,
                payload: res.data
            })
        })
        .catch(err => {
                if (err && err.response && err.response.data)
                    dispatch({
                        type: GET_ERRORS,
                        payload: err.response.data
                    })
            }
        )
};

export const changePersonPaymentStatus = (person) => dispatch => {
    dispatch(setPersonLoading());
    dispatch(clearErrors());
    axios.post(`/api/person/changePersonPaymentStatus`, person)
        .then(res => {
            dispatch({
                type: GET_PERSON,
                payload: res.data
            })
        })
        .catch(err => {
                if (err && err.response && err.response.data)
                    dispatch({
                        type: GET_ERRORS,
                        payload: err.response.data
                    })
            }
        )
};

export const addPersonExcellijst = (person_list, company_id) => dispatch => {
    dispatch(clearErrors());
    axios.post(`/api/person/addExcellijstPersons`, {persons: person_list, company_id: company_id})
        .then(res => {
            dispatch({
                type: GET_PERSON,
                payload: res.data
            })
        })
        .catch(err => dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        }))
};


export const deletePerson = (id) => dispatch => {
    axios.delete(`/api/person/${id}`)
        .then(res => {
            dispatch({
                type: GET_PERSONS,
                payload: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            })
        });
};

export const checkCompanyNameExists = (companyName) => dispatch => {
    axios.get(`/api/company/checkCompanyNameExists/${companyName}`).then(res => {
        dispatch({
            type: EXIST_COMPANYNAME,
            payload: res.data
        })
    }).catch(err => {
        dispatch({
            type: GET_ERRORS,
            payload: err.response.data
        })
    });
}

//Person loading
export const setPersonLoading = () => {
    return {
        type: PERSON_LOADING
    }
};

//Person loading
export const clearErrors = () => {
    return {
        type: CLEAR_ERRORS
    }
};

export const clearPersons = () => {
    return {
        type: CLEAR_PERSONS
    }
};


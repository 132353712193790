import React from "react";
import './container.styles.css'

// @ts-ignore

export interface containerProps {
    children?: Array<any> | any,
    style?: {},
    contentStyle?:{}
}

class GrayContainer extends React.Component<containerProps, {}> {
    render() {

        return <div style={this.props.style} className={"container-gray-gradient"}>
            <div className={"content-wrapper"} style={this.props.contentStyle}>
                {this.props.children}
            </div>
        </div>;
    }

}

export default GrayContainer;
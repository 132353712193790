import React from 'react'
import GrayContainer from "../common2/containers/gray.container";
import NavigationHeader from "../common2/headers/navigation.header";
import GradientContainer from "../common2/containers/gradient.container";
import PageTitle from "../common2/fields/text/pagetitle.text";
import Footer from "../common2/footers/footer";
import MainButton from "../common2/buttons/action_main/main.button";
import ButtonColors from "../common2/buttons/button.colors";
import {translate} from "react-i18nify";

interface pageProps {
    width: number
}

class NotFoundPage extends React.Component<pageProps, {}> {
    render() {
        return (<>
            <GrayContainer style={{paddingBottom: "40px"}}>
                <NavigationHeader width={this.props.width}/>
            </GrayContainer>
            <GradientContainer>
                <div className={"login-block"}>
                        <PageTitle text={"Oeps!"}/><PageTitle style={{marginLeft: 10}}
                                                              text={translate("hd.notFound.title")}
                                                              thin={true}/>
                    <br/>
                    <br/>
                    <br/>
                    <span>{translate("hd.notFound.content")}</span>
                    <br/>
                    <br/>
                    <br/>
                    <div style={{display: "flex", flexDirection: this.props.width<768?"column":"row", justifyContent: "space-between"}}>
                        <MainButton color={ButtonColors.orange} onClick={() => window.location.href = translate("hd.links.home")}
                                    text={translate("hd.notFound.buttonHome")} style={{marginRight: 10,marginBottom:this.props.width<768?20:0}}/>
                        <MainButton color={ButtonColors.white} onClick={() => window.location.href = translate("hd.links.contact")}
                                    text={translate("hd.notFound.buttonContact")} style={{marginLeft: 10}}/>

                    </div>
                </div>
            </GradientContainer>
            <Footer/></>);
    }
}

export default NotFoundPage
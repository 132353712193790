import React from "react";
// @ts-ignore
import {default as qrcode} from "../../../assets/icons/qrcode.icon.svg";
import DropdownFilter from "./dropdown.filter";

// @ts-ignore
import {default as wedge} from "../../../assets/icons/wedge.png"
import {translate} from "react-i18nify";
import SubSubSubTitle from "../fields/text/subsubsubtitle.text";

interface filterProps {
    width: number,
    onImageUpload: (file, type) => void,
    onChangeOrder: (e) => void,
    onChangeProfile: (e) => void,
}

class HeaderFilter extends React.Component<filterProps, { folded: boolean }> {
    constructor(props) {
        super(props);
        this.state = {folded: true}
        this.changeFoldState = this.changeFoldState.bind(this)
    }

    render() {
        return (
            <>
                <div className={"filter-header-container"}>
                    <DropdownFilter title={translate("hd.headerFilter.sortOn")} type={"order"}
                                    categories={[translate("hd.headerFilter.newest"), translate("hd.headerFilter.mostChosen")]}
                                    categoryCounts={[-1, -1]} onChange={this.props.onChangeOrder}
                                    filterTypes={["Nieuwste", "Meest gekozen"]}/>
                    <DropdownFilter title={translate("hd.headerFilter.images")} type={"profile"}
                                    categories={[translate("hd.headerFilter.allProfile"), translate("hd.headerFilter.largeProfile"), translate("hd.headerFilter.smallProfile"), translate("hd.headerFilter.noProfile")]}
                                    categoryCounts={[70, 28, 15]} onChange={this.props.onChangeProfile}
                                    filterTypes={["Alle profielfotos", "Grote profielfoto", "Kleine profielfoto", "Geen profielfoto"]}/>
                    <span style={{
                        fontWeight: "bold",
                        color: "#334c6e",
                        fontSize: "16px",
                        marginLeft: this.props.width < 768 ? "0" : "auto",
                        cursor: 'pointer'
                    }} onClick={this.changeFoldState}>{translate("hd.headerFilter.adjustCard")}<img
                        style={{width: "14px", transform: !this.state.folded ? "rotate(180deg)" : ""}}
                        src={wedge}/></span>
                </div>
                <div style={{
                    display: this.state.folded ? "none" : "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    width: "100%",
                    paddingLeft: "15%",
                    marginBottom: "20px"

                }}>
                    <div style={{
                        display: this.state.folded ? "none" : "flex",
                        flexDirection: this.props.width < 768 ? "column" : "row",
                        justifyContent: "flex-start",
                        width: "100%",
                        marginBottom: "20px"

                    }}>
                        <label
                            style={{marginTop: 0, marginRight: "15px", marginBottom: this.props.width < 768 ? 20 : 0,}}
                            htmlFor="file-upload2" className="profile-picture-upload-button">
                            {translate("hd.actions.uploadLogo")}
                        </label>
                        <input id={"file-upload2"} type={'file'} name={'file'}
                               onChange={(e) => this.props.onImageUpload(e, "logo")} style={{display: "none"}}/>


                        <label style={{marginTop: 0}}
                               htmlFor="file-upload" className="profile-picture-upload-button">
                            {translate("hd.actions.uploadProfile")}
                        </label>
                        <input id={"file-upload"} type={'file'} name={'file'}
                               onChange={(e) => this.props.onImageUpload(e, "profile")}
                               style={{display: "none"}}/>
                    </div>
                    <SubSubSubTitle style={{textAlign: "left", paddingRight: this.props.width < 768 ? "10%" : 0}}
                                    text={translate("hd.headerFilter.warning")}/>

                </div>

            </>)
    }

    changeFoldState() {
        this.setState({folded: !this.state.folded})
    }
}

export default HeaderFilter
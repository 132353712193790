import React, {useState} from 'react'
// @ts-ignore
import {default as cknl} from "../../assets/pvh/cards/calvinklein_card-nl.png"
// @ts-ignore
import {default as ckbe} from "../../assets/pvh/cards/calvinklein_card-be.png"
// @ts-ignore
import {default as pvhnl} from "../../assets/pvh/cards/pvh_card-nl.png"
// @ts-ignore
import {default as pvhbe} from "../../assets/pvh/cards/pvh_card-be.png"
// @ts-ignore
import {default as tommyeurbe} from "../../assets/pvh/cards/tommyeurope_card-be.png"
// @ts-ignore
import {default as tommyeurnl} from "../../assets/pvh/cards/tommyeurope_card-nl.png"
// @ts-ignore
import {default as tommyjeansbe} from "../../assets/pvh/cards/tommyjeans_card-be.png"
// @ts-ignore
import {default as tommyjeansnl} from "../../assets/pvh/cards/tommyjeans_card-nl.png"
// @ts-ignore
import {default as tommystoresnl} from "../../assets/pvh/cards/tommystores_card-nl.png"
// @ts-ignore
import {default as tommystoresbe} from "../../assets/pvh/cards/tommystores_card-be.png"

// @ts-ignore
import {default as pvhlogo} from "../../assets/pvh/pvhlogo.png"

// @ts-ignore
import {default as nlflag} from "../../assets/pvh/nlflag.svg"
// @ts-ignore
import {default as beflag} from "../../assets/pvh/beflag.svg"

import "./page.css"

// @ts-ignore
import {default as calvinklein} from "../../assets/pvh/companies/calvinklein.svg"
// @ts-ignore
import {default as pvheurope} from "../../assets/pvh/companies/pvheurope.svg"
// @ts-ignore
import {default as tommyeurope} from "../../assets/pvh/companies/tommyeurope.svg"
// @ts-ignore
import {default as tommyjeanes} from "../../assets/pvh/companies/tommyjeanes.svg"
// @ts-ignore
import {default as tommystores} from "../../assets/pvh/companies/tommystores.svg"
import MainButton from "../common2/buttons/action_main/main.button";
import ButtonColors from "../common2/buttons/button.colors";
import axios from "axios";


export function PVHRequestPage({width}) {
    const [submitted, setSubmitted] = useState(false)

    const [active, setActive] = useState("pvheurope")
    const [languageActive, setlanguageActive] = useState("nl")
    const [fullname, setFullname] = useState("")
    const [email, setEmail] = useState("")
    const [jobtitle, setJobtitle] = useState("")
    const [jobtitle2, setJobtitle2] = useState("")
    const [phone, setPhone] = useState("")
    const [mobile, setMobile] = useState("")
    return (
        <div className={"pvh-request-container"}>
            <div
                style={{display: "flex", flexDirection: "column", alignItems: "flex-start", width: "100%"}}>
                <img style={{width: 80}} src={pvhlogo}/>
                <h1 style={{marginBottom: 1}}>Request a digital business card</h1>
                <h3 style={{fontSize: 14, opacity: 0.6, fontWeight: "normal", marginBottom: 30, marginTop: 10}}>Select
                    the correct
                    country and
                    identity</h3>
                <div style={{flex: "row"}}>
                    <img onClick={() => setlanguageActive("nl")} src={nlflag}
                         style={{opacity: languageActive === "be" ? 0.3 : 1}}/>
                    <img onClick={() => setlanguageActive("be")}
                         style={{marginLeft: 15, opacity: languageActive === "nl" ? 0.3 : 1}} src={beflag}/>
                </div>
                <div style={{display: "flex", flexDirection: "row", flexWrap: "wrap", marginTop: 20}}>
                    <div
                        className={"pvh-button" + (active === "pvheurope" ? " pvh-active" : "")}
                        onClick={() => setActive("pvheurope")}>
                        <img src={pvheurope}/>
                        <span className={"pvh-button-label"}>PVH Europe B.V.</span>
                    </div>
                    <div
                        className={"pvh-button" + (active === "calvinklein" ? " pvh-active" : "")}
                        onClick={() => setActive("calvinklein")}>
                        <img src={calvinklein}/>
                        <span className={"pvh-button-label"}>Calvin Klein Europe B.V.</span>
                    </div>
                    <div
                        className={"pvh-button" + (active === "tommyeurope" ? " pvh-active" : "")}
                        onClick={() => setActive("tommyeurope")}>
                        <img src={tommyeurope}/>
                        <span className={"pvh-button-label"}>Tommy Hilfiger <br/> Europe B.V.</span>
                    </div>
                    <div
                        className={"pvh-button" + (active === "tommyjeans" ? " pvh-active" : "")}
                        onClick={() => setActive("tommyjeans")}>
                        <img src={tommyjeanes}/>
                        <span className={"pvh-button-label"}>Tommy Jeans {languageActive === "nl" ? "NL" : "BE"}</span>
                    </div>
                    <div
                        className={"pvh-button" + (active === "tommystores" ? " pvh-active" : "")}
                        onClick={() => setActive("tommystores")}>
                        <img src={tommystores}/>
                        <span className={"pvh-button-label"}>Tommy Hilfiger Stores {languageActive === "nl" ? "NL" : "BE"}</span>
                    </div>
                </div>
                <h2>Your Information</h2>
                <div className={"pvh-input-row"}>
                    <div className={"pvh-input-column"}>
                        <label className={"pvh-label"} htmlFor={"fullname"}>Name & Surname <span
                            style={{color: "#FF0000"}}>*</span></label>
                        <input
                            onChange={(e) => setFullname(e.target.value)} value={fullname}
                            id={"fullname"} name={"fullname"} placeholder={"Fullname"} className={"pvh-input"}/>
                    </div>
                    <div className={"pvh-input-column"}>
                        <label className={"pvh-label"} htmlFor={"email"}>Email Address<span
                            style={{color: "#FF0000"}}>*</span></label>
                        <input
                            onChange={(e) => setEmail(e.target.value)} value={email}
                            id={"email"} name={"email"} placeholder={"Email"} className={"pvh-input"}/>
                    </div>
                </div>
                <div className={"pvh-input-row"}>
                    <div className={"pvh-input-column"}>
                        <label htmlFor={"jobtitle"} className={"pvh-label"}>Jobtitle</label>
                        <input id={"jobtitle"} name={"jobtitle"} placeholder={"Jobtitle"} className={"pvh-input"}
                               onChange={(e) => setJobtitle(e.target.value)} value={jobtitle}
                        />
                    </div>
                    <div className={"pvh-input-column"}>
                        <label htmlFor={"jobtitle2"} className={"pvh-label"}>Jobtitle 2</label>
                        <input id={"jobtitle2"} name={"jobtitle2"} placeholder={"Jobtitle 2"} className={"pvh-input"}
                               onChange={(e) => setJobtitle2(e.target.value)} value={jobtitle2}
                        />
                    </div>
                </div>
                <div className={"pvh-input-row"} style={{marginBottom: 10}}>
                    <div className={"pvh-input-column"}>
                        <label htmlFor={"phone"} className={"pvh-label"}>Phone</label>
                        <div
                            style={{
                                display: "flex",
                                width: "95%",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}>
                            <span style={{
                                fontSize: 13,
                                marginRight: 5,
                                display: "block"
                            }}>{languageActive === "nl" ? "+31(0)" : "+32(0)"}</span>
                            <input id={"phone"} name={"phone"} placeholder={"Phone"}
                                   style={{marginBottom: 0}}
                                   onChange={(e) => setPhone(e.target.value)} value={phone}

                                   className={"pvh-input"}/>
                        </div>
                    </div>
                    <div className={"pvh-input-column"}>
                        <label htmlFor={"mobilephone"} className={"pvh-label"}>Mobile Phone</label>
                        <div
                            style={{
                                display: "flex",
                                width: "95%",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}>
                            <span style={{
                                fontSize: 13,
                                marginRight: 5,
                                display: "block"
                            }}>{languageActive === "nl" ? "+31(0)6" : "+32(0)4"}</span>

                            <input id={"mobilephone"} name={"mobilephone"}
                                   style={{marginBottom: 0}}
                                   placeholder={"Mobile Phone"}
                                   onChange={(e) => setMobile(e.target.value)} value={mobile}
                                   className={"pvh-input"}/>
                        </div>
                    </div>
                </div>
                <span
                    style={{color: "#747474", fontSize: 13, marginBottom: 10, fontStyle: "italic"}}>Don’t use spaces between the numbers</span>
                <span style={{color: "#747474", fontSize: 13, marginBottom: 10, fontStyle: "italic"}}><span
                    style={{color: "#FF0000"}}>*</span> Required</span>
                <span style={{color: "#747474", fontSize: 13, fontWeight: "bold", marginBottom: 10}}>Your request will be sent to the Facilities Department.</span>
                {submitted ?
                    <span style={{color: "#0037da"}}>Your request has been submitted. You will find the confirmation in the entered email address</span> :
                    <MainButton onClick={() => {
                        submitForm(fullname, email, jobtitle, jobtitle2, phone, mobile, languageActive, active, setSubmitted)
                    }}
                                style={{
                                    fontFamily: "Inter, serif",
                                    display: (!!fullname && !!email && email.includes("@") && ["@pvh.com", "@ck.com", "@tommy.com"].includes("@" + email.split("@")[1]) ? "block" : "none")
                                }}
                                color={ButtonColors.orange}
                                text={"Request Card"}/>}

            </div>
            <div className={"pvh-image-container"}>
                <img style={{maxWidth: 300, boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.10)"}}
                     src={getCorrectDesign(active, languageActive)}/>
            </div>
        </div>)
}

function getCorrectDesign(company, country) {
    switch (country) {
        case "nl":
            switch (company) {
                case "pvheurope":
                    return pvhnl
                case "calvinklein":
                    return cknl
                case "tommyeurope":
                    return tommyeurnl
                case "tommyjeans":
                    return tommyjeansnl
                case "tommystores":
                    return tommystoresnl
            }
            break
        case "be":
            switch (company) {
                case "pvheurope":
                    return pvhbe
                case "calvinklein":
                    return ckbe
                case "tommyeurope":
                    return tommyeurbe
                case "tommyjeans":
                    return tommyjeansbe
                case "tommystores":
                    return tommystoresbe
            }
            break
    }
}

function submitForm(fullname, email, jobtitle, jobtitle2, phone, mobile, country, company, setSubmitted) {
    axios.post(`/api/person/pvh/request`, {
        fullname: fullname,
        email: email,
        jobtitle: jobtitle,
        jobtitle2: jobtitle2,
        tel: phone,
        mobile: mobile,
        country: country,
        company: company
    })
        .then(res => {
            setSubmitted(true)
        })
}
import React, {useEffect} from 'react';
import '../scss/layout.scss';

import {BrowserRouter, Route, Switch, useLocation} from 'react-router-dom';
import Shop from "./Shop/Shop.container";
import PrivateRoute from "./common/PrivateRoute";
import Dashboard from "./oldbackoffice/dashboard/Dashboard";
import AddColleague from "./Editor/AddColleagues/AddColleague/AddColleague";
import ReactGA from 'react-ga';
import rg4js from 'raygun4js'
import axios from "axios";

// function getCookie(name) {
//     var match = document.cookie.match(RegExp('(?:^|;\\s*)' + name + '=([^;]*)'));
//     return match ? match[1] : null;
// }

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return false;
}

if (getCookie("CookieConsent") /*&& !window.location.href.includes("localhost")*/) {
    ReactGA.initialize('UA-73972616-6');
    ReactGA.plugin.require("ecommerce");
    sessionStorage.setItem("UAinitialised", true)
}

//Setup GA
function updateGA() {
    if (sessionStorage.getItem("UAinitialised")) {
        ReactGA.pageview(window.location.pathname + window.location.search);
        rg4js('trackEvent', {type: 'pageView', path: window.location.pathname});
    }

}


function postPaths(paths, userId) {

    console.log("Got here")
    axios.post("/api/stats/postPaths", {paths: paths, userId: userId}).then(response => {
        console.log(response)
    })
}


export default class MainRouter extends React.Component {

    componentDidMount() {
        window.addEventListener('beforeunload', this.handleBeforeUnload);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    handleBeforeUnload = (e) => {

        let userId = localStorage.getItem("uid")
        let paths = sessionStorage.getItem("paths")
        postPaths(JSON.parse(paths), userId)

    };

    render() {
        // updateGA();

        return <BrowserRouter>
            <section className="flowContainer">
                <Switch>
                    <PrivateRoute path={'/dashboard'} component={Dashboard} refto={"back"}/>
                    <PrivateRoute path={'/addcolleague'} component={AddColleague} refto={'selectcolleague'}>

                    </PrivateRoute>
                    <Route path={'/'}>

                        <Shop/>
                    </Route>

                </Switch>
            </section>
        </BrowserRouter>
    }

}

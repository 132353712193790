import React from "react";
import '../headers/header.styles.css'

// @ts-ignore
import {default as beemster} from "../../../assets/logo/svg/beemster.svg";
// @ts-ignore
import {default as blijdorp} from "../../../assets/logo/svg/blijdorp.svg";
// @ts-ignore
import {default as duravermeer} from "../../../assets/logo/svg/duravermeer.svg";
// @ts-ignore
import {default as elipslife} from "../../../assets/logo/svg/elipslife.svg";
// @ts-ignore
import {default as enactus} from "../../../assets/logo/svg/enactus.svg";
// @ts-ignore
import {default as enphase} from "../../../assets/logo/svg/enphase.svg";
// @ts-ignore
import {default as foodvalley} from "../../../assets/logo/svg/foodvalley.svg";
// @ts-ignore
import {default as frieslandlease} from "../../../assets/logo/svg/frieslandlease.svg";
// @ts-ignore
import {default as gemeentekoevorden} from "../../../assets/logo/svg/gemeentekoevorden.svg";
// @ts-ignore
import {default as hendrikidoambacht} from "../../../assets/logo/svg/hendrikidoambacht.svg";
// @ts-ignore
import {default as hurks} from "../../../assets/logo/svg/hurks.svg";
// @ts-ignore
import {default as ingka} from "../../../assets/logo/svg/ingka.svg";
// @ts-ignore
import {default as jll} from "../../../assets/logo/svg/jll.svg";
// @ts-ignore
import {default as knauf} from "../../../assets/logo/svg/knauf.svg";
// @ts-ignore
import {default as kone} from "../../../assets/logo/svg/kone.svg";
// @ts-ignore
import {default as makeawish} from "../../../assets/logo/svg/makeawish.svg";
// @ts-ignore
import {default as mecc} from "../../../assets/logo/svg/mecc.svg";
// @ts-ignore
import {default as multicopy} from "../../../assets/logo/svg/multicopy.svg";
// @ts-ignore
import {default as newomij} from "../../../assets/logo/svg/newomij.svg";
// @ts-ignore
import {default as nibud} from "../../../assets/logo/svg/nibud.svg";
// @ts-ignore
import {default as pfizer} from "../../../assets/logo/svg/pfizer.svg";
// @ts-ignore
import {default as provincienoordbrabant} from "../../../assets/logo/svg/provincienoordbrabant.svg";
// @ts-ignore
import {default as rijksmuseum} from "../../../assets/logo/svg/rijksmuseum.svg";
// @ts-ignore
import {default as site} from "../../../assets/logo/svg/site.svg";
// @ts-ignore
import {default as sonneveld} from "../../../assets/logo/svg/sonneveld.svg";
// @ts-ignore
import {default as tommyhilfiger} from "../../../assets/logo/svg/tommyhilfiger.svg";
// @ts-ignore
import {default as viapass} from "../../../assets/logo/svg/viapass.svg";
// @ts-ignore
import {default as volkerwessels} from "../../../assets/logo/svg/volkerwessels.svg";
// @ts-ignore
import {default as wbl} from "../../../assets/logo/svg/wbl.svg";
// @ts-ignore
import {default as zuiver} from "../../../assets/logo/svg/zuiver.svg";


interface homeHeaderProps {
    width: number,
    logoCount: number,
    style?: any,

}

class LogoBlock extends React.Component<homeHeaderProps, { randomLogos: number[] }> {

    constructor(props) {
        super(props);
        let arr = [];
        const allLogos = [
            "beemster", "blijdorp", "duravermeer", "elipslife", "enactus", "enphase", "foodvalley", "frieslandlease", "gemeentekoevorden", "hendrikidoambacht", "hurks", "ingka", "jll", "knauf", "kone", "makeawish", "mecc", "multicopy", "newomij", "nibud", "pfizer", "provincienoordbrabant", "rijksmuseum", "site", "sonneveld", "tommyhilfiger", "viapass", "volkerwessels", "wbl", "zuiver"
        ]
        while (arr.length < 6) {
            let r = Math.floor(Math.random() * allLogos.length - 1) + 1;
            if (arr.indexOf(r) === -1) arr.push(r);
        }
        this.state = {
            randomLogos: arr
        }
        setInterval(this.changeLogos, 5000)
    }

    changeLogos = () => {
        let arr = []
        const allLogos = [
            "beemster", "blijdorp", "duravermeer", "elipslife", "enactus", "enphase", "foodvalley", "frieslandlease", "gemeentekoevorden", "hendrikidoambacht", "hurks", "ingka", "jll", "knauf", "kone", "makeawish", "mecc", "multicopy", "newomij", "nibud", "pfizer", "provincienoordbrabant", "rijksmuseum", "site", "sonneveld", "tommyhilfiger", "viapass", "volkerwessels", "wbl", "zuiver"
        ]
        while (arr.length < 6) {
            let r = Math.floor(Math.random() * allLogos.length - 1) + 1;
            if (arr.indexOf(r) === -1) arr.push(r);
        }
        this.setState({randomLogos: arr})
    }

    render() {
        const allLogos = [
            "beemster", "blijdorp", "duravermeer", "elipslife", "enactus", "enphase", "foodvalley", "frieslandlease", "gemeentekoevorden", "hendrikidoambacht", "hurks", "ingka", "jll", "knauf", "kone", "makeawish", "mecc", "multicopy", "newomij", "nibud", "pfizer", "provincienoordbrabant", "rijksmuseum", "site", "sonneveld", "tommyhilfiger", "viapass", "volkerwessels", "wbl", "zuiver"
        ]
        let arr = this.state.randomLogos
        const logoMap = {
            beemster: beemster,
            blijdorp: blijdorp,
            duravermeer: duravermeer,
            elipslife: elipslife,
            enactus: enactus,
            enphase: enphase,
            foodvalley: foodvalley,
            frieslandlease: frieslandlease,
            gemeentekoevorden: gemeentekoevorden,
            hendrikidoambacht: hendrikidoambacht,
            hurks: hurks,
            ingka: ingka,
            jll: jll,
            knauf: knauf,
            kone: kone,
            makeawish: makeawish,
            mecc: mecc,
            multicopy: multicopy,
            newomij: newomij,
            nibud: nibud,
            pfizer: pfizer,
            provincienoordbrabant: provincienoordbrabant,
            rijksmuseum: rijksmuseum,
            site: site,
            sonneveld: sonneveld,
            tommyhilfiger: tommyhilfiger,
            viapass: viapass,
            volkerwessels: volkerwessels,
            wbl: wbl,
            zuiver: zuiver,
        }
        let maxHeight = 100;
        if (this.props.width < 768) {
            return <div className={"logo-container"} style={this.props.style}>
                <img style={{opacity: 0.7, maxHeight: maxHeight}} alt={allLogos[arr[0]]}
                     src={logoMap[allLogos[arr[0]]]}/>
                <img style={{opacity: 0.7, maxHeight: maxHeight}} alt={allLogos[arr[1]]}
                     src={logoMap[allLogos[arr[1]]]}/>
                <img style={{opacity: 0.7, maxHeight: maxHeight}} alt={allLogos[arr[2]]}
                     src={logoMap[allLogos[arr[2]]]}/>
            </div>

        }
        maxHeight = 120;
        let logos: any = []
        for (let i = 0; i < this.props.logoCount; i++) {
            logos.push(<img style={{opacity: 0.7, maxHeight: maxHeight}} alt={allLogos[arr[i]]}
                            src={logoMap[allLogos[arr[i]]]}/>)
        }

        return (<div className={"logo-container"} style={this.props.style}>
            {logos}
            {/*<img style={{opacity: 0.7, maxHeight: maxHeight}} alt={allLogos[arr[1]]} src={logoMap[allLogos[arr[1]]]}/>*/}
            {/*<img style={{opacity: 0.7, maxHeight: maxHeight}} alt={allLogos[arr[2]]} src={logoMap[allLogos[arr[2]]]}/>*/}
            {/*<img style={{opacity: 0.7, maxHeight: maxHeight}} alt={allLogos[arr[3]]} src={logoMap[allLogos[arr[3]]]}/>*/}
            {/*<img style={{opacity: 0.7, maxHeight: maxHeight}} alt={allLogos[arr[4]]} src={logoMap[allLogos[arr[4]]]}/>*/}
            {/*<img style={{opacity: 0.7, maxHeight: maxHeight}} alt={allLogos[arr[5]]} src={logoMap[allLogos[arr[5]]]}/>*/}
        </div>)

    }

}

export default LogoBlock;
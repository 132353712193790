import React from 'react'
import GrayContainer from "../common2/containers/gray.container";
import NavigationHeader from "../common2/headers/navigation.header";
import PageTitle from "../common2/fields/text/pagetitle.text";
import SubSubTitle from "../common2/fields/text/subsubtitle.text";
import Footer from "../common2/footers/footer";
import {getAllTemplates} from "../../actions/templateActions"
import {getAllVariations} from "../../actions/variationsActions"
import {connect} from "react-redux";
import BusinessCardsFlow from "../common2/flow/businesscards.flow";
import HeaderFilter from "../common2/filters/header.filter";
import GradientContainer from "../common2/containers/gradient.container";
import IconsBlock from "../common2/blocks/icons.block";
import {translate} from "react-i18nify";

import {uploadPassportPhoto} from "../../actions/personActions";
import SubSubSubTitle from "../common2/fields/text/subsubsubtitle.text";

interface pageProps {
    width: number,
    getAllTemplates: () => void,
    getAllVariations: () => void,
    uploadPassportPhoto: (file) => void,
    templates: any,
    persons: any,
    variations: any,
}

interface businesscardsState {
    templates: any,
    variations: any,
    personContent: any,
    logo?: string,
    order: string,
    profileFilter: string,
}

class BusinesscardsPage extends React.Component<pageProps, businesscardsState> {

    constructor(props) {
        super(props);
        let personString = localStorage.getItem("person")
        let personContent = {
            firstname: translate("chooseDesigns.fields.firstname"),
            pasfoto: "https://res.cloudinary.com/bizzerd-b-v/image/upload/v1680172291/dt3sixmvarf6ht35qpoi.jpg",
            lastname: translate("chooseDesigns.fields.lastname"),
            fullname: translate("chooseDesigns.fields.fullname"),
            jobtitle: translate("chooseDesigns.fields.title"),
            tel: translate("chooseDesigns.fields.tel"),
            mobile: translate("chooseDesigns.fields.mobile"),
            email: translate("chooseDesigns.fields.email"),
            adresveld: translate("chooseDesigns.fields.adres"),
            website: translate("chooseDesigns.fields.website"),
            facebook: "https://facebook.com",
            twitter: "https://twitter.com",
            linkedin: "https://linkedin.com",
            instagram: "https://instagram.com",
            vimeo: "https://vimeo.com",
        };
        if (personString) {
            personContent = JSON.parse(personString)
        }
        let orderString = new URLSearchParams(window.location.search).get("order")
        let profileString = new URLSearchParams(window.location.search).get("profile")
        this.state = {
            order: orderString ? orderString : "Nieuwste",
            profileFilter: profileString ? profileString : "Alle profielfotos",
            templates: [],
            variations: [],
            personContent: personContent,
        }
        this.props.getAllTemplates();
        this.props.getAllVariations();


        this.changeOrder = this.changeOrder.bind(this)
        this.changeProfile = this.changeProfile.bind(this)

    }

    componentWillReceiveProps(nextProps: Readonly<pageProps>, nextContext: any) {
        if (nextProps.templates && nextProps.templates.templates) {
            this.setState({templates: nextProps.templates.templates})
        }
        if (nextProps.variations && nextProps.variations.variations) {
            this.setState({variations: nextProps.variations.variations})
        }
        if (nextProps.persons && nextProps.persons.photoUrl && nextProps.persons.photoUrl.url) {
            if (nextProps.persons.photoUrl.type === "profile")
                this.setState({personContent: {...this.state.personContent, pasfoto: nextProps.persons.photoUrl.url}})
            else {
                this.setState({logo: nextProps.persons.photoUrl.url})
            }
        }
    }

    changeOrder(e) {
        const searchParams = new URLSearchParams();
        let profileString = new URLSearchParams(window.location.search).get("profile")
        searchParams.append("page", "1");
        searchParams.append("order", e.target.value);
        if (profileString)
            searchParams.append("profile", profileString);

        window.gtag('event', 'view_search_results', {search_term: e.target.value + " - " + this.state.profileFilter});
        window.location.href = translate("hd.links.businessCards") + "?" + searchParams.toString();

    }

    changeProfile(e) {
        const searchParams = new URLSearchParams();
        let orderString = new URLSearchParams(window.location.search).get("order")
        searchParams.append("page", "1");
        searchParams.append("profile", e.target.value);
        if (orderString)
            searchParams.append("order", orderString);
        window.gtag('event', 'view_search_results', {search_term: this.state.order + " - " + e.target.value});
        window.location.href = translate("hd.links.businessCards") + "?" + searchParams.toString();

    }

    render() {
        return (<><GrayContainer style={{paddingBottom: "40px"}}>
            <NavigationHeader width={this.props.width}/>
            <div style={{
                width: "100%",
                alignSelf: "flex-start",
                paddingLeft: "15%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column"
            }}>
                <PageTitle style={{paddingTop: "40px", paddingBottom: "10px", fontSize: "40px"}}
                           text={translate("hd.businessCardPage.pageTitle")}/>

                <SubSubTitle style={{fontFamily: "Outfit-Regular, serif"}}
                             text={translate("hd.businessCardPage.subTitle")}/>

                <br/>
                <SubSubSubTitle style={{fontFamily: "Outfit-Regular, serif", color: "#334c6e"}}
                                text={translate("hd.businessCardPage.enterpriseSelling")}/>
                <br/>
                <a className={"text-link"}
                   href={translate("hd.links.enterprise")}>{translate("hd.actions.clickHere")}</a>
            </div>
        </GrayContainer>
            <div className={"business-cards-container"}>
                <HeaderFilter width={this.props.width} onImageUpload={this.onImageUpload}
                              onChangeOrder={this.changeOrder} onChangeProfile={this.changeProfile}/>
                {this.state.variations ?
                    <BusinessCardsFlow templates={this.state.templates}
                                       variations={this.applyFilter(this.state.variations)}
                                       personContent={this.state.personContent} logo={this.state.logo}
                                       width={this.props.width}/> : <></>}


            </div>

            <GradientContainer
                style={{marginTop: "0px", backgroundImage: "linear-gradient(to top, #42809620, #ffffff40)"}}
                reverse={false}>
                <IconsBlock width={this.props.width} bottomBorder={true}/><></>
            </GradientContainer>
            <Footer/>
        </>);
    }

    applyFilter(variations) {
        if (!variations)
            return variations
        let newVariations = []
        const order = this.state.order
        const profileFilter = this.state.profileFilter
        newVariations = this.applyOrder(variations, order);
        newVariations = this.applyProfile(newVariations, profileFilter);
        return newVariations
    }

    applyOrder(variations, order) {
        if (order === "Meest gekozen") {
            return variations
        }
        let varCopy = JSON.parse(JSON.stringify(variations))
        if (order === "Nieuwste") {
            varCopy.sort((x, y) => {
                // @ts-ignore
                return (x.dates[0] < y.dates[0]) - (x.dates[0] > y.dates[0])
            })
        }
        return varCopy
    }

    applyProfile(variations, profile) {
        if (profile === "Alle profielfotos") {
            return variations
        }
        let varCopy = JSON.parse(JSON.stringify(variations))

        let newVars: Array<any> = []
        varCopy.forEach(variation => {
            if (variation.pictureTypes.includes(profile)) {
                newVars.push(variation);
            }
        })
        newVars.map(variation => {
            for (let i = variation.templateIds.length - 1; i >= 0; i--) {
                if (variation.pictureTypes[i] !== profile) {
                    variation.pictureTypes.splice(i, 1)
                    variation.dates.splice(i, 1)
                    variation.templateIds.splice(i, 1)
                    variation.colors.splice(i, 1)
                }
            }
            return variation
        })
        return newVars

    }

    onImageUpload = (file, type) => {
        const formData = new FormData();
        if (!file)
            return
        if (type === "profile" && file.target.files[0])
            this.setState({personContent: {...this.state.personContent, pasfoto: "/ezgif-4-09613decb1.gif"}})
        formData.append("file", file.target.files[0]);
        formData.append("type", type);
        this.props.uploadPassportPhoto(formData);
    };


}

const mapStateToProps = (state) => ({
    templates: state.templates,
    variations: state.variations,
    persons: state.persons,
});

export default connect(mapStateToProps, {getAllTemplates, getAllVariations, uploadPassportPhoto})(BusinesscardsPage);
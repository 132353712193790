import React from "react";
import './header.styles.css'
import ButtonColors from "../buttons/button.colors";
// @ts-ignore
import {default as shoppingCart} from "../../../assets/icons/shoppingcart.icon.svg";
// @ts-ignore
import {default as bizzerdLogo} from '../../../assets/img/bizzerd_logo.png';
// @ts-ignore
import {default as graphicBig} from '../../../assets/home/landing.home.png';
// @ts-ignore
import {default as graphicSmall} from '../../../assets/home/landingsmall.home.png';
// @ts-ignore
import {default as qrcode} from '../../../assets/icons/qrcode.icon.svg';
import PageTitle from "../fields/text/pagetitle.text";
import MainButton from "../buttons/action_main/main.button";
import {translate} from "react-i18nify";
import LogoBlock from "../blocks/logo.block";
import SubTitle from "../fields/text/subtitle.text";
import Lottie from "react-lottie";
import axios from "axios";


interface homeHeaderProps {
    width: number
}

class HomeHeader extends React.Component<homeHeaderProps, { animationData: any }> {
    constructor(props: homeHeaderProps) {
        super(props);
        this.state = {
            animationData: null,
        };
    }

    async componentDidMount() {
        try {
            const response = await axios.get('/api/nfc/lottie/Home_animatiev1.9.json');
            const json = await response.data;
            this.setState({animationData: json});
        } catch (error) {
            console.error('Error fetching animation data:', error);
        }
    }

    render() {
        let {usp1, usp2, usp3} = this.determineUsps()
        const defaultOptions = {
            loop: true, // Set to true if you want the animation to loop
            autoplay: true, // Set to true to play the animation as soon as it's loaded
            animationData: this.state.animationData, // Your Lottie animation JSON file
        };
        if (this.props.width < 768) {
            return (<>
                <div className={"home-header-container"} style={{padding: "0px 20px 5%", overflow: "hidden"}}>
                    <div className={"home-header-left"} style={{width: "65%", zIndex: 2}}>
                        <PageTitle text={translate("hd.home.pageTitle")}
                                   style={{
                                       marginBottom: 5,
                                       paddingBottom: 5,
                                       fontSize: "36px",
                                   }}/>
                        <SubTitle text={translate("hd.home.pageSubtitle")} thin={true}></SubTitle>

                        <div className={"home-header-buttons"}>
                            <MainButton color={ButtonColors.orange} href={translate("hd.links.contact")}
                                        style={{marginRight: "10px", fontSize: 18}}
                                        text={translate("hd.home.startNow")}/>
                            <MainButton color={ButtonColors.white} newPage={true} style={{fontSize: 18}}
                                        href={"https://bizzerd.com/show/bizzerd_app/marieke"}
                                        text={translate("hd.home.moreInfo")}/>
                        </div>

                    </div>

                    <Lottie options={defaultOptions}
                            style={{minWidth: 350, left: this.props.width < 500 ? -120 : 0, position: "relative", zIndex: 1}}/>

                </div>
            </>)
        }

        return (<>
            <div className={"home-header-container"}>
                <div className={"home-header-left"}>
                    <div>
                        <PageTitle text={translate("hd.home.pageTitle")}
                                   style={this.props.width < 768 ? {
                                       marginBottom: "20px",
                                       fontSize: "28px",
                                   } : {marginBottom: "20px", fontSize: "40px", width: "100%"}}/>
                        <SubTitle text={translate("hd.home.pageSubtitle")} thin={true}
                                  style={{marginLeft: 10}}></SubTitle>
                    </div>
                    <div className={"home-header-usp"}>
                    <span
                        className={"home-header-usp-item"}>{translate("hd.home.pageContent")}</span>
                        {usp1}
                        {usp2}
                        {usp3}

                    </div>
                    <div className={"home-header-buttons"}>
                        <MainButton color={ButtonColors.orange} href={translate("hd.links.contact")}
                                    style={{marginRight: "10px"}}
                                    text={translate("hd.home.startNow")}/>
                        <MainButton color={ButtonColors.white} newPage={true}
                                    href={"https://bizzerd.com/show/bizzerd_app/marieke"}
                                    text={translate("hd.home.moreInfo")}/>
                    </div>
                </div>
                <div className={"home-header-right"}>
                    <Lottie options={defaultOptions} style={{minWidth: 350}}/>
                </div>

            </div>
        </>)

    }

    determineUsps = () => {
        let uspString = translate("hd.home.usp1").split("<b>")
        let usp1 = <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}><span
            className={"check-mark-blue"}>✔︎</span>
            <span
                className={"home-header-usp-item"}>{uspString[0]}<b
                style={{color: "#428096"}}>{uspString[1].split("</b>")[0]}</b>{uspString[1].split("</b>")[1]}</span>
        </div>

        uspString = translate("hd.home.usp2").split("<b>")


        let usp2 = <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}><span
            className={"check-mark-blue"}>✔︎</span>
            <span
                className={"home-header-usp-item"}>{uspString[0]}<b
                style={{color: "#428096"}}>{uspString[1].split("</b>")[0]}</b>{uspString[1].split("</b>")[1]}</span>
        </div>

        uspString = translate("hd.home.usp3").split("<b>")

        let usp3 = <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}><span
            className={"check-mark-blue"}>✔︎</span>
            <span
                className={"home-header-usp-item"}>{uspString[0]}<b
                style={{color: "#428096"}}>{uspString[1].split("</b>")[0]}</b>{uspString[1].split("</b>")[1]}</span>
        </div>

        return {usp1, usp2, usp3}

    }


}

export default HomeHeader;

import {useEffect, useState} from "react";
import {TemplatePicker} from "./template-picker";
import {FlowStepNavigation} from "./flow-step-navigation";
import {PersonalDetails} from "./personal-details";
import {CustomizeTemplate} from "./customize-template";
import {StepPayment} from "./step-payment";
import {ChoosePlan} from "./choose-plan";
import {templates} from "./templates";
import {translate} from "react-i18nify";
import axios from "axios/index";
import setAuthToken from "../../utils/setAuthToken";

export const StepFlow = () => {
    const [step, setStep] = useState(1)
    const [template, setTemplate] = useState(templates[0])
    const [paymentPlan, setPaymentPlan] = useState(0)
    const [users, setUsers] = useState(10)
    const [personValidated, setPersonValidated] = useState(false)
    const [errors, setErrors] = useState({})
    const [person, setPerson] = useState({
        firstname: translate("chooseDesigns.fields.firstname"),
        pasfoto: "https://res.cloudinary.com/bizzerd-b-v/image/upload/v1680172291/dt3sixmvarf6ht35qpoi.jpg",
        lastname: translate("chooseDesigns.fields.lastname"),
        fullname: translate("chooseDesigns.fields.fullname"),
        jobtitle: translate("chooseDesigns.fields.title"),
        companyname: translate("chooseDesigns.fields.company"),
        tel: translate("chooseDesigns.fields.tel"),
        mobile: translate("chooseDesigns.fields.mobile"),
        email: translate("chooseDesigns.fields.email"),
        adresveld: translate("chooseDesigns.fields.adres"),
        website: translate("chooseDesigns.fields.website"),
        linkedin: "https://test.com",
        facebook: "https://test.com",
        twitter: "https://test.com",
        instagram: "https://test.com",
        youtube: "https://test.com",
    })

    const [width, setWidth] = useState(window.innerWidth)
    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        }

        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);


    const startPayment = async () => {
        const response = await axios.post("/api/company/submit/checkout", {person, template, paymentPlan, users})
        if (response.data.success) {
            window.location.href = "/dashboard/simple"
            setAuthToken(response.data.token)
            localStorage.setItem("jwtToken", response.data.token)
        } else {
            setStep(2)
            setErrors(response.data.errors)
        }
    }

    const submitTryForFree = async () => {
        const response = await axios.post("/api/company/submit/tryforfree", {person, template})
        if (response.data.success) {
            window.location.href = "/dashboard/simple"
            setAuthToken(response.data.token)
            localStorage.setItem("jwtToken",response.data.token)
        } else {
            setStep(2)
            setErrors(response.data.errors)
        }
    }

    const [defaultLogoHeight, setDefaultLogoHeight] = useState(template.image.filter(image => image.logo)[0].height)
    const [defaultLogoWidth, setDefaultLogoWidth] = useState(template.image.filter(image => image.logo)[0].width)
    let content;
    switch (step) {
        case 1:
            content = <TemplatePicker templates={templates} setTemplate={setTemplate} step={step} setStep={setStep}
                                      person={person} width={width}/>
            break
        case 2:
            content = <PersonalDetails step={step} template={template} setStep={setStep} setTemplate={setTemplate} setPersonValidated={setPersonValidated}
                                       person={person} setPerson={setPerson} errors={errors} setErrors={setErrors} width={width}/>
            break
        case 3:
            content = <CustomizeTemplate step={step} setStep={setStep} person={person} template={template}
                                         setTemplate={setTemplate}
                                         defaultLogoHeight={defaultLogoHeight}
                                         setDefaultLogoWidth={setDefaultLogoWidth}
                                         setDefaultLogoHeight={setDefaultLogoHeight}
                                         defaultLogoWidth={defaultLogoWidth}/>
            break
        case 4:
            content =
                <ChoosePlan step={step} setStep={setStep} person={person} template={template} paymentPlan={paymentPlan}
                            setPaymentPlan={setPaymentPlan} users={users} setUsers={setUsers}
                            submitTryForFree={submitTryForFree}/>
            break
        case 5:
            content = <StepPayment step={step} setStep={setStep} person={person} template={template}
                                   paymentPlan={paymentPlan} users={users} setUsers={setUsers}
                                   startPayment={startPayment}/>
            break

    }

    return (<div className={"step-flow-container"}>
        <FlowStepNavigation width={width} s={step} setStep={setStep} personValidated={personValidated}/>
        {content}
    </div>)

}
import React from "react";
import SubSubSubTitle from "../../fields/text/subsubsubtitle.text";
import EditorFooter from "./footer.editor";
import SecondaryButton from "../../buttons/action_secondary/secondary.button";
import ButtonColors from "../../buttons/button.colors";
import ColorPicker from "../../../common/ColorPicker/ColorPicker.component";
import {translate} from "react-i18nify";

interface editorProps {
    width: number,
    folded: boolean,
    changeSelected: (blockNr) => void,
    onImageUpload: (file, type) => void,
    selectedField?: { text: string, color: string },
    updateColor: (color) => void,
    deleteField: () => void,
    reset: () => void,
}

class ColorEditor extends React.Component<editorProps, {}> {
    render() {
        let colorPicker
        if (this.props.selectedField) {
            colorPicker = <>
                <ColorPicker color={this.props.selectedField.color ? this.props.selectedField.color : "#000"}
                             onChange={this.props.updateColor}
                             name={this.props.selectedField.text}
                             label={this.props.selectedField.text}/>
                <span style={{color: "#F00", fontWeight: "bold", marginTop: "10px", cursor: "pointer"}}
                      onClick={this.props.deleteField}>{translate("hd.editor.delete")}</span>

            </>
        }
        return <div className={"editor-container"}>
            <div className={"personal-detail-outer-container"} onClick={() => {
                if (this.props.folded)
                    this.props.changeSelected(3)
            }} style={{
                maxHeight: (this.props.folded ? "70px" : "100vh"),
                cursor: (this.props.folded ? "pointer" : "")
            }}>
                <div className={"editor-header"}>
                    <span className={"pagination-ball"}>3</span>
                    <SubSubSubTitle style={{textAlign: "left"}} text={translate("hd.editor.colorLogo")}/>
                </div>
                <div className={"personal-detail-inner-container"}>
                    <SubSubSubTitle
                        text={translate("hd.editor.correctLogoMessage")}/>
                    <div className={"editor-footer-container"} style={{justifyContent: "flex-start"}}>
                        <>
                            <label style={{marginTop: 0}}
                                   htmlFor="file-upload2" className="profile-picture-upload-button">
                                {translate("hd.actions.uploadLogo")}
                            </label>
                            <input id={"file-upload2"} type={'file'} name={'file'}
                                   onChange={(e) => this.props.onImageUpload(e, "logo")} style={{display: "none"}}/>
                        </>
                        <span style={{marginLeft: "20px", color: "#8a8a8a"}}>Max. 10mb, .png / .jpg / .svg</span>
                    </div>
                    <div style={{textAlign: "left", paddingTop: "20px", paddingBottom: "20px"}}>
                        <span className={"page-sub-sub-sub-title"}>{translate("hd.editor.adjustFields")}</span><br/>
                        <span>{this.props.selectedField ? this.props.selectedField.text : translate("hd.editor.clickToSelect")}</span><br/>
                        {colorPicker}
                        <SecondaryButton color={ButtonColors.aqua} onClick={this.props.reset} text={"Reset"}
                                         style={{marginTop: "10px"}}/>

                    </div>

                    <EditorFooter width={this.props.width} changeSelected={this.props.changeSelected}
                                  text={translate("hd.editor.next")}
                                  toStep={4}/>
                </div>
            </div>
        </div>
    }
}

export default ColorEditor;
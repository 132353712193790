import React from "react";
import './header.styles.css'
import LocaleSelector from "../../Shop/common/ToolBar/LocaleSelector/LocaleSelector.component";
import SecondaryButton from "../buttons/action_secondary/secondary.button";
import ButtonColors from "../buttons/button.colors";
// @ts-ignore
import {default as shoppingCart} from "../../../assets/icons/shoppingcart.icon.svg";
// @ts-ignore
import {default as hamburger} from "../../../assets/icons/hamburger.icon.svg";
// @ts-ignore
import {default as personIcon} from "../../../assets/icons/person.icon.svg";
// @ts-ignore
import {default as bizzerdLogo} from '../../../assets/img/bizzerd_logo.png';
import {connect} from "react-redux";
import {logoutUser} from '../../../actions/userActions'
import {translate} from "react-i18nify";
import {Link} from "react-router-dom";


export interface navigationHeaderProps {
    logoutUser: () => void,
    dashboard?: boolean,
}


class NavigationHeader extends React.Component<navigationHeaderProps, {
    width: number
}> {

    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth
        }
        window.addEventListener('scroll', this.reportWindowSize);
        window.addEventListener('resize', this.reportWindowSize);

    }

    reportWindowSize = () => {
        this.setState({width: window.innerWidth})
    }

    private static hideShowMenu() {
        let menu = document.getElementById("navigationMenu")
        if (!menu) {
            return
        }
        if (menu.style.display == "flex") {
            menu.style.display = "none";
        } else {
            menu.style.display = "flex";
        }
    }

    onLogoutClick = () => {
        this.props.logoutUser();
    };

    render() {
        if (window.location.href.includes("dashboard/ontwerpen/tool") || window.location.href.includes("view/analytics")) {
            return <></>
        }
        let scrolled = "";
        let showBar = true;
        // if (document.body.scrollTop > 140 || document.documentElement.scrollTop > 140) {
        //     scrolled = " scrolled"
        //     showBar = true;
        // }
        let pages = [

            <Link key={"product"} to={translate("hd.links.product")}
                  className={window.location.href.includes('/product') ? "navigation-item-selected" : "navigation-item"}>
                {translate("hd.navigationbar.product")}</Link>,
            <Link key={"enterprise"} to={translate("hd.links.enterprise")}
                  className={window.location.href.includes('/enterprise') ? "navigation-item-selected" : "navigation-item"}>
                {translate("hd.navigationbar.enterprise")}</Link>,
            <Link key={"pricing"} to={translate("hd.links.pricing")}
                  className={window.location.href.includes('/pricing') ? "navigation-item-selected" : "navigation-item"}>
                {translate("hd.navigationbar.pricing")}</Link>,

            <Link key={"about"} to={translate("hd.links.about")}
                  className={window.location.href.includes('/about') ? "navigation-item-selected" : "navigation-item"}>
                {translate("hd.navigationbar.about")}</Link>,
            <Link key={"contact"} to={translate("hd.links.contact")}
                  className={window.location.href.includes('/contact') ? "navigation-item-selected" : "navigation-item"}>
                {translate("hd.navigationbar.contact")}</Link>,

            // , <a key={"contact"} href={translate("hd.links.contact")}
            //    className={window.location.href.includes('/contact') ? "navigation-item-selected" : ""}>
            //     {translate("hd.navigationbar.contact")}</link>
        ]


        let header = (<div style={{display: "flex", flexDirection: "column", width: "100%"}}>
            {showBar ?
                <div style={{height: 69, position: "relative", top: 0, width: "100%", display: "block"}}/> : ""}
            <div className={"navigation-header-container" + scrolled}>
                <Link to={translate("hd.links.home")}>
                    <img className={"navigation-header-bizzerd-logo"}
                         src={bizzerdLogo} alt={"logo"}/></Link>
                <div className={"navigation-header-pages"}>
                    {pages}
                </div>

                <div className={"navigation-header-button"}>
                    <div style={{display: "flex", alignItems: "center"}}>
                        <div style={{
                            marginRight: "15px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <Link to={translate("hd.links.login")} style={{textDecoration: "none"}}>
                                <span className={"navigation-item"}>Sign in</span>
                            </Link>

                        </div>
                        <LocaleSelector showText={this.state.width > 768}/>
                    </div>

                    {this.state.width > 1100 ?
                        <>
                            {this.props.dashboard ?
                                <SecondaryButton onClick={() => this.onLogoutClick()}
                                                 text={translate("hd.actions.logout")}
                                                 color={ButtonColors.white}
                                                 style={{marginLeft: "10px"}}/>

                                : <>

                                    <SecondaryButton color={ButtonColors.orange}
                                                     href={translate("hd.links.businessCards")}
                                                     text={translate("hd.actions.tryForFree")}
                                                     style={{marginRight: 10, marginLeft: 10}}/>
                                </>}


                        </>
                        : <>
                        </>}
                </div>


            </div>
        </div>)

        if (this.state.width < 768) {
            pages = [<div className={"navigation-menu-item-container"}>
                <Link className={"navigation-menu-item"}
                      to={translate("hd.links.home")}>{translate("hd.navigationbar.home")}</Link>
                <i className="fas fa-chevron-right" aria-hidden="true"/>
            </div>,

                <div className={"navigation-menu-item-container"}>
                    <Link className={"navigation-menu-item"}
                          to={translate("hd.links.product")}>{translate("hd.navigationbar.product")}</Link>
                    <i className="fas fa-chevron-right" aria-hidden="true"/>
                </div>,
                <div className={"navigation-menu-item-container"}>
                    <Link className={"navigation-menu-item"}
                          to={translate("hd.links.pricing")}>{translate("hd.navigationbar.pricing")}</Link>
                    <i className="fas fa-chevron-right" aria-hidden="true"/>
                </div>,
                <div className={"navigation-menu-item-container"}>
                    <Link className={"navigation-menu-item"}
                          to={translate("hd.links.enterprise")}>{translate("hd.navigationbar.enterprise")}</Link>
                    <i className="fas fa-chevron-right" aria-hidden="true"/>
                </div>,
                <div className={"navigation-menu-item-container"}>
                    <Link className={"navigation-menu-item"}
                          to={translate("hd.links.about")}>{translate("hd.navigationbar.about")}</Link>
                    <i className="fas fa-chevron-right" aria-hidden="true"/>
                </div>,
                // <div className={"navigation-menu-item-container"}>
                //     <a className={"navigation-menu-item"}
                //        href={translate("hd.links.contact")}>{translate("hd.navigationbar.contact")}</link>
                //     <i className="fas fa-chevron-right" aria-hidden="true"/>
                // </div>,
                <div className={"navigation-menu-item-container"}>
                    <Link className={"navigation-menu-item"}
                          to={translate("hd.links.businessCards")}><span
                        style={{
                            fontWeight: "bold"
                        }}>{translate("hd.actions.tryForFree")}</span></Link>
                    <i className="fas fa-chevron-right" aria-hidden="true"/>
                </div>,
                <div className={"navigation-menu-item-container"}>
                    <Link className={"navigation-menu-item"}
                          to={translate("hd.links.contact")}><span
                        style={{
                            color: "#F7A823",
                            fontWeight: "bold"
                        }}>{translate("hd.actions.contactSales")}</span></Link>
                    <i className="fas fa-chevron-right" aria-hidden="true"/>
                </div>

            ]
            if (this.props.dashboard) {
                pages = [<div className={"navigation-menu-item-container"}>
                    <Link className={"navigation-menu-item"}
                          to={'/dashboard/collegas'}>{translate("hd.navigationbar.myOverview")}</Link>
                    <i className="fas fa-chevron-right" aria-hidden="true"/>
                </div>,
                    <div className={"navigation-menu-item-container"}>
                        <Link className={"navigation-menu-item"}
                              to={'/dashboard/instellingen/linkbonnement'}>{translate("hd.navigationbar.mySubscriptions")}</Link>
                        <i className="fas fa-chevron-right" aria-hidden="true"/>
                    </div>,
                    <div className={"navigation-menu-item-container"}>
                        <Link className={"navigation-menu-item"}
                              to={'/dashboard/instellingen/bedrijf'}>{translate("hd.navigationbar.myCompany")}</Link>
                        <i className="fas fa-chevron-right" aria-hidden="true"/>
                    </div>,]
            }
            header = (
                <>{showBar ?
                    <div style={{height: 80, position: "relative", top: 0, width: "100%", display: "block"}}/> : ""}
                    <div className={"navigation-header-container" + scrolled}>
                        <img src={hamburger} className={"navigation-hamburger-icon"}
                             style={{paddingRight: 50, width: 75}}
                             onClick={NavigationHeader.hideShowMenu}/>
                        <Link to={translate("hd.links.home")}>
                            <img className={"navigation-header-bizzerd-logo"}
                                 src={bizzerdLogo} alt={"logo"}
                            /></Link>
                        <div className={"navigation-header-button"}>
                            <LocaleSelector/>
                            <Link to={translate("hd.links.login")}>
                                <img src={personIcon}
                                     className={"navigation-person-icon"}/></Link>

                        </div>
                        <div className={"navigation-unfolded-menu"} id={"navigationMenu"}>
                            <div className={"navigation-menu-item-container"} onClick={NavigationHeader.hideShowMenu}>
                                <Link to={""} className={"navigation-menu-item"}><b>Menu</b></Link>
                                <span>✖</span>
                            </div>
                            {pages}
                        </div>


                    </div>
                </>
            )
        }


        return header;

    }


}

const
    mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
    logoutUser
})(NavigationHeader);

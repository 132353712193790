import {translate} from "react-i18nify";
import SecondaryButton from "../buttons/action_secondary/secondary.button";
import ButtonColors from "../buttons/button.colors";
import React, {useEffect, useState} from "react";
// @ts-ignore
import {default as sso} from "../../../assets/calculator/sso.calculator.svg"
// @ts-ignore
import {default as nfc} from "../../../assets/calculator/nfc.calculator.svg"
// @ts-ignore
import {default as crm} from "../../../assets/calculator/crm.calculator.svg"
// @ts-ignore
import {default as domainGraphic} from "../../../assets/calculator/domain.calculator.svg"
// @ts-ignore
import {default as building} from "../../../assets/calculator/building.calculator.svg"
// @ts-ignore
import {default as line} from "../../../assets/calculator/line.calculator.svg"
// @ts-ignore
import {default as ball} from "../../../assets/calculator/ball.calculator.svg"

export function SSOAnimation({}) {
    const [iconOpacity, setIconOpacity] = useState(0.0);
    const [width, setWidth] = useState(50);
    const [left, setLeft] = useState(0);
    const [top, setTop] = useState(0);
    const [animationActive, setAnimationActive] = useState(false)

    let title = translate("hd.calculator.sso.title")
    let subTitle = translate("hd.calculator.sso.subTitle")
    let buttonText = animationActive ? translate("hd.calculator.sso.buttonTextActive") : translate("hd.calculator.sso.buttonText")
    return (<div style={{display: "flex"}}>
        <div className={"calculator-options-outer-container"}>
            <div className={"calculator-options-content-container"}>
                <div className={"calculator-options-icon-container"}>
                    <img
                        style={{
                            position: "absolute",
                            opacity: iconOpacity,
                            width: 40,
                            transition: "all 0.5s ease-in-out"
                        }}
                        src={building}/>
                    <img className={"calculator-options-icon"}
                         style={{width: width, left: left, top: top}}
                         src={sso}/>
                </div>
                <div className={"calculator-options-text-container"}>
                    <span className={"calculator-options-title"}>{title}</span>
                    <span className={"calculator-options-subTitle"}>{subTitle}</span>
                </div>
            </div>
            <SecondaryButton color={!animationActive ? ButtonColors.blue : ButtonColors.aqua} style={{width: "90%"}}
                             onClick={() => {
                                 if (animationActive) {
                                     setLeft(0)
                                     setTop(0)
                                     setWidth(50)
                                     setIconOpacity(0.0)
                                 } else {
                                     setLeft(14)
                                     setTop(17)
                                     setWidth(40)
                                     setIconOpacity(1.0)
                                 }
                                 setAnimationActive(!animationActive)
                             }}
                             text={buttonText}/>
        </div>
    </div>)
}

export function CRMAnimation({changePhoneImage, CRMAnimationActive, upwards = false}) {
    const [animationActive, setAnimationActive] = useState(false)
    const [opacity, setOpacity] = useState(0.0)
    const [left, setLeft] = useState(upwards ? -75 : -105)
    const [transitionSpeed, setTransitionSpeed] = useState(2)
    let title = translate("hd.calculator.crm.title")
    let subTitle = translate("hd.calculator.crm.subTitle")
    let buttonText = animationActive ? translate("hd.calculator.crm.buttonTextActive") : translate("hd.calculator.crm.buttonText")
    return (<div style={{display: "flex"}}>
            <div className={"calculator-options-outer-container"}>
                <div className={"calculator-options-content-container"}>
                    <div
                        style={{
                            position: "absolute",
                            transform: upwards ? "rotate(90deg)" : "",
                            left: upwards ? "135px" : ""
                        }}>
                        <img
                            style={{
                                opacity: opacity,
                                transition: "all 0.5s ease-in-out",
                                position: "relative",
                                left: upwards ? -70 : -100,
                            }}
                            src={line}/>

                        <img src={ball}
                             style={{
                                 opacity: opacity,
                                 transition: "left " + transitionSpeed + "s linear " + transitionSpeed + "s, opacity 1s ease-in-out",
                                 position: "relative",
                                 left: left,
                             }}/>
                    </div>
                    <div className={"calculator-options-icon-container"}>
                        <img className={"calculator-options-icon"}
                             src={crm}/>
                    </div>
                    <div className={"calculator-options-text-container"}>
                        <span className={"calculator-options-title"}>{title}</span>
                        <span className={"calculator-options-subTitle"}>{subTitle}</span>
                    </div>
                </div>
                <SecondaryButton color={!animationActive ? ButtonColors.blue : ButtonColors.aqua} style={{width: "90%"}}
                                 onClick={() => {
                                     if (animationActive) {

                                         setTransitionSpeed(0.1)
                                         setOpacity(0.0)
                                         setLeft(upwards ? -75 : -105)
                                         changePhoneImage("default")
                                         CRMAnimationActive(false)
                                     } else {
                                         setTransitionSpeed(2)
                                         setOpacity(1.0)
                                         setLeft(!upwards ? -190 : -160)
                                         changePhoneImage("loading")
                                         setTimeout(() => {
                                             changePhoneImage("filled")
                                         }, 4000)
                                     }
                                     setAnimationActive(!animationActive)
                                     CRMAnimationActive(!animationActive)
                                 }}
                                 text={buttonText}/>
            </div>
        </div>
    )
}

export function NFCAnimation({flipPhone, cancelNFCAnimation}) {
    const [animationActive, setAnimationActive] = useState(false)
    let title = translate("hd.calculator.nfc.title")
    let subTitle = translate("hd.calculator.nfc.subTitle")
    let buttonText = animationActive ? translate("hd.calculator.nfc.buttonTextActive") : translate("hd.calculator.nfc.buttonText")
    return (<div style={{display: "flex"}}>
        <div className={"calculator-options-outer-container"}>
            <div className={"calculator-options-content-container"}>
                <div className={"calculator-options-icon-container"}>
                    <img className={"calculator-options-icon"}
                         src={nfc}/>
                </div>
                <div className={"calculator-options-text-container"}>
                    <span className={"calculator-options-title"}>{title}</span>
                    <span className={"calculator-options-subTitle"}>{subTitle}</span>
                </div>
            </div>
            <SecondaryButton color={!animationActive ? ButtonColors.blue : ButtonColors.aqua} style={{width: "90%"}}
                             onClick={() => {
                                 if (animationActive) {
                                     cancelNFCAnimation()
                                 }
                                 setAnimationActive(!animationActive)
                                 flipPhone(animationActive)

                             }}
                             text={buttonText}/>
        </div>
    </div>)
}

export function DomainAnimation({changeDomain, domain, changeExtension, customDomainActive}) {
    const [animationActive, setAnimationActive] = useState(false)
    let title = translate("hd.calculator.domain.title")
    let subTitle = translate("hd.calculator.domain.subTitle")
    let buttonText = animationActive ? translate("hd.calculator.domain.buttonTextActive") : translate("hd.calculator.domain.buttonText")
    let domainInput = (<div
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            opacity: animationActive ? 1 : 0,
            transition: "all 1s linear 0.6s"
        }}>
        <span style={{color: "#324A6D", fontWeight: "bold", fontSize: 13}}>Beschikbaarheid checken</span>
        <div>
            <input style={{padding: 8}} placeholder={"Bedrijfsnaam"} onChange={(e) => changeDomain(e.target.value)}
                   value={domain}/>
            <select style={{padding: 8}} onChange={(e) => changeExtension(e.target.value)}>
                <option>card.com</option>
                <option>card.nl</option>
            </select>

        </div>
    </div>)
    return (<div
        style={{display: "flex",}}>
        <div className={"calculator-options-outer-container"}
             style={{height: animationActive ? 200 : 130, overflow: "hidden", transition: "all 0.8s ease"}}>
            <div className={"calculator-options-content-container"}>
                <div className={"calculator-options-icon-container"}>
                    <img className={"calculator-options-icon"}
                         src={domainGraphic}/>
                </div>
                <div className={"calculator-options-text-container"}>
                    <span className={"calculator-options-title"}>{title}</span>
                    <span className={"calculator-options-subTitle"}>{subTitle}</span>
                </div>
            </div>
            <SecondaryButton color={!animationActive ? ButtonColors.blue : ButtonColors.aqua}
                             style={{width: "90%", marginTop: 19}}
                             onClick={() => {
                                 setAnimationActive(!animationActive)
                                 customDomainActive(!animationActive)

                             }}
                             text={buttonText}/>

            {domainInput}
        </div>
    </div>)
}
import React from "react";
import './blocks.styles.css'
import ButtonColors from "../buttons/button.colors";
// @ts-ignore
import {default as EyeIcon} from "../../../assets/icons/eye.icon.svg"
// @ts-ignore
import {default as CloudIcon} from "../../../assets/icons/cloud.icon.svg"
// @ts-ignore
import {default as DesignIcon} from "../../../assets/icons/design.icon.svg"
// @ts-ignore
import {default as CardsBackground} from "../../../assets/containers/small.container.png"
import InputField from "../fields/editable_input/text.input";
import MainButton from "../buttons/action_main/main.button";
import {login} from "../../../actions/userActions"
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {translate} from "react-i18nify";

export interface loginBlockProps {
    width: number,
    auth: any,
    login: ({email, password}) => void,
}

export interface loginBlockState {
    auth: any,
    errors?: any,
    email: string,
    password: string,
}


class LoginBlock extends React.Component<loginBlockProps, loginBlockState> {
    constructor(props) {
        super(props);
        this.state = {
            auth: {
                isAuthenticated: false,
            },
            password: "",
            email: "",
        }
        this.onChange = this.onChange.bind(this)
        this.mouseDown = this.mouseDown.bind(this)
        window.addEventListener(
            "keydown",
            this.mouseDown
        );
    }

    mouseDown(event) {
        if (event.key === "Enter") {
            this.startLogin();
        }
    }

    startLogin() {
        // @ts-ignore
        const email = document.getElementById('email').value
        // @ts-ignore
        const password = document.getElementById("password").value
        this.props.login({email: email, password: password})

    }

    componentWillUnmount() {
        window.removeEventListener("keydown", this.mouseDown);
    }

    componentWillReceiveProps(nextProps, nextContext: any) {
        if (nextProps.auth && nextProps.auth.isAuthenticated) {
            this.setState({auth: nextProps.auth})
        }
        if (nextProps.errors && nextProps.errors.errors) {
            this.setState({errors: nextProps.errors.errors})
        }
    }

    onChange(e) {
        const value = e.target.value;
        this.setState({
            ...this.state,
            errors: undefined,
            [e.target.id]: value
        })

    }

    render() {
        if (this.state.auth && this.state.auth.isAuthenticated) {
            window.gtag('event', 'login');
            // @ts-ignore
            return <><Redirect to={"/dashboard/simple"}/></>;
        }
        return (
            <div className={"single-block-container"}>
                <div className={"login-block"}>
                    <span>{translate("hd.loginBlock.blockTitle")}</span>

                    <InputField placeholder={translate("hd.loginBlock.email")}
                                validation={"^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$"} id={"email"}
                                label={translate("hd.loginBlock.email")} showError={!!this.state.errors}
                                errorMessage={this.state.errors ? this.state.errors : translate("hd.errorMessages.invalidEmail")}
                                value={this.state.email} onChange={(e) => this.onChange(e)}/>
                    <InputField password={true} placeholder={translate("hd.loginBlock.password")} id={"password"}
                                new={false}
                                label={translate("hd.loginBlock.password")} value={this.state.password}
                                onChange={(e) => this.onChange(e)}/>

                    <div className={"login-buttons"}>
                        <div className={"login-buttons-group"} style={{marginTop: 0}}>
                            <MainButton color={ButtonColors.white}
                                        onClick={() => window.location.href = "https://app.bizzerd.com/s_edit.php"}
                                        style={{width: (this.props.width < 768 ? "50%" : "auto")}}
                                        text={translate("hd.loginBlock.enterprise")}/>
                            <MainButton
                                style={{
                                    marginLeft: "10px",
                                    width: (this.props.width < 768 ? "50%" : "auto")
                                }}
                                color={ButtonColors.white}
                                onClick={() => window.location.href = "https://app2.bizzerd.com"} text={"SSO"}/>
                        </div>
                        <MainButton color={ButtonColors.orange} style={{width: "auto"}}
                                    onClick={() => this.startLogin()}
                                    text={translate("hd.actions.login")}/>
                    </div>
                    <a className={"text-link"} href={"/forgotpassword"}
                       style={{
                           textAlign: "left",
                           width: "100%",
                           display: "block",
                           marginTop: "10px"
                       }}>{translate("hd.loginBlock.forgotPassword")}</a>
                </div>
                <span style={{color: "#428096", width: "30%", minWidth: "340px"}}>{translate("hd.loginBlock.noAccount")}
                    <a
                        className={"text-link"}
                        href={translate("hd.links.businessCards")}>{translate("hd.loginBlock.pickCard")}</a> {translate("hd.loginBlock.makeDesign")}</span>
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors,
});


export default connect(mapStateToProps, {login})(LoginBlock);
import "./noeditorflow.css"
import React, {useEffect, useState} from "react";
import {default as bizzerdLogo} from '../../assets/img/bizzerd_logo.png';
import {translate} from "react-i18nify";

export const FlowStepNavigation = ({s, setStep, width, personValidated}) => {
    const step = parseInt(s)


    if (width < 768) {
        return (<div className={"step-nav-container"}>
            <span>{translate("hd.nef.nav.step")} {step} {translate("hd.nef.nav.ofThe")} 5</span>
            {step === 1 && <span className={"step-nav-item" + (step === 1 ? " selected" : step > 1 ? " old" : "")}
                                 onClick={() => setStep(1)}>{translate("hd.nef.nav.step1")}</span>}
            {step === 2 && <span className={"step-nav-item" + (step === 2 ? " selected" : step > 2 ? " old" : "")}
                                 onClick={() => setStep(2)}>{translate("hd.nef.nav.step2")}</span>}
            {step === 3 && <span className={"step-nav-item" + (step === 3 ? " selected" : step > 3 ? " old" : "")}
                                 onClick={() => setStep(3)}>{translate("hd.nef.nav.step3")}</span>}
            {step === 4 && <span className={"step-nav-item" + (step === 4 ? " selected" : step > 4 ? " old" : "")}
                                 onClick={() => setStep(4)}>{translate("hd.nef.nav.step4")}</span>}
            {step === 5 && <span className={"step-nav-item" + (step === 5 ? " selected" : step > 5 ? " old" : "")}
                                 onClick={() => setStep(5)}>{translate("hd.nef.nav.step5")}</span>}
        </div>)
    }

    return <div className={"step-nav-container"}>
        <img style={{maxHeight: 50, cursor: "pointer"}} onClick={() => window.location.href = "/"}
             src={bizzerdLogo} alt={"logo"}/>
        <span className={"step-nav-item" + (step === 1 ? " selected" : step > 1 ? " old" : "")}
              onClick={() => setStep(1)}>{translate("hd.nef.nav.step1")}</span>
        <span className={"step-nav-item"}>></span>
        <span className={"step-nav-item" + (step === 2 ? " selected" : step > 2 ? " old" : "")}
              onClick={() => setStep(2)}>{translate("hd.nef.nav.step2")}</span>
        <span className={"step-nav-item"}>></span>
        <span className={"step-nav-item" + (step === 3 ? " selected" : step > 3 ? " old" : "")}
              onClick={() => personValidated ? setStep(3) : setStep(2)}>{translate("hd.nef.nav.step3")}</span>
        <span className={"step-nav-item"}>></span>
        <span className={"step-nav-item" + (step === 4 ? " selected" : step > 4 ? " old" : "")}
              onClick={() => personValidated ? setStep(4) : setStep(2)}>{translate("hd.nef.nav.step4")}</span>
        <span className={"step-nav-item"}>></span>
        <span className={"step-nav-item" + (step === 5 ? " selected" : step > 5 ? " old" : "")}
              onClick={() => personValidated ? setStep(5) : setStep(2)}>{translate("hd.nef.nav.step5")}</span>
    </div>
}
import React from "react";
import '../headers/header.styles.css'
// @ts-ignore
import {default as shoppingCart} from "../../../assets/icons/shoppingcart.icon.svg";
// @ts-ignore
import {default as bizzerdLogo} from '../../../assets/img/bizzerd_logo.png';
// @ts-ignore
import {default as graphicBig} from '../../../assets/img/holderGraphic.png';
// @ts-ignore
import {default as graphicSmall} from '../../../assets/img/holderGraphicSmall.png';
// @ts-ignore
import {default as afas} from '../../../assets/logo/afas.png';
// @ts-ignore
import {default as azure} from '../../../assets/logo/azure.png';

// @ts-ignore
import {default as qrcode} from '../../../assets/icons/qrcode.icon.svg';
// @ts-ignore
import {default as blueb} from '../../../assets/img/bluebchip.png';
import PageTitle from "../fields/text/pagetitle.text";
import {translate} from "react-i18nify";


interface homeHeaderProps {
    width: number,
    blockType: string,
    reverse?: boolean,
    img: any,
}

class SellingBlock extends React.Component<homeHeaderProps, {}> {
    render() {
        return (<div className={"enterprise-header-container"} id={this.props.blockType}
                     style={this.props.reverse ? {flexDirection: "row-reverse"} : {}}>
            <div className={"enterprise-header-left"}>
                <PageTitle text={translate("hd.enterprise." + this.props.blockType + ".pageTitle")}
                           style={{
                               fontSize: "36px",
                               marginBottom: "20px",
                               marginTop: 0
                           }}/>
                <span style={{marginBottom: "10px"}}
                      className={"enterprise-header-usp-item"}>{translate("hd.enterprise." + this.props.blockType + ".pageContent")}</span>


                <div className={"enterprise-header-usp"}>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        paddingBottom: 7,
                        paddingTop: 7
                    }}><span
                        className={"check-mark"}>✔︎</span>
                        <span>{translate("hd.enterprise." + this.props.blockType + ".usp1")}</span></div>

                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", paddingBottom: 7}}><span
                        className={"check-mark"}>✔︎</span>
                        <span>{translate("hd.enterprise." + this.props.blockType + ".usp2")}</span></div>

                    <div style={{display: "flex", flexDirection: "row", alignItems: "center", paddingBottom: 7}}><span
                        className={"check-mark"}>✔︎</span>
                        <span>{translate("hd.enterprise." + this.props.blockType + ".usp3")}</span></div>


                </div>


                {this.props.blockType === "hrmBlock" ?
                    <div style={{
                        display: "flex",
                        flexDirection: this.props.width < 768 ? "column" : "row",
                        justifyContent: this.props.width < 768 ? "space-evenly" : "flex-start",
                        alignItems: "center",

                        paddingTop: 20,
                        height: 150
                    }}>
                        <div>
                            <img style={{
                                display: "block",
                                height: "100%",
                                maxWidth: 120,
                                marginRight: this.props.width < 768 ? 0 : 40
                            }}
                                 src={azure}/>
                        </div>
                        <img style={{maxWidth: 120}} src={afas}/>
                    </div> : <></>}
            </div>
            <div className={"enterprise-header-right"}>
                <img style={{width: "50%"}} src={this.props.img}/>
            </div>

        </div>)

    }

}

export default SellingBlock;
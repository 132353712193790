import React, {Component} from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {
    getAllTemplates,
    getAllTemplatesFromVariations,
    getPopularTemplates
} from "../../../../../actions/templateActions";
import DisplayBizzerd from "../../../../bizzerddisplay/DisplayBizzerd";
import {translate} from "react-i18nify";
import PageTitle from "../../../../common2/fields/text/pagetitle.text";
import SecondaryButton from "../../../../common2/buttons/action_secondary/secondary.button";
import ButtonColors from "../../../../common2/buttons/button.colors";
import Spinner from '../../../../../assets/img/Spinner.svg';
import WhiteContainer from "../../../../common2/containers/white.container";
import {default as bizzerdb} from "../../../../../assets/img/bizzerd-b.png"
import {default as bizzerdbEmpty} from "../../../../../assets/img/bizzerdb-empty.svg"
import {default as bizzerdLogo} from '../../../../../assets/img/bizzerd_logo.png';

import "./DesignPicker.css"
import "../designTool.css"
import {Link} from "react-router-dom";
import SubTitle from "../../../../common2/fields/text/subtitle.text";

class DesignPicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: [],
            hovering: false,
            selected: undefined,
            width: window.innerWidth,
        }
        window.addEventListener('resize', this.reportWindowSize);
    }

    reportWindowSize = () => {
        this.setState({width: window.innerWidth})
    }

    componentDidMount() {
        this.props.getPopularTemplates()
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.templates && nextProps.templates.templates) {
            this.setState({templates: nextProps.templates.templates.reverse()})
        }
    }


    render() {
        let templates;
        if (this.state.templates.length > 0)
            templates = this.state.templates.map(temp => {
                    return (<div className={"bizzerd-picker-card-outer-container"}
                                 style={this.state.selected === temp._id ? {border: "4px #f7a823 solid"} : {}}>
                            <a className={"bizzerd-picker-card-container"}
                               href={"/design/template/" + temp._id}>
                                <DisplayBizzerd bizzerdLayout={temp}
                                                personContent={{
                                                    firstname: translate("chooseDesigns.fields.firstname"),
                                                    pasfoto: "https://res.cloudinary.com/bizzerd-b-v/image/upload/v1680172291/dt3sixmvarf6ht35qpoi.jpg",
                                                    lastname: translate("chooseDesigns.fields.lastname"),
                                                    fullname: translate("chooseDesigns.fields.fullname"),
                                                    jobtitle: translate("chooseDesigns.fields.title"),
                                                    tel: translate("chooseDesigns.fields.tel"),
                                                    mobile: translate("chooseDesigns.fields.mobile"),
                                                    email: translate("chooseDesigns.fields.email"),
                                                    adresveld: translate("chooseDesigns.fields.adres"),
                                                    website: translate("chooseDesigns.fields.website"),
                                                    facebook: "https://facebook.com",
                                                    twitter: "https://twitter.com",
                                                    linkedin: "https://linkedin.com",
                                                    instagram: "https://instagram.com",
                                                    vimeo: "https://vimeo.com",
                                                }}
                                                shouldBeClickable={false}
                                                realWidth={this.state.width < 768 ? 120 : 220}/></a>
                            <div className={"bizzerd-picker-mask"}>
                                <SecondaryButton color={ButtonColors.white} href={"/demo/" + temp._id}
                                                 newPage={true}
                                                 text={"Demo"}/>
                                <SecondaryButton color={ButtonColors.orange} text={translate("hd.designPicker.select")}
                                                 onClick={() => window.location.href = "/design/template/" + temp._id}/>
                            </div>
                        </div>
                    )
                }
            )

        return (
            <div style={{
                width: "100%",
                background: "linear-gradient(227deg, #EAF2F6 0%, rgba(234, 242, 246, 0.00) 100%)"
            }}>
                <div className={"content-wrapper"}>
                    <div className={"container-white"}
                         style={{
                             marginTop: 0,
                             backgroundImage: "d",
                             minHeight: "100vh",
                             width: "100%",
                         }}>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "100%"
                        }}>
                            <div className={"bizzerd-picker-flex-container"}>
                                <div className={"bizzerd-picker-header"}>
                                    <Link to={translate("hd.links.home")}>
                                        <img className={"bizzerd-picker-logo"} src={bizzerdLogo}/>
                                    </Link>
                                    <PageTitle text={translate("hd.designPicker.free")} style={{marginBottom: 10}}/>
                                    <div style={{width: 200}}></div>
                                </div>
                                <SubTitle thin={true} text={translate("hd.designPicker.start")}
                                          style={{marginBottom: 0}}/>
                                <div className={"bizzerd-picker-flex"}>
                                    {templates ? templates : <img src={Spinner}/>}

                                </div>
                                {this.state.templates.length < 20 ?
                                    <div style={{position: "fixed", zIndex: 999999, bottom: 50}}>
                                        <SecondaryButton color={ButtonColors.white}
                                                         text={translate("hd.designPicker.loadAll")}
                                                         onClick={() => this.props.getAllTemplatesFromVariations()}/>
                                    </div> : <></>}

                            </div>
                            {/*<div className={"bottom-bar"}>*/}
                            {/*    <Link className={"bottom-bar-back"}*/}
                            {/*          to={translate("hd.links.home")}>{translate("hd.designPicker.back")}</Link>*/}
                            {/*    <div className={"bottom-bar-progress"}>*/}
                            {/*        <span>{translate("hd.designPicker.chooseAndPersonalize")}</span>*/}
                            {/*        <div>*/}
                            {/*            <img style={{maxWidth: 15, marginLeft: 5, marginRight: 5}} src={bizzerdb}/>*/}
                            {/*            <img style={{maxWidth: 15, marginLeft: 5, marginRight: 5}} src={bizzerdbEmpty}/>*/}
                            {/*            <img style={{maxWidth: 15, marginLeft: 5, marginRight: 5}} src={bizzerdbEmpty}/>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*    <div className={"bottom-bar-back"}></div>*/}
                            {/*</div>*/}
                        </div>


                    </div>
                </div>
            </div>
        )
    }
}

DesignPicker.propTypes =
    {
        getAllTemplates: PropTypes.func.isRequired,
        getAllTemplatesFromVariations: PropTypes.func.isRequired,
    }
;

const mapStateToProps = (state) => (
        {
            template: state.template,
            templates: state.templates,

        }
    )
;

export default connect(mapStateToProps,
    {
        getAllTemplatesFromVariations,
        getPopularTemplates
    }
)(DesignPicker);

import "./noeditorflow.css"
import PageTitle from "../common2/fields/text/pagetitle.text";
import MainButton from "../common2/buttons/action_main/main.button";
import ButtonColors from "../common2/buttons/button.colors";
import React from "react";
import DisplayBizzerd from "../bizzerddisplay/DisplayBizzerd";
import {translate} from "react-i18nify";
import SecondaryButton from "../common2/buttons/action_secondary/secondary.button";
import SubSubSubTitle from "../common2/fields/text/subsubsubtitle.text";

export const TemplatePicker = ({templates, setTemplate, step, setStep, person, width}) => {
    return <div className={"step-flow-item-container"}>
        <div className={"step-flow-title-container"}>
            <div className={"step-flow-title-container-left"}>
                <PageTitle style={{paddingBottom: 0, margin: 0}} text={translate("hd.nef.s1.title")}/>
                <SubSubSubTitle style={{color: "#324A6D", opacity: 0.5}} text={translate("hd.nef.s1.subTitle")}/>

            </div>
            <div className={"step-flow-title-container-right"}>
                <SecondaryButton text={translate("hd.nef.s1.back")} color={ButtonColors.white}
                                 onClick={() => window.location.href = "/"}/>
            </div>
        </div>
        <div className={"step-flow-horizontal"}>
            <div className={"step-flow-left"} style={width > 768 ? {width: "90%"} : {}}>
                <div className={"template-container"}>
                    {templates.map(template => <DisplayBizzerd bizzerdLayout={template} realWidth={300}
                                                               shouldBeClickable={false} shouldShowShadow={true}
                                                               personContent={person}
                                                               onClick={() => {
                                                                   setTemplate(template)
                                                                   setStep(step + 1)
                                                               }}/>)}

                </div>

            </div>
        </div>

    </div>
}
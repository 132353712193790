import React from "react";
import './header.styles.css'
import ButtonColors from "../buttons/button.colors";
// @ts-ignore
import {default as shoppingCart} from "../../../assets/icons/shoppingcart.icon.svg";
// @ts-ignore
import {default as bizzerdLogo} from '../../../assets/img/bizzerd_logo.png';
// @ts-ignore
import {default as graphicBig} from '../../../assets/img/holderGraphic.png';
// @ts-ignore
import {default as graphicSmall} from '../../../assets/img/holderGraphicSmall.png';

// @ts-ignore
import {default as qrcode} from '../../../assets/icons/qrcode.icon.svg';
import PageTitle from "../fields/text/pagetitle.text";
import MainButton from "../buttons/action_main/main.button";
// @ts-ignore
import {translate} from "react-i18nify";
import LogoBlock from "../blocks/logo.block";
import Lottie from 'react-lottie';
import axios from "axios";

interface homeHeaderProps {
    width: number,
    img: any,
}

class EnterpriseHeader extends React.Component<homeHeaderProps, {}> {

    constructor(props: homeHeaderProps) {
        super(props);
        this.state = {
            animationData: null,
        };
    }

    async componentDidMount() {
        try {
            const response = await axios.get('/api/nfc/lottie/opmaat.lottie.json');
            const json = await response.data;
            this.setState({animationData: json});
        } catch (error) {
            console.error('Error fetching animation data:', error);
        }
    }

    render() {
        const defaultOptions = {
            loop: true, // Set to true if you want the animation to loop
            autoplay: true, // Set to true to play the animation as soon as it's loaded
            animationData: this.state.animationData, // Your Lottie animation JSON file
        };
        return (<>
                <div className={"enterprise-header-container"}
                     style={{paddingBottom: 0, paddingRight: this.props.width < 768 ? "" : 0}}>
                    <div className={"enterprise-header-left"}>
                        <PageTitle text={translate("hd.enterprise.enterpriseTitle")}
                                   style={{
                                       fontSize: "36px",
                                       marginBottom: "15px"
                                   }}/>
                        <span
                            className={"enterprise-header-usp-item"}
                            style={{lineHeight: "24px"}}>{translate("hd.enterprise.enterpriseContent")}</span>
                        <br/>
                        <div>

                            <span className={"enterprise-header-usp-item"}>{translate("hd.enterprise.viewPros")}</span>

                            <div className={"enterprise-usp-container"}>
                                <div className={"enterprise-header-usp"}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        paddingBottom: 7
                                    }}><span
                                        className={"check-mark"}>✔︎</span>
                                        <a className={"enterprise-header-usp-item-link"}
                                           href="#scalableBlock">{translate("hd.enterprise.scalable")}</a>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        paddingBottom: 7
                                    }}><span
                                        className={"check-mark"}>✔︎</span>
                                        <a className={"enterprise-header-usp-item-link"}
                                           href="#engagementBlock">{translate("hd.enterprise.engagement")}</a>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        paddingBottom: 7
                                    }}><span
                                        className={"check-mark"}>✔︎</span>
                                        <a className={"enterprise-header-usp-item-link"}
                                           href="#brandingBlock">{translate("hd.enterprise.branding")}</a>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        paddingBottom: 7
                                    }}><span
                                        className={"check-mark"}>✔︎</span>
                                        <a className={"enterprise-header-usp-item-link"}
                                           href="#privacyBlock">{translate("hd.enterprise.privacy")}</a>
                                    </div>

                                </div>
                                <div className={"enterprise-header-usp"} style={{marginLeft: "20px"}}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        paddingBottom: 7
                                    }}><span
                                        className={"check-mark"}>✔︎</span>
                                        <a className={"enterprise-header-usp-item-link"}
                                           href="#securityBlock">{translate("hd.enterprise.security")}</a>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        paddingBottom: 7
                                    }}><span
                                        className={"check-mark"}>✔︎</span>
                                        <a className={"enterprise-header-usp-item-link"}
                                           href="#hrmBlock">{translate("hd.enterprise.crm")}</a>
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        paddingBottom: 7
                                    }}><span
                                        className={"check-mark"}>✔︎</span>
                                        <a className={"enterprise-header-usp-item-link"}
                                           href="#climateBlock">{translate("hd.enterprise.climate")}</a>
                                    </div>


                                </div>
                            </div>

                        </div>
                        <div className={"enterprise-header-buttons"}
                             style={{width: this.props.width < 768 ? "100%" : "90%"}}>
                            <MainButton color={ButtonColors.orange} href={translate("hd.links.contact")}
                                        style={{
                                            marginRight: this.props.width < 768 ? 0 : "10px",
                                            marginTop: "20px",
                                            width: this.props.width < 768 ? "100%" : ""
                                        }}
                                        text={translate("hd.actions.requestDraft")}/>
                        </div>

                    </div>
                    <div className={"enterprise-header-right"}>
                        {/*<img style={{width: this.props.width < 768 ? "50%" : "100%"}} src={this.props.img}/>*/}
                        <Lottie options={defaultOptions}/>
                    </div>
                </div>
                <LogoBlock width={this.props.width} logoCount={this.props.width < 768 ? 3 : 7}/></>
        )

    }

}

export default EnterpriseHeader;

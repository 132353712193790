import React from "react";
import './localeselector.scss'
import {getLocale, setLocale} from 'react-i18nify'
// @ts-ignore
import dutchFlag from "../../../../../assets/img/NL vlag.png"
// @ts-ignore
import englishFlag from "../../../../../assets/img/EN vlag.png"
import {textDecorationColor} from "html2canvas/dist/types/css/property-descriptors/text-decoration-color";

type localeSelectorProps = {
    showText: boolean
}
type localeSelectorState = {
    selected: string,
}

export default class LocaleSelector extends React.Component<localeSelectorProps, localeSelectorState> {
    constructor(props: localeSelectorProps) {
        super(props);

        this.state = {
            selected: getLocale() || 'en',
        };
        this.onChange = this.onChange.bind(this);
        this.determineUrl = this.determineUrl.bind(this);
    }


    onChange(err) {

        let newLanguage = "en";
        if (this.state.selected === "en")
            newLanguage = "nl"
        setLocale(newLanguage)
        this.setState({
            selected: newLanguage,
        })
        localStorage.setItem("language", newLanguage)
        if (!err) {
            return
        }
        window.location.reload()


    }

    determineUrl() {
        if (window.location.pathname.includes("/en/")) {
            return window.location.pathname.replace("/en/", "/")
        } else if (window.location.pathname.endsWith("/en")) {
            return window.location.pathname.replace("/en", "")
        } else {
            let found = false;
            let urls = ['/product', '/contact', '/business-cards', '/enterprise', '/shoppingcart', '/pricing', '/upload', '/blogs', '/about']
            urls.forEach(url => {
                if (window.location.pathname.includes(url)) {
                    found = true
                }
            })
            if (window.location.pathname === "/" || window.location.pathname === "") {
                found = true
            }
            if (found)
                return ("/en" + window.location.pathname).replace("//", '/')
            else
                return window.location.pathname
        }


        return ""


    }


    correctImage() {
        switch (this.state.selected) {
            case 'en':
                return <img className={"flagImage"} alt={"dutch flag"} src={dutchFlag}/>
            case 'nl':
                return <img className={"flagImage"} alt={"english flag"} src={englishFlag}/>
            default:
                return <img className={"flagImage"} alt={"english flag"} src={englishFlag}/>
        }
    }

    render() {
        let url = this.determineUrl()
        let err = false;
        if (url === "err") {
            url = ""
            err = true
        }
        return (

            <a className={"OuterLocale"} style={{textDecoration: "none", height: this.props.showText ?50:"fit-content"}}
               href={window.location.origin + url} onClick={() => this.onChange(err)}>
                {this.correctImage()}
                {/*<span style={{*/}
                {/*    color: "#334c6e",*/}
                {/*    marginTop: "auto",*/}
                {/*    display: this.props.showText ? "block" : "none"*/}
                {/*}}>{this.state.selected === "nl" ? "EN" : "NL"}</span>*/}
            </a>
        );
    }
}

import React from 'react'
import GrayContainer from "../common2/containers/gray.container";
import NavigationHeader from "../common2/headers/navigation.header";
import Footer from "../common2/footers/footer";
import ReviewBlock from "../common2/blocks/review.block";
import ProductHeader from "../common2/headers/product.header";
import {ProductBlock} from "../common2/blocks/product/product.block";
import {ProductBarBlock} from "../common2/blocks/product/productbar.block";
import {ProductBarOrangeBlock} from "../common2/blocks/product/productorangebar.block";
// @ts-ignore
import {default as app} from '../../assets/product/appcolor.product.png';
// @ts-ignore
import {default as dashboard} from '../../assets/product/dashboardcolor.product.png';
// @ts-ignore
import {default as inzicht} from '../../assets/product/inzichtcolor.product.png';
// @ts-ignore
import {default as kaartje} from '../../assets/product/kaartjecolor.product.png';
// @ts-ignore
import {default as landing} from '../../assets/product/landing.product.png';
// @ts-ignore
import {default as nfc} from '../../assets/product/nfc.product.png';
import SubTitle from "../common2/fields/text/subtitle.text";
import {translate} from "react-i18nify";
import FaqContainer from "../common2/containers/faq.container";
import FaqBlock from "../common2/blocks/faq.block";
import SmallRectangle from "../common2/blocks/small.rectangle";
import {FeaturesBlock} from "../common2/blocks/product/features.block"

interface pageProps {
    width: number,
}


class ProductPage extends React.Component<pageProps, {}> {
    constructor(props) {
        super(props);
    }

    render() {


        return (<div style={{overflowX:"hidden"}}>
            <GrayContainer style={{backgroundImage: "none"}}>
                <NavigationHeader width={this.props.width}/>
                <ProductHeader width={this.props.width}/>
            </GrayContainer>
            <ProductBarBlock/>
            <div className={"content-wrapper"}>
                <ProductBarOrangeBlock/>
            </div>
            <ProductBlock width={this.props.width} blockType={"shareBlock"} reverse={true}
                          img={"/product/blocks/share.png"}/>
            <ProductBlock width={this.props.width} blockType={"statisticsBlock"}
                          img={"/product/blocks/statistics.svg"}/>
            <ProductBlock width={this.props.width} blockType={"brandingBlock"} reverse={true}
                          img={"/product/blocks/branding.svg"}/>
            <ProductBlock width={this.props.width} blockType={"nfcBlock"} img={"/product/blocks/nfc.svg"}/>
            <ProductBlock width={this.props.width} blockType={"appBlock"} reverse={true}
                          img={"/product/blocks/app.svg"}/>

            {/*<FeaturesBlock/>*/}
            <div>
                <SubTitle text={translate("hd.home.faqTitle")}/>
                <FaqContainer>
                    <FaqBlock
                        text={translate("hd.home.faq.a1")}
                        title={translate("hd.home.faq.q1")} open={true} first={true}/>
                    <FaqBlock
                        text={translate("hd.home.faq.a2")}
                        title={translate("hd.home.faq.q2")}/>
                    <FaqBlock
                        text={translate("hd.home.faq.a3")}
                        title={translate("hd.home.faq.q3")}/>
                    <FaqBlock
                        text={translate("hd.home.faq.a4")}
                        title={translate("hd.home.faq.q4")}/>
                    <FaqBlock
                        text={translate("hd.home.faq.a5")}
                        title={translate("hd.home.faq.q5")} last={true}/>

                </FaqContainer>
            </div>
            <div style={{paddingTop: 30}} className={"small-rectangle-container"}>
                <SmallRectangle text={translate("hd.home.tryForFree")}
                                buttonText={translate("hd.actions.tryForFree")}
                                icon={"eye"} width={this.props.width}
                                background={"#FFF"} href={translate("hd.links.businessCards")}/>

                <SmallRectangle text={translate("hd.home.letUsDesign")}
                                buttonText={translate("hd.home.enterprise")}
                                icon={"design"} width={this.props.width}
                                background={"#FFF"} href={translate("hd.links.enterprise")}/>
                <SmallRectangle text={translate("hd.home.costs")}
                                buttonText={translate("hd.home.upload")}
                                icon={"cloud"} width={this.props.width}
                                background={"#FFF"} href={translate("hd.links.product")}/>
            </div>
            <ReviewBlock/>

            <Footer/>
        </div>);
    }

}


export default ProductPage;
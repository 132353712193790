import React, {Suspense} from 'react'
// @ts-ignore
import Spinner from "../../assets/img/Spinner.svg";

import WhiteContainer from "../common2/containers/white.container";

const Designtool = React.lazy(() => import("../oldbackoffice/designtool/tool/DesignTool2"));


interface pageProps {
    width: number,
    match: any
}

class DesignPage extends React.Component<pageProps, {}> {
    render() {

        let tool = (
            <WhiteContainer style={{marginTop: 0, backgroundImage: "d", backgroundColor: "#f2f2f2", padding: 0}}>
                <></>
                <div id={"backoffice"}>
                    <Designtool match={this.props.match}/>
                </div>
            </WhiteContainer>)
        if (window.location.href.endsWith("/design/template")) {
            tool = (<>
                    <div id={"backoffice"}>
                        <Designtool match={this.props.match}/>
                    </div>
                </>
            )

        }
        return (<>
            <Suspense fallback={<div className="dashboard--main-content">
                <div className="placeholder--container">
                    <div className="card--graphic">
                        <img alt={"spinnner"} src={Spinner}/>
                    </div>
                </div>
            </div>}>

                {tool}


            </Suspense>
        </>);
    }
}

export default DesignPage
import React from "react";
import InputField from "../../fields/editable_input/text.input";
import InputArea from "../../fields/editable_input/textarea.input";
import "./editor.styles.css";
import SubSubSubTitle from "../../fields/text/subsubsubtitle.text";
import EditorFooter from "./footer.editor";
import {translate} from "react-i18nify";

interface editorProps {
    width: number,
    folded: boolean,
    changeSelected: (blockNr) => void,
    person: any,
    onChange: (e) => void,
    errors: any,
    onImageUpload: (file, type) => void,

}

class PersonalEditor extends React.Component<editorProps, {}> {

    render() {
        return <div className={"editor-container"}>
            <div className={"personal-detail-outer-container"} onClick={() => {
                if (this.props.folded)
                    this.props.changeSelected(1)
            }} style={{
                maxHeight: (this.props.folded ? "70px" : "1000vh"),
                cursor: (this.props.folded ? "pointer" : "")
            }}>
                <div className={"editor-header"}>
                    <span className={"pagination-ball"}>1</span>
                    <SubSubSubTitle style={{textAlign: "left"}} text={translate("hd.editor.contactDetails")}/>
                </div>
                <div className={"personal-detail-inner-container"}>

                    <InputField id={"fullname"} placeholder={translate("hd.editor.fullname")}
                                label={translate("hd.editor.fullname") + "*"}
                                errorMessage={this.props.errors.fullname} onChange={this.props.onChange}
                                validation={""} value={this.props.person.fullname}
                                showError={!!this.props.errors.fullname}/>
                    <div className={"personal-detail-container"}>
                        <div className={"personal-detail-left"}>
                            <InputField id={"email"} placeholder={translate("hd.editor.email")}
                                        label={translate("hd.editor.email") + "*"}
                                        errorMessage={translate("hd.errorMessages.invalidEmail")}
                                        validation={"^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$"}
                                        value={this.props.person.email} onChange={this.props.onChange}
                                        showError={!!this.props.errors.email}/>
                            <InputField id={"mobile"} placeholder={translate("hd.editor.mobile")}
                                        label={translate("hd.editor.mobile")}
                                        errorMessage={translate("hd.errorMessages.invalidNumber")}
                                        value={this.props.person.mobile}
                                        validation={"((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\\W*\\d){0,13}\\d$"}
                                        onChange={this.props.onChange}/>
                            <InputField id={"companyName"} placeholder={translate("hd.editor.companyName")}
                                        label={translate("hd.editor.companyName") + "*"}
                                        errorMessage={this.props.errors.companyName}
                                        value={this.props.person.companyName}
                                        validation={""} onChange={this.props.onChange}
                                        showError={!!this.props.errors.companyName}/>
                            <>
                                <label
                                    htmlFor="file-upload" className="profile-picture-upload-button">
                                    {translate("hd.actions.uploadProfile")}
                                </label>
                                <input id={"file-upload"} type={'file'} name={'file'}
                                       onChange={(e) => this.props.onImageUpload(e, "profile")}
                                       style={{display: "none"}}/>
                            </>
                        </div>
                        <div className={"personal-detail-right"}>
                            <InputField id={"jobtitle"} placeholder={translate("hd.editor.jobtitle")}
                                        label={translate("hd.editor.jobtitle")}
                                        errorMessage={translate("hd.errorMessages.invalidNumber")}
                                        onChange={this.props.onChange}
                                        validation={""} value={this.props.person.jobtitle}/>
                            <InputField id={"tel"} placeholder={translate("hd.editor.tel")}
                                        label={translate("hd.editor.tel")}
                                        errorMessage={""} onChange={this.props.onChange}
                                        validation={"((?:9[679]|8[035789]|6[789]|5[90]|42|3[578]|2[1-689])|9[0-58]|8[1246]|6[0-6]|5[1-8]|4[013-9]|3[0-469]|2[70]|7|1)(?:\\W*\\d){0,13}\\d$"}
                                        value={this.props.person.tel}/>
                            <InputArea id={"adresveld"} placeholder={translate("hd.editor.adresveld")}
                                       label={translate("hd.editor.adresveld")}
                                       errorMessage={""} onChange={this.props.onChange}
                                       validation={""} value={this.props.person.adresveld}/>
                        </div>
                    </div>
                    <EditorFooter width={this.props.width} changeSelected={this.props.changeSelected}
                                  text={translate("hd.editor.toStep") + " 2"} toStep={2}/>
                </div>
            </div>
        </div>
    }


}

export default PersonalEditor
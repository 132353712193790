import PageTitle from "../common2/fields/text/pagetitle.text";
import SubSubTitle from "../common2/fields/text/subsubtitle.text";
import ButtonColors from "../common2/buttons/button.colors";
import {translate} from "react-i18nify";
import MainButton from "../common2/buttons/action_main/main.button";
import GrayContainer from "../common2/containers/gray.container";
import NavigationHeader from "../common2/headers/navigation.header";
import React from "react";
import IconsBlock from "../common2/blocks/icons.block";
import Footer from "../common2/footers/footer";
import GradientContainer from "../common2/containers/gradient.container";

const subSubTitleStyle = {
    paddingTop: 30,
    paddingBottom: 5,
    fontSize: 16
}

export function nfcBlog(width, language) {
    if (language === "en") {
        return <><GrayContainer style={{}}>
            <NavigationHeader width={width}/>
            <div style={{
                width: "100%",
                alignSelf: "flex-start",
                paddingLeft: "17%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column"
            }}>
                <span style={{paddingTop: "40px", color: "#334c6e88"}}>{"27-05-2023"}</span>
                <PageTitle style={{
                    paddingBottom: 20,
                    marginBlockEnd: 0,
                    marginBlockStart: 5,
                    paddingRight: width < 768 ? "15%" : "30%"
                }}
                           text={"What is an NFC business card? And why do you want an NFC business card? Or not?"}/>

            </div>
        </GrayContainer>
            <GradientContainer style={{backgroundColor: "#42809620", marginTop: 0}}>
                <div className={"single-blog-block-container"}
                     style={width < 768 ? {paddingLeft: "5%", paddingRight: "5%"} : {
                         paddingLeft: "15%",
                         paddingRight: "15%"
                     }}>
                    <div className={"blog-block"}>
                        <span>Hardly anyone uses a paper business card these days. Interactive business cards are the standard. There are lots of different forms of digital business cards available. What you hear most about are NFC business cards. NFC means Near Field Communication. An NFC business card is a physical card with a tiny microchip embedded in it. This chip can be scanned by most smartphones and linked to your digital business card. This is how you share your contact information. This data can also be stored directly in the phone's address book.</span>
                        <br/>
                        <span>The core of most NFC business cards is VCard. This is a file type and is the standard for exchanging personal data, especially for electronic business cards. They can contain name and address information, phone numbers, URLs, logos, photos and bits of audio. Yet a VCard-based NFC card is not fully in a sender's corporate identity. The design is plain and limited, and thus its distinctiveness and attention value is also limited.</span>
                        <SubSubTitle style={subSubTitleStyle}
                                     text={"Bizzerd with NFC-tag "}/>
                        <span>Fortunately, it is possible to opt for a truly distinctive interactive, digital business card with an NFC tag. The provider of this is Bizzerd. This digital business card is significantly different from the many plastic NFC tags on the market. Actually, with Bizzerd, your digital business card is a kind of mini-website, with a unique url. You share your digital business card at Bizzerd via a card with NFC tag or one of the other ways (via WhatsApp, SMS, e-mail or the qr code).</span>
                        <br/>
                        <span>So you can always use Bizzerd without a special app. But there is a Bizzerd app, which adds extra features. If you use the Bizzerd app, you benefit from extra advantages, such as a handy library with all your colleagues' digital business cards. And a super-practical scan function. This allows you to scan a received paper business card and return your digital business card to the sender.</span>
                        <br/>
                        <SubSubTitle style={subSubTitleStyle} text={"Benefits at a glance:"}/>
                        <span style={{fontWeight: "bold"}}>Bizzerd:</span>
                        <ul>
                            <li>With NFC-tag</li>
                            <li>Understanding your impact: you can monitor what happens to your interactive business
                                cards
                            </li>
                            <li>Truly digital: easier, contactless sharing</li>
                            <li>Includes a free dedicated app with many practical functions (scanning, library)</li>
                        </ul>
                        <div style={{width: "100%", borderTop: "#EEF3F6 solid 3px", marginTop: 20}}/>
                        <div style={{
                            display: "flex",
                            flexDirection: width < 768 ? "column" : "row",
                            justifyContent: "space-between",
                            marginTop: 30
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: width < 768 ? "column" : "row",
                                marginBottom: width < 768 ? 20 : 0
                            }}>
                                <MainButton color={ButtonColors.orange} href={translate("hd.links.product")}
                                            style={{marginRight: 20, marginBottom: width < 768 ? 20 : 0}}
                                            text={"How it works"}/>
                                <MainButton color={ButtonColors.white}
                                            href={translate("hd.links.contact")}
                                            text={"Contact us"}/>
                            </div>
                            <MainButton color={ButtonColors.white}
                                        href={translate("hd.links.blogs")}
                                        text={translate("hd.blogBlock.backToOverview")}
                                        style={{float: "right"}}/>
                        </div>
                    </div>
                </div>
            </GradientContainer>
            <IconsBlock width={width}/>
            <Footer/></>
    }

    return (<><GrayContainer style={{}}>
        <NavigationHeader width={width}/>
        <div style={{
            width: "100%",
            alignSelf: "flex-start",
            paddingLeft: "17%",
            textAlign: "left",
            display: "flex",
            flexDirection: "column"
        }}>
            <span style={{paddingTop: "40px", color: "#334c6e88"}}>{"27-05-2023"}</span>
            <PageTitle style={{
                paddingBottom: 20,
                marginBlockEnd: 0,
                marginBlockStart: 5,
                paddingRight: width < 768 ? "15%" : "30%"
            }}
                       text={"Wat is een NFC-visitekaartje? En waarom wil je een NFC visitekaartje? Of juist niet?"}/>

        </div>
    </GrayContainer>
        <GradientContainer style={{backgroundColor: "#42809620", marginTop: 0}}>
            <div className={"single-blog-block-container"}
                 style={width < 768 ? {paddingLeft: "5%", paddingRight: "5%"} : {
                     paddingLeft: "15%",
                     paddingRight: "15%"
                 }}>
                <div className={"blog-block"}>
                    <span>Bijna niemand gebruikt tegenwoordig nog een papieren visitekaartje. Interactieve visitekaartjes zijn de standaard. Er zijn heel veel verschillende vormen van digitale visitekaartjes beschikbaar. Waar je het meest over hoort, zijn NFC-visitekaartjes. NFC betekent Near Field Communication. Een NFC-visitekaartje is een fysiek kaartje met een kleine microchip erin verwerkt. Deze chip kunnen de meeste smartphones scannen en linken naar je digitale visitekaartje. Zo deel je jouw contactgegevens. Deze gegevens kunnen ook direct worden opgeslagen in het adresboek van de telefoon.</span>
                    <br/>
                    <span>De kern van de meeste NFC-visitekaartjes is VCard. Dit is een bestandstype en is de standaard voor de uitwisseling van persoonsgegevens, met name voor elektronische visitekaartjes. Ze kunnen naam- en adresgegevens bevatten, telefoonnummers, URLs, logo's, foto's en stukjes geluid. Toch is een NFC-kaartje op basis VCard niet volledig in de huisstijl van een afzender. De vormgeving is sober en beperkt, en daarmee is het onderscheidende karakter en de attentiewaarde ook beperkt. </span>
                    <SubSubTitle style={subSubTitleStyle}
                                 text={"Bizzerd met NFC-tag "}/>
                    <span>Gelukkig is het mogelijk om te kiezen voor een echt onderscheidend interactief, digitaal visitekaartje met een NFC-tag. De aanbieder daarvan is Bizzerd. Dit digitale visitekaartje verschilt aanzienlijk van de vele plastic NFC-kaartjes op de markt. Eigenlijk is je digitale visitekaartje bij Bizzerd een soort mini-website, met een unieke url. Jouw digitale visitekaartje deel je bij Bizzerd via een kaartje met NFC-tag of op een van de andere manieren (via WhatsApp, sms, e-mail of de qr-code). </span>
                    <br/>
                    <span>Je kunt Bizzerd dus altijd gebruiken zonder een speciale app. Maar er is wel een Bizzerd-app, die extra mogelijkheden toevoegt. Gebruik je de Bizzerd-app, dan profiteert je van extra voordelen, zoals een handige bibliotheek met alle digitale visitekaartjes van je collega’s. En een superpraktische scanfunctie. Daarmee scan je een ontvangen papieren visitekaartje en kun je de afzender jouw digitale visitekaartje terugsturen.</span>
                    <br/>
                    <SubSubTitle style={subSubTitleStyle} text={"Voordelen op een rij:"}/>
                    <span style={{fontWeight: "bold"}}>Bizzerd:</span>
                    <ul>
                        <li>Met NFC-tag

                        </li>
                        <li>Inzicht in je impact: je kunt monitoren wat er met je digitale visitekaartjes
                            gebeurt
                        </li>
                        <li>Echt digitaal: makkelijker, contactloos delen
                        </li>
                        <li>Met gratis app met veel praktische functies (scannen, bibliotheek)
                        </li>
                    </ul>
                    <div style={{width: "100%", borderTop: "#EEF3F6 solid 3px", marginTop: 20}}/>
                    <div style={{
                        display: "flex",
                        flexDirection: width < 768 ? "column" : "row",
                        justifyContent: "space-between",
                        marginTop: 30
                    }}>
                        <div style={{
                            display: "flex",
                            flexDirection: width < 768 ? "column" : "row",
                            marginBottom: width < 768 ? 20 : 0
                        }}>
                            <MainButton color={ButtonColors.orange}
                                        style={{marginRight: 20, marginBottom: width < 768 ? 20 : 0}}
                                        href={translate("hd.links.product")}
                                        text={"Hoe het werkt"}/>
                            <MainButton color={ButtonColors.white}
                                        href={translate("hd.links.contact")}
                                        text={"Naar contact"}/>
                        </div>
                        <MainButton color={ButtonColors.white}
                                    href={translate("hd.links.blogs")}
                                    text={translate("hd.blogBlock.backToOverview")}
                                    style={{float: "right"}}/>
                    </div>
                </div>
            </div>
        </GradientContainer>
        <IconsBlock width={width}/>
        <Footer/></>)
}

export function uselessBlog(width, language) {
    if (language === "en") {
        return <><GrayContainer style={{}}>
            <NavigationHeader width={width}/>
            <div style={{
                width: "100%",
                alignSelf: "flex-start",
                paddingLeft: "17%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column"
            }}>
                <span style={{paddingTop: "40px", color: "#334c6e88"}}>{"27-05-2023"}</span>
                <PageTitle style={{
                    paddingBottom: 20,
                    marginBlockEnd: 0,
                    marginBlockStart: 5,
                    paddingRight: width < 768 ? "15%" : "30%"
                }}
                           text={"Advantages and Disadvantages of Paper Business Cards, LinkedIn, and Digital Business Cards"}/>
            </div>
        </GrayContainer>
            <GradientContainer style={{backgroundColor: "#42809620", marginTop: 0}}>
                <div className={"single-blog-block-container"}
                     style={width < 768 ? {paddingLeft: "5%", paddingRight: "5%"} : {
                         paddingLeft: "15%",
                         paddingRight: "15%"
                     }}>
                    <div className={"blog-block"}>
                        <span>Who still uses paper business cards? Isn't LinkedIn enough? If you want to share your information with someone, you can just connect on LinkedIn, right? Or not? Here, we list the advantages and disadvantages of paper cards, LinkedIn, and digital business cards:</span>
                        <SubSubTitle style={subSubTitleStyle}
                                     text={"Advantages and Disadvantages of Paper Business Cards"}/>
                        <span style={{fontWeight: "bold"}}>Advantages:</span>
                        <ul className={"plus-points-list"}>
                            <li>We are accustomed to paper business cards from the past. Especially people born before
                                1980 have experienced that almost everyone used paper business cards. It feels familiar
                                to them.
                            </li>
                            <li>You can express your own style well with paper business cards. You can have them made in
                                your own (house) style, with a paper type, shape, and design of your choice.
                            </li>
                        </ul>
                        <span style={{fontWeight: "bold"}}>Disadvantages:</span>
                        <ul className={"min-points-list"}>
                            <li>You may often forget to bring your paper business cards, leaving you empty-handed at a
                                meeting or event.
                            </li>
                            <li>The recipient of your paper business card may quickly discard or lose the card. All the
                                effort goes to waste.
                            </li>
                            <li>The recipient has to make a lot of effort to manually enter your information into their
                                phone or database.
                            </li>
                            <li>If your details change (e.g., new phone number), you have to get your business cards
                                remade. You can throw away the old ones. What a hassle and waste.
                            </li>
                            <li>Perhaps paper cards are hopelessly outdated. They have been used in China for over 600
                                years, and French Emperor Louis XIV and his court knew how to use them in the 17th
                                century. Maybe it's time for something new?
                            </li>
                            <li>Paper business cards are bad for the environment and climate. Every year, approximately
                                10 billion paper business cards are printed. Out of those, around 8.8 billion are thrown
                                away. All the paper (from trees!), printing, and transportation involved in this process
                                have a negative impact on the environment and climate.
                            </li>
                            <li>Every year, around 10 billion paper business cards are printed. Out of those,
                                approximately 8.8 billion are thrown away. All the paper (from trees!), printing, and
                                transportation involved in this process have a negative impact on the environment and
                                climate.
                            </li>
                        </ul>
                        <SubSubTitle style={subSubTitleStyle} text={"Advantages and Disadvantages of LinkedIn"}/>
                        <span style={{fontWeight: "bold"}}>Advantages:</span>

                        <ul className={"plus-points-list"}>
                            <li>Almost everyone uses LinkedIn. So it's recognizable, and everyone understands how it
                                works.
                            </li>
                            <li>Connecting and sharing information is easy. It's convenient!</li>
                            <li>LinkedIn is free. Well, at least the basic version. If you use Premium, you have to pay
                                a significant amount every month.
                            </li>
                        </ul>
                        <span style={{fontWeight: "bold"}}>Disadvantages:</span>
                        <ul className={"min-points-list"}>
                            <li>LinkedIn is not as representative. It's not really a business card for your organization
                                and yourself, is it?
                            </li>
                            <li>On LinkedIn, there is hardly any opportunity to showcase your own (house) style. You
                                can't differentiate yourself.
                            </li>
                            <li>It's difficult to exchange contact information during a trade show, conference, meeting,
                                or gathering on LinkedIn.
                            </li>
                            <li>You are harder to find, especially if your name is something common like John Smith.
                            </li>
                            <li>Protecting privacy is challenging. You want to share your information but not with
                                everyone. Should you include your phone number on LinkedIn or not?
                            </li>
                        </ul>
                        <SubSubTitle style={subSubTitleStyle}
                                     text={"Advantages and Disadvantages of Digital Business Cards"}/>
                        <span style={{fontWeight: "bold"}}>Disadvantages:</span>

                        <ul className={"min-points-list"}>
                            <li>If you don't have your phone with you or your battery is dead, you need to remember the
                                link to share your digital business card live during a trade show, conference, meeting,
                                or gathering.
                            </li>
                            <li>If the recipient doesn't have a phone or computer, sharing contact information becomes
                                difficult.
                            </li>
                        </ul>
                        <span style={{fontWeight: "bold"}}>Advantages:</span>
                        <ul className={"plus-points-list"}>
                            <li>You can track what happens with your digital business card. How often is it viewed and
                                shared? What do people click on? This way, you have insight into your impact!
                            </li>
                            <li>Easy and fast for you. With Bizzerd, you can share your digital business card in the
                                easiest and fastest way. You choose how: via email, WhatsApp, through the QR code, or
                                contactless via AirDrop (iPhone). Did you know that with AirDrop, you can give your
                                digital business card to a full (meeting) room at once?
                            </li>
                            <li>Easy and fast for the recipient! The recipient of your digital business card can save
                                your details in their phone with just one touch. No more typing required.
                            </li>
                            <li>You always have your business card with you. Well, as long as you don't forget your
                                phone.
                            </li>
                            <li>You can change your details as often as necessary. For example, if you get a new phone
                                number, you can update it, and everyone who has your digital business card will have
                                your correct number instantly.
                            </li>
                            <li>A digital business card is contemporary and distinctive. You create your digital
                                business card with Bizzerd in your own (house) style and design.
                            </li>
                            <li>You help save the environment and climate. No paper, no printing, no transportation.
                                It's sustainable!
                            </li>
                        </ul>
                        <div style={{width: "100%", borderTop: "#EEF3F6 solid 3px", marginTop: 20}}/>
                        <div style={{
                            display: "flex",
                            flexDirection: width < 768 ? "column" : "row",
                            justifyContent: "space-between",
                            marginTop: 30
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: width < 768 ? "column" : "row",
                                marginBottom: width < 768 ? 20 : 0
                            }}>
                                <MainButton color={ButtonColors.orange} href={translate("hd.links.product")}
                                            style={{marginRight: 20, marginBottom: width < 768 ? 20 : 0}}
                                            text={"How it works"}/>
                                <MainButton color={ButtonColors.white}
                                            href={translate("hd.links.contact")}
                                            text={"Contact us"}/>
                            </div>
                            <MainButton color={ButtonColors.white}
                                        href={translate("hd.links.blogs")}
                                        text={translate("hd.blogBlock.backToOverview")}
                                        style={{float: "right"}}/>
                        </div>
                    </div>
                </div>
            </GradientContainer>
            <IconsBlock width={width}/>
            <Footer/></>
    }


    return (<><GrayContainer style={{}}>
            <NavigationHeader width={width}/>
            <div style={{
                width: "100%",
                alignSelf: "flex-start",
                paddingLeft: "17%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column"
            }}>
                <span style={{paddingTop: "40px", color: "#334c6e88"}}>{"27-05-2023"}</span>
                <PageTitle style={{
                    paddingBottom: 20,
                    marginBlockEnd: 0,
                    marginBlockStart: 5,
                    paddingRight: width < 768 ? "15%" : "30%"
                }}
                           text={"Voordelen en nadelen van papieren visitekaartjes, LinkedIn én digitale visitekaartjes"}/>

            </div>
        </GrayContainer>
            <GradientContainer style={{backgroundColor: "#42809620", marginTop: 0}}>
                <div className={"single-blog-block-container"}
                     style={width < 768 ? {paddingLeft: "5%", paddingRight: "5%"} : {
                         paddingLeft: "15%",
                         paddingRight: "15%"
                     }}>
                    <div className={"blog-block"}>
                        <span>Wie gebruikt er nog een papieren visitekaartje? Er is toch LinkedIn? Als je iemand jouw gegevens wilt geven, maak je gewoon contact via LinkedIn, toch? Of niet? Hier zetten we de voordelen en nadelen van papieren kaartjes, LinkedIn én digitale visitekaartjes op een rij:</span>
                        <SubSubTitle style={subSubTitleStyle}
                                     text={"Voordelen en nadelen van papieren visitekaartjes"}/>
                        <span style={{fontWeight: "bold"}}>Pluspunten:</span>
                        <ul className={"plus-points-list"}>
                            <li>We zijn papieren visitekaartjes <b>gewend van vroeger</b>. Vooral mensen die
                                voor 1980
                                zijn
                                geboren
                                hebben nog meegemaakt dat vrijwel iedereen papieren visitekaartjes
                                gebruikte. Voor hen
                                is
                                het lekker vertrouwd.
                            </li>
                            <li>
                                Je kunt je <b>eigen stijl</b> goed uitdrukken met papieren visitekaartjes.
                                Je kunt ze
                                laten
                                maken
                                in je eigen (huis)stijl, met een papiersoort, vorm en design van jouw keuze.
                            </li>
                        </ul>
                        <span style={{fontWeight: "bold"}}>Minpunten:</span>
                        <ul className={"min-points-list"}>
                            <li>Je vergeet misschien wel regelmatig om je papieren visitekaartjes mee te
                                nemen, waardoor
                                je
                                op een bijeenkomst of ontmoeting alsnog met <b>lege handen</b> staat.
                            </li>
                            <li>De ontvanger van jouw papieren visitekaartje zal het kaartje wellicht snel
                                weer <b>weggooien
                                    of
                                    kwijtraken</b>. Alle moeite voor niks.
                            </li>
                            <li>De ontvanger moet <b>veel moeite</b> doen om jouw gegevens over te typen in
                                haar/zijn
                                telefoon of
                                database.
                            </li>
                            <li>Als je gegevens veranderen (nieuw telefoonnummer), moet je jouw
                                visitekaartjes opnieuw
                                laten
                                maken. De oude kun je weggooien. Wat een <b>gedoe en verspilling</b>.
                            </li>
                            <li>Misschien is een papieren kaartje ook wel <b>hopeloos ouderwets</b>. Ruim
                                600 jaar
                                geleden
                                werden
                                ze al gebruikt in China. En de Franse keizer Lodewijk de veertiende en zijn
                                hofhouding
                                wisten er ook raad mee in de zeventiende eeuw. Misschien tijd voor iets
                                nieuws?
                            </li>
                            <li>Papieren visitekaartjes zijn <b>slecht voor het milieu en klimaat</b>.
                                Jaarlijks worden
                                er
                                zo’n 10
                                miljard papieren visitekaartjes bedrukt. Daarvan worden er zo’n 8,8 miljard
                                weggegooid.
                                Al
                                het papier (bomen!), drukwerk en transport hiervoor gaat ten koste van
                                milieu en
                                klimaat.
                            </li>

                        </ul>


                        <SubSubTitle style={subSubTitleStyle}
                                     text={"Voordelen en nadelen van LinkedIn"}/>
                        <span style={{fontWeight: "bold"}}>Pluspunten:</span>
                        <ul className={"plus-points-list"}>
                            <li>Bijna <b>iedereen gebruikt LinkedIn</b>. Dus herkenbaar en iedereen begrijpt
                                hoe het
                                werkt.
                            </li>
                            <li>Contact maken en gegevens delen is een kleine moeite. <b>Lekker
                                makkelijk</b>!
                            </li>
                            <li>LinkedIn is <b>gratis</b>. Tenminste, als je de basisversie gebruikt.
                                Gebruik je Premium
                                dan
                                moet
                                je elke maand flink betalen.
                            </li>
                        </ul>
                        <span style={{fontWeight: "bold"}}>Minpunten:</span>
                        <ul className={"min-points-list"}>
                            <li>LinkedIn is niet zo representatief. <b>Niet echt een visitekaartje</b> voor
                                jouw
                                organisatie en
                                jouzelf, toch?
                            </li>
                            <li>Op LinkedIn is er nauwelijks mogelijkheid om je eigen (huis)stijl te laten
                                zien. Je kunt
                                je
                                <b>niet onderscheiden</b>.
                            </li>
                            <li>Het is <b>lastig om je gegevens uit te wisselen</b> tijdens een beurs,
                                congres,
                                bijeenkomst,
                                vergadering of ontmoeting.
                            </li>
                            <li>Je bent <b>moeilijker vindbaar</b>, zeker als je bijvoorbeeld Jan Jansen
                                heet.
                            </li>
                            <li><b>Privacybescherming is lastig</b>. Je wil graag je gegevens delen, maar
                                niet met
                                iedereen.
                                Zet je
                                dan wel of niet je telefoonnummer op LinkedIn?
                            </li>
                        </ul>
                        <SubSubTitle style={subSubTitleStyle}
                                     text={"Nadelen en voordelen van digitale visitekaartjes"}/>
                        <span style={{fontWeight: "bold"}}>Minpunten:</span>
                        <ul className={"min-points-list"}>
                            <li>Als je je <b>telefoon niet bij je hebt of je batterij is leeg</b>, dan moet
                                je je link
                                uit je hoofd kennen om live je
                                digitale visitekaartje te geven tijdens een beurs, congres, bijeenkomst of
                                vergadering.
                            </li>
                            <li>Als de ontvanger <b>geen telefoon of computer</b> heeft, wordt delen van
                                gegevens ook
                                moeilijk.
                            </li>
                        </ul>
                        <span style={{fontWeight: "bold"}}>Pluspunten:</span>
                        <ul className={"plus-points-list"}>
                            <li>Je kunt <b>volgen wat er met jouw digitale visitekaartje gebeurt</b>. Hoe
                                vaak wordt je
                                Bizzerd
                                getoond, en hoe vaak gedeeld? Waarop wordt geklikt? Zo heb je inzicht in
                                jouw impact!
                            </li>
                            <li>Makkelijk en snel voor jou. Met Bizzerd deel je jouw digitale visitekaartje
                                op de
                                makkelijkste en snelste manier. Jij kiest hoe: via e-mail, WhatsApp, via de
                                QR-code of
                                contactloos via AirDrop (iPhone). Wist je trouwens dat je via AirDrop in 1
                                keer een
                                volle
                                (vergader)zaal jouw digitale visitekaartje kunt geven?
                            </li>
                            <li><b>Makkelijk en snel</b> voor de ontvanger! De ontvanger van jouw digitale
                                visitekaartje,
                                kan jouw
                                gegevens in 1 touch opslaan in zijn/haar telefoon. Overtypen is nooit meer
                                nodig.
                            </li>
                            <li>Je hebt <b>altijd je visitekaartje bij je</b>. Tenminste, als je je telefoon
                                niet
                                vergeet.
                            </li>
                            <li>Je kunt je <b>gegevens zo vaak veranderen als nodig is</b>. (Bijvoorbeeld)
                                een nieuw
                                telefoonnummer
                                pas je aan, en iedereen die jouw digitale visitekaartje heeft, heeft meteen
                                jouw
                                correcte
                                nummer.
                            </li>
                            <li>Een digitaal visitekaartje is <b>eigentijds en onderscheidend</b>. Je maakt
                                jouw
                                digitale
                                visitekaartje met Bizzerd in jouw (huis)stijl en design.
                            </li>
                            <li><b>Je kunt alle informatie erop kwijt die je wilt delen</b>. Wat je maar
                                kunt bedenken,
                                met
                                Bizzerd
                                koppel je moeiteloos jouw social media, maar ook bijvoorbeeld een filmpje,
                                foto’s, een
                                presentatie, prijslijst of profiel.
                            </li>
                            <li>Digitale visitekaartjes zijn <b>duurzaam en maatschappelijk verantwoord</b>.
                                Je hoeft
                                nooit
                                meer
                                ongebruikte visitekaartjes weg te gooien. Je bespaart papier en dus bomen,
                                drukwerk en
                                transport en daarmee vervuiling en CO2-uitstoot.
                            </li>

                        </ul>

                        <div style={{width: "100%", borderTop: "#EEF3F6 solid 3px", marginTop: 20}}/>
                        <div style={{
                            display: "flex",
                            flexDirection: width < 768 ? "column" : "row",
                            justifyContent: "space-between",
                            marginTop: 30
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: width < 768 ? "column" : "row",
                                marginBottom: width < 768 ? 20 : 0
                            }}>
                                <MainButton color={ButtonColors.orange}
                                            style={{marginRight: 20, marginBottom: width < 768 ? 20 : 0}}
                                            href={translate("hd.links.product")}
                                            text={"Hoe het werkt"}/>
                                <MainButton color={ButtonColors.white}
                                            href={translate("hd.links.contact")}
                                            text={"Naar contact"}/>
                            </div>
                            <MainButton color={ButtonColors.white}
                                        href={translate("hd.links.blogs")}
                                        text={translate("hd.blogBlock.backToOverview")}
                                        style={{float: "right"}}/>
                        </div>
                    </div>
                </div>
            </GradientContainer>
            <IconsBlock width={width}/>
            <Footer/></>

    )
}

export function riseBlog(width, language) {
    if (language === "en") {
        return (<><GrayContainer style={{}}>
            <NavigationHeader width={width}/>
            <div style={{
                width: "100%",
                alignSelf: "flex-start",
                paddingLeft: "17%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column"
            }}>
                <span style={{paddingTop: "40px", color: "#334c6e88"}}>{"27-05-2023"}</span>
                <PageTitle style={{
                    paddingBottom: 20,
                    marginBlockEnd: 0,
                    marginBlockStart: 5,
                    paddingRight: width < 768 ? "15%" : "30%"
                }}
                           text={"The rise of online business cards and their essential role in the modern business world"}/>

            </div>
        </GrayContainer>
            <GradientContainer style={{backgroundColor: "#42809620", marginTop: 0}}>
                <div className={"single-blog-block-container"}
                     style={width < 768 ? {paddingLeft: "5%", paddingRight: "5%"} : {
                         paddingLeft: "15%",
                         paddingRight: "15%"
                     }}>
                    <div className={"blog-block"}>
                        <span>In the digital age, traditional business cards are no longer sufficient to make a lasting impression in the business world. With the growing need for efficiency and convenience, digital business cards are becoming increasingly popular. In this blog post, we will discuss the rise of digital business cards and the important role they play in the modern business environment.</span>
                        <SubSubTitle style={subSubTitleStyle}
                                     text={"What are digital business cards?"}/>
                        <span>Digital business cards are electronic versions of traditional business cards that can be easily exchanged through mobile devices, emails, or social media. They typically contain the same information as traditional business cards, such as name, job title, company details, and contact information, but offer additional benefits in terms of flexibility and functionality.</span>
                        <SubSubTitle text={"The benefits of digital business cards"} style={subSubTitleStyle}/>
                        <ol>
                            <li>Environmentally friendly: Digital business cards contribute to reducing paper waste and
                                printing costs, making them a sustainable alternative to traditional business cards.
                            </li>
                            <li>Easy sharing: With digital business cards, contact information can be quickly and
                                easily shared with a simple tap on a screen, eliminating the hassle of physically
                                handing out cards.
                            </li>
                            <li>Dynamic updates: Unlike traditional business cards, digital business cards can be easily
                                updated with new information. This ensures that your contacts always have access to the
                                most up-to-date data.
                            </li>
                            <li>Multimedia integration: Digital business cards offer the ability to add multimedia
                                elements, such as links to your website, social media profiles, or a company video,
                                allowing you to enhance your professional image.
                            </li>
                            <li>Data analysis: By using digital business cards, you can track statistics on how often
                                your card is viewed or shared. This provides valuable insights into your networking
                                activity and helps you manage your business relationships more effectively.
                            </li>
                        </ol>

                        <br/>

                        <span>The rise of digital business cards has played an important role in the modern business world. With their environmental friendliness, easy sharing, dynamic updates, multimedia integration, and data analysis capabilities, digital business cards offer numerous advantages over traditional business cards.</span>
                        <br/>
                        <span>By using digital business cards, professionals can network more efficiently and make a lasting impression on potential contacts. They allow users to quickly and easily share contact information and ensure that the information is always up-to-date. Additionally, multimedia elements and data analysis can contribute to enhancing the professional image and better managing business relationships.</span>
                        <br/>

                        <span>In conclusion, digital business cards have become a valuable tool in the modern business environment. With their many benefits and the ability to easily adapt to the needs of the digital age, it is no surprise that more and more professionals are opting for digital business cards as an efficient and effective alternative to traditional paper cards.</span>

                        <div style={{width: "100%", borderTop: "#EEF3F6 solid 3px", marginTop: 20}}/>
                        <div style={{
                            display: "flex",
                            flexDirection: width < 768 ? "column" : "row",
                            justifyContent: "space-between",
                            marginTop: 30
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: width < 768 ? "column" : "row",
                                marginBottom: width < 768 ? 20 : 0
                            }}>
                                <MainButton color={ButtonColors.orange} href={translate("hd.links.product")}
                                            style={{marginRight: 20, marginBottom: width < 768 ? 20 : 0}}
                                            text={"How it works"}/>
                                <MainButton color={ButtonColors.white}
                                            href={translate("hd.links.contact")}
                                            text={"Contact us"}/>
                            </div>
                            <MainButton color={ButtonColors.white}
                                        href={translate("hd.links.blogs")}
                                        text={translate("hd.blogBlock.backToOverview")}
                                        style={{float: "right"}}/>
                        </div>
                    </div>
                </div>
            </GradientContainer>
            <IconsBlock width={width}/>
            <Footer/></>)
    }
    return (<><GrayContainer style={{}}>
            <NavigationHeader width={width}/>
            <div style={{
                width: "100%",
                alignSelf: "flex-start",
                paddingLeft: "17%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column"
            }}>
                <span style={{paddingTop: "40px", color: "#334c6e88"}}>{"27-05-2023"}</span>
                <PageTitle style={{
                    paddingBottom: 20,
                    marginBlockEnd: 0,
                    marginBlockStart: 5,
                    paddingRight: width < 768 ? "15%" : "30%"
                }}
                           text={"De opkomst van online visitekaartjes en hun essentiële rol in de moderne zakelijke wereld"}/>

            </div>
        </GrayContainer>
            <GradientContainer style={{backgroundColor: "#42809620", marginTop: 0}}>
                <div className={"single-blog-block-container"}
                     style={width < 768 ? {paddingLeft: "5%", paddingRight: "5%"} : {
                         paddingLeft: "15%",
                         paddingRight: "15%"
                     }}>
                    <div className={"blog-block"}>
                        <span>In het digitale tijdperk zijn traditionele visitekaartjes niet langer voldoende om een blijvende indruk te maken in de zakelijke wereld. Met de groeiende behoefte aan efficiëntie en gemak, worden digitale visitekaartjes steeds populairder. In dit blogartikel zullen we de opkomst van digitale visitekaartjes bespreken en de belangrijke rol die ze spelen in de moderne zakelijke omgeving.</span>
                        <SubSubTitle style={subSubTitleStyle}
                                     text={"Wat zijn digitale visitekaartjes?"}/>
                        <span>Digitale visitekaartjes zijn elektronische versies van traditionele visitekaartjes die gemakkelijk kunnen worden uitgewisseld via mobiele apparaten, e-mails of sociale media. Ze bevatten meestal dezelfde informatie als traditionele visitekaartjes, zoals naam, functie, bedrijfsgegevens en contactinformatie, maar bieden extra voordelen in termen van flexibiliteit en functionaliteit.</span>
                        <SubSubTitle text={"De voordelen van digitale visitekaartjes"} style={subSubTitleStyle}/>
                        <ol>
                            <li>Milieuvriendelijk: Digitale visitekaartjes dragen bij aan het verminderen van
                                papierafval en drukkosten, waardoor ze een duurzaam alternatief zijn voor traditionele
                                visitekaartjes.
                            </li>
                            <li>Gemakkelijke uitwisseling: Met digitale visitekaartjes kunnen contactgegevens snel en
                                eenvoudig worden gedeeld met een simpele tik op een scherm, waardoor het gedoe van het
                                fysiek overhandigen van kaartjes wordt geëlimineerd.
                            </li>
                            <li>Dynamische updates: In tegenstelling tot traditionele visitekaartjes kunnen digitale
                                visitekaartjes gemakkelijk worden bijgewerkt met nieuwe informatie. Dit zorgt ervoor dat
                                uw contacten altijd toegang hebben tot de meest recente gegevens.
                            </li>
                            <li>Multimedia-integratie: Digitale visitekaartjes bieden de mogelijkheid om
                                multimedia-elementen toe te voegen, zoals links naar uw website, sociale media-profielen
                                of een bedrijfsvideo, waardoor u uw professionele imago kunt versterken.
                            </li>
                            <li>Gegevensanalyse: Door het gebruik van digitale visitekaartjes kunt u statistieken
                                bijhouden over hoe vaak uw kaartje wordt bekeken of gedeeld. Dit geeft waardevolle
                                inzichten in uw netwerkactiviteit en helpt u uw zakelijke relaties effectiever te
                                beheren.
                            </li>
                        </ol>

                        <br/>

                        <span>De opkomst van digitale visitekaartjes gaat een belangrijke rol spelen in de moderne zakelijke wereld. Met hun milieuvriendelijkheid, gemakkelijke uitwisseling, mogelijkheid tot dynamische updates, multimedia-integratie en gegevensanalyse bieden digitale visitekaartjes talloze voordelen ten opzichte van traditionele visitekaartjes.</span>
                        <br/>
                        <span>Door het gebruik van digitale visitekaartjes kunnen professionals efficiënter netwerken en een blijvende indruk maken op potentiële relaties. Ze stellen gebruikers in staat om snel en gemakkelijk contactgegevens te delen en zorgen ervoor dat de informatie altijd up-to-date is. Bovendien kunnen multimedia-elementen en gegevensanalyse bijdragen aan het versterken van het professionele imago en het beter beheren van zakelijke relaties.</span>
                        <br/>

                        <span>Kortom, digitale visitekaartjes zijn een waardevol hulpmiddel geworden in de moderne zakelijke omgeving. Met hun vele voordelen en het vermogen om gemakkelijk in te spelen op de behoeften van het digitale tijdperk, is het geen verrassing dat steeds meer professionals kiezen voor digitale visitekaartjes als een efficiënt en effectief alternatief voor traditionele papieren kaartjes.</span>

                        <div style={{width: "100%", borderTop: "#EEF3F6 solid 3px", marginTop: 20}}/>
                        <div style={{
                            display: "flex",
                            flexDirection: width < 768 ? "column" : "row",
                            justifyContent: "space-between",
                            marginTop: 30
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: width < 768 ? "column" : "row",
                                marginBottom: width < 768 ? 20 : 0
                            }}>
                                <MainButton color={ButtonColors.orange}
                                            style={{marginRight: 20, marginBottom: width < 768 ? 20 : 0}}
                                            href={translate("hd.links.product")}
                                            text={"Hoe het werkt"}/>
                                <MainButton color={ButtonColors.white}
                                            href={translate("hd.links.contact")}
                                            text={"Naar contact"}/>
                            </div>
                            <MainButton color={ButtonColors.white}
                                        href={translate("hd.links.blogs")}
                                        text={translate("hd.blogBlock.backToOverview")}
                                        style={{float: "right"}}/>
                        </div>
                    </div>
                </div>
            </GradientContainer>
            <IconsBlock width={width}/>
            <Footer/>
        </>
    )
}

export function privacyBlog(width, language) {
    if (language === "en") {
        return (<><GrayContainer style={{}}>
            <NavigationHeader width={width}/>
            <div style={{
                width: "100%",
                alignSelf: "flex-start",
                paddingLeft: "17%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column"
            }}>
                <span style={{paddingTop: "40px", color: "#334c6e88"}}>{"26-06-2023"}</span>
                <PageTitle style={{
                    paddingBottom: 20,
                    marginBlockEnd: 0,
                    marginBlockStart: 5,
                    paddingRight: width < 768 ? "15%" : "30%"
                }}
                           text={"Here’s how to ensure privacy and data security of digital business cards"}/>

            </div>
        </GrayContainer>
            <GradientContainer style={{backgroundColor: "#42809620", marginTop: 0}}>
                <div className={"single-blog-block-container"}
                     style={width < 768 ? {paddingLeft: "5%", paddingRight: "5%"} : {
                         paddingLeft: "15%",
                         paddingRight: "15%"
                     }}>
                    <div className={"blog-block"}>
                        <span>More and more organisations are opting for interactive, digital business cards. This requires extra attention to privacy and data security of the stored data. After all, personal data is involved. You want to decide with whom you share your data. Your business cards should not end up on the street.</span>
                        <SubSubTitle style={subSubTitleStyle}
                                     text={"Here are the main aspects of privacy and data security of digital business cards:"}/>
                        <span>Data encryption: Ensure that the data stored on digital business cards is adequately encrypted, both during storage and during any transfer. This helps prevent unauthorised access to the data even if a security breach occurs.</span>
                        <br/>
                        <span>Access control: Implement strict access controls within the digital business card system you use. Limit access to authorised users based on their roles and responsibilities. Use strong password authentication.</span>
                        <br/>
                        <span>Secure storage: Ensure that the server or database in which the digital business cards are stored is properly secured. This can include regularly installing security updates and patches, implementing firewalls, using intrusion detection systems and restricting physical access to the servers.</span>
                        <SubSubTitle style={subSubTitleStyle}
                                     text={"Hosted on servers in the Netherlands and Germany"}/>
                        <span>Choose to host digital business cards on servers in the European Union. This is important because of privacy issues. This is not as well protected in the United States. There, privacy is not (yet) a fundamental right as it is in Europe. In Europe, the General Data Protection Regulation (GDPR), translated into the Algemene Verordening Gegevensbescherming (AVG) in the Netherlands, provides an overarching legal framework for privacy. For this reason, Bizzerd is only hosted on servers in the Netherlands and Germany.</span>
                        <br/>
                        <span>Data minimisation: Collect only the minimum amount of data needed for the intended use. Limit the inclusion of sensitive or non-relevant information on digital business cards. This reduces the risk in case of a data breach. In other words, don't put your private address on your business digital business card, for example, and certainly not your (business) bank account number.</span>
                        <SubSubTitle style={subSubTitleStyle} text={"Determine how long the data will be kept"}/>
                        <span>Data retention: Establish a clear data retention policy for digital business cards. Determine how long the data will be retained and make sure it is securely and permanently deleted after the retention period expires. For example: if an employee leaves employment, ensure that her/his digital business card is deleted within a short period of time (e.g. one month).</span><br/>
                        <span>Bizzerd is used at organisations of varying sizes. From a few dozen users to hundreds of thousands. Also with various country offices and different language areas. Bizzerd can only do this by meeting the privacy and personal data protection requirements of large (international) user organisations. Our data security is regularly tested, including through pen tests.</span>
                        <br/>
                        <span>Choose Bizzerd today and get digital business cards with optimal data and privacy protection.</span>


                        <div style={{width: "100%", borderTop: "#EEF3F6 solid 3px", marginTop: 20}}/>
                        <div style={{
                            display: "flex",
                            flexDirection: width < 768 ? "column" : "row",
                            justifyContent: "space-between",
                            marginTop: 30
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: width < 768 ? "column" : "row",
                                marginBottom: width < 768 ? 20 : 0
                            }}>
                                <MainButton color={ButtonColors.orange} href={translate("hd.links.product")}
                                            style={{marginRight: 20, marginBottom: width < 768 ? 20 : 0}}
                                            text={"How it works"}/>
                                <MainButton color={ButtonColors.white}
                                            href={translate("hd.links.contact")}
                                            text={"Contact us"}/>
                            </div>
                            <MainButton color={ButtonColors.white}
                                        href={translate("hd.links.blogs")}
                                        text={translate("hd.blogBlock.backToOverview")}
                                        style={{float: "right"}}/>
                        </div>
                    </div>
                </div>
            </GradientContainer>
            <IconsBlock width={width}/>
            <Footer/></>)
    }
    return (<><GrayContainer style={{}}>
            <NavigationHeader width={width}/>
            <div style={{
                width: "100%",
                alignSelf: "flex-start",
                paddingLeft: "17%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column"
            }}>
                <span style={{paddingTop: "40px", color: "#334c6e88"}}>{"26-06-2023"}</span>
                <PageTitle style={{
                    paddingBottom: 20,
                    marginBlockEnd: 0,
                    marginBlockStart: 5,
                    paddingRight: width < 768 ? "15%" : "30%"
                }}
                           text={"Zo borg je de privacy en databeveiliging van digitale visitekaartjes"}/>

            </div>
        </GrayContainer>
            <GradientContainer style={{backgroundColor: "#42809620", marginTop: 0}}>
                <div className={"single-blog-block-container"}
                     style={width < 768 ? {paddingLeft: "5%", paddingRight: "5%"} : {
                         paddingLeft: "15%",
                         paddingRight: "15%"
                     }}>
                    <div className={"blog-block"}>
                        <span>Steeds meer organisaties kiezen voor interactieve, digitale visitekaartjes. Dat vraagt om extra aandacht voor privacy en databeveiliging van de opgeslagen gegevens. Het gaat immers om persoonsgegevens. Je wil zelf bepalen met wie je jouw gegevens deelt. Je visitekaartjes mogen dan niet zomaar op straat komen te liggen.</span>
                        <SubSubTitle style={subSubTitleStyle}
                                     text={"De belangrijkste aspecten rond privacy en databeveiliging van digitale visitekaartjes op een rij:"}/>
                        <span>Gegevensversleuteling: Zorg ervoor dat de opgeslagen gegevens van de digitale visitekaartjes adequaat versleuteld zijn, zowel tijdens de opslag als bij eventuele overdracht. Dit helpt bij het voorkomen van ongeoorloofde toegang tot de gegevens, zelfs als er een inbreuk op de beveiliging plaatsvindt.</span>
                        <br/>
                        <span>Toegangscontrole: Implementeer strikte toegangscontroles binnen het systeem voor digitale visitekaartjes dat je gebruikt. Beperk de toegang tot geautoriseerde gebruikers op basis van hun rol en verantwoordelijkheden. Gebruik sterke wachtwoordauthenticatie.</span>
                        <br/>
                        <span>Beveiligde opslag: Zorg ervoor dat de server of database waarin de digitale visitekaartjes worden opgeslagen, goed beveiligd is. Dit kan onder andere omvatten: het regelmatig installeren van beveiligingsupdates en patches, het implementeren van firewalls, het gebruik van intrusiedetectiesystemen en het beperken van fysieke toegang tot de servers.</span>
                        <SubSubTitle style={subSubTitleStyle}
                                     text={"Gehost op servers in Nederland en Duitsland"}/>
                        <span>Kies voor hosting van de digitale visitekaartjes op servers in de Europese Unie. Dit is van belang vanwege de privacy. Die wordt in de Verenigde Staten minder goed beschermd. Daar is privacy (nog) geen fundamenteel recht zoals in Europa. In Europa zorgt de General Data Protection Regulation (GDPR), in Nederland doorvertaald naar de Algemene Verordening Gegevensbescherming (AVG) voor een overkoepelend wettelijk kader voor privacy. Bizzerd wordt om deze reden alleen gehost op servers in Nederland en Duitsland.</span>
                        <br/>
                        <span>Gegevensminimalisatie: Verzamel alleen de minimale hoeveelheid gegevens die nodig zijn voor het beoogde gebruik. Beperk de opname van gevoelige of niet-relevante informatie op digitale visitekaartjes. Dit vermindert het risico in het geval van een gegevensinbreuk. Met andere woorden, zet niet bijvoorbeeld je privéadres op je zakelijke digitale visitekaartje, en al helemaal niet je (zakelijke)bankrekeningnummer.</span>
                        <SubSubTitle style={subSubTitleStyle} text={"Bepaal hoe lang de gegevens bewaard worden"}/>
                        <span>Dataretentie: Stel een duidelijk dataretentiebeleid op voor digitale visitekaartjes. Bepaal hoe lang de gegevens bewaard worden en zorg ervoor dat ze na het verstrijken van de bewaartermijn veilig en permanent worden verwijderd. Bijvoorbeeld: als een medewerker uit dienst gaat, zorg er dan voor dat haar/zijn digitale visitekaartje binnen een korte termijn (bijvoorbeeld een maand) verwijderd is.</span><br/>
                        <span>Bizzerd wordt gebruikt bij organisaties van uiteenlopende omvang. Van enkele tientallen gebruikers tot honderdduizenden. Ook met diverse landenvestigingen en verschillende taalgebieden. Dit kan Bizzerd alleen maar doen door te voldoen aan de eisen van grote (internationale) gebruikersorganisaties op het gebied van privacy en bescherming van persoonsgegevens. Onze databeveiliging wordt regelmatig getoetst, onder meer door middel van pentests.</span>
                        <br/>
                        <span>Kies vandaag nog voor Bizzerd en zorg voor digitale visitekaartjes met optimale databescherming en privacybescherming.</span>

                        <div style={{width: "100%", borderTop: "#EEF3F6 solid 3px", marginTop: 20}}/>
                        <div style={{
                            display: "flex",
                            flexDirection: width < 768 ? "column" : "row",
                            justifyContent: "space-between",
                            marginTop: 30
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: width < 768 ? "column" : "row",
                                marginBottom: width < 768 ? 20 : 0
                            }}>
                                <MainButton color={ButtonColors.orange}
                                            style={{marginRight: 20, marginBottom: width < 768 ? 20 : 0}}
                                            href={translate("hd.links.product")}
                                            text={"Hoe het werkt"}/>
                                <MainButton color={ButtonColors.white}
                                            href={translate("hd.links.contact")}
                                            text={"Naar contact"}/>
                            </div>
                            <MainButton color={ButtonColors.white}
                                        href={translate("hd.links.blogs")}
                                        text={translate("hd.blogBlock.backToOverview")}
                                        style={{float: "right"}}/>
                        </div>
                    </div>
                </div>
            </GradientContainer>
            <IconsBlock width={width}/>
            <Footer/>
        </>
    )
}

export function engagementBlog(width, language) {
    if (language === "en") {
        return (<><GrayContainer style={{}}>
            <NavigationHeader width={width}/>
            <div style={{
                width: "100%",
                alignSelf: "flex-start",
                paddingLeft: "17%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column"
            }}>
                <span style={{paddingTop: "40px", color: "#334c6e88"}}>{"26-06-2023"}</span>
                <PageTitle style={{
                    paddingBottom: 20,
                    marginBlockEnd: 0,
                    marginBlockStart: 5,
                    paddingRight: width < 768 ? "15%" : "30%"
                }}
                           text={"Here's how te get extra engagement from (potential) customers with your digital business card"}/>

            </div>
        </GrayContainer>
            <GradientContainer style={{backgroundColor: "#42809620", marginTop: 0}}>
                <div className={"single-blog-block-container"}
                     style={width < 768 ? {paddingLeft: "5%", paddingRight: "5%"} : {
                         paddingLeft: "15%",
                         paddingRight: "15%"
                     }}>
                    <div className={"blog-block"}>
                        <span>With a digital business card, you ensure that recipients do more than just passively receive information. You can actively engage them in further interaction and ensure activation, such as contacting them, continuing a conversation, requesting more information or a quotation, participating in promotions or giving feedback. A few tips.</span>
                        <br/>
                        <span>
Engagement matters because it helps build relationships, increase brand awareness, create loyalty and promote business opportunities. Encouraging engagement through a digital business card is the first step to building a lasting interaction and relationship with potential customers, partners or other contacts. How. Some tips:
</span>
                        <br/>
                        <span>Interactive elements: Make your digital business card interactive by using elements such as clickable buttons, links to relevant content or social media profiles, or integrating a short video. This grabs recipients' attention and encourages them to explore further.
</span>
                        <br/>
                        <span>Attractive design: Make sure your digital business card is visually appealing and professionally designed. Use attractive colours, easy-to-read fonts and graphics that represent your brand. An aesthetically pleasing business card arouses curiosity and increases the likelihood that recipients will want to interact with it.
</span>
                        <br/>
                        <span>Personalised message: Add a personal touch to your digital business card by including a short, personalised message for the recipient. For example, this could be a thank you for a meeting or a specific aspect of the conversation you had. This creates a sense of appreciation and makes the business card more personal.
</span>

                        <SubSubTitle style={subSubTitleStyle}
                                     text={"Give your recipients a reason to stay engaged"}/>

                        <span>Added value: Offer recipients something of value through your digital business card. This could be a free e-book, a whitepaper, an exclusive discount code or access to valuable content. By offering something useful or interesting, you give recipients a reason to stay engaged and contact you further.
</span><br/>
                        <span>Easy contact options: Make it easy for recipients to contact you by adding clear and direct contact options, such as an email address, phone number or link to an online appointment scheduler. The easier it is to get in touch with you, the more likely recipients are to feel engaged and take action.</span>


                        <SubSubTitle text={"Interest and proactivity strengthen engagement"} style={subSubTitleStyle}/>
                        <span>Follow-up: Ensure a quick and personal follow-up after sharing your digital business card. For example, send a thank-you email or a follow-up message to encourage further communication. Showing interest and proactivity strengthens engagement and shows that you are seriously interested in building a relationship.
</span><br/>
                        <span>With Bizzerd, you make the best first impression. But not only that. Besides maximum opportunities for interaction, Bizzerd also gives you insight into the impact of your business card and the engagement you create with it. You can monitor what a recipient does with it, for instance which buttons are clicked. This gives you additional tools to maintain contact.
</span><br/>
                        <span>Choose Bizzerd today and create extra involvement from your target group and ensure extra interaction.</span><br/>

                        <div style={{width: "100%", borderTop: "#EEF3F6 solid 3px", marginTop: 20}}/>
                        <div style={{
                            display: "flex",
                            flexDirection: width < 768 ? "column" : "row",
                            justifyContent: "space-between",
                            marginTop: 30
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: width < 768 ? "column" : "row",
                                marginBottom: width < 768 ? 20 : 0
                            }}>
                                <MainButton color={ButtonColors.orange} href={translate("hd.links.product")}
                                            style={{marginRight: 20, marginBottom: width < 768 ? 20 : 0}}
                                            text={"How it works"}/>
                                <MainButton color={ButtonColors.white}
                                            href={translate("hd.links.contact")}
                                            text={"Contact us"}/>
                            </div>
                            <MainButton color={ButtonColors.white}
                                        href={translate("hd.links.blogs")}
                                        text={translate("hd.blogBlock.backToOverview")}
                                        style={{float: "right"}}/>
                        </div>
                    </div>
                </div>
            </GradientContainer>
            <IconsBlock width={width}/>
            <Footer/></>)
    }
    return (<><GrayContainer style={{}}>
            <NavigationHeader width={width}/>
            <div style={{
                width: "100%",
                alignSelf: "flex-start",
                paddingLeft: "17%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column"
            }}>
                <span style={{paddingTop: "40px", color: "#334c6e88"}}>{"26-06-2023"}</span>
                <PageTitle style={{
                    paddingBottom: 20,
                    marginBlockEnd: 0,
                    marginBlockStart: 5,
                    paddingRight: width < 768 ? "15%" : "30%"
                }}
                           text={"Zo krijg je extra betrokkenheid van (potentiële) klanten met jouw digitale  visitekaartje"}/>

            </div>
        </GrayContainer>
            <GradientContainer style={{backgroundColor: "#42809620", marginTop: 0}}>
                <div className={"single-blog-block-container"}
                     style={width < 768 ? {paddingLeft: "5%", paddingRight: "5%"} : {
                         paddingLeft: "15%",
                         paddingRight: "15%"
                     }}>
                    <div className={"blog-block"}>
                        <span>Met een digitaal visitekaartje zorg je ervoor dat ontvangers meer doen dan alleen passief informatie ontvangen. Je kunt ze actief betrekken bij verdere interactie en zorgen voor activatie, zoals contact opnemen, een gesprek voortzetten, meer informatie of een offerte aanvragen, deelnemen aan acties of feedback geven. Hoe? Een paar tips.</span>
                        <br/>
                        <span>‘Engagement’ (het marketingwoord voor betrokkenheid) is van belang, omdat het helpt bij het opbouwen van relaties, het vergroten van merkbekendheid, het creëren van loyaliteit en het bevorderen van zakelijke kansen. Het stimuleren van engagement via een digitaal visitekaartje is de eerste stap om een duurzame interactie en relatie met potentiële klanten, partners of andere contacten op te bouwen. Hoe? Enkele tips:
</span><br/>
                        <span>Interactieve elementen: Maak je digitale visitekaartje interactief door gebruik te maken van elementen zoals klikbare knoppen, links naar relevante content of sociale media-profielen, of het integreren van een korte video. Dit trekt de aandacht van ontvangers en moedigt hen aan om verder te verkennen.
</span><br/>
                        <span>Aantrekkelijk ontwerp: Zorg ervoor dat je digitale visitekaartje visueel aantrekkelijk en professioneel ontworpen is. Gebruik aantrekkelijke kleuren, goed leesbare lettertypen en grafische elementen die je merk vertegenwoordigen. Een esthetisch aantrekkelijk visitekaartje wekt nieuwsgierigheid op en vergroot de kans dat ontvangers ermee willen interageren.
</span><br/>
                        <span>Persoonlijke boodschap: Voeg een persoonlijke touch toe aan je digitale visitekaartje door een korte, gepersonaliseerde boodschap op te nemen voor de ontvanger. Dit kan bijvoorbeeld een bedankje zijn voor een ontmoeting of een specifiek aspect van het gesprek dat jullie hebben gehad. Dit creëert een gevoel van waardering en maakt het visitekaartje persoonlijker.
</span>


                        <SubSubTitle style={subSubTitleStyle}
                                     text={"Geef je ontvangers een reden om betrokken te blijven"}/>
                        <span>Toegevoegde waarde: Bied de ontvangers iets van waarde aan via je digitale visitekaartje. Dit kan een gratis e-book, een whitepaper, een exclusieve kortingscode of toegang tot waardevolle content zijn. Door iets nuttigs of interessants aan te bieden, geef je ontvangers een reden om betrokken te blijven en verder contact met je op te nemen.</span><br/>
                        <span>Gemakkelijke contactmogelijkheden: Maak het voor ontvangers eenvoudig om contact met je op te nemen door duidelijke en directe contactmogelijkheden toe te voegen, zoals een e-mailadres, telefoonnummer of link naar een online afspraakplanner. Hoe gemakkelijker het is om met je in contact te komen, hoe waarschijnlijker het is dat ontvangers zich betrokken voelen en actie ondernemen.
</span>
                        <SubSubTitle style={subSubTitleStyle}
                                     text={"Interesse en proactiviteit versterken de betrokkenheid"}/>
                        <span>Interesse en proactiviteit versterken de betrokkenheid
Opvolging: Zorg voor een snelle en persoonlijke opvolging na het delen van je digitale visitekaartje. Stuur bijvoorbeeld een bedankmail of een follow-upbericht om verdere communicatie aan te moedigen. Het tonen van interesse en proactiviteit versterkt de betrokkenheid en laat zien dat je serieus geïnteresseerd bent in het opbouwen van een relatie.
</span><br/>
                        <span>Met Bizzerd maak je de beste eerste indruk. Maar dat niet alleen. Naast maximale mogelijkheden voor interactie heb je met Bizzerd ook inzicht in de impact van jouw visitekaartje en de betrokkenheid die je ermee creëert. Je kunt monitoren wat een ontvanger ermee doet, bijvoorbeeld op welke buttons wordt geklikt. Dat geeft je extra handvatten bij het onderhouden van contact.
</span><br/>
                        <span>Kies vandaag nog voor Bizzerd en creëer ook extra betrokkenheid van je doelgroep en zorg voor extra interactie. </span><br/>

                        <div style={{width: "100%", borderTop: "#EEF3F6 solid 3px", marginTop: 20}}/>
                        <div style={{
                            display: "flex",
                            flexDirection: width < 768 ? "column" : "row",
                            justifyContent: "space-between",
                            marginTop: 30
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: width < 768 ? "column" : "row",
                                marginBottom: width < 768 ? 20 : 0
                            }}>
                                <MainButton color={ButtonColors.orange}
                                            style={{marginRight: 20, marginBottom: width < 768 ? 20 : 0}}
                                            href={translate("hd.links.product")}
                                            text={"Hoe het werkt"}/>
                                <MainButton color={ButtonColors.white}
                                            href={translate("hd.links.contact")}
                                            text={"Naar contact"}/>
                            </div>
                            <MainButton color={ButtonColors.white}
                                        href={translate("hd.links.blogs")}
                                        text={translate("hd.blogBlock.backToOverview")}
                                        style={{float: "right"}}/>
                        </div>
                    </div>
                </div>
            </GradientContainer>
            <IconsBlock width={width}/>
            <Footer/>
        </>
    )
}

export function climateBlog(width, language) {
    if (language === "en") {
        return (<><GrayContainer style={{}}>
            <NavigationHeader width={width}/>
            <div style={{
                width: "100%",
                alignSelf: "flex-start",
                paddingLeft: "17%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column"
            }}>
                <span style={{paddingTop: "40px", color: "#334c6e88"}}>{"26-06-2023"}</span>
                <PageTitle style={{
                    paddingBottom: 20,
                    marginBlockEnd: 0,
                    marginBlockStart: 5,
                    paddingRight: width < 768 ? "15%" : "30%"
                }}
                           text={"Here’s how your business cards show that you take sustainability seriously"}/>

            </div>
        </GrayContainer>
            <GradientContainer style={{backgroundColor: "#42809620", marginTop: 0}}>
                <div className={"single-blog-block-container"}
                     style={width < 768 ? {paddingLeft: "5%", paddingRight: "5%"} : {
                         paddingLeft: "15%",
                         paddingRight: "15%"
                     }}>
                    <div className={"blog-block"}>
                        <span>Your organisation takes sustainability seriously and takes as many measures as possible to improve the environment and limit climate change. What can digital business cards contribute to this?
</span>
                        <span>You might think that paper business cards don't matter that much when it comes to the environment and climate. What do those little pieces of paper matter? Well, a lot! Did you know that over 10 billion business cards are printed worldwide every year? That's about 27 million every day! And of those, most are thrown away almost immediately, so they mainly contribute to a large waste mountain. We can easily do something about that.
</span><br/>
                        <span>If you choose digital business cards, you will contribute to the reduction of paper waste and prevent the unnecessary cutting down of trees. But not only that. Energy-intensive processes such as printing and transport also have a considerable environmental impact and generate CO2 emissions.
</span>
                        <SubSubTitle style={subSubTitleStyle}
                                     text={"From (foot)print to digital"}/>

                        <span>And what about all those boxes of paper business cards that end up in the waste after an organisation has introduced a new house style? Or when just a phone number has changed. The big advantage of digital business cards is that you can change the data on them as often as you want, including a new house style. The changes are implemented in one go on the cards of all users, whether they are dozens or (hundreds of) thousands. Here, too, care for the climate and the environment goes hand in hand with cost savings. No wonder more and more organisations are switching from (foot)print to digital. </span>
                        <SubSubTitle text={"You make a clear statement"} style={subSubTitleStyle}/>
                        <span>Of course, digital business cards alone are not going to save the world. But it is a valuable contribution to your policy that makes more of a difference than you thought. Moreover, you clearly show that, as an organisation, you care about the environment and climate. You are making a clear statement. Another advantage is that it is a low-threshold measure. As an organisation, you can make the switch quickly and easily. You then also benefit from the many other advantages of interactive, digital business cards, such as cost savings and the many practical extra features.
</span>

                        <br/>

                        <span>Choose Bizzerd today and show that you take sustainability seriously.</span>
                        <div style={{width: "100%", borderTop: "#EEF3F6 solid 3px", marginTop: 20}}/>
                        <div style={{
                            display: "flex",
                            flexDirection: width < 768 ? "column" : "row",
                            justifyContent: "space-between",
                            marginTop: 30
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: width < 768 ? "column" : "row",
                                marginBottom: width < 768 ? 20 : 0
                            }}>
                                <MainButton color={ButtonColors.orange} href={translate("hd.links.product")}
                                            style={{marginRight: 20, marginBottom: width < 768 ? 20 : 0}}
                                            text={"How it works"}/>
                                <MainButton color={ButtonColors.white}
                                            href={translate("hd.links.contact")}
                                            text={"Contact us"}/>
                            </div>
                            <MainButton color={ButtonColors.white}
                                        href={translate("hd.links.blogs")}
                                        text={translate("hd.blogBlock.backToOverview")}
                                        style={{float: "right"}}/>
                        </div>
                    </div>
                </div>
            </GradientContainer>
            <IconsBlock width={width}/>
            <Footer/></>)
    }
    return (<><GrayContainer style={{}}>
            <NavigationHeader width={width}/>
            <div style={{
                width: "100%",
                alignSelf: "flex-start",
                paddingLeft: "17%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column"
            }}>
                <span style={{paddingTop: "40px", color: "#334c6e88"}}>{"26-06-2023"}</span>
                <PageTitle style={{
                    paddingBottom: 20,
                    marginBlockEnd: 0,
                    marginBlockStart: 5,
                    paddingRight: width < 768 ? "15%" : "30%"
                }}
                           text={"Zo laat je met je visitekaartjes zien dat je duurzaamheid serieus neemt"}/>

            </div>
        </GrayContainer>
            <GradientContainer style={{backgroundColor: "#42809620", marginTop: 0}}>
                <div className={"single-blog-block-container"}
                     style={width < 768 ? {paddingLeft: "5%", paddingRight: "5%"} : {
                         paddingLeft: "15%",
                         paddingRight: "15%"
                     }}>
                    <div className={"blog-block"}>
                        <span>Jouw organisatie neemt duurzaamheid serieus en zorgt voor zoveel mogelijk maatregelen om het milieu te verbeteren en klimaatverandering te beperken. Wat kunnen digitale visitekaartjes daaraan bijdragen?</span>
                        <span>Je zou denken dat papieren visitekaartjes niet zoveel uitmaken als het gaat om milieu en klimaat. Wat doen die kleine stukjes papier er nou toe? Nou, veel! Wist je dat er wereldwijd jaarlijks ruim 10 miljard visitekaartjes worden gedrukt? Dat is zo’n 27 miljoen per dag! En daarvan worden de meeste vrijwel meteen weer weggegooid, dus ze dragen vooral bij aan een grote afvalberg. Daar kunnen we makkelijk iets tegen doen.
</span><br/>
                        <span>Kies je voor digitale visitekaartjes dan draag je bij aan de vermindering van papierafval en voorkom je het onnodig kappen van bomen. Maar dat niet alleen. Ook energie-intensieve processen als het bedrukken en het transporteren hebben een forse milieu-impact en zorgen voor CO2-uitstoot.
</span>
                        <SubSubTitle style={subSubTitleStyle}
                                     text={"Van (foot)print naar digitaal"}/>
                        <span>En wat dacht je van al die dozen met papieren visitekaartjes die bij het afval belanden, nadat een organisatie een nieuwe huisstijl heeft ingevoerd? Of wanneer slechts een telefoonnummer is veranderd. Groot voordeel van digitale visitekaartjes is dat je de gegevens erop zo vaak kunt veranderen als je wil, inclusief een nieuwe huisstijl. De aanpassingen worden in 1 keer doorgevoerd op de kaartjes van alle gebruikers, of dat er nou tientallen zijn of (honderd)duizenden. Ook hier gaat zorg voor klimaat en milieu hand in hand met kostenbesparing. Geen wonder dat steeds meer organisaties overschakelen van (foot)print naar digitaal.
</span>
                        <SubSubTitle text={"Je maakt een helder statement"} style={subSubTitleStyle}/>
                        <span>Met digitale visitekaartjes alleen ga je de wereld natuurlijk niet redden. Maar het is wel een waardevolle bijdrage aan je beleid, die meer verschil maakt dan je dacht. Bovendien laat je duidelijk zien dat je als organisatie geeft om milieu en klimaat. Je maakt een helder statement. Een ander voordeel is dat het een laagdrempelige maatregel is. Als organisatie kun je snel en eenvoudig overschakelen. Je profiteert dan bovendien van de vele andere voordelen van interactieve, digitale visitekaartjes, zoals kostenbesparing en de vele praktische extra functies. </span>
                        <br/>
                        <span>Kies vandaag nog voor Bizzerd en kies voor duurzaamheid en laat zien dat je duurzaamheid serieus neemt. </span>
                        <div style={{width: "100%", borderTop: "#EEF3F6 solid 3px", marginTop: 20}}/>
                        <div style={{
                            display: "flex",
                            flexDirection: width < 768 ? "column" : "row",
                            justifyContent: "space-between",
                            marginTop: 30
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: width < 768 ? "column" : "row",
                                marginBottom: width < 768 ? 20 : 0
                            }}>
                                <MainButton color={ButtonColors.orange}
                                            style={{marginRight: 20, marginBottom: width < 768 ? 20 : 0}}
                                            href={translate("hd.links.product")}
                                            text={"Hoe het werkt"}/>
                                <MainButton color={ButtonColors.white}
                                            href={translate("hd.links.contact")}
                                            text={"Naar contact"}/>
                            </div>
                            <MainButton color={ButtonColors.white}
                                        href={translate("hd.links.blogs")}
                                        text={translate("hd.blogBlock.backToOverview")}
                                        style={{float: "right"}}/>
                        </div>
                    </div>
                </div>
            </GradientContainer>
            <IconsBlock width={width}/>
            <Footer/>
        </>
    )
}

export function corporateIdentityBlog(width, language) {
    if (language === "en") {
        return (<><GrayContainer style={{}}>
            <NavigationHeader width={width}/>
            <div style={{
                width: "100%",
                alignSelf: "flex-start",
                paddingLeft: "17%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column"
            }}>
                <span style={{paddingTop: "40px", color: "#334c6e88"}}>{"26-06-2023"}</span>
                <PageTitle style={{
                    paddingBottom: 20,
                    marginBlockEnd: 0,
                    marginBlockStart: 5,
                    paddingRight: width < 768 ? "15%" : "30%"
                }}
                           text={"Here’s how to enhance your brand with a digital business card"}/>

            </div>
        </GrayContainer>
            <GradientContainer style={{backgroundColor: "#42809620", marginTop: 0}}>
                <div className={"single-blog-block-container"}
                     style={width < 768 ? {paddingLeft: "5%", paddingRight: "5%"} : {
                         paddingLeft: "15%",
                         paddingRight: "15%"
                     }}>
                    <div className={"blog-block"}>
                        <span>Leading, meaningful brands stand or fall with a consistent translation of the brand identity. But not only that, a brand should also inspire and capture the imagination. So brands invest a lot in their brand identity and in brand communication. This ranges from appealing commercials to daily branding such as signing, corporate clothing and stationary.</span>
                        <SubSubTitle style={subSubTitleStyle}
                                     text={"The business card is seen as insignificant"}/>
                        <span>Of course, the business card is always included in this, but unfortunately it is often seen as an afterthought. The business card is seen as insignificant. And increasingly it is even forgotten, because there is LinkedIn, right?</span>
                        <br/>
                        <span>A missed opportunity, because it is precisely the business card that is an excellent tool to bring your brand to life, and in a personal way. At least, if, as an organisation, you opt for an interactive, digital business card.</span>
                        <SubSubTitle text={"Every brand comes to life"} style={subSubTitleStyle}/>
                        <span>With an interactive business card, you strengthen your organisation's visual identity and branding. Each brand comes to life, thanks to its personal and distinctive character. Each interactive business card is a lively expression of the corporate identity and turns employees into real organisational ambassadors. </span>
                        <br/>
                        <span>Think, for example, of a beer brand that makes its business cards sparkle with a moving visual of a fizzy glass of beer. Or a trade fair organiser that has all employees photographed in such an appealing way that they are a personification of the brand.
</span>
                        <SubSubTitle text={"Interactive presentation platform"} style={subSubTitleStyle}/>
                        <span>All the possibilities for interaction also ensure the effective use of digital business cards as 'brand amplifiers'. Organisations that link presentations, videos or background information, for example, truly turn their digital business cards into an interactive presentation platform.</span><br/>
                        <span>By using all the technical options and design possibilities, you can turn an interactive business card into one of the most effective personal communication tools. And completely in your organisation's house style.
</span>

                        <SubSubTitle text={"How to make the switch"} style={subSubTitleStyle}/>
                        <span>Switching to an interactive business card is easy. Smaller organisations and freelancers can create and manage their own business cards at <a
                            href={"https://www.bizzerd.com"} target={"_blank"}>www.bizzerd.com</a>. There you will find an extensive collection of designs. Most large(r) organisations choose to have their digital business cards customised, increasingly linked to crm systems (Afas, Microsoft). </span><br/>
                        <span>Choose Bizzerd today and strengthen your brand (experience) too.</span>


                        <div style={{width: "100%", borderTop: "#EEF3F6 solid 3px", marginTop: 20}}/>
                        <div style={{
                            display: "flex",
                            flexDirection: width < 768 ? "column" : "row",
                            justifyContent: "space-between",
                            marginTop: 30
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: width < 768 ? "column" : "row",
                                marginBottom: width < 768 ? 20 : 0
                            }}>
                                <MainButton color={ButtonColors.orange} href={translate("hd.links.product")}
                                            style={{marginRight: 20, marginBottom: width < 768 ? 20 : 0}}
                                            text={"How it works"}/>
                                <MainButton color={ButtonColors.white}
                                            href={translate("hd.links.contact")}
                                            text={"Contact us"}/>
                            </div>
                            <MainButton color={ButtonColors.white}
                                        href={translate("hd.links.blogs")}
                                        text={translate("hd.blogBlock.backToOverview")}
                                        style={{float: "right"}}/>
                        </div>
                    </div>
                </div>
            </GradientContainer>
            <IconsBlock width={width}/>
            <Footer/></>)
    }
    return (<><GrayContainer style={{}}>
            <NavigationHeader width={width}/>
            <div style={{
                width: "100%",
                alignSelf: "flex-start",
                paddingLeft: "17%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column"
            }}>
                <span style={{paddingTop: "40px", color: "#334c6e88"}}>{"26-06-2023"}</span>
                <PageTitle style={{
                    paddingBottom: 20,
                    marginBlockEnd: 0,
                    marginBlockStart: 5,
                    paddingRight: width < 768 ? "15%" : "30%"
                }}
                           text={"Zo versterk je jouw merk met een digitaal visitekaartje"}/>

            </div>
        </GrayContainer>
            <GradientContainer style={{backgroundColor: "#42809620", marginTop: 0}}>
                <div className={"single-blog-block-container"}
                     style={width < 768 ? {paddingLeft: "5%", paddingRight: "5%"} : {
                         paddingLeft: "15%",
                         paddingRight: "15%"
                     }}>
                    <div className={"blog-block"}>
                        <span>Leidende, betekenisvolle merken staan of vallen met een consistente doorvertaling van de merkidentiteit. Maar dat niet alleen, een merk moet ook inspireren en tot de verbeelding spreken. Zodoende investeren merken veel in hun brand identity en in merkcommunicatie. Dat loopt uiteen van aansprekende commercials tot dagelijkse huisstijluitingen als signing, bedrijfskleding en stationary.
</span>
                        <SubSubTitle style={subSubTitleStyle}
                                     text={"Het visitekaartje wordt een beetje gezien als een ‘moetje’"}/>
                        <span>Het visitekaartje wordt daarbij uiteraard altijd meegenomen, maar helaas wel vaak als ondergeschoven kindje. Het visitekaartje wordt een beetje gezien als een ‘moetje’. En steeds vaker wordt het zelfs vergeten, want er is LinkedIn, toch?
</span>
                        <br/>
                        <span>Een gemiste kans, want nou juist het visitekaartje is een uitgelezen instrument om je merk tot leven te brengen, en dat ook nog eens op een persoonlijke manier. Tenminste, als je als organisatie kiest voor een interactief, digitaal visitekaartje.
</span>
                        <SubSubTitle text={"Elk merk komt tot leven"} style={subSubTitleStyle}/>
                        <span>Met een interactief visitekaartje versterk je de visuele identiteit en branding van je organisatie. Elk merk komt tot leven, dankzij het persoonlijke en onderscheidende karakter. Elk interactief visitekaartje is een levendige uiting van de huisstijl en maakt van medewerkers echte organisatieambassadeurs. </span>
                        <br/>
                        <span>Denk bijvoorbeeld aan een biermerk dat z’n visitekaartjes sprankelend maakt met een bewegende visual van een bruisend glas bier. Of een beursorganisator die alle medewerkers zo aansprekend op de foto laat zetten dat ze een verpersoonlijking zijn van het merk.
</span>
                        <SubSubTitle text={"Interactief presentatieplatform"} style={subSubTitleStyle}/>
                        <span>Ook zorgen alle mogelijkheden voor interactie voor het effectief inzetten van digitale visitekaartjes als ‘merkversterker’. Organisaties die bijvoorbeeld presentaties, video’s of achtergrondinformatie koppelen, maken van hun digitale visitekaartjes echt een interactief presentatieplatform.
</span><br/>
                        <span>Door gebruik te maken van alle technische opties en designmogelijkheden kun je van een interactief visitekaartje een van de meest effectieve persoonlijke communicatiemiddelen maken. En dat volledig in de huisstijl van je organisatie.
</span>

                        <SubSubTitle text={"Zo stap je over"} style={subSubTitleStyle}/>
                        <span>Het overstappen op een interactief visitekaartje is laagdrempelig. Kleinere organisaties en zzp’ers kunnen zelf hun visitekaartjes creëren en beheren op <a
                            href={"https://www.bizzerd.com"} target={"_blank"}>www.bizzerd.com</a>. Daar vind je een uitgebreide collectie designs. De meeste grote(re) organisaties kiezen ervoor om hun digitale visitekaartjes op maat te laten maken, steeds vaker gekoppeld aan crm-systemen (Afas, Microsoft).</span><br/>
                        <span>Kies vandaag nog voor Bizzerd en versterk ook je merk(beleving).</span>


                        <div style={{width: "100%", borderTop: "#EEF3F6 solid 3px", marginTop: 20}}/>
                        <div style={{
                            display: "flex",
                            flexDirection: width < 768 ? "column" : "row",
                            justifyContent: "space-between",
                            marginTop: 30
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: width < 768 ? "column" : "row",
                                marginBottom: width < 768 ? 20 : 0
                            }}>
                                <MainButton color={ButtonColors.orange}
                                            style={{marginRight: 20, marginBottom: width < 768 ? 20 : 0}}
                                            href={translate("hd.links.product")}
                                            text={"Hoe het werkt"}/>
                                <MainButton color={ButtonColors.white}
                                            href={translate("hd.links.contact")}
                                            text={"Naar contact"}/>
                            </div>
                            <MainButton color={ButtonColors.white}
                                        href={translate("hd.links.blogs")}
                                        text={translate("hd.blogBlock.backToOverview")}
                                        style={{float: "right"}}/>
                        </div>
                    </div>
                </div>
            </GradientContainer>
            <IconsBlock width={width}/>
            <Footer/>
        </>
    )
}

export function crmHrmBlog(width, language) {
    if (language === "en") {
        return (<><GrayContainer style={{}}>
            <NavigationHeader width={width}/>
            <div style={{
                width: "100%",
                alignSelf: "flex-start",
                paddingLeft: "17%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column"
            }}>
                <span style={{paddingTop: "40px", color: "#334c6e88"}}>{"26-06-2023"}</span>
                <PageTitle style={{
                    paddingBottom: 20,
                    marginBlockEnd: 0,
                    marginBlockStart: 5,
                    paddingRight: width < 768 ? "15%" : "30%"
                }}
                           text={"Here's how to automatically link business cards to your CRM and HRM system"}/>

            </div>
        </GrayContainer>
            <GradientContainer style={{backgroundColor: "#42809620", marginTop: 0}}>
                <div className={"single-blog-block-container"}
                     style={width < 768 ? {paddingLeft: "5%", paddingRight: "5%"} : {
                         paddingLeft: "15%",
                         paddingRight: "15%"
                     }}>
                    <div className={"blog-block"}>
                        <span>New employee hired? Then you also need to arrange business cards. Fortunately, these days this is a matter of a few clicks. Thanks to linking digital business cards to CRM and HRM systems. You can read how that works here.
</span>

                        <span>Good software is at the basis of CRM (Customer Relationship Management). In a CRM system, you organise and manage all your organisation's contacts, relationships and customers. Most organisations choose leading systems such as Afas and Microsoft Azure.
</span><br/>
                        <span>For HRM (Human Resources Management), Afas is also one of the best-known software providers. Afas software can be used to arrange matters such as personnel administration, payroll processing, leave, absence and recruitment & selection.
</span><br/>
                        <span>Because of the ease of use, more and more organisations choose to link their digital business cards to CRM and HRM systems. This offers many advantages. It makes business card management even easier. New colleagues automatically receive a digital business card, with all details correctly stated. Simply ticking the box in the system is enough. Completely in your organisation's house style.  New employees then receive their digital business card in the mailbox, with link and details. Ready for use.
</span>
                        <SubSubTitle style={subSubTitleStyle}
                                     text={"Statistics for insight and overview in CRM"}/>
                        <span>Digital business cards issued to prospects or customers are registered in the CRM. You can also monitor what happens to shared digital business cards: how often they are viewed, which parts are clicked and how often they are shared again. But also, for example: which account managers share the most business cards?
</span>
                        <SubSubTitle text={"No more worries about managing business cards"} style={subSubTitleStyle}/>
                        <span>Any changes? For example, a job change or a new phone number. These are implemented the same day. Is the employee leaving service? The digital business card is terminated immediately. All business cards are updated at once if company details change. You no longer have to worry about it. </span><br/>
                        <span>Choose Bizzerd today and link your digital business cards to the crm/hrm system.</span>

                        <div style={{width: "100%", borderTop: "#EEF3F6 solid 3px", marginTop: 20}}/>
                        <div style={{
                            display: "flex",
                            flexDirection: width < 768 ? "column" : "row",
                            justifyContent: "space-between",
                            marginTop: 30
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: width < 768 ? "column" : "row",
                                marginBottom: width < 768 ? 20 : 0
                            }}>
                                <MainButton color={ButtonColors.orange} href={translate("hd.links.product")}
                                            style={{marginRight: 20, marginBottom: width < 768 ? 20 : 0}}
                                            text={"How it works"}/>
                                <MainButton color={ButtonColors.white}
                                            href={translate("hd.links.contact")}
                                            text={"Contact us"}/>
                            </div>
                            <MainButton color={ButtonColors.white}
                                        href={translate("hd.links.blogs")}
                                        text={translate("hd.blogBlock.backToOverview")}
                                        style={{float: "right"}}/>
                        </div>
                    </div>
                </div>
            </GradientContainer>
            <IconsBlock width={width}/>
            <Footer/></>)
    }
    return (<><GrayContainer style={{}}>
            <NavigationHeader width={width}/>
            <div style={{
                width: "100%",
                alignSelf: "flex-start",
                paddingLeft: "17%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column"
            }}>
                <span style={{paddingTop: "40px", color: "#334c6e88"}}>{"26-06-2023"}</span>
                <PageTitle style={{
                    paddingBottom: 20,
                    marginBlockEnd: 0,
                    marginBlockStart: 5,
                    paddingRight: width < 768 ? "15%" : "30%"
                }}
                           text={"Zo koppel je automatisch visitekaartjes aan je CRM- en HRM-systeem"}/>

            </div>
        </GrayContainer>
            <GradientContainer style={{backgroundColor: "#42809620", marginTop: 0}}>
                <div className={"single-blog-block-container"}
                     style={width < 768 ? {paddingLeft: "5%", paddingRight: "5%"} : {
                         paddingLeft: "15%",
                         paddingRight: "15%"
                     }}>
                    <div className={"blog-block"}>
                        <span>Nieuwe medewerker in dienst? Dan moet je ook visitekaartjes regelen. Gelukkig is dat tegenwoordig een kwestie van een paar keer klikken. Dankzij het koppelen van digitale visitekaartjes aan CRM- en HRM-systemen. Hoe dat werkt, lees je hier.

</span>

                        <span>Goede software staat aan de basis van CRM (Customer Relationship Management), ofwel relatiebeheer. In een CRM-systeem organiseer en beheer je alle contacten, relaties en klanten van je organisatie. De meeste organisaties kiezen voor leidende systemen als Afas en Microsoft Azure.

</span><br/>
                        <span>Voor HRM (Human Resources Management) is Afas ook een van de bekendste aanbieders van software. Met de software van Afas regel je zaken als personeelsadministratie, salarisverwerking, verlof, verzuim en werving & selectie.

</span><br/>
                        <span>Vanwege het gebruiksgemak kiezen steeds meer organisaties voor het koppelen van hun digitale visitekaartjes aan CRM- en HRM-systemen. Dit biedt veel voordelen. Het beheer van visitekaartjes wordt zo nóg makkelijker. Nieuwe collega’s krijgen automatisch een digitaal visitekaartje, met alle gegevens vanzelf correct vermeld. Aanvinken in het systeem is voldoende. Volledig in de huisstijl van je organisatie.  Nieuwe medewerkers  ontvangen vervolgens hun digitale visitekaartje in de mailbox, met link en gegevens. Klaar voor gebruik.

</span>
                        <SubSubTitle style={subSubTitleStyle}
                                     text={"Statistieken voor inzicht en overzicht in CRM"}/>
                        <span>Digitale visitekaartjes die worden verstrekt aan prospects of klanten, worden geregistreerd is het CRM. Ook kun je monitoren wat er gebeurt met gedeelde digitale visitekaartjes: hoe vaak worden ze bekeken, welke onderdelen worden aangeklikt en hoe vaak worden ze opnieuw gedeeld. Maar ook bijvoorbeeld: welke accountmanagers delen de meeste visitekaartjes?

</span>
                        <SubSubTitle text={"Geen omkijken meer naar beheer visitekaartjes\n"} style={subSubTitleStyle}/>
                        <span>Zijn er wijzigingen? Bijvoorbeeld een functieverandering of een nieuw telefoonnummer. Deze worden dezelfde dag nog doorgevoerd. Gaat de medewerker uit dienst? Het digitale visitekaartje wordt direct beëindigd. Alle visitekaartjes worden in 1 keer geüpdatet als bedrijfsgegevens veranderen. Je hebt er geen omkijken meer naar. </span><br/>
                        <span>Kies vandaag nog voor Bizzerd en creëer koppel ook jullie digitale visitekaartjes aan het crm/hrm-systeem.</span>
                        <div style={{width: "100%", borderTop: "#EEF3F6 solid 3px", marginTop: 20}}/>
                        <div style={{
                            display: "flex",
                            flexDirection: width < 768 ? "column" : "row",
                            justifyContent: "space-between",
                            marginTop: 30
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: width < 768 ? "column" : "row",
                                marginBottom: width < 768 ? 20 : 0
                            }}>
                                <MainButton color={ButtonColors.orange}
                                            style={{marginRight: 20, marginBottom: width < 768 ? 20 : 0}}
                                            href={translate("hd.links.product")}
                                            text={"Hoe het werkt"}/>
                                <MainButton color={ButtonColors.white}
                                            href={translate("hd.links.contact")}
                                            text={"Naar contact"}/>
                            </div>
                            <MainButton color={ButtonColors.white}
                                        href={translate("hd.links.blogs")}
                                        text={translate("hd.blogBlock.backToOverview")}
                                        style={{float: "right"}}/>
                        </div>
                    </div>
                </div>
            </GradientContainer>
            <IconsBlock width={width}/>
            <Footer/>
        </>
    )
}

export function createNFCBlog(width, language) {
    if (language === "en") {
        return (<><GrayContainer style={{}}>
            <NavigationHeader width={width}/>
            <div style={{
                width: "100%",
                alignSelf: "flex-start",
                paddingLeft: "17%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column"
            }}>
                <span style={{paddingTop: "40px", color: "#334c6e88"}}>{"26-06-2023"}</span>
                <PageTitle style={{
                    paddingBottom: 20,
                    marginBlockEnd: 0,
                    marginBlockStart: 5,
                    paddingRight: width < 768 ? "15%" : "30%"
                }}
                           text={"Here’s how to make your own NFC business card"}/>

            </div>
        </GrayContainer>
            <GradientContainer style={{backgroundColor: "#42809620", marginTop: 0}}>
                <div className={"single-blog-block-container"}
                     style={width < 768 ? {paddingLeft: "5%", paddingRight: "5%"} : {
                         paddingLeft: "15%",
                         paddingRight: "15%"
                     }}>
                    <div className={"blog-block"}>
                        <span>You have probably heard of it or dealt with it: an NFC business card. An NFC business card is a digital business card that uses Near Field Communication (NFC) technology. NFC is a wireless communication technology that allows data to be exchanged between two devices located within a short distance of each other, usually within a few centimetres.</span>


                        <span>With an NFC business card, you share your data with another person very easily. The person you want to give your business card to holds her/his phone (or other device) near your NFC tag and then gets your business card in 1 second. That's it!
</span><br/>
                        <span>With an NFC business card, different types of data can be shared, such as:</span><br/>
                        <ul>
                            <li>Contact details: Name, phone number, e-mail address, address, and so on.</li>
                            <li>Website url: A direct link to your personal website or company website.
                            </li>
                            <li>Social media profiles: Links to your profiles on social media platforms such as
                                LinkedIn, Twitter, Facebook, and so on.
                            </li>
                            <li>Portfolio or project information: A link to an online portfolio or information about
                                your projects.
                            </li>
                            <li>Dynamic content: You can even programme the NFC tag to deliver dynamic content, such as
                                updating data or displaying current offers.
                            </li>
                        </ul>
                        <br/>
                        <span>NFC business cards offer a convenient and contemporary way to quickly share contact details and other relevant information. They use the NFC functionality available on many smartphones and other NFC devices today.</span><br/>
                        <span>Super convenient, in other words. You want that too! So get started. Here's how to make your own NFC business card:</span>
                        <SubSubTitle style={subSubTitleStyle}
                                     text={"Step 1: Buy an NFC tag"}/>
                        <span>You need an NFC tag that you can programme with the desired data. There are different types of NFC tags available, such as stickers, cards and key rings. Make sure you choose an NFC tag suitable for your needs.</span>

                        <SubSubTitle text={"Step 2: Choose the right data"} style={subSubTitleStyle}/>
                        <span>Decide what information you want to store on your business card. This could be your name, contact details, website, social media profiles and so on. Think about what data is relevant to you and what you want to share with others. If you use Bizzerd, you can also link the data from your digital business card.</span>

                        <SubSubTitle text={"Step 3: Program the NFC tag"} style={subSubTitleStyle}/>
                        <span>To programme the NFC tag, you will need an NFC reader/writer. This could be a dedicated NFC reader or a smartphone with NFC functionality. There are several apps available that allow you to programme NFC tags, such as NFC Tools (for Android) or NFC Writer (for iPhone).</span>
                        <br/>
                        <span>Open the NFC programming app and follow the instructions to store the desired data on the NFC tag. You can fill in the various fields with the information you have chosen. Make sure you check everything before saving the data.</span>

                        <SubSubTitle text={"Step 4: Test your NFC business card"} style={subSubTitleStyle}/>
                        <span>Once you have programmed the NFC tag, you can test your business card. Open an NFC reader app on your smartphone and hold your phone near the NFC tag. The app should be able to read and display the stored data.</span>

                        <SubSubTitle text={"Step 5: Attach the NFC tag to your business card"}
                                     style={subSubTitleStyle}/>
                        <span>Once you have verified that your NFC tag is working correctly, you can attach it to your business card. You can do this by gluing, stapling or otherwise attaching the NFC tag.</span>

                        <SubSubTitle text={"Step 6: Distribute your NFC business cards"} style={subSubTitleStyle}/>
                        <span>Now that you have created your own NFC business cards, you can distribute them to people who might be interested in your contact details. When someone else with an NFC-compatible phone holds her/his device near your business card, the information on the NFC tag will be read. So you only need 1 tag, which you can keep using indefinitely.</span>

                        <SubSubTitle text={"Step 7: Keep your data up-to-date"} style={subSubTitleStyle}/>
                        <span>Make sure you update your NFC business cards if your contact details or other information changes.</span>

                        <SubSubTitle text={"Too complicated?"} style={subSubTitleStyle}/>
                        <span>At Bizzerd, we have found that some people find buying and programming an NFC tag themselves difficult or complicated. That's why we've added NFC tags. From now on, you can also get Bizzerd with an NFC tag. You can choose a bamboo or wooden business card with the NFC tag or a round sticker, which you can stick on the back of your phone, for instance. The NFC tag is then a practical sharing option that you add to your digital business card. But should you have forgotten the tag, there is no problem. Then you can always just share your digital business card in one of the many other ways, such as with the qr code, or via WhatsApp, SMS or e-mail.</span>


                        <span>Choose Bizzerd today and link your digital business cards to the crm/hrm system.</span>

                        <div style={{width: "100%", borderTop: "#EEF3F6 solid 3px", marginTop: 20}}/>
                        <div style={{
                            display: "flex",
                            flexDirection: width < 768 ? "column" : "row",
                            justifyContent: "space-between",
                            marginTop: 30
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: width < 768 ? "column" : "row",
                                marginBottom: width < 768 ? 20 : 0
                            }}>
                                <MainButton color={ButtonColors.orange} href={translate("hd.links.product")}
                                            style={{marginRight: 20, marginBottom: width < 768 ? 20 : 0}}
                                            text={"How it works"}/>
                                <MainButton color={ButtonColors.white}
                                            href={translate("hd.links.contact")}
                                            text={"Contact us"}/>
                            </div>
                            <MainButton color={ButtonColors.white}
                                        href={translate("hd.links.blogs")}
                                        text={translate("hd.blogBlock.backToOverview")}
                                        style={{float: "right"}}/>
                        </div>
                    </div>
                </div>
            </GradientContainer>
            <IconsBlock width={width}/>
            <Footer/></>)
    }
    return (<><GrayContainer style={{}}>
            <NavigationHeader width={width}/>
            <div style={{
                width: "100%",
                alignSelf: "flex-start",
                paddingLeft: "17%",
                textAlign: "left",
                display: "flex",
                flexDirection: "column"
            }}>
                <span style={{paddingTop: "40px", color: "#334c6e88"}}>{"26-06-2023"}</span>
                <PageTitle style={{
                    paddingBottom: 20,
                    marginBlockEnd: 0,
                    marginBlockStart: 5,
                    paddingRight: width < 768 ? "15%" : "30%"
                }}
                           text={"Zo maak je zelf een NFC-visitekaartje"}/>

            </div>
        </GrayContainer>
            <GradientContainer style={{backgroundColor: "#42809620", marginTop: 0}}>
                <div className={"single-blog-block-container"}
                     style={width < 768 ? {paddingLeft: "5%", paddingRight: "5%"} : {
                         paddingLeft: "15%",
                         paddingRight: "15%"
                     }}>
                    <div className={"blog-block"}>

                    <span>Je hebt er vast wel eens van gehoord of mee te maken gehad: een NFC-visitekaartje. Een NFC-visitekaartje is een digitaal visitekaartje dat gebruikmaakt van NFC-technologie (Near Field Communication). NFC is een draadloze communicatietechnologie die het mogelijk maakt om gegevens uit te wisselen tussen twee apparaten die zich binnen een korte afstand van elkaar bevinden, meestal binnen enkele centimeters.
</span>


                        <span>Met een NFC-visitekaartje deel je jouw gegevens heel makkelijk met een ander. Degene die jij jouw visitekaartje wil geven, houdt haar/zijn telefoon (of een ander apparaat) bij jouw NFC-tag en krijgt dan in 1 seconde jouw visitekaartje. Dat is alles!


</span><br/>
                        <span>Met een NFC-visitekaartje kunnen verschillende soorten gegevens worden gedeeld, zoals:</span><br/>
                        <ul>
                            <li>Contactgegevens: Naam, telefoonnummer, e-mailadres, adres, enzovoort.</li>
                            <li>Website-url: Een directe link naar je persoonlijke website of bedrijfswebsite.

                            </li>
                            <li>Sociale media-profielen: Links naar je profielen op sociale mediaplatforms zoals
                                LinkedIn,
                                Twitter,
                                Facebook, enzovoort.

                            </li>
                            <li>Portfolio of projectinformatie: Een link naar een online portfolio of informatie over je
                                projecten.

                            </li>
                            <li>Dynamische inhoud: Je kunt zelfs de NFC-tag zo programmeren dat deze dynamische inhoud
                                levert, zoals
                                het bijwerken van de gegevens of het weergeven van actuele aanbiedingen.

                            </li>
                        </ul>
                        <br/>
                        <span>NFC-visitekaartjes bieden een handige en eigentijdse manier om contactgegevens en andere relevante informatie snel te delen. Ze maken gebruik van de NFC-functionaliteit die tegenwoordig beschikbaar is op veel smartphones en andere NFC-apparaten.
</span><br/>
                        <span>Superhandig dus. Dat wil je ook! Aan de slag dus. Zo maak je zelf een NFC-visitekaartje:
</span>
                        <SubSubTitle style={subSubTitleStyle}
                                     text={"Stap 1: Koop een NFC-tag"}/>
                        <span>
Je hebt een NFC-tag nodig die je kunt programmeren met de gewenste gegevens. Er zijn verschillende soorten NFC-tags beschikbaar, zoals stickers, kaarten en sleutelhangers. Zorg ervoor dat je een NFC-tag kiest die geschikt is voor jouw behoeften.
</span>

                        <SubSubTitle text={"Stap 2: Kies de juiste gegevens\n"} style={subSubTitleStyle}/>
                        <span>
Bepaal welke informatie je op je visitekaartje wilt opslaan. Dit kan je naam, contactgegevens, website, sociale mediaprofielen, enzovoort zijn. Denk na over welke gegevens voor jou relevant zijn en wat je wilt delen met anderen. Als je Bizzerd gebruikt, kun je ook de gegevens van jouw digitale visitekaartje koppelen.
</span>

                        <SubSubTitle text={"Stap 3: Programmeer de NFC-tag\n"} style={subSubTitleStyle}/>
                        <span>
Om de NFC-tag te programmeren, heb je een NFC-lezer/schrijver nodig. Dit kan een speciale NFC-lezer zijn of een smartphone met NFC-functionaliteit. Er zijn verschillende apps beschikbaar waarmee je NFC-tags kunt programmeren, zoals NFC Tools (voor Android) of NFC Writer (voor iPhone).
</span>
                        <br/>
                        <span>Open de NFC-programmeer-app en volg de instructies om de gewenste gegevens op de NFC-tag op te slaan. Je kunt de verschillende velden invullen met de informatie die je hebt gekozen. Zorg ervoor dat je alles controleert voordat je de gegevens opslaat.
</span>

                        <SubSubTitle text={"Stap 4: Test je NFC-visitekaartje\n"} style={subSubTitleStyle}/>
                        <span>
Zodra je de NFC-tag hebt geprogrammeerd, kun je je visitekaartje testen. Open een NFC-lezer-app op je smartphone en houd je telefoon in de buurt van de NFC-tag. De app moet de opgeslagen gegevens kunnen lezen en weergeven.
</span>

                        <SubSubTitle text={"Stap 5: Bevestig de NFC-tag op je visitekaartje\n"}
                                     style={subSubTitleStyle}/>
                        <span>
Als je hebt gecontroleerd dat je NFC-tag correct werkt, kun je deze bevestigen aan je visitekaartje. Dit kan door de NFC-tag te plakken, te nieten of op een andere manier te bevestigen.
</span>

                        <SubSubTitle text={"Stap 6: Distribueer je NFC-visitekaartjes\n"} style={subSubTitleStyle}/>
                        <span>
Nu je je eigen NFC-visitekaartjes hebt gemaakt, kun je ze uitdelen aan mensen die mogelijk geïnteresseerd zijn in je contactgegevens. Wanneer iemand anders met een NFC-compatibele telefoon haar/zijn toestel in de buurt van je visitekaartje houdt, wordt de informatie op de NFC-tag gelezen. Je hebt dus maar 1 tag nodig, die je oneindig kunt blijven gebruiken.
</span>

                        <SubSubTitle text={"Stap 7: Hou je gegevens up-to-date\n"} style={subSubTitleStyle}/>
                        <span>
Zorg ervoor dat je de NFC-visitekaartjes bijwerkt als je contactgegevens of andere informatie verandert.
</span>

                        <SubSubTitle text={"Te ingewikkeld?\n"} style={subSubTitleStyle}/>
                        <span>
Bij Bizzerd hebben we gemerkt dat sommigen het zelf kopen en programmeren van een NFC-tag lastig of ingewikkeld vinden. Daarom hebben we NFC-tags toegevoegd. Voortaan kun je Bizzerd ook krijgen met een NFC-tag. Je kunt kiezen voor een bamboe of houten business card met de NFC-tag of voor een ronde sticker, die je bijvoorbeeld achterop je telefoon kunt plakken. De NFC-tag is dan een praktische deelmogelijkheid die je toevoegt aan jouw digitale visitekaartje. Maar mocht je de tag zijn vergeten, dan is er niets aan de hand. Dan kun je altijd gewoon jouw digitale visitekaartje delen op een van de vele andere manier, zoals met de qr-code, of via WhatsApp, sms of e-mail. </span>

                        <div style={{width: "100%", borderTop: "#EEF3F6 solid 3px", marginTop: 20}}/>
                        <div style={{
                            display: "flex",
                            flexDirection: width < 768 ? "column" : "row",
                            justifyContent: "space-between",
                            marginTop: 30
                        }}>
                            <div style={{
                                display: "flex",
                                flexDirection: width < 768 ? "column" : "row",
                                marginBottom: width < 768 ? 20 : 0
                            }}>
                                <MainButton color={ButtonColors.orange}
                                            style={{marginRight: 20, marginBottom: width < 768 ? 20 : 0}}
                                            href={translate("hd.links.product")}
                                            text={"Hoe het werkt"}/>
                                <MainButton color={ButtonColors.white}
                                            href={translate("hd.links.contact")}
                                            text={"Naar contact"}/>
                            </div>
                            <MainButton color={ButtonColors.white}
                                        href={translate("hd.links.blogs")}
                                        text={translate("hd.blogBlock.backToOverview")}
                                        style={{float: "right"}}/>
                        </div>
                    </div>
                </div>
            </GradientContainer>
            <IconsBlock width={width}/>
            <Footer/>
        </>
    )
}
import React, {ReactElement} from 'react';
import '../Icon.scss';
import IconProps from "../IconProps.interface";

const SettingsBackOffice = (props: IconProps): ReactElement => {

    return (
        <div className="icon">
            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" fill={props.color} viewBox="0 0 60 60" >{/*width="60" height="60"*/}
                <path id="Layer" fill-rule="evenodd" className="s0"
                      d="m54 27.2v4.6c0 1.5-1.2 2.8-2.7 3l-3.8 0.5q-0.5 1.5-1.2 2.8l2.4 3.1c0.9 1.2 0.8 2.9-0.3 4l-3.2 3.2c-1.1 1.1-2.8 1.2-4 0.3l-3.1-2.4q-1.3 0.7-2.8 1.2l-0.5 3.8c-0.2 1.5-1.5 2.7-3 2.7h-4.6c-1.5 0-2.8-1.2-3-2.7l-0.5-3.8q-1.5-0.5-2.8-1.2l-3.1 2.4c-1.2 0.9-2.9 0.8-4-0.3l-3.2-3.2c-1.1-1.1-1.2-2.8-0.3-4l2.4-3.1q-0.7-1.3-1.2-2.8l-3.8-0.5c-1.5-0.2-2.7-1.5-2.7-3v-4.6c0-1.5 1.2-2.8 2.7-3l3.8-0.5q0.5-1.5 1.2-2.8l-2.4-3.1c-0.9-1.2-0.8-2.9 0.3-4l3.2-3.2c1.1-1.1 2.8-1.2 4-0.3l3.1 2.4q1.3-0.7 2.8-1.2l0.5-3.8c0.2-1.5 1.5-2.7 3-2.7h4.6c1.5 0 2.8 1.2 3 2.7l0.5 3.8q1.5 0.5 2.8 1.2l3.1-2.4c1.2-0.9 2.9-0.8 4 0.3l3.2 3.2c1.1 1.1 1.2 2.8 0.3 4l-2.4 3.1q0.7 1.3 1.2 2.8l3.8 0.5c1.5 0.2 2.7 1.5 2.7 3zm-14.3 2.3c0-5.6-4.6-10.2-10.2-10.2-5.6 0-10.2 4.6-10.2 10.2 0 5.6 4.6 10.2 10.2 10.2 5.6 0 10.2-4.6 10.2-10.2z"/>
            </svg>
        </div>
    )
};

export default SettingsBackOffice;
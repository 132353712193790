import React from 'react'
import GrayContainer from "../common2/containers/gray.container";
import NavigationHeader from "../common2/headers/navigation.header";
import Footer from "../common2/footers/footer";
import ReviewBlock from "../common2/blocks/review.block";
import EnterpriseHeader from "../common2/headers/enterprise.header";
import PageTitle from "../common2/fields/text/pagetitle.text";
import MainButton from "../common2/buttons/action_main/main.button";
import ButtonColors from "../common2/buttons/button.colors";
import SecondaryButton from "../common2/buttons/action_secondary/secondary.button";
import CardContainer from "../common2/containers/card.container";
import {translate} from "react-i18nify";
import RoundBizzerdContainer from "../common2/containers/round.bizzerd.container";
import SellingBlock from "../common2/blocks/selling.block";

// @ts-ignore
import {default as brandingImg} from "../../assets/enterprice/ontwerp_icon.svg"
// @ts-ignore
import {default as crmImg} from "../../assets/enterprice/crm_icon.svg"
// @ts-ignore
import {default as engagementImg} from "../../assets/enterprice/engagement_icon.svg"
// @ts-ignore
import {default as privacyImg} from "../../assets/enterprice/privacy_icon.svg"
// @ts-ignore
import {default as scalableImg} from "../../assets/enterprice/schaalbaar_icon.svg"
// @ts-ignore
import {default as climateImg} from "../../assets/enterprice/duurzaam_icon.svg"
// @ts-ignore
import {default as securityImg} from "../../assets/enterprice/beveiliging_icon.svg"
// @ts-ignore
import {default as headerImg} from "../../assets/enterprice/header.enterprise.svg"
// @ts-ignore
import {default as brabant} from "../../assets/enterprice/designs/brabant.png";
// @ts-ignore
import {default as enactus} from "../../assets/enterprice/designs/enactus.png";
// @ts-ignore
import {default as hurks} from "../../assets/enterprice/designs/hurks.png";
// @ts-ignore
import {default as makeawish} from "../../assets/enterprice/designs/makeawish.png";
// @ts-ignore
import {default as nibud} from "../../assets/enterprice/designs/nibud.png";
// @ts-ignore
import {default as rijksmuseum} from "../../assets/enterprice/designs/rijksmuseum.png";
// @ts-ignore
import {default as site} from "../../assets/enterprice/designs/site.png";
// @ts-ignore
import {default as sonneveld} from "../../assets/enterprice/designs/sonneveld.png";
// @ts-ignore
import {default as thecolourkitchen} from "../../assets/enterprice/designs/thecolourkitchen.png";
// @ts-ignore
import {default as wbl} from "../../assets/enterprice/designs/wbl.png";

interface pageProps {
    width: number,

}


class EnterprisePage extends React.Component<pageProps, { randomCards: number[] }> {
    constructor(props) {
        super(props);
        let arr = [];
        while (arr.length < 6) {
            let r = Math.floor(Math.random() * 10 - 1) + 1;
            if (arr.indexOf(r) === -1) arr.push(r);
        }
        this.state = {
            randomCards: arr
        }
        setInterval(this.changeLogos, 5000)
    }

    changeLogos = () => {
        let arr = []
        while (arr.length < 6) {
            let r = Math.floor(Math.random() * 10 - 1) + 1;
            if (arr.indexOf(r) === -1) arr.push(r);
        }
        this.setState({randomCards: arr})
    }


    render() {
        let templates = this.calculateNrTemplates()


        return (<>
            <GrayContainer style={{backgroundColor: "#42809620", backgroundImage: "none", marginBottom: 20}}>
                <NavigationHeader width={this.props.width}/>
                <EnterpriseHeader width={this.props.width} img={headerImg}/>
            </GrayContainer>
            <SellingBlock width={this.props.width} blockType={"scalableBlock"} img={scalableImg}/>
            <SellingBlock width={this.props.width} blockType={"engagementBlock"} reverse={true} img={engagementImg}/>
            <SellingBlock width={this.props.width} blockType={"brandingBlock"} img={brandingImg}/>
            <SellingBlock width={this.props.width} blockType={"privacyBlock"} reverse={true} img={privacyImg}/>
            <SellingBlock width={this.props.width} blockType={"securityBlock"} img={securityImg}/>
            <SellingBlock width={this.props.width} blockType={"hrmBlock"} reverse={true} img={crmImg}/>
            <SellingBlock width={this.props.width} blockType={"climateBlock"} img={climateImg}/>
            <CardContainer>
                <div className={"title-button-row-container"}>
                    <div className={"title-thin-container"}>
                        <PageTitle text={translate("hd.enterprise.cardTitle")}/><PageTitle style={{marginLeft: "10px"}}
                                                                                           text={translate("hd.enterprise.fromPriceMonth")}
                                                                                           thin={true}/>
                    </div>
                    {/*<MainButton color={ButtonColors.white}*/}
                    {/*            onClick={() => window.location.href = translate("hd.links.contact")}*/}
                    {/*            text={translate("hd.enterprise.viewAllCards")}*/}
                    {/*            style={this.props.width < 768 ? {display: "none"} : {}}/>*/}
                </div>
                <div className={"container-inner-cards"}>
                    {templates}
                </div>

                {this.props.width > 768 ?
                    <></> : <SecondaryButton style={{marginTop: "10px"}} color={ButtonColors.white}
                                             text={translate("hd.enterprise.viewMoreCards")}
                                             onClick={() => window.location.href = translate("hd.links.contact")}/>}
            </CardContainer>

            <ReviewBlock/>

            <Footer/>
        </>);
    }

    private calculateNrTemplates() {
        const designs = {
            brabant: brabant,
            enactus: enactus,
            hurks: hurks,
            makeawish: makeawish,
            nibud: nibud,
            rijksmuseum: rijksmuseum,
            site: site,
            sonneveld: sonneveld,
            thecolourkitchen: thecolourkitchen,
            wbl: wbl,
        }
        let steps = [180, 160, 140, 100]
        let sizeOfContainer = steps[0] + 100
        let circleSize = 170
        if (this.props.width < 468) {
            sizeOfContainer = steps[3] + 20
            circleSize = 100
        } else if (this.props.width < 568) {
            sizeOfContainer = steps[2] + 100
            circleSize = 150
        } else if (this.props.width < 768) {
            sizeOfContainer = steps[1] + 100
            circleSize = 110
        }
        let templates: Array<JSX.Element> = []
        //margin
        sizeOfContainer += 40
        let nrOfTemplates = Math.floor(this.props.width / sizeOfContainer)
        if (nrOfTemplates === 0)
            nrOfTemplates = 1
        if (nrOfTemplates > 5) {
            nrOfTemplates = 5
        }


        for (let i = 0; i < nrOfTemplates; i++) {
            templates.push(<RoundBizzerdContainer width={this.props.width} steps={steps}
                                                  img={designs[Object.keys(designs)[this.state.randomCards[i]]]}
                                                  circleSize={circleSize}
            />)
        }
        return templates
    }
}


export default EnterprisePage

import React from "react";
import SecondaryButton from "../buttons/action_secondary/secondary.button";
import ButtonColors from "../buttons/button.colors";
// @ts-ignore
import {default as graphic} from "../../../assets/blocks/computer.graphic.png"
import PageTitle from "../fields/text/pagetitle.text";
import "./flow.styles.css"
import {translate} from "react-i18nify";

interface detailProps {
    width: number
}

class SubscriptionFlow extends React.Component<detailProps, {}> {

    selectSubscription(duration) {
        localStorage.setItem("duration", duration)
        let bizzerdString = localStorage.getItem("bizzerd")
        let templateId;
        if (bizzerdString) {
            let template = JSON.parse(bizzerdString);
            templateId = template._id
        }
        window.gtag("event", "add_to_cart", {
            currency: "EUR",
            value: [-1, 9.48, 16.56, 21.24][duration],
            items: [{
                item_id: templateId,
                item_name: "Bizzerd design " + duration.toString() + " year(s)",
                price: [-1, 9.48, 16.56, 21.24][duration],
                quantity: 1,

            }]
        })
        window.gtag("event", "view_cart", {
            currency: "EUR",
            value: [-1, 9.48, 16.56, 21.24][duration],
            items: [{
                item_id: templateId,
                item_name: "Bizzerd design " + duration.toString() + " year(s)",
                quantity: 1,
                price: [-1, 9.48, 16.56, 21.24][duration]
            }]
        })
        window.location.href = translate("hd.links.shoppingcart")
    }

    render() {
        return (
            <div className={"editor-container"}>
                <div className={"subscription-left"}>
                    <img src={graphic} className={"subscription-graphic"}/>
                </div>
                <div className={"subscription-right"}>
                    <PageTitle text={translate("hd.subscription.pageTitle")}/>
                    <br/>
                    <br/>
                    <div className={"subscription-container"}>
                        <div className={"subscription-text"}>
                            <span className={"subscription-title"}>{translate("hd.subscription.block1.title")}</span>
                            <span>{translate("hd.subscription.block1.subTitle")}</span>
                        </div>
                        <SecondaryButton color={ButtonColors.orange} onClick={() => this.selectSubscription(1)}
                                         text={translate("hd.subscription.block1.button")}/>
                    </div>

                    <div className={"subscription-container"}>
                        <div className={"subscription-text"}>
                            <span className={"subscription-title"}>{translate("hd.subscription.block2.title")}</span>
                            <span>{translate("hd.subscription.block2.subTitle")}</span>
                        </div>
                        <SecondaryButton color={ButtonColors.orange} onClick={() => this.selectSubscription(2)}
                                         text={translate("hd.subscription.block2.button")}/>
                    </div>
                    <div className={"subscription-container"}>
                        <div className={"subscription-text"}>
                            <span className={"subscription-title"}>{translate("hd.subscription.block3.title")}</span>
                            <span>{translate("hd.subscription.block3.subTitle")}</span>
                        </div>
                        <SecondaryButton color={ButtonColors.orange} onClick={() => this.selectSubscription(3)}
                                         text={translate("hd.subscription.block3.button")}/>
                    </div>
                    <span className={"subscription-inclusive"}>{translate("hd.subscription.inclusive")}:</span><br/>
                    <span className={"subscription-usp"}><span
                        className={"check-mark"} style={{color: "#334c6e"}}>✔</span>{translate("hd.subscription.usp1")}</span>
                    <span className={"subscription-usp"}><span
                        className={"check-mark"} style={{color: "#334c6e"}}>✔</span>{translate("hd.subscription.usp2")}</span>
                    <span className={"subscription-usp"}><span
                        className={"check-mark"} style={{color: "#334c6e"}}>✔</span>{translate("hd.subscription.usp3")}</span>
                </div>
            </div>)
    }
}

export default SubscriptionFlow
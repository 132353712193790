import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import bizzerdLogo from '../../../assets/img/bizzerd_logo.png'

import {logoutUser} from '../../../actions/userActions'
import MainButton from "../../common2/buttons/action_main/main.button";
import {translate} from "react-i18nify";
import ButtonColors from "../../common2/buttons/button.colors";
import SecondaryButton from "../../common2/buttons/action_secondary/secondary.button";
import {default as hamburgerIcon} from "../../../assets/icons/hamburger.icon.svg"
import {default as dashboardIcon} from "../../../assets/sidebar/dashboard.icon.png"
import {default as designIcon} from "../../../assets/sidebar/design.icon.png"
import {default as gebruikersIcon} from "../../../assets/sidebar/gebruikers.icon.png"
import {default as toevoegenIcon} from "../../../assets/sidebar/collegas_icon.svg"
import {default as settingsIcon} from "../../../assets/sidebar/settings.icon.png"
import {default as bizzerdNFCIcon} from "../../../assets/sidebar/bizzerd_nfc.svg"


class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            width: window.innerWidth,
            menuOpen: false
        }
        window.addEventListener('resize', this.reportWindowSize);
    }

    reportWindowSize = () => {
        this.setState({width: window.innerWidth})
    }

    onLogoutClick = (e) => {
        e.preventDefault();
        this.props.logoutUser();
    };

    render() {
        let content;
        if (this.props.superuser) {
            content =
                (<div className="dashboard--sidebar">
                    <Link to={'/dashboard/simple'} className="sidebar--logo">
                        <img src={bizzerdLogo} alt="Klik hier om naar home te gaan"/>
                    </Link>
                    <Link
                        className={this.props.page.includes('/simple') ? "sidebar--item selected" : "sidebar--item collegas"}
                        to="/dashboard/simple">
                        <div style={{display: "flex", alignItems: "center"}}>
                            <img src={dashboardIcon} style={{width: 30, marginRight: 10, marginLeft: 10}}/>
                            <span>Dashboard</span>
                        </div>
                    </Link>
                    <Link
                        className={this.props.page.includes('/colleague/overview') ? "sidebar--item selected" : "sidebar--item collegas"}
                        to="/dashboard/colleague/overview">
                        <div style={{display: "flex", alignItems: "center"}}>
                            <img src={gebruikersIcon} style={{width: 30, marginRight: 10, marginLeft: 10}}/>
                            <span>{translate("dashboard.sidebar.overview")}</span>
                        </div>
                    </Link>
                    <Link
                        className={this.props.page.includes('/ontwerpen/kiesdesign') || this.props.page.includes("/kiestemplate") || this.props.page.includes("/ontwerpen/tool") ? "sidebar--item selected" : "sidebar--item ontwerpjoy"}
                        to="/dashboard/ontwerpen/kiesdesign">
                        <div style={{display: "flex", alignItems: "center"}}>
                            <img src={designIcon} style={{width: 30, marginRight: 10, marginLeft: 10}}/>
                            <span>{translate("dashboard.sidebar.customize")}</span>
                        </div>
                    </Link>
                    <Link
                        className={this.props.page.includes('/collegas/kiezen') || this.props.page.includes("/collegas/toevoegen") || this.props.page.includes("/collegas/wijzigen") || this.props.page.includes("/collegas/uitnodigen") ? "sidebar--item selected" : "sidebar--item"}
                        to="/dashboard/collegas/kiezen">
                        <div style={{display: "flex", alignItems: "center"}}>
                            <img src={toevoegenIcon} style={{width: 30, marginRight: 10, marginLeft: 10}}/>
                            <span>{translate("dashboard.sidebar.add")}</span>
                        </div>
                    </Link>
                    <Link
                        className={this.props.page.includes('/instellingen/nfc/edit') ? "sidebar--item selected" : "sidebar--item"}
                        to="/dashboard/instellingen/nfc/edit">
                        <div style={{display: "flex", alignItems: "center"}}>
                            <img src={bizzerdNFCIcon} style={{width: 30, marginRight: 10, marginLeft: 10}}/>
                            <span>Bizzerd NFC</span>
                        </div>
                    </Link>
                    <Link
                        className={this.props.page.includes('instellingen') && !this.props.page.includes("nfc/edit") ? "sidebar--item selected" : "sidebar--item"}
                        to="/dashboard/instellingen/bedrijf">
                        <div style={{display: "flex", alignItems: "center"}}>
                            <img src={settingsIcon} style={{width: 30, marginRight: 10, marginLeft: 10}}/>
                            <span>{translate("dashboard.sidebar.settings")}</span>
                        </div>
                    </Link>
                    <div className={'sidebar--second'}>
                        <SecondaryButton onClick={this.onLogoutClick} text={translate("hd.actions.logout")}
                                         color={ButtonColors.orange}/>
                    </div>
                </div>)
        }
        else {
            content = (
                <div className="dashboard--sidebar">
                    <Link to={'/dashboard/collegas'} className="sidebar--logo">
                        <img src={bizzerdLogo} alt="Klik hier om naar home te gaan"/>
                    </Link>
                    <Link className={this.props.page.includes('collegas') ? "sidebar--item selected" : "sidebar--item"}
                          to="/dashboard/collegas/toevoegen">
                        <span>Visitekaartjes beheren</span>
                    </Link>
                    <div className={'sidebar--second'}>
                        <SecondaryButton onClick={this.onLogoutClick} text={translate("hd.actions.logout")}
                                         color={ButtonColors.orange}/>
                    </div>
                </div>);
        }
        if (this.state.width < 768) {
            if (!this.state.menuOpen) {
                content =
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 20}}>
                        <img src={hamburgerIcon}
                             style={{width: 30, height: 30}}
                             onClick={() => this.setState({menuOpen: !this.state.menuOpen})}/>
                        <SecondaryButton href={'#/'} onClick={this.onLogoutClick} text={translate("hd.actions.logout")}
                                         color={ButtonColors.orange}/>
                    </div>
            } else {
                content =
                    <>
                        <div style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            padding: 20
                        }}>
                            <img src={hamburgerIcon}
                                 style={{width: 30, height: 30}}
                                 onClick={() => this.setState({menuOpen: !this.state.menuOpen})}/>
                            <SecondaryButton href={'#/'} onClick={this.onLogoutClick}
                                             text={translate("hd.actions.logout")}
                                             color={ButtonColors.orange}/>
                        </div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            zIndex: 999,

                            left: 0,
                            top: 0,
                            width: "100%",
                            position: "fixed",
                            height: "100%"
                        }} onClick={() => this.setState({menuOpen: false})}>
                            <div style={{
                                flexDirection: "column",
                                width: "70%",
                                height: "100%",
                                backgroundColor: "#FFF",
                            }}>
                                <Link
                                    to={'/dashboard/simple'}
                                    className="sidebar--item">
                                    <img src={bizzerdLogo} alt="Klik hier om naar home te gaan"
                                         style={{width: 200, height: 50}}/>
                                </Link>
                                <Link
                                    className={this.props.page.includes('/simple') ? "sidebar--item selected" : "sidebar--item collegas"}
                                    to="/dashboard/simple">
                                    <img src={dashboardIcon} style={{width: 30, marginRight: 10, marginLeft: 10}}/>
                                    <span>Dashboard</span>
                                </Link>
                                <Link
                                    className={this.props.page.includes('/colleague/overview') ? "sidebar--item selected" : "sidebar--item collegas"}
                                    to="/dashboard/colleague/overview">
                                    <img src={gebruikersIcon} style={{width: 30, marginRight: 10, marginLeft: 10}}/>

                                    <span>{translate("dashboard.sidebar.overview")}</span>
                                </Link>
                                <Link
                                    className={this.props.page.includes('/ontwerpen/kiesdesign') || this.props.page.includes("/kiestemplate") || this.props.page.includes("/ontwerpen/tool") ? "sidebar--item selected" : "sidebar--item ontwerpjoy"}
                                    to="/dashboard/ontwerpen/kiesdesign">
                                    <img src={designIcon} style={{width: 30, marginRight: 10, marginLeft: 10}}/>

                                    <span>{translate("dashboard.sidebar.customize")}</span>
                                </Link>
                                <Link
                                    className={this.props.page.includes('/collegas/kiezen') || this.props.page.includes("/collegas/toevoegen") || this.props.page.includes("/collegas/uitnodigen") ? "sidebar--item selected" : "sidebar--item"}
                                    to="/dashboard/collegas/kiezen">
                                    <img src={toevoegenIcon} style={{width: 30, marginRight: 10, marginLeft: 10}}/>

                                    <span>{translate("dashboard.sidebar.add")}</span>
                                </Link>
                                <Link
                                    className={this.props.page.includes('/instellingen/nfc/edit') ? "sidebar--item selected" : "sidebar--item"}
                                    to="/dashboard/instellingen/nfc/edit">
                                    <div style={{display: "flex", alignItems: "center"}}>
                                        <img src={bizzerdNFCIcon} style={{width: 30, marginRight: 10, marginLeft: 10}}/>
                                        <span>Bizzerd NFC</span>
                                    </div>
                                </Link>
                                <Link
                                    className={this.props.page.includes('instellingen') && !this.props.page.includes('/instellingen/nfc/edit') ? "sidebar--item selected" : "sidebar--item"}
                                    to="/dashboard/instellingen/bedrijf">
                                    <img src={settingsIcon} style={{width: 30, marginRight: 10, marginLeft: 10}}/>

                                    <span>{translate("dashboard.sidebar.settings")}</span>
                                </Link></div>
                        </div>
                    </>
            }
        }
        return content
    }
}


Sidebar.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps, {logoutUser})(Sidebar);

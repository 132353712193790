import PageTitle from "../fields/text/pagetitle.text";
import IconHeader from "../headers/icon.header";
import SecondaryButton from "../buttons/action_secondary/secondary.button";
import ButtonColors from "../buttons/button.colors";
import IconsContainer from "../containers/icons.container";
import React from "react";
import {translate} from "react-i18nify";

// @ts-ignore
import {default as climate} from "../../../assets/icons/blue/duurzaam_icon.svg"
// @ts-ignore
import {default as collect} from "../../../assets/icons/blue/collect.icon.svg"
// @ts-ignore
import {default as fingerprint} from "../../../assets/icons/blue/branding_icon.svg"
// @ts-ignore
import {default as share} from "../../../assets/icons/blue/share.icon.svg"
// @ts-ignore
import {default as uptodate} from "../../../assets/icons/blue/inzicht_icon.svg"
// @ts-ignore
import {default as interactive} from "../../../assets/icons/blue/interactief_icon.svg"
// @ts-ignore
import {default as impact} from "../../../assets/icons/blue/impact_icon.svg"

import SubSubSubTitle from "../fields/text/subsubsubtitle.text";

class IconsBlock extends React.Component<{ width: number, white?: boolean, bottomBorder?: boolean }, { selected: string }> {
    constructor(props) {
        super(props);
        this.state = {
            selected: "nfc"
        }
        this.onClick = this.onClick.bind(this)
        setInterval(this.nextCarousel, 5000);
    }

    nextCarousel = () => {
        let options = ['nfc', 'interactive', 'uptodate', 'climate', 'fingerprint']
        let currentIndex = options.indexOf(this.state.selected)
        if (currentIndex === options.length - 1) {
            currentIndex = 0
        } else {
            currentIndex += 1
        }
        this.setState({selected: options[currentIndex]})
    }

    onClick(icon) {
        this.setState({selected: icon})
    }

    render() {
        let texts = {
            nfc: translate("hd.iconsBlock.nfc"),
            interactive: translate("hd.iconsBlock.interactive"),
            impact: translate("hd.iconsBlock.impact"),
            uptodate: translate("hd.iconsBlock.uptodate"),
            climate: translate("hd.iconsBlock.climate"),
            fingerprint: translate("hd.iconsBlock.fingerprint")
        }

        return (
            <IconsContainer background={this.props.white}
                            style={this.props.white || this.props.bottomBorder ? {
                                borderRadius: "0px 0px 130px 130px",
                                backgroundColor: "#FFF"
                            } : {}}>

                {this.props.white ? <></> :
                    <>
                        <PageTitle
                            text={translate("hd.iconsBlock.pageTitle")}
                            style={{
                                paddingBottom: this.props.bottomBorder ? "20px" : "0",
                                color: this.props.white ? "#FFF" : ""
                            }}/>
                        {!this.props.bottomBorder ?
                            <span className={"icon-container-content"} style={{color: this.props.white ? "#FFF" : ""}}>
                                {translate("hd.iconsBlock.iconContainerContent")}
                        </span> : <></>}
                    </>
                }
                <div className={"icon-outer-container"}>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        marginRight: this.props.width < 768 ? 0 : 20
                    }}>
                        <div className={"container-icon"}>
                            <img className={'icon-usp'} src={interactive} alt={"icon"}/>
                            <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                                <SubSubSubTitle
                                    style={{color: "#2d4267", fontWeight: "bold", fontSize: 18, marginBottom: 5}}
                                    text={translate("hd.iconsBlock.interactiveLabel")}/>
                                <span>{translate("hd.iconsBlock.interactive")}</span>
                            </div>
                        </div>
                        <div className={"container-icon"}>
                            <img className={'icon-usp'} src={impact} alt={"icon"}/>
                            <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                                <SubSubSubTitle
                                    style={{color: "#2d4267", fontWeight: "bold", fontSize: 18, marginBottom: 5}}
                                    text={translate("hd.iconsBlock.impactLabel")}/>
                                <span>{translate("hd.iconsBlock.impact")}</span>
                            </div>
                        </div>
                        <div className={"container-icon"}>
                            <img className={'icon-usp'} src={uptodate} alt={"icon"}/>
                            <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                                <SubSubSubTitle
                                    style={{color: "#2d4267", fontWeight: "bold", fontSize: 18, marginBottom: 5}}
                                    text={translate("hd.iconsBlock.uptodateLabel")}/>
                                <span>{translate("hd.iconsBlock.uptodate")}</span>
                            </div>
                        </div>
                    </div>

                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                        marginLeft: this.props.width < 768 ? 0 : 20
                    }}>

                        <div className={"container-icon"}>
                            <img className={'icon-usp'} src={climate} alt={"icon"}/>
                            <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                                <SubSubSubTitle
                                    style={{color: "#2d4267", fontWeight: "bold", fontSize: 18, marginBottom: 5}}
                                    text={translate("hd.iconsBlock.climateLabel")}/>
                                <span>{translate("hd.iconsBlock.climate")}</span>
                            </div>
                        </div>

                        <div className={"container-icon"}>
                            <img className={'icon-usp'} src={fingerprint} alt={"icon"}/>
                            <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
                                <SubSubSubTitle
                                    style={{color: "#2d4267", fontWeight: "bold", fontSize: 18, marginBottom: 5}}
                                    text={translate("hd.iconsBlock.fingerprintLabel")}/>
                                <span>{translate("hd.iconsBlock.fingerprint")}</span>
                            </div>
                        </div>
                    </div>
                </div>


                <div style={{
                    width: "100%",
                    textAlign: 'center',
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "40px"
                }}>
                    <SecondaryButton color={ButtonColors.white} href={translate("hd.links.product")}
                                     text={translate("hd.iconsBlock.howDoesItWork")}/>
                </div>
            </IconsContainer>
        );
    }

}

export default IconsBlock
